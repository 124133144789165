import { PDF_REPORT_CONSTANTS, REPORT_SECTION_NAMES } from './pdfReport_constants';
import { reportSectionsToShow } from '../../modules/AicaPDFReport/AicaPDFReport';

interface PDFReportAction {
    type: keyof typeof PDF_REPORT_CONSTANTS;
    payload?: any;
    assesseeOrgId?: string;
}

interface PDFReportReducer {
    initialised: boolean;
    assesseeOrgId: string;
    reportSectionsObj: null | {
        [sectionKey: string]: {
            // hasData: boolean;
            pageNum: string;
            pagesInSection: number;
        };
    };
}

const initialState: PDFReportReducer = {
    initialised: false,
    assesseeOrgId: '',
    reportSectionsObj: null,
};

const initialisePDFReportFromSections = (state, action) => {
    const reportSectionsArr = action?.payload || [];
    const assesseeOrgId = action?.assesseeOrgId;
    const reportSectionsObj = reportSectionsArr?.reduce((acc, sectionName) => {
        const sectionIndex = reportSectionsToShow.indexOf(sectionName);
        if (sectionName)
            acc[sectionName] = {
                // hasData: false,
                pageNum: sectionIndex > -1 ? sectionIndex : null,
                pagesInSection: 0,
            };
        return acc;
    }, {});
    return {
        ...state,
        reportSectionsObj,
    };
};

const resetPDFReport = (state, action) => {
    return {
        ...state,
        initialised: false,
        assesseeOrgId: '',
        reportSectionsObj: null,
    };
};

const setPagesCountForSection = (state, action) => {
    const sectionName = action?.payload?.sectionName;
    const pagesCount = action?.payload?.pagesCount;

    if (!sectionName) return state;
    return {
        ...state,
        reportSectionsObj: {
            ...(state?.reportSectionsObj || {}),
            [sectionName]: {
                ...(state?.reportSectionsObj?.[sectionName] || {}),
                pagesInSection: pagesCount,
            },
        },
    };
};
const incrementPagesCountForSection = (state, action) => {
    const sectionName = action?.payload?.sectionName;

    if (!sectionName) return state;
    return {
        ...state,
        reportSectionsObj: {
            ...(state?.reportSectionsObj || {}),
            [sectionName]: {
                ...(state?.reportSectionsObj?.[sectionName] || {}),
                pagesInSection:
                    (Number(state?.reportSectionsObj?.[sectionName]?.pagesInSection) || 0) + 1,
            },
        },
    };
};

const reducer = (
    state: PDFReportReducer = initialState,
    action: PDFReportAction,
): PDFReportReducer => {
    switch (action.type) {
        case PDF_REPORT_CONSTANTS.INITIALISE_PDF_REPORT:
            return initialisePDFReportFromSections(state, action);
        case PDF_REPORT_CONSTANTS.SET_PAGES_COUNT_FOR_SECTION:
            return setPagesCountForSection(state, action);
        case PDF_REPORT_CONSTANTS.INC_PAGES_COUNT_FOR_SECTION:
            return incrementPagesCountForSection(state, action);
        case PDF_REPORT_CONSTANTS.RESET_PDF_REPORT:
            return resetPDFReport(state, action);
        default:
            return state;
    }
};

export default reducer;
