import React, { useEffect, useState } from 'react';
import { addColorandIconToRow } from '../../../../../utils/TableTransformers';
import styles from './ClassifiedTable.module.scss';
import { isEmpty } from '../../../../../utils/utils';
import EmptyBank from '../../../DashboardModules/BankDataTable/components/EmptyBank/EmptyBank';
import loaderSvg from '../../../../../assets/aica-loader.svg';
import {
    getDocMetaDataHelper,
    getPresignedUrl,
} from '../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import SideDrawer from '../../../../../common/_custom/SideDrawer/SideDrawer';
import DocumentViewer from '../../../../../common/DocumentViewer/DocumentViewer';
import { toast } from 'react-toastify';
import { alertIconDisabled, ctaPurpleRight } from '../../../../../assets/hostedassets';
import Formula from '../../Formula/Formula';
import Tooltip from '../../../../../common/_custom/Tooltip/Tooltip';
import clsx from 'clsx';

type ClassifiedTableProps = {
    data: {
        headers: { [key: string]: string }[];
        rows: { [key: string]: string }[];
    };
    loading: boolean;
    handleClick: (row: { [key: string]: string }, header: { [key: string]: string }) => void;
    metric: string;
    isPDFReport?: boolean;
};

const ClassifiedTable = (props: ClassifiedTableProps) => {
    const { metric, isPDFReport = false } = props;
    const [headers, setHeaders] = useState<{ [key: string]: string }[]>([]);
    const [rows, setRows] = useState<{ [key: string]: string }[]>([]);
    const [groupedRows, setGroupedRows] = useState<any>([]);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [showFile, setShowFile] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');

    const handleRefernceRowClick = async (docId) => {
        if (docId) {
            await getFileUrl(docId);
            await DocMetaDataFromDocId(docId);
            setShowFile(true);
        }
    };

    const DocMetaDataFromDocId = async (docId: string) => {
        try {
            const res = await getDocMetaDataHelper(docId);
            if (res && res?.docMetaData) {
                setFileName(res?.docMetaData?.fileName);
            }
        } catch (error) {
            console.error('Error in fetching document metadata', error);
        }
    };

    const getFileUrl = async (docId: string) => {
        try {
            const res = await getPresignedUrl({ docId: Number(docId) }, false);
            if (res) {
                if (res.fileType === 'application/pdf') {
                    setFileUrl(res.uri);
                } else {
                    toast.info('File is not a PDF');
                }
            } else {
                toast.error('Error in fetching file');
            }
        } catch (error) {
            toast.error('Error in fetching file');
        }
    };

    useEffect(() => {
        setHeaders(props.data?.headers ?? []);
        setRows(props.data?.rows?.filter((row) => !isEmpty(row)) ?? []);
    }, [props.data]);

    const groupRowsByLabel = (rows: { [key: string]: string }[]) => {
        // make a map of rows with label as key
        let temp = {};

        rows.forEach((row) => {
            // group the rows based on sublabel
            let lab = row?.label?.trim() ?? 'Unclassified';
            let subLab =
                row?.subLabel === 'Reference Document'
                    ? ''
                    : row?.subLabel?.trim() ?? 'Unclassified';

            if (!temp[lab]) {
                temp[lab] = [];
            }
            if (!temp[lab][subLab]) {
                temp[lab][subLab] = [];
            }

            let newRow = addColorandIconToRow(row);
            temp[lab][subLab].push(newRow);
        });

        Object.keys(temp).forEach((label) => {
            temp[label] = Object.keys(temp[label])
                .sort((a, b) => {
                    if (a === 'Current Assets') return 1;
                    if (b === 'Current Assets') return -1;
                    return 0;
                })
                .reduce((acc, key) => {
                    acc[key] = temp[label][key];
                    return acc;
                }, {});
            Object.keys(temp[label]).forEach((subLabel) => {
                // sorting the rows making isAggregated rows as last row
                temp[label][subLabel].sort((a, b) => {
                    if (a.isAggregated && !b.isAggregated) return 1;
                    if (!a.isAggregated && b.isAggregated) return -1;
                    return 0;
                });
            });
        });
        setGroupedRows(temp);
    };
    useEffect(() => {
        if (rows.length) {
            groupRowsByLabel(rows);
        }
    }, [rows]);
    const getStyleBasedOnLabel = (label: string, sublabel: boolean) => {
        switch (label) {
            case 'Assets':
                if (sublabel)
                    return {
                        color: 'rgb(0, 170, 0)',
                    };
                return {
                    background: 'rgb(236, 252, 216)',
                    color: 'rgb(0, 170, 0)',
                };
            case 'Equity & Liabilities':
                if (sublabel)
                    return {
                        color: 'rgb(170, 0, 0)',
                    };
                return {
                    background: 'rgb(255, 230, 230)',
                    color: 'rgb(170, 0, 0)',
                };
        }
    };
    const renderRefernceDocumentRow = (row: any, key: string) => {
        if (row.classificationName === 'Reference Document') {
            const docId = row[key];
            if (docId) {
                return (
                    <div className={styles.PDF} onClick={() => handleRefernceRowClick(docId)}>
                        <img src={ctaPurpleRight} alt="available" />
                        <span>PDF</span>
                    </div>
                );
            } else {
                return (
                    <div className={styles.NA}>
                        <img src={alertIconDisabled} alt="not available" />
                        <span>NA</span>
                    </div>
                );
            }
        } else {
            return row?.[key] ?? '-';
        }
    };
    return (
        <>
            {props.loading ? (
                <div className={styles.LoadingContainer}>
                    <img
                        className={styles.LoaderImg}
                        src={loaderSvg}
                        alt="Loading..."
                        height={'30%'}
                        width={'30%'}
                    />
                </div>
            ) : props?.data && isEmpty(headers) ? (
                <div className={styles.DataNotAvailable}>
                    <EmptyBank
                        style={{ margin: 0, boxShadow: 'none' }}
                        text={'Data not available'}
                    />
                </div>
            ) : (
                <div className={clsx(styles.TableContainer, { [styles.PDFReport]: isPDFReport })}>
                    <table className={styles.Table}>
                        <thead className={styles.TableHeaders}>
                            <tr className={styles.TableHeader}>
                                {headers?.map((header, index) => (
                                    <th
                                        className={
                                            header.name === 'Classification'
                                                ? styles.FrozenCell
                                                : styles.HeaderCell
                                        }
                                        key={index}
                                        style={{ minWidth: isPDFReport ? 'auto' : '' }}
                                    >
                                        {header.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={styles.TableBody}>
                            {Object.keys(groupedRows).map((label, index) => (
                                <>
                                    {label !== '' &&
                                        label !== 'Unclassified' &&
                                        metric === 'Balance Sheet' && (
                                            <tr
                                                // style={getStyleBasedOnLabel(label, false)}
                                                className={styles.LabelRow}
                                                key={index}
                                            >
                                                <td
                                                    className={styles.LabelCell}
                                                    style={getStyleBasedOnLabel(label, false)}
                                                    colSpan={
                                                        headers.length < 9 ? headers?.length : 9
                                                    }
                                                >
                                                    {label}
                                                </td>
                                            </tr>
                                        )}
                                    {Object.keys(groupedRows[label]).map((subLabel) => (
                                        <>
                                            {subLabel !== '' && metric === 'Balance Sheet' && (
                                                <tr
                                                    style={getStyleBasedOnLabel(label, true)}
                                                    className={styles.SubLabelRow}
                                                    key={index}
                                                >
                                                    <td className={styles.SubLabelCell}>
                                                        {subLabel}
                                                    </td>
                                                    <td
                                                        className={styles.SubLabelCell}
                                                        colSpan={
                                                            headers.length > 12
                                                                ? 13
                                                                : headers.length - 1
                                                        }
                                                    ></td>
                                                </tr>
                                            )}
                                            {groupedRows?.[label] &&
                                                groupedRows[label][subLabel]?.map(
                                                    (row: any, index: number) => (
                                                        <tr
                                                            className={clsx(styles.TableRow, {
                                                                [styles.RefDocRow]:
                                                                    row.classificationName ===
                                                                    'Reference Document',
                                                            })}
                                                            key={index}
                                                        >
                                                            {headers.map((header, index) => (
                                                                <td
                                                                    style={{
                                                                        borderTop: row.isAggregated
                                                                            ? '2px solid #000'
                                                                            : '',
                                                                        //@description: not to be reverted!!
                                                                        fontFamily: row.isAggregated
                                                                            ? 'Gilroy-semibold'
                                                                            : '',
                                                                        background:
                                                                            header.name ===
                                                                                'Classification' ||
                                                                            row.isAggregated
                                                                                ? row?.bg ?? '#fff'
                                                                                : '#fff',
                                                                        color:
                                                                            header.name ===
                                                                                'Classification' ||
                                                                            row.isAggregated
                                                                                ? row?.color ??
                                                                                  '#000'
                                                                                : '#000',
                                                                        minWidth:
                                                                            header.name ===
                                                                            'Classification'
                                                                                ? '15rem'
                                                                                : 'auto',
                                                                        cursor:
                                                                            header.name ===
                                                                                'Classification' &&
                                                                            !row.isAggregated &&
                                                                            row.classificationName !==
                                                                                'Reference Document' &&
                                                                            row?.[header.key]
                                                                                ? 'pointer'
                                                                                : 'auto',
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            header.name ===
                                                                                'Classification' &&
                                                                            row.classificationName !==
                                                                                'Reference Document'
                                                                        ) {
                                                                            props.handleClick(
                                                                                row,
                                                                                header,
                                                                            );
                                                                        }
                                                                    }}
                                                                    className={
                                                                        header.name ===
                                                                        'Classification'
                                                                            ? styles.FrozenCell
                                                                            : styles.TableCell
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {row.formula &&
                                                                    header.name ===
                                                                        'Classification' ? (
                                                                        <Tooltip
                                                                            content={
                                                                                row.formula ? (
                                                                                    <Formula
                                                                                        string={
                                                                                            row.formula
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    row.description
                                                                                )
                                                                            }
                                                                            direction={
                                                                                row.formula
                                                                                    ? 'top'
                                                                                    : 'right'
                                                                            }
                                                                            containerFullWidth={
                                                                                !!row.formula
                                                                            }
                                                                            fitContent
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    styles.CellText
                                                                                }
                                                                            >
                                                                                {row.symbol &&
                                                                                    header.name ===
                                                                                        'Classification' && (
                                                                                        <div
                                                                                            className={
                                                                                                styles.Label
                                                                                            }
                                                                                            style={{
                                                                                                border: `1px solid ${row.color}`,
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                row.symbol
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                {row?.[
                                                                                    header.key
                                                                                ] ?? '-'}
                                                                                {header.name ===
                                                                                    'Classification' &&
                                                                                    row?.[
                                                                                        header.key
                                                                                    ] &&
                                                                                    !row.isAggregated && (
                                                                                        <img
                                                                                            src={
                                                                                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/rightArrowMisIngestion.svg'
                                                                                            }
                                                                                            height={
                                                                                                12
                                                                                            }
                                                                                            width={
                                                                                                12
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                            </span>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <span
                                                                            className={
                                                                                styles.CellText
                                                                            }
                                                                        >
                                                                            {row.symbol &&
                                                                                header.name ===
                                                                                    'Classification' && (
                                                                                    <div
                                                                                        className={
                                                                                            styles.Label
                                                                                        }
                                                                                        style={{
                                                                                            border: `1px solid ${row.color}`,
                                                                                        }}
                                                                                    >
                                                                                        {row.symbol}
                                                                                    </div>
                                                                                )}
                                                                            {header.name ===
                                                                            'Classification'
                                                                                ? row?.classificationName ??
                                                                                  '-'
                                                                                : renderRefernceDocumentRow(
                                                                                      row,
                                                                                      header.key,
                                                                                  )}

                                                                            {header.name ===
                                                                                'Classification' &&
                                                                                row?.[header.key] &&
                                                                                !row.isAggregated &&
                                                                                row.classificationName !==
                                                                                    'Reference Document' && (
                                                                                    <img
                                                                                        src={
                                                                                            'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/rightArrowMisIngestion.svg'
                                                                                        }
                                                                                        height={12}
                                                                                        width={12}
                                                                                    />
                                                                                )}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ),
                                                )}
                                        </>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {showFile && (
                <SideDrawer
                    heading={fileName}
                    showHeading={true}
                    width="75%"
                    allowClose
                    onClose={() => setShowFile(false)}
                >
                    <DocumentViewer fileUrl={fileUrl} />
                </SideDrawer>
            )}
        </>
    );
};

export default ClassifiedTable;
