import React from 'react';
import styles from './KeyInvestors.module.scss';
import AIBadge from '../AIBadge/AIBadge';
import { organisationIcon } from '../../../../../../assets/hostedassets';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';

interface KeyInvestorsProps {
    fundingInfo: any;
    isPDFReport?: boolean;
}

const KeyInvestors: React.FC<KeyInvestorsProps> = ({ fundingInfo, isPDFReport = false }) => {
    if (!fundingInfo?.investors?.length) {
        return <></>;
    }
    const render = (
        <div className={styles.KeyInvestors}>
            <div className={styles.Top}>
                <div className={styles.Title}>Key Investors</div>
                <div className={styles.Right}>
                    <AIBadge />
                </div>
            </div>

            <div className={styles.Body}>
                <div className={styles.Container}>
                    {fundingInfo?.investors?.map((investor, i) => (
                        <div className={styles.Investor} key={i}>
                            <div className={styles.Image}>
                                <img
                                    src={investor?.imageLink || organisationIcon}
                                    alt="profile"
                                    width="100%"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = organisationIcon;
                                    }}
                                />
                            </div>
                            <div className={styles.Name} title={investor?.name}>
                                {investor?.name?.length > 60
                                    ? `${investor?.name?.substring?.(0, 60)}...`
                                    : investor?.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    if (isPDFReport)
        return fundingInfo?.investors?.length &&
            reportSectionsToShow?.includes(REPORT_SECTION_NAMES.KEY_INVESTORS) ? (
            <A4Page>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default KeyInvestors;
