import React, { useEffect, useRef, useState } from 'react';
import styles from './CircularSelect.module.scss';
import clsx from 'clsx';
import {
    plusPurpleCircle,
    PURPLE_CROSS,
    PURPLE_DROPDOWN_CARET,
} from '../../../../assets/hostedassets';
import LoadingSpinner from '../../../_custom/LoadingSpinner/LoadingSpinner';

interface CircularSelectProps {
    option: string[];
    onSelect: (value: string) => Promise<boolean>;
    selectedOption?: string;
    placeholder?: string;
    disabled?: boolean;
    isFinalized?: boolean;
    isSelectingDocType?: boolean;
}

const CircularSelect: React.FC<CircularSelectProps> = ({
    option,
    onSelect,
    isFinalized,
    selectedOption,
    placeholder,
    disabled,
    isSelectingDocType,
}) => {
    const [options, setOptions] = useState<string[]>(option);
    const [filterPhrase, setFilterPhrase] = useState<string | null>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [otherOption, setOtherOption] = useState('');
    const [showOtherOption, setShowOtherOption] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [dontTriggerBlur, setDontTriggerBlur] = useState(false);
    const inputFocus = useRef<HTMLInputElement>(null);
    const [isSearchingDocType, setIsSearchingDocType] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
            setOptions(option);
            setShowOptions(false);
        }
    };

    const getIcon = (src) => {
        if (isSelectingDocType) {
            return (
                <LoadingSpinner
                    color="#DF666A"
                    style={{
                        width: '1rem',
                        height: '1rem',
                        position: 'absolute',
                        right: '-2px',
                        top: '0px',
                        zIndex: 10,
                    }}
                />
            );
        }
        return <img src={src} alt="Cross" height={9} width={9} />;
    };

    const renderIcon = () => {
        switch (true) {
            case showOtherOption:
                return (
                    <div
                        className={styles.crossIcon}
                        onMouseDown={(e) => {
                            e.preventDefault();
                            setShowOtherOption(false);
                            setOtherOption('');
                        }}
                    >
                        {getIcon(PURPLE_CROSS)}
                    </div>
                );
            case !disabled && !!selectedOption:
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!showOtherOption) {
                                setShowOptions((prev) => !prev);
                            }
                        }}
                        className={styles.crossIcon}
                    >
                        {getIcon(PURPLE_DROPDOWN_CARET)}
                    </div>
                );
            case disabled:
                return null;
            case isSearchingDocType || isSelectingDocType:
                return (
                    <LoadingSpinner
                        color="#DF666A"
                        style={{
                            width: '1rem',
                            height: '1rem',
                            position: 'absolute',
                            right: '11px',
                            top: '12px',
                            zIndex: 10,
                        }}
                    />
                );
            default:
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!showOtherOption) {
                                setShowOptions((prev) => !prev);
                            }
                        }}
                        className={styles.arrowIcon}
                    />
                );
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showOtherOption) {
            inputFocus.current?.click();
        }
    }, [showOtherOption]);

    const handleChange = (value: string) => {
        if (!showOtherOption) {
            setOptions(option.filter((item) => item.toLowerCase().includes(value.toLowerCase())));
            setFilterPhrase(value);
        } else {
            setOtherOption(value);
        }
        if (showOptions) {
            setIsSearchingDocType(value.length >= 1);
        }
    };

    useEffect(() => {
        if (showOptions && filterPhrase) {
            setIsSearchingDocType(false);
        }
    }, [options, filterPhrase]);

    const handleSelect = async (option: string) => {
        setLoading(true);
        await onSelect(option);
        setLoading(false);
        setFilterPhrase(null);
        setShowOptions(false);
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && showOtherOption) {
                inputFocus.current?.blur();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOtherOption]);

    const handleBlur = () => {
        setTimeout(async () => {
            if (dontTriggerBlur) {
                // If this flag is set, don't execute the blur logic.
                setDontTriggerBlur(false); // Reset the flag for future actions.
                return;
            }

            // If the user clicked outside and didn't enter any value, reset the state.
            if (otherOption === '') {
                setShowOtherOption(false);
                setFilterPhrase(null);
                return;
            }

            // If the user entered a value in the "other option" input, proceed with selection.
            if (otherOption && showOtherOption) {
                let res = await onSelect(otherOption);
                if (res) {
                    setShowOtherOption(false);
                    setOtherOption('');
                    setFilterPhrase(null);
                }
            }
        }, 0);
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative', width: '100%' }}>
            <input
                ref={inputFocus}
                type="text"
                autoComplete={'off'}
                placeholder={showOtherOption ? 'Enter Data Type...' : placeholder ?? 'Select...'}
                value={
                    showOtherOption
                        ? otherOption
                        : filterPhrase !== null
                        ? filterPhrase
                        : selectedOption || otherOption
                }
                disabled={disabled}
                className={clsx({
                    [styles.selectBox]: true,
                    [styles.FilteredOptionAvailableInput]: filterPhrase !== null,
                    [styles.Input]: showOtherOption,
                    [styles.selectedBox]: selectedOption,
                    [styles.finalized]: isFinalized || disabled,
                })}
                onClick={(e) => {
                    if (showOtherOption || loading) return;
                    setShowOptions((prev) => !prev);
                }}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
            />

            {renderIcon()}

            {showOptions && !disabled && (
                <div className={styles.dropdown}>
                    {options?.map((option, index) => (
                        <div
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                handleSelect(option);
                            }}
                            key={index}
                            className={styles.option}
                        >
                            {option}
                        </div>
                    ))}
                    <div
                        onMouseDown={() => {
                            setShowOptions(false);
                            setShowOtherOption(true);
                            setFilterPhrase('');
                            setDontTriggerBlur(true);
                            setTimeout(() => {
                                inputFocus.current?.focus();
                            }, 0);
                        }}
                        className={styles.option}
                        style={{
                            color: 'var(--primary-text-colour)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <img src={plusPurpleCircle} alt={'Add'} height={12} width={12} />
                        Add Other Data Type
                    </div>
                </div>
            )}
        </div>
    );
};

export default CircularSelect;
