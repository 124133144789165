import { COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import { Mixpanel } from '../../../../../utils/mixpanel';
import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import styles from './AdditionalDocuments.module.scss';

function AdditionalDocuments({
    additionalDocsData,
    updateFunction,
    assesseeOrgId,
    categoryId,
    flowId,
    dropDownOptions,
    isInvestor,
}) {
    const updateBankStatementAttachment = async (fileData, isDelete = false) => {
        const newFileAdded = {
            docId: fileData?.docId,
            docName: isDelete ? fileData?.docName : fileData?.fileName,
            description: fileData?.description,
            isDeleted: isDelete,
        };
        await updateFunction(newFileAdded);
    };
    return (
        <div style={isInvestor ? { width: '80%' } : {}} className={styles.AdditionalDocuments}>
            <div className={styles.Title}>Add Additional Data</div>
            <div className={styles.Subtext}>
                Add any supporting document (like Company Deck etc.)
            </div>

            <UploadCard
                dropDownOptions={dropDownOptions}
                existingFiles={additionalDocsData?.additionalDocs || []}
                assesseeOrgId={assesseeOrgId}
                // categoryId={28}
                categoryId={categoryId}
                // flowId={8}
                flowId={flowId}
                metadataUpdate={updateBankStatementAttachment}
                fileDeleteHandler={(fileData) => updateBankStatementAttachment(fileData, true)}
                fileWithDescription
                acceptedFormats={[...FILE_FORMATS.EXCEL_PDF, ...FILE_FORMATS.JSON]}
                onClickFunction={() => {
                    Mixpanel.track(COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK);
                }}
            />
        </div>
    );
}

export default AdditionalDocuments;
