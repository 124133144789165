import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { Handlers } from '../../../types/actions/types';
import { fetchRelatedPartyDataService } from './relatedParties_service';

export async function fetchRelatedPartiesData(payload) {
    try {
        const response = await fetchRelatedPartyDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.responseBody;
            case JAVA_API_STATUS.ERROR:
                return null;
        }
    } catch (err) {
        return null;
    }
}
