import React, { useEffect, useRef, useState } from 'react';
import styles from './AicaPDFReport.module.scss';
import './A4Page/A4.scss';
import { Modal } from '../../common/_custom';
import Overview from '../Dashboard/DashboardModules/Overview/Overview';
import useLegacyBusinessDataFetch from '../DataRoom/useLegacyBusinessDataFetch';
import html2pdf from 'html2pdf.js';
import A4Page from './A4Page/A4Page';
import Dashboard from '../Dashboard';
import Financials, { AUDITED_FINANCIALS } from '../Dashboard/DashboardModules/Financials/Financials';
import LegalCases from '../Dashboard/components/DashboardTables/LegalCases/LegalCases';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { initialisePDFReportSectionsObj, resetPDFReport } from '../../store/pdfReport/pdfReport_actions';
import { REPORT_SECTION_NAMES } from '../../store/pdfReport/pdfReport_constants';
import Button from '../../common/_custom/Button/Button';
import dayjs from 'dayjs';
import AicaOverview from '../../common/AicaOverview/AicaOverview';
import RelatedParties from '../Dashboard/DashboardModules/RelatedParties/RelatedParties';

export const reportSectionsToShow = [
    '',
    REPORT_SECTION_NAMES.COVER,
    REPORT_SECTION_NAMES.CONTENTS,
    REPORT_SECTION_NAMES.OVERVIEW,
    REPORT_SECTION_NAMES.AICA_GRADE,

    REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_INDIVIDUAL,
    REPORT_SECTION_NAMES.FINANCIAL_RATIOS,
    REPORT_SECTION_NAMES.CHARGE_DETAILS,
    REPORT_SECTION_NAMES.EMPLOYEE_TREND_CHART,
    REPORT_SECTION_NAMES.WEBSITE_TRAFFIC_CHART,
    REPORT_SECTION_NAMES.KEY_COMPETITORS,
    REPORT_SECTION_NAMES.SECTOR_OUTLOOK,
    REPORT_SECTION_NAMES.COMPLIANCES_SUMMARY,
    REPORT_SECTION_NAMES.AUDITED_FINANCIALS,
    REPORT_SECTION_NAMES.RELATED_PARTIES,
    REPORT_SECTION_NAMES.LEGAL_CASES,
    REPORT_SECTION_NAMES.REGULATORY_INDICATORS,
];

function AicaPdfReport({ assesseeOrgId = '' }) {
    const { selectedCompany } = useLegacyBusinessDataFetch({
        fetchAicaGrade: true,
        assesseeOrgId,
    });

    const navigate = useNavigate();
    const sample = useRef();
    const params = useParams();
    const dispatch = useAppDispatch();
    const reportRef = useRef(null);

    const [generatingReport, setGeneratingReport] = useState(false);
    const pdfReportState = useAppSelector((state) => state.pdfReportReducer);
    const reportSectionsObj = pdfReportState.reportSectionsObj;

    useEffect(() => {
        if (selectedCompany?.assesseeOrgId) dispatch(initialisePDFReportSectionsObj(reportSectionsToShow, selectedCompany.assesseeOrgId));

        return () => {
            dispatch(resetPDFReport());
        };
    }, [selectedCompany]);

    const generatePDF = async () => {
        const element: any = reportRef.current;
        if (!element) {
            console.error('Element is null');
            return;
        }

        element.style.backgroundColor = '#e7e7e7';
        element.style.opacity = '1';
        const opt = {
            margin: 0,
            filename: 'AICA_Report.pdf',
            html2canvas: { scale: 1.5, useCORS: true, dpi: 50, allowTaint: false },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        try {
            const pdf = await html2pdf().from(element).set(opt).toPdf().get('pdf');
            const totalPages = pdf.internal.pages.length;
            for (let i = 1; i < totalPages; i++) {
                pdf.setPage(i);
                const pageHeight = pdf.internal.pageSize.getHeight();
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageNumber = `${i}`;
                pdf.setFontSize(8);
                pdf.text(pageNumber, pageWidth - 5, pageHeight - 3);

                // if (i === totalPages - 1) {
                //     pdf.setFontSize(12);
                //     pdf.text('--End of Report--', pageWidth / 2, pageHeight / 2, {
                //         align: 'center',
                //     });
                // }
            }
            pdf.save(`${selectedCompany?.orgName}_AI Report.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setGeneratingReport(false);
        }
    };

    const showSectionInReport = (sectionName) => {
        return reportSectionsToShow.includes(sectionName);
    };

    return (
        <Modal
            open={true}
            onClose={() => {
                window.close();
            }}
            size="flexible"
        >
            <div className={styles.MainContainer}>
                {!!reportSectionsObj && (
                    <div className={styles.AicaPDFReportContainer}>
                        <div ref={reportRef}>
                            {showSectionInReport(REPORT_SECTION_NAMES.COVER) && (
                                <A4Page>
                                    <div className={styles.CompanyNameContainer}>
                                        <div className={styles.Box}>
                                            <div className={styles.Name} onClick={generatePDF}>
                                                {selectedCompany?.orgName}
                                            </div>
                                            <div className={styles.Subtext}>AI Evaluation Report</div>

                                            <div className={styles.ReportGen}>Report Generated on: {dayjs().format('DD MMM YYYY')}</div>
                                        </div>
                                    </div>
                                </A4Page>
                            )}

                            {showSectionInReport(REPORT_SECTION_NAMES.CONTENTS) && (
                                <A4Page>
                                    <div className={styles.TableOfContents}>
                                        <div className={styles.Box}>
                                            <div className={styles.Title}>TABLE OF CONTENTS</div>
                                            <div className={styles.Sections}>
                                                {reportSectionsToShow
                                                    .filter((val, index) => index > 2)
                                                    .filter((sectionName) => !!reportSectionsObj?.[sectionName]?.pagesInSection)
                                                    .map((sectionName) => (
                                                        <div className={styles.Section} key={sectionName}>
                                                            <div>{sectionName}</div>
                                                            <div>{/*{reportSectionsObj?.[sectionName]?.pageNum || '-'}*/}</div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </A4Page>
                            )}
                            <Overview
                                selectedCompany={selectedCompany}
                                refetchOverviewRef={sample}
                                appendSearchParams={() => {}}
                                isPDFReport
                            />

                            <Dashboard pdfReportSection={'compliances'} isPDFReport={true} />

                            <A4Page sectionName={'FINANCIALS_OVERVIEW'}>
                                <AicaOverview style={{ margin: '24px 0' }} module={AUDITED_FINANCIALS} isPDFReport />
                            </A4Page>

                            {reportSectionsToShow.includes(REPORT_SECTION_NAMES.AUDITED_FINANCIALS) && (
                                <>
                                    <Financials
                                        selectedFinancials={AUDITED_FINANCIALS}
                                        selectedMetric={'Balance Sheet'}
                                        assesseeOrgId={selectedCompany.assesseeOrgId}
                                    />

                                    <Financials
                                        selectedFinancials={AUDITED_FINANCIALS}
                                        selectedMetric={'Profit And Loss'}
                                        assesseeOrgId={selectedCompany.assesseeOrgId}
                                    />

                                    <Financials
                                        selectedFinancials={AUDITED_FINANCIALS}
                                        selectedMetric={'Cash flow'}
                                        assesseeOrgId={selectedCompany.assesseeOrgId}
                                    />
                                </>
                            )}

                            {reportSectionsToShow.includes(REPORT_SECTION_NAMES.RELATED_PARTIES) && <RelatedParties isPDFReport />}

                            {reportSectionsToShow.includes(REPORT_SECTION_NAMES.LEGAL_CASES) && (
                                <LegalCases
                                    assesseeOrgId={params.id || selectedCompany.assesseeOrgId}
                                    companyName={selectedCompany?.orgName || ''}
                                    isPDFReport
                                />
                            )}

                            <A4Page>
                                <div className={styles.Disclaimer}>
                                    <div className={styles.Title}>Disclaimer</div>
                                    <div className={styles.Text}>
                                        <div>
                                            This Risk Assessment Report ("Report") has been prepared by ("Founderlink Technologies Pvt Ltd
                                            (“Company”)") solely for informational purposes of the Company’s client (“Client”) which is
                                            registered on its proprietary AI credit and monitoring assessment platform (“AICA”). This Report
                                            is intended to assist in assessing potential risks associated with the Client’s third-party
                                            engagements and should not be considered exhaustive or definitive. The Company’s work including
                                            this Report is solely for the Client and is not to be relied upon or shared with any other
                                            person or third party without our prior written permission.
                                        </div>
                                        <div>
                                            Limited Scope: The findings, analyses, and recommendations presented in this Report are based on
                                            information available to the Company, either through publicly available information or through
                                            information provided by the third party being assessed as part of the Client’s engagements
                                            (“Client’s Customer”), at the time of its preparation. The Company has made reasonable efforts
                                            to ensure the accuracy and completeness of the information contained herein, but makes no
                                            representations or warranties, express or implied, regarding the accuracy, reliability, or
                                            completeness of the Report. The Company has no responsibility to update this Report for events
                                            or circumstances occurring after the date of the Report, unless specifically requested for on
                                            mutually acceptable terms.
                                        </div>
                                        <div>
                                            Not Professional Advice: This Report does not constitute legal, accounting, audit, financial or
                                            any other specialist advice or a legal formal due diligence report. Our investigation is not
                                            performed in accordance with generally accepted auditing, review, and other assurance standards.
                                            It is not a substitute for independent professional judgment or analysis. Users of this Report
                                            As a permitted user of this Report, the Client is encouraged to seek appropriate professional
                                            advice tailored to their specific circumstances before making any decisions or taking any
                                            actions based on the information provided herein.
                                        </div>
                                        <div>
                                            Third-Party Information: The Company has relied on information obtained from third parties,
                                            including but not limited to publicly available sources, government databases and data provided
                                            by the third parties' Client’s Customers themselves. While reasonable efforts have been made to
                                            verify the accuracy and reliability of such information, the Company does not assume any
                                            responsibility or liability for the accuracy, completeness, or reliability of third-party
                                            information.
                                        </div>
                                        <div>
                                            Assumptions and Limitations: The assessments and conclusions presented in this Report are based
                                            on certain assumptions, methodologies, and models. These may not accurately reflect all possible
                                            scenarios or outcomes. Users of this Report should be aware of the inherent limitations of the
                                            methodologies employed and exercise caution in their interpretation of the results.
                                        </div>
                                        <div>
                                            Limitation of Liability: In no event shall the Company (and any employee, officer, director, or
                                            affiliate of the Company) be liable for any direct, indirect, incidental, consequential,
                                            special, or punitive damages arising out of or in connection with the use of this Report,
                                            including but not limited to any action taken basis this report, lost profits, loss of business,
                                            or loss of data, even if advised of the possibility of such damages. The maximum liability of
                                            the Company for any claim arising out of or relating to this Report shall not exceed the amount
                                            paid by the user for the Report.
                                        </div>
                                        <div>
                                            Regulatory Compliance: Users of this Report are solely responsible for ensuring compliance with
                                            all applicable laws, regulations, and industry standards governing third-party engagements, risk
                                            management practices, and data protection requirements in India or any other jurisdiction
                                            relevant to their operations.
                                        </div>
                                        <div>
                                            Modification and Update: The Company reserves the right to modify or update this Report at any
                                            time without prior notice. It is the responsibility of users to review the most current version
                                            of the Report for any changes or updates.
                                        </div>
                                        <div>
                                            By accessing or using this Report, users acknowledge that they have read, understood, and agree
                                            to be bound by the terms and conditions set forth in this Disclaimer. If you do not agree with
                                            any part of this Disclaimer, you should refrain from accessing or using this Report.
                                        </div>
                                    </div>
                                </div>
                            </A4Page>
                        </div>
                    </div>
                )}
                <div className={styles.Buttons}>
                    <div className={styles.Text}>Please ensure all sections are generated before exporting PDF</div>
                    <Button
                        text={'Export PDF'}
                        onClick={() => {
                            setGeneratingReport(true);
                            generatePDF();
                        }}
                        style={{ margin: 0 }}
                        loading={generatingReport}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default AicaPdfReport;
