import { PASSWORD_PROTECTION_STATUS } from '../../../Utils/PasswordProtectionUtils';
import styles from '../MagicUploadFilesTable.module.scss';
import { ErrorStateFileIconMU, fileIconDataRoom } from '../../../../../assets/hostedassets';
import EnterPassword from '../../EnterPassword/EnterPassword';
import { getSourceIcon, SourceType } from '../../../Utils/MagicUploadUtils';
import AIText from '../../../../AIText/AIText';
import ThreeDotsIcon from '../../../../../assets/Svg/ThreeDotsIcon';
import React from 'react';
import { MagicUploadFileData } from '../../../useFetchDataForMU';

type MagicUploadRowsProps = {
    file: any;
    index: number;
    isCompany: boolean;
    setDocIdToView: (docId: string) => void;
    setDocName: (docName: string) => void;
    submitPassword: (password: string, file: MagicUploadFileData) => Promise<boolean>;
    setUpdatedFileData: (fileData: any[]) => void;
    loaderWidth: { [key: string]: number };
    getLoadingText: (file: any) => string;
    getOrgName: (orgName: string, file: any, index: number) => React.ReactNode;
    getDocType: (docType: string, file: any, index: number, isCompany?: boolean) => React.ReactNode;
    renderThreeDotsOptions: (file: any) => React.ReactNode;
    dropdownRef: React.RefObject<HTMLTableCellElement>;
    handleShowOptions: (index: number) => void;
    showOptions: number | null;
};

const MagicUploadRows = (props: MagicUploadRowsProps) => {
    const {
        file,
        index,
        isCompany,
        setDocIdToView,
        setDocName,
        submitPassword,
        setUpdatedFileData,
        loaderWidth,
        getLoadingText,
        getOrgName,
        getDocType,
        renderThreeDotsOptions,
        dropdownRef,
        handleShowOptions,
        showOptions,
    } = props;

    if (
        [
            PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED,
            PASSWORD_PROTECTION_STATUS.PASSWORD_INVALID,
        ].includes(file?.passwordProtectionStatus ?? '')
    ) {
        return (
            <tr
                style={{ position: 'relative', cursor: 'pointer' }}
                className={styles.MURow}
                key={`${file.fileName}-${index}`}
            >
                <td title={file.fileName}>
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.25rem',
                        }}
                    >
                        <img src={ErrorStateFileIconMU} alt={'File Icon'} />
                        <span style={{ color: '#DF666A' }} className={styles.TruncatedText}>
                            {file.fileName}
                        </span>
                    </span>
                </td>
                <td>
                    <EnterPassword
                        key={file.id}
                        submitPassword={submitPassword}
                        setUpdatedFileData={setUpdatedFileData}
                        file={file}
                    />
                </td>
                {!isCompany && <td style={{ width: '310px' }}></td>}
                <td>
                    <span>{getSourceIcon(file.source as SourceType)}</span>
                </td>
                <td>
                    <span title={file.uploadedBy} className={styles.TruncateText}>
                        {file.uploadedBy}
                    </span>
                </td>
                <td>
                    <span>{file.lastUpdated}</span>
                </td>
            </tr>
        );
    }
    if (file.status !== 'tagged' && file.status !== 'pre-tagged') {
        return (
            <tr
                key={`${file.fileName}-${index}`}
                className={styles.MURow}
                style={{ position: 'relative' }}
            >
                <td title={file.fileName}>
                    <div
                        style={{ width: `${loaderWidth[file.id]}%` }}
                        className={styles.ProgressBackground}
                    />
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.25rem',
                        }}
                    >
                        <span className={styles.TruncatedText}>{file?.fileName}</span>
                    </span>
                </td>
                {!isCompany && (
                    <td style={{ width: '310px' }}>
                        <span style={{ width: '100%' }}>
                            {file?.orgName ? (
                                getOrgName(file?.orgName ?? '', file, index)
                            ) : (
                                <AIText
                                    text={getLoadingText(file)}
                                    style={{
                                        marginLeft: '0.5rem',
                                        fontFamily: 'Gilroy-Semibold',
                                        fontSize: '0.875rem',
                                    }}
                                    loadingDotsStyle={{
                                        color: 'var(--primary-text-colour)',
                                        fontSize: '0.875rem',
                                    }}
                                    showLoadingDots
                                />
                            )}
                        </span>
                    </td>
                )}
                <td style={{ width: '310px' }}>
                    <span style={{ width: '100%' }}>
                        {file.docType
                            ? getDocType(file?.docType ?? '', file, index, true)
                            : isCompany && (
                                  <AIText
                                      text={getLoadingText(file)}
                                      style={{
                                          marginLeft: '0.5rem',
                                          fontFamily: 'Gilroy-Semibold',
                                          fontSize: '0.875rem',
                                      }}
                                      loadingDotsStyle={{
                                          color: 'var(--primary-text-colour)',
                                          fontSize: '0.875rem',
                                      }}
                                      showLoadingDots
                                  />
                              )}
                    </span>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        );
    }
    return (
        <tr
            style={{ position: 'relative', cursor: 'pointer' }}
            className={styles.MURow}
            key={`${file.fileName}-${index}`}
        >
            <td
                onClick={(e) => {
                    e.stopPropagation();
                    setDocIdToView(file.docId.toString());
                    setDocName(file.fileName);
                }}
                title={file.fileName}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.25rem',
                    }}
                >
                    <img src={fileIconDataRoom} alt={'File Icon'} />
                    <span className={styles.TruncatedText}>{file.fileName}</span>
                </span>
            </td>
            {!isCompany && (
                <td style={{ width: '100%' }}>{getOrgName(file?.orgName ?? '', file, index)}</td>
            )}
            <td style={{ width: '100%' }}>{getDocType(file?.docType ?? '', file, index, false)}</td>
            <td>
                <span>{getSourceIcon(file.source as SourceType)}</span>
            </td>
            <td>
                <span title={file.uploadedBy} className={styles.TruncateText}>
                    {file.uploadedBy}
                </span>
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
                <span>{file.lastUpdated}</span>
            </td>
            <td
                ref={dropdownRef}
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={(e) => {
                    e.stopPropagation();
                    handleShowOptions(index);
                }}
            >
                <ThreeDotsIcon
                    colour={'var(--primary-text-colour)'}
                    style={{ cursor: 'pointer' }}
                    height="14"
                    width="14"
                />
                {showOptions === index && (
                    <div className={styles.MoreOptions}>{renderThreeDotsOptions(file)}</div>
                )}
            </td>
        </tr>
    );
};

export default MagicUploadRows;
