import { toTitleCase } from '../../../../../utils/utils';
import { getBannerStatusColors, SEVERITY_DEG } from '../relatedParty_constants';
import { alertsConst } from './relatedPartyAlerts';
import styles from './RelatedPartiesAlerts.module.scss';

const headers = [
    {
        key: 'risk',
        label: 'Risk',
    },
    {
        key: 'description',
        label: 'Description',
    },
    {
        key: 'category',
        label: 'Category',
    },
];

function RelatedPartiesAlerts({ data }: any) {
    const alerts = data;

    const sortBySeverity = (alerts) => {
        return alerts.sort((a, b) => {
            return SEVERITY_DEG[b.risk?.toUpperCase()] - SEVERITY_DEG[a.risk?.toUpperCase()];
        });
    };

    const updatedAlerts = alerts
        ?.reduce((acc, alert) => {
            if (!acc.find((element) => element?.alert === alert?.alert)) acc.push(alert);

            return acc;
        }, [])
        ?.map((alert) => ({
            ...alert,
            risk: alertsConst[alert?.alert]?.severity || '-',
            description: alertsConst[alert?.alert]?.description,
            category: alertsConst[alert?.alert]?.category || '-',
        }));
    sortBySeverity(updatedAlerts);

    return (
        <div className={styles.RelatedPartiesAlerts}>
            <div className={styles.Heading}>
                <span className={styles.Top}>Alerts</span>
                {data?.name && <span className={styles.Name}>&nbsp;|&nbsp;{toTitleCase(data?.name)}</span>}
            </div>

            <div className={styles.Content}>
                <div className={styles.Count}>Showing {alerts?.length} records</div>

                <div className={styles.Table}>
                    <div className={styles.Headers}>
                        {headers?.map((header, j) => (
                            <div className={styles.Header} key={j}>
                                {header.label}
                            </div>
                        ))}
                    </div>

                    <div className={styles.Rows}>
                        {updatedAlerts?.map((alert, i) => (
                            <div className={styles.Row} key={i}>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: getBannerStatusColors(alert?.risk?.toUpperCase()).banner,
                                            color: getBannerStatusColors(alert?.risk?.toUpperCase()).color,
                                        }}
                                        className={styles.Risk}
                                    >
                                        {alert?.risk?.toUpperCase()}
                                    </div>
                                </div>
                                <div className={styles.Desc}>{alert?.description || alert?.alert || '-'}</div>
                                <div>
                                    <div className={styles.Category}>{alert?.category}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RelatedPartiesAlerts;
