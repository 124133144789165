import { FC, useState } from 'react';
import classNames from 'classnames';
import styles from './CollapsableDropdown.module.scss';

interface CollapsableDropdownProps {
    children: React.ReactNode;
    className?: string;
    contentClassName?: string;
    triggerContent: (isExpanded: boolean) => React.ReactNode;
    isExpanded?: boolean;
    onToggle?: (isExpanded: boolean) => void;
}

export const CollapsableDropdown: FC<CollapsableDropdownProps> = ({
    children,
    className,
    contentClassName,
    triggerContent,
    isExpanded: controlledIsExpanded,
    onToggle,
}) => {
    const [internalIsExpanded, setInternalIsExpanded] = useState(false);

    // Use controlled or uncontrolled expanded state
    const isExpanded = controlledIsExpanded !== undefined ? controlledIsExpanded : internalIsExpanded;

    const toggleDropdown = () => {
        const newExpandedState = !isExpanded;
        setInternalIsExpanded(newExpandedState);
        onToggle?.(newExpandedState);
    };

    return (
        <div className={classNames(styles.dropdown, className)}>
            <div
                className={classNames(styles.trigger, { [styles.expanded]: isExpanded })}
                onClick={toggleDropdown}
                role="button"
                tabIndex={0}
            >
                <svg
                    className={classNames(styles.arrow, { [styles.expanded]: isExpanded })}
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M1 1.5L6 6.5L11 1.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {triggerContent(isExpanded)}
            </div>
            <div className={classNames(styles.content, { [styles.expanded]: isExpanded }, contentClassName)}>{children}</div>
        </div>
    );
};
