import axios from 'axios';
import {
    aicaBaseUrl,
    coreBaseUrl,
    mnemosyneBaseUrl,
    POST,
    PUT,
} from '../../../../../../../utils/axios-interceptor';

export const getSignedUrlApi = (data: any) => {
    const axiosPayload = {
        method: POST,
        baseURL: coreBaseUrl,
        url: `/investeeonboarding/s3/sign`,
        data: data,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const uploadFileApi = (url: string, file: any) => {
    const requestOptions = {
        method: 'put',
        body: file,
        headers: new Headers({
            'Content-Type': file.type || 'application/pdf',
        }),
    };
    return fetch(url, {
        method: PUT,
        body: file,
        headers: new Headers({
            'Content-Type': file.type || 'application/pdf',
        }),
    });
};

export const fetchSignedUrlToUpload = (input: object, orgId: string): any => {
    const payload = {
        method: POST,
        baseURL: mnemosyneBaseUrl,
        url: `/file/signedUrl`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const saveMetaDataInDocService = (input: object, orgId: string): any => {
    const payload = {
        method: POST,
        url: `${mnemosyneBaseUrl}/file/saveMetadata`,
        data: input,
        headers: {
            'organization-id': orgId,
        },
        isAuthRequired: true,
    };
    return axios(payload);
};

export const downloadFilePresigned = (docId: number, markAsDownloaded: boolean = false): any => {
    const payload = {
        method: POST,
        url: `${mnemosyneBaseUrl}/file/downloadFilePresigned?fileId=${docId}&markAsDownloaded=${markAsDownloaded}&byOrgId=${
            localStorage.getItem('user_org_id') || ''
        }`,
        isAuthRequired: true,
    };
    return axios(payload);
};

export const downloadFileUsingURL = (url: string, bucketName: string) => {
    return axios({
        url: `${mnemosyneBaseUrl}/file/downloadSignedUrlForPath`,
        method: POST,
        data: { path: url, bucketName: bucketName },
    });
};

export const saveKycDocs = async (data: any) => {
    return axios({
        url: `${aicaBaseUrl}/data/org/updateKycData`,
        method: POST,
        data,
    });
};

export const getDocMetaData = (docId: string): any => {
    const payload = {
        method: POST,
        url: `${mnemosyneBaseUrl}/file/get-file-meta-data?fileId=${docId}`,
        isAuthRequired: true,
    };
    return axios(payload);
};
