import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Modal } from '../../../../../common/_custom';

import styles from './PortfolioSuccess.module.scss';

type Props = {
    isOpen: boolean;
};

const PortfolioSuccess: React.FC<Props> = ({ isOpen }) => {
    const [queryParams, setQueryParams] = useSearchParams();
    const navigate = useNavigate();

    const onClick = () => {
        queryParams.delete('portfolioSuccess');
        setQueryParams(queryParams);
        navigate('/investor/app/monitor');
    };

    return (
        <Modal open={isOpen} size="small">
            <div className={styles.PortfolioSuccess}>
                <div className={styles.SuccessAnimation}>
                    <img
                        src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/gif/Successfull.gif'}
                        className={styles.SuccessAnimationImage}
                    />
                </div>
                <h2 className={styles.Title}>Successfully added to Portfolio!</h2>
                <Button text="View in Portfolio" onClick={onClick} variant="purple" />
            </div>
        </Modal>
    );
};

export default PortfolioSuccess;
