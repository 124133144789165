import { Button, Input, Modal } from '../_custom';
import { useEffect, useRef, useState } from 'react';
import { COUNTRY_CODE, EMAIL, NAME, PHONE, WEBSITE, TRIGGER_NOTIFICATION, ORG_DISPLAY_NAME } from '../../utils/constants/companyLogin';
import styles from './InviteTeammateModal.module.scss';
import CountryCodesDropdown from '../../modules/Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import Dropdown from '../_custom/Dropdown';
import { INVITE_SCREENS } from '../../modules/Dashboard/components/InviteCompanyModal/InviteCompanyModal';
import { usersTypeDropdownList } from '../../utils/constants/investorSignup';
import { useDispatch } from 'react-redux';
import { createAccountV2 } from '../../store/user/userV2/actions';
import { USER_TYPE } from '../../utils/constants/user';
import { useAppSelector } from '../../app/hooks';
import { toast } from 'react-toastify';
import { isEmpty, setRolesForUser } from '../../utils/utils';
import { Mixpanel } from '../../utils/mixpanel';
import { AICA_INVITE_USER } from '../../utils/constants/mixpanelEvents/investorEvents';
import { fetchOwners, GetOwnerDataPayload } from '../../store/investor/action';
import { useSearchParams } from 'react-router-dom';
import { MANAGE_USERS_TABS, USER_TYPES } from '../../store/investor/constants';
import CheckBox from '../../common/CheckBox/CheckBox';
import CompanyLegalNameSearch, { CompanyData } from '../CompanyLegalNameSearch/CompanyLegalNameSearch';
const { PARTNER, INVESTOR, TEAM } = MANAGE_USERS_TABS;

interface InviteTeammateModalProps {
    isOpen: boolean;
    onClose: () => void;
    fetchRows: (pgNo: number) => Promise<void>;
    pageNo: number;
    getDynamicHeaders?: () => void;
    heading?: string;
}

const InviteTeammateModal = ({ isOpen, onClose, fetchRows, getDynamicHeaders, pageNo, heading = 'Add User' }: InviteTeammateModalProps) => {
    const USER_TYPES = 'userType';
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const userData = useAppSelector((state) => state.user.userData);
    const companyPickedFromDropdown = useRef<CompanyData | null>(null);
    const ORG_NAME = 'orgName';
    const [loading, setLoading] = useState(false);
    const [userTypeDropdownList, setUserTypeDropdownList] = useState(usersTypeDropdownList);
    const [searchParams, setSearchParams] = useSearchParams();
    const getUserType = (userType) => {
        switch (userType) {
            case TEAM:
                return userData.accountType === USER_TYPE.ADMIN_TEAM
                    ? {
                          id: 'ADMIN_TEAM',
                          label: 'Team Member',
                      }
                    : {
                          id: 'INVESTOR',
                          label: 'Admin',
                      };
            case PARTNER:
                return {
                    id: 'PARTNER',
                    label: 'Partner',
                };
            case INVESTOR:
                return {
                    id: 'LENDER',
                    label: 'Investor',
                };
            default:
                return '';
        }
    };

    const [formData, setFormData] = useState<any>({
        [NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
        [USER_TYPES]: getUserType(searchParams.get('category')?.toString()),
        [ORG_NAME]: '',
        [ORG_DISPLAY_NAME]: '',
        [TRIGGER_NOTIFICATION]: false,
    });
    const [formErrors, setFormErrors] = useState({
        [NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: '',
        [ORG_NAME]: '',
    });

    const disabled = !(
        formData[NAME] &&
        formData[EMAIL] &&
        formData[USER_TYPES] &&
        ([USER_TYPE.LENDER, USER_TYPE.PARTNER].includes(formData[USER_TYPES].id) ? formData[ORG_DISPLAY_NAME] : true) &&
        ([USER_TYPE.LENDER, USER_TYPE.PARTNER].includes(formData[USER_TYPES].id) ? formData[ORG_NAME] : true)
    );
    useEffect(() => {
        if (user.userData.accountType === 'ADMIN_TEAM') {
            setUserTypeDropdownList(userTypeDropdownList.filter((item) => item.id !== 'INVESTOR'));
        }
    }, [user]);
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            [USER_TYPES]: getUserType(searchParams.get('category')?.toString()),
        }));
    }, [searchParams]);
    const handleFormChange = (val, identifier: string) => {
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
        if (formErrors?.[identifier])
            setFormErrors((errors) => ({
                ...errors,
                [identifier]: '',
            }));
    };

    const fetchOwnerDetails = () => {
        let payload: GetOwnerDataPayload = {
            investorOrgId: userData.investorOrgId,
        };
        dispatch(fetchOwners(payload));
    };

    const onInviteUserClick = () => {
        setLoading(true);
        const investorId = user?.userData?.investorOrgId;
        const roleName = setRolesForUser(user?.userData?.roles?.[0]);
        const selectedUserType = formData[USER_TYPES];
        if (formData[PHONE] && formData[PHONE].length < 10) {
            toast.error('Enter a valid phone number');
            setLoading(false);
            return;
        }

        //Replace ORG_DISPLAY_NAME with ORG_NAME when ORG_DISPLAY_NAME is Empty
        if (!formData[ORG_DISPLAY_NAME] && formData[ORG_NAME]) {
            setFormData((prevData) => ({
                ...prevData,
                [ORG_DISPLAY_NAME]: formData[ORG_NAME],
            }));
        }

        dispatch(
            createAccountV2(
                {
                    user: {
                        name: formData[NAME],
                        accountTypes: [USER_TYPE.INVESTOR, USER_TYPE.PARTNER, USER_TYPE.LENDER, USER_TYPE.ADMIN_TEAM],
                        emailId: formData[EMAIL],
                        mobileNumber: formData[PHONE],
                        inviterEmailId: user?.userData?.primaryEmail,
                        countryCode: formData[COUNTRY_CODE]?.countryCode,
                        organizationName: isEmpty(formData[ORG_NAME]) ? '' : formData[ORG_NAME],
                        orgDisplayName: formData[ORG_DISPLAY_NAME],
                        accountType: selectedUserType.id,
                        investorId: investorId,
                        countryId: formData[COUNTRY_CODE].countryId,
                        journeyName:
                            selectedUserType.id === USER_TYPE.LENDER
                                ? 'INVITE_LENDER'
                                : selectedUserType.id === USER_TYPE.ADMIN_TEAM
                                ? 'INVITE_TEAM_MEMBER'
                                : 'INVITE_PARTNER',
                        triggerNotification: formData[TRIGGER_NOTIFICATION],
                    },
                    platformId: user.domainMetadata?.productName ?? '',
                },
                {
                    isInviteCall: true,
                    onSuccess: () => {
                        const currentCountryCode = formData[COUNTRY_CODE];
                        fetchOwnerDetails();
                        toast.success('User invited successfully');
                        setLoading(false);
                        setFormData({
                            [NAME]: '',
                            [EMAIL]: '',
                            [PHONE]: '',
                            [COUNTRY_CODE]: currentCountryCode,
                            [USER_TYPES]: getUserType(searchParams.get('category')?.toString()),
                            [ORG_NAME]: '',
                            [ORG_DISPLAY_NAME]: '',
                            [TRIGGER_NOTIFICATION]: false,
                        });
                        onClose();
                        fetchRows && fetchRows(pageNo);
                        getDynamicHeaders && getDynamicHeaders();
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            ),
        );
        Mixpanel.track(AICA_INVITE_USER, {
            Role: roleName,
            PartnetAccessType: selectedUserType?.label,
            InvestorId: investorId,
            websiteUrl: window.location.href,
        });
    };

    return (
        <Modal open={isOpen} onClose={onClose} size="flexible" persistent style={{ padding: 0 }}>
            <div className={styles.ModalBody}>
                <h2>{heading}</h2>
                <Input
                    value={formData[NAME]}
                    onChange={(val) => handleFormChange(val, NAME)}
                    placeholder="Full Name"
                    style={{ marginTop: '30px' }}
                    isValid={!formErrors[WEBSITE]}
                    errorMessage={formErrors[WEBSITE]}
                />
                <Input
                    value={formData[EMAIL]}
                    onChange={(val) => handleFormChange(val, EMAIL)}
                    placeholder="Email ID"
                    style={{ marginTop: '30px' }}
                    isValid={!formErrors[EMAIL]}
                    errorMessage={formErrors[EMAIL]}
                />
                <div className={styles.Row}>
                    <CountryCodesDropdown
                        //@ts-ignore
                        style={{ fontFamily: 'Gilroy-Medium' }}
                        setCountryCode={(val) => handleFormChange(val, COUNTRY_CODE)}
                    />
                    <Input
                        placeholder="Phone Number"
                        value={formData[PHONE]}
                        onChange={(value) => {
                            if (/^\d*$/.test(value) && value.length <= 10) {
                                handleFormChange(value, PHONE);
                            }
                        }}
                        style={{ marginTop: '0', width: '100%' }}
                        type="number"
                        isValid={!formErrors[PHONE]}
                        errorMessage={formErrors[PHONE]}
                    />
                </div>
                {heading !== 'Add Teammate' && (
                    <>
                        <CompanyLegalNameSearch
                            placeholder={'MCA Registered Entity Name'}
                            value={formData[ORG_NAME]}
                            onChange={(value) => handleFormChange(value, ORG_NAME)}
                            errorMessage={formErrors[ORG_NAME]}
                            isValid={!formErrors[ORG_NAME]}
                            companyPickedFromDropdown={companyPickedFromDropdown}
                            style={{ marginTop: '30px' }}
                            containerStyle={{ marginBottom: 0 }}
                            optionsStyle={{
                                width: '100%',
                                maxHeight: '150px',
                                marginTop: '0.5rem',
                            }}
                        />
                        <Input
                            value={formData[ORG_DISPLAY_NAME]}
                            onChange={(val) => handleFormChange(val, ORG_DISPLAY_NAME)}
                            placeholder={'Organisation Name'}
                            style={{ marginTop: '30px' }}
                        />
                    </>
                )}
                <CheckBox
                    label={'Send invite on email'}
                    checked={formData[TRIGGER_NOTIFICATION]}
                    onCheck={(val) => handleFormChange(val, TRIGGER_NOTIFICATION)}
                    variant={'tertiary'}
                    style={{ padding: '1.5rem 0 0rem 0.25rem' }}
                />
            </div>
            <div className={styles.Bottom}>
                <Button
                    onClick={onInviteUserClick}
                    text={'Submit'}
                    variant="purple"
                    disabled={disabled}
                    loading={loading}
                    style={{ width: '100%' }}
                />
            </div>
        </Modal>
    );
};

export default InviteTeammateModal;
