import clsx from 'clsx';
import React from 'react';

import styles from './DealsHeader.module.scss';
import { useAppSelector } from '../../../../../../../../app/hooks';
import { USER_TYPE } from '../../../../../../../../utils/constants/user';
import { HeaderConfig } from '../../DealsTable';

interface Props {
    headers: HeaderConfig[] | null;
}

const DealsHeader: React.FC<Props> = ({ headers }) => {
    const user = useAppSelector((state) => state.user);
    const userType = user.userData.accountType;

    return (
        <tr className={styles.Headers}>
            {headers
                ? headers.map((header, i) => (
                      <th
                          key={header.id}
                          className={clsx({
                              [styles.HeadMain]: i === 0,
                              [styles.HeadItem]: i !== 0,
                              [styles.HideLabel]: header?.hideLabel,
                              [styles.WideColumn]: header.id === 'borrowerName',
                              [styles.CommentColumn]: header.id === 'comment',
                              [styles.CollapsibleHeader]: header.id === 'collapsible',
                              [styles.FirstHeadRowItem]: header.id === 'borrowerName' && userType != USER_TYPE.LENDER,
                              [styles.LenderHeadersHeadRowItem]:
                                  (header.id === 'sharedDate' || header.id === 'borrowerName') && userType === USER_TYPE.LENDER,
                              [styles.FirstDrawdownRowItem]: header.id === 'collapsible',
                              [styles.StickyActions]: header.id === 'actions',
                              [styles.DealTermsHeader]: header.id === 'blankCol',
                          })}
                      >
                          {header.label}
                      </th>
                  ))
                : null}
        </tr>
    );
};

export default DealsHeader;
