import React, { MouseEventHandler } from 'react';
import { Button, Modal } from '../../../../../../../../common/_custom';
import styles from './ConfirmationModal.module.scss';

type ConfirmationModalProps = {
    showModal: {
        closeModal: boolean;
    };
    onClose: () => void;
    onClickSave: MouseEventHandler<HTMLButtonElement>;
    onClickNoSave: MouseEventHandler<HTMLButtonElement>;
    isInvestor: boolean;
    heading?: string;
    subHeading?: string;
    saveCTAText?: string;
    noSaveCTAText?: string;
};

function ConfirmationModal(props: ConfirmationModalProps) {
    const {
        showModal,
        onClose,
        onClickNoSave,
        onClickSave,
        isInvestor,
        noSaveCTAText,
        saveCTAText,
        subHeading,
        heading,
    } = props;
    return (
        <Modal
            className={styles.MapDataModal}
            open={showModal.closeModal}
            style={{ padding: '2rem' }}
            onClose={onClose}
            size="flexible"
            persistent={true}
        >
            <div className={styles.ModalBody}>
                <div style={{ padding: '1rem 0' }}>
                    <h2>{heading ?? 'Are you sure you want to close?'}</h2>
                    <p>{subHeading ?? 'You still have unsaved mapping in your selected range.'}</p>
                </div>
                <div className={styles.ButtonContainer}>
                    <Button
                        variant={'purpleinverted'}
                        style={{ marginTop: '0', width: '100%' }}
                        text={noSaveCTAText ?? 'Continue Without Saving'}
                        type={'button'}
                        onClick={onClickNoSave}
                    />
                    <Button
                        variant={'primary'}
                        style={{ marginTop: '0', width: '100%' }}
                        type={'button'}
                        onClick={onClickSave}
                        text={saveCTAText ?? 'Save Mapping'}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;
