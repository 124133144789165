import { REPORT_NAVIGATION, REPORT_TAB_STATES } from '../../TabConstants';
import clsx from 'clsx';
import styles from './ReportNavigation.module.scss';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import greyLock from '../../../../../../assets/grey-lock-2.svg';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { AICA_DATA_ROOM_OPENED } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { useAppSelector } from '../../../../../../app/hooks';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { ACCESS_TIERS } from '../../../../../../store/investor/constants';
import { updateQueryParams } from '../../../../../../utils/searchParamUtils';

const REPORT_SECTIONS = {
    'debt-products': 'debtProducts',
    portfolio: 'aicaReport',
    'data-room': 'dataRoom',
    'company-deals': 'deals',
};

const ReportNavigation = ({ selectedCompany }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const [tabHovered, setTabHovered] = useState<string>('');
    const [tabSelected, setTabSelected] = useState<string>('');

    const dashboardConfig = useAppSelector((state) => state.dashboard.dashboardReducer.config);
    const reportTabs = dashboardConfig?.additionalDetailsConfig?.companyTabs;

    const companyName = useAppSelector((state) => state.investor.selectedCompany)?.orgName;
    const user = useAppSelector((state) => state.user.userData);
    const userEmail = user.primaryEmail;
    const userName = user.userName;
    const isPartner = user?.accountType === USER_TYPE.PARTNER;
    const isLender = user?.accountType === USER_TYPE.LENDER;
    const filteredTabs = isPartner
        ? reportTabs?.filter((tab) => tab.id === 'dataRoom')
        : reportTabs?.filter((tab) => !(tab.id === 'deals' && (isPartner || isLender)));

    const tabsWithLockStatusUpdate = filteredTabs?.map((tab) => ({
        ...tab,
        state:
            tab.id === 'dataRoom' && selectedCompany?.companyAccessType === ACCESS_TIERS.WATCH
                ? 'LOCKED'
                : ['debtProducts', 'deals'].includes(tab.id) && user?.accountType === USER_TYPE.LENDER
                ? 'HIDDEN'
                : tab?.state,
    }));

    const currentReportSection = location.pathname?.split('/')?.[3];

    const sliderRef = useRef<HTMLDivElement | null>(null);
    const tabRefs = useRef<Record<string, HTMLDivElement | null>>({});

    useEffect(() => {
        //* This is for preventing unauthorised access to report tabs
        if (currentReportSection && tabsWithLockStatusUpdate) {
            if (!(tabsWithLockStatusUpdate?.find((tab) => tab.id === REPORT_SECTIONS[currentReportSection])?.state === 'UNLOCKED'))
                navigate(-1);
        }
    }, [currentReportSection, tabsWithLockStatusUpdate]);

    useEffect(() => {
        if (searchParams.get('tab')) {
            setTabSelected(searchParams.get('tab') as string);
        } else {
            updateQueryParams(searchParams, setSearchParams, { tab: 'aicaReport' }, location);
        }
    }, [searchParams]);

    useEffect(() => {
        const matchedTab = Object.keys(REPORT_NAVIGATION).find((key) => location.pathname.startsWith(REPORT_NAVIGATION[key]));
        if (matchedTab && matchedTab !== tabSelected) {
            setTabSelected(matchedTab);
            updateQueryParams(searchParams, setSearchParams, { tab: matchedTab }, location);
        }
    }, [location.pathname]);

    const updateSliderPosition = () => {
        if (tabRefs.current[tabSelected] && sliderRef.current) {
            const selectedTab = tabRefs.current[tabSelected];
            if (selectedTab) {
                sliderRef.current.style.left = `${selectedTab.offsetLeft}px`;
                sliderRef.current.style.width = `${selectedTab.offsetWidth}px`;
            }
        }
    };

    useEffect(() => {
        updateSliderPosition();
    }, [tabSelected, tabsWithLockStatusUpdate]);

    const trackMixpanel = () => {
        Mixpanel.track(AICA_DATA_ROOM_OPENED, {
            userEmail,
            companyName,
            userName,
        });
    };

    return (
        <>
            <div className={styles.TabSlider} ref={sliderRef}></div>
            {tabsWithLockStatusUpdate?.map(
                (tab) =>
                    tab.state !== REPORT_TAB_STATES.HIDDEN && (
                        <div
                            key={tab?.id}
                            ref={(el) => (tabRefs.current[tab.id] = el)}
                            className={clsx({
                                [styles.TabContainer]: true,
                                [styles.TabContainerHovered]: tab.id === tabHovered && tab?.state === REPORT_TAB_STATES.UNLOCKED,
                                [styles.TabContainerSelected]: tab?.id === tabSelected,
                                [styles.DisabledTab]: tab?.state === REPORT_TAB_STATES.LOCKED,
                            })}
                            onClick={() => {
                                if (tab?.state === REPORT_TAB_STATES.LOCKED) return;
                                if (tab?.id === 'dataRoom') trackMixpanel();
                                setTabSelected(tab?.id);
                                updateQueryParams(searchParams, setSearchParams, { tab: tab?.id }, location);

                                navigate(`${REPORT_NAVIGATION?.[tab?.id]}/${params.id ?? ''}?${searchParams.toString()}`, {
                                    state: location.state,
                                });
                            }}
                            onMouseEnter={() => setTabHovered(tab?.id)}
                            onMouseLeave={() => setTabHovered('')}
                        >
                            <div
                                className={clsx({
                                    [styles.TabTextContainerHovered]: tab?.id === tabHovered && tab?.state === REPORT_TAB_STATES.UNLOCKED,
                                    [styles.TabTextContainerSelected]: tab?.id === tabSelected,
                                    [styles.TabTextContainer]: true,
                                })}
                            >
                                <img
                                    src={
                                        tab.id === tabSelected || (tab.id === tabHovered && tab?.state === REPORT_TAB_STATES.UNLOCKED)
                                            ? tab?.selectedIcon
                                            : tab?.icon
                                    }
                                    alt={tab?.title}
                                />
                                <span>{tab?.title}</span>
                                {tab?.state === REPORT_TAB_STATES.LOCKED && <img src={greyLock} alt="lock" height="20px" width="20px" />}
                            </div>
                        </div>
                    ),
            )}
        </>
    );
};

export default ReportNavigation;
