import React, { useState } from 'react';
import { fetchRelatedPartiesData } from '../../../../store/dashboard/relatedParties/relatedParties_apiHelper';
import styles from './RelatedParties.module.scss';
import { caretRightPurple, infoIconGray } from '../../../../assets/hostedassets';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import Tooltip from '../../../../common/_custom/Tooltip/Tooltip';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import RelatedPartiesAlerts from './RelatedPartiesAlerts/RelatedPartiesAlerts';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { AION_INGEST_RELATED_PARTIES_DATA, getEdgeTypeLabels, getRelatedPartyHeaders } from './relatedParty_constants';
import A4Page from '../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../store/pdfReport/pdfReport_constants';
import FirstDegreeRow from './components/FirstDegreeRow/FirstDegreeRow';
import SecondDegreeRow from './components/SecondDegreeRow/SecondDegreeRow';
import { useRelatedPartiesDataFetch } from './useRelatedPartiesDataFetch';
import { useRelatedPartyPolling } from './useRelatedPartiesPolling';

function RelatedParties({ isPDFReport = false }) {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const { startPolling, stopPolling } = useRelatedPartyPolling(fetchRelatedParties, setStatus, setLoading);

    const {
        // loading,
        expandAll,
        // status,
        showAlerts,
        relatedPartyData,
        relatedPartyGroupedData,

        //FUNCTIONS
        // setLoading,
        // setStatus,
        isDataEmpty,
        setRelatedPartyData,
        handleAlertsClick,
        handleShowRelationsClick,
        toggleExpandCollapseAll,
        setShowAlerts,
    } = useRelatedPartiesDataFetch(isPDFReport, fetchRelatedParties);

    function fetchRelatedParties(isPollingCall = false) {
        if (!isPollingCall) setLoading(true);

        fetchRelatedPartiesData({
            orgId: params.id,
            taskStepsList: [AION_INGEST_RELATED_PARTIES_DATA],
        })
            .then((data) => {
                const relatedPartiesData = data?.['UW_FETCH_RELATED_PARTIES'];
                const taskTrackerData = data?.['AICA_GET_TASK_TRACKER_DATA']?.taskTrackerData;

                if (relatedPartiesData?.data) {
                    setRelatedPartyData(relatedPartiesData);
                }

                if (
                    !(AION_INGEST_RELATED_PARTIES_DATA in taskTrackerData) ||
                    ['TODO', 'TODO_ASYNC'].includes(taskTrackerData?.[AION_INGEST_RELATED_PARTIES_DATA])
                ) {
                    setStatus('PROCESSING');
                    startPolling();
                } else {
                    setStatus('COMPLETE');
                    stopPolling();
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    }
    const renderHeaders = () => (
        <tr className={styles.Headers}>
            {getRelatedPartyHeaders(isPDFReport).map((header, i) => (
                <th
                    className={clsx({
                        [styles.Header_FromName]: header.key === 'relatedPartyName' || header.key === 'relationTo',
                        [styles.Header_ToName]: header.key === 'relationTo',
                        [styles.Header_Relation]: header.key === 'relation',
                        [styles.Header_Risk]: header.key === 'risk',
                        [styles.Header_Alerts]: header.key === 'alerts',
                    })}
                    key={header.key}
                >
                    <div className={clsx(styles.Header)}>
                        {header.label}
                        {header.tooltip && !isPDFReport && (
                            <Tooltip content={header.tooltip} direction="bottom" style={{ width: '250px' }}>
                                <img
                                    src={infoIconGray}
                                    alt="info"
                                    className={styles.TooltipIcon}
                                    style={{
                                        marginLeft: '5px',
                                        marginTop: '4px',
                                        transform: 'rotate(180deg)',
                                    }}
                                    height={'15px'}
                                />
                            </Tooltip>
                        )}

                        {header.key === 'relatedPartyName' && !isPDFReport && (
                            <div className={styles.ExpandButton} onClick={toggleExpandCollapseAll}>
                                {expandAll ? 'Collapse All' : 'Expand All'}
                                <img
                                    className={styles.ExpandIcon}
                                    src={caretRightPurple}
                                    alt={''}
                                    height={'10px'}
                                    style={{
                                        transform: expandAll ? 'rotate(270deg)' : '',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </th>
            ))}
        </tr>
    );

    const render = (
        <div
            className={clsx(styles.RelatedParties, {
                [styles.RelatedPartiesPDFReport]: isPDFReport,
            })}
        >
            <div
                className={clsx(styles.Top, {
                    [styles.TopNoData]: isDataEmpty() || status === 'PROCESSING',
                })}
            >
                <div className={styles.Title}>Related Party Risk & Relations</div>
                <div className={styles.StatusRight}>
                    {loading || status === 'PROCESSING' ? (
                        <span className={styles.NoData}>
                            Fetching Data
                            <LoadingSpinner color="var(--primary-text-colour)" height="20px" />
                        </span>
                    ) : Object.keys(relatedPartyData?.data || {}).length === 0 ? (
                        <span className={styles.NoData}>No Data Available</span>
                    ) : (
                        relatedPartyData?.lastUpdatedAt && (
                            <span className={styles.LastUpdated}>
                                Last updated: {dayjs(relatedPartyData?.lastUpdatedAt).format('DD MMM YYYY')}
                            </span>
                        )
                    )}
                </div>
            </div>

            {!isDataEmpty() && (
                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <table className={styles.Table3}>
                            <thead>{renderHeaders()}</thead>
                            <tbody>
                                {Object.entries(relatedPartyGroupedData).map(([edgeType, data]: [string, any], i) => (
                                    <React.Fragment key={edgeType}>
                                        <tr>
                                            <td colSpan={isPDFReport ? 4 : 5} className={styles.EdgeGroupType}>
                                                {getEdgeTypeLabels(edgeType)} ({Object?.keys(data)?.length})
                                            </td>
                                        </tr>
                                        {Object.entries(data).map(([fromKid, entityEntries]: [string, any], i) => {
                                            const entityEntry = entityEntries[0];
                                            const showSecondDegreeRow = !!(
                                                (expandAll || entityEntry?.isExpanded) &&
                                                entityEntry?.rows?.length
                                            );
                                            return entityEntry ? (
                                                <React.Fragment key={fromKid}>
                                                    <FirstDegreeRow
                                                        edgeType={edgeType}
                                                        isPDFReport={isPDFReport}
                                                        expandAll={expandAll}
                                                        entityEntry={entityEntry}
                                                        handleAlertsClick={handleAlertsClick}
                                                        handleShowRelationsClick={handleShowRelationsClick}
                                                    />
                                                    {showSecondDegreeRow &&
                                                        entityEntry?.rows?.map((row, j) =>
                                                            row?.fromName && row?.fromName !== 'null' ? (
                                                                <SecondDegreeRow
                                                                    key={j}
                                                                    isPDFReport={isPDFReport}
                                                                    entityEntry={entityEntry}
                                                                    row={row}
                                                                    handleAlertsClick={handleAlertsClick}
                                                                />
                                                            ) : (
                                                                <React.Fragment key={row?.fromKid}></React.Fragment>
                                                            ),
                                                        )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment key={fromKid}></React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {showAlerts && (
                <SideDrawer
                    width="70%"
                    onClose={() => {
                        setShowAlerts(null);
                    }}
                >
                    <RelatedPartiesAlerts data={showAlerts} />
                </SideDrawer>
            )}
        </div>
    );

    if (isPDFReport) return isDataEmpty() ? <></> : <A4Page sectionName={REPORT_SECTION_NAMES.RELATED_PARTIES}>{render}</A4Page>;
    else return render;
}

export default RelatedParties;
