import { RISK_HEADERS } from '../RiskIndicatorConstants';
import { RiskIndicatorHeaders } from './TableComponents/RiskIndicatorHeaders';
import styles from '../RiskIndicators.module.scss';
import RiskIndicatorRows from './TableComponents/RiskIndicatorRows';

const RiskIndicatorTable = ({ rows, setRows, isPDFReport = false }) => {
    return (
        <div className={styles.TableContainer} style={{ border: '0.031rem' }}>
            <div className={styles.TableHead}>
                <RiskIndicatorHeaders headers={RISK_HEADERS} isPDFReport={isPDFReport} />
            </div>
            <div className={styles.TableRows}>
                <RiskIndicatorRows
                    rows={rows}
                    setRows={setRows}
                    headers={RISK_HEADERS}
                    isPDFReport={isPDFReport}
                />
            </div>
        </div>
    );
};

export default RiskIndicatorTable;
