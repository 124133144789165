import React, { useEffect, useRef, useState } from 'react';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import styles from './FinancialMetrics.module.scss';
import { fetchFinancialMetrics } from '../../../../../../store/dashboard/financials/financialsApiHelpers';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { ctaPurpleRight } from '../../../../../../assets/hostedassets';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { updateQueryParams } from '../../../../../../utils/searchParamUtils';
import dayjs from 'dayjs';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { AICA_FINANCIAL_METRICS } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { useAppSelector } from '../../../../../../app/hooks';
import { ACCESS_TIERS } from '../../../../../../store/investor/constants';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import FinancialLoaderComponent from '../../../../../../common/FinancialLoader/FinancialLoaderComponent';
import clsx from 'clsx';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';

const activityRatios = [
    'daysPayablesOutStanding',
    'daysInventoryOutStanding',
    'daysSalesOutStanding',
    'CashConversionCycleDays',
    'SalesByNetFixedAssets',
];
const liquidityRatios = ['CashAndCashEquivalent', 'CurrentRatio', 'QuickRatio'];
const solvencyRatios = ['DebtToEquity', 'TOLByTNW'];
const otherMetrics = [
    'Revenue',
    'RevenueGrowthPercentage',
    'GrossProfitMargin',
    'EbitaMargin',
    'ShareholdersFund',
    'TangibleNetWorth',
    'NetWorkingCapital',
    'ReceivablesPayablesGap',
    'BalanceSheetSize',
    'PAT',
    'DrawingPower',
];
const overviewMetrics = [
    'Revenue',
    'RevenueGrowthPercentage',
    'EbitaMargin',
    'PAT',
    'CashAndCashEquivalent',
    'TotalCurrentAssets',
    'TotalCurrentLiabilities',
    'NetWorkingCapital',
    'LongTermDebt',
    'ShortTermDebt',
    'TangibleNetWorth',
];
const AUDITED_STATUS = {
    PROCESSING: 'Processing',
    COMPLETE: 'Processed',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};
const UW_FETCH_FINANCIAL_METRICS = 'UW_FETCH_FINANCIAL_METRICS';
const UW_FETCH_FINANCIALS_RAW_BY_CLASSIFICATION = 'UW_FETCH_FINANCIALS_RAW_BY_CLASSIFICATION';
const AICA_GET_REPORT_STATUS_FROM_ORG_MASTER = 'AICA_GET_REPORT_STATUS_FROM_ORG_MASTER';
const BORROWINGS_CURRENT = 'borrowingsCurrent';
const BORROWINGS_NON_CURRENT = 'borrowingsNonCurrent';
const OVERVIEW = 'overview';
const DASHBOARD = 'dashboard';

const activityRatiosStart = 0;
const liquidityRatiosStart = activityRatios?.length;
const solvencyRatiosStart = liquidityRatiosStart + liquidityRatios?.length;
const otherMetricsStart = solvencyRatiosStart + solvencyRatios?.length;

function FinancialMetrics({
    screen = '',
    assesseeOrgId = '',
    onClose = () => {},
    isPDFReport = false,
    pdfReportSection = '',
}) {
    const location = useLocation();
    const params = useParams();
    const user = useAppSelector((state) => state.user.userData);
    const [fetching, setFetching] = useState(false);
    const [financialMetricsData, setFinancialMetricsData] = useState<any>(null);
    const [noDataAvailable, setNoDataAvailable] = useState(true);
    const [auditedFinancialsStatus, setAuditedFinancialsStatus] = useState('');
    const [loaderMessage, setLoaderMessage] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [isProcessing, setIsProcessing] = useState(false);
    const rowDataExists = !!financialMetricsData?.rows?.length;

    const currentMonth = dayjs().month();
    const currentMonthBetweenAprilAndSeptember = currentMonth >= 3 && currentMonth <= 8;

    const apiPollingRef: any = useRef(null);
    const noDataPollCountRef = useRef(0);

    useEffect(() => {
        if (isPDFReport && financialMetricsData && Array.isArray(financialMetricsData.headers)) {
            const headersLength = financialMetricsData.headers.length;
            if (headersLength > 1) {
                const updatedHeaders = [
                    financialMetricsData.headers[0],
                    ...(headersLength > 3 ? [financialMetricsData.headers[headersLength - 3]] : []),
                    ...(headersLength > 2 ? [financialMetricsData.headers[headersLength - 2]] : []),
                    financialMetricsData.headers[headersLength - 1],
                ];

                if (
                    JSON.stringify(financialMetricsData.headers) !== JSON.stringify(updatedHeaders)
                ) {
                    setFinancialMetricsData((existing) => ({
                        ...existing,
                        headers: updatedHeaders,
                    }));
                }
            }
        }
    }, [isPDFReport, financialMetricsData]);

    useEffect(() => {
        if (assesseeOrgId && screen) {
            fetchData();
        }

        return () => {
            stopPolling();
        };
    }, [assesseeOrgId, screen]);

    useEffect(() => {
        if (auditedFinancialsStatus === AUDITED_STATUS.PROCESSING) {
            setIsProcessing(true);
        } else setIsProcessing(false);
    }, [auditedFinancialsStatus, noDataAvailable]);

    const startPolling = () => {
        if (!apiPollingRef.current) {
            apiPollingRef.current = setInterval(() => {
                fetchData();
            }, 10000);
        }
    };

    const stopPolling = () => {
        if (apiPollingRef.current) {
            clearInterval(apiPollingRef.current);
            apiPollingRef.current = null;
        }
    };

    const fetchData = () => {
        setFetching(true);
        fetchFinancialMetrics(
            {
                orgId: assesseeOrgId,
                startDate: null,
                endDate: null,
                frequency: 'Yearly',
                metricsList:
                    screen === 'overview'
                        ? [...overviewMetrics]
                        : [
                              ...activityRatios,
                              ...liquidityRatios,
                              ...solvencyRatios,
                              ...otherMetrics,
                          ],
            },
            {
                onSuccess: (data) => {
                    transformAndUpdateData({ ...data });
                },
                onError: () => {
                    setFetching(false);
                },
            },
        );
    };

    const transformAndUpdateData = (data) => {
        let uwFinancialMetrics = data?.[UW_FETCH_FINANCIAL_METRICS]?.data?.data;
        let uwRawByClassification = data?.[UW_FETCH_FINANCIALS_RAW_BY_CLASSIFICATION]?.data;
        let auditedTaskStatus =
            data?.[AICA_GET_REPORT_STATUS_FROM_ORG_MASTER]?.reportStatus?.auditedFinancialsStatus;
        if (!auditedTaskStatus) {
            setAuditedFinancialsStatus(AUDITED_STATUS.COMPLETE);
        }
        let borrowingsCurrent = uwRawByClassification?.rows?.find(
            (row_data) => row_data?.parent === BORROWINGS_CURRENT,
        );
        let borrowingsNonCurrent = uwRawByClassification?.rows?.find(
            (row_data) => row_data?.parent === BORROWINGS_NON_CURRENT,
        );

        let noDataAvailable = true;
        let updatedRows =
            uwFinancialMetrics?.rows?.map((row) => {
                let rowData = {
                    ...row,
                    ...(row?.metricName === 'Long Term Debt' && borrowingsNonCurrent),
                    ...(row?.metricName === 'Short Term Debt' && borrowingsCurrent),
                };
                uwFinancialMetrics?.headers?.forEach((header, headerIndex) => {
                    if (
                        noDataAvailable &&
                        headerIndex > 0 &&
                        (rowData?.[header.key] || rowData?.[header.key] === 0)
                    ) {
                        noDataAvailable = false;
                    }
                });
                return rowData;
            }) || [];
        uwFinancialMetrics.rows = updatedRows;
        if (auditedTaskStatus === AUDITED_STATUS.COMPLETE) {
            if (noDataAvailable && !noDataPollCountRef.current) {
                startPolling();
                noDataPollCountRef.current = 1;
            } else {
                setAuditedFinancialsStatus(AUDITED_STATUS.COMPLETE);
                stopPolling();
            }
        } else if (auditedTaskStatus === AUDITED_STATUS.PROCESSING) {
            setAuditedFinancialsStatus(AUDITED_STATUS.PROCESSING);
            startPolling();
        } else {
            setAuditedFinancialsStatus(AUDITED_STATUS.COMPLETE);
            stopPolling();
        }

        setNoDataAvailable(noDataAvailable);
        setFinancialMetricsData(uwFinancialMetrics);
        setFetching(false);
    };
    const getSeparatorRowText = (rowIndex) => {
        switch (rowIndex) {
            case activityRatiosStart:
                return 'Activity Ratios';
            case liquidityRatiosStart:
                return 'Liquidity Ratios';
            case solvencyRatiosStart:
                return 'Solvency Ratios';
            case otherMetricsStart:
                return 'Other Derived Metrics';
            default:
                return '';
        }
    };
    const getSeparatorRow = (rowIndex, screenName) => {
        if (screenName === OVERVIEW) return null;
        if (
            [
                activityRatiosStart,
                liquidityRatiosStart,
                solvencyRatiosStart,
                otherMetricsStart,
            ]?.includes(rowIndex)
        )
            return <div className={styles.SeparatorRow}>{getSeparatorRowText(rowIndex)}</div>;
        else return null;
    };

    const getRowText = (value, unit = '') => {
        if (typeof value === 'number') {
            return parseFloat(value.toFixed(2))?.toLocaleString('en-IN') + unit;
        } else return value ?? '-';
    };

    const showFinancialSummary = () => {
        Mixpanel.track(AICA_FINANCIAL_METRICS, {
            role: user.accountType,
            investorOrgId: user.investorOrgId,
            companyOrgId: params.id,
            AICASpecificSection: 'Overview',
            AICASection: ACCESS_TIERS.SCAN,
        });
        updateQueryParams(searchParams, setSearchParams, { showFinancialMetrics: true }, location);
    };

    const getToolTipContent = (row) => {
        return (
            <div>
                <div>
                    {row?.definitionText && (
                        <>
                            <span className={styles.Bold}>Definition: </span>
                            {row?.definitionText}
                        </>
                    )}
                </div>
                <br />
                <div>
                    {row?.calculationText && (
                        <>
                            <span className={styles.Bold}>Calculation: </span>
                            {row?.calculationText}
                        </>
                    )}
                </div>
            </div>
        );
    };
    useEffect(() => {
        showLoaderMessage(noDataAvailable);
    }, [isProcessing, noDataAvailable]);

    function showLoaderMessage(noDataAvailable) {
        if (isProcessing) setLoaderMessage('Fetching latest data from MCA (takes upto 24 hours)');
        else if (noDataAvailable) setLoaderMessage('No Data Available');
        else setLoaderMessage('');
    }

    const getTableComponent = (screenName = '') => {
        return (
            <div
                className={clsx(styles.Table, {
                    [styles.overviewScreen]: screenName === OVERVIEW,
                })}
            >
                <div
                    className={styles.TableHeadRow}
                    style={{
                        gridTemplateColumns: `2fr repeat(${
                            financialMetricsData?.headers?.length - 1
                        },1fr)`,
                    }}
                >
                    {financialMetricsData?.headers?.map((header, i) => (
                        <div key={i} className={styles.TableHeadRowItem}>
                            {header?.name ?? ''}
                        </div>
                    ))}
                </div>

                {rowDataExists && !noDataAvailable ? (
                    financialMetricsData?.rows?.map((row, i) => (
                        <React.Fragment key={i}>
                            {getSeparatorRow(i, screenName)}
                            <div
                                className={styles.TableBodyRow}
                                style={{
                                    gridTemplateColumns: `2fr repeat(${
                                        financialMetricsData?.headers?.length - 1
                                    },1fr)`,
                                }}
                            >
                                {financialMetricsData?.headers?.map((header, j) => (
                                    <div
                                        className={clsx(
                                            {
                                                [styles.sectionEnd]:
                                                    (i == 3 || i == 7) && screenName === OVERVIEW,
                                            },
                                            styles.TableBodyRowItem,
                                        )}
                                        key={j}
                                    >
                                        {getRowText(row?.[header.key], row?.unit)}
                                        {j === 0 &&
                                            !isPDFReport &&
                                            (row?.definitionText || row?.calculationText) && (
                                                <Tooltip
                                                    content={getToolTipContent(row)}
                                                    direction="right"
                                                >
                                                    <img
                                                        src={
                                                            'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-black.svg'
                                                        }
                                                        height={'14px'}
                                                    />
                                                </Tooltip>
                                            )}
                                    </div>
                                ))}
                            </div>
                            {screenName === OVERVIEW && (
                                <div className={styles.cta} onClick={showFinancialSummary}>
                                    <img src={ctaPurpleRight} alt="" />
                                    View Financial Ratios
                                </div>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <div className={styles.Nodata}>
                        Data not available for last three financial years.
                    </div>
                )}
            </div>
        );
    };
    const ctaElement = (
        <div className={styles.Cta} onClick={showFinancialSummary}>
            <img src={ctaPurpleRight} alt="" />
            View Financial Ratios
        </div>
    );

    const renderComponent = () => (
        <div className={styles.OverviewScreenContainer}>
            <div className={styles.Header}>
                <FinancialLoaderComponent
                    tableName="Financial Summary"
                    message={loaderMessage}
                    status={isProcessing}
                    SideButton={!noDataAvailable && !isPDFReport && ctaElement}
                />
            </div>
            {!noDataAvailable && <div className={styles.Body}>{getTableComponent(screen)}</div>}
        </div>
    );

    const render =
        screen === DASHBOARD ? (
            isPDFReport ? (
                <>{getTableComponent(screen)}</>
            ) : (
                <SideDrawer onClose={onClose} width="70%">
                    <SideDrawer.Header width={'95%'}>
                        <div
                            className={clsx(
                                { [styles.dashboard]: screen === DASHBOARD },
                                styles.Header,
                            )}
                        >
                            <span>
                                Financial Metrics{' '}
                                <span className={styles.Sub}>| From Audited Financials</span>
                            </span>
                            {(fetching || isProcessing) && (
                                <span className={styles.LoaderMessage}>
                                    <LoadingSpinner color="#6021B3" />
                                    Fetching latest data from MCA (takes upto 24 hours)
                                </span>
                            )}
                        </div>
                    </SideDrawer.Header>
                    <div className={styles.FinancialMetrics}>{getTableComponent(screen)}</div>
                </SideDrawer>
            )
        ) : (
            renderComponent()
        );

    if (isPDFReport)
        return !reportSectionsToShow.includes(pdfReportSection) || noDataAvailable ? (
            <></>
        ) : (
            <A4Page sectionName={pdfReportSection}>
                {screen === DASHBOARD ? (
                    <div className={styles.PDFCard}>
                        <div className={styles.Top}>
                            <div className={styles.Title}>{pdfReportSection}</div>
                        </div>
                        <div className={styles.Body}>{render}</div>
                    </div>
                ) : (
                    render
                )}
            </A4Page>
        );
    else return render;
}

export default FinancialMetrics;
