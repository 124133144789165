import styles from './GlobalSearch.module.scss';
import { Dispatch, SetStateAction, useEffect } from 'react';
import SearchBarAndIcon from './Components/SearchBarAndIcon/SearchBarAndIcon';
import useSearchResultFetcher from './useSearchResultFetcher';
import isEmpty from 'lodash/isEmpty';

type GlobalSearchProps = {
    showSearch: boolean;
    setShowSearch: Dispatch<SetStateAction<boolean>>;
};

const GlobalSearch = (props: GlobalSearchProps) => {
    const { showSearch, setShowSearch } = props;
    const {
        setSearchText,
        searchText,
        searchResults,
        navigateUser,
        setSearchResults,
        getTextForKey,
    } = useSearchResultFetcher();
    useEffect(() => {
        if (!showSearch) {
            setSearchText('');
            setSearchResults(null);
        }
    }, [showSearch]);

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.GlobalSearchWrapper}>
                <SearchBarAndIcon
                    setSearchText={setSearchText}
                    searchText={searchText}
                    showSearch={showSearch}
                    onCrossIconClick={() => setShowSearch(false)}
                    onSearchIconClick={() => setShowSearch(!showSearch)}
                />
            </div>
            <div
                style={
                    searchText.length >= 3 && searchResults !== null
                        ? { height: 'auto' }
                        : { height: 0, transition: 'none' }
                }
                className={styles.SearchResults}
            >
                {searchResults !== null && !isEmpty(searchResults) ? (
                    Object.keys(searchResults).map((key) => {
                        return (
                            <div key={key}>
                                <h3 className={styles.SearchResultHeading}>{getTextForKey(key)}</h3>
                                {searchResults[key].map((result) => {
                                    return (
                                        <div
                                            key={result.orgId}
                                            onMouseDown={() => navigateUser(result.orgId, key)}
                                            className={styles.SearchResultItem}
                                        >
                                            <p className={styles.OrgName}>{result.orgName}</p>
                                            <p className={styles.WebsiteUrl}>{result.websiteUrl}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                    <div
                        style={{
                            height: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <h3 className={styles.NoDataFound}>No results found</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GlobalSearch;
