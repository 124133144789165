import React from 'react';

const SampleReportIcon = ({ colour = '#2F8FFF', width = '15', height = '15' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.26522 11.0167H11.3486M4.26522 13.85H14.1819M4.26522 16.6833H8.51522M18.4319 18.1V8.18334L11.3486 1.1H4.26522C3.51377 1.1 2.7931 1.39851 2.26175 1.92986C1.7304 2.46122 1.43188 3.18189 1.43188 3.93333V18.1C1.43188 18.8515 1.7304 19.5721 2.26175 20.1035C2.7931 20.6348 3.51377 20.9333 4.26522 20.9333H15.5986C16.35 20.9333 17.0707 20.6348 17.602 20.1035C18.1334 19.5721 18.4319 18.8515 18.4319 18.1Z"
                stroke={colour}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3401 1.09999V5.34999C11.3401 6.10144 11.6386 6.82211 12.17 7.35346C12.7013 7.88482 13.422 8.18333 14.1734 8.18333H18.4234"
                stroke={colour}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SampleReportIcon;
