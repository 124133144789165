import React from 'react';
import styles from './RiskIndicatorCard.module.scss';
import { RISK_HEADERS } from './RiskIndicatorConstants';
import { useRiskIndicatorStates } from './useRiskIndicatorStates';

interface Row {
    type: string;
    name: string;
    riskProfile: Record<string, unknown>;
}

interface RiskIndicatorCardProps {
    row: Row;
}

const RiskIndicatorCard = ({ row }: RiskIndicatorCardProps) => {
    const { getRiskDot, getRowImage } = useRiskIndicatorStates({
        rows: [],
        setRows: () => {},
        autoFetch: false,
    });
    return (
        <div className={styles.Card}>
            <div className={styles.CardHeader}>
                <span>{getRowImage(row.type)}</span>
                <span>{row.name}</span>
            </div>
            <div className={styles.CardBody}>
                {RISK_HEADERS.map((header) => (
                    <div key={header.key} className={styles.CardRow}>
                        <span className={styles.MetricName}>{header.label}:</span>
                        <span className={styles.MetricValue}>
                            {' '}
                            {getRiskDot(row.riskProfile[header.key])}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RiskIndicatorCard;
