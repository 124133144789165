export const TAB_CONSTANTS = [
    {
        id: 'aicaReport',
        title: 'AICA Report',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIconSelected.svg',
        navigate: '/investor/app/portfolio',
        isLocked: false,
    },
    {
        id: 'debtProducts',
        title: 'Debt Products',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIconSelected.svg',
        navigate: '/investor/app/debt-products',
        isLocked: false,
    },
    {
        id: 'dataRoom',
        title: 'Data Room',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIconSelected.svg',
        navigate: '/investor/app/data-room',
        isLocked: false,
    },
];
export const TAB_CONSTANTS_DATA_ROOM_LOCKED = [
    {
        id: 'aicaReport',
        title: 'AICA Report',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIconSelected.svg',
        navigate: '/investor/app/portfolio',
        isLocked: false,
    },
    {
        id: 'dataRoom',
        title: 'Data Room',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIconSelected.svg',
        navigate: '/investor/app/data-room',
        isLocked: true,
    },
];

export const TAB_CONSTANTS_HIDE_AICA_REPORT = [
    {
        id: 'dataRoom',
        title: 'Data Room',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIcon.svg',
        selectedIcon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/DataRoomHeaderIconSelected.svg',
        navigate: '/investor/app/data-room',
        isLocked: false,
    },
];
export const backButtonImg = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/dashBoardHeaderBackButton.svg';
export const backButtonImgHovered = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/dashBoardHeaderBackButtonHovered.svg';
// header button on dashboard
export const HEADER_BUTTONS: {
    mainButtons:
        | [
              {
                  id: string;
                  title: string;
                  icon: string;
                  hoveredIcon: string;
                  variant: string;
              },
          ]
        | [];
} = {
    mainButtons: [
        // {
        //     id: 'dealBtn',
        //     title: 'Create Deal',
        //     icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/createDealIconHovered.svg',
        //     hoveredIcon:
        //         'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/createDealIcon.svg',
        //     variant: 'purple',
        // },
    ],
};

export const REPORT_TAB_STATES = {
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',
    HIDDEN: 'HIDDEN',
};

export const REPORT_TABS = {
    AICA_REPORT: 'aicaReport',
    DATA_ROOM: 'dataRoom',
    DEBT_PRODUCTS: 'debtProducts',
    COMPANY_DEALS: 'deals',
};

export const REPORT_NAVIGATION = {
    [REPORT_TABS.AICA_REPORT]: '/investor/app/portfolio',
    [REPORT_TABS.DATA_ROOM]: '/investor/app/data-room',
    [REPORT_TABS.DEBT_PRODUCTS]: '/investor/app/debt-products',
    [REPORT_TABS.COMPANY_DEALS]: '/investor/app/company-deals',
};
