// src/components/ManageDealDrawer/ManageDealDrawerFooter.tsx

import React from 'react';
import styles from '../ManageDealDrawer.module.scss';

interface ManageDealDrawerFooterProps {
    currentConfig: any;
    disablePrimary: boolean;
    onPrimary: () => void;
    onSecondary: () => void;
    disableSecondary: boolean;
    style?: React.CSSProperties;
}

export const ManageDealDrawerFooter: React.FC<ManageDealDrawerFooterProps> = ({
    currentConfig,
    disablePrimary,
    onPrimary,
    onSecondary,
    disableSecondary,
    style,
}) => {
    if (!currentConfig?.ctas) return null;

    return (
        <div style={style} className={styles.DrawerFooter}>
            <div className={styles.CtaContainer}>
                {currentConfig.ctas.primary && (
                    <button type="submit" disabled={disablePrimary} onClick={onPrimary} className={`${styles.Button} ${styles.Primary}`}>
                        {currentConfig.ctas.primary.text}
                    </button>
                )}

                {currentConfig.ctas.secondary && (
                    <button
                        type="button"
                        disabled={disableSecondary}
                        className={`${styles.Button} ${styles.Secondary}`}
                        onClick={onSecondary}
                    >
                        {currentConfig.ctas.secondary.text}
                    </button>
                )}
            </div>
        </div>
    );
};
