import axios from 'axios';
import { GET, polusBaseUrl } from '../../../utils/axios-interceptor';

export function getLegalCasesDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_LEGAL_CASES,
        method: GET,
        params: {
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    GET_LEGAL_CASES: '/data/fetchLegalCases',
};
