// src/components/ManageDealDrawer/ManageDealDrawerHeader.tsx

import React from 'react';
import styles from '../ManageDealDrawer.module.scss';

type ManageDealDrawerHeaderProps = {
    drawerLabel: string;
    investorName?: string;
    noBorder?: boolean;
    headerAction?: React.ReactNode;
};

export const ManageDealDrawerHeader: React.FC<ManageDealDrawerHeaderProps> = ({ drawerLabel, investorName, noBorder, headerAction }) => {
    return (
        <div style={noBorder ? { border: 'none' } : {}} className={styles.DrawerHeading}>
            <div className={styles.DrawerHeadingTitle}>
                {drawerLabel}
                {investorName && (
                    <span className={styles.CompanyName}>
                        <span className={styles.VerticalSeparator}>|</span>
                        {investorName}
                    </span>
                )}
            </div>
            {headerAction && <div className={styles.HeaderCTA}>{headerAction}</div>}
        </div>
    );
};
