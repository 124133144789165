import React, { useState, useEffect, useRef } from 'react';
import styles from './PendingDocuments.module.scss';
import ThreeDotsIcon from '../../../../assets/Svg/ThreeDotsIcon';
import useDataGapDocument, { DocumentItem } from './useDataGapDocument';
import { CLARIFICATION_TYPE } from '../../../MagicUpload/Utils/MagicUploadConstants';
import { useOutsideClick } from '../../../../utils/constants/hooks';
import DeleteModal from '../../../DeleteModal/DeleteModal';
import { getPlatformUserId, getSubdomain } from '../../../../utils/utils';
import { fetchInternalSummonAicaData, InternalSummonAicaDataPayload, InternalSummonUserType } from '../../../../store/user/actions';
import { useAppSelector } from '../../../../app/hooks';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { copyIcon } from '../../../../assets/hostedassets';
import useCopyText from '../../../_custom/Hooks/useCopyText/useCopyText';
import { Mixpanel } from '../../../../utils/mixpanel';
import { toast } from 'react-toastify';

type PendingDocumentProps = {
    docList: DocumentItem[];
    setDocList: React.Dispatch<React.SetStateAction<DocumentItem[]>>;
    setSelectedDocument: (doc: DocumentItem) => void;
    toggleMiniDrawer: () => void;
    setLastUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    hasEditPermission?: boolean;
};

const PendingDocuments = (props: PendingDocumentProps) => {
    const { docList, setDocList, setSelectedDocument, toggleMiniDrawer, setLastUpdatedAt, setLoading, hasEditPermission = true } = props;
    const { toggleDocumentCompletion, fetchNeedInfoList, formatDataGapList } = useDataGapDocument(
        'needInfo',
        docList,
        setDocList,
        setLastUpdatedAt,
        setLoading,
    );
    const [openMenuDocId, setOpenMenuDocId] = useState<string | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
    const params = useParams();
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    useOutsideClick(containerRef, () => setOpenMenuDocId(null));
    const toggleMenu = (docId: string) => {
        setOpenMenuDocId((prev) => (prev === docId ? null : docId));
    };

    const handleEdit = (doc: DocumentItem) => {
        setSelectedDocument(doc);
        toggleMiniDrawer();
        setOpenMenuDocId(null);
    };

    const generateGSTLink = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            const platformUserId = getPlatformUserId();
            const payload: InternalSummonAicaDataPayload = {
                parentOrgId: platformData?.investorOrgId as string,
                parentEmailId: user.userData.investorPrimaryEmail,
                orgId: params.id as string,
                userType: InternalSummonUserType.ASSESSEE,
                requesterUserId: platformUserId,
                isMagicLink: true,
            };

            dispatch(
                fetchInternalSummonAicaData(payload, {
                    onSuccess: (userData, link) => {
                        resolve(`https://${link}`);
                    },
                    onError: (error) => {
                        reject(new Error('Failed to fetch the link'));
                    },
                }),
            );
        });
    };

    const openGSTLink = async () => {
        try {
            const link = await generateGSTLink();
            window.open(link, '_blank');
        } catch (error) {
            console.error('Error opening GST link:', error);
            toast.error('Failed to open GST link. Please try again.');
        }
    };

    const { copy: copyGenerateGSTLink } = useCopyText(generateGSTLink);
    const { copy: copyDataGapList } = useCopyText(fetchNeedInfoList, formatDataGapList);

    const insertLinkIntoText = (text: string, link: string | undefined) => {
        // this function will return a jsx element with the link inserted into the text where ${LINK} is found
        if (!link) return text;
        const splitText = text.split('${LINK}');
        if (link === 'MAGIC_LINK_GST_R1') {
            return (
                <>
                    <div className={styles.MagicLink}>
                        <span>
                            {splitText[0]}
                            <span
                                className={styles.DynamicLink}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openGSTLink();
                                }}
                            >
                                Link
                            </span>
                            {splitText[1]}
                        </span>
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                copyGenerateGSTLink();
                                Mixpanel.track('GST Fetch Link Copied', {
                                    'user Role': user?.userData?.accountType,
                                    'PNO Platform': getSubdomain(),
                                    'User Name': user?.userData?.userName,
                                    'Current URL': window.location.href,
                                    'Organization ID': params.id ?? user?.userData?.assesseeOrgId,
                                });
                            }}
                        >
                            <img src={copyIcon} alt="copyText" />
                        </span>
                    </div>
                </>
            );
        }
        return (
            <>
                {splitText[0]}
                <a className={styles.DynamicLink} href={link} target={'_blank'} rel="noreferrer">
                    Link
                </a>
                {splitText[1]}
            </>
        );
    };

    const handleSelect = async (doc) => {
        if (!hasEditPermission) return;
        if (doc.clarificationType === CLARIFICATION_TYPE.SYSTEM_GENERATED) {
            await toggleDocumentCompletion(doc.id, false, doc);
            return;
        }
        await toggleDocumentCompletion(doc.id, false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span>List of pending data and clarifications</span>
                <span
                    className={styles.copyButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        copyDataGapList();
                        Mixpanel.track('Pending Data List Copied', {
                            'user Role': user?.userData?.accountType,
                            'PNO Platform': getSubdomain(),
                            'User Name': user?.userData?.userName,
                            'Current URL': window.location.href,
                            'Organization ID': params.id ?? user?.userData?.assesseeOrgId,
                        });
                    }}
                >
                    <img src={copyIcon} alt="copyText" />
                    <span>Copy Pending List</span>
                </span>
            </div>
            <ul className={styles.docList}>
                {docList.map((doc) => (
                    <li
                        key={doc.id}
                        className={`${styles.listItem} ${doc.completed ? styles.completed : ''} ${
                            !hasEditPermission ? styles.disabled : ''
                        }`}
                    >
                        {hasEditPermission && (
                            <div className={styles.radioWrapper} onClick={() => handleSelect(doc)}>
                                <input
                                    type="radio"
                                    checked={doc.completed}
                                    readOnly
                                    disabled={!hasEditPermission}
                                    className={styles.radioButton}
                                />
                            </div>
                        )}
                        <div className={styles.textWrapper} onClick={() => handleSelect(doc)}>
                            <span className={styles.docName}>
                                <strong>{doc.name}</strong>
                            </span>
                            <span className={styles.docDetails}>{insertLinkIntoText(doc.details, doc.sampleFileLink)}</span>
                        </div>
                        {hasEditPermission && (
                            <div className={styles.iconWrapper}>
                                <div
                                    className={styles.iconTrigger}
                                    onClick={(e) => {
                                        e.stopPropagation(); // prevent triggering toggleDocumentCompletion
                                        if (doc.clarificationType === CLARIFICATION_TYPE.SYSTEM_GENERATED) return;
                                        toggleMenu(doc.id);
                                    }}
                                >
                                    <ThreeDotsIcon
                                        colour={'var(--primary-text-colour)'}
                                        style={
                                            doc.clarificationType === CLARIFICATION_TYPE.SYSTEM_GENERATED
                                                ? {
                                                      filter: 'grayscale(1)',
                                                      opacity: 0.5,
                                                      cursor: 'not-allowed',
                                                  }
                                                : { cursor: 'pointer' }
                                        }
                                        height="14"
                                        width="14"
                                    />
                                </div>
                                {openMenuDocId === doc.id && (
                                    <div ref={containerRef} className={styles.dropdownMenu}>
                                        <div className={styles.dropdownItem} onClick={() => handleEdit(doc)}>
                                            Edit
                                        </div>
                                        <div
                                            className={styles.dropdownItemDelete}
                                            onClick={() => {
                                                setShowDeleteModal(doc.id);
                                                setOpenMenuDocId(null);
                                            }}
                                        >
                                            Delete
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <DeleteModal
                isOpen={showDeleteModal !== null}
                deleteFunction={async () => {
                    await toggleDocumentCompletion(showDeleteModal as string, true);
                    setShowDeleteModal(null);
                }}
                onClose={() => setShowDeleteModal(null)}
                heading="Delete"
                message="Are you sure you want to delete this pending data from the list?"
            />
        </div>
    );
};

export default PendingDocuments;
