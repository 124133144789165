import { JAVA_API_STATUS } from '../../../../../../utils/axios-interceptor';
import { createOrUpdateDealTermsLog, fetchAllTypeOrgList, fetchDealTermsLogs } from './DealsService';

export const getAllTypeOrgList = async (dealProposerId: string, handler: any) => {
    try {
        const res = await fetchAllTypeOrgList(dealProposerId);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.responseBody.data.dealBeneficiaryIds);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
        handler?.onError();
    }
};

export const getDealTermsLogs = async (investorDealId: string, handler: any) => {
    try {
        const res = await fetchDealTermsLogs(investorDealId);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.responseBody.dealTermsLogs);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
        handler?.onError();
    }
};

export const postCreateOrUpdateDealTermsLog = async (payload: any, handler: any) => {
    try {
        const res = await createOrUpdateDealTermsLog(payload);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.responseBody.data);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
        handler?.onError();
    }
};
