import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../OperatingInflowReconciliation.module.scss';
import { expandPurple } from '../../../../../../assets/hostedassets';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { AICA_RELATED_PARTY } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { setRolesForUser } from '../../../../../../store/user/userV2/reducer';
import { useAppSelector } from '../../../../../../app/hooks';

interface RowsProps {
    rows: any;
    headers: any;
    tableRef: any;
    assesseeOrgId: string;
}

function Rows(props: RowsProps) {
    const { rows = [], headers, assesseeOrgId } = props;
    const [showCustomers, setShowCustomers] = useState(false);
    const user = useAppSelector((state) => state.user);

    const handleShowCustomersClick = () => {
        setShowCustomers(!showCustomers);
        if (!showCustomers) {
            const mixpanelData = {
                Parent_Id: user?.userData?.investorOrgId,
                WebsiteUrl: window.location.href,
                User_Id: user?.userData?.userId,
                User_Role: setRolesForUser(user?.userData?.roles?.[0]),
                Company_Org_Id: assesseeOrgId,
            };
            Mixpanel.track(AICA_RELATED_PARTY, mixpanelData);
        }
    };

    return (
        <>
            {rows?.map?.((row, i) => (
                <React.Fragment key={i}>
                    <tr key={i} className={styles.TableBodyRow}>
                        {headers?.map((header, j) => (
                            <td className={styles.TableBodyRowItem} key={`${header?.name}-${j}`}>
                                <div className={j === 0 ? styles.FirstRowItem : ''}>
                                    {row?.[header?.key] || '-'}
                                    {j === 0 && row?.subRows?.length && (
                                        <div
                                            className={styles.Button}
                                            onClick={handleShowCustomersClick}
                                        >
                                            {showCustomers ? 'Hide Customers' : 'Show Customers'}
                                            <img
                                                src={expandPurple}
                                                alt=""
                                                height="20px"
                                                style={{
                                                    transform: showCustomers
                                                        ? 'rotate(180deg)'
                                                        : '',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        ))}
                    </tr>
                    {showCustomers &&
                        row?.subRows?.map((subrow, k) => (
                            <tr key={k} className={styles.TableBodyRow}>
                                {headers?.map((header, j) => (
                                    <td className={styles.SubrowItem} key={`${header?.name}-${j}`}>
                                        <div className={j === 0 ? styles.FirstRowItem : ''}>
                                            {subrow?.[header?.key] || '-'}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                </React.Fragment>
            ))}
        </>
    );
}

export default Rows;
