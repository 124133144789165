import React, { useEffect, useState } from 'react';
import FileTree from './Components/FileTree/FileTree';
import styles from './FileExplorer.module.scss';
import { FileNode } from '../../DataRoom';
import { Button } from '../../../../common/_custom';
import {
    dataRoomDownloadIcon,
    downloadDarkGray,
    downloadIconDataRoomHovered,
} from '../../../../assets/hostedassets';
import { isEmpty } from '../../../../utils/utils';
import EmptyBank from '../../../Dashboard/DashboardModules/BankDataTable/components/EmptyBank/EmptyBank';
import useFetchDataRoomFiles from '../../useFetchDataRoomFiles';
import CategorySelector from '../../../../common/CategorySelector/CategorySelector';
import { updateQueryParams } from '../../../../utils/searchParamUtils';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';

interface FileExplorerProps {
    data: FileNode[];
    activeFilecount: number;
    archivedFilecount: number;
    selectedCompany: any;
    exportData: Function;
    fetchFiles: Function;
    newFilePresent?: boolean;
    // isRecurCompany: boolean;
}

const FileExplorer: React.FC<FileExplorerProps> = ({
    data,
    activeFilecount,
    archivedFilecount,
    selectedCompany,
    exportData,
    fetchFiles,
    newFilePresent,
    // isRecurCompany,
}) => {
    const { downloadTheFolder, downloading, downloadingNew } = useFetchDataRoomFiles(fetchFiles);
    const [searchParams, setSearchParams] = useSearchParams();
    const [fileViewType, setFileViewType] = useState('');
    const isLender = useAppSelector((state) => state.user.userData.accountType) === 'LENDER';
    const islenderOrArchiveView = isLender || fileViewType === 'archived';

    const VIEW_TYPE = [
        {
            id: 'active',
            label: 'Active',
            count: activeFilecount ?? null,
        },
        {
            id: 'archived',
            label: 'Archived',
            count: archivedFilecount ?? null,
        },
    ];
    useEffect(() => {
        const fileViewType = searchParams.get('fileViewType');
        if (!fileViewType) {
            const defaultViewType = 'active';
            setFileViewType(defaultViewType);
            searchParams.set('fileViewType', defaultViewType);
        } else {
            setFileViewType(fileViewType);
        }
        // Update query params
        updateQueryParams(searchParams, setSearchParams, {}, location);
    }, [searchParams]);

    const changeSelectedCategory = (categoryId) => {
        setFileViewType(categoryId);
        updateQueryParams(searchParams, setSearchParams, { fileViewType: categoryId }, location);
    };

    return (
        <div className={styles.fileExplorer}>
            <header className={styles.header}>
                <div className={styles.leftSubHeader}>
                    <h1>Data Room</h1>
                    {!isLender && (
                        <div className={styles.selectorView}>
                            <div className={styles.separator} />
                            <div className={styles.CategorySelector}>
                                <CategorySelector
                                    categoryOptions={VIEW_TYPE}
                                    selectedCategory={fileViewType}
                                    changeSelectedTab={changeSelectedCategory}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <Button
                    disabled={isEmpty(data)}
                    text={
                        <div
                            className={styles.ButtonTextContainer}
                            style={isEmpty(data) ? { color: '#c7c7c7' } : {}}
                        >
                            {!isEmpty(data) ? (
                                <img src={downloadIconDataRoomHovered} alt={'Download'} />
                            ) : (
                                <img
                                    style={{ opacity: 0.3 }}
                                    src={downloadDarkGray}
                                    alt={'Download'}
                                />
                            )}
                            Download All
                        </div>
                    }
                    style={{
                        margin: 0,
                        border: '.0625rem solid #c7c7c7',
                        backgroundColor: 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '10rem',
                        padding: '10px 16px',
                    }}
                    loading={downloading}
                    loadingText={'Downloading'}
                    onClick={async () => {
                        await downloadTheFolder(data, selectedCompany?.orgName ?? 'Data', false);
                    }}
                    variant={'purpleinverted'}
                />
                <Button
                    disabled={isEmpty(data) || !newFilePresent}
                    text={
                        <div className={`${styles.ButtonTextContainer}`}>
                            <img src={dataRoomDownloadIcon} alt={'Download'} />
                            Download New
                        </div>
                    }
                    style={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '10rem',
                        padding: '10px 16px',
                    }}
                    loading={downloadingNew}
                    loadingText={'Downloading'}
                    onClick={async () => {
                        await downloadTheFolder(data, selectedCompany?.orgName ?? 'Data', true);
                    }}
                    variant={'purple'}
                />
            </header>
            <div className={styles.fileList}>
                <div
                    className={
                        islenderOrArchiveView
                            ? styles.fileTreeHeaderLenderView
                            : styles.fileTreeHeader
                    }
                >
                    <div className={styles.fileTreeHeaderItem}>Data</div>
                    <div className={styles.fileTreeHeaderItem}>Status</div>
                    <div className={styles.fileTreeHeaderItem}>Last Updated</div>
                </div>
                <div className={styles.FileTreeContainer}>
                    {isEmpty(data) ? (
                        <EmptyBank
                            style={{ background: 'transparent', boxShadow: 'none', height: '80%' }}
                            text={'No Data Available'}
                        />
                    ) : (
                        <div style={{ height: '100%' }}>
                            <FileTree
                                data={data}
                                index={0}
                                fetchFiles={fetchFiles}
                                // isRecurCompany={isRecurCompany}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileExplorer;
