import React, { useEffect, useState } from 'react';
import styles from './DebtDetails.module.scss';
import {
    fetchDebtDetailsData,
    fetchDebtDocsData,
} from '../../../../store/dashboard/debtDetails/debtDetails_apiHelper';
import clsx from 'clsx';
import dayjs from 'dayjs';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { downloadFile } from '../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { downloadIconDataRoomHovered } from '../../../../assets/hostedassets';
import { useParams } from 'react-router-dom';

const headers = [
    {
        label: 'Lender Name',
        key: 'lenderName',
    },
    {
        label: 'Loan Type',
        key: 'typeOfDebtLoan',
    },
    {
        label: 'Disbursal On',
        key: 'disbursalDate',
    },
    {
        label: 'Sanctioned',
        key: 'sanctionedAmount',
    },
    {
        label: 'O/S Balance',
        key: 'outstandingBalance',
    },
    {
        label: 'EMI',
        key: 'emiAmount',
    },
    {
        label: 'Remaining Tenure*',
        key: 'remainingTenure',
    },
    {
        label: 'ROI (%)',
        key: 'roi',
    },
];

function DebtDetails() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [debtDetailsData, setDebtDetailsData] = useState<{ rows: any; totals: any } | null>(null);
    const [debtDocs, setDebtDocs] = useState<any>({ debtDocsAica: [], debtDocsRrf: [] });
    const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
    const [downloading, setDownloading] = useState(false);

    const debtDocsAicaLength = debtDocs?.debtDocsAica?.length;

    useEffect(() => {
        fetchData();
        fetchDebtDocs();
    }, []);

    const fetchData = () => {
        setLoading(true);
        fetchDebtDetailsData({
            orgId: params.id as string,
        })
            .then((data) => {
                setDebtDetailsData({
                    rows: data?.rows,
                    totals: data?.totals,
                });
                setLastUpdatedAt(data?.updatedAt);
            })
            .catch((err) => {
                console.error('Error in fetching debt schedule data', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchDebtDocs = () => {
        fetchDebtDocsData({
            orgId: params.id as string,
        })
            .then((data) => {
                setDebtDocs({
                    debtDocsAica: data?.debtDocsAica,
                    debtDocsRrf: data?.debtDocsRrf,
                });
            })
            .catch((err) => {
                console.error('Error in fetching debt schedule latest doc', err);
            })
            .finally(() => {});
    };

    const getDebtDocForDownload = () => {
        const docIdFromData = debtDetailsData?.rows?.[0]?.docId;
        if (docIdFromData)
            return {
                docId: docIdFromData as string,
                docName: '',
            };
        else if (debtDocsAicaLength)
            return {
                docId: debtDocs?.debtDocsAica?.[debtDocsAicaLength - 1]?.docId as string,
                docName: debtDocs?.debtDocsAica?.[debtDocsAicaLength - 1]?.docName as string,
            };
        else return null;
        // else if(debtDocsRrfLength) return debtDocs?.debtDocsRrf?.[debtDocsRrfLength - 1]
        // else return null;
    };

    const getRowData = (keyName: string, rowData) => {
        switch (keyName) {
            case 'emiAmount':
            case 'sanctionedAmount':
            case 'outstandingBalance':
                return Number.isNaN(parseInt(rowData?.[keyName]))
                    ? '-'
                    : parseInt(rowData?.[keyName]).toLocaleString('en-IN');
            case 'remainingTenure':
                return Number.isNaN(parseFloat(rowData?.[keyName])) ? '-' : rowData?.[keyName];
            case 'disbursalDate':
                return dayjs(rowData?.[keyName])?.isValid()
                    ? dayjs(rowData?.[keyName]).format('DD MMM YYYY')
                    : '-';
            default:
                return rowData?.[keyName] || '-';
        }
    };

    const getTotalRowData = (keyName: string) => {
        const totalOutstanding = debtDetailsData?.totals?.outstandingBalance;
        const totalSanctioned = debtDetailsData?.totals?.sanctionedAmount;
        const totalEmi = debtDetailsData?.totals?.emiAmount;
        switch (keyName) {
            case 'lenderName':
                return 'Totals';
            case 'outstandingBalance':
                return totalOutstanding ? parseInt(totalOutstanding)?.toLocaleString('en-IN') : '-';
            case 'sanctionedAmount':
                return totalSanctioned ? parseInt(totalSanctioned)?.toLocaleString('en-IN') : '-';
            case 'emiAmount':
                return totalEmi ? parseInt(totalEmi)?.toLocaleString('en-IN') : '-';
            default:
                return '-';
        }
    };

    const downloadDebtDoc = async () => {
        const docToDownload: any = getDebtDocForDownload();
        if (docToDownload?.docId) {
            setDownloading(true);
            downloadFile(docToDownload?.docId, () => {}, docToDownload?.docName).finally(() => {
                setDownloading(false);
            });
        }
    };

    return (
        <div className={styles.DebtDetails}>
            <div className={clsx(styles.Top, { [styles.NoData]: !debtDetailsData?.rows?.length })}>
                <div className={styles.Title}>Debt Details</div>
                {loading ? (
                    <LoadingSpinner color={'#6021B3'} height={'16px'} />
                ) : !!debtDetailsData?.rows?.length ? (
                    <div className={styles.Right}>
                        {dayjs(lastUpdatedAt).isValid() && (
                            <div className={styles.UpdatedDate}>
                                Data as of {dayjs(lastUpdatedAt).format('DD MMM YYYY')}
                            </div>
                        )}
                        {getDebtDocForDownload() && (
                            <div className={styles.Download} onClick={downloadDebtDoc}>
                                {downloading ? (
                                    <LoadingSpinner
                                        height={'14px'}
                                        color={'#6021B3'}
                                        style={{ margin: 0 }}
                                    />
                                ) : (
                                    <img
                                        src={downloadIconDataRoomHovered}
                                        alt={''}
                                        height={'14px'}
                                    />
                                )}
                                Download Latest
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={styles.NoDataText}>No Data Available</div>
                )}
            </div>
            {!!debtDetailsData?.rows?.length && (
                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <table className={styles.Table}>
                            <thead className={styles.TableHead}>
                                <tr className={styles.TableHeadRow}>
                                    {headers.map((header, index) => (
                                        <th
                                            key={index}
                                            className={clsx(styles.HeadRowItem, {
                                                [styles.FirstHeadRowItem]: index === 0,
                                                [styles.RightAlign]: [
                                                    'roi',
                                                    'remainingTenure',
                                                ].includes(header.key),
                                            })}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className={styles.TableBody}>
                                {debtDetailsData?.rows?.map((rowData, i) => (
                                    <tr className={styles.TableBodyRow} key={i}>
                                        {headers.map((header, j) => (
                                            <td
                                                className={clsx(styles.RowItem, {
                                                    [styles.FirstColumnItem]: j === 0,
                                                    [styles.RightAlign]: [
                                                        'roi',
                                                        'remainingTenure',
                                                    ].includes(header.key),
                                                })}
                                                key={j}
                                            >
                                                {getRowData(header.key, rowData)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr className={styles.TableBodyRow}>
                                    {headers.map((header, i) => (
                                        <td
                                            className={clsx(styles.RowItem, styles.TotalRowItem, {
                                                [styles.FirstColumnItem]: i === 0,
                                                [styles.RightAlign]: [
                                                    'roi',
                                                    'remainingTenure',
                                                ].includes(header.key),
                                            })}
                                            key={i}
                                        >
                                            {getTotalRowData(header.key)}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={styles.NoteText}>
                        <span className={styles.Bold}>*Note:</span> Remaining Tenure is on months
                        unless specified
                    </div>
                </div>
            )}
        </div>
    );
}

export default DebtDetails;
