import React from 'react';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import styles from './StatCard.module.scss';
import aboveGreen from '../../../../assets/above-green.svg';
import belowRed from '../../../../assets/below-red.svg';
import clsx from 'clsx';

interface StatCardProps {
    data: any;
    statName: string;
    isLoading: boolean;
    isPDFReport?: boolean;
}

function StatCard(props: StatCardProps) {
    const { data, isLoading, isPDFReport } = props;

    const Loader = () => {
        return (
            <React.Fragment>
                <ShimmerThumbnail height={30} rounded className={styles.ShimmerThumbStat} />
            </React.Fragment>
        );
    };

    const getTrendIndicator = () => {
        switch (data.trendIndicator) {
            case 'Up':
                return (
                    <>
                        <img src={aboveGreen} alt="" />
                        {'Above'}
                    </>
                );
            case 'Down':
                return (
                    <>
                        <img src={belowRed} alt="" />
                        {'Below'}
                    </>
                );
            default:
                return '-';
        }
    };

    if (isPDFReport && !data?.value) return <></>;

    return (
        <div className={clsx(styles.StatCard, { [styles.PDFReport]: isPDFReport })}>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={clsx(styles.Content, { [styles.Animate]: !isPDFReport })}>
                    <div className={styles.Name}>
                        <div className={styles.Title}>{data.title}</div>
                    </div>
                    <div className={styles.Value}>
                        {data.value ? `${data.value}` : '-'}
                        <div className={styles.Dates}>{data.metricDuration}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StatCard;
