import axios from 'axios';
import { bablerBaseUrl, GET, polusBaseUrl, POST } from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';

export function fetchDocs(
    payload: any,
    constraints: {
        pageNo: number;
        pageSize: number;
    },
) {
    return axios({
        url: API_ENDPOINTS.FETCH_DOCS,
        method: POST,
        data: payload,
        params: {
            pageNo: constraints.pageNo,
            pageSize: constraints.pageSize,
        },
    });
}

export function fetchOrgList(payload: { pnoId: string; searchText?: string }) {
    return axios({
        url: API_ENDPOINTS.FETCH_ORG_LIST,
        method: GET,
        params: {
            pnoId: payload.pnoId,
            searchText: payload?.searchText,
        },
    });
}

export function callUpdateDocMetadata(payload: any) {
    return axios({
        url: API_ENDPOINTS.UPDATE_DOC_METADATA,
        method: POST,
        data: payload,
    });
}

export function fetchDocList(payload: any) {
    return axios({
        url: `${polusBaseUrl}${API_ENDPOINTS.FETCH_DOC_LIST}`,
        method: GET,
        params: {
            pnoId: payload.pnoId,
            requestId: getTimeStamp(),
        },
    });
}

export function callUploadFile(payload: any) {
    return axios({
        url: `${bablerBaseUrl}${API_ENDPOINTS.UPLOAD_FILE}`,
        method: POST,
        data: payload,
    });
}

export function fetchDocStatus(payload: any) {
    return axios({
        url: API_ENDPOINTS.FETCH_DOC_STATUS,
        method: POST,
        data: {
            ids: payload.ids,
        },
    });
}

export function fetchUntaggedCount(payload: any) {
    return axios({
        url: API_ENDPOINTS.NOTIFY_COUNT,
        method: GET,
        params: {
            pnoId: payload.pnoId,
            orgId: payload.orgId,
        },
    });
}

export function deleteDocApi(payload: any) {
    return axios({
        url: `${bablerBaseUrl}/${API_ENDPOINTS.DELETE_DOC}`,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function fetchDuplicateFilesMap(payload: any) {
    return axios({
        url: API_ENDPOINTS.FETCH_DUPLICATE_FILE_MAP,
        method: POST,
        data: {
            pnoId: payload.pnoId,
        },
    });
}

export function pollZipDocStatus(payload: any) {
    return axios({
        url: API_ENDPOINTS.POLL_ZIP_DOC_STATUS,
        method: POST,
        data: {
            ids: payload.ids,
        },
    });
}

export const fetchOrgDropdownList = (payload: any) => {
    return axios({
        url: API_ENDPOINTS.FETCH_ORG_DROPDOWN_LIST,
        method: GET,
        params: {
            pnoId: payload.pnoId,
            filtered: true,
        },
    });
};

export const fetchSourcesList = () => {
    return axios({
        url: API_ENDPOINTS.FETCH_SOURCES_LIST,
        method: GET,
    });
};

export const fetchUserDropdownList = (payload) => {
    return axios({
        url: API_ENDPOINTS.FETCH_USER_DROPDOWNN_LIST,
        method: GET,
        params: payload,
    });
};
export const fetchDataGapDocs = (payload) => {
    return axios({
        url: API_ENDPOINTS.FETCH_DATA_GAP_LIST,
        method: POST,
        data: payload,
    });
};

export const callAddClarification = (payload) => {
    return axios({
        url: API_ENDPOINTS.ADD_DATA_GAP_CLARIFICATION,
        method: POST,
        data: payload,
    });
};
export const callUpdateClarification = (payload) => {
    return axios({
        url: API_ENDPOINTS.UPDATE_DATA_GAP_CLARIFICATION,
        method: POST,
        data: payload,
    });
};
export const callNeedInfoReminder = (payload) => {
    return axios({
        url: API_ENDPOINTS.SEND_DATA_GAP_NOTIFICATION,
        method: POST,
        data: payload,
    });
};
export const fetchUntaggedCountPerUser = (payload) => {
    return axios({
        url: API_ENDPOINTS.FETCH_UNTAGGED_COUNT,
        method: GET,
        params: payload,
    });
};

const API_ENDPOINTS = {
    FETCH_DOCS: '/magic/fetch/docs',
    FETCH_ORG_LIST: '/magic/orgList',
    FETCH_UNTAGGED_COUNT: '/magic/getUntaggedDocsCount',
    UPDATE_DOC_METADATA: 'magic/update/docMetaData',
    FETCH_DOC_LIST: '/config/fetchDocList',
    UPLOAD_FILE: '/docs/upload',
    FETCH_DOC_STATUS: '/magic/docStatus',
    NOTIFY_COUNT: 'magic/notify',
    DELETE_DOC: 'docs/deleteDoc',
    FETCH_DUPLICATE_FILE_MAP: 'magic/fetchFileMetaData',
    POLL_ZIP_DOC_STATUS: 'magic/zipDocStatus',
    FETCH_ORG_DROPDOWN_LIST: '/magic/allOrgList',
    FETCH_SOURCES_LIST: '/magic/sources',
    FETCH_USER_DROPDOWNN_LIST: '/magic/userList',
    FETCH_DATA_GAP_LIST: '/dataGap/getDataGap',
    ADD_DATA_GAP_CLARIFICATION: 'dataGap/addNewClarification',
    UPDATE_DATA_GAP_CLARIFICATION: '/dataGap/update',
    SEND_DATA_GAP_NOTIFICATION: '/dataGap/sendNeedInfoReminder',
};
