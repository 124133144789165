import { MasterData } from './useDADataTransformations';

type DealTerms = {
    loanTypes?: string[];
    securityOffered?: string[] | { value: string; type: string }[];
    maximumDealAmount?: string;
    roi?: string; // We'll map this to `maximumRoi` (and possibly `minimumRoi`)
    tenure?: string; // We'll map this to `minimumTenure` (and possibly `maximumTenure`)
    [key: string]: any; // In case there are more fields
};

type DebtProductData = {
    dealTerms: DealTerms;
    productEnums: string[];
    lenderDetails: Map<string, string[]>;
};

export function TransformDataFromDebtProducts(data: DebtProductData): MasterData {
    const { dealTerms } = data;
    const newDealTerms: Partial<DealTerms> = {};
    // Example: copy "maximumDealAmount" if present
    if (typeof dealTerms?.maximumDealAmount !== 'undefined') {
        newDealTerms.maximumDealAmount = dealTerms?.maximumDealAmount;
    }

    // Example: map "roi" to "maximumRoi" (and optionally also "minimumRoi")
    if (typeof dealTerms?.roi !== 'undefined') {
        newDealTerms.maximumRoi = dealTerms.roi;
        // Uncomment if you want both:
        // newDealTerms.minimumRoi = dealTerms.roi;
    }
    if (typeof dealTerms?.minimumRoi !== 'undefined') {
        newDealTerms.minimumRoi = dealTerms.minimumRoi;
    }

    if (typeof dealTerms?.maximumRoi !== 'undefined') {
        newDealTerms.maximumRoi = dealTerms.maximumRoi;
    }

    // Example: map "tenure" to "minimumTenure" (and optionally also "maximumTenure")
    if (typeof dealTerms?.tenure !== 'undefined') {
        newDealTerms.minimumTenure = dealTerms.tenure;
        // Uncomment if you want both:
        // newDealTerms.maximumTenure = dealTerms.tenure;
    }

    if (typeof dealTerms?.maximumTenure !== 'undefined') {
        newDealTerms.maximumTenure = dealTerms.maximumTenure;
    }

    if (typeof dealTerms?.minimumTenure !== 'undefined') {
        newDealTerms.minimumTenure = dealTerms.minimumTenure;
    }

    // Copy "loanTypes" if it exists
    if (Array.isArray(data.productEnums)) {
        newDealTerms.loanTypes = data.productEnums;
    }
    // Copy "securityOffered" if it exists
    if (Array.isArray(dealTerms?.securityOffered) && dealTerms.securityOffered.length > 0) {
        // Ensure securityOffered is treated as an array
        const securityOfferedArray = dealTerms?.securityOffered as (string | { type: string })[];

        // Remove null/undefined values
        const filteredSecurityOffered = securityOfferedArray?.filter(
            (item) => item !== null && item !== undefined,
        );

        if (filteredSecurityOffered.length > 0 && typeof filteredSecurityOffered[0] === 'object') {
            newDealTerms.securityOffered = (filteredSecurityOffered as { type: string }[])
                .filter((item) => 'type' in item && typeof item.type === 'string')
                .map((item) => item.type);
        } else {
            newDealTerms.securityOffered = filteredSecurityOffered as string[];
        }
    }

    const dealReceiverDetailMap = new Map<string, { dealTerms: Object; productEnums: string[] }>();
    if (data.lenderDetails) {
        for (const [key, value] of data.lenderDetails) {
            dealReceiverDetailMap.set(key, {
                dealTerms: {
                    ...newDealTerms,
                    // If you want to overwrite the loanTypes, you can do it here
                    loanTypes: value,
                },
                productEnums: value,
            });
        }
    }

    return {
        products: data.productEnums,
        dealTerms: newDealTerms,
        dealReceiverDetailMap: dealReceiverDetailMap,
    };
}

export function TransformDataFromCreateDeal(data: any): MasterData {
    const { dealTerms } = data;
    const newDealTerms: Partial<DealTerms> = {};
    const newDealReceiverDetailMap = new Map<
        string,
        { dealTerms: Object; productEnums: string[]; isExisting?: boolean }
    >();
    if (dealTerms.maximumDealAmount) {
        newDealTerms.maximumDealAmount = dealTerms.maximumDealAmount;
    }
    if (dealTerms.maximumRoi) {
        newDealTerms.maximumRoi = dealTerms.maximumRoi;
    }
    if (dealTerms.minimumRoi) {
        newDealTerms.minimumRoi = dealTerms.minimumRoi;
    }
    if (dealTerms.minimumTenure) {
        newDealTerms.minimumTenure = dealTerms.minimumTenure;
    }
    if (dealTerms.maximumTenure) {
        newDealTerms.maximumTenure = dealTerms.maximumTenure;
    }
    if (dealTerms.loanTypes) {
        newDealTerms.loanTypes = data.products;
    }
    if (Array.isArray(dealTerms?.securityOffered) && dealTerms.securityOffered.length > 0) {
        // Ensure securityOffered is treated as an array
        const securityOfferedArray = dealTerms?.securityOffered as (string | { type: string })[];

        // Remove null/undefined values
        const filteredSecurityOffered = securityOfferedArray?.filter(
            (item) => item !== null && item !== undefined,
        );

        if (filteredSecurityOffered.length > 0 && typeof filteredSecurityOffered[0] === 'object') {
            newDealTerms.securityOffered = (filteredSecurityOffered as { type: string }[])
                .filter((item) => 'type' in item && typeof item.type === 'string')
                .map((item) => item.type);
        } else {
            newDealTerms.securityOffered = filteredSecurityOffered as string[];
        }
    }

    if (dealTerms.documents) {
        newDealTerms.documents = dealTerms.documents;
    }
    if (data.dealReceiverDetailMap) {
        Object.keys(data.dealReceiverDetailMap).forEach((key) => {
            newDealReceiverDetailMap.set(key, {
                dealTerms: {
                    ...data.dealReceiverDetailMap[key].dealTerms,
                    loanTypes: data.dealReceiverDetailMap[key].products,
                },
                productEnums: data.dealReceiverDetailMap[key].products,
                isExisting: true,
            });
        });
    }

    return {
        products: data.products,
        dealTerms: newDealTerms,
        dealStatus: data.dealStatus,
        dealReceiverDetailMap: newDealReceiverDetailMap ?? new Map(),
    };
}
