import clsx from 'clsx';
import isNil from 'lodash/isNil';
import { useMemo, useRef } from 'react';

import {
    ADD_TO_PORTFOLIO,
    CREATE_DEAL,
    DOWNLOAD_IR,
    DOWNLOAD_RAW,
    GENERATE_PDF_REPORT,
    INVITE_USER,
    SUMMON,
    TOGGLE_MONITORING,
    UNLOCK_REPORT,
    UPLOAD_DATA,
    VIEW_DATA_ROOM,
    VIEW_ONE_PAGER,
    VIEW_REPORT,
} from '../Rows';
import { MonitoringStatus } from '../../../../../../../../../store/investor/constants';

import threeDotVertical from '../../../../../../../../../assets/three-dot-vertical.svg';

import styles from './ActionButton.module.scss';
import { useAppSelector } from '../../../../../../../../../app/hooks';
import { Mixpanel } from '../../../../../../../../../utils/mixpanel';
import { AICA_CREATE_DEAL } from '../../../../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { useOutsideClick } from '../../../../../../../../../utils/constants/hooks';
import AicaPDFReport from '../../../../../../../../AicaPDFReport/AicaPDFReport';
import { useNavigate } from 'react-router-dom';

function ActionButton({
    rowIndex,
    row,
    action,
    handleActionClick,
    dataLength,
    accessTier,
    isOpen,
    onToggleDropdown,
    tdRef,
}) {
    const navigate = useNavigate();
    const isSummonUser = !isNil(sessionStorage.getItem('auth_token'));
    const firstTrigger = action?.firstCTATrigger;
    const secondaryTriggers = action?.secondCTATrigger || [];
    const threeDotRef = useRef<null | HTMLImageElement>(null);
    const menuRef = useRef<null | HTMLDivElement>(null);
    const user = useAppSelector((state) => state.user);
    const onePagerDataLoading = useAppSelector((state) => state.investor.onePager.loading);

    useOutsideClick(menuRef, () => {
        onToggleDropdown(null);
    });

    const toggleOptions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onToggleDropdown(rowIndex);
    };

    const getButtonLabel = (action = '') => {
        switch (action) {
            case TOGGLE_MONITORING: {
                return (
                    <div className={styles.MonitoringWrap}>
                        <span>
                            Turn Monitoring: {row?.['monitoringStatus'] === 'ACTIVE' ? 'OFF' : 'ON'}
                        </span>
                        <div
                            className={clsx(styles.MonitoringDot, {
                                [styles.Green]:
                                    row?.['monitoringStatus'] === MonitoringStatus.INACTIVE,
                                [styles.Red]: row?.['monitoringStatus'] === MonitoringStatus.ACTIVE,
                            })}
                        />
                    </div>
                );
            }
            case VIEW_ONE_PAGER:
                return onePagerDataLoading ? 'Please Wait...' : 'View One Pager';
            case VIEW_REPORT:
                return 'View Report';
            case CREATE_DEAL:
                return 'Create Deal';
            case UPLOAD_DATA:
                return 'View Account';
            case DOWNLOAD_RAW:
                return 'Export Data';
            case UNLOCK_REPORT:
                return 'Unlock AICA Report';
            case INVITE_USER:
                return 'Invite User';
            case SUMMON:
                return 'View Account';
            case ADD_TO_PORTFOLIO:
                return 'Add To Portfolio';
            case DOWNLOAD_IR:
                return 'Export Investment Report';
            case VIEW_DATA_ROOM:
                return 'View Data Room';
            case GENERATE_PDF_REPORT:
                return 'Generate PDF Report';
        }
    };
    const trackMixpanelEvent = (row) => {
        const mixpanelData = {
            Company_Org_Id: row?.assesseeOrgId,
            PartnetAccessType: user?.userData?.accountType,
            Dsa_Id: user?.userData?.investorOrgId,
            websiteUrl: window.location.href,
        };
        Mixpanel.track(AICA_CREATE_DEAL, mixpanelData);
    };

    const filteredSecondaryTriggers = useMemo(() => {
        if (isSummonUser) {
            return secondaryTriggers?.filter((trigger: string) => trigger !== SUMMON) ?? [];
        }
        return secondaryTriggers;
    }, [secondaryTriggers, isSummonUser]);

    return (
        <div className={styles.Action}>
            {!!filteredSecondaryTriggers.length && (
                <div
                    className={clsx(styles.ThreeDotBox, {
                        [styles.ThreeDotActive]: isOpen,
                    })}
                >
                    <img
                        id={rowIndex}
                        src={threeDotVertical}
                        alt="upl"
                        height="30px"
                        onClick={toggleOptions}
                        ref={threeDotRef}
                    />
                </div>
            )}

            {isOpen && (
                <div
                    ref={menuRef}
                    className={clsx(styles.Options, {
                        [styles.OptionsLastChild]: dataLength > 10 && dataLength - rowIndex <= 5,
                    })}
                >
                    {filteredSecondaryTriggers?.map((trigger, index) => (
                        <div
                            key={index}
                            className={styles.Option}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleActionClick(trigger, row);
                                if (trigger === CREATE_DEAL) {
                                    trackMixpanelEvent(row);
                                }
                            }}
                        >
                            {getButtonLabel(trigger)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ActionButton;
