import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './FilteredHeader.module.scss';
import { useOutsideClick } from '../../../../utils/constants/hooks';
import {
    getOrgDropdownList,
    getSourceDropdownList,
    getUserDropdownList,
} from '../../MagicUploadHelper';
import { useAppSelector } from '../../../../app/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { HEADER_TITLE, KEY_NAME, sourceMap } from '../../Utils/MagicUploadConstants';
import FilteredIconMU from '../../../../assets/Svg/FilteredIconMU';
import { getSourceIcon, SourceType } from '../../Utils/MagicUploadUtils';

type FilteredHeaderProps = {
    onFilter: (selectedItems: { value: string; label: string }[], key: string) => void;
    title: string;
    items: { value: string; label: string }[];
    disabled: boolean;
    style?: React.CSSProperties;
};

const FilteredHeader = (props: FilteredHeaderProps) => {
    const { title, items, disabled, onFilter, style } = props;
    const user = useAppSelector((state) => state.user.userData);
    const [dropdownOptions, setDropdownOptions] = useState<{ value: string; label: string }[]>([]);
    const [selectedItems, setSelectedItems] = useState<{ value: string; label: string }[]>(items);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [originalDropdownOptions, setOriginalDropdownOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isCompany = searchParams.get('isCompany') === 'true';
    const dropdownRef = useRef(null);
    useOutsideClick(dropdownRef, () => {
        setShowDropdown(false);
        setShowFilters({
            assesseeOrgIdList: false,
            userIds: false,
            sources: false,
        });
    });

    const [showfilters, setShowFilters] = useState({
        assesseeOrgIdList: false,
        userIds: false,
        sources: false,
    });

    useEffect(() => {
        // Sync selectedItems with items prop without causing infinite re-renders
        if (JSON.stringify(items) !== JSON.stringify(selectedItems)) {
            setSelectedItems(items);
        }
    }, [items]);

    const fetchDropdownOptions = async () => {
        setLoading(true);
        switch (title) {
            case HEADER_TITLE.ORGANISATION_NAME: {
                await getOrgDropdownList(
                    { pnoId: user.investorOrgId },
                    {
                        onSuccess: (data) => {
                            const options = data.map((org) => ({
                                label: org.orgName,
                                value: org.assesseeOrgId,
                            }));
                            setDropdownOptions(options);
                            setOriginalDropdownOptions(options);
                        },
                    },
                );
                setLoading(false);
                break;
            }
            case HEADER_TITLE.SOURCE: {
                await getSourceDropdownList({
                    onSuccess: (data) => {
                        const options = data.map((source: string) => ({
                            label: sourceMap[source],
                            value: source,
                        }));
                        setDropdownOptions(options);
                        setOriginalDropdownOptions(options);
                    },
                });
                setLoading(false);
                break;
            }
            case HEADER_TITLE.UPLOADED_BY: {
                await getUserDropdownList(
                    {
                        pnoId: user.investorOrgId,
                        ...(isCompany && { orgId: user.assesseeOrgId ?? params.id }),
                    },
                    {
                        onSuccess: (data) => {
                            const options = data.map((user: any) => ({
                                label: user.name,
                                value: user.userId,
                            }));
                            setDropdownOptions(options);
                            setOriginalDropdownOptions(options);
                        },
                    },
                );
                setLoading(false);
                break;
            }
        }
    };

    useEffect(() => {
        fetchDropdownOptions();
    }, [title]);

    useEffect(() => {
        // Trigger onFilter only when selectedItems change
        const keyName = getKeyName(title);
        onFilter(selectedItems, keyName);
    }, [selectedItems]);

    const getKeyName = (title: string) => {
        switch (title) {
            case HEADER_TITLE.ORGANISATION_NAME:
                return KEY_NAME.ASSESSEE_ORGID_LIST;
            case HEADER_TITLE.SOURCE:
                return KEY_NAME.SOURCES;
            case HEADER_TITLE.UPLOADED_BY:
                return KEY_NAME.USER_IDS;
            default:
                return '';
        }
    };

    const toggleSelection = (option: { value: string; label: string }) => {
        const isSelected = selectedItems.some((item) => item.value === option.value);
        const isUntagged =
            option.value === 'Untagged' ||
            selectedItems.map((item) => item.value).includes('Untagged');
        if (isUntagged) {
            setSelectedItems((prevState) => {
                if (isSelected) {
                    return prevState.filter((item) => item.value !== option.value);
                } else {
                    return [option];
                }
            });
            return;
        }
        setSelectedItems((prevSelected) =>
            isSelected
                ? prevSelected.filter((item) => item.value !== option.value)
                : [...prevSelected, option],
        );
    };

    const filteredDropdownOptions = useMemo(() => {
        return dropdownOptions.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase()),
        );
    }, [dropdownOptions, searchText]);

    const toggleFilter = (key: keyof typeof showfilters) => {
        setShowFilters((prev) => ({
            assesseeOrgIdList:
                key === KEY_NAME.ASSESSEE_ORGID_LIST ? !prev.assesseeOrgIdList : false,
            userIds: key === KEY_NAME.USER_IDS ? !prev.userIds : false,
            sources: key === KEY_NAME.SOURCES ? !prev.sources : false,
        }));
        setShowDropdown((prev) => !prev);
    };

    return (
        <th
            ref={dropdownRef}
            onMouseDown={() => {
                if (!disabled) setShowDropdown(!showDropdown);
            }}
            style={disabled ? { cursor: 'inherit', ...style } : { ...style }}
            className={
                !showfilters[getKeyName(title)]
                    ? styles.FilteredHeader
                    : styles.FilteredHeaderSelected
            }
            onClick={() => toggleFilter(getKeyName(title) as keyof typeof showfilters)}
        >
            <span className={styles.Title}>{title}</span>
            {!disabled && (
                <FilteredIconMU
                    colour={
                        showfilters[getKeyName(title)] || items.length > 0
                            ? 'var(--primary-text-colour)'
                            : '#B0B0B0'
                    }
                    width="14"
                    height="10"
                    style={{ marginLeft: '0.5rem' }}
                />
            )}
            {showfilters[getKeyName(title)] && (
                <div
                    className={clsx(styles.FilteredDropdownContainer, {
                        [styles.FilteredDropdownContainerForUploadedBy]: title === 'Uploaded By',
                    })}
                    style={
                        showfilters[getKeyName(title)] ? { maxHeight: '10rem' } : { maxHeight: 0 }
                    }
                >
                    <div className={styles.DropdownOptionsWrapper}>
                        <input
                            type="text"
                            placeholder="Search"
                            className={styles.SearchInputFilter}
                            value={searchText}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className={styles.FilteredDropdownWrapper}>
                            {filteredDropdownOptions.length > 0 ? (
                                filteredDropdownOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        className={styles.FilteredDropdownItem}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            toggleSelection(option);
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.some(
                                                (item) => item.value === option.value,
                                            )}
                                            readOnly
                                        />
                                        <label className={styles.DropdownLabel}>
                                            {option.label}
                                            {title === HEADER_TITLE.SOURCE
                                                ? getSourceIcon(option.value as SourceType)
                                                : null}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <div className={styles.NoData}>
                                    <span>No Results</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </th>
    );
};

export default FilteredHeader;
