import { toast } from 'react-toastify';
import { bankAccountsUpdate } from '../../../../../store/company/apiHelpers';
import { USER_TYPE } from '../../../../../utils/constants/user';
import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import styles from './RawBankStatements.module.scss';
import { useAppSelector } from '../../../../../app/hooks';

export const DIRECT_CIBIL_DATA = 'directorCibilData';

function RawBankStatements({
    bankStatementsData,
    fetchOnboardingData,
    userType,
    assesseeOrgId,
    categoryId,
    flowId,
    isInvestor,
}) {
    // const lenderConsent = directorCibilData?.lenderDirectorCibilConsent || false;
    // const borrowerConsent = directorCibilData?.borrowerDirectorCibilConsent || false;

    const user = useAppSelector((state) => state.user);

    const deleteFile = async (fileData) => {
        await bankAccountsUpdate(
            {
                assesseeOrgId: assesseeOrgId,
                pnoId: user.userData.investorOrgId,
                entityType: 'MANUAL',
                bankStatement: {
                    docId: fileData?.docId,
                    docName: fileData?.docName,
                    isDeleted: true,
                },
            },
            {
                onSuccess: () => {
                    fetchOnboardingData();
                },
            },
        );
    };

    const updateRawBankStatement = async (fileData, isLastFile) => {
        await bankAccountsUpdate(
            {
                assesseeOrgId: assesseeOrgId,
                pnoId: user.userData.investorOrgId,
                entityType: 'MANUAL',
                bankStatement: {
                    docId: fileData?.docId,
                    docName: fileData?.fileName,
                    isDeleted: false,
                },
            },
            {
                onSuccess: () => {
                    fetchOnboardingData();
                    if (isLastFile) toast.success('Bank Statements Uploaded Successfully');
                },
            },
        );
    };
    return (
        <div style={isInvestor ? { width: '80%' } : {}} className={styles.DirectorCibil}>
            <div className={styles.Title}>
                {userType === USER_TYPE.INVESTOR
                    ? 'Bank Statements (last 12 months)'
                    : 'Uploaded Bank Statements'}
            </div>

            {userType === USER_TYPE.INVESTOR && (
                <>
                    <div className={styles.Text}>
                        Upload bank statements for the last 12 months (supported file formats: pdf,
                        zip)
                    </div>
                </>
            )}

            {/* {userType === USER_TYPE.INVESTOR && ( */}
            <>
                <UploadCard
                    existingFiles={bankStatementsData?.bankStatements || []}
                    // disabled={!lenderConsent}
                    // uploadText={userType === USER_TYPE.INVESTOR ? "Upload bank statements for the last 12 months (supported file formats: pdf, zip). Learn more" : ""}
                    acceptedFormats={FILE_FORMATS.PDF_AND_ZIP}
                    assesseeOrgId={assesseeOrgId}
                    metadataUpdate={updateRawBankStatement}
                    accountNumber={'Others'} // Doing this to aggregate them into others folder in Data Room.
                    categoryId={categoryId}
                    flowId={flowId}
                    // categoryId={18}
                    // flowId={16}
                    fileDeleteHandler={deleteFile}
                    hideUpload={userType === USER_TYPE.COMPANY}
                    allowMultiple
                />
            </>
            {/* )} */}
        </div>
    );
}

export default RawBankStatements;
