import { CONSTANTS } from './constants';

export const fetchCompanyOverviewData = (payload: {
    onSuccess: Function;
    assesseeOrgId: string;
    investorOrgId: string;
    partnerUserId?: string;
}) => {
    return {
        type: CONSTANTS.FETCH_COMPANY_OVERVIEW,
        payload,
    };
};

export const fetchScorecardData = (payload: any) => {
    return {
        type: CONSTANTS.FETCH_SCORECARD,
        payload,
    };
};

export const fetchAlertsData = (payload: any) => {
    return {
        type: CONSTANTS.FETCH_ALERTS,
        payload,
    };
};

export const fetchShareholdingPatternData = (payload: {
    graphNames: string[];
    tabName: string;
    startDate: string;
    endDate: string;
    tenure: string;
    onSuccess: Function;
}) => {
    return {
        type: CONSTANTS.FETCH_SHAREHOLDING_PATTERN,
        payload,
    };
};
