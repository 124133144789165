import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { Handlers } from '../../../types/actions/types';
import { fetchOperatingInflowTableDataService } from './reconciliationService';

export async function fetchOperatingInflowReconData(payload, handlers: Handlers) {
    try {
        const response = await fetchOperatingInflowTableDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.data || {});
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
            // return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
    }
}
