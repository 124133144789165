import clsx from 'clsx';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Legend,
    Label,
} from 'recharts';
import styles from '../Graph.module.scss';

/**
 * @Description Bar graph component for 2 values for every x-coordinate
 */

interface BrushGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function BrushGraph(props: BrushGraphProps) {
    const { error } = props;
    const { colors, amount, hasNegative, metadata } = props.graphData;
    const data = error ? [] : amount;
    const getBarGap = () => {
        if (data.length === 1) return '50%';
        else if (data.length > 1 && data.length < 4) return '30%';
        else if (data.length >= 4 && data.length < 8) return '20%';
        else if (data.length >= 8) return '8%';
    };

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 30,
                    right: 10,
                    left: -10,
                    bottom: 5,
                }}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="period"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    // tickFormatter={(val) => formatPeriod(val)}
                    interval={0}
                />
                <Legend
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 20 }}
                    iconType="circle"
                    formatter={(value, entry, index) => {
                        return metadata?.legend?.[value];
                    }}
                />
                <YAxis
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    domain={[(dataMin) => 1.1 * dataMin, 'auto']}
                    dx={5}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        height={15}
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 20 }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                    />
                ) : null}
                {hasNegative ? <ReferenceLine y={0} stroke="#000000" /> : null}
                <Bar
                    dataKey="Operating"
                    fill={error ? colors?.error?.primary : colors?.primary}
                    radius={[3, 3, 0, 0]}
                />
                <Bar
                    dataKey="Investing"
                    fill={error ? colors?.error?.secondary : colors?.secondary}
                    radius={[3, 3, 0, 0]}
                />
                <Bar
                    dataKey="Financing"
                    fill={error ? colors?.error?.primary : colors?.tertiary}
                    radius={[3, 3, 0, 0]}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default BrushGraph;
