import React, { useState } from 'react';
import { getPlatformUserId } from '../../../../../utils/utils';
import styles from './AddUserModal.module.scss';
import {
    COUNTRY_CODE,
    EMAIL,
    EMAIL_ERROR_MSG,
    FULL_NAME,
    FULL_NAME_ERROR_MSG,
    ORG_NAME,
    PASSWORD,
    PHONE,
    PHONE_ERROR_MSG,
    TRIGGER_NOTIFICATION,
} from '../../../../../utils/constants/companyLogin';
import {
    InternalSummonAicaDataPayload,
    InternalSummonUserType,
} from '../../../../../store/user/actions';
import { useAppSelector } from '../../../../../app/hooks';
import { createAccountV2 } from '../../../../../store/user/userV2/actions';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal } from '../../../../../common/_custom';
import CountryCodesDropdown, {
    CountryCodeData,
} from '../../../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import {
    InviteUserFormValidations,
    partnerFormValidations,
} from '../../../../../utils/constants/formValidations';
import { MANAGE_USERS_TABS } from '../../../../../store/investor/constants';
import { useSearchParams } from 'react-router-dom';
import { fetchOwners, GetOwnerDataPayload } from '../../../../../store/investor/action';
import { toast } from 'react-toastify';
import CheckBox from '../../../../../common/CheckBox/CheckBox';
const { PARTNER, INVESTOR, TEAM } = MANAGE_USERS_TABS;

interface AddUserModalProps {
    showAddUserModal: boolean;
    setShowAddUserModal: (value: boolean | ((prevState: boolean) => boolean)) => void;
    currentRowUser: any;
    onClose: () => void;
    fetchRows: (pgNo: number) => Promise<void>;
    getDynamicHeaders?: () => void;
    pageNo: number;
    onUserAdded?: (user: any) => void;
}
const USER_TYPE_KEY = 'userType';
export type AddUserData = {
    [FULL_NAME]: string;
    [EMAIL]: string;
    [PHONE]: string;
    [COUNTRY_CODE]: CountryCodeData | null;
    [USER_TYPE_KEY]?: string;
    [TRIGGER_NOTIFICATION]: boolean;
};

function AddUserModal(props: AddUserModalProps) {
    const {
        showAddUserModal,
        setShowAddUserModal,
        currentRowUser,
        onClose,
        fetchRows,
        pageNo,
        getDynamicHeaders,
        onUserAdded,
    } = props;
    const user = useAppSelector((state) => state.user);
    const [searchParams, setSearchParams] = useSearchParams();
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const userType = user.userData.accountType;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<AddUserData>({
        [FULL_NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
        [USER_TYPE_KEY]: '',
        [TRIGGER_NOTIFICATION]: false,
    });
    const [errors, setErrors] = useState({
        [FULL_NAME]: false,
        [EMAIL]: false,
        [PHONE]: false,
    });
    const disabled = !(
        formData[FULL_NAME] &&
        formData[EMAIL] &&
        formData[PHONE] &&
        formData[COUNTRY_CODE]
    );
    const handleFormChange = (val: string | boolean, identifier: string) => {
        setErrors((errors) => ({
            ...errors,
            [identifier]: false,
        }));
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
    };
    const getSummonUserType = (userType: string) => {
        switch (userType) {
            case 'Partner':
                return InternalSummonUserType.PARTNER;
            case 'Lender':
                return InternalSummonUserType.LENDER;
            case 'Team member':
                return InternalSummonUserType.ADMIN_TEAM;
            default:
                return null;
        }
    };

    const getUserType = (userType) => {
        switch (userType) {
            case PARTNER:
                return {
                    id: 'PARTNER',
                    label: 'Partner',
                };
            case INVESTOR:
                return {
                    id: USER_TYPE.LENDER,
                    label: 'Investor',
                };
            default:
                return { id: '', label: '' };
        }
    };

    const fetchOwnerDetails = () => {
        let payload: GetOwnerDataPayload = {
            investorOrgId: user?.userData?.investorOrgId,
        };
        dispatch(fetchOwners(payload));
    };

    const handleSubmit = () => {
        setErrors({
            [FULL_NAME]: !InviteUserFormValidations.isFullNameValid(formData[FULL_NAME]),
            [EMAIL]: !InviteUserFormValidations.isEmailValid(formData[EMAIL]),
            [PHONE]: !InviteUserFormValidations.isPhoneValid(
                formData[PHONE],
                formData[COUNTRY_CODE],
            ),
        });
        if (!InviteUserFormValidations.allValid(formData)) return;
        setLoading(true);
        dispatch(
            createAccountV2(
                {
                    user: {
                        name: formData[FULL_NAME],
                        emailId: formData[EMAIL]?.toLowerCase(),
                        investorId: user.domainMetadata?.investorOrgId || '',
                        accountType: getUserType(searchParams.get('category')?.toString())?.id,
                        accountTypes: [
                            USER_TYPE.INVESTOR,
                            USER_TYPE.PARTNER,
                            USER_TYPE.ADMIN_TEAM,
                            USER_TYPE.LENDER,
                        ],
                        journeyName:
                            getUserType(searchParams.get('category')?.toString())?.id ===
                            USER_TYPE.LENDER
                                ? 'INVITE_LENDER'
                                : getUserType(searchParams.get('category')?.toString())?.id ===
                                  USER_TYPE.ADMIN_TEAM
                                ? 'INVITE_TEAM_MEMBER'
                                : 'INVITE_PARTNER',
                        mobileNumber: formData[PHONE],
                        countryId: formData[COUNTRY_CODE]?.countryId || '',
                        triggerNotification: formData[TRIGGER_NOTIFICATION],
                        countryCode: formData[COUNTRY_CODE]?.countryCode || '',
                        orgId:
                            currentRowUser?.organizationId || currentRowUser?.[0]?.organizationId,
                    },
                    platformId: user.domainMetadata?.productName || '',
                },
                {
                    isInviteCall: true,
                    onSuccess: () => {
                        const newUser = {
                            name: formData[FULL_NAME],
                            emailId: formData[EMAIL]?.toLowerCase(),
                            mobileNumber: `+${formData[COUNTRY_CODE]?.callingCode} ${formData[PHONE]}`,
                            countryCode: formData[COUNTRY_CODE]?.countryCode,
                            organizationId:
                                currentRowUser?.organizationId || currentRowUser[0]?.organizationId,
                            userStatus: 'Unverified',
                        };

                        onUserAdded?.(newUser);
                        fetchOwnerDetails();
                        toast.success('User invited successfully');
                        setLoading(false);
                        setFormData({
                            [FULL_NAME]: '',
                            [EMAIL]: '',
                            [PHONE]: '',
                            [COUNTRY_CODE]: formData[COUNTRY_CODE],
                            [USER_TYPE_KEY]: getUserType(searchParams.get('category')?.toString())
                                ?.id,
                            [TRIGGER_NOTIFICATION]: false,
                        });
                        onClose();
                        fetchRows(pageNo);
                        getDynamicHeaders && getDynamicHeaders();
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            ),
        );
    };
    return (
        <Modal
            open={showAddUserModal}
            onClose={() => {
                setShowAddUserModal(false);
                setFormData({
                    [FULL_NAME]: '',
                    [EMAIL]: '',
                    [PHONE]: '',
                    [COUNTRY_CODE]: null,
                    [USER_TYPE_KEY]: '',
                    [TRIGGER_NOTIFICATION]: false,
                });
            }}
            persistent
            size={'flexible'}
            style={{ padding: '10px' }}
        >
            <div className={styles.FormBox}>
                <div className={styles.Title}>Add User</div>
                <div className={styles.FormContainer}>
                    <Input
                        placeholder="Full Name"
                        value={formData[FULL_NAME]}
                        onChange={(val: string) => handleFormChange(val, FULL_NAME)}
                        errorMessage={FULL_NAME_ERROR_MSG}
                        isValid={!errors[FULL_NAME]}
                    />
                    <Input
                        placeholder="Email"
                        value={formData[EMAIL]}
                        onChange={(val: string) => handleFormChange(val.trim(), EMAIL)}
                        errorMessage={EMAIL_ERROR_MSG}
                        isValid={!errors[EMAIL]}
                    />
                    <div className={styles.Row}>
                        <CountryCodesDropdown
                            //@ts-ignore
                            setCountryCode={(val) => handleFormChange(val, COUNTRY_CODE)}
                        />
                        <Input
                            type="number"
                            placeholder="Phone Number"
                            value={formData[PHONE]}
                            onChange={(val: string) => handleFormChange(val, PHONE)}
                            errorMessage={PHONE_ERROR_MSG}
                            isValid={!errors[PHONE]}
                            style={{ marginTop: '0', width: '100%' }}
                        />
                    </div>
                    <CheckBox
                        label={'Send invite on email'}
                        checked={formData[TRIGGER_NOTIFICATION]}
                        onCheck={(val) => handleFormChange(val, TRIGGER_NOTIFICATION)}
                        variant={'tertiary'}
                        style={{ padding: '1.5rem 0 0 0.25rem' }}
                    />
                    <Button
                        variant={'primary'}
                        style={{ width: '100%' }}
                        text="Add"
                        loadingText="Please Wait"
                        loading={loading}
                        onClick={handleSubmit}
                        disabled={disabled}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default AddUserModal;
