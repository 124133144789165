import React, { useEffect } from 'react';
import styles from './CreditSummaryBar.module.scss';

interface CreditSummaryBarProps {
    minScore;
    maxScore;
}

const CreditSummaryBar: React.FC<CreditSummaryBarProps> = ({ minScore, maxScore }) => {
    const [width, setWidth] = React.useState(0);

    useEffect(() => {
        if (maxScore) {
            setWidth((maxScore / 900) * 100);
        } else {
            setWidth(((minScore || 0) / 900) * 100);
        }
    }, [minScore, maxScore]);

    return (
        <div className={styles.BarContainer}>
            <div className={styles.Value}>
                <span className={styles.Black}>
                    {minScore}
                    {maxScore ? `-${maxScore}` : ''}
                </span>
                /900
            </div>

            <div className={styles.BarParent}>
                <div className={styles.BarChild} style={{ width: `${width}%` }}></div>
            </div>
        </div>
    );
};

export default CreditSummaryBar;
