import styles from './ManageUserTable.module.scss';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import loaderSvg from '../../../../assets/aica-loader.svg';
import clsx from 'clsx';
import { Headers, Rows } from '../useManageUser';
import ActionMenu from '../../../../common/ActionMenu/ActionMenu';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    fetchInternalSummonAicaData,
    InternalSummonAicaDataPayload,
    InternalSummonUserType,
} from '../../../../store/user/actions';
import { getPlatformUserId, openUserSummonTab } from '../../../../utils/utils';
import isNil from 'lodash/isNil';
import { useSearchParams } from 'react-router-dom';
import { MANAGE_USERS_TABS } from '../../../../store/investor/constants';
import { CompanySideHeaders } from '../ManageUserConstants';
import { LENDER_ROUTES } from '../../../../utils/constants/routesConst';
import { debtProductsEmpty } from '../../../../assets/hostedassets';

const { PARTNER, INVESTOR, TEAM } = MANAGE_USERS_TABS;

type ManageUserTableProps = {
    rows: Rows;
    headers: Headers;
    isRowsLoading: boolean;
    setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentRowUser: React.Dispatch<React.SetStateAction<any>>;
    isCompanySide: boolean;
};

const ManageUserTable = ({
    rows,
    headers,
    isRowsLoading,
    setShowAddUserModal,
    setCurrentRowUser,
    isCompanySide,
}: ManageUserTableProps) => {
    const dispatch = useAppDispatch();
    const isSummonUser = !isNil(sessionStorage.getItem('auth_token'));
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const user = useAppSelector((state) => state.user);
    const [params, setParams] = useSearchParams();

    const getSummonUserType = (userType: string) => {
        switch (userType) {
            case 'Partner':
                return InternalSummonUserType.PARTNER;
            case 'Lender':
                return InternalSummonUserType.LENDER;
            case 'Team member':
                return InternalSummonUserType.ADMIN_TEAM;
            default:
                return null;
        }
    };

    const ACTION_MENU_ITEMS = {
        'View Account': {
            action: ({ userId, userType }, source) => {
                const platformUserId = getPlatformUserId();
                const summonUserType = getSummonUserType(userType);
                if (!summonUserType) {
                    console.error('Invalid user type');
                    return;
                }
                const payload: InternalSummonAicaDataPayload = {
                    parentOrgId: platformData?.investorOrgId as string,
                    parentEmailId: user.userData.investorPrimaryEmail,
                    userId,
                    userType: summonUserType,
                    requesterUserId: platformUserId,
                };
                dispatch(
                    fetchInternalSummonAicaData(payload, {
                        onSuccess: (userData) => {
                            openUserSummonTab({ ...userData, orgId: platformData?.investorOrgId });
                        },
                    }),
                );
            },
        },
        'Add User': {
            action: ({ userId, organizationId, userType }, source) => {
                setCurrentRowUser({
                    organizationId,
                    userId,
                    userType,
                });
                setShowAddUserModal(true);
            },
        },
        'Investment Preferences': {
            action: ({ userId, userType }, source) => {
                const platformUserId = getPlatformUserId();
                const summonUserType = getSummonUserType(userType);
                if (!summonUserType) {
                    console.error('Invalid user type');
                    return;
                }
                const payload: InternalSummonAicaDataPayload = {
                    parentOrgId: platformData?.investorOrgId as string,
                    parentEmailId: user.userData.investorPrimaryEmail,
                    userId,
                    userType: summonUserType,
                    requesterUserId: platformUserId,
                };
                dispatch(
                    fetchInternalSummonAicaData(payload, {
                        onSuccess: (userData) => {
                            openUserSummonTab({
                                ...userData,
                                orgId: platformData?.investorOrgId,
                                referrer: LENDER_ROUTES.MANAGE_ELIGIBILITY,
                            });
                        },
                    }),
                );
            },
        },
    };

    const getActionCtaVal = (actionCta: string[] | undefined) => {
        if (!actionCta) return [];

        return actionCta.map((cta) => {
            if (cta === 'SUMMON') return 'View Account';
            if (cta === 'ADD_USER') return 'Add User';
            if (cta === 'INVESTMENT_PREFERENCE') return 'Investment Preferences';
            return '';
        });
    };

    const renderTableContent = () => {
        return (
            <table className={styles.Table}>
                <thead className={styles.TableHead}>
                    <tr className={styles.Header}>
                        {headers.map((header, i) => {
                            if (
                                params.get('category')?.toString() !== TEAM &&
                                header.label === 'User Type'
                            ) {
                                return null;
                            }
                            return (
                                <th
                                    key={header.id}
                                    className={clsx({
                                        [styles.HeadMain]: i === 0,
                                        [styles.ActionColumn]: header.label === 'Actions',
                                        [styles.HeadItem]: i !== 0,
                                    })}
                                    style={isCompanySide ? { borderRight: 'none' } : {}}
                                >
                                    {header.label !== 'Actions' ? header.label : ''}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className={styles.TableBody}>
                    {isRowsLoading ? (
                        <tr>
                            <td colSpan={headers.length}>
                                <div className={styles.LoadingContainer}>
                                    <img
                                        style={{ zIndex: 1000 }}
                                        src={loaderSvg}
                                        alt="Loading..."
                                        width={'80px'}
                                    />
                                </div>
                            </td>
                        </tr>
                    ) : !Array.isArray(rows) || rows.length === 0 ? (
                        <tr>
                            <td colSpan={headers.length}>
                                <div className={styles.EmptyState}>
                                    <img src={debtProductsEmpty} alt="Empty" />
                                    <p>No users found</p>
                                </div>
                            </td>
                        </tr>
                    ) : isCompanySide ? (
                        rows?.map((row) => (
                            <tr key={row.userId} className={styles.Row} style={{ width: '100%' }}>
                                {CompanySideHeaders.map((header) => {
                                    if (header.type === 'static') {
                                        const value =
                                            header.label === 'User Name'
                                                ? row.name
                                                : header.label === 'Email'
                                                ? row.emailId
                                                : header.label === 'Phone Number'
                                                ? row.mobileNumber
                                                : row.userStatus;

                                        return (
                                            <td
                                                className={styles.RowItem}
                                                title={value || '-'}
                                                key={header.id}
                                                style={{
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    width: '25%',
                                                }}
                                            >
                                                {header.label === 'Status' ? (
                                                    <span
                                                        className={clsx({
                                                            [styles.Verified]: value === 'Verified',
                                                            [styles.Unverified]:
                                                                value === 'Unverified',
                                                        })}
                                                    >
                                                        {value}
                                                    </span>
                                                ) : (
                                                    value || '-'
                                                )}
                                            </td>
                                        );
                                    }
                                    return null;
                                })}
                            </tr>
                        ))
                    ) : (
                        rows?.map((row) => (
                            <tr key={row.userId} className={styles.Row}>
                                <td className={styles.RowItem} title={row.name}>
                                    {row.name}
                                </td>
                                <td className={styles.RowItem} title={row.emailId}>
                                    {row.emailId}
                                </td>
                                <td className={styles.RowItem} title={row.mobileNumber}>
                                    {row.mobileNumber}
                                </td>
                                {params.get('category')?.toString() === TEAM && (
                                    <td className={styles.RowItem} title={row.userType}>
                                        {row.userType}
                                    </td>
                                )}
                                <td
                                    style={{ padding: '1.38rem 1.5rem' }}
                                    className={styles.RowItem}
                                    title={row.userStatus}
                                >
                                    <span
                                        className={clsx({
                                            [styles.Verified]: row.userStatus === 'Verified',
                                            [styles.Unverified]: row.userStatus === 'Unverified',
                                        })}
                                    >
                                        {row.userStatus}
                                    </span>
                                </td>
                                <td className={styles.RowItem} title={row.organizationName ?? '-'}>
                                    {row.organizationName === '' ? '-' : row.organizationName}
                                </td>
                                {headers.map((header) => {
                                    if (header.type === 'dynamic') {
                                        return (
                                            <td
                                                className={styles.RowItem}
                                                title={
                                                    row.additionalDetails[header.key ?? ''] ?? '-'
                                                }
                                                key={header.id}
                                            >
                                                {row.additionalDetails[header.key ?? ''] ?? '-'}
                                            </td>
                                        );
                                    }
                                    return null;
                                })}
                                <td className={styles.RowItem}>
                                    <ActionMenu
                                        triggerPayload={{
                                            userId: row.userId,
                                            organizationId: row.organizationId,
                                            userType: row.userType,
                                        }}
                                        rowData={{
                                            mainConfig: {
                                                actionConfig: getActionCtaVal(row?.actionCta),
                                            },
                                        }}
                                        actionMenu={ACTION_MENU_ITEMS}
                                        source="MANAGE_USERS"
                                        disabled={!row?.actionCta?.length || isSummonUser}
                                    />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        );
    };

    return <div className={styles.TableContainer}>{renderTableContent()}</div>;
};

export default ManageUserTable;
