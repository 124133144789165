import React from 'react';
import styles from '../CompanyOffersTable/CompanyOffersTable.module.scss';

interface OffersHeaderProps {
    headers: {
        key: string;
        label: string;
    }[];
}

const OffersHeader: React.FC<OffersHeaderProps> = ({ headers }) => {
    return (
        <tr className={styles.TableHeadRow}>
            {headers.map((header) => (
                <th key={header.key} className={styles.TableHeadRowItem}>
                    {header.label}
                </th>
            ))}
        </tr>
    );
};

export default OffersHeader;
