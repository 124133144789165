import React from 'react';
import styles from './CompanyOffersTable.module.scss';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { OffersHeaders } from '../OffersHeader/OffersHeaderConstants';
import OffersRows from '../OffersRows';
import OffersHeader from '../OffersHeader/OffersHeader';
import { Button } from '../../../../../common/_custom';
import WhiteBackGroundPencilIcon from '../../../../../assets/Svg/WhiteBackGroundPencilIcon';
import { debtProductsEmpty } from '../../../../../assets/hostedassets';
import { OFFERS_TEXT, OFFERS_CONFIG, CAPITAL_REQUIREMENT_BUTTON } from '../OffersConstants';

interface CompanyOffersTableProps {
    rows?: any[];
    loading?: boolean;
    openCapitalRequirementDrawer: (referer: string) => void;
    showEmptyStateButton: boolean;
}

const CompanyOffersTable: React.FC<CompanyOffersTableProps> = ({
    rows,
    loading,
    openCapitalRequirementDrawer,
    showEmptyStateButton,
}) => {
    return (
        <div className={styles.Container}>
            <table className={styles.Table}>
                <thead className={styles.TableHead}>
                    <OffersHeader headers={OffersHeaders} />
                </thead>
                {loading ? (
                    <div className={styles.Loader}>
                        <LoadingSpinner color="black" height="40px" />
                    </div>
                ) : rows?.length === 0 ? (
                    <tbody className={styles.TableBody}>
                        <div className={styles.EmptyScreen}>
                            <img src={debtProductsEmpty} alt="Empty" />
                            {OFFERS_TEXT.NO_OFFERS}
                            {showEmptyStateButton && (
                                <Button
                                    variant="purple"
                                    text={CAPITAL_REQUIREMENT_BUTTON}
                                    onClick={() =>
                                        openCapitalRequirementDrawer('top-right-offersPage')
                                    }
                                    size="small"
                                    style={{ height: OFFERS_CONFIG.BUTTON_HEIGHT }}
                                    iconStyles={{ marginRight: 8, marginBottom: 5 }}
                                    icon={
                                        <WhiteBackGroundPencilIcon
                                            width={20}
                                            height={20}
                                            fill="black"
                                        />
                                    }
                                />
                            )}
                        </div>
                    </tbody>
                ) : (
                    <tbody className={styles.TableBody}>
                        <OffersRows headers={OffersHeaders} rows={rows} />
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default CompanyOffersTable;
