import clsx from 'clsx';
import React from 'react';
// import { formatPeriodForTable } from '../../../utils/utils';

import styles from '../OperatingInflowReconciliation.module.scss';

interface TableHeaderProps {
    data: { name: string }[] | undefined;
}

function Header(props: TableHeaderProps) {
    const { data } = props;

    return (
        <tr className={styles.TableHeadRow}>
            {data
                ? data.map((column, i) => (
                      <th key={i} className={styles.TableHeadRowItem}>
                          {column?.name || '-'}
                      </th>
                  ))
                : null}
        </tr>
    );
}

export default Header;
