import { warnIconOrange } from '../../assets/hostedassets';
import styles from './EmailVerificationBanner.module.scss';
import React from 'react';

const EmailVerificationBanner = ({ onResend }: { onResend: () => void }) => {
    return (
        <div className={styles.EmailNotVerified}>
            <img className={styles.WarnIcon} src={warnIconOrange} alt="Warning" />
            Please verify your email to start receiving important updates.{' '}
            <button className={styles.ResendVerificationCta} onClick={onResend}>
                Resend verification email
            </button>
        </div>
    );
};

export default EmailVerificationBanner;
