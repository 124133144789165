import clsx from 'clsx';
import React from 'react';
// import { formatPeriodForTable } from '../../../utils/utils';

import styles from '../BankDataTable.module.scss';

interface TableHeaderProps {
    data: { name: string }[] | undefined;
}

function Header(props: TableHeaderProps) {
    const { data } = props;

    const formatHeader = (text = '') => {
        return text?.replace('_20', "'");
    };
    return (
        <tr className={styles.TableHeadRow}>
            {data
                ? data.map((column, i) => (
                      <th key={i} className={styles.TableHeadRowItem}>
                          {i === 0 ? column.name : formatHeader(column.name)}
                      </th>
                  ))
                : null}
        </tr>
    );
}

export default Header;
