import './GstOtp.scss';
import { useGSTContext } from '../../Context/GstContext';
import styles from '../GstErrorScreens/GstErrorScreens.module.scss';
import OTP from '../../../../../../../../common/_custom/OTP/OTP';
import { useState } from 'react';
import { Button } from '../../../../../../../../common/_custom';

const GstOtp = () => {
    const { setOtp, otpBtnDisable, handleSubmitOtp, gstData, handleBack, step } = useGSTContext();
    const [resetOtp, setResetOtp] = useState(false);

    return (
        <>
            <div className={styles.GstContentContainer}>
                <div className={styles.GstContainer}>
                    <p className={styles.Label}>{gstData?.state} </p>
                    <p className={styles.SubLabel}>
                        {gstData?.gstin_number ? `(${gstData?.gstin_number})` : ''}
                    </p>
                </div>
                <div>
                    <span>
                        OTP sent to{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            mobile number registered with GST
                        </span>
                    </span>
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <OTP setUserOtp={setOtp} resetOtp={resetOtp} setResetOtp={setResetOtp} />
                </div>
            </div>

            <div className={styles.Footer}>
                <Button
                    text={'Back'}
                    onClick={() => {
                        handleBack(step.component);
                    }}
                    variant={'secondary'}
                    size={'wide'}
                    style={{ marginTop: '0' }}
                />
                <Button
                    text={'Submit OTP'}
                    onClick={handleSubmitOtp}
                    size={'wide'}
                    disabled={otpBtnDisable}
                    variant={'primary'}
                    style={{ marginTop: '0' }}
                />
            </div>
        </>
    );
};

export default GstOtp;
