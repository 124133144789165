import { DASHBOARD_DATA_TYPES } from '../../modules/Dashboard';
import {
    AICA_REVENUE_CHARTS,
    AICA_REVENUE_TABLE,
    AICA_VENDOR_CHARTS,
    AICA_VENDOR_TABLE,
    INVESTOR_CHARTS_CLICK,
    INVESTOR_OPEN_TAB,
    INVESTOR_TABLE_CLICK,
} from '../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../utils/dateUtils';
import { Mixpanel } from '../../utils/mixpanel';
import { setRolesForUser } from '../user/userV2/reducer';

export function mixpanelDashboardTabUpdates(dataType = '', dashboardTab, selectedCompany, user) {
    if (dataType === DASHBOARD_DATA_TYPES.CHARTS) {
        if (dashboardTab?.selectedTab === 'revenue') {
            Mixpanel.track(AICA_REVENUE_CHARTS, {
                Role: setRolesForUser(user?.userData?.roles?.[0]),
                InvestorOrgId: user?.userData.investorOrgId,
                AicaSection: selectedCompany?.companyAccessType,
                TimeStamp: getTimeStamp(),
            });
        } else if (dashboardTab?.selectedTab === 'vendorExpenses') {
            Mixpanel.track(AICA_VENDOR_CHARTS, {
                Role: setRolesForUser(user.userData?.roles?.[0]),
                InvestorOrgId: user.userData?.investorOrgId,
                AicaSection: selectedCompany?.companyAccessType,
                TimeStamp: getTimeStamp(),
            });
        }
    } else if (dataType === DASHBOARD_DATA_TYPES.TABLE) {
        if (dashboardTab?.selectedTab === 'revenue') {
            Mixpanel.track(AICA_REVENUE_TABLE, {
                Role: setRolesForUser(user.userData?.roles?.[0]),
                InvestorOrgId: user?.userData?.investorOrgId,
                AicaSection: selectedCompany?.companyAccessType,
                TimeStamp: getTimeStamp(),
            });
        } else if (dashboardTab?.selectedTab === 'vendorExpenses') {
            Mixpanel.track(AICA_VENDOR_TABLE, {
                Role: setRolesForUser(user?.userData?.roles?.[0]),
                InvestorOrgId: user?.userData?.investorOrgId,
                AicaSection: selectedCompany?.companyAccessType,
                TimeStamp: getTimeStamp(),
            });
        }
    }
}

export function mixpanelRegisterDataTypeChange(
    dataTypeId: string,
    selectedTab: string,
    selectedCompany,
) {
    let eventName = '';
    switch (dataTypeId) {
        case DASHBOARD_DATA_TYPES.CHARTS:
            eventName = INVESTOR_CHARTS_CLICK;
            break;
        case DASHBOARD_DATA_TYPES.TABLE:
            eventName = INVESTOR_TABLE_CLICK;
            break;
        default:
            break;
    }
    if (eventName) {
        Mixpanel.track(eventName, {
            TabName: selectedTab,
            Timestamp: getTimeStamp(),
            AICASection: selectedCompany?.companyAccessType,
            CompanyId: selectedCompany?.assesseeOrgId,
        });
    }
}

export function mixpanelRegisterTabChangeClick(tabName: string, selectedCompany) {
    Mixpanel.track(INVESTOR_OPEN_TAB, {
        TabName: tabName,
        TimeStamp: getTimeStamp(),
        AICASection: selectedCompany?.companyAccessType,
    });

    switch (tabName) {
    }
}
