import styles from './DashboardHeader.module.scss';
import { ACCESS_TYPES } from '../../../../store/investor/constants';
import { FEATURE_STATUS } from '../../../../store/user/constants';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { backButtonImg, backButtonImgHovered } from './TabConstants';
import ReportNavigation from './components/ReportNavigation/ReportNavigation';
import HeaderButtonContainer from './components/HeaderButtonContainer/HeaderButtonContainer';
// import { tabOptions } from '../../../../common/Topbar/Topbar';
import React, { useEffect, useState } from 'react';
import { INVESTOR_ROUTES } from '../../../../utils/constants/routesConst';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { commentsIcon, magicUploadIcon } from '../../../../assets/hostedassets';
import { closeCommentsThread, setShowCommentsThread } from '../../../../store/commentsThread/commentsThread_actions';
import clsx from 'clsx';
import { removeTooltipContent, setTooltipContent } from '../../../../store/tooltip/tooltip_actions';
import { USER_TYPE } from '../../../../utils/constants/user';

type DashboardHeaderProps = {
    isUserRestricted: boolean;
    selectedCompany: any;
    metricScoreGenerated?: boolean;
    refreshReportStatus: string;
    downloadPDFStatus: string;
    goBackToPortfolio: () => void;
    renderHeaderButton: () => void;
    exportData: () => void;
};

const DashboardHeader = (props: DashboardHeaderProps) => {
    const {
        isUserRestricted,
        selectedCompany,
        metricScoreGenerated,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        exportData,
        renderHeaderButton,
    } = props;
    const dispatch = useDispatch();
    const isDSInvestor = localStorage.getItem('isDSInvestor') === 'true';
    const permissions = useAppSelector((state) => state.investor.permissions);
    const commentsThreadState = useAppSelector((state) => state.commentsThreadReducer);
    const userData = useAppSelector((state) => state.user.userData);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [selected, setSelected] = useState<any>({
        id: 'report',
        label: 'Aica Report',
        img: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIcon.svg',
        route: '',
        isLocked: false,
    });
    const isMagicUploadOpen =
        searchParams.get('showNeedInfo') === 'true' &&
        searchParams.get('isCompany') === 'true' &&
        searchParams.get('magicUpload') === 'true';

    const isPartnerOrLender = [USER_TYPE.LENDER, USER_TYPE.PARTNER].includes(userData.accountType);
    useEffect(() => {
        if (selected.id === 'dataroom') {
            navigate(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.DATA_ROOM_DETAILS}${params.id ?? ''}?${searchParams.toString()}`);
        }
    }, [selected]);

    const openCloseCommentsThread = () => {
        if (commentsThreadState.showCommentsThread) {
            dispatch(closeCommentsThread());
        } else {
            dispatch(
                setShowCommentsThread({
                    source: 'DASHBOARD',
                    assesseeOrgId: params.id as string,
                }),
            );
        }
    };

    const openMagicUpload = () => {
        searchParams.set('showNeedInfo', 'true');
        searchParams.set('isCompany', 'true');
        searchParams.set('magicUpload', 'true');
        setSearchParams(searchParams);
    };

    const showTooltipForName = (e) => {
        const target = e.currentTarget;
        if (target.scrollWidth <= target.clientWidth) return;
        dispatch(
            setTooltipContent({
                content: selectedCompany?.orgName,
                target: e.currentTarget,
                placement: 'bottom',
            }),
        );
    };

    const hideTooltip = () => {
        removeTooltipContent(dispatch);
    };

    return (
        <div className={styles.DashboardHeader}>
            <div className={styles.CompanyName}>
                {!isUserRestricted && (
                    <img
                        onMouseEnter={(e) => (e.currentTarget.src = backButtonImgHovered)}
                        onMouseLeave={(e) => (e.currentTarget.src = backButtonImg)}
                        alt="back"
                        src={backButtonImg}
                        className={styles.HeaderBackButton}
                        onClick={() => goBackToPortfolio()}
                    />
                )}
                <div className={styles.Name}>
                    {/*@todo: the first character is a fallback we need to add favicons here.*/}
                    <span className={styles.FavIcon}>{selectedCompany?.orgName?.slice(0, 1) ?? ''}</span>
                    <div className={styles.NameTextContainer}>
                        <div
                            className={styles.NameText}
                            title={selectedCompany?.orgName}
                            onMouseOver={showTooltipForName}
                            onMouseLeave={hideTooltip}
                        >
                            {selectedCompany?.orgName}
                        </div>
                        <div className={styles.SubText}>Created on {dayjs(selectedCompany?.dateCreated).format('DD MMM YYYY')}</div>
                    </div>
                </div>
                {/* {selectedCompany?.companyAccessType !== ACCESS_TIERS.WATCH &&
                   metricScoreGenerated && (
                       <AicaGradeBatch
                           showAicaGradeInfo={() => dispatch(setShowAicaGradeDrawer(true))}
                       />
                   )} */}
            </div>
            <div className={styles.DashboardHeaderMiddle}>
                {!isDSInvestor && <ReportNavigation selectedCompany={selectedCompany} />}
                {/*<TabSlider*/}
                {/*    selected={selected}*/}
                {/*    onChange={(tab) => {*/}
                {/*        setSelected(tab);*/}
                {/*    }}*/}
                {/*    navbarItems={[...tabOptions]}*/}
                {/*/>*/}
            </div>
            <div className={styles.DashboardHeaderRight}>
                <>
                    {permissions?.TRACK !== ACCESS_TYPES.LOCKED && renderHeaderButton()}

                    {!isPartnerOrLender && (
                        <>
                            <div
                                className={clsx(styles.MessageIcon, {
                                    [styles.MessageIcon_Active]: isMagicUploadOpen,
                                })}
                                onClick={openMagicUpload}
                            >
                                <img src={magicUploadIcon} alt="comments" height={'30px'} />
                            </div>
                            <div
                                className={clsx(styles.MessageIcon, {
                                    [styles.MessageIcon_Active]:
                                        commentsThreadState.showCommentsThread && !commentsThreadState.closingCommentsThread,
                                })}
                                onClick={openCloseCommentsThread}
                            >
                                <img src={commentsIcon} alt="comments" height={'30px'} />
                            </div>
                        </>
                    )}

                    <HeaderButtonContainer
                        exportData={exportData}
                        selectedCompany={selectedCompany}
                        refreshReportStatus={refreshReportStatus}
                        downloadPDFStatus={downloadPDFStatus}
                    />
                    {refreshReportStatus && refreshReportStatus === FEATURE_STATUS.SHOW && (
                        <div className={styles.RefreshReport}>Refresh Report</div>
                    )}
                    {downloadPDFStatus && downloadPDFStatus === FEATURE_STATUS.SHOW && (
                        <div className={styles.DownloadPDF}>Download PDF</div>
                    )}
                </>
            </div>
        </div>
    );
};

export default DashboardHeader;
