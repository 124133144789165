import clsx from 'clsx';
import React from 'react';
import styles from '../RevenueVendorExpenseTable.module.scss';

interface TableHeaderProps {
    data: { name: string }[] | undefined;
}

function Headers(props: TableHeaderProps) {
    const { data } = props;

    const transformMonthYear = (header: string) => {
        const parts = header.split(' ');
        if (parts.length === 2 && !isNaN(Number(parts[1]))) {
            return `${parts[0]}'${parts[1]}`;
        }
        return header;
    };

    const isMonthYear = (header: string) => {
        const parts = header.split(' ');
        return parts.length === 2 && !isNaN(Number(parts[1]));
    };

    return (
        <tr className={styles.TableHeadRow}>
            {data
                ? data.map((column, i) => (
                      <th
                          key={i}
                          className={clsx(styles.TableHeadRowItem, {
                              [styles.RightAligned]: isMonthYear(column.name),
                          })}
                      >
                          {transformMonthYear(column.name)}
                      </th>
                  ))
                : null}
        </tr>
    );
}

export default Headers;
