import { Modal } from '../../_custom';
import GoogleSignIn from '../../GoogleSignIn/GoogleSignIn';
import styles from './IntegrationModal.module.scss';

const IntegrationModal = ({ open, onClose, onIntegrationSuccess }) => {
    return (
        <Modal open={open} onClose={onClose} style={{ width: '28rem', height: 'fit-content' }}>
            <div className={styles.Wrapper}>
                <h2 className={styles.Heading}>Connect Email</h2>
                <h6 className={styles.SubHeading}>
                    Connect your email to automatically sync attachments to your platform.
                </h6>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2rem 0',
                    }}
                >
                    <GoogleSignIn />
                </div>
            </div>
        </Modal>
    );
};

export default IntegrationModal;
