import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { Mixpanel } from '../../../../utils/mixpanel';
import { AICA_RELATED_PARTY_ALERTS, AICA_RELATED_PARTY_RELATIONSHIP } from '../../../../utils/constants/mixpanelEvents/investorEvents';

export const useRelatedPartiesDataFetch = (isPDFReport = false, fetchRelatedParties) => {
    const params = useParams();
    const assesseeOrgId = params.id as string;
    const user = useAppSelector((state) => state.user);

    const [expandAll, setExpandAll] = useState(false);

    const [showAlerts, setShowAlerts] = useState<null | AlertData>(null);

    const [relatedPartyData, setRelatedPartyData] = useState<RelatedPartyData>({
        data: {},
        lastUpdatedAt: null,
    });
    const relatedPartyGroupedData = useMemo(() => {
        const dataWithEntries = {};
        const dataWithoutEntries = {};

        Object.entries(relatedPartyData.data).forEach(([edgeType, entries]) => {
            const hasEntries = Object.values(entries).some((entryArray) => entryArray?.length > 0);
            if (hasEntries) {
                dataWithEntries[edgeType] = entries;
            } else {
                dataWithoutEntries[edgeType] = entries;
            }
        });

        return { ...dataWithEntries, ...dataWithoutEntries };
    }, [relatedPartyData]);

    useEffect(() => {
        if (isPDFReport) setExpandAll(true);
    }, [isPDFReport]);

    useEffect(() => {
        fetchRelatedParties();
    }, []);

    const isDataEmpty = () => {
        return !Object.entries(relatedPartyGroupedData).some(([edgeType, entries]: [string, any]) => Object.keys(entries).length > 0);
    };

    const handleAlertsClick = (relatedParty) => {
        Mixpanel.track(AICA_RELATED_PARTY_ALERTS, {
            parentID: user.userData.investorOrgId,
            alertsCount: relatedParty?.alerts?.length,
            risk: relatedParty?.risk,
            websiteUrl: window.location.href,
            companyOrgId: assesseeOrgId,
        });
        setShowAlerts(relatedParty?.alerts);
    };

    const handleShowRelationsClick = (entityEntry, edgeType) => {
        if (!entityEntry.isExpanded) {
            Mixpanel.track(AICA_RELATED_PARTY_RELATIONSHIP, {
                parentID: user.userData.investorOrgId,
                degree: '1',
                websiteUrl: window.location.href,
                companyOrgId: assesseeOrgId,
            });
        }
        expandCollapseSelectedRow(edgeType, entityEntry?.fromKid, !entityEntry.isExpanded);
    };

    const expandCollapseSelectedRow = (edgeType: string, fromKid: string, expand: boolean) => {
        if (!expand && expandAll) setExpandAll(false);
        setRelatedPartyData((relatedPartiesData) => ({
            lastUpdatedAt: relatedPartiesData.lastUpdatedAt,
            data: {
                ...relatedPartiesData.data,
                [edgeType]: {
                    ...relatedPartiesData.data[edgeType],
                    [fromKid]: [
                        {
                            ...relatedPartiesData.data?.[edgeType]?.[fromKid]?.[0],
                            isExpanded: expand,
                        },
                    ],
                },
            },
        }));
    };

    const toggleExpandCollapseAll = () => {
        setExpandAll((prevExpandAll) => !prevExpandAll);
        setRelatedPartyData((prevRelatedPartyData) => {
            const updatedData = { ...prevRelatedPartyData.data };

            Object.keys(updatedData).forEach((edgeType) => {
                updatedData[edgeType] = Object.keys(updatedData[edgeType]).reduce((acc, fromKid) => {
                    acc[fromKid] = [
                        {
                            ...updatedData[edgeType][fromKid][0],
                            isExpanded: !expandAll,
                        },
                    ];
                    return acc;
                }, {});
            });

            return {
                ...prevRelatedPartyData,
                data: updatedData,
            };
        });
    };

    return {
        expandAll,
        showAlerts,
        relatedPartyData,
        relatedPartyGroupedData,

        //FUNCTIONS
        isDataEmpty,
        setShowAlerts,
        setRelatedPartyData,
        handleAlertsClick,
        handleShowRelationsClick,
        toggleExpandCollapseAll,
    };
};
