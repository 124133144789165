import { useEffect, useRef } from 'react';
import { AION_INGEST_RELATED_PARTIES_DATA } from './relatedParty_constants';
import { useAppSelector } from '../../../../app/hooks';
import { TabModule } from '../../../../utils/constants/commonConstants';

export const useRelatedPartyPolling = (fetchRelatedParties, setStatus, setLoading) => {
    const apiPollingRef = useRef<any>(null);
    const POLLING_LIMIT = 20;
    const pollingAttemptsRef = useRef(0);
    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);

    useEffect(() => {
        if (modulePollingList.includes(TabModule.RELATED_PARTY)) {
            fetchRelatedParties();
        }
    }, [modulePollingList]);

    const startPolling = (taskTrackerData = {}) => {
        if (!apiPollingRef.current) {
            apiPollingRef.current = setInterval(() => {
                pollingAttemptsRef.current += 1;

                if (pollingAttemptsRef.current < POLLING_LIMIT || AION_INGEST_RELATED_PARTIES_DATA in taskTrackerData) {
                    fetchRelatedParties(true);
                } else {
                    setStatus('COMPLETE');
                    stopPolling();
                }
            }, 10000);
        }
    };

    const stopPolling = () => {
        if (apiPollingRef.current) {
            clearInterval(apiPollingRef.current);
            apiPollingRef.current = null;
        }
        pollingAttemptsRef.current = 0;
        setLoading(false);
    };

    return {
        startPolling,
        stopPolling,
    };
};
