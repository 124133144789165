import React, { useEffect } from 'react';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import { COLORS } from '../../../../../../utils/constants/colors';
import styles from './DealTermsLoggingDrawer.module.scss';
import { CollapsableDropdown } from '../CollapsableDropdown/CollapsableDropdown';
import { Button } from '../../../../../../common/_custom';
import { ManageDealDrawerHeader } from '../../../../../../common/ManageDealDrawer/Components/ManageDealDrawerHeader';
import StatusDropdown, {
    StatusDropdownOption,
} from '../../../../Portfolio/components/CompaniesList/components/Table/components/StatusDropdown/StatusDropdown';
import { STATUS_DROPDOWN_OPTIONS } from '../DealsTable/components/DealsRow/DealsRow';
import { ClockTimer, plusWhite } from '../../../../../../assets/hostedassets';
import DealTermsLog from '../DealTermsLog/DealTermsLog';
import DeleteIcon from '../../../../../../assets/Svg/DeleteIcon';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { useDealTermsLoggingDrawer } from './useDealTermsLoggingDrawer';

interface DealTermsLoggingDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    investorDealId: string;
    borrowerName: string;
    source: string;
}

export type DealTermsLogType = {
    id?: string;
    tempId?: string;
    investorDealId: string;
    dealId: string;
    logStatus: string;
    logDate: string;
    owner: {
        name: string;
        userId: string;
        activityBy: string;
        activityDate: string;
        ownerType: string;
    };
    dealTerms: DealTerms;
};

export type DealTerms = {
    logDate: string;
    maximumDealAmount: number;
    minimumRoi: number;
    maximumRoi: number;
    minimumTenure?: number;
    maximumTenure: number;
    loanTypes: string[];
    securityOffered: string[];
    dealNotes: Array<{
        note: string;
        noteBy: string;
        noteDate: string;
    }>;
    documents: Array<{
        docId: number;
        documentName: string;
        action: string;
    }>;
};

export type DealTermsLogs = {
    dealTermsLogs: Array<DealTermsLogType>;
};

const DealTermsLoggingDrawer: React.FC<DealTermsLoggingDrawerProps> = ({ isOpen, onClose, investorDealId, borrowerName, source }) => {
    const {
        // States
        dealTermsLoggingConfig,
        dealTermsLogs,
        runtimeLogs,
        selectedStatus,
        hasChanges,
        expandedLogStatus,
        isLoading,
        isInitialLoading,
        latestLogRef,

        // Constants
        primaryCtaText,
        secondaryCtaText,
        headerCtsText,

        // Methods
        setIsFormValid,
        handleStatusChange,
        handleLogUpdate,
        handleDeleteRuntimeLog,
        handleDropdownToggle,
        handleClose,
        handleSave,
        sourceBasedActions,
        handleCancel,
        setExpandedLogStatus,
        deletingLogIds,
        handleDeleteWithAnimation,
    } = useDealTermsLoggingDrawer({
        isOpen,
        onClose,
        investorDealId,
    });

    useEffect(() => {
        if (dealTermsLogs?.dealTermsLogs?.length > 0) {
            sourceBasedActions(source);
        }
    }, [isInitialLoading]);

    if (!isOpen) return null;

    const renderCollapsedContent = (content, isRuntimeLog: boolean, isExpanded: boolean, onDelete?: () => void) => (
        <div className={styles.triggerContent}>
            <div className={styles.leftContent}>
                <div
                    style={{
                        backgroundColor: content.status?.backgroundColor || 'var(--secondary-bg-colour)',
                        color: content.status?.labelColor || 'var(--primary-text-colour)',
                        padding: '6px 12px',
                        borderRadius: '44px',
                        fontSize: '14px',
                        fontWeight: 500,
                    }}
                >
                    {content.status?.label}
                </div>
            </div>
            {!(isExpanded && isRuntimeLog) && (
                <div className={styles.rightContent}>
                    <img src={ClockTimer} alt="clock" />
                    {content.date && content.by && (
                        <span className={styles.dateBy}>
                            {new Date(content.date).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })}{' '}
                            by {content.by}
                        </span>
                    )}
                    {content.amount && <span className={styles.amount}>{content.amount} Cr</span>}
                </div>
            )}
            {isExpanded && isRuntimeLog && (
                <div
                    className={styles.deleteIcon}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete?.();
                    }}
                >
                    <DeleteIcon width={16} height={16} className={styles.deleteIcon} />
                </div>
            )}
        </div>
    );

    const AddNewButton = (
        <Button
            text={headerCtsText}
            onClick={() => {}}
            variant="purple"
            icon={plusWhite}
            size="small"
            style={{ height: '40px', marginTop: '0px', width: '137px' }}
            iconStyles={{ marginRight: '10px' }}
        />
    );

    return (
        <SideDrawer
            onClose={handleClose}
            showHeading={false}
            style={{
                backgroundColor: COLORS.LIGHT_GREY_BG,

                height: '100%',
            }}
            iconStyle={{ zIndex: 20 }}
            width={'50%'}
        >
            <div className={styles.DrawerHeader}>
                <ManageDealDrawerHeader
                    drawerLabel={'Deal Terms'}
                    investorName={borrowerName}
                    headerAction={
                        <StatusDropdown
                            dropdownOptions={Object.values(STATUS_DROPDOWN_OPTIONS)}
                            selectedOption={selectedStatus}
                            selectOption={handleStatusChange}
                            customTrigger={AddNewButton}
                        />
                    }
                />
            </div>
            <div className={styles.DrawerContent}>
                {isInitialLoading ? (
                    <div className={styles.loaderContainer}>
                        <LoadingSpinner height="44px" color={COLORS.PRIMARY_PURPLE} />
                    </div>
                ) : (
                    dealTermsLogs?.dealTermsLogs &&
                    [...dealTermsLogs?.dealTermsLogs, ...runtimeLogs].map((entry: DealTermsLogType, index, array) => {
                        const isRuntimeLog = !entry?.id;
                        const logIdentifier = entry?.id || entry?.tempId;
                        const isLastLog = index === array?.length - 1;
                        const isDeleting = deletingLogIds.has(logIdentifier || '');

                        return (
                            <div
                                key={logIdentifier}
                                className={`${styles.DropdownContainer} ${isDeleting ? styles.deleting : ''}`}
                                ref={isLastLog ? latestLogRef : null}
                            >
                                <CollapsableDropdown
                                    triggerContent={(isExpanded) =>
                                        renderCollapsedContent(
                                            {
                                                date: entry?.logDate,
                                                by: entry?.owner?.name,
                                                amount: entry?.dealTerms?.maximumDealAmount,
                                                status: STATUS_DROPDOWN_OPTIONS[entry?.logStatus],
                                            },
                                            isRuntimeLog,
                                            isExpanded,
                                            isRuntimeLog ? () => handleDeleteWithAnimation(entry?.tempId!) : undefined,
                                        )
                                    }
                                    className="custom-dropdown"
                                    isExpanded={expandedLogStatus === logIdentifier}
                                    onToggle={(isExpanded) => handleDropdownToggle(entry, isExpanded)}
                                >
                                    <DealTermsLog
                                        formData={entry}
                                        dealTermsLoggingConfig={dealTermsLoggingConfig}
                                        setIsFormValid={setIsFormValid}
                                        onUpdateLog={handleLogUpdate}
                                    />
                                </CollapsableDropdown>
                            </div>
                        );
                    })
                )}
            </div>
            <div className={`${styles.DrawerFooter} ${hasChanges ? styles.visible : ''}`}>
                <div className={styles.ButtonContainer}>
                    <Button
                        text={secondaryCtaText}
                        onClick={handleCancel}
                        variant="secondary"
                        disabled={isLoading}
                        style={{ marginTop: '0px' }}
                    />
                    <Button
                        text={primaryCtaText}
                        onClick={handleSave}
                        variant="purple"
                        disabled={isLoading}
                        loading={isLoading}
                        style={{ marginTop: '0px' }}
                    />
                </div>
            </div>
        </SideDrawer>
    );
};

export default DealTermsLoggingDrawer;
