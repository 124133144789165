import styles from '../ManageUserTable/ManageUserTable.module.scss';
import { Headers } from '../useManageUser';
import loaderSvg from '../../../../assets/aica-loader.svg';
import clsx from 'clsx';
import { ManageUserDrawer } from '../../../Company/UploadData/components/ManageUserDrawer';
import { useOrgBasedUsersTable } from './useOrgBasedUsersTable';
import { debtProductsEmpty } from '../../../../assets/hostedassets';
import ManageDealDrawer from '../../../../common/ManageDealDrawer/ManageDealDrawer';
import { COMMENTS_THREAD_SOURCES } from '../../../../store/commentsThread/commentsThread_reducer';

export type OrgUser = {
    userId: string;
    emailId: string;
    name: string;
    mobileNumber: string;
    userType: string;
    organizationId: string;
    phoneVerified: boolean;
    emailVerified: boolean;
    countryCode: string;
};

export type OrgBasedUser = {
    organizationName: string;
    organizationDisplayName: string;
    organizationType: string;
    userData: OrgUser[];
    organizationOwner: string;
    organizationId: string;
    comment?: { text: string; activityDate: Date; activityBy: string };
};

export type OrgBasedUsersTableProps = {
    headers: Headers;
    rows: any;
    isRowsLoading: boolean;
    isSummonUser: boolean;
    actionMenuItems: Record<string, { action: (payload: any, source: string) => void }>;
    category: 'Partner' | 'Lender';
    actionConfig: string[];
    fetchRows: (pageNo: number) => Promise<void>;
};

const OrgBasedUsersTable = ({
    headers,
    rows,
    isRowsLoading,
    isSummonUser,
    actionMenuItems,
    category,
    actionConfig,
    fetchRows,
}: OrgBasedUsersTableProps) => {
    const {
        manageUserDrawerState,
        selectedOrgUsers,
        selectedOrgName,
        originalCategory,
        showDealDrawer,
        dealDrawerState,
        handleDrawerClose,
        getRowText,
        handleDealDrawerClose,
        handleManageUsers,
        commentsThreadState,
    } = useOrgBasedUsersTable({
        headers,
        rows,
        isRowsLoading,
        isSummonUser,
        actionMenuItems,
        category,
        actionConfig,
        fetchRows,
    });

    const renderTableBodyContent = () => {
        switch (true) {
            case isRowsLoading:
                // When loading
                return (
                    <tr>
                        <td colSpan={headers.length}>
                            <div className={styles.LoadingContainer}>
                                <img style={{ zIndex: 1000 }} src={loaderSvg} alt="Loading..." width={'80px'} />
                            </div>
                        </td>
                    </tr>
                );

            case !Array.isArray(rows) || rows.length === 0:
                // When no rows are available
                return (
                    <tr>
                        <td colSpan={headers.length}>
                            <div className={styles.EmptyState}>
                                <img src={debtProductsEmpty} alt="Empty" />
                                <p>No {category} Available</p>
                            </div>
                        </td>
                    </tr>
                );

            default:
                // When rows are available
                return rows.map((row: OrgBasedUser, rowIndex: number) => (
                    <tr
                        key={`${row?.organizationDisplayName}-${rowIndex}`}
                        className={clsx(styles.Row, styles.OrgBasedRow, {
                            [styles.OrgBasedRow_HoverActive]:
                                commentsThreadState.source === COMMENTS_THREAD_SOURCES.MANAGE_PARTNERS_LENDERS &&
                                commentsThreadState.organizationId === row.organizationId,
                        })}
                    >
                        {headers?.map((header, k) => (
                            <td
                                key={`${row?.organizationDisplayName}-${header.id}-${k}`}
                                className={clsx(styles.RowItem, {
                                    [styles.ActionColumn]: header.label === 'Actions',
                                    [styles.PartnerColumn]: category === 'Partner' && header.id === 1,
                                    [styles.CommentsRowItem]: header.label === 'Internal Comments',
                                })}
                                title={header.label !== 'Actions' ? row?.[header.id] : undefined}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleManageUsers(row.userData, row.organizationId, row.organizationDisplayName);
                                }}
                            >
                                {getRowText(row, header)}
                            </td>
                        ))}
                    </tr>
                ));
        }
    };

    const renderTableContent = () => {
        return (
            <table className={styles.Table}>
                <thead className={styles.TableHead}>
                    <tr className={styles.Header}>
                        {headers?.map((header, j) => (
                            <th
                                key={`header-${header.id}-${j}`}
                                className={clsx({
                                    [styles.HeadMain]: header.id === 0,
                                    [styles.ActionColumn]: header.label === 'Actions',
                                    [styles.HeadItem]: header.id !== 0,
                                    [styles.PartnerColumn]: category === 'Partner' && header.id === 1,
                                    [styles.InternalComments]: header.label === 'Internal Comments',
                                })}
                            >
                                {header.label !== 'Actions' ? header.label : ''}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={styles.TableBody}>{renderTableBodyContent()}</tbody>
            </table>
        );
    };

    return (
        <div className={styles.TableContainer}>
            {manageUserDrawerState && (
                <ManageUserDrawer
                    isOrgDrawer={true}
                    onClose={handleDrawerClose}
                    selectedOrgName={selectedOrgName}
                    selectedUsers={selectedOrgUsers}
                    parentCategory={originalCategory}
                />
            )}
            {renderTableContent()}
            {showDealDrawer && dealDrawerState.drawerSource !== 'ProfileButton' && (
                <ManageDealDrawer source={'OrgUsersTable'} onClose={handleDealDrawerClose} />
            )}
        </div>
    );
};

export default OrgBasedUsersTable;
