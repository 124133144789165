import React, { useEffect, useState } from 'react';
import styles from './ProvisionalFinancials.module.scss';
import { INTEGRATION_TYPES } from '../Integrations';
import clsx from 'clsx';
import { useAppSelector } from '../../../../../app/hooks';
import LandingCard from './components/LandingCard';
import ProvisionalsUploadCard, { FILE_CATEGORIES } from './components/ProvisionalsUploadCard';
import { useDispatch } from 'react-redux';
import {
    fetchMonthlyViewData,
    fetchUploadJourneyData,
    updateProvisionalStatements,
    updateUploadingStatus,
} from '../../../../../store/company/action';
import DebtSchedule from './components/DebtSchedule/DebtSchedule';
import { USER_TYPE } from '../../../../../utils/constants/user';
import SmallMonthlyView from '../SmallMonthlyView/SmallMonthlyView';
import { initializeMonthlyViewData } from '../../uploadJourneyUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import { ingestProvisionalStatements } from '../../../../../store/company/action';

type ProvisionalFinancialsProps = {
    categoryId: number;
    flowId: number;
    isInvestor: boolean;
};

function ProvisionalFinancials(props: ProvisionalFinancialsProps) {
    const { categoryId, flowId, isInvestor } = props;
    const dispatch = useDispatch();
    const provisionalData = useAppSelector(
        (state) => state.company.uploadJourneyData?.provisionalData?.docData,
    );
    const [selected, setSelected] = useState<string | null>(null);
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const [ingesting, setIngesting] = useState({});
    const configData = useAppSelector((state) => state.company.config?.tabConfigs);
    const showDebtSchedule = configData?.financials?.components?.find(
        (item) => item.name === 'debtSchedule',
    );
    const [integration, setIntegration] = useState<{ [key: string]: boolean } | null>({
        tally: false,
        manualUpload: false,
        zoho: false,
    });
    const [transition, setTransition] = useState('');

    const [params, setParams] = useSearchParams();
    const queryParams = useParams();
    const assesseeOrgId =
        useAppSelector((state) => state.user?.userData?.assesseeOrgId) ?? queryParams.id;
    const [errorData, setErrorData] = useState();
    const [fileData, setFileData] = useState();
    const [hovered, setHovered] = useState(false);
    const [showModal, setShowModal] = useState({
        mapDataModal: false,
        closeModal: false,
    });
    const [monthlyViewData, setMonthlyViewData] = useState<any>({});
    const [monthlyDataView, setMonthlyDataView] = useState<any>(null);
    const [showMonthlyView, setShowMonthlyView] = useState(false);
    useEffect(() => {
        dispatch(
            fetchMonthlyViewData({
                assesseeOrgId: assesseeOrgId,
                onSuccess: (res) => {
                    setMonthlyViewData(res);
                },
            }),
        );
    }, []);
    useEffect(() => {
        if (monthlyViewData) {
            initializeMonthlyViewData(monthlyViewData, setMonthlyDataView);
        }
    }, [monthlyViewData]);
    const monthlyView = [
        {
            label: 'PNL',
            availableMonthsCount: monthlyDataView?.pnl_complete_months,
            monthlyViewDates: monthlyDataView?.availablePnlMonthlyDates,
        },
        {
            label: 'Cashflow',
            availableMonthsCount: monthlyDataView?.cashflow_complete_months,
            monthlyViewDates: monthlyDataView?.availableCashflowMonthlyDates,
        },
        {
            label: 'Balance Sheet',
            availableMonthsCount: monthlyDataView?.balance_sheet_complete_months,
            monthlyViewDates: monthlyDataView?.availableBalanceSheetMonthlyDates,
        },
    ];
    useEffect(() => {
        if (uploadJourneyData?.provisionalData) {
            setIntegration(uploadJourneyData?.provisionalData?.integrations);
        }
    }, [uploadJourneyData]);

    const selectOption = (option: string) => {
        setTransition(option ? 'next' : 'previous');
        setTimeout(() => {
            setSelected((prev) => {
                if (prev === option) return null;
                else {
                    return option;
                }
            });
        }, 300);
    };
    useEffect(() => {
        setSelected(INTEGRATION_TYPES.manualUpload);
    }, []);
    useEffect(() => {
        // setShowMonthlyView(true) if any of the files have the key 'isDocConfirmed' as true
        provisionalData?.[FILE_CATEGORIES.manualUpload]?.forEach?.((file) => {
            if (file?.isDocConfirmed) {
                setShowMonthlyView(true);
                return;
            }
        });
        provisionalData?.[FILE_CATEGORIES.tally]?.forEach?.((file) => {
            if (file?.isDocConfirmed) {
                setShowMonthlyView(true);
                return;
            }
        });
    }, [provisionalData]);

    const integrationClick = (type: string) => {
        return selectOption(type);
    };
    const getContentCard = () => {
        switch (selected) {
            case INTEGRATION_TYPES.TALLY:
                return (
                    <ProvisionalsUploadCard
                        isInvestor={isInvestor}
                        fileData={fileData}
                        ingesting={ingesting}
                        errorData={errorData}
                        setErrorData={setErrorData}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        selected={selected}
                        selectOption={selectOption}
                        onUploadSuccess={onUploadSuccess}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            case INTEGRATION_TYPES.manualUpload:
                return (
                    <ProvisionalsUploadCard
                        isInvestor={isInvestor}
                        fileData={fileData}
                        ingesting={ingesting}
                        errorData={errorData}
                        setErrorData={setErrorData}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        selected={selected}
                        selectOption={selectOption}
                        onUploadSuccess={onUploadSuccess}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            default:
                return (
                    <LandingCard
                        selected={selected}
                        integration={integration}
                        selectOption={selectOption}
                        integrationClick={integrationClick}
                        isInvestor={isInvestor}
                    />
                );
        }
    };
    const onUploadSuccess = async (file, sourceSelected) => {
        setFileData(file);
        //write a constant sourcesIntegrated which only has sourcesSelected key as true and rest is the same as integration
        let sourcesIntegrated = { ...integration };
        for (let key in integration) {
            if (key === sourceSelected) {
                sourcesIntegrated = { ...sourcesIntegrated, [key]: true };
            }
        }
        let provisionalDoc = {
            docId: file?.docId,
            docName: file?.fileName,
            isDeleted: false,
            source: sourceSelected,
        };
        dispatch(
            updateProvisionalStatements({
                assesseeOrgId: assesseeOrgId,
                provisionalDoc,
                sourcesIntegrated,
                onSuccess: () => {
                    dispatch(
                        fetchUploadJourneyData({
                            accountType: USER_TYPE.COMPANY,
                            assesseeOrgId: assesseeOrgId ?? '',
                        }),
                    );
                    setIngesting({
                        ...ingesting,
                        [file?.docId]: true,
                    });
                    dispatch(
                        ingestProvisionalStatements({
                            assesseeOrgId: assesseeOrgId ?? '',
                            provisionalDoc: provisionalDoc,
                            sourcesIntegrated: sourcesIntegrated,
                            onSuccess: (response) => {
                                params.set('fileId', file?.docId);
                                params.set('currentScreen', '2');
                                setParams(params);
                                setErrorData(response);
                                setIngesting({
                                    ...ingesting,
                                    [file?.docId]: false,
                                });
                            },
                            onError: () => {
                                setIngesting({
                                    ...ingesting,
                                    [file?.docId]: false,
                                });
                            },
                        }),
                    );
                },
            }),
        );
    };
    return (
        <>
            <div
                style={isInvestor ? { width: '80%' } : {}}
                className={styles.ProvisionalCard}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div
                    className={clsx({
                        [styles.Next]: transition === 'next',
                        [styles.Previous]: transition === 'previous',
                    })}
                >
                    {getContentCard()}
                </div>
            </div>
            {/*{showMonthlyView && (*/}
            {/*    <SmallMonthlyView*/}
            {/*        category={'mis'}*/}
            {/*        isReview={false}*/}
            {/*        collapsibleMonthlyViewData={monthlyView}*/}
            {/*        parentHovered={hovered}*/}
            {/*    />*/}
            {/*)}*/}
            {showDebtSchedule && (
                <div style={isInvestor ? { width: '80%' } : {}} className={styles.ProvisionalCard}>
                    <DebtSchedule flowId={flowId} />
                </div>
            )}
        </>
    );
}

export default ProvisionalFinancials;
