import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import FinancialsFromBanking from './components/FinancialsFromBanking/FinancialsFromBanking';
import styles from './Financials.module.scss';
import ProvisionalFinancials from './components/ProvisionalFinanicals/ProvisionalFinancials';
import AuditedFinancialsVisualisation from '../AuditedFinancialsVisualisation/AuditedFinancialsVisualisation';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Mixpanel } from '../../../../utils/mixpanel';
import { AICA_CASHFLOW_BANKING } from '../../../../utils/constants/mixpanelEvents/investorEvents';

export const AUDITED_FINANCIALS = 'auditedFinancials';
export const PROVISIONAL_FINANCIALS = 'provisionalFinancials';
export const CASHFLOW_FROM_BANKING = 'cashflowFromBanking';
import AicaOverview from '../../../../common/AicaOverview/AicaOverview';
import { updateQueryParams } from '../../../../utils/searchParamUtils';

const FINANCIALS_TYPES = [
    { id: AUDITED_FINANCIALS, label: 'Audited Financials' },
    { id: PROVISIONAL_FINANCIALS, label: 'Provisional Financials' },
    { id: CASHFLOW_FROM_BANKING, label: 'Cashflow From Banking' },
];

interface FinancialsProps {
    selectedFinancials?: string;
    selectedMetric?: string;
    assesseeOrgId?: string;
}

function Financials({
    selectedFinancials = '',
    selectedMetric = '',
    assesseeOrgId = '',
}: FinancialsProps) {
    const [selectedFinancialsType, setSelectedFinancialsType] = useState(AUDITED_FINANCIALS);
    const [params, setParams] = useSearchParams();
    const location = useLocation();

    const isPDFReport = !!(selectedFinancials && selectedMetric);

    useEffect(() => {
        if (
            isPDFReport &&
            [AUDITED_FINANCIALS, PROVISIONAL_FINANCIALS, CASHFLOW_FROM_BANKING].includes(
                selectedFinancials,
            )
        ) {
            setSelectedFinancialsType(selectedFinancials);
        }
    }, [isPDFReport]);

    const getRenderComponent = () => {
        switch (selectedFinancialsType) {
            case CASHFLOW_FROM_BANKING:
                return <FinancialsFromBanking />;
            case AUDITED_FINANCIALS:
                return (
                    <AuditedFinancialsVisualisation
                        selectedMetric={selectedMetric}
                        isPDFReport={isPDFReport}
                        assesseeOrgId={assesseeOrgId}
                    />
                );
            case PROVISIONAL_FINANCIALS:
                return <ProvisionalFinancials />;
        }
    };
    useEffect(() => {
        if (params.get('financialsType')) {
            const financialsType = params.get('financialsType');
            if (financialsType) {
                setSelectedFinancialsType(financialsType);
            }
        }
    }, [params.get('financialsType')]);

    return (
        <div className={styles.Financials}>
            {!isPDFReport && (
                <div className={styles.Selector}>
                    {FINANCIALS_TYPES.map((type, i) => (
                        <div
                            key={`${type.id}-${i}`}
                            className={clsx(styles.Option, {
                                [styles.SelectedOption]: type.id === selectedFinancialsType,
                            })}
                            onClick={() => {
                                setSelectedFinancialsType(type.id);
                                updateQueryParams(
                                    params,
                                    setParams,
                                    { financialsType: type.id },
                                    location,
                                );

                                if (type.id === CASHFLOW_FROM_BANKING)
                                    Mixpanel.track(AICA_CASHFLOW_BANKING);
                            }}
                        >
                            {type.label}
                        </div>
                    ))}
                </div>
            )}
            {selectedFinancialsType === AUDITED_FINANCIALS && !isPDFReport && (
                <AicaOverview
                    style={{ margin: '1.5rem 0' }}
                    module={params.get('selectedTab') ?? AUDITED_FINANCIALS}
                />
            )}
            <div className={styles.Content} style={{ margin: isPDFReport ? '0' : '' }}>
                {getRenderComponent()}
            </div>
        </div>
    );
}

export default Financials;
