export const RISK_TYPES = {
    NO_DATA: 'NO_DATA',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    SEVERE: 'SEVERE',
};

export const RISK_MAP = {
    LOW: {
        label: 'Low',
        color: '#49A71B',
        background: '#E1FFE1',
        icon: '',
    },
    MEDIUM: {
        label: 'Medium',
        color: '#E4AB01',
        background: '#FFF2CB',
        icon: '',
    },
    HIGH: {
        label: 'High',
        color: '#DF666A',
        background: '#FFEEEE',
        icon: '',
    },
    SEVERE: {
        label: 'Severe',
        color: 'white',
        background: '#B25255',
        icon: '',
    },
    NO_DATA: {
        label: 'No Data',
        color: '#CBCBCB',
        background: '#EBEBEB',
        icon: '',
    },
};

export const getRiskType = (score: number | null) => {
    if (score === null || score === undefined || isNaN(score)) {
        return RISK_TYPES.NO_DATA;
    }

    if (score <= 1) return RISK_TYPES.LOW;
    else if (score > 1 && score <= 2) return RISK_TYPES.MEDIUM;
    else if (score > 2 && score <= 3) return RISK_TYPES.HIGH;
    else return RISK_TYPES.SEVERE;
};

export type MetricRiskBadgeProps = {
    score: null | number;
    isPDFReport: boolean;
};

export type RiskMapType = {
    label: string;
    color: string;
    background: string;
    icon: '';
};
