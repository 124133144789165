import clsx from 'clsx';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
    ReferenceLine,
    Label,
    LabelList,
} from 'recharts';
import styles from '../Graph.module.scss';
import {
    secondDataPointExists,
    thirdDataPointExists,
    formatPeriod,
} from '../../../../../store/dashboard/transformer';
import { useEffect, useState } from 'react';
import { CHART_NAMES } from '../../../../../store/dashboard/creditBureau/constants';

/**
 * @Description Bar graph component for 2 values for every x-coordinate
 */

interface StackedBarGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function StackedBarGraph(props: StackedBarGraphProps) {
    const { error, graphName } = props;
    const { amount, colors, hasNegative, plots, metadata } = props.graphData;
    const graphData = plots[0]?.plotValues || [];
    const data = error ? [] : amount;

    const [linesExists, setLineExists] = useState({
        secondBarExists: false,
        thirdBarExists: false,
    });

    useEffect(() => {
        if (metadata?.legend) {
            const secondBarExists = 'yValue2' in metadata?.legend;
            const thirdBarExists = 'yValue3' in metadata?.legend;
            setLineExists({
                secondBarExists,
                thirdBarExists,
            });
        }
    }, [metadata?.legend]);

    const getBarGap = () => {
        if (graphData?.length === 1) return '48%';
        else if (graphData?.length > 1 && graphData.length < 4) return '42%';
        else if (graphData?.length >= 4 && graphData.length < 8) return '35%';
        else if (graphData?.length >= 8) return '30%';
        else return '20%';
    };

    const getYAxisLabel = () => {
        switch (graphName) {
            case CHART_NAMES.REPAYMENTS_OVERVIEW:
                return 'Number of repayments';
        }
    };

    const showTotalValue = () => {
        switch (graphName) {
            case CHART_NAMES.REPAYMENTS_OVERVIEW:
                return true;
            default:
                return false;
        }
    };

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <BarChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                    top: 30,
                    right: 15,
                    left: -25,
                    bottom: 5,
                }}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="xValue"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    interval={0}
                    tickFormatter={(val) => formatPeriod(val)}
                />
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{
                            fontSize: isMobile ? '10px' : '14px',
                            top: 20,
                            marginTop: '-20px',
                        }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                    />
                ) : null}
                <YAxis
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    dx={5}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                {hasNegative ? <ReferenceLine y={0} stroke="#000000" /> : null}
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {/* <Legend
                    verticalAlign="top"
                    height={15}
                    align="right"
                    wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 20 }}
                    iconType="circle"
                /> */}
                <Bar
                    dataKey="yValue"
                    fill={colors?.primary}
                    radius={linesExists.secondBarExists ? [0, 0, 5, 5] : [5, 5, 5, 5]}
                    stackId="a"
                />
                {linesExists.secondBarExists && (
                    <Bar
                        dataKey="yValue2"
                        fill={colors?.secondary}
                        radius={linesExists.thirdBarExists ? [0, 0, 0, 0] : [5, 5, 0, 0]}
                        stackId="a"
                    >
                        {showTotalValue() && (
                            <LabelList
                                valueAccessor={(a) => a.yValue + a.yValue2}
                                position="top"
                                formatter={(val) => val?.toFixed(2) || ''}
                                style={{ fill: '#BCBCBC', fontSize: '12px' }}
                            />
                        )}
                    </Bar>
                )}
                {linesExists.thirdBarExists && (
                    <Bar
                        dataKey="yValue3"
                        fill={colors?.tertiary}
                        radius={[5, 5, 0, 0]}
                        stackId="a"
                    />
                )}
            </BarChart>
        </ResponsiveContainer>
    );
}

export default StackedBarGraph;
