export enum OffersHeaderKeys {
    NAME = 'name',
    PRODUCT = 'product',
    CREATE_DATE = 'createDate',
    AMOUNT = 'amount',
    STATUS = 'status',
}

export const OffersHeaders = [
    {
        key: 'collapsible',
        label: '',
    },
    {
        key: OffersHeaderKeys.NAME,
        label: 'Name',
    },
    {
        key: OffersHeaderKeys.PRODUCT,
        label: 'Product',
    },
    {
        key: OffersHeaderKeys.CREATE_DATE,
        label: 'Create Date',
    },
    {
        key: OffersHeaderKeys.AMOUNT,
        label: 'Amount',
    },
    {
        key: OffersHeaderKeys.STATUS,
        label: 'Status',
    },
];
