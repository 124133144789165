import { useEffect, useState } from 'react';
import styles from './CreditSummaryTable.module.scss';
import { useAppDispatch } from '../../../../../../app/hooks';
import { fetchCreditBureauSummaryTable } from '../../../../../../store/dashboard/creditBureau/actions';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';

type Header = {
    name: string;
    key: string;
};

type Row = {
    name?: string;
    investorName?: string;
    loanType?: string;
    accountStatus?: string;
    lastReported?: string;
    sanctionDate?: string;
    sanctionedLimit?: string;
    outstandingAmount?: string;
    currentOverdue?: string;
    emi?: string;
    tenure?: string;
    frequency?: string;
    accountRemarks?: string;
    ownership?: string;
    repaymentHistory?: string;
};

type TableData = {
    headers: Header[];
    cibilData: Row[];
};

function CreditSummaryTable({ assesseeOrgId }) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<TableData | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const directorId = searchParams.get('directorId');
    const bureauType =
        searchParams.get('bureauType') == 'All' ? null : searchParams.get('bureauType');

    useEffect(() => {
        assesseeOrgId && fetchTableData();
    }, [assesseeOrgId]);

    const fetchTableData = () => {
        setLoading(true);
        dispatch(
            fetchCreditBureauSummaryTable(
                {
                    assesseeOrgId,
                    ...(directorId && { directorId: directorId }),
                    ...(bureauType && { type: bureauType }),
                },
                {
                    onSuccess: (data) => {
                        setLoading(false);
                        setTableData(data);
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            ),
        );
    };

    return (
        <div className={styles.CreditSummaryTablePage}>
            <div className={styles.TableContainer}>
                {loading ? (
                    <div className={styles.Loading}>
                        <LoadingSpinner height="40px" color="#6021b3" />
                    </div>
                ) : (
                    <>
                        {tableData?.headers?.length && tableData?.cibilData?.length ? (
                            <table className={styles.CreditSummaryTable}>
                                <thead className={styles.TableHead}>
                                    <tr className={styles.TableHeadRow}>
                                        {tableData?.headers?.map((header, i) => (
                                            <td
                                                className={clsx(styles.TableHeadRowItem, {
                                                    [styles.SecondHeader]: i === 1,
                                                    [styles.ThirdHeader]: !directorId && i === 2,
                                                })}
                                            >
                                                {header?.name || 'no header'}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className={styles.TableBody}>
                                    {tableData?.cibilData?.map((row) => (
                                        <tr className={styles.TableBodyRow}>
                                            {tableData?.headers?.map((header, i) => (
                                                <td
                                                    className={clsx(styles.TableBodyRowItem, {
                                                        [styles.SecondColumn]: i === 1,
                                                        [styles.ThirdColumn]:
                                                            !directorId && i === 2,
                                                    })}
                                                >
                                                    {row?.[header?.key] || ''}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className={styles.NoData}>No Data Available</div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default CreditSummaryTable;
{
}
