import styles from './ProfileButton.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../../utils/constants/hooks';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ReactDOM from 'react-dom';
import { clearLocalStorageOnLogout, getInitialsFromName, getSubdomain, isRestrictedUser, setRolesForUser } from '../../../utils/utils';
import { ACCESS_TYPES, MANAGE_DROPDOWN, MANAGE_USERS_TABS, RESTRICTED_ROLES } from '../../../store/investor/constants';
import { USER_TYPE } from '../../../utils/constants/user';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Mixpanel } from '../../../utils/mixpanel';
import {
    INVESTOR_LOG_OUT,
    INVESTOR_MANAGE_INVESTORS,
    INVESTOR_MANAGE_PARTNER,
    INVESTOR_MANAGE_TEAM,
    INVESTOR_VIEW_AICA_SAMPLE_REPORT,
} from '../../../utils/constants/mixpanelEvents/investorEvents';
import { INVESTOR_ROUTES } from '../../../utils/constants/routesConst';
import {
    blackManageInvestors,
    blackManagePartners,
    blackManageTeam,
    blackSampleReport,
    dropdownBlackRounded,
    integrationBlack,
    integrationPurple,
    logout,
    pecSidebarCta,
    purpleManageInvestors,
    purpleManagePartners,
    purpleManageTeam,
} from '../../../assets/hostedassets';
import ManageDealDrawer from '../../ManageDealDrawer/ManageDealDrawer';
import { logoutUser } from '../../../store/user/userV2/actions';
import LoadingSpinner from '../../_custom/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify';
import IntegrationModal from '../IntegrationModal/IntegrationModal';
import InvestmentPreferenceIcon from '../../../assets/Svg/InvestmentPreferenceIcon';
import LogoutIcon from '../../../assets/Svg/Logouticon';
import SampleReportIcon from '../../../assets/Svg/SampleReportIcon';
import YourProfileIcon from '../../../assets/Svg/YourProfileIcon';
import { setDealDrawer } from '../../../store/investor/action';
import { setShowManageUserDrawer } from '../../../store/company/action';
import { COMPANY_LOGOUT } from '../../../utils/constants/mixpanelEvents/companyEvents';
import { PROFILE_BUTTON, PROFILE_LABEL } from '../../ManageDealDrawer/DealDrawerConstants';

const { PARTNER, INVESTOR, TEAM, COMPANY } = MANAGE_USERS_TABS;

const PROFILE_DROPDOWN = {
    YOUR_PROFILE: 'YOUR_PROFILE',
    MANAGE_USERS: 'MANAGE_USERS',
    INVESTMENT_PREFERENCE: 'INVESTMENT_PREFERENCE',
    INTEGRATIONS: 'INTEGRATIONS',
    LOGOUT: 'LOGOUT',
    SAMPLE_REPORT: 'SAMPLE_REPORT',
};

type ProfileOption = {
    id: string;
    label: string;
    img: string | JSX.Element;
    hoverImg: string | JSX.Element;
    show: boolean;
    action: () => void;
    isLoading?: boolean;
};

function ProfileButton() {
    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const user = useAppSelector((state) => state.user);
    const isUserLenderOrPno = user.userData.accountType === USER_TYPE.LENDER || user.userData.accountType === USER_TYPE.INVESTOR;
    const userDataFromStorage =
        JSON.parse(sessionStorage.getItem('user_data') as string) ?? JSON.parse(localStorage.getItem('user_data') as string) ?? '';
    const isCompanySide = userDataFromStorage?.accountType === USER_TYPE.COMPANY;
    const [showIntegrationModal, setShowIntegrationModal] = useState(false);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const uploadDataJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const openDealDrawer = (rowClicked = false, investmentPreferenceId = '') => {
        if (params.has('orgId')) {
            params.delete('orgId');
            setParams(params);
        }
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: PROFILE_LABEL,
                drawerSource: PROFILE_BUTTON,
            }),
        );
    };

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const buttonRef = useRef(null);
    const userData = user.userData;
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const isLender = user.userData.accountType === USER_TYPE.LENDER;
    const permissions = useAppSelector((state) => state.investor.permissions);
    const subDomain = getSubdomain();
    const demoOrgId = user.domainMetadata?.demoOrgId;
    const investor = useAppSelector((state) => state.investor);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const dashboardConfig: any = investor.config;
    const [options, setOptions] = useState<ProfileOption[]>([]);
    const [params, setParams] = useSearchParams();
    const [showOptions, setShowOptions] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const isMagicLink = searchParams.get('configName') === 'MAGIC_LINK';

    const [hoveredOptionId, setHoveredOptionId] = useState<string | null>(null);
    useOutsideClick(buttonRef, () => {
        setShowOptions(false);
    });

    useEffect(() => {
        if (permissions.permissionsFetched && permissions.TRACK === ACCESS_TYPES.LOCKED) {
            setOptions((options) => {
                return options.filter((option) => option.id !== PROFILE_DROPDOWN.INVESTMENT_PREFERENCE);
            });
        }
    }, [permissions]);

    useEffect(() => {
        setOptions((options) =>
            options.map((option) => {
                if (option.id === PROFILE_DROPDOWN.SAMPLE_REPORT) {
                    return {
                        ...option,
                        action: () => onClickSampleReport(demoOrgId),
                    };
                }
                return option;
            }),
        );
    }, [demoOrgId]);

    const setIsLoadingForOption = (optionId: string, loadingValue: boolean) => {
        setOptions((options) =>
            options.map((option) => ({
                ...option,
                ...(option.id === optionId && { isLoading: loadingValue }),
            })),
        );
    };

    const logoutUserFromApp = () => {
        setIsLoadingForOption(PROFILE_DROPDOWN.LOGOUT, true);
        dispatch(
            logoutUser(null, {
                onSuccess: () => {
                    setIsLoadingForOption(PROFILE_DROPDOWN.LOGOUT, false);
                },
            }),
        );
        if (isCompanySide) {
            Mixpanel.track(COMPANY_LOGOUT);
            navigate('/login');
        } else {
            Mixpanel.track(INVESTOR_LOG_OUT);
            navigate('/investor');
        }
        clearLocalStorageOnLogout();
    };

    const callMixPanel = (label) => {
        switch (label) {
            case MANAGE_DROPDOWN.MANAGE_TEAM:
                Mixpanel.track(INVESTOR_MANAGE_TEAM, {
                    userName: user?.userData?.userName,
                    userEmail: user?.userData?.primaryEmail,
                    PNOName: subDomain,
                });
                break;
            case MANAGE_DROPDOWN.MANAGE_INVESTORS:
                Mixpanel.track(INVESTOR_MANAGE_INVESTORS, {
                    userName: user?.userData?.userName,
                    userEmail: user?.userData?.primaryEmail,
                    PNOName: subDomain,
                });
                break;
            case MANAGE_DROPDOWN.MANAGE_PARTNERS:
                Mixpanel.track(INVESTOR_MANAGE_PARTNER, {
                    userName: user?.userData?.userName,
                    userEmail: user?.userData?.primaryEmail,
                    PNOName: subDomain,
                });
                break;
        }
    };

    const goToManageUser = (label) => {
        setShowOptions(false);
        setParams(params);
        callMixPanel(label);
        navigate(`${INVESTOR_ROUTES.MANAGE_USER}?category=${params.get('category')?.toString()}`);
    };

    const goToManageEligibility = () => {
        Mixpanel.track('Manage Eligibility Opened', {
            userEmail: userData?.primaryEmail,
            PNOId: userData?.investorOrgId,
            PNOName: subDomain,
        });
        setShowOptions(false);
        navigate(INVESTOR_ROUTES.MANAGE_ELIGIBILITY);
    };

    const onClickSampleReport = (demoOrgId) => {
        const selectedTabIdQuery = searchParams.get('selectedTab');
        const selectedTabFromQuery = selectedTabIdQuery && dashboardConfig?.tabs?.find((tab) => tab?.id === selectedTabIdQuery);

        const roleName = setRolesForUser(user?.userData?.roles?.[0]);
        const trackingData = {
            role: roleName,
            investorOrgId: user?.userData?.investorOrgId,
            AICASection: selectedTabFromQuery ? selectedTabFromQuery?.id : 'Listing vew',
        };

        Mixpanel.track(INVESTOR_VIEW_AICA_SAMPLE_REPORT, trackingData);
        const navigateTo = () => {
            const path = window.location.pathname;
            return path.includes(INVESTOR_ROUTES.WATCH)
                ? 'WATCH'
                : path.includes(INVESTOR_ROUTES.SCAN)
                ? 'SCAN'
                : path.includes(INVESTOR_ROUTES.TRACK)
                ? 'TRACK'
                : path.includes(INVESTOR_ROUTES.DEALS)
                ? 'DEALS'
                : path.includes(INVESTOR_ROUTES.MONITOR)
                ? 'MONITOR'
                : '';
        };
        navigate(`/investor/app/portfolio/${demoOrgId}`, {
            state: {
                ...location.state,
                from: navigateTo(),
            },
        });
    };

    useEffect(() => {
        if (user.userData.fetched) {
            const updatedOptions: ProfileOption[] = [
                {
                    id: PROFILE_DROPDOWN.YOUR_PROFILE,
                    label: PROFILE_LABEL,
                    img: <YourProfileIcon colour="#1C1B1F" height="20" width="20" />,
                    hoverImg: <YourProfileIcon colour={'var(--primary-text-colour)'} height="20" width="20" />,
                    show: isUserLenderOrPno,
                    action: () => {
                        openDealDrawer();
                    },
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.MANAGE_USERS,
                    label: MANAGE_DROPDOWN.MANAGE_TEAM,
                    img: blackManageTeam,
                    hoverImg: purpleManageTeam,
                    show: true,
                    action: () => {
                        if (!isCompanySide) {
                            params.set('category', TEAM);
                            setParams(params);
                            goToManageUser(MANAGE_DROPDOWN.MANAGE_TEAM);
                        } else {
                            params.set('category', COMPANY);
                            setParams(params);
                            dispatch(setShowManageUserDrawer(true));
                        }
                    },
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.MANAGE_USERS,
                    label: MANAGE_DROPDOWN.MANAGE_PARTNERS,
                    img: blackManagePartners,
                    hoverImg: purpleManagePartners,
                    show: true,
                    action: () => {
                        params.set('category', PARTNER);
                        goToManageUser(MANAGE_DROPDOWN.MANAGE_PARTNERS);
                    },
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.MANAGE_USERS,
                    label: MANAGE_DROPDOWN.MANAGE_INVESTORS,
                    img: blackManageInvestors,
                    hoverImg: purpleManageInvestors,
                    show: true,
                    action: () => {
                        params.set('category', INVESTOR);
                        goToManageUser(MANAGE_DROPDOWN.MANAGE_INVESTORS);
                    },
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.INVESTMENT_PREFERENCE,
                    label: 'Investment Preferences',
                    img: pecSidebarCta,
                    hoverImg: <InvestmentPreferenceIcon colour={'var(--primary-text-colour)'} height="17" width="17" />,
                    show: true,
                    action: () => {
                        goToManageEligibility();
                    },
                },
                {
                    id: PROFILE_DROPDOWN.INTEGRATIONS,
                    label: 'Integrations',
                    img: integrationBlack,
                    hoverImg: integrationPurple,
                    show: true,
                    action: () => {
                        setShowIntegrationModal(true);
                    },
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.SAMPLE_REPORT,
                    label: 'Sample AICA Report',
                    img: blackSampleReport,
                    hoverImg: <SampleReportIcon colour={'var(--primary-text-colour)'} height="16" width="16" />,
                    show: true,
                    action: () => onClickSampleReport(demoOrgId),
                    isLoading: false,
                },
                {
                    id: PROFILE_DROPDOWN.LOGOUT,
                    label: `Logout (${isCompanySide ? userDataFromStorage?.userName ?? '' : user?.userData?.userName ?? ''})`,
                    img: logout,
                    hoverImg: <LogoutIcon color={'var(--primary-text-colour)'} height="15" width="15" />,
                    show: true,
                    action: () => logoutUserFromApp(),
                    isLoading: false,
                },
            ];

            setOptions(
                updatedOptions.map((option) => {
                    if (isCompanySide) {
                        if (
                            (option.id === PROFILE_DROPDOWN.MANAGE_USERS && option.label === MANAGE_DROPDOWN.MANAGE_TEAM) ||
                            option.id === PROFILE_DROPDOWN.LOGOUT
                        ) {
                            option.show = true;
                        } else {
                            option.show = false;
                        }
                    } else {
                        switch (option.id) {
                            case PROFILE_DROPDOWN.MANAGE_USERS:
                            case PROFILE_DROPDOWN.INTEGRATIONS:
                                option.show = !isPartner && !isUserRestricted && !isLender;
                                break;
                            case PROFILE_DROPDOWN.INVESTMENT_PREFERENCE:
                                option.show = !isPartner;
                                break;
                            case PROFILE_DROPDOWN.LOGOUT:
                                option.show = !isUserRestricted;
                                break;
                        }
                    }
                    return option;
                }),
            );
        }
    }, [
        user.userData.fetched,
        isPartner,
        isUserRestricted,
        isLender,
        isCompanySide,
        userDataFromStorage?.userName,
        user?.userData?.userName,
    ]);

    const renderOptionIcon = (option: ProfileOption, isHovered: boolean) => {
        if (option.isLoading) {
            return <LoadingSpinner height="14px" color="black" style={{ margin: '0' }} />;
        }

        const iconToRender = isHovered ? option.hoverImg : option.img;
        if (!iconToRender) return null;

        if (typeof iconToRender === 'string') {
            return <img src={iconToRender} alt={isHovered ? 'hover' : 'default'} height={15} width={15} className={styles.OptionImage} />;
        }

        return React.cloneElement(iconToRender);
    };

    return (
        <div className={styles.ProfileButton}>
            <div
                className={clsx(styles.MainButton, { [styles.MainButtonActive]: showOptions })}
                onClick={() => setShowOptions((prev) => !prev)}
                ref={buttonRef}
            >
                {!isCompanySide ? (
                    <img
                        className={styles.logoImage}
                        src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/profileDropdownImg.svg'}
                        alt="-"
                        height={20}
                    />
                ) : (
                    <div className={styles.companyLogo}>
                        <div className={styles.companyLogoPlaceholder}>
                            {getInitialsFromName(uploadDataJourneyData?.organizationName, false)}
                        </div>
                        <div className={styles.companyLogoText}>{uploadDataJourneyData?.organizationName}</div>
                    </div>
                )}

                {!isMagicLink && (
                    <img
                        className={clsx(styles.DropdownImg, {
                            [styles.DropdownImgActive]: showOptions,
                        })}
                        src={dropdownBlackRounded}
                        alt="-"
                        height="6px"
                    />
                )}
                {showOptions && !isMagicLink && (
                    <div className={styles.Options}>
                        {options
                            ?.filter?.((option) => !!option?.show)
                            ?.map((option, i) => {
                                const truncatedLabel =
                                    option.id === PROFILE_DROPDOWN.LOGOUT
                                        ? option.label.length > 17
                                            ? `${option.label.slice(0, 17)}...`
                                            : option.label
                                        : option.label;
                                return (
                                    <div
                                        className={styles.Option}
                                        key={i}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowOptions(false);
                                            option?.action?.();
                                        }}
                                        onMouseEnter={() => setHoveredOptionId(option.label)} // Set hovered option
                                        onMouseLeave={() => setHoveredOptionId(null)}
                                    >
                                        {renderOptionIcon(option, hoveredOptionId === option.label)}
                                        <span>{truncatedLabel}</span>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
            {showIntegrationModal && (
                <IntegrationModal
                    open={showIntegrationModal}
                    onClose={() => setShowIntegrationModal(false)}
                    onIntegrationSuccess={() => {
                        setShowIntegrationModal(false);
                        toast.success('Integration Successful');
                    }}
                />
            )}
            {showDealDrawer && <ManageDealDrawer source={dealDrawerState?.drawerSource ?? ''} />}
        </div>
    );
}

export default ProfileButton;
