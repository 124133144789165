import { useEffect, useRef, useState } from 'react';
import styles from './GSTR3BTable.module.scss';
import Tooltip from '../../../../../common/_custom/Tooltip/Tooltip';
import { infoIconGreen, infoIconYellow, infoToolTip } from '../../../../../assets/hostedassets';
// import { headers } from './data';
import clsx from 'clsx';
import { fetchGstr3bData, getGSTReports } from '../../../../../store/dashboard/revenueVendorExpenses/revenueVendorExpensesApiHelpers';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { infoTooltipGStR3b } from './gstr3Constants';
import dayjs from 'dayjs';

type tableHeaders = { value: string; type: string }[];
type MetricsData = Record<string, MetricData>;
type MetricData = {
    key: string;
    value: string;
    amounts: Record<string, string>;
};

interface Gstr3bTableData {
    headers: tableHeaders;
    metricsData: MetricsData;
    transformedGstData: Record<string, { gsts: { gst: string; state: string | null }[] }>;
}

interface GstConnectionData {
    totalCount: number;
    totalGstList: { gstNumber: string; gstStatus: string }[];
}

const GST_DATA = 'GST_DATA';
const GST_DATA_MODULE = 'GST_DATA_MODULE';
const UW_GSTR3B_DATA = 'UW_GSTR3B_DATA';
const AION_GET_GST_LIST = 'AION_GET_GST_LIST';

function Gstr3BTable({ dataSource, fetchHeadlinePanelData }) {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [gstr3bTableData, setGstr3bTableData] = useState<Gstr3bTableData | null>(null);
    const [gstConnectionData, setGstConnectionData] = useState<GstConnectionData | null>(null);
    const { headers, metricsData, transformedGstData } = gstr3bTableData || {};
    const noDataAvailable = !loading && !headers?.length;
    const dataSourceList = gstConnectionData?.totalGstList || [];

    const assesseeOrgId = params.id;
    let pollingIntervalRef: any = useRef(null);
    let pollingCount = useRef(0);

    useEffect(() => {
        if (assesseeOrgId) {
            fetchData(assesseeOrgId);
            fetchGSTReports();
        }

        return () => {
            clearInterval(pollingIntervalRef.current);
        };
    }, [assesseeOrgId]);

    const fetchData = (assesseeOrgId, isPollingCall = false) => {
        if (!isPollingCall) setLoading(true);
        else pollingCount.current += 1;
        fetchGstr3bData({ orgId: assesseeOrgId }, {})
            .then((data) => {
                let gstr3bData = data?.[UW_GSTR3B_DATA]?.data || {};
                let gstConnectionData = data?.[AION_GET_GST_LIST] || {};

                gstr3bData.headers = updateHeadersToFill(gstr3bData?.headers);
                setGstr3bTableData(gstr3bData);
                setGstConnectionData(gstConnectionData);
            })
            .catch((err) => console.error('Error in fetching gst3b data', err))
            .finally(() => setLoading(false));
    };

    const fetchGSTReports = () => {
        getGSTReports({ assesseeOrgId: assesseeOrgId }, {})
            .then((data) => {
                if (data?.some((item) => item.status === 'PROCESSING')) {
                    if (!pollingIntervalRef.current)
                        pollingIntervalRef.current = setInterval(() => {
                            fetchGSTReports();
                            fetchData(assesseeOrgId, true);
                            fetchHeadlinePanelData?.(true);
                        }, 10000);
                } else {
                    if (pollingIntervalRef.current || pollingCount.current >= 32) {
                        clearInterval(pollingIntervalRef.current);
                        pollingIntervalRef.current = null;
                        pollingCount.current = 0;
                    }
                }
            })
            .catch((err) => {});
    };

    const updateHeadersToFill = (headers = []) => {
        if (!headers?.length) return [];
        const firstHeader = headers?.[0];
        const lastHeader = headers[headers.length - 1];
        const middleHeaders: any = headers.slice(1, headers.length - 1);
        while (middleHeaders.length < 5) {
            middleHeaders.push({ value: '-', type: '-' });
        }
        return [firstHeader, ...middleHeaders, lastHeader];
    };

    const renderTooltip = (gsts = []) => {
        return (
            <div className={styles.Tooltip}>
                <div className={styles.TopText}>Charts are generated only for connected Gsts:</div>
                <div className={styles.Gsts}>
                    {dataSourceList?.map((gst: any, i) => {
                        const monthlyGst: any = gsts?.find((monthlyGst: any) => monthlyGst.gst === gst.gstNumber);
                        return (
                            <div className={styles.GstRow} key={i}>
                                <div className={styles.Left}>
                                    <span className={styles.State}>{gst?.state} </span>
                                    <span>{gst?.gstNumber}</span>
                                </div>
                                <div className={styles.Right}>
                                    <div
                                        className={clsx(styles.Badge, {
                                            [styles.NotConnected]: true,
                                            [styles.Connected]: monthlyGst?.dataSource === 'CONNECTED',
                                            [styles.Uploaded]: monthlyGst?.dataSource === 'UPLOADED',
                                        })}
                                    >
                                        {monthlyGst?.dataSource === 'CONNECTED'
                                            ? 'Connected'
                                            : monthlyGst?.dataSource === 'UPLOADED'
                                            ? 'Uploaded'
                                            : 'Not Connected'}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const getCountValue = (header, headersLength, index) => {
        const monthlyGstCount = transformedGstData?.[header]?.gsts?.length ?? '0';
        const totalGstCount = dataSourceList?.length ?? '-';
        return (
            <div
                className={clsx(styles.DataCountBadge, {
                    [styles.Green]: totalGstCount === monthlyGstCount,
                    [styles.Yellow]: totalGstCount !== monthlyGstCount,
                })}
            >
                {monthlyGstCount}/{totalGstCount}
                <Tooltip
                    content={renderTooltip(transformedGstData?.[header]?.gsts as [])}
                    direction={'top'}
                    style={{ top: `20px`, left: headersLength - index < 5 ? '-100px' : '' }}
                >
                    <img
                        className={styles.InfoIcon}
                        src={totalGstCount === monthlyGstCount ? infoIconGreen : infoIconYellow}
                        alt={''}
                        height={'14px'}
                    />
                </Tooltip>
            </div>
        );
    };

    return (
        <div className={styles.Gstr3b}>
            <div
                className={clsx(styles.Top, {
                    [styles.TopNoData]: loading || noDataAvailable,
                })}
            >
                <div className={styles.Left}>
                    <span className={styles.Title}>GSTR-3B Summary</span>
                    <Tooltip content={infoTooltipGStR3b} direction={'right'}>
                        <img src={infoToolTip} alt="info" height={'18px'} />
                    </Tooltip>
                </div>
                <div className={styles.Right}>
                    {loading || pollingIntervalRef.current ? (
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                            <LoadingSpinner color={'#6021b3'} height={'20px'} />
                            {pollingIntervalRef.current && <span className={styles.Nodata}>Processing (may take ~2 mins)</span>}
                        </div>
                    ) : headers?.length ? (
                        ''
                    ) : (
                        <span className={styles.Nodata}>No data available</span>
                    )}
                </div>
            </div>
            {!!(!loading && headers?.length) && (
                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <div className={styles.Headers}>
                            {headers?.map((header, i) => (
                                <div
                                    key={i}
                                    className={clsx(styles.Header, {
                                        [styles.Classification]: header.type === 'classification',
                                        [styles.Total]: header.type === 'total',
                                    })}
                                >
                                    {header.type === 'period' ? dayjs(header.value, 'MM-YYYY').format("MMM'YY") : header.value}
                                </div>
                            ))}
                        </div>

                        <div className={styles.Rows}>
                            <div className={styles.Row}>
                                {headers?.map((header, i) => (
                                    <div
                                        key={`row-item-${i}`}
                                        className={clsx(styles.RowItem, {
                                            [styles.Classification]: header.type === 'classification',
                                            [styles.Total]: header.type === 'total',
                                        })}
                                    >
                                        {header.type === 'classification'
                                            ? 'Data Available'
                                            : header.type === 'period'
                                            ? getCountValue(header.value, headers?.length, i)
                                            : '-'}
                                    </div>
                                ))}
                            </div>
                            {Object.entries(metricsData || {}).map(([key, metricData], i) => (
                                <div className={styles.Row}>
                                    {headers?.map((header, j) => (
                                        <div
                                            key={`metric-item-${key}-${j}`}
                                            className={clsx(styles.RowItem, {
                                                [styles.Classification]: header.type === 'classification',
                                                [styles.Total]: header.type === 'total',
                                            })}
                                        >
                                            {header.type === 'classification'
                                                ? metricData.value
                                                : `${
                                                      !!(metricData?.amounts?.[header.value] || metricData?.amounts?.[header.type])
                                                          ? ''
                                                          : ''
                                                  }${
                                                      metricData?.amounts?.[header.value]?.toLocaleString() ||
                                                      metricData?.amounts?.[header.type]?.toLocaleString() ||
                                                      '-'
                                                  }`}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Gstr3BTable;
