import { useState, useEffect, useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isNil } from 'lodash';

import { getAllUsers, getOrgBasedUsers, getPartnetConfigDataAndSetFormData } from '../../../store/investor/apiHelpers';
import { MANAGE_USERS_TABS, USER_TYPES } from '../../../store/investor/constants';
import { USER_TYPE } from '../../../utils/constants/user';
import { fetchInternalSummonAicaData, InternalSummonAicaDataPayload, InternalSummonUserType } from '../../../store/user/actions';
import { CompanySideHeaders, InvestorTableHeaders, ManageUserTableHeaders, PartnerTableHeaders } from './ManageUserConstants';
import { getPlatformUserId, getUserTypeList, openUserSummonTab } from '../../../utils/utils';
import { LENDER_ROUTES } from '../../../utils/constants/routesConst';
import { setShowManageUserDrawer } from '../../../store/company/action';
import { setDealDrawer } from '../../../store/investor/action';
import { ORG_USERS_TABLE, PROFILE_LABEL } from '../../../common/ManageDealDrawer/DealDrawerConstants';
import { setRefetchFlags } from '../../../store/commentsThread/commentsThread_actions';

const { PARTNER, INVESTOR, TEAM, COMPANY } = MANAGE_USERS_TABS;
const { LENDER, ADMIN_TEAM, PARTNER_TYPE, INVESTOR_TYPE, COMPANY_TYPE } = USER_TYPES;

export type Headers = {
    id: number;
    label: string;
    type: 'static' | 'dynamic';
    key?: string;
}[];

export type Rows = {
    actionCta: string[];
    userId: string;
    name: string;
    emailId: string;
    mobileNumber: string;
    userType: string;
    userStatus: string;
    organizationName: string;
    organizationDisplayName: string;
    additionalDetails: { [key: string]: string };
    partnerOrgId: string;
    organizationId: string;
}[];

export const useManageUser = (isOrgDrawer?: boolean, selectedUsers?: any[], parentCategory?: string | null) => {
    const dispatch = useDispatch();

    const userData = useAppSelector((state) => state.user.userData);
    const uploadDataJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const commentThreadState = useAppSelector((state) => state.commentsThreadReducer);
    const refetchUsersData = commentThreadState.refetchFlags.refetchPartnersFlag;

    const [pageNo, setPageNo] = useState<number>(1);
    const [params, setParams] = useSearchParams();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showInviteTeammateModal, setShowInviteTeammateModal] = useState<boolean>(false);
    const [headers, setHeaders] = useState<Headers>([]);
    const [rows, setRows] = useState<Rows | []>([]);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [currentRowUser, setCurrentRowUser] = useState<Rows | null>(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isRowsLoading, setIsRowsLoading] = useState(true);
    const [isHeadersLoading, setIsHeadersLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [entityCounts, setEntityCounts] = useState({
        countLender: 0,
        countPartner: 0,
        countTeam: 0,
    });

    const isCompanySide = isOrgDrawer || userData?.accountType === USER_TYPE.COMPANY;
    const organisationId = params.get('orgId');
    const FETCH_COUNT = params.get('category') === 'Team' ? 20 : 100;

    const getSummonUserType = (userType: string) => {
        switch (userType) {
            case 'Partner':
                return InternalSummonUserType.PARTNER;
            case 'Lender':
                return InternalSummonUserType.LENDER;
            case 'Team member':
                return InternalSummonUserType.ADMIN_TEAM;
            default:
                return null;
        }
    };

    const fetchDynamicHeaders = async () => {
        setIsHeadersLoading(true);

        // For company side or when viewing org users in drawer
        if (isCompanySide || selectedUsers) {
            setHeaders(CompanySideHeaders);
            setIsHeadersLoading(false);
            return;
        }

        if (params.get('category') === INVESTOR) {
            setHeaders(InvestorTableHeaders);
            setIsHeadersLoading(false);
            return;
        }

        if (params.get('category') === PARTNER) {
            setHeaders(PartnerTableHeaders);
            setIsHeadersLoading(false);
            return;
        }

        try {
            await getPartnetConfigDataAndSetFormData(
                {
                    primaryEmail: userData?.primaryEmail,
                    investorOrgId: userData?.investorOrgId,
                },
                {
                    onSuccess: (config) => {
                        let dynamicHeader: Headers = [...ManageUserTableHeaders];
                        Object.keys(config?.additionalDetailsConfig).forEach((key) => {
                            if (dynamicHeader.find((header) => header.label === config?.additionalDetailsConfig?.[key].placeholder)) return;
                            dynamicHeader.push({
                                id: dynamicHeader.length,
                                label: config?.additionalDetailsConfig?.[key].placeholder,
                                type: 'dynamic',
                                key: key,
                            });
                        });
                        dynamicHeader.push({
                            id: dynamicHeader.length,
                            label: 'Actions',
                            type: 'static',
                        });
                        setHeaders(dynamicHeader);
                    },
                    onError: () => {
                        setHeaders([...ManageUserTableHeaders, { id: 6, label: 'Actions', type: 'static' }]);
                    },
                },
            );
        } finally {
            setIsHeadersLoading(false);
        }
    };

    // Common Actions
    const createAddUserAction = (payload: any, source: any) => {
        setCurrentRowUser([
            {
                organizationId: payload?.organizationId,
                userType: payload?.userType || getSummonUserType(params.get('category') || ''),
                userId: '',
                name: '',
                emailId: '',
                mobileNumber: '',
                userStatus: '',
                organizationName: '',
                organizationDisplayName: '',
                additionalDetails: {},
                partnerOrgId: '',
                actionCta: [],
            },
        ]);
        setShowAddUserModal(true);
    };

    const createViewAccountAction = (payload: any, source: any) => {
        const platformUserId = getPlatformUserId();
        const summonPayload: InternalSummonAicaDataPayload = {
            parentOrgId: userData?.investorOrgId as string,
            parentEmailId: userData.investorPrimaryEmail,
            userId: payload?.userId,
            userType: payload?.userType,
            requesterUserId: platformUserId,
        };
        dispatch(
            fetchInternalSummonAicaData(summonPayload, {
                onSuccess: (userData) => {
                    openUserSummonTab({ ...userData, orgId: platformData?.investorOrgId });
                },
            }),
        );
    };

    // Investor Action Menu Items
    const INVESTOR_ACTION_MENU_ITEMS = {
        'Add User': { action: createAddUserAction },
        'View Account': { action: createViewAccountAction },
        'Investment Preferences': {
            action: (payload, source) => {
                if (!payload?.userType || !payload?.userId) {
                    toast.error('User not found for this organisation');
                    return;
                }
                const summonPayload: InternalSummonAicaDataPayload = {
                    parentOrgId: userData?.investorOrgId as string,
                    parentEmailId: userData.investorPrimaryEmail,
                    userId: payload?.userId,
                    userType: payload?.userType,
                    requesterUserId: getPlatformUserId(),
                };
                dispatch(
                    fetchInternalSummonAicaData(summonPayload, {
                        onSuccess: (userData) => {
                            openUserSummonTab({
                                ...userData,
                                orgId: platformData?.investorOrgId,
                                referrer: LENDER_ROUTES.MANAGE_ELIGIBILITY,
                            });
                        },
                    }),
                );
            },
        },
        'Edit Profile': {
            action: (payload, source) => {
                params.set('orgId', payload?.organizationId);
                setParams(params);
                dispatch(
                    setDealDrawer({
                        open: true,
                        drawerLabel: PROFILE_LABEL,
                        drawerSource: ORG_USERS_TABLE,
                        companyName: payload?.organizationName,
                    }),
                );
            },
        },
    };

    // Partner Action Menu Items
    const PARTNER_ACTION_MENU_ITEMS = {
        'Add User': { action: createAddUserAction },
        'View Account': { action: createViewAccountAction },
    };

    const getActionMenuItems = () => {
        const category = params.get('category')?.toString();
        switch (category) {
            case INVESTOR:
                return INVESTOR_ACTION_MENU_ITEMS;
            case PARTNER:
                return PARTNER_ACTION_MENU_ITEMS;
            default:
                return {};
        }
    };

    // Fetch Users or orgs
    const getUsers = async (pgNo: number) => {
        const category = params?.get('category')?.toString() ?? '';
        const userType = getUserTypeList(category, parentCategory);
        const isInvestorCategory = category === 'Investors';
        const pageSize = isInvestorCategory ? 200 : 20;
        const orgId = userData?.accountType === USER_TYPE.COMPANY ? userData?.assesseeOrgId || organisationId : userData?.investorOrgId;

        let users;
        if (category === TEAM || category === COMPANY) {
            users = await getAllUsers({
                pageSize,
                pageNumber: pgNo,
                orgId,
                userType,
            });
            setRows(users?.userDetails);
        } else {
            users = await getOrgBasedUsers({
                orgId: userData?.investorOrgId,
                userType,
                pageNumber: pgNo,
                orgName: searchQuery,
            });
            setRows(users?.data);
        }

        return users;
    };

    const fetchRows = async (pgNo: number, fetchWithoutReload = false) => {
        try {
            if (!fetchWithoutReload) {
                setIsRowsLoading(true);
                setRows([]);
            }
            const users = await getUsers(pgNo);

            if (!users || users.length === 0) {
                toast.error('No users found');
                return;
            }

            setEntityCounts({
                countLender: users?.countLender,
                countPartner: users?.countPartner,
                countTeam: users?.countTeam,
            });
            setHasNextPage(users?.hasNextPage);
        } catch (error) {
            toast.error('Failed to fetch users');
            setRows([]);
        } finally {
            if (refetchUsersData) dispatch(setRefetchFlags({ flagKey: 'refetchPartnersFlag', status: false }));
            setIsRowsLoading(false);
            setIsInitialLoad(false);
        }
    };

    const getTotalPages = (category: string | null) => {
        let count;
        switch (category) {
            case TEAM:
                count = entityCounts.countTeam;
                break;
            case PARTNER:
                count = entityCounts.countPartner;
                break;
            case COMPANY:
            case INVESTOR:
                count = entityCounts.countLender;
                break;
            default:
                count = 0;
        }
        return Math.ceil(count / FETCH_COUNT);
    };

    const getModalHeading = (category?: string) => {
        switch (category) {
            case INVESTOR:
                return 'Add Lender';
            case PARTNER:
                return 'Add Partner';
            case 'Team':
                return 'Add Teammate';
            default:
                return 'Add User';
        }
    };

    const initializeData = async () => {
        // For drawer view with selected users
        if (selectedUsers?.length || isOrgDrawer) {
            setRows(selectedUsers || []);
            setHeaders(CompanySideHeaders);
            setIsRowsLoading(false);
            setIsHeadersLoading(false);
            return;
        }

        // For normal table view
        if (!params.get('page')) {
            params.set('page', '1');
            setPageNo(1);
            setParams(params);
        } else {
            setPageNo(Number(params.get('page')));
        }

        setRows([]);
        await Promise.all([fetchRows(Number(params.get('page')) || 1), fetchDynamicHeaders()]);
    };

    useEffect(() => {
        initializeData();
    }, [params.get('category'), selectedUsers]);

    // Handle pagination changes
    useEffect(() => {
        if (!isInitialLoad) {
            fetchRows(pageNo);
        }
    }, [pageNo, searchQuery]);

    useEffect(() => {
        if (refetchUsersData) {
            fetchRows(pageNo, true);
        }
    }, [refetchUsersData, pageNo]);

    const getActionConfig = (category: string | null) => {
        switch (category) {
            case INVESTOR:
                return ['Add User', 'Manage User', 'View Account', 'Investment Preferences', 'Edit Profile'];
            case PARTNER:
                return ['Add User', 'Manage User', 'View Account'];
            default:
                return [];
        }
    };

    return {
        // States
        pageNo,
        params,
        showInviteTeammateModal,
        headers,
        rows,
        hasNextPage,
        showAddUserModal,
        currentRowUser,
        uploadDataJourneyData,
        isRowsLoading,
        isHeadersLoading,

        // Constants
        isCompanySide,

        // Methods
        setPageNo,
        setParams,
        setShowInviteTeammateModal,
        setShowAddUserModal,
        setCurrentRowUser,
        fetchRows,
        fetchDynamicHeaders,
        getTotalPages,
        getActionMenuItems,
        setSearchQuery,
        getActionConfig,
        getModalHeading,
    };
};
