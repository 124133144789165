import { infoToolTip } from '../../../../assets/hostedassets';
import Tooltip from '../../../../common/_custom/Tooltip/Tooltip';
import { RISK_HEADER_KEYS, RISK_MESSAGES_MAP } from '../../RiskIndicatorConstants';
import styles from '../../RiskIndicators.module.scss';

export interface Header {
    key: string;
    label: string;
}

export interface RiskIndicatorHeadersProps {
    headers: Header[];
    isPDFReport?: boolean;
}

export const RiskIndicatorHeaders = ({
    headers,
    isPDFReport = false,
}: RiskIndicatorHeadersProps) => {
    return (
        <div className={styles.TableHeadRow}>
            <div className={styles.StickyHeaders}>
                <span className={styles.TableHeadRowItem}>Name</span>
                {!isPDFReport && <span className={styles.TableHeadRowItem}>Risk Report</span>}
            </div>
            {headers?.map((header) => {
                const isLeftDirection = (
                    [
                        RISK_HEADER_KEYS.STATE_OWNED_ENTERPRISES_KEY,
                        RISK_HEADER_KEYS.COUNTRY_REGIMES_WATCHLIST_KEY,
                    ] as RISK_HEADER_KEYS[]
                ).includes(header.key as RISK_HEADER_KEYS);

                return (
                    <span key={header.key} className={styles.TableHeadRowItem}>
                        <span>{header.label}</span>
                        <span className={styles.InfoTip}>
                            <Tooltip
                                content={RISK_MESSAGES_MAP[header.key as RISK_HEADER_KEYS] || ''}
                                direction={'left'}
                                style={{ top: isLeftDirection ? '200%' : '210%' }}
                            >
                                <img src={infoToolTip} height={'14px'} alt="Information tooltip" />
                            </Tooltip>
                        </span>
                    </span>
                );
            })}
        </div>
    );
};
