import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { fetchSearchResults } from './GlobalSearchService';

interface IGetSearchResultsPayload {
    parentOrgId: string;
    globalSearchText: string;
    userId?: string;
    cancelToken?: any; // optional
}

export const getSearchResults = async (payload: IGetSearchResultsPayload) => {
    try {
        const { cancelToken, ...rest } = payload;
        const res = await fetchSearchResults(rest, cancelToken);

        if (res.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return res.data.data;
        } else {
            return false;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
};
