import axios from 'axios';
import { cerberusBaseUrl, GET, polusBaseUrl, POST } from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';
import {
    FetchInvestorConfigPayload,
    FetchOrgBasedUsersPayload,
    FetchPortfolioDataPayload,
    GetOwnerDataPayload,
    InitiateReportGenerationPayload,
    InviteCompanyPayload,
    InviteCompanyPayloadV2,
} from './action';
import { MonitoringStatus, RiskGrade } from './constants';

export function getInvestorConfig(payload: FetchInvestorConfigPayload) {
    return axios({
        url: API_ENDPOINTS.GET_CONFIG,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                investorOrgId: payload.investorOrgId,
                primaryEmail: payload.primaryEmail,
                assesseeOrgId: payload.assesseeOrgId,
                reportId: payload?.reportId || '',
                configName: payload.configName,
            },
            userRole: payload?.userRole,
        },
    });
}

export function getLenderConfig(payload: FetchInvestorConfigPayload) {
    return axios({
        url: API_ENDPOINTS.GET_CONFIG,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                investorOrgId: payload.investorOrgId,
                primaryEmail: payload.primaryEmail,
                assesseeOrgId: payload.assesseeOrgId,
                configName: payload.configName,
            },
            userRole: payload?.userRole,
        },
    });
}

export function getPortfolioData(payload: FetchPortfolioDataPayload) {
    let apipayload: { [key: string]: string | number } = {
        portfolioTypeToFetch: payload.portfolioTypeToFetch,
        pageNumber: payload.pageNumber,
        companyAccessType: payload.companyAccessType,
        requestId: getTimeStamp(),
    };
    if (payload.partnerUserId) apipayload.partnerUserId = payload.partnerUserId;

    return axios({
        url: `${API_ENDPOINTS.GET_PORTFOLIO}`,
        method: POST,
        data: apipayload,
        params: {
            ...(payload?.companyName && { companyName: payload.companyName }),
        },
    });
}

export function getOwnerData(payload: GetOwnerDataPayload) {
    return axios({
        url: API_ENDPOINTS.GET_OWNER_DATA,
        method: GET,
        params: {
            parentOrgId: payload?.investorOrgId,
        },
    });
}

export function getSingleCompanyDetailsService(payload: any) {
    return axios({
        url: API_ENDPOINTS.GET_SINGLE_COMPANY_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            ...payload,
        },
        headers: {
            'x-organization-id': payload?.assesseeOrgId,
        },
    });
}

export function initiateReportGenerationService(payload: InitiateReportGenerationPayload) {
    return axios({
        url: API_ENDPOINTS.INITIATE_REPORT_GENERATION,
        method: POST,
        data: {
            assesseeOrgId: payload.assesseeOrgId,
            requestId: getTimeStamp(),
        },
    });
}
export function fetchCityNames() {
    return axios({
        url: 'https://countriesnow.space/api/v0.1/countries/cities',
        method: 'POST',
        data: {
            country: 'India',
        },
    });
}

export function fetchAllUser(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_ALL_USERS,
        method: 'POST',
        data: {
            pageSize: payload.pageSize,
            pageNumber: payload.pageNumber,
            orgId: payload.orgId,
            userType: payload.userType,
        },
    });
}

export function fetchOrgBasedUsers(payload: FetchOrgBasedUsersPayload) {
    return axios({
        url: API_ENDPOINTS.GET_ORG_BASED_USERS,
        method: 'POST',
        data: {
            pageSize: payload.pageSize,
            pageNumber: payload.pageNumber,
            organisationId: payload.orgId,
            userType: payload.userType,
            organisationName: payload.orgName,
        },
    });
}

export function getAllInvestmentPreferenceData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_ALL_INVESTMENT_PREFERENCE_DATA,
        method: POST,
        data: payload,
    });
}

export function getAllInvestmentPreferences(payload: FetchAllInvestmentPreferencesPayload) {
    return axios({
        url: API_ENDPOINTS.GET_ALL_INVESTMENT_PREFERENCES,
        baseURL: polusBaseUrl,
        method: POST,
        data: payload,
    });
}

export function fetchAmlPepData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_AML_PEP_DATA,
        method: GET,
        params: {
            orgId: payload?.orgId,
            requestId: getTimeStamp(),
        },
    });
}

export const callEditUser = (payload) => {
    return axios({
        url: API_ENDPOINTS.EDIT_USER,
        method: POST,
        data: {
            userId: payload.userId,
            orgInfo: {
                userAdditionalDetails: payload.userAdditionalDetails,
            },
        },
    });
};

export function inviteCompanyService(payload: InviteCompanyPayload) {
    return axios({
        url: API_ENDPOINTS.INVITE_COMPANY,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
                // primaryUserName: payload.primaryUserName,
            },
        },
    });
}
export function inviteCompanyServiceV2(payload: InviteCompanyPayloadV2) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_ENDPOINTS.INVITE_COMPANY_V2}`,
        method: POST,
        data: payload,
    });
}

export function addToWatchlistService(payload: AddCompanyToWatchlistPayload) {
    return axios({
        url: API_ENDPOINTS.ADD_TO_WATCH,
        method: POST,
        data: {
            orgInfo: {
                ...payload,
            },
        },
    });
}

export function getInvestorAccessTypePermissionsService(payload: InvestorAccessTypePermissionServicePayload) {
    return axios({
        url: API_ENDPOINTS.GET_CONFIG,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
            },
        },
    });
}

export function moveCompanyToNewTierService(payload: AddCompanyToScanPayload) {
    return axios({
        url: API_ENDPOINTS.MOVE_COMPANY_TO_NEW_TIER,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
            },
        },
    });
}

export function inviteCompanyUserService(payload: AddCompanyToScanPayload) {
    return axios({
        url: API_ENDPOINTS.INVITE_COMPANY_USER,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
            },
        },
    });
}

export function updateCompanyService(payload: CompanyUpdatePayload) {
    return axios({
        url: API_ENDPOINTS.COMPANY_UPDATE,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: payload,
    });
}

export function createDealService(payload: any) {
    return axios({
        url: API_ENDPOINTS.CREATE_DEAL,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function createInvestmentPreference(payload: any) {
    return axios({
        url: API_ENDPOINTS.CREATE_INVESTMENT_PREFERENCE,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function getMetricsForDealCreationService(payload: FetchDealMetricsPayload) {
    return axios({
        url: API_ENDPOINTS.METRICS_FOR_DEAL_CREATION,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: {
                requestId: getTimeStamp(),
                ...payload,
            },
        },
    });
}

export function getPecMatchingDataService(payload: any) {
    return axios({
        url: API_ENDPOINTS.PEC_MATCHING,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function getAllDealsService(payload: FetchDealsPayload) {
    return axios({
        url: API_ENDPOINTS.GET_ALL_DEALS,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function getAllLenderDealsService(payload: FetchLenderDealsPayload) {
    return axios({
        url: API_ENDPOINTS.GET_ALL_LENDER_DEALS,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function getInvestmentPreference(investmentPreferenceId: string) {
    return axios({
        url: API_ENDPOINTS.GET_INVESTMENT_PREFERENCE,
        baseURL: polusBaseUrl,
        method: GET,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        params: {
            investmentPreferenceId,
        },
    });
}

export function updateInvestmentPreference(payload: any) {
    return axios({
        url: API_ENDPOINTS.UPDATE_INVESTMENT_PREFERENCE,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function updateDealService(payload: DealUpdatePayload) {
    return axios({
        url: API_ENDPOINTS.DEAL_UPDATE,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: payload,
        },
    });
}

export function updateInvestorDealService(payload: InvestorDealUpdatePayload) {
    return axios({
        url: API_ENDPOINTS.INVESTOR_DEAL_UPDATE,
        baseURL: polusBaseUrl,
        method: POST,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        data: {
            requestPayload: {
                dealId: payload.investorDealId,
                dealStatus: payload.dealStatus,
                lastUpdatedBy: payload.lastUpdatedBy,
                comment: payload.comment,
                owner: payload.owner,
                userId: payload.userId,
            },
        },
    });
}

export function unlockAicaForCompanyService(payload: UnlockAICAReportPayload) {
    return axios({
        url: API_ENDPOINTS.UNLOCK_AICA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
            },
            tabs: payload.tabs,
        },
    });
}

export function getDealDataService(payload: { dealId: string; dealBeneficiaryId?: string }) {
    return axios({
        url: API_ENDPOINTS.GET_DEAL_DATA,
        baseURL: polusBaseUrl,
        method: GET,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        params: {
            dealId: payload?.dealId,
            dealBeneficiaryId: payload?.dealBeneficiaryId,
        },
    });
}

export function getLenderDealDataService(payload: { dealId: string }) {
    return axios({
        url: API_ENDPOINTS.GET_LENDER_DEAL_DATA,
        baseURL: polusBaseUrl,
        method: GET,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        params: {
            dealId: payload.dealId,
        },
    });
}

export function getOnePagerDataService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_ONE_PAGER_DATA,
        baseURL: polusBaseUrl,
        method: POST,
        data: {
            requestPayload: {
                requestId: getTimeStamp(),
                ...payload,
                source: 'AICA_ONE_PAGER',
            },
        },
    });
}

export function getOnePagerTemplateDataService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_TEMPLATE_ONE_PAGER_FOR_ASSESSEE,
        method: GET,
        params: {
            ...payload,
        },
    });
}

export function saveOnePagerTemplateForAssesseeService(payload) {
    return axios({
        url: API_ENDPOINTS.SAVE_TEMPLATE_ONE_PAGER_FOR_ASSESSEE,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            ...payload,
        },
    });
}

export function getInvestmentReportService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_INVESTMENT_REPORT,
        method: GET,
        params: {
            requestId: getTimeStamp(),
            assesseeOrgId: payload?.assesseeOrgId,
        },
    });
}

export function getTabLockStatus(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_TAB_LOCK_STATUS,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            ...payload,
        },
        params: {
            assesseeOrgId: payload?.assesseeOrgId,
        },
    });
}

export function fetchAvailableProducts(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_AVAILABLE_PRODUCTS,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
            },
        },
    });
}

export function fetchCompanySector() {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_COMPANY_SECTOR,
        method: GET,
        params: {
            requestId: getTimeStamp(),
        },
    });
}

export function fetchAvailableDebtProducts(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_ALL_PRODUCTS,
        method: GET,
        params: {
            ...payload,
        },
    });
}

export function fetchOrgUser(payload: { orgId: string; accountType: string }) {
    return axios({
        url: API_ENDPOINTS.GET_ORG_USER,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                orgId: payload.orgId,
                accountType: payload.accountType,
            },
        },
    });
}

export function getAllTaskTrackerDataService(payload: { orgId: string }) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_ALL_TASK_TRACKER_DATA,
        method: GET,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
        },
        params: {
            requestId: getTimeStamp(),
            orgId: payload.orgId,
        },
    });
}

export function verifyWebsite(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.VERIFY_WEBSITE,
        method: POST,
        data: {
            requestPayload: {
                requestId: getTimeStamp(),
                ...payload,
            },
        },
    });
}

export function fetchOrgInfoService(payload: { orgId: string }) {
    return axios({
        url: API_ENDPOINTS.GET_ORG_INFO,
        method: GET,
        headers: {
            [REQUEST_ID_HEADER]: getTimeStamp(),
            ['x-organization-id']: payload.orgId,
        },
        params: {
            requestId: getTimeStamp(),
            orgId: payload.orgId,
        },
    });
}

const API_ENDPOINTS = {
    GET_CONFIG: '/account/config',
    GET_PORTFOLIO: '/portfolio/investor/fetch',
    GET_OWNER_DATA: '/portfolio/getPnoOwners',
    GET_SINGLE_COMPANY_DATA: '/portfolio/assessee/basic/fetch',
    GET_ORG_INFO: '/account/fetch/orgInfo',
    INITIATE_REPORT_GENERATION: '/profile/evaluation/generate',
    INVITE_COMPANY: '/account/org/create',
    COMPANY_UPDATE: '/account/updateOrgMaster',
    CREATE_DEAL: '/unifier/createDeal',
    METRICS_FOR_DEAL_CREATION: '/unifier/getAicaDealMetrics',
    PEC_MATCHING: '/unifier/pecMatching',
    DEAL_UPDATE: '/unifier/updateDeal',
    INVESTOR_DEAL_UPDATE: '/unifier/updateInvestorDeal',
    GET_ALL_DEALS: '/unifier/getAllDeals',
    GET_ORG_BASED_USERS: '/account/getOrgBasedUsers',
    GET_ALL_LENDER_DEALS: '/unifier/getAllLenderDeals',
    GET_INVESTMENT_PREFERENCE: '/unifier/getInvestmentPreference',
    GET_ALL_INVESTMENT_PREFERENCES: '/unifier/getAllInvestmentPreferneces',
    UPDATE_INVESTMENT_PREFERENCE: '/unifier/investmentPreference/update',
    CREATE_INVESTMENT_PREFERENCE: '/unifier/investmentPreference/create',
    GET_DEAL_DATA: '/unifier/getDealData',
    GET_LENDER_DEAL_DATA: '/unifier/getLenderDealData',
    GET_ONE_PAGER_DATA: '/unifier/getAicaOnePagerData',
    GET_INVESTMENT_REPORT: '/investmentReport/download',

    ADD_TO_WATCH: '/account/org/addWatchAccount',
    MOVE_COMPANY_TO_NEW_TIER: '/account/org/moveCompanyToNewTier',
    INVITE_COMPANY_USER: '/account/org/inviteCompanyOrgUser',
    EDIT_USER: '/account/editUser',
    FETCH_ALL_USERS: '/account/getAllUsers',
    UNLOCK_AICA: '/account/unlock',
    GET_TAB_LOCK_STATUS: '/insights/getTabsLockStatus',
    GET_ALL_INVESTMENT_PREFERENCE_DATA: '/unifier/getAllInvestmentPreferenceData',
    GET_AVAILABLE_PRODUCTS: '/unifier/getAvailableProducts',
    GET_ALL_PRODUCTS: '/unifier/getAllProducts',
    GET_COMPANY_SECTOR: '/unifier/getCompanySector',
    GET_ALL_TASK_TRACKER_DATA: '/unifier/getAllTaskTrackerData',
    GET_ORG_USER: '/account/getOrgUser',

    // ONE PAGER
    GET_TEMPLATE_ONE_PAGER_FOR_ASSESSEE: '/data/getOnePagerData',
    SAVE_TEMPLATE_ONE_PAGER_FOR_ASSESSEE: '/data/saveOnePagerData',
    GET_AML_PEP_DATA: '/unifier/getAmlPepData',
    VERIFY_WEBSITE: '/unifier/websiteVerification',
};

const CERBERUS_ENDPOINTS = {
    INVITE_COMPANY_V2: '/v2/access/signUp',
};

const REQUEST_ID_HEADER = 'request-id';

export type AddCompanyToWatchlistPayload = {
    websiteUrl?: string;
    investorOrgId: string;
    journeyName: string;
    inviteCode?: string;
};

export type InvestorAccessTypePermissionServicePayload = {
    accountType: string;
    investorOrgId: string;
    primaryEmail: string;
    reportId?: string;
    configName: string;
};

export type AddCompanyToScanPayload = {
    assesseeOrgId: string;
    investorOrgId?: string;
    phoneNumber?: string;
    countryCode?: string;
    countryId?: string;
    primaryEmail?: string;
    inviteType?: string;
    triggerNotification?: boolean;
    primaryUserName?: string;
    websiteUrl?: string;
    pan?: string;
};

export enum CompanyStatus {
    NEW = 'NEW',
    ONGOING = 'ONGOING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    DEAL_CREATED = 'DEAL_CREATED',
}

export type CompanyUpdatePayload = {
    orgId: string;
    companyStatus?: {
        status?: CompanyStatus;
        userId: string;
        activityDate: string;
    };
    companyRiskGrade?: RiskGrade;
    monitoringStatus?: MonitoringStatus;
    companyComment?: {
        text: string;
        activityBy: string;
        activityDate: string;
    };
    orgOwner?: {
        name: string;
        userId: string;
        activityBy: string;
        activityDate: string;
    };
};

export type FetchDealMetricsPayload = {
    requestId?: string;
    parentOrgId: string;
    orgId: string;
    startDate: string;
    endDate: string;
};

export type FetchDealsPayload = {
    dealStatuses: string[];
    dealProposerId: string;
    pageNumber: number;
    pageSize: number;
    ownerUserId: string;
    dealBeneficiaryId?: string;
    filters?: {
        dealBeneficiaryIds?: string[];
    };
};

export type FetchLenderDealsPayload = {
    dealStatuses: string[];
    dealReceiverId: string;
    pageNumber: number;
    pageSize: number;
};

export type DealUpdatePayload = {
    dealId: string;
    dealStatus?: string;
    lastUpdatedBy: string;
    comment?: {
        text: string;
        activityBy: string;
        activityDate: string;
        userId: string;
    };
};

export type InvestorDealUpdatePayload = {
    dealId?: string;
    source?: string;
    investorDealId?: string;
    dealStatus?: string;
    lastUpdatedBy: string;
    comment?: {
        text: string;
        activityBy: string;
        activityDate: string;
        userId: string;
    };
    owner: {
        name: string;
        userId: string;
        activityBy: string;
        activityDate: string;
        ownerType: string;
    };
    userId?: string;
};

export type UnlockAICAReportPayload = {
    investorOrgId: string;
    assesseeOrgId: string;
    tabs: string[];
};

export type FetchInvestmentPreferencesPayload = {
    aggregatorId: string;
    lenderIds: string[];
};

export type FetchAllInvestmentPreferencesPayload = {
    requestPayload: {
        aggregatorId: string;
        lenderIds: string[];
        investmentPreferenceType: string;
        investmentPreferenceId: string;
    };
};
