import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/**
 * Utility function to check if data is empty
 */
const isEmptyData = (data: any) => {
    return !data || (Array.isArray(data) && data.length === 0);
};

/**
 * Custom hook to fetch and copy formatted text.
 */
const useCopyText = (fetchData: () => Promise<any>, formatData?: (data: any) => any) => {
    const [copiedText, setCopiedText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const copy = useCallback(async () => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const data = await fetchData();

            if (isEmptyData(data)) {
                setCopiedText('');
                toast.error('No data to copy');
                return;
            }

            const formattedText = formatData ? formatData(data) : typeof data === 'string' ? data : JSON.stringify(data, null, 2);

            await navigator.clipboard.writeText(formattedText);

            setCopiedText(formattedText);
            setSuccess(true);
            toast.success('Text copied to clipboard');
        } catch (err) {
            setCopiedText('');
            setError(err instanceof Error ? err.message : 'An unexpected error occurred.');
            toast.error('Failed to copy text');
        } finally {
            setLoading(false);
        }
    }, [fetchData, formatData]);

    return { copy, copiedText, loading, success, error };
};

export default useCopyText;
