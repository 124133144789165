import React, { useState, useEffect, useRef } from 'react';

const Typewriter = ({
    text,
    delay,
    chatEndRef = null,
    setFetchingResponse = (boolean) => {},
}: any) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const typewriterBody = useRef<any>(null);

    useEffect(() => {
        if (currentIndex < text?.length) {
            const timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);

            return () => clearTimeout(timeout);
        } else setFetchingResponse?.(false);
    }, [currentIndex, delay, text]);

    useEffect(() => {
        if (currentText && chatEndRef?.current) {
            chatEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [typewriterBody?.current?.clientHeight, chatEndRef]);
    // Typing logic goes here

    return <div ref={typewriterBody}>{currentText}</div>;
};

export default Typewriter;
