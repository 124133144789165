import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import { useEffect, useRef, useState } from 'react';
import { fetchCompanyOverviewData } from '../../../../store/dashboard/overview/actions';
import { OverviewData } from '../../../../store/dashboard/overview/types';
import { initialOverviewData } from '../../../../store/dashboard/overview/constants';
import styles from './Overview.module.scss';
import { ACCESS_TIERS } from '../../../../store/investor/constants';

import { DATA_FETCH_STATUS } from '../../../../store/dashboard/constants';
import axios from 'axios';
import { changeOrgIdHeaderForCharts } from '../../../../utils/axios-interceptor';
import { setDashboardConfig } from '../../../../store/dashboard/actions';

import Details from './components/Details/Details';
import ManagementProfile2 from './components/ManagementProfile/ManagementProfile';
import ChargeDetails from './components/ChargeDetails/ChargeDetails';
import ShareholdingPattern2 from './components/ShareholdingPattern/ShareholdingPattern';
import KeyInvestors2 from './components/KeyInvestors/KeyInvestors';
import NewsUpdates2 from './components/NewsUpdates/NewsUpdates';
import SectorIndustryOutlook from './components/SectorIndustryOutlook/SectorIndustryOutlook';
import KeyCompetitors2 from './components/KeyCompetitors/KeyCompetitors';
import FinancialMetrics from './components/FinancialMetrics/FinancialMetrics';
import EmployeeAndWebsiteTraffic from './components/EmployeeAndWebsiteTraffic/EmployeeAndWebsiteTraffic';
import Checks2 from './components/Checks/Checks';
import Users from './components/UserAndUserSubmittedDetails/Users';
import GeneratingReport from './components/GeneratingReport/GeneratingReport';
import clsx from 'clsx';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import FundingHistory from './components/FundingHistory/FundingHistory';
import { REPORT_SECTION_NAMES } from '../../../../store/pdfReport/pdfReport_constants';
import AICAGradeV2 from './components/AICAGradeV2/AICAGradeV2';

function Overview({ selectedCompany, refetchOverviewRef, appendSearchParams, isPDFReport = false, triggerMain = false }) {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const userData = useAppSelector((state) => state.user.userData);
    const dashboardConfig = useAppSelector((state) => state.dashboard.dashboardReducer.config);

    const [loadingOverview, setLoadingOverview] = useState(true);
    const [overviewData, setOverviewData] = useState<OverviewData>(initialOverviewData);
    const [showGeneratingPill, setShowGeneratingPill] = useState(false);

    const accessType = selectedCompany?.companyAccessType || '';
    const isSoleProp = selectedCompany?.isSoleProp || false;
    const isOverviewLocked = overviewData?.overallStatus === 'LOCKED';

    const isGeneratingProfile = overviewData?.overallStatus === DATA_FETCH_STATUS.PROCESSING && !isOverviewLocked;

    let pollingIntervalRef: any = useRef(null);
    const assesseeOrgId = id ?? selectedCompany.assesseeOrgId;

    useEffect(() => {
        if (isGeneratingProfile) {
            setTimeout(() => {
                setShowGeneratingPill(true);
            }, 2000);
        }
    }, [isGeneratingProfile]);

    useEffect(() => {
        if (assesseeOrgId) {
            changeOrgIdHeaderForCharts(axios, assesseeOrgId);
            getCompanyData();
        }
    }, [assesseeOrgId]);

    useEffect(() => {
        if (refetchOverviewRef?.current) {
            getCompanyData(false, true);
        }
    }, [refetchOverviewRef?.current]);

    useEffect(() => {
        if (isGeneratingProfile && !isPDFReport) {
            pollingIntervalRef.current = setInterval(() => {
                getCompanyData(true);
            }, 5000);
        } else {
            clearInterval(pollingIntervalRef?.current);
        }

        return () => {
            clearInterval(pollingIntervalRef?.current);
        };
    }, [isGeneratingProfile]);

    useEffect(() => {
        if (triggerMain) {
            getCompanyData();
        }
    }, [triggerMain]);

    const getCompanyData = (isRepeatPollingCall = false, isCallFromOutside = false) => {
        !isRepeatPollingCall && setLoadingOverview(true);
        dispatch(
            fetchCompanyOverviewData({
                assesseeOrgId: assesseeOrgId,
                investorOrgId: userData.investorOrgId,
                partnerUserId: userData.userId,
                onSuccess: (data: OverviewData, actionCta) => {
                    !isRepeatPollingCall && setLoadingOverview(false);
                    if (isCallFromOutside) refetchOverviewRef.current = false;
                    setOverviewData((prevData) => data || prevData);
                    dispatch(setDashboardConfig({ ...dashboardConfig, actionCta }));
                },
            }),
        );
    };

    if (loadingOverview) {
        return (
            <div>
                <LoadingSpinner color={'var(--primary-text-colour)'} height={'35px'} style={{ marginTop: '50px' }} />
            </div>
        );
    }

    return (
        <div
            className={clsx({
                [styles.Overview]: !isPDFReport,
                [styles.Loading]: loadingOverview,
            })}
        >
            {showGeneratingPill && !isPDFReport && <GeneratingReport />}

            <Details overviewData={overviewData} accessType={accessType} selectedCompany={selectedCompany} isPDFReport={isPDFReport} />
            {accessType !== ACCESS_TIERS.WATCH && <AICAGradeV2 isPDFReport={isPDFReport} />}

            <ManagementProfile2
                profiles={overviewData?.managementProfile?.profiles || []}
                redirectToCreditBureau={(directorId: string) => {
                    appendSearchParams({
                        selectedTab: 'creditBureau',
                        directorId: directorId,
                        report: 'keyAccountsDetailedReport',
                    });
                }}
                redirectToCompliances={() => {
                    appendSearchParams({ selectedTab: 'legalChecks' });
                }}
                isPDFReport={isPDFReport}
            />

            {!isPDFReport ? (
                <FinancialMetrics screen="overview" assesseeOrgId={selectedCompany?.assesseeOrgId} />
            ) : (
                <FinancialMetrics
                    screen="dashboard"
                    assesseeOrgId={selectedCompany?.assesseeOrgId}
                    isPDFReport={isPDFReport}
                    pdfReportSection={REPORT_SECTION_NAMES.FINANCIAL_RATIOS}
                />
            )}

            <ChargeDetails isPDFReport={isPDFReport} />
            {!isSoleProp && <FundingHistory isPDFReport={isPDFReport} />}
            <EmployeeAndWebsiteTraffic
                employeeData={overviewData?.employeeData}
                websiteTraffic={overviewData?.websiteTraffic}
                isLoading={loadingOverview}
                isPDFReport={isPDFReport}
            />
            {!isSoleProp && <ShareholdingPattern2 isPDFReport={isPDFReport} />}
            <KeyCompetitors2 competitorsInfo={overviewData.competitorsInfo} isPDFReport={isPDFReport} />
            <NewsUpdates2 newsInfo={overviewData.newsInfo} accessType={accessType} isPDFReport={isPDFReport} />
            <SectorIndustryOutlook sectorInfo={overviewData.sectorInfo} accessType={accessType} isPDFReport={isPDFReport} />
            <KeyInvestors2 fundingInfo={overviewData?.fundingInfo} isPDFReport={isPDFReport} />
            {accessType !== ACCESS_TIERS.WATCH && (
                <Checks2 isLoading={loadingOverview} isGeneratingProfile={isGeneratingProfile} isPDFReport={isPDFReport} />
            )}

            {!isPDFReport && (
                <Users
                    additionalDetails={overviewData?.additionalDetails}
                    additionalDocMetaData={overviewData?.additionalDocMetaData}
                    selectedCompany={selectedCompany}
                />
            )}
        </div>
    );
}

export default Overview;
