import axios from 'axios';
import { GET, polusBaseUrl, POST } from '../../utils/axios-interceptor';
import { CompanyDealMetricsData } from '../../modules/DebtProducts/debtProductTypes';

export function fetchCompanyMetricsService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_COMPANY_METRICS,
        method: GET,
        params: {
            orgId: payload.assesseeOrgId,
        },
    });
}

export function updateCompanyMetricsService(payload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_COMPANY_METRICS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function fetchDebtProductsDataService(payload: { orgId: string; aggregatorId: string }) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_DEBT_PRODUCTS,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
            },
        },
    });
}

export function fetchCompanyDealMetrics(requestPayload: {
    requestPayload: CompanyDealMetricsData;
}) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_COMPANY_DEAL_METRICS,
        method: POST,
        data: {
            ...requestPayload,
        },
    });
}

const API_ENDPOINTS = {
    GET_COMPANY_METRICS: '/profile/getMetrics',
    UPDATE_COMPANY_METRICS: '/profile/updateMetrics',
    GET_DEBT_PRODUCTS: '/unifier/getDebtProducts',
    GET_COMPANY_DEAL_METRICS: '/unifier/getCompanyDealMetrics',
};
