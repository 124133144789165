import { Handlers } from '../../../types/actions/types';
import CONSTANTS from './constants';

export const initializeCreditBureauCharts = (payload) => {
    return {
        type: CONSTANTS.INITIALIZE_CHARTS_DATA,
        payload,
    };
};

export const fetchChartData = (payload) => {
    return {
        type: CONSTANTS.FETCH_CHART_DATA,
        payload,
    };
};

export const fetchChargeDetails = (payload, handlers: Handlers) => {
    return {
        type: CONSTANTS.FETCH_CHARGE_DETAILS,
        payload,
        handlers,
    };
};

export const fetchCibilOverview = (payload) => {
    return {
        type: CONSTANTS.FETCH_CIBIL_OVERVIEW,
        payload,
    };
};

export const fetchDirectorsOverview = (payload) => {
    return {
        type: CONSTANTS.FETCH_DIRECTORS_OVERVIEW,
        payload,
    };
};
export const fetchDirectorBureauCibil = (payload) => {
    return {
        type: CONSTANTS.FETCH_DIRECTOR_BUREAU_CIBIL,
        payload,
    };
};

export const fetchCreditBureauSummaryTable = (
    payload: CreditBureauSummaryPayload,
    handlers: Handlers,
) => {
    return {
        type: CONSTANTS.FETCH_CREDIT_BUREAU_SUMMARY_TABLE,
        payload,
        handlers,
    };
};

type CreditBureauSummaryPayload = {
    assesseeOrgId: string;
    directorId?: string;
};
