import { Player } from '@lottiefiles/react-lottie-player';
import { summonAicaJson } from '../../../assets/hostedassets';
import styles from './MagicLink.module.scss';
import { utilBaseUrl } from '../../../utils/axios-interceptor';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_ROUTES, COMPANY_ROUTES } from '../../../utils/constants/routesConst';
import { useEffect } from 'react';

const MagicLink = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const redirectUser = () => {
        const shortcode = params.get('sc');
        if (shortcode) {
            // Redirect to resolver
            window.location.replace(`${utilBaseUrl}/resolver/resolve/${shortcode}`);
        } else {
            toast.error('Invalid Magic Link');
            navigate(BASE_ROUTES.LOGIN);
        }
    };

    const checkAuth = () => {
        return (
            localStorage.getItem('auth_token') &&
            localStorage.getItem('org_id') &&
            localStorage.getItem('user_id')
        );
    };

    useEffect(() => {
        if (params.get('sc')) redirectUser();
        if (checkAuth()) navigate(`${COMPANY_ROUTES.UPLOAD}?configName=MAGIC_LINK`);
    }, [params.get('sc')]);

    return (
        <div className={styles.MagicLinkContainer}>
            <Player
                src={summonAicaJson}
                autoplay
                loop
                speed={2}
                style={{
                    width: '50%',
                }}
            />
            <h1 style={{ fontSize: '1.5rem' }}>Working Magic...</h1>
        </div>
    );
};

export default MagicLink;
