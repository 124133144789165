import { CommentsThreadSources, ShowCommentsThreadAction } from './commentsThread';
import { CONSTANTS } from './commentsThread_constants';

export const COMMENTS_THREAD_SOURCES = {
    COMPANIES_LIST: 'COMPANIES_LIST',
    DASHBOARD: 'DASHBOARD',
    DEAL: 'DEAL',
    INVESTOR_DEAL: 'INVESTOR_DEAL',
    ONE_PAGER: 'ONE_PAGER',
    MANAGE_PARTNERS_LENDERS: 'MANAGE_PARTNERS_LENDERS',
} as const;

interface CommentsThreadReducer {
    showCommentsThread: boolean;
    source: CommentsThreadSources;
    loadingCommentsThread: boolean;
    closingCommentsThread: boolean;

    headerText: string | null;
    headerSecondaryText?: string | null;

    assesseeOrgId?: string | null;
    dealId?: string | null;
    dealIdForInvestorDeal?: string | null;
    organizationId?: string | null;
    commentsThread: any[];

    refetchFlags: {
        refetchPartnersFlag: boolean;
    };
}

const initialState: CommentsThreadReducer = {
    showCommentsThread: false,
    source: null,
    loadingCommentsThread: false,
    closingCommentsThread: false,

    headerText: null,
    headerSecondaryText: null,

    assesseeOrgId: null,
    dealId: null,
    dealIdForInvestorDeal: null,
    organizationId: null,
    commentsThread: [],

    refetchFlags: {
        refetchPartnersFlag: false,
    },
};

const setIsFetchingData = (state, action): CommentsThreadReducer => {
    return {
        ...state,
        loadingCommentsThread: action.payload,
    };
};

const setShowCommentsThread = (state, action: ShowCommentsThreadAction): CommentsThreadReducer => {
    if (state.closingCommentsThread) return state;
    const { source } = action.payload;
    let newState = {
        ...state,
        source,
        showCommentsThread: true,
        assesseeOrgId: null,
        dealId: null,
        dealIdForInvestorDeal: null,
        headerText: action.payload?.headerText ?? null,
        headerSecondaryText: action.payload?.headerSecondaryText ?? null,
    };

    if (source === 'COMPANIES_LIST' || source === 'DASHBOARD' || source === 'ONE_PAGER') {
        newState = { ...newState, assesseeOrgId: action.payload.assesseeOrgId };
    } else if (source === 'DEAL') {
        newState = { ...newState, dealId: action.payload.dealId };
    } else if (source === 'INVESTOR_DEAL') {
        newState = {
            ...newState,
            dealId: action.payload.dealId,
            dealIdForInvestorDeal: action.payload.dealIdForInvestorDeal,
        };
    } else if (source === 'MANAGE_PARTNERS_LENDERS') {
        newState = { ...newState, organizationId: action.payload.organizationId };
    }

    return newState;
};

const intitiateCloseCommentsThread = (state): CommentsThreadReducer => {
    return {
        ...state,
        closingCommentsThread: true,
    };
};

const closeCommentsThreadAndReset = (): CommentsThreadReducer => {
    return { ...initialState };
};

const setCommentsThreadData = (state, action) => {
    return {
        ...state,
        loadingCommentsThread: false,
        ...(action.payload.data && { commentsThread: action.payload.data }),
        ...(action.payload.headerText && { headerText: action.payload.headerText }),
    };
};

const addCommentToThread = (state, action) => {
    return {
        ...state,
        commentsThread: [...state.commentsThread, action.payload],
    };
};

const setRefetchFlag = (state, action) => {
    const { flagKey, status } = action.payload;
    return {
        ...state,
        refetchFlags: {
            ...state.refetchFlags,
            ...(flagKey && { [flagKey]: status || false }),
        },
    };
};

const reducer = (state = initialState, action): CommentsThreadReducer => {
    switch (action.type) {
        case CONSTANTS.SET_IS_FETCHING_DATA:
            return setIsFetchingData(state, action);
        case CONSTANTS.SET_SHOW_COMMENTS_THREAD:
            return setShowCommentsThread(state, action);
        case CONSTANTS.INITIATE_CLOSE_COMMENTS_THREAD:
            return intitiateCloseCommentsThread(state);
        case CONSTANTS.CLOSE_COMMENTS_THREAD:
            return closeCommentsThreadAndReset();
        case CONSTANTS.SET_COMMENTS_THREAD_DATA:
            return setCommentsThreadData(state, action);
        case CONSTANTS.ADD_COMMENT_TO_THREAD:
            return addCommentToThread(state, action);
        case CONSTANTS.SET_REFETCH_FLAG:
            return setRefetchFlag(state, action);
        default:
            return state;
    }
};

export default reducer;
