import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';
import { CMR_DATA } from '../../modules/Company/UploadJourney/components/CompanyCMR/CompanyCMR';
import { DIRECT_CIBIL_DATA } from '../../modules/Company/UploadJourney/components/DirectorCibil/DirectorCibil';
import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { CONSTANTS } from './constants';
import {
    fetchCountryCodesApi,
    getCompaniesLegalNamesListService,
    getCompanyConfigData,
    getConfigDataFromPolus,
    getFinboxRedirectUrlService,
    polusExportRaw,
    submitData,
    underwritingExportRaw,
    updateAdditionalDocumentsService,
    updateBankAccountsData,
    updateCreditBureauData,
    updateFinancialReportsData,
    updateGSTReportsData,
} from './service';
import { store } from '../store';
import {
    updateAdditionalDocuments,
    updateCreditBureauCibilAction,
    updateCreditBureauCMRAction,
    updateFinancialReports,
    updateGstReportsAction,
} from './action';
import { USER_TYPE } from '../../utils/constants/user';
import { Handlers } from '../../types/actions/types';

export async function creditBureauUpdate(payload, updateKey) {
    try {
        const response = await updateCreditBureauData(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (updateKey === CMR_DATA) {
                    store.dispatch(
                        updateCreditBureauCMRAction({
                            data: { ...(response?.data?.updatedCreditBureau || {}) },
                        }),
                    );
                } else if (updateKey === DIRECT_CIBIL_DATA) {
                    store.dispatch(
                        updateCreditBureauCibilAction({
                            data: { ...(response?.data?.updatedCreditBureau || {}) },
                        }),
                    );
                }
                return;
            // toast.success('Credit Bureau Data Update Success');

            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Failed to update. Please try again later.');
    }
}

export async function gstReportsUpdate(payload) {
    try {
        const response = await updateGSTReportsData(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                store.dispatch(
                    updateGstReportsAction({
                        data: { ...(response?.data?.updatedGstData || {}) },
                    }),
                );
                break;
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Failed to update. Please try again later.');
    }
}

export async function financialReportsUpdate(payload) {
    try {
        const response = await updateFinancialReportsData(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                store.dispatch(
                    updateFinancialReports({
                        data: { ...(response?.data?.updatedFinancialReport || {}) },
                    }),
                );
                // }
                return toast.success('Financial Report Data Update Success');

            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Failed to update. Please try again later.');
    }
}

export async function additionalDocumentsUpdateHelper(payload) {
    try {
        const response = await updateAdditionalDocumentsService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                store.dispatch(
                    updateAdditionalDocuments({
                        data: response?.data?.additionalDocsData?.additionalDocs || [],
                    }),
                );
                return toast.success('Document Successfully Uploaded');

            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Failed to update. Please try again later.');
    }
}

export async function submitOnboardingData(payload, handlers) {
    try {
        const response = await submitData(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                // return toast.success('Data submitted successfully');
                break;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
        console.error('Error in submitting onboarding data', err);
    }
}

export async function bankAccountsUpdate(payload, handlers) {
    try {
        const response = await updateBankAccountsData(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                store.dispatch(
                    updateFinancialReports({
                        data: { ...(response?.data?.updatedBankAccountStatement || {}) },
                    }),
                );
                handlers?.onSuccess?.(response?.data?.updatedBankAccountStatement);
                break;
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Failed to update. Please try again later.');
    }
}

export async function getFinboxRedirectUrl(payload) {
    try {
        const res = await getFinboxRedirectUrlService(payload);
        return res.data?.data?.redirect_url;
    } catch (err) {}
}

export async function exportRawFromUnderwritingAndPolus(payload, handlers, stopAdditional = true) {
    let uwExportSuccess = false,
        polusExportSuccess = !stopAdditional;
    try {
        await underwritingExportRaw(payload, {
            onSuccess: () => {
                uwExportSuccess = true;
            },
        });
        if (stopAdditional) {
            await polusExportRaw(payload, {
                onSuccess: () => {
                    polusExportSuccess = true;
                },
            });
        }
        if (!uwExportSuccess && !polusExportSuccess)
            toast.warn('No data available to download. Please ask borrower to Connect/Upload Data.');
        handlers?.onSuccess();
    } catch (err) {
        handlers?.onError?.();
    }
}

export async function exportRawDataFromUnderwriting(payload, handlers) {
    try {
        await underwritingExportRaw(payload);
    } catch (err) {
        toast.warn('Failed to download. Please try again later.');
    }
}

export async function exportRawDataFromPolus(payload, handlers) {
    try {
        await polusExportRaw(payload);
    } catch (err) {
        toast.warn('Failed to download. Please try again later.');
    }
}

export async function getCompanyConfigDataAndSetFormData(payload, handlers) {
    try {
        const configResponse = await getCompanyConfigData({
            accountType: USER_TYPE.COMPANY,
            investorOrgId: payload.investorOrgId,
            primaryEmail: payload.primaryEmail,
            configName: 'AICA_SIGNUP_ADDITIONAL_DETAILS',
        });
        const configData = configResponse?.data?.mainConfig;
        if (configData) handlers?.onSuccess?.(configData);
        else {
            toast.error('Error fetching application details config');
            handlers?.onError?.();
        }
    } catch (error) {
        handlers?.onError?.();
    }
}

export async function fetchCountryCodes(handlers) {
    try {
        const res = await fetchCountryCodesApi();
        if (res?.data?.responseData?.responseCode === 20) {
            handlers?.onSuccess(res?.data?.geoCallingMapperList)?.();
        } else {
            // showNotification(
            //     'Error',
            //     res.responseData.responseMessage ?? "Couldn't Fetch Country Codes",
            // );
        }
    } catch (err) {}
}

export async function getCompaniesLegalNamesList(payload, handlers) {
    try {
        const response = await getCompaniesLegalNamesListService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.autocompleteResultList || []);
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
        }
    } catch (error) {
        handlers?.onError?.();
    }
}

export async function getCompanySignupConfig(payload, handlers: Handlers) {
    try {
        const response = await getConfigDataFromPolus({
            orgId: payload.orgId,
            configName: 'AICA_COMPANY_SIGNUP_CONFIG',
        });
        switch (response?.data?.responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.mainConfig?.additionalDetailsConfig);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.(null);
                break;
        }
    } catch (error) {
        handlers?.onError?.(null);
    }
}
