import axios from 'axios';
import { mnemosyneBaseUrl, olumpusBaseUrl, POST } from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';

export function fetchAllDocs(payload) {
    return axios({
        url: `${olumpusBaseUrl}/${API_ENDPOINTS.GET_ALL_DOCS}`,
        method: POST,
        data: {
            investeeOrganizationId: payload.assesseeOrgId,
        },
    });
}

export function fetchAllDocsAica(payload) {
    return axios({
        url: `${API_ENDPOINTS.GET_ALL_DOCS_AICA}`,
        method: POST,
        data: {
            assesseeOrgId: payload.assesseeOrgId,
            pnoId: payload.pnoId,
            viewType: payload.viewType,
            forOrgId: payload.forOrgId,
        },
    });
}
export function fetchIsRecurCompany(payload) {
    return axios({
        url: `${olumpusBaseUrl}/${API_ENDPOINTS.IS_RECUR_COMPANY}`,
        method: POST,
        data: {
            investeeOrganizationId: payload.assesseeOrgId,
        },
    });
}

export function downloadFolderStructure(data) {
    return axios({
        url: `${mnemosyneBaseUrl}/${API_ENDPOINTS.DOWNLOAD_FOLDER_STRUCTURE}`,
        method: POST,
        data,
        responseType: 'blob',
    });
}

const API_ENDPOINTS = {
    GET_ALL_DOCS: 'doc/dataRoom/getAllDocs',
    GET_ALL_DOCS_AICA: 'data/dataRoom/getData',
    DOWNLOAD_FOLDER_STRUCTURE: 'file/downloadStructure',
    IS_RECUR_COMPANY: 'aica/is-recur-company',
};
