import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import Progressbar from '../../../../../../common/Progressbar/Progressbar';
import { Button } from '../../../../../../common/_custom';
import styles from './KeyOfficialsCreditSummary.module.scss';
import { useAppSelector } from '../../../../../../app/hooks';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import { useEffect, useMemo, useState } from 'react';
import UploadData from '../../../../../Company/UploadData/UploadData';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { toast } from 'react-toastify';
import { COMBINED_REPORT, DETAILED_REPORT } from '../../CreditBureau';
import clsx from 'clsx';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import {
    INVESTOR_CB_ADD_OFFICIAL,
    INVESTOR_CB_OPEN_COMBINED_REPORT,
    INVESTOR_CB_OPEN_FULL_REPORT,
    INVESTOR_DOWNLOAD_CIBIL_QUERY,
} from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { downloadCibilNeedInfoSheet } from '../../../../../../store/dashboard/creditBureau/service';
import { capitalizeWords } from '../../../../../../utils/utils';
import {
    ctaPurpleRight,
    downloadIconDataRoomHovered,
    uploadCibil,
    uploadEquifax,
    creditBureauEmptyState,
} from '../../../../../../assets/hostedassets';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import CreditProgessBar from './Components/CreditProgessBar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    fetchDirectorBureauCibil,
    fetchDirectorsOverview,
} from '../../../../../../store/dashboard/creditBureau/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CategorySelector from '../../../../../../common/CategorySelector/CategorySelector';
import { set, update } from 'lodash';
import { updateQueryParams } from '../../../../../../utils/searchParamUtils';
import greyLock from '../../../../../../assets/grey-lock.svg';

dayjs.extend(relativeTime);

function KeyOfficialsCreditSummary({
    showReport,
    listDirectorsOverview,
    setSelectedReport,
    isLoading,
    assesseeOrgId,
    orgName,
    selectedCompany,
}) {
    const [showUploader, setShowUploader] = useState(false);
    const [downloadingCibilQuery, setDownloadingCibilQuery] = useState(false);
    const user = useAppSelector((state) => state.user);
    const isLender = useAppSelector((state) => state.user.userData.accountType) === 'LENDER';
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedBureauType = searchParams.get('bureauType');
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedBureau, setSelectedBureau] = useState('');
    const BUREAU_TYPE = [
        {
            id: 'All',
            label: 'ALL',
        },
        {
            id: 'CIBIL',
            label: 'CIBIL',
        },
        {
            id: 'EQUIFAX',
            label: 'EQUIFAX',
        },
    ];
    useEffect(() => {
        const bureauType = searchParams.get('bureauType');
        // If 'bureauType' is null, undefined, or an empty string, set it to 'All'
        if (!bureauType) {
            const defaultBureau = 'All';
            setSelectedBureau(defaultBureau);
            searchParams.set('bureauType', defaultBureau);
        } else {
            setSelectedBureau(bureauType);
        }
        // Update query params
        updateQueryParams(searchParams, setSearchParams, {}, location);
    }, [searchParams]);

    if (isLoading)
        return (
            <div className={styles.KeyOfficialsCreditSummary} style={{ padding: '12px 24px' }}>
                <ShimmerTitle line={1} gap={20} variant="primary" />
                <ShimmerThumbnail height={320} rounded className={styles.ShimmerThumbnail} />
            </div>
        );

    function getImageSource(director) {
        if (!director?.directorId) return undefined;

        switch (director.creditBureau) {
            case 'CIBIL':
                return uploadCibil;
            case 'EQUIFAX':
                return uploadEquifax;
            default:
                return undefined;
        }
    }

    const changeSelectedCategory = (categoryId) => {
        setSelectedBureau(categoryId);
        updateQueryParams(searchParams, setSearchParams, { bureauType: categoryId }, location);
    };

    const getImageWidth = (source) => {
        if (source === uploadEquifax) return '3.75rem';
        if (source === uploadCibil) return '2.813rem';
    };

    return (
        <>
            <div className={styles.KeyOfficialsCreditSummary}>
                <div className={styles.Content}>
                    <div className={styles.MainHeader}>
                        <div className={styles.LeftHeader}>
                            <div className={styles.Header}>Director Bureau</div>
                            <div className={styles.CategorySelector}>
                                <CategorySelector
                                    categoryOptions={BUREAU_TYPE}
                                    selectedCategory={selectedBureau}
                                    changeSelectedTab={changeSelectedCategory}
                                />
                            </div>
                        </div>
                        <div className={styles.BarRow}>
                            {/* <Button
                                variant="purpleinverted"
                                onClick={() => {
                                    Mixpanel.track(INVESTOR_CB_OPEN_COMBINED_REPORT);
                                    if (
                                        Array.isArray(listDirectorsOverview) &&
                                        listDirectorsOverview?.length > 0
                                    ) {
                                        showReport(COMBINED_REPORT);
                                    } else
                                        toast.error(
                                            'Please add officials by uploading Director CIBIL in Add New Officials',
                                        );
                                }}
                                text="Combined Director Report"
                                fit
                                style={{ marginTop: '0', fontWeight: '700' }}
                                size="small"
                            /> */}

                            {/* <Button
                                variant="purpleinverted"
                                onClick={async () => {
                                    setDownloadingCibilQuery(true);
                                    Mixpanel.track(INVESTOR_DOWNLOAD_CIBIL_QUERY);
                                    if (
                                        Array.isArray(listDirectorsOverview) &&
                                        listDirectorsOverview?.length > 0
                                    ) {
                                        const payLoad = {
                                            assesseeId: assesseeOrgId,
                                            requestId: Date.now(),
                                            name: orgName,
                                        };
                                        await downloadCibilNeedInfoSheet(payLoad);
                                        setDownloadingCibilQuery(false);
                                    } else {
                                        setDownloadingCibilQuery(false);
                                        toast.error(
                                            'Please add officials by uploading Director CIBIL in Add New Officials',
                                        );
                                    }
                                }}
                                text="Download Bureau Query"
                                fit
                                style={{ marginTop: '0', fontWeight: '700' }}
                                size="small"
                                loading={downloadingCibilQuery}
                            /> */}
                            <div className={styles.HeaderButtons}>
                                <div
                                    onClick={() => {
                                        Mixpanel.track(INVESTOR_CB_OPEN_COMBINED_REPORT);
                                        if (
                                            Array.isArray(listDirectorsOverview) &&
                                            listDirectorsOverview.some(
                                                (director) => director.directorId,
                                            )
                                        ) {
                                            showReport(COMBINED_REPORT);
                                        } else
                                            toast.error(
                                                'Please add officials by uploading Director CIBIL in Add New Officials',
                                            );
                                    }}
                                    style={{ marginTop: '0', fontWeight: '700' }}
                                >
                                    <img src={ctaPurpleRight} alt="" />
                                    Combined Director Report
                                </div>

                                <div
                                    onClick={async () => {
                                        setDownloadingCibilQuery(true);
                                        Mixpanel.track(INVESTOR_DOWNLOAD_CIBIL_QUERY);
                                        if (
                                            Array.isArray(listDirectorsOverview) &&
                                            listDirectorsOverview.some(
                                                (director) => director.directorId,
                                            )
                                        ) {
                                            const payLoad = {
                                                assesseeId: assesseeOrgId,
                                                requestId: Date.now(),
                                                name: orgName,
                                                ...(searchParams.get('bureauType') !== 'All' && {
                                                    type: searchParams.get('bureauType'),
                                                }),
                                            };
                                            await downloadCibilNeedInfoSheet(payLoad);
                                            setDownloadingCibilQuery(false);
                                        } else {
                                            setDownloadingCibilQuery(false);
                                            toast.error(
                                                'Please add officials by uploading Director CIBIL in Add New Officials',
                                            );
                                        }
                                    }}
                                    style={{ marginTop: '0', fontWeight: '700' }}
                                >
                                    {downloadingCibilQuery ? (
                                        <LoadingSpinner color="purple" height="15px" />
                                    ) : (
                                        <img src={downloadIconDataRoomHovered} alt="" />
                                    )}
                                    Download Bureau Query
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={styles.HorizontalLine} />
                    <div className={styles.OverviewContainer}>
                        <div className={styles.Overview}>
                            <div className={styles.Headers}>
                                <div>Name</div>
                                {/* <div>All Accounts</div> */}
                                <div>DPD Accounts</div>
                                <div>Overdue</div>
                                <div>Max DPD</div>
                                <div>Bureau Score</div>
                                <div style={{ marginLeft: '20px' }}>Fetched</div>
                            </div>

                            {listDirectorsOverview?.map((director, i) => (
                                <div
                                    className={styles.Row}
                                    key={director.id || i}
                                    onClick={() => {
                                        if (director?.directorId) {
                                            Mixpanel.track(INVESTOR_CB_OPEN_FULL_REPORT);
                                            showReport(DETAILED_REPORT, director);
                                        } else if (!isLender) {
                                            Mixpanel.track(INVESTOR_CB_ADD_OFFICIAL);
                                            setShowUploader(true);
                                        } else {
                                            toast.error(
                                                'Access restricted. Please ask the admin to fetch the credit details.',
                                            );
                                        }
                                    }}
                                >
                                    <div className={styles.Name}>
                                        {capitalizeWords(director.name)}
                                    </div>

                                    <>
                                        {director?.directorId ? (
                                            <div
                                                className={`${styles.Dot} ${
                                                    director.dpdAccounts == 0
                                                        ? styles.Green
                                                        : styles.Red
                                                }`}
                                            >
                                                {director.dpdAccounts ?? '-'}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}

                                        {director?.directorId ? (
                                            <div
                                                className={`${styles.Dot} ${
                                                    director.currentOverdue == 0
                                                        ? styles.Green
                                                        : styles.Red
                                                }`}
                                            >
                                                ₹
                                                {director.currentOverdue?.toLocaleString?.(
                                                    'en-IN',
                                                ) || '-'}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        {director?.directorId ? (
                                            <div
                                                className={`${styles.Dot} ${
                                                    director.maxDpds == 0
                                                        ? styles.Green
                                                        : styles.Red
                                                }`}
                                            >
                                                {director.maxDpds ?? '-'} days
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div className={styles.Cibil}>
                                            {director?.directorId ||
                                            director.cibilScore !== undefined ? (
                                                <div>
                                                    {director.cibilScore ?? '-'}
                                                    <span>/900</span>
                                                </div>
                                            ) : (
                                                <div className={styles.NA}>N.A.</div>
                                            )}
                                            <CreditProgessBar
                                                width={((director.cibilScore ?? 0) / 900) * 100}
                                                ParentWidth="12.5rem"
                                            />
                                            <img
                                                src={getImageSource(director)}
                                                alt=""
                                                className={
                                                    director?.directorId
                                                        ? styles.Image
                                                        : styles.NoImage
                                                }
                                                style={{
                                                    width: getImageWidth(getImageSource(director)),
                                                }}
                                            />
                                        </div>

                                        <div
                                            className={styles.Fetched}
                                            style={{ marginLeft: '15px' }}
                                        >
                                            {director?.directorId ? (
                                                <div className={styles.Date}>
                                                    {dayjs(director.lastFetched).fromNow()}
                                                </div>
                                            ) : (
                                                <div
                                                    className={styles.FetchNow}
                                                    onClick={() => {
                                                        if (isLender) return;
                                                        Mixpanel.track(INVESTOR_CB_ADD_OFFICIAL);
                                                        setShowUploader(true);
                                                    }}
                                                >
                                                    <img src={ctaPurpleRight} alt="" />
                                                    <span className={styles.FetchNowText}>
                                                        Fetch Now
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                </div>
                            ))}

                            {!isLender &&
                                (listDirectorsOverview?.length === 0 ? (
                                    <div className={clsx(styles.AddOfficialEmptyState)}>
                                        <img
                                            src={creditBureauEmptyState}
                                            alt="No Directors"
                                            className={styles.EmptyStateImage}
                                        />
                                        <p className={styles.EmptyStateText}>
                                            No Director added yet?&nbsp;
                                            <span
                                                className={styles.Add}
                                                onClick={() => {
                                                    Mixpanel.track(INVESTOR_CB_ADD_OFFICIAL);
                                                    setShowUploader(true);
                                                }}
                                            >
                                                Add Director or upload CIBIL report
                                            </span>
                                        </p>
                                    </div>
                                ) : (
                                    <div className={clsx(styles.AddOfficial)}>
                                        Any director missing?&nbsp;
                                        <span
                                            className={styles.Add}
                                            onClick={() => {
                                                Mixpanel.track(INVESTOR_CB_ADD_OFFICIAL);
                                                setShowUploader(true);
                                            }}
                                        >
                                            Add Director, Refresh or upload CIBIL report
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {showUploader && (
                    <SideDrawer
                        onClose={() => {
                            setShowUploader(false);
                            const payload = {
                                orgId: selectedCompany.assesseeOrgId,
                                reportId: selectedCompany.reportId || '',
                                type: selectedBureauType !== 'All' ? selectedBureauType : null,
                            };
                            if (selectedBureauType === 'CIBIL') {
                                dispatch(fetchDirectorBureauCibil(payload));
                            } else {
                                dispatch(fetchDirectorsOverview(payload));
                            }
                        }}
                        width="50%"
                    >
                        <SideDrawer.Header>
                            Add credit report of directos
                            <span className={styles.Grey}> | {selectedCompany?.orgName}</span>
                        </SideDrawer.Header>
                        <UploadData
                            overrideSelectedStep={'creditBureau'}
                            userType={USER_TYPE.INVESTOR}
                            isInvestor
                            assesseeOrgId={assesseeOrgId}
                            closeDrawer={() => {
                                const payload = {
                                    orgId: selectedCompany.assesseeOrgId,
                                    reportId: selectedCompany.reportId || '',
                                    type: selectedBureauType !== 'All' ? selectedBureauType : null,
                                };
                                if (selectedBureauType === 'CIBIL') {
                                    dispatch(fetchDirectorBureauCibil(payload));
                                } else {
                                    dispatch(fetchDirectorsOverview(payload));
                                }
                                setShowUploader(false);
                            }}
                        />
                        {/* <SideDrawer.Footer>
                            <div className={styles.FooterText}>
                                <img src={greyLock} alt="lock" height="10px" />
                                256-Bit Bank-Level Encryption
                            </div>
                        </SideDrawer.Footer> */}
                    </SideDrawer>
                )}
            </div>
            {/* <div
                style={{ background: 'transparent', marginTop: '-1rem', padding: 0 }}
                className={styles.KeyOfficialsCreditSummary}
            >
                <ChargesTable />
            </div> */}
        </>
    );
}

export default KeyOfficialsCreditSummary;
