import { useParams } from 'react-router-dom';
import { aicaAIProfileBanners, plusWhite, poweredByAICAWhite } from '../../../../../../assets/hostedassets';
import Typewriter from '../../../../../../common/Typewriter/Typewriter';
import { INVESTOR_OPEN_COMPANY_WEBSITE } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../../../../../utils/dateUtils';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { getHashFromId, validateCIN } from '../../../../../../utils/utils';
import styles from './Details.module.scss';
import { useEffect, useState } from 'react';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import AicaOverview from '../../../../../../common/AicaOverview/AicaOverview';
import clsx from 'clsx';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { setDealDrawer } from '../../../../../../store/investor/action';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../app/hooks';
import { USER_TYPE } from '../../../../../../utils/constants/user';

const foundedIcon = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/founded.svg';
const employeesIcon = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/employees.svg';
const fundingIcon = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/funding.svg';
const location = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/location.svg';
const linkedin = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/linkedIn-white.svg';
const ctaRightWhite = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/ctaRightWhite.svg';

function Details({ overviewData, accessType, selectedCompany, isPDFReport }) {
    const {
        sectorInfo,
        overview,
        businessModel,
        socialInfo,
        foundationDate,
        headquarterLocation,
        fundingInfo,
        employeeData,
        cinNumber,
        panNumber,
        gstin,
    } = overviewData;

    const params = useParams();
    const [bannerLoaded, setBannerLoaded] = useState(false);
    const showSectorSubsector = !!(sectorInfo?.sector || sectorInfo?.subSector);
    const user = useAppSelector((state) => state.user.userData);
    const isValidUser = [USER_TYPE.INVESTOR, USER_TYPE.ADMIN_TEAM].includes(user?.accountType);
    const showOverview = !!overview;
    const invalidSectors = [null, '', 'Others'];
    const dispatch = useDispatch();

    const bannerImgSrc = getImageForId(params.id || '');

    const additionalDetails = (cinNumber && cinNumber !== '') || (panNumber && panNumber !== '') || gstin?.length > 0;
    const bodyHasData = !!(
        sectorInfo?.sector ||
        sectorInfo?.subSector ||
        !!overview ||
        !!businessModel ||
        !!headquarterLocation?.fullAddress ||
        additionalDetails
    );

    useEffect(() => {
        if (bannerImgSrc) {
            const img = new Image();
            img.src = bannerImgSrc;
            img.onload = () => setBannerLoaded(true);
        }
    }, [bannerImgSrc]);

    const onWebsiteClick = () => {
        Mixpanel.track(INVESTOR_OPEN_COMPANY_WEBSITE, {
            Timestamp: getTimeStamp(),
            AICASection: accessType,
        });
    };

    function formatDate(inputDate: string): string {
        const [day, month, year] = inputDate.split('-');
        const date = new Date(`${year}-${month}-${day}`);

        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
    }

    function getImageForId(id: string): string {
        const hash = getHashFromId(id);
        const index = hash % aicaAIProfileBanners.length;
        return aicaAIProfileBanners[index];
    }

    const getFirstGstIn = (gstinList) => {
        const [stateName, gstinValue] = Object.entries(gstinList[0])[0] as [string, string];
        return gstinValue;
    };

    const getToolTipContent = (gstinList) => {
        return (
            <div>
                {gstinList?.slice(1).map((gstinObj) => {
                    const [stateName, gstinValue] = Object.entries(gstinObj)[0] as [string, string];
                    return (
                        <div className={styles.GstTooltip} key={`${stateName}-${gstinValue}`}>
                            {stateName}:<span className={styles.Bold}> {gstinValue} </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const addSocials = (buttonName: String) => {
        if (!isValidUser) return;
        return (
            <div
                className={clsx(styles.Pill, styles.PillWithHover)}
                style={{ cursor: 'pointer', columnGap: '5px' }}
                onClick={() => {
                    dispatch(
                        setDealDrawer({
                            open: true,
                            drawerLabel: 'Edit Profile',
                            drawerSource: 'Debt Products',
                            selectedStep: 2,
                        }),
                    );
                }}
            >
                <img src={plusWhite} alt="" height={'13.33px'} />
                {buttonName}
            </div>
        );
    };

    const renderTopSection = () => (
        <div
            className={styles.Top}
            style={{
                background: isPDFReport
                    ? 'linear-gradient(127deg, rgba(0,0,0,1) 0%, rgba(96,33,179,1) 100%)'
                    : bannerLoaded
                    ? `url(${bannerImgSrc}) center/cover no-repeat`
                    : 'linear-gradient(127deg, rgba(0,0,0,1) 0%, rgba(96,33,179,1) 100%)',
            }}
        >
            <div className={styles.CompanyLogo}>
                {selectedCompany?.logo ? (
                    <img src={selectedCompany?.logo} alt="Company Logo" width={'100%'} />
                ) : (
                    <div className={styles.PlaceholderImg}>{selectedCompany?.orgName?.charAt(0) || ''}</div>
                )}
            </div>
            <div className={styles.Title}>
                <Typewriter text={selectedCompany?.orgName} delay={20} />
            </div>
            <div className={styles.Badges}>
                {!!foundationDate && (
                    <div className={styles.Pill}>
                        <img src={foundedIcon} alt="" height={'12px'} />
                        Founded {formatDate(foundationDate)}
                    </div>
                )}
                {!!employeeData?.currentEmployeeRange && (
                    <div className={styles.Pill}>
                        <img src={employeesIcon} alt="" height={'12px'} />
                        {employeeData?.currentEmployeeRange} Employees
                    </div>
                )}

                {!!fundingInfo?.stage && (
                    <div className={styles.Pill}>
                        <img src={fundingIcon} alt="" height={'12px'} />
                        {fundingInfo?.stage}
                    </div>
                )}
                {!!(headquarterLocation?.city || headquarterLocation?.state) && (
                    <div className={styles.Pill}>
                        <img src={location} alt="" height={'12px'} />
                        {headquarterLocation?.city}
                        {headquarterLocation?.city && headquarterLocation?.state && ', '}
                        {headquarterLocation?.state}
                    </div>
                )}
                {!!socialInfo?.websiteUrl ? (
                    <a
                        className={clsx(styles.Pill, styles.PillWithHover)}
                        href={
                            socialInfo?.websiteUrl?.includes('https://') || socialInfo?.websiteUrl?.includes('http://')
                                ? socialInfo?.websiteUrl
                                : `https://${socialInfo.websiteUrl}`
                        }
                        target="_blank"
                        onClick={onWebsiteClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={ctaRightWhite} alt="" height={'12px'} />
                        Website
                    </a>
                ) : (
                    addSocials('Add Website')
                )}
                {!!socialInfo?.userInputLinkedin ? (
                    <a
                        className={clsx(styles.Pill, styles.PillWithHover)}
                        href={
                            socialInfo?.userInputLinkedin?.includes('https://') || socialInfo?.userInputLinkedin?.includes('http://')
                                ? socialInfo?.userInputLinkedin
                                : `https://${socialInfo.userInputLinkedin}`
                        }
                        target="_blank"
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={linkedin} alt="" height={'12px'} style={{ marginTop: '2px' }} />
                        LinkedIn
                    </a>
                ) : (
                    addSocials('Add Linkedin')
                )}
            </div>

            <img className={styles.poweredByAica} src={poweredByAICAWhite} alt="powered by aica" height="18px" />
        </div>
    );

    const renderBasicDetails = () => (
        <div className={styles.BottomWrap}>
            <div className={styles.Header}>
                <div className={styles.Title}>Basic Details</div>
                <div className={styles.Right}></div>
            </div>
            <div className={styles.Bottom}>
                {(sectorInfo?.sector || sectorInfo?.subSector) && (
                    <>
                        <div className={styles.Title}>Sector & Sub-Sector</div>
                        <div className={styles.Badges}>
                            {!invalidSectors.includes(sectorInfo?.sector ?? '') && <div className={styles.Pill}>{sectorInfo?.sector}</div>}
                            {!invalidSectors.includes(sectorInfo?.subSector ?? '') && (
                                <div className={styles.Pill}>{sectorInfo?.subSector}</div>
                            )}
                        </div>
                    </>
                )}

                {!!overview && (
                    <>
                        <div className={styles.Title}>Business Overview</div>
                        <div className={styles.Text}>{overview}</div>
                    </>
                )}
                {!!headquarterLocation?.fullAddress && (
                    <>
                        <div className={styles.Title}>Registered Address:</div>
                        <div className={styles.Badges}>
                            <div className={styles.Pill}>{headquarterLocation?.fullAddress}</div>
                        </div>
                    </>
                )}
                {additionalDetails && (
                    <>
                        <div className={styles.Title}>Details:</div>
                        <div className={styles.Badges}>
                            {panNumber && (
                                <div className={styles.Pill}>
                                    <span className={styles.Label}>PAN:</span> {panNumber}
                                </div>
                            )}
                            {cinNumber && (
                                <div className={styles.Pill}>
                                    <span className={styles.Label}>{validateCIN(cinNumber) ? 'CIN' : 'LLPIN'}:</span> {cinNumber}
                                </div>
                            )}
                            {gstin?.length > 0 && (
                                <div className={styles.Pill}>
                                    <span className={styles.Label}>GSTIN:</span>{' '}
                                    <>
                                        {isPDFReport ? (
                                            gstin.map((gst) => Object.values(gst)[0]).join(', ')
                                        ) : (
                                            <>
                                                {getFirstGstIn(gstin)}
                                                {gstin?.length > 1 && (
                                                    <Tooltip
                                                        content={getToolTipContent(gstin)}
                                                        direction="right"
                                                        height="5.625rem"
                                                        isOverviewDetail={true}
                                                        hideDelay={500}
                                                    >
                                                        <span className={styles.ExtraGstin}> , +{gstin?.length - 1} more</span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    const render = (
        <div className={styles.Details}>
            {renderTopSection()}
            <AicaOverview style={{ marginBottom: '40px' }} module="overview" />
            {bodyHasData && renderBasicDetails()}
        </div>
    );

    if (isPDFReport)
        return reportSectionsToShow?.includes(REPORT_SECTION_NAMES.OVERVIEW) ? (
            <>
                <A4Page sectionName={REPORT_SECTION_NAMES.OVERVIEW}>
                    <div className={clsx(styles.Details, styles.PDFReport)}>
                        {renderTopSection()}
                        <AicaOverview style={{ marginTop: '0px', marginBottom: '24px' }} module={'overview'} isPDFReport={isPDFReport} />
                        {/* <>{renderBasicDetails()}</> */}
                    </div>
                </A4Page>
                {bodyHasData && (
                    <A4Page sectionName={REPORT_SECTION_NAMES.OVERVIEW}>
                        <div className={clsx(styles.Details, styles.PDFReport)}>{renderBasicDetails()}</div>
                    </A4Page>
                )}
            </>
        ) : (
            <></>
        );
    else return render;
}

export default Details;
