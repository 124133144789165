export const PAN = 'PAN';
export const PAN_ERROR_MSG = 'Please Enter A Valid PAN';

export const NAME = 'BUSINESS_NAME';
export const NAME_ERROR_MSG = 'Please Enter Full Name';

export const FULL_NAME = 'FULL_NAME';
export const FULL_NAME_ERROR_MSG = 'Please Enter Full Name';

export const BUSINESS_NAME = 'BUSINESS_NAME';
export const BUSINESS_NAME_ERROR_MSG = 'Please Enter Full Business Name';

export const SECTOR = 'SECTOR';
export const SECTOR_ERROR_MSG = 'Please Select Sector';

export const WEBSITE = 'WEBSITE';
export const WEBSITE_ERROR_MSG = 'Please Enter a valid Website URL';
// export const NAME = 'NAME';
export const EMAIL = 'EMAIL';
export const EMAIL_ERROR_MSG = 'Please enter a valid email';
export const EMAIL_PHONE_ERROR_MSG = 'Please enter a valid email or phone number';
export const PASSWORD = 'PASSWORD';
export const ORG_NAME = 'ORG_NAME';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const PASSWORD_ERROR_MSG = 'Please enter a valid password';
export const PHONE = 'PHONE';
export const PHONE_ERROR_MSG = 'Invalid phone number.';

export const TRIGGER_NOTIFICATION = 'triggerNotification';
export const ORG_DISPLAY_NAME = 'orgDisplayName';

export const COUNTRY_CODE = 'COUNTRY_CODE';

export const IDENTIFIER = 'IDENTIFIER';

// export const PAN = 'PAN';
// export const EMAIL = 'EMAIL';
