import clsx from 'clsx';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { getColorKeyFromIndex } from '../../../../../store/dashboard/transformer';
import styles from '../Graph.module.scss';

/**
 * @Description Pie graph component for custom tooltip
 */

interface PieGraphCustomTooltipProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

const renderActiveShape = (props) => {
    const { cx, cy, midAngle, outerRadius, fill, data } = props;
    const dataExists = Object.keys(data || {}).length;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + outerRadius * cos;
    const my = cy + outerRadius * sin;
    const ex = cx + (outerRadius + 50);
    const ey = my;
    return (
        <g>
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex - 20},${ey}`}
                stroke={fill ?? '#000'}
                strokeWidth={1}
                fill="none"
            />
            <circle cx={ex - 15} cy={ey} r={8} fill={fill ?? '#000'} stroke="none" />
            {/* <text x={ex + 12} y={ey} textAnchor="start" fill="#333" >{`${name}`}</text>
            <text x={ex + 12} y={ey} dy={18} textAnchor="start" fill="#444">
                {`${value} ` + (percent > 0.05 ? '' : `(${(percent * 100).toFixed(0)}%)`)}
            </text> */}
            <foreignObject x={ex + 10} y={ey - 15} width={'100%'} height={'50px'}>
                <div>
                    {dataExists &&
                        Object.keys(data).map((dataKey, i) => (
                            <div
                                style={{ marginBottom: i === 0 ? '8px' : '' }}
                                key={`${i}-${dataKey}`}
                            >
                                <div className={styles.tooltiptext}>
                                    <div>{dataKey}: </div>

                                    <div className={styles.bold}> {data[dataKey]}</div>
                                </div>
                            </div>
                        ))}
                </div>
            </foreignObject>
        </g>
    );
};

function PieGraphCustomTooltip(props: PieGraphCustomTooltipProps) {
    const { error } = props;
    const { plots, colors } = props.graphData;
    const data = plots?.[0]?.plotValues || [];

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <PieChart
                data={data}
                margin={{
                    top: 50,
                    right: 0,
                    left: -300,
                    bottom: 50,
                }}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                {/* <Legend
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 10,right:0 }}
                    iconType="circle"
                /> */}
                <Pie
                    data={data}
                    innerRadius={45}
                    outerRadius={85}
                    cornerRadius={8}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="value"
                    label={renderActiveShape}
                    labelLine={false}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors?.[getColorKeyFromIndex(index)]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default PieGraphCustomTooltip;
