import React from 'react';
import styles from './GeneratingReport.module.scss';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';

const GeneratingReport: React.FC = () => {
    return (
        <div className={styles.Generating}>
            <div className={styles.Left}>
                <LoadingSpinner color="var(--primary-text-colour)" height="20px" />

                <span className={styles.MainText}>
                    Generating AICA Report<span className={styles.Ellipsis}></span>
                </span>
            </div>

            <span className={styles.Time}>takes up to 5 mins</span>
        </div>
    );
};

export default GeneratingReport;
