import { Button, Input, Modal } from '../../../common/_custom';
import styles from './AddAssessee.module.scss';
import React from 'react';
import {
    EMAIL,
    PHONE,
    FULL_NAME,
    COUNTRY_CODE,
    TRIGGER_NOTIFICATION,
    FULL_NAME_ERROR_MSG,
    EMAIL_ERROR_MSG,
    PHONE_ERROR_MSG,
} from '../../../utils/constants/companyLogin';
import CountryCodesDropdown from '../CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import useAddAssessee from './useAddAssessee';
import CheckBox from '../../../common/CheckBox/CheckBox';

type AddAssesseeProps = {
    modalOpen: boolean;
    closeModal: Function;
    assesseeOrgId: string;
};

const AddAssessee = (props: AddAssesseeProps) => {
    const { modalOpen, closeModal, assesseeOrgId } = props;
    const { formData, changeFormValue, disabled, loading, onInviteUserClick, setFormData, formErrors } = useAddAssessee(
        assesseeOrgId,
        closeModal,
    );
    return (
        <Modal
            open={modalOpen ?? true}
            onClose={() => {
                closeModal(false);
                setFormData({
                    [FULL_NAME]: '',
                    [EMAIL]: '',
                    [PHONE]: '',
                    [COUNTRY_CODE]: null,
                    [TRIGGER_NOTIFICATION]: false,
                });
            }}
            persistent
            size="flexible"
            style={{ padding: '0' }}
        >
            <div className={styles.AddAssesseeWrapper}>
                <div className={styles.Title}>Add User</div>
                <div className={styles.FormContainer}>
                    <Input
                        value={formData[FULL_NAME]}
                        onChange={(val) => changeFormValue(val, FULL_NAME)}
                        placeholder={'Full Name'}
                        isValid={!formErrors[FULL_NAME]}
                        errorMessage={FULL_NAME_ERROR_MSG}
                    />
                    <Input
                        value={formData[EMAIL]}
                        onChange={(val) => changeFormValue(val, EMAIL)}
                        placeholder={'Email ID'}
                        isValid={!formErrors[EMAIL]}
                        errorMessage={EMAIL_ERROR_MSG}
                    />
                    <div className={styles.Row}>
                        <CountryCodesDropdown
                            //@ts-ignore
                            style={{ fontFamily: 'Gilroy-Medium' }}
                            setCountryCode={(val) => changeFormValue(val, COUNTRY_CODE)}
                        />
                        <Input
                            placeholder="Phone Number"
                            value={formData[PHONE]}
                            onChange={(val) => changeFormValue(val, PHONE)}
                            style={{ marginTop: '0', width: '100%' }}
                            type="number"
                            isValid={!formErrors[PHONE]}
                            errorMessage={PHONE_ERROR_MSG}
                        />
                    </div>
                    <CheckBox
                        label={'Send invite on email'}
                        checked={formData[TRIGGER_NOTIFICATION]}
                        onCheck={(val) => changeFormValue(val, TRIGGER_NOTIFICATION)}
                        variant={'tertiary'}
                        style={{ padding: '1.5rem 0 0 0.25rem' }}
                    />
                    <Button
                        onClick={onInviteUserClick}
                        text={'Add'}
                        variant="purple"
                        disabled={disabled}
                        loading={loading}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddAssessee;
