import { useState } from 'react';
import { infoToolTip } from '../../assets/hostedassets';
import LoadingSpinner from '../../common/_custom/LoadingSpinner/LoadingSpinner';
import styles from './RiskIndicators.module.scss';
import RiskIndicatorTable from './RiskIndicatorTable/RiskIndicatorTable';
import Tooltip from '../../common/_custom/Tooltip/Tooltip';
import { RISK_HEADER_KEYS, RISK_MESSAGES_MAP } from './RiskIndicatorConstants';
import clsx from 'clsx';
import { useRiskIndicatorStates } from './useRiskIndicatorStates';
import A4Page from '../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../store/pdfReport/pdfReport_constants';
import RiskIndicatorCard from './RiskIndicatorCard';

interface RiskProfile {
    [key: string]: number;
}

export interface Row {
    docId?: string;
    name: string;
    type: string;
    riskProfile: RiskProfile;
}

const RiskIndicators = ({ isPDFReport = false }) => {
    const [rows, setRows] = useState<Row[]>([]);
    const { loading, getStatusMessage, RiskDotMessage } = useRiskIndicatorStates({
        rows,
        setRows,
        autoFetch: true,
    });

    const render = (
        <div className={clsx(styles.ContainerWrapper, { [styles.PDFReport]: isPDFReport })}>
            <div
                className={clsx(
                    {
                        [styles.DataAvailable]: rows?.length > 0 && !loading,
                    },
                    styles.HeaderContainer,
                )}
            >
                <div className={styles.HeadingMessage}>
                    <span className={styles.Heading}>Regulatory Checks</span>
                    <span className={styles.InfoIcon}>
                        {!isPDFReport && (
                            <Tooltip
                                content={
                                    RISK_MESSAGES_MAP[RISK_HEADER_KEYS.RISK_INDICATOR_KEY] || ''
                                }
                                direction="right"
                                style={{ top: '100%' }}
                            >
                                <img src={infoToolTip} height={'14px'} />
                            </Tooltip>
                        )}
                    </span>
                </div>
                <div className={styles.DataMessage}>
                    <span className={styles.Loader}>
                        {loading && <LoadingSpinner color="#6021B3" height="1.125rem" />}
                    </span>
                    <span className={styles.Message}>{getStatusMessage()}</span>
                </div>
            </div>
            {rows?.length > 0 && !loading && (
                <>
                    {isPDFReport ? (
                        <div className={styles.CardsContainer}>
                            {rows.map((row, index) => (
                                <RiskIndicatorCard key={index} row={row} />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.TableWrapper}>
                            <div className={styles.TableContainer}>
                                <RiskIndicatorTable
                                    rows={rows}
                                    setRows={setRows}
                                    isPDFReport={isPDFReport}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.FooterContainer}>
                        <div className={styles.FooterMessage}>
                            <span className={styles.Note}>Note:</span> Risks are flagged based on
                            the legal name match (please verify).
                        </div>
                        <div className={styles.RiskDotMessage}>{RiskDotMessage()}</div>
                    </div>
                </>
            )}
        </div>
    );

    if (isPDFReport)
        return rows?.length ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.REGULATORY_INDICATORS}>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default RiskIndicators;
