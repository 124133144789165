import { useGSTContext } from '../../Context/GstContext';
import styles from '../GstErrorScreens/GstErrorScreens.module.scss';
import { Button, Input } from '../../../../../../../../common/_custom';
import CheckBox from '../../../../../../../../common/CheckBox/CheckBox';

const GstConsent = (props) => {
    const { formValues, buttonDisable, setFormValues, handleProceed, gstData } = useGSTContext();
    return (
        <>
            <div className={styles.GstContentContainer}>
                <div className={styles.GstContainer}>
                    <p className={styles.Label}>{gstData?.state} </p>
                    <p className={styles.SubLabel}>
                        {gstData?.gstin_number ? `(${gstData?.gstin_number})` : ''}
                    </p>
                </div>
                <div>
                    <Input
                        style={{ marginTop: '0' }}
                        onChange={(e) => {
                            setFormValues((prev) => {
                                return {
                                    ...prev,
                                    gstUserName: e,
                                };
                            });
                        }}
                        value={formValues.gstUserName}
                        placeholder={'GST User Name'}
                    />
                    <CheckBox
                        labelStyles={{ color: '#000000', fontSize: '0.75rem' }}
                        style={{ padding: 0, marginTop: '1.5rem' }}
                        checked={formValues.consent}
                        onCheck={(e) => {
                            setFormValues((prev) => {
                                return {
                                    ...prev,
                                    consent: e,
                                };
                            });
                        }}
                        variant={'primary'}
                        label={'I agree to provide consent to fetch my GST report'}
                    />
                </div>
            </div>
            <div
                className={styles.Footer}
                style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '0.25rem' }}
            >
                <Button
                    text={'Proceed'}
                    style={{ marginTop: '0' }}
                    onClick={handleProceed}
                    size={'wide'}
                    disabled={buttonDisable}
                />
                <p className={styles.FooterSubtext}>
                    We will get one time read access of your GST files
                </p>
            </div>
        </>
    );
};

export default GstConsent;
