import React from 'react';
import styles from './CreditProgressBar.module.scss';

interface CreditProgressBarProps {
    width: number;
    ParentWidth?: string | number;
    inactive?: boolean;
}

const CreditProgessBar: React.FC<CreditProgressBarProps> = ({ width, ParentWidth, inactive }) => {
    return (
        <div
            className={styles.BarParent}
            style={ParentWidth ? { width: ParentWidth } : {}}
            data-inactive={inactive}
        >
            <div className={styles.BarChild} style={{ width: `${width}%` }}></div>
        </div>
    );
};

export default CreditProgessBar;
