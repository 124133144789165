import clsx from 'clsx';
import styles from '../../RelatedParties.module.scss';
import { alertIcon, alertIconDisabled, caretRight } from '../../../../../../assets/hostedassets';
import { toTitleCase } from '../../../../../../utils/utils';
import { getImage } from '../../relatedParty_constants';

function FirstDegreeRow({ edgeType, isPDFReport, expandAll, entityEntry, handleAlertsClick, handleShowRelationsClick }) {
    return (
        <tr
            key={entityEntry?.fromKid}
            className={clsx(styles.FirstDegreeRow, styles.FirstDegreeRow_Hover)}
            onClick={() => {
                handleShowRelationsClick(entityEntry, edgeType);
            }}
        >
            <td className={styles.RowItem}>
                <div className={styles.EntityNameContainer}>
                    {!isPDFReport && (
                        <div
                            className={clsx(styles.Caret, {
                                [styles.Caret_Disabled]: !entityEntry?.rows?.length,
                                [styles.Caret_Expanded]: entityEntry?.rows?.length && (entityEntry.isExpanded || expandAll),
                            })}
                        >
                            <img
                                className={clsx(styles.CaretImg, {
                                    [styles.CaretImg_Rotate]: entityEntry.isExpanded || (expandAll && entityEntry?.rows?.length),
                                })}
                                src={caretRight}
                                alt="caret"
                                height={'12px'}
                            />
                        </div>
                    )}
                    <div className={styles.EntityNamePill}>
                        {!isPDFReport && (
                            <img
                                src={getImage(entityEntry?.type)}
                                alt="ent"
                                height={'14px'}
                                style={{
                                    marginTop: '2px',
                                }}
                            />
                        )}
                        <span className={styles.Text}>{toTitleCase(entityEntry?.fromName) || '-'}</span>
                    </div>
                </div>
            </td>

            <td className={styles.RowItem}>{/* {getRelationType(entityEntry?.edgeType, entityEntry?.type) || '-'} */}</td>

            <td className={styles.RowItem}>
                {/* <div className={styles.EntityNamePill}>
                    {toTitleCase(entityEntry?.toName) || '-'}
                </div> */}
            </td>

            <td className={styles.RowItem}>
                <div
                    className={clsx(styles.Risk, {
                        [styles.Risk_Severe]: entityEntry?.risk === 'SEVERE',
                        [styles.Risk_High]: entityEntry?.risk === 'HIGH',
                        [styles.Risk_Medium]: entityEntry?.risk === 'MEDIUM',
                        [styles.Risk_Low]: entityEntry?.risk === 'LOW',
                    })}
                >
                    {entityEntry?.risk && entityEntry?.risk !== 'null' ? toTitleCase(entityEntry?.risk) : 'NA'}
                </div>
            </td>

            {!isPDFReport && (
                <td className={styles.RowItem}>
                    <div
                        className={clsx(styles.Alerts, {
                            [styles.Alerts_Disabled]: !entityEntry?.alerts?.length,
                        })}
                        onClick={() => {
                            handleAlertsClick(entityEntry);
                        }}
                    >
                        <img
                            src={entityEntry?.alerts?.length ? alertIcon : alertIconDisabled}
                            alt="alert"
                            style={{
                                margin: '0px 5px 2px 0',
                            }}
                        />
                        <span>{entityEntry?.alerts?.length} Alerts</span>
                    </div>
                </td>
            )}
        </tr>
    );
}

export default FirstDegreeRow;
