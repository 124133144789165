export const REPORT_SECTION_NAMES = {
    COVER: 'Cover',
    CONTENTS: 'Contents',
    OVERVIEW: 'Overview',
    AICA_GRADE: 'Risk Grade',

    MANAGEMENT_PROFILE_SUMMARY: 'Management Profile Summary',
    MANAGEMENT_PROFILE_INDIVIDUAL: 'Management Profile',
    FINANCIAL_SUMMARY: 'Financial Summary',
    FINANCIAL_RATIOS: 'Key Financial Metrics',
    CHARGE_DETAILS: 'Charge Details',
    EMPLOYEE_TREND_CHART: 'Employee Trend',
    WEBSITE_TRAFFIC_CHART: 'Website Traffic',
    KEY_INVESTORS: 'Key Investors',
    KEY_COMPETITORS: 'Key Competitors',
    SECTOR_OUTLOOK: 'Sector Outlook',
    NEWS_UPDATES: 'NEWS_UPDATES',
    COMPLIANCES_SUMMARY: 'Compliances Summary',
    COMPLIANCES_SUMMARY_OVERVIEW: 'Compliances Summary Overview',
    AUDITED_FINANCIALS: 'Audited Financials',
    RELATED_PARTIES: 'Related Parties',
    LEGAL_CASES: 'Legal Cases',
    REGULATORY_INDICATORS: 'Regulatory Indicators',
};

export const PDF_REPORT_CONSTANTS = {
    INITIALISE_PDF_REPORT: 'INITIALISE_PDF_REPORT',
    RESET_PDF_REPORT: 'RESET_PDF_REPORT',

    SET_PAGE_NUMBER: 'PDF_SET_PAGE_NUMBER',
    SET_PAGES_COUNT_FOR_SECTION: 'PDF_SET_PAGES_COUNT_FOR_SECTION',
    INC_PAGES_COUNT_FOR_SECTION: 'PDF_INC_PAGES_COUNT_FOR_SECTION',
};
