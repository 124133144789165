import React from 'react';
import styles from './GstConnectionPopup.module.scss';
import clsx from 'clsx';

interface GSTPopupProps {
    gstList: {
        state: string;
        gstin: string;
        connectionStatus: string;
    }[];
}

const list = [
    {
        gstin: 'GSTIN1',
        connectionStatus: 'Connected',
    },
    {
        gstin: 'GSTIN1',
        connectionStatus: 'Connected',
    },
    {
        gstin: 'GSTIN1',
        connectionStatus: 'Connected',
    },
    // {
    //     gstin: 'GSTIN1',
    //     connectionStatus: 'Connected',
    // },
    // {
    //     gstin: 'GSTIN1',
    //     connectionStatus: 'Connected',
    // },
];

function GstConnectionPopup({ gstList = [] }: GSTPopupProps) {
    return (
        <div className={styles.GstPopup}>
            <div className={styles.Title}>Charts are only generated for connected GSTs:</div>
            {gstList?.map((gst, i) => (
                <div className={styles.GSTRow} key={i}>
                    <div className={styles.Left}>
                        {gst?.state && <span className={styles.State}>{gst?.state}:&nbsp;</span>}
                        <span className={styles.GstNum}>{gst?.gstin}</span>
                    </div>
                    <div className={styles.Right}>
                        <div
                            className={clsx(styles.Badge, {
                                [styles.Connected]: gst.connectionStatus === 'Connected',
                                [styles.NotConnected]: gst.connectionStatus === 'Not Connected',
                                [styles.Uploaded]:
                                    gst.connectionStatus === 'Uploaded' ||
                                    gst.connectionStatus === 'Expired',
                            })}
                        >
                            {gst?.connectionStatus}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default GstConnectionPopup;
