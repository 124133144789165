import { useEffect, useState, useRef } from 'react';
import styles from './SearchBox.module.scss';
import { Mixpanel } from '../../../../../../../utils/mixpanel';
import useDebounce from '../../../../../../../utils/constants/useDebounce';
import { Input } from '../../../../../../../common/_custom';
import clsx from 'clsx';
import { searchCutIcon, searchIcon } from '../../../../../../../assets/hostedassets';
import { useAppSelector } from '../../../../../../../app/hooks';

interface DropdownOption {
    id: string;
    label: string;
}

interface SearchBoxProps {
    onSearch: (companyName: string) => void;
    categoryParam: string;
    location: any;
    accessTierParam: string;
    placeholder?: string;
    showDropdown?: boolean;
    dropdownOptions?: DropdownOption[];
    onOptionSelect?: (id: string) => void;
    allowResize?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({
    onSearch,
    categoryParam,
    location,
    accessTierParam,
    showDropdown = false,
    dropdownOptions = [],
    onOptionSelect,
    placeholder = 'Search by Company Name (Min 3 char)',
    allowResize = false,
}) => {
    const [queryText, setQueryText] = useState('');
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const debouncedQueryText = useDebounce(queryText.trim(), 300);
    const searchBoxRef = useRef<HTMLDivElement>(null);

    const commentThreadState = useAppSelector((state) => state.commentsThreadReducer);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (debouncedQueryText.length === 0 || debouncedQueryText.length >= 3) {
            handleSearch();
        }
    }, [debouncedQueryText]);

    const handleSearch = () => {
        onSearch(debouncedQueryText);
        if (debouncedQueryText.length >= 3) {
            const trackProperties = {
                search_query: debouncedQueryText,
                search_length: debouncedQueryText.length,
                view: categoryParam,
                timestamp: new Date().toISOString(),
            };
            Mixpanel.track('Search Button Click', trackProperties);
        }
    };

    useEffect(() => {
        setQueryText('');
    }, [categoryParam, accessTierParam]);

    const handleTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQueryText(value);
        setSelectedOption(null);
        setIsDropdownVisible(value.length >= 3);
    };

    const handleOptionSelection = (id: string) => {
        setIsDropdownVisible(false);
        const selected = dropdownOptions.find((option) => option.id === id);
        if (selected) {
            setSelectedOption(selected);
            setQueryText(selected.label);
            onOptionSelect?.(id);
            onSearch(selected.label);
        }
    };

    const handleClearSearch = () => {
        setQueryText('');
        setSelectedOption(null);
        onSearch('');
    };

    return (
        <div
            className={clsx(styles.searchBox, {
                [styles.searchBoxCommentsActive]:
                    commentThreadState.showCommentsThread && !commentThreadState.closingCommentsThread && allowResize,
            })}
            ref={searchBoxRef}
        >
            <div className={styles.boxWrapper}>
                <input
                    className={clsx(styles.searchInput, {
                        [styles.HasData]: !!queryText,
                    })}
                    type="text"
                    onChange={handleTextSearch}
                    value={queryText}
                    placeholder={placeholder}
                />
                {queryText ? (
                    <img alt="Cancel Icon" src={searchCutIcon} className={styles.cancelIcon} onClick={handleClearSearch} />
                ) : (
                    <img alt="Search Icon" src={searchIcon} className={styles.searchIcon} />
                )}
            </div>

            {showDropdown && isDropdownVisible && (
                <div className={styles.dropdownContainer}>
                    {dropdownOptions.length > 0 ? (
                        dropdownOptions.map((option) => (
                            <div key={option.id} className={styles.dropdownItem} onClick={() => handleOptionSelection(option.id)}>
                                {option.label}
                            </div>
                        ))
                    ) : (
                        <div className={styles.dropdownItem}>No company available</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBox;
