const namespace = 'COMPANY_';
export const CONSTANTS = {
    SET_IS_FETCHING_CONFIG: `${namespace}SET_IS_FETCHING_CONFIG`,
    SET_IS_FETCHING_UPLOAD_JOURNEY: `${namespace}SET_IS_FETCHING_UPLOAD_JOURNEY`,

    FETCH_COMPANY_CONFIG: `${namespace}FETCH_COMPANY_CONFIG`,
    FETCH_COMPANY_CONFIG_SUCCESS: `${namespace}FETCH_COMPANY_CONFIG_SUCCESS`,

    CONNECT_GST: `${namespace}CONNECT_GST`,
    CONNECT_GST_SUCCESS: `${namespace}CONNECT_GST_SUCCESS`,

    FETCH_UPLOAD_JOURNEY_DATA: `${namespace}FETCH_UPLOAD_JOURNEY_DATA`,
    FETCH_UPLOAD_JOURNEY_DATA_SUCCESS: `${namespace}FETCH_UPLOAD_JOURNEY_DATA_SUCCESS`,

    UPDATE_CREDIT_BUREAU_CMR: `${namespace}UPDATE_CREDIT_BUREAU_CMR`,
    UPDATE_CREDIT_BUREAU_CIBIL: `${namespace}UPDATE_CREDIT_BUREAU_CIBIL`,
    UPDATE_GST_REPORTS: `${namespace}UPDATE_GST_REPORTS`,
    UPDATE_FINANCIAL_REPORTS: `${namespace}UPDATE_FINANCIAL_REPORTS`,
    UPDATE_BANK_ACCOUNTS: `${namespace}UPDATE_BANK_ACCOUNTS`,
    UPDATE_ADDITIONAL_DOCS: `${namespace}UPDATE_ADDITIONAL_DOCS`,
    SEND_CIBIL_CONSENT_LINK: `${namespace}SEND_CIBIL_CONSENT_LINK`,
    SEND_CIBIL_CONSENT_LINK_SUCCESS: `${namespace}SEND_CIBIL_CONSENT_LINK_SUCCESS`,
    CAPTURE_CIBIL_CONSENT: `${namespace}CAPTURE_CIBIL_CONSENT`,
    UPDATE_PROVISIONAL_STATEMENTS: `${namespace}UPDATE_PROVISIONAL_STATEMENTS`,
    UPDATE_DEBT_SCHEDULE: `${namespace}UPDATE_DEBT_SCHEDULE`,
    FETCH_MONTHLY_VIEW_DATA_SUCCESS: `${namespace}FETCH_MONTHLY_VIEW_DATA_SUCCESS`,
    FETCH_MONTHLY_VIEW_DATA: `${namespace}FETCH_MONTHLY_VIEW_DATA`,
    FETCH_METADATA_FOR_INPUT_MODAL: `${namespace}FETCH_METADATA_FOR_INPUT_MODAL`,
    UPDATE_UPLOADING_STATUS: `${namespace}UPDATE_UPLOADING_STATUS`,
    UPDATING_UPLOADING_STATUS: `${namespace}UPDATEING_UPLOADING_STATUS`,
    FETCH_TABLE_DATA_FOR_MIS: `${namespace}FETCH_TABLE_DATA_FOR_MIS`,
    FETCH_MIS_TABLE_OPTIONS: `${namespace}FETCH_MIS_TABLE_OPTIONS`,
    UPDATE_DATA_METRICS: `${namespace}UPDATE_DATA_METRICS`,
    INGEST_PROVISIONAL_STATEMENTS: `${namespace}INGEST_PROVISIONAL_STATEMENTS`,
    FETCH_VISUALISATION_TABLE_DATA: `${namespace}FETCH_VISUALISATION_TABLE_DATA`,
    FETCH_RAW_DATA_FOR_VISUALISATION: `${namespace}FETCH_RAW_DATA_FOR_VISUALISATION`,
    FETCH_AUDITED_TABLE_DATA: `${namespace}FETCH_AUDITED_TABLE_DATA`,
    UPDATE_AUDITED_TABLE_DATA: `${namespace}UPDATE_AUDITED_TABLE_DATA`,
    FETCH_AICA_OVERVIEW_DATA: `${namespace}FETCH_AICA_OVERVIEW_DATA`,
    SEND_AICA_OVERVIEW_FEEDBACK: `${namespace}SEND_AICA_OVERVIEW_FEEDBACK`,
    SET_DEBT_PRODUCTS_FILTERS: `${namespace}SET_DEBT_PRODUCTS_FILTERS`,
    SET_SHOW_INVITE_USER: `${namespace}SET_SHOW_INVITE_USER`,
    SET_SHOW_MANAGE_USER_DRAWER: `${namespace}SET_SHOW_MANAGE_USER_DRAWER`,
};

export const MONTHLY_VIEW_DATA_TYPES = {
    NOT_UPLOADED: 'Not Uploaded',
    UPLOADED: 'Uploaded',
    INGESTED: 'Ingested',
};

// export const COMPANY_UPLOAD_TABS = [
//   { id: 'connect', label: 'Connect GST' },
//   { id: 'bank', label: 'BankStatement' },
//   { id: 'financials', label: 'Financials' },
// ]

// export const COMPANY_UPLOAD_TABS = {
//     CREDIT_BUREAU: 'CREDIT_BUREAU',
//     GST: 'GST',
//     BANK: 'BANK',
//     FINANCIALS: 'FINANCIALS',
//     FINISHED: 'FINISHED',
// };
