import clsx from 'clsx';
import React from 'react';
import styles from '../RevenueVendorExpenseTable.module.scss';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';

interface RowsProps {
    rows: any;
    headers: any;
    tableRef: any;
}

function Rows(props: RowsProps) {
    const { rows, headers, tableRef } = props;
    return (
        <>
            {rows?.map?.((row, i) => (
                <React.Fragment key={i}>
                    <tr key={i} className={clsx(styles.TableBodyRow)}>
                        {headers?.map((header, j) => (
                            <td
                                className={clsx(
                                    (row?.[header?.key]?.includes('₹') || !row?.[header?.key]) &&
                                        styles.RightAligned,
                                    styles.TableBodyRowItem,
                                )}
                                key={`${header?.name}-${i}`}
                            >
                                {header?.key === 'state' &&
                                row?.[header?.key] !== '-' &&
                                row?.[header?.key]?.length > 25 ? (
                                    <Tooltip
                                        content={`${row?.[header?.key]}`}
                                        direction="bottom"
                                        fitContent
                                    >
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {row?.[header?.key]?.substring(0, 25)}...
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <>{row?.[header?.key] || '-'}</>
                                )}
                            </td>
                        ))}
                    </tr>
                </React.Fragment>
            ))}
        </>
    );
}

export default Rows;
