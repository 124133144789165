import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { ActionMenuConst } from '../../modules/Investor/Deals/components/DealsList/DealsTable/components/DealsRow/DealsRow';
import { useOutsideClick } from '../../utils/constants/hooks';

import threeDotVertical from '../../assets/three-dot-vertical.svg';

import styles from './ActionMenu.module.scss';
import { useLocation } from 'react-router-dom';

type Props = {
    triggerPayload: any;
    rowData: any;
    style?: React.CSSProperties;
    actionMenu: ActionMenuConst;
    source: string;
    disabled?: boolean;
    onThreeDotsClick?: () => void;
};

const ActionMenu: React.FC<Props> = ({ triggerPayload, style, actionMenu, rowData, source, disabled, onThreeDotsClick }) => {
    const location = useLocation();
    const actionMenuItems = rowData?.mainConfig?.actionConfig || [];
    const [menuItems, setMenuItems] = useState<string[]>([]);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const isCompanyDealsPage = location.pathname.includes('company-deals');

    const threeDotRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        if (source === 'deal' || source === 'investor-deal') {
            if (actionMenuItems.length > 0) {
                if (actionMenuItems.includes('One Pager')) {
                    setMenuItems(actionMenuItems);
                } else {
                    setMenuItems([...actionMenuItems, 'One Pager']);
                }
            }
            if (isCompanyDealsPage) {
                setMenuItems((actionMenuItems) => actionMenuItems.filter((item) => item !== 'AICA Report'));
            }
        } else if (actionMenuItems.length > 0) {
            setMenuItems(actionMenuItems);
        }
    }, [actionMenuItems, source]);

    useOutsideClick(threeDotRef, () => {
        closeMenu();
    });

    const toggleMenu = () => {
        if (disabled) return;
        onThreeDotsClick?.();
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    return (
        <div className={styles.Action}>
            {actionMenuItems?.length != null && (
                <div
                    className={clsx(styles.ThreeDotBox, {
                        [styles.ThreeDotActive]: !!showMenu,
                        [styles.Disabled]: disabled,
                    })}
                >
                    <img
                        src={threeDotVertical}
                        alt="upl"
                        height="30px"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleMenu();
                        }}
                        ref={threeDotRef}
                    />
                </div>
            )}

            {showMenu && (
                <div className={clsx(styles.Options)}>
                    {menuItems?.map((item, index) => (
                        <div
                            key={index}
                            className={clsx({
                                [styles.Option]: true,
                                // [styles.Disabled]:
                                //     !actionMenuItems.includes('One Pager') && item === 'One Pager',
                                [styles.Hide]: !actionMenuItems.includes('One Pager') && item === 'One Pager',
                            })}
                            onMouseDown={() => {
                                actionMenu?.[item]?.action?.(triggerPayload, source);
                            }}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ActionMenu;
