import React, { useEffect, useState } from 'react';
import SideDrawerHeader from './components/SideDrawerHeader';
import closeIcon from './close.svg';
import styles from './SideDrawer.module.scss';
import clsx from 'clsx';
import { handleBackgroundScroll } from '../../../utils/utils';
import SideDrawerFooter from './components/SideDrawerFooter';
import greyLock from '../../../assets/grey-lock.svg';

interface SideDrawerProps {
    children?: React.ReactNode;
    heading?: string | React.ReactNode;
    allowClose?: boolean;
    onClose?: Function;
    width?: string;
    dontClose?: boolean;
    showHeading?: boolean;
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
}

function SideDrawer({
    children,
    heading = 'Upload Data',
    allowClose = true,
    onClose,
    width,
    dontClose,
    showHeading,
    style,
    iconStyle,
}: SideDrawerProps) {
    const TIMING_MS = 500;
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        handleBackgroundScroll(false);
        document.addEventListener('keydown', closeDrawerOnEsc);
        return () => {
            handleBackgroundScroll(true);
            document.removeEventListener('keydown', closeDrawerOnEsc);
        };
    }, []);

    const closeDrawerOnEsc = (e) => {
        if (!dontClose && e.key === 'Escape') closeDrawer();
    };

    const closeDrawer = () => {
        if (dontClose) {
            onClose?.();
            return;
        }
        setClosing(true);
        setTimeout(() => {
            onClose?.();
            setClosing(false);
        }, TIMING_MS - 1);
    };

    return (
        <div
            className={clsx(styles.DrawerBackdrop, {
                [styles.CloseDrawerBackdrop]: closing,
            })}
            onClick={closeDrawer}
            style={{ animationDuration: `${TIMING_MS}ms` }}
        >
            <div
                className={clsx(styles.DrawerBody, {
                    [styles.CloseDrawer]: closing,
                })}
                onClick={(e) => e.stopPropagation()}
                style={{ ...style, width: width ?? '', animationDuration: `${TIMING_MS}ms` }}
            >
                {showHeading && <SideDrawerHeader>{heading}</SideDrawerHeader>}
                {allowClose && (
                    <img
                        className={styles.closeIcon}
                        src={closeIcon}
                        alt="close"
                        height="15px"
                        onClick={closeDrawer}
                        style={iconStyle}
                    />
                )}
                {children}
            </div>
        </div>
    );
}

SideDrawer.Header = SideDrawerHeader;
SideDrawer.Footer = SideDrawerFooter;

export default SideDrawer;
