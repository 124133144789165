import { useAppDispatch } from '../../../app/hooks';
import { updateDealApplicationData } from '../../../store/investor/action';
import { TransformDataFromCreateDeal, TransformDataFromDebtProducts } from './DealApplicationTransformers';

export type MasterData = {
    products?: string[];
    dealTerms: Object;
    dealStatus?: string;
    dealReceiverDetailMap?: DealRecieverDetailMap;
};

export type DealRecieverDetailMap = Map<string, LenderValues>;

export type LenderValues = {
    dealTerms: Object;
    lenderName?: string;
    productEnums: string[];
    isExisting?: boolean;
};

type useDADataTransformationsArgs = {
    source: string;
};

const useDADataTransformations = (args: useDADataTransformationsArgs) => {
    const { source } = args;
    const dispatch = useAppDispatch();
    const getTransformer = () => {
        switch (source) {
            case 'debtProducts':
                return TransformDataFromDebtProducts;
            case 'createDeal':
            case 'editDeal':
                return TransformDataFromCreateDeal;
            default:
                return null;
        }
    };
    const createDealData = (data: any) => {
        const transformer = getTransformer();
        if (transformer) {
            dispatch(updateDealApplicationData({ masterData: transformer(data) }));
            return transformer(data);
        }
    };

    return {
        createDealData,
    };
};

export default useDADataTransformations;
