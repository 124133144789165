import axios from 'axios';
import { polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function fetchBankStatementDataService() {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_BANK_STMT_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            // "endDate" : "2024-01-31",
            csv: false,
        },
    });
}

const API_ENDPOINTS = {
    FETCH_BANK_STMT_DATA: '/data/bankStatementData',
};
