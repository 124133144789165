import axios from 'axios';
import { polusBaseUrl, POST } from '../../../utils/axios-interceptor';

export function fetchDebtDetailsDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_DEBT_SCHEDULE_DATA,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
                // orgId: '65febce9c54b6d0066e9aeeb',
                raw: true,
            },
        },
    });
}

export function fetchDebtDocsService(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_DEBT_DOCS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    FETCH_DEBT_SCHEDULE_DATA: '/unifier/getDebtScheduleData',
    FETCH_DEBT_DOCS: '/data/getDebtDocs',
};
