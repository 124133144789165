import React, { useRef, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import CircularSelect from '../../CircularSelect/CircularSelect';
import styles from '../MagicUploadFilesTable.module.scss';
import { MagicUploadFileData } from '../../../useFetchDataForMU';

interface Props {
    file: MagicUploadFileData;
    docType: string;
    rowIndex: number;
    isPreTagged: boolean;
    shouldAnimate: boolean;
    selectDocType: (option: any, file: MagicUploadFileData) => Promise<any>;
    documentList: any[];
    isLoading: boolean;
    isSelectingDocType: boolean;
    isFinalized?: boolean;
}

const DocTypeCell: React.FC<Props> = ({
    file,
    docType,
    rowIndex,
    isPreTagged,
    shouldAnimate,
    selectDocType,
    documentList,
    isLoading,
    isSelectingDocType,
    isFinalized = false,
}) => {
    const animationRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = animationRef.current;
        if (!el) return;

        const handleAnimationEnd = () => {
            // Only remove animation classes, keep the wrapper classes
            if (el.classList.contains(styles.successAnimation)) {
                el.classList.remove(styles.successAnimation);
            }
            if (el.classList.contains(styles.errorAnimation)) {
                el.classList.remove(styles.errorAnimation);
            }
        };

        el.addEventListener('animationend', handleAnimationEnd);
        return () => el.removeEventListener('animationend', handleAnimationEnd);
    }, []);

    const animationDelay = `${rowIndex * 0.1}s`;

    return (
        <div
            ref={animationRef}
            style={
                {
                    position: 'relative',
                    '--animation-delay': animationDelay,
                } as React.CSSProperties
            }
            className={`${isPreTagged ? (docType ? styles.AnimationWrapper : styles.AnimationErrorWrapper) : ''} ${
                shouldAnimate ? (docType ? styles.successAnimation : styles.errorAnimation) : ''
            }`}
        >
            {isPreTagged && (
                <div className={isPreTagged ? (docType ? styles.AnimatedImage : styles.AnimatedImageError) : styles.dNone}>
                    <Player
                        src={
                            docType
                                ? 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/SuccessAnimationMU.json'
                                : 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json'
                        }
                        style={{
                            height: '1.5rem',
                            width: '1.5rem',
                        }}
                        autoplay
                        keepLastFrame
                        speed={4}
                    />
                </div>
            )}
            <div className={isPreTagged ? (docType ? styles.SuccessAnimatedPill : styles.ErrorAnimatedPill) : ''}>
                <CircularSelect
                    placeholder={'Search Data Type'}
                    option={documentList}
                    onSelect={async (option) => {
                        return await selectDocType(option, file);
                    }}
                    selectedOption={docType}
                    isFinalized={isFinalized}
                    disabled={isLoading}
                    isSelectingDocType={isSelectingDocType}
                />
            </div>
        </div>
    );
};

export default DocTypeCell;
