import clsx from 'clsx';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    CartesianGrid,
    Legend,
    Label,
} from 'recharts';
import styles from '../Graph.module.scss';

/**
 * @Description Vertical Bar graph component
 */

interface VerticalBarGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function VerticalBarGraph(props: VerticalBarGraphProps) {
    const { error } = props;
    const { colors, hasNegative, plots, metadata } = props.graphData;
    const data = plots[0]?.plotValues || [];

    const getBarGap = () => {
        if (data.length === 1) return '46%';
        else if (data.length > 1 && data.length < 8) return '30%';
        else if (data.length >= 8) return '20%';
        else return '20%';
    };
    const CustomizedAxisTick = (props: any) => {
        const { x, y, payload } = props;
        const text = payload.value;
        const maxLength = 18;

        const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={-140}
                    y={0}
                    dy={4}
                    fontSize={isMobile ? '8px' : '12px'}
                    textAnchor="start"
                    fill="#666"
                >
                    <title>{text}</title>
                    {truncatedText}
                </text>
            </g>
        );
    };

    const CustomizedLabel = (props) => {
        const { x, y, fill, value, width, viewBox, height } = props;
        const isAmountValue = ['Lacs', 'Cr', 'Amount'].includes(metadata?.yType);

        const getAmountSuffix = () => {
            switch (metadata?.yType) {
                case 'Lacs':
                    return 'L';
                case 'Cr':
                    return 'Cr';
            }
        };
        return (
            <text
                x={viewBox.x + width}
                y={y}
                dy={height - 2}
                dx={5}
                fontSize="12"
                fontFamily="sans-serif"
                fill={fill}
                textAnchor="start"
            >
                {isAmountValue ? '₹' : ''}
                {value} {getAmountSuffix()}
            </text>
        );
    };

    const getVerticalCoordintes = (props) => {
        const { x, width, tickCount } = props.xAxis;

        let coordinates = [x];
        const gap = width / (tickCount - 1) || 0;
        for (let i = 1; i <= tickCount; i++) {
            coordinates.push(x + gap * i);
        }
        return coordinates;
    };

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <BarChart
                width={500}
                height={250}
                data={data}
                margin={{
                    top: 10,
                    right: 40,
                    left: 90,
                    bottom: 5,
                }}
                layout={'vertical'}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 0, right: 0 }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                    />
                ) : null}
                <CartesianGrid
                    vertical={true}
                    strokeDasharray="3 3"
                    horizontal={false}
                    verticalCoordinatesGenerator={getVerticalCoordintes}
                />
                <XAxis
                    type="number"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    interval={0}
                    tickLine={false}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={-6}
                            angle={0}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                            position={'bottom'}
                        />
                    )}
                </XAxis>
                <YAxis
                    tick={<CustomizedAxisTick />}
                    axisLine={{ stroke: '#ffffff' }}
                    type="category"
                    dataKey="xValue"
                    tickLine={false}
                >
                    {/* {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )} */}
                </YAxis>
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {hasNegative ? <ReferenceLine y={0} stroke="#000000" /> : null}

                <Bar
                    dataKey={'yValue'}
                    // label={{ position: 'right', fontSize: '12px' }}
                    fill={error ? colors?.error?.primary : colors?.primary}
                    radius={[4, 4, 4, 4]}
                    label={<CustomizedLabel />}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default VerticalBarGraph;
