import Progressbar from '../../../../../../../common/Progressbar/Progressbar';
import SideDrawer from '../../../../../../../common/_custom/SideDrawer/SideDrawer';
import dropdownPurple from '../../../../../../../assets/dropdown-purple.svg';
import infoIcon from '../../../../../../../assets/info.svg';
import styles from './AicaGradeSideDrawer.module.scss';
import Tooltip from '../../../../../../../common/_custom/Tooltip/Tooltip';
import { useAppSelector } from '../../../../../../../app/hooks';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { getRiskType, RISK_MAP, RISK_TYPES } from '../aicaGradeConstants';
import MetricRiskBadge from '../MetricRiskBadge/MetricRiskBadge';
import A4Page from '../../../../../../AicaPDFReport/A4Page/A4Page';

function AicaGradeSideDrawer({ showDrawer = false, closeDrawer = () => {}, isPDFReport = false }) {
    const aicaGradeData = useAppSelector((state) => state.dashboard.dashboardReducer.aicaGradeData);

    const render = (
        <div
            className={clsx(styles.AicaGradeSideDrawer, {
                [styles.SideDrawerPDFReport]: isPDFReport,
            })}
        >
            <h1 className={styles.Title}>Risk Parameters</h1>

            <div className={styles.Content}>
                <div className={styles.LegendContainer}>
                    {Object.entries(RISK_MAP).map(([gradeKey, gradeData]) => (
                        <div className={styles.SingleLegend} key={gradeKey}>
                            <div
                                className={styles.Icon}
                                style={{
                                    backgroundColor:
                                        gradeKey === RISK_TYPES.SEVERE
                                            ? gradeData.background
                                            : gradeData.color,
                                }}
                            ></div>
                            {gradeData.label}
                        </div>
                    ))}
                </div>

                <div className={styles.MetricsData}>
                    {aicaGradeData?.headers.map((data, i) => (
                        <div
                            className={clsx(styles.MainMetric, {
                                [styles.NoData]: !data?.metrics?.length,
                            })}
                            key={`${data.displayName}-${i}`}
                        >
                            <div className={styles.Top}>
                                <div className={styles.Left}>
                                    <span className={styles.MetricNameText}>
                                        {data.displayName}
                                    </span>

                                    {!!data?.tooltipDescription && !isPDFReport && (
                                        <Tooltip
                                            content={data.tooltipDescription}
                                            direction="right"
                                        >
                                            <img src={infoIcon} alt="score" height="18px" />
                                        </Tooltip>
                                    )}
                                    <MetricRiskBadge
                                        score={data?.headerScore}
                                        isPDFReport={isPDFReport}
                                    />
                                </div>
                            </div>
                            {!!data?.metrics?.length && (
                                <div className={styles.Submetrics}>
                                    {data?.metrics?.map((metric, j) => {
                                        const riskType = RISK_MAP[getRiskType(metric?.metricScore)];
                                        const isRiskTypeSevere = riskType?.label === 'Severe';
                                        return (
                                            <div
                                                className={styles.SubmetricCard}
                                                key={`${metric?.displayName}-${j}`}
                                                style={{
                                                    backgroundColor: isRiskTypeSevere
                                                        ? '#E58588'
                                                        : riskType?.background,
                                                    color: isRiskTypeSevere ? 'white' : 'black',
                                                }}
                                            >
                                                <div
                                                    className={styles.Icon}
                                                    style={{
                                                        backgroundColor: isRiskTypeSevere
                                                            ? riskType?.background
                                                            : riskType?.color,
                                                    }}
                                                ></div>
                                                <div className={styles.Text}>
                                                    {metric?.displayName}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    if (isPDFReport) return render;
    else
        return (
            <>
                {showDrawer && (
                    <SideDrawer allowClose heading="Alerts" onClose={closeDrawer} width="70%">
                        {render}
                    </SideDrawer>
                )}
            </>
        );
}

export default AicaGradeSideDrawer;
