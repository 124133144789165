import { Tab } from '../../common/TabSelector/TabSelector';
import { CONSTANTS } from './constants';
import { defaultColors, GRAPH_COLORS } from './graphColorData';
import { chartInitData } from './transformer';
import { graphDataType, StatCardData } from './types';

interface DashboardNew {
    isLoading: boolean;
    loadingHeadline: boolean;
    config: null | {
        tabsCount: number;
        tabConfigs: any;
        tabs: Tab[];
        selectedTabId: string;
        actionCta?: { secondCTATrigger: string[] };
        additionalDetailsConfig: {
            companyTabs: {
                id: string;
                title: string;
                selectedIcon: string;
                icon: string;
                state: 'LOCKED' | 'UNLOCKED';
            }[];
        };
    };
    headlineData: null | {
        cards: string[];
        data: { [x: string]: StatCardData };
    };
    charts: null | graphDataType[];
    dataSource: {
        [x: number]: { text: string; link: string };
    };
    aicaGradeData: {
        showAicaGradeDrawer: false;
        isLoading: false;
        headers: any[];
        companyScore: number | null;
        companyGrade: string | null;
        metricScoreGenerated: boolean | null;
    };
}

const inititalState: any = {
    isLoading: false,
    loadingHeadline: false,
    config: null,
    headlineData: null,
    dataSource: {},
    aicaGradeData: {
        showAicaGradeDrawer: false,
        isLoading: false,
        headers: [],
        companyScore: null,
        companyGrade: null,
        metricScoreGenerated: false,
    },
    // creditBureau: creditBureauReducer
};

const setIsLoading = (state, action) => {
    return {
        ...state,
        isLoading: action.payload,
    };
};

const setIsLoadingHeadline = (state, action) => {
    return {
        ...state,
        loadingHeadline: action.payload,
    };
};

const setIsLoadingGraph = (state, action) => {
    const graphName = action.graphName;
    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state?.charts?.[graphName],
                isLoading: true,
            },
        },
    };
};

const setIsLoadingAicaGrade = (state, action) => {
    return {
        ...state,
        aicaGradeData: {
            ...state.aicaGradeData,
            isLoading: action.payload,
        },
    };
};

const setShowAicaGradeDrawer = (state, action) => {
    return {
        ...state,
        aicaGradeData: {
            ...state.aicaGradeData,
            showAicaGradeDrawer: action.payload,
        },
    };
};

const resetIsFetched = (state, action) => {
    const graphName = action.payload;

    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state?.charts?.[graphName],
                isFetched: false,
                isLoading: true,
            },
        },
    };
};
const initalizeCharts = (state, action) => {
    let chartsEnum = {};

    action.payload.charts.forEach((chart) => {
        const colors = GRAPH_COLORS?.[chart] || defaultColors;
        chartsEnum[chart] = { ...chartInitData, colors };
    });
    return {
        ...state,
        charts: chartsEnum,
    };
};

const setConfigData = (state, action) => {
    return {
        ...state,
        isLoading: false,
        config: {
            ...state.config,
            ...action.payload,
        },
    };
};

const getHeadlineDataSuccess = (state, action) => {
    return {
        ...state,
        loadingHeadline: false,
        headlineData: {
            ...action?.payload?.headlinePanelData,
        },
        dataSource: {
            ...action?.payload?.dataSource,
        },
    };
};

const getGraphDataSuccess = (state, action) => {
    let charts = state.charts;
    let newChartsObj = {};
    action.payload.graphNames.forEach((graphName) => {
        newChartsObj[graphName] = {
            ...charts[graphName],
            ...action.payload.data[graphName],
            isFetched: true,
            isLoading: false,
            fetchError: false,
        };
    });
    return {
        ...state,
        charts: { ...state.charts, ...newChartsObj },
    };
};

const getGraphDataError = (state, action) => {
    let charts = state.charts;
    let newChartsObj = {};
    action.payload.graphNames.forEach((graphName) => {
        newChartsObj[graphName] = {
            ...charts[graphName],
            isFetched: true,
            isLoading: false,
            fetchError: true,
        };
    });
    return {
        ...state,
        charts: { ...state.charts, ...newChartsObj },
    };
};

const getAicaGradeDataSuccess = (state, action) => {
    return {
        ...state,
        aicaGradeData: {
            isLoading: false,
            headers: action.payload?.headers || [],
            companyScore: action.payload?.companyScore ?? null,
            companyGrade: action.payload?.companyGrade ?? null,
            metricScoreGenerated: action.payload?.metricScoreGenerated ?? null,
        },
    };
};

const reducer = (state: DashboardNew = inititalState, action: any): DashboardNew => {
    switch (action.type) {
        case CONSTANTS.SET_IS_LOADING:
            return setIsLoading(state, action);
        case CONSTANTS.SET_IS_LOADING_HEADLINE:
            return setIsLoadingHeadline(state, action);
        case CONSTANTS.SET_IS_LOADING_GRAPH:
            return setIsLoadingGraph(state, action);
        case CONSTANTS.SET_IS_LOADING_AICA_GRADE_DATA:
            return setIsLoadingAicaGrade(state, action);
        case CONSTANTS.SET_SHOW_AICA_GRADE_DRAWER:
            return setShowAicaGradeDrawer(state, action);
        case CONSTANTS.RESET_IS_FETCHED_GRAPH:
            return resetIsFetched(state, action);
        case CONSTANTS.INITIALIZE_GRAPH_CARDS:
            return initalizeCharts(state, action);
        case CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS:
            return setConfigData(state, action);
        case CONSTANTS.GET_HEADLINE_DATA_SUCCESS:
            return getHeadlineDataSuccess(state, action);
        case CONSTANTS.GET_GRAPH_DATA_SUCCESS:
            return getGraphDataSuccess(state, action);
        case CONSTANTS.GET_GRAPH_DATA_ERROR:
            return getGraphDataError(state, action);
        case CONSTANTS.GET_AICA_GRADE_DATA_SUCCESS:
            return getAicaGradeDataSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
