import React, { useState } from 'react';
import styles from './PersistentConfigToolTip.module.scss';
import { crossBtnGrey } from '../../assets/hostedassets';

interface TooltipProps {
    heading: string | React.ReactNode;
    icon?: React.ReactNode;
    message: string;
    ctaText?: string;
    ctaLink?: string;
    position?: 'top' | 'bottom';
    anchorId: string;
    offset?: number;
    CustomComponent?: React.ReactNode;
    width?: string;
    zIndex?: number;
    arrowOffset?: number;
    hideDelay?: number;
    observeChanges?: boolean;
    className?: string;
    tooltipStyles?: React.CSSProperties;
    onClose?: () => void;
    showArrow?: boolean;
}

const PersistentConfigToolTip: React.FC<TooltipProps> = ({
    heading,
    message,
    icon,
    ctaText,
    ctaLink,
    position = 'top',
    anchorId,
    offset = 0,
    CustomComponent,
    width = '21rem',
    zIndex = 10000,
    arrowOffset = 130,
    hideDelay = 5000,
    observeChanges = true,
    className = '',
    tooltipStyles = {},
    onClose,
    showArrow = true,
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [opacity, setOpacity] = useState(0);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [anchorExists, setAnchorExists] = useState(false);

    const positionTooltip = React.useCallback(() => {
        if (!anchorId) return;

        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
            const rect = anchorElement.getBoundingClientRect();

            const top = position === 'bottom' ? rect.bottom + offset - 8 : rect.top - offset;

            setTooltipPosition({
                top: top,
                left: rect.left + rect.width / 2 + arrowOffset,
            });
        }
    }, [anchorId, position, offset, arrowOffset]);

    React.useEffect(() => {
        const checkAnchorElement = () => {
            const element = document.getElementById(anchorId);
            if (element) {
                setAnchorExists(true);
                return true;
            }
            return false;
        };

        if (!checkAnchorElement()) {
            const observer = new MutationObserver((mutations, obs) => {
                if (checkAnchorElement()) {
                    obs.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });

            return () => observer.disconnect();
        }
    }, [anchorId]);

    React.useEffect(() => {
        if (!anchorId || !anchorExists) return;

        const anchorElement = document.getElementById(anchorId);
        if (!anchorElement) return;

        const observers: Array<MutationObserver | ResizeObserver> = [];

        if (observeChanges) {
            const mutationObserver = new MutationObserver(() => {
                requestAnimationFrame(positionTooltip);
            });

            const resizeObserver = new ResizeObserver(() => {
                requestAnimationFrame(positionTooltip);
            });

            mutationObserver.observe(document.body, {
                attributes: true,
                childList: true,
                subtree: true,
            });

            resizeObserver.observe(anchorElement);

            observers.push(mutationObserver, resizeObserver);
        }

        positionTooltip();

        window.addEventListener('scroll', positionTooltip);
        window.addEventListener('resize', positionTooltip);

        const intervalId = setInterval(positionTooltip, 100);
        const timeoutId = setTimeout(() => clearInterval(intervalId), hideDelay);

        // Trigger fade in after mount
        requestAnimationFrame(() => setOpacity(1));

        return () => {
            observers.forEach((observer) => observer.disconnect());
            window.removeEventListener('scroll', positionTooltip);
            window.removeEventListener('resize', positionTooltip);
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [anchorId, positionTooltip, observeChanges, hideDelay, anchorExists]);

    const handleClose = () => {
        setIsVisible(false);
        onClose?.();
    };

    if (!isVisible || !anchorId || !anchorExists) return null;

    const wrappedCustomComponent =
        CustomComponent && React.isValidElement(CustomComponent)
            ? React.cloneElement(CustomComponent as React.ReactElement, {
                  onClick: (e: React.MouseEvent) => {
                      if ((CustomComponent as React.ReactElement).props.onClick) {
                          (CustomComponent as React.ReactElement).props.onClick(e);
                      }
                      handleClose();
                  },
              })
            : CustomComponent;

    return (
        <div
            className={`${styles.tooltipContainer} ${styles[position]} ${className}`}
            style={{
                position: 'fixed',
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
                transform: 'translateX(-50%)',
                zIndex,
                width,
                opacity,
                ...tooltipStyles,
            }}
        >
            <div className={styles.tooltipContent}>
                <button
                    className={styles.closeButton}
                    onClick={handleClose}
                    aria-label="Close tooltip"
                >
                    <img src={crossBtnGrey} alt={'Close tooltip'} />
                </button>
                <div style={{ display: 'flex', alignItems: 'start', gap: '0.5rem' }}>
                    {icon && <div>{icon}</div>}
                    <div>
                        <h3 className={styles.tooltipHeading}>{heading}</h3>
                        <p className={styles.tooltipMessage}>{message}</p>
                    </div>
                </div>
                {wrappedCustomComponent
                    ? wrappedCustomComponent
                    : ctaText &&
                      ctaLink && (
                          <a
                              href={ctaLink}
                              className={styles.ctaButton}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              {ctaText}
                          </a>
                      )}
            </div>
            {/* Pointy Tail (Triangle) */}
            {showArrow && (
                <svg
                    className={`${styles.tooltipArrow} ${styles[position]}`}
                    width="20"
                    height="10"
                    viewBox="0 0 20 10"
                >
                    {position === 'top' ? (
                        <polygon points="10,0 0,10 20,10" fill="white" />
                    ) : (
                        <polygon points="10,10 0,0 20,0" fill="white" />
                    )}
                </svg>
            )}
        </div>
    );
};

export default PersistentConfigToolTip;
