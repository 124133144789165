import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { USER_TYPE } from '../../../utils/constants/user';
import UploadJourney from '../UploadJourney/UploadJourney';
import ProfileButton from '../../../common/Topbar/ProfileButton/ProfileButton';
import { InviteUserIcon } from '../../../assets/Svg/InviteUserIcon';
import { ManageUserDrawer } from './components/ManageUserDrawer';

import styles from './UploadData.module.scss';
import { FullReturn, useUploadData } from './useUploadData';
import EmailVerificationBanner from '../../../common/EmailVerificationBanner/EmailVerificationBanner';
import { useSearchParams, useLocation } from 'react-router-dom';
import ConfigurablePopup from '../../../common/ConfigurablePopup/ConfigurablePopup';
import MagicUpload from '../../../common/MagicUpload/MagicUpload';
import PendingDataPill from './components/PendingDataPill';

interface ContentBlock {
    type: 'text' | 'image' | 'link' | 'heading' | 'divider';
    value: string;
    label?: string;
}

interface PopupData {
    contentBlocks: ContentBlock[];
}

interface UploadDataProps {
    overrideSelectedStep?: string;
    isInvestor?: boolean;
    userType?: string;
    assesseeOrgId?: string;
    closeDrawer?: () => void;
}

/**
 * The UploadData component focuses on rendering UI and delegating side effects/data fetching
 * to the `useUploadData` custom hook.
 */
export default function UploadData({
    isInvestor = false,
    userType,
    assesseeOrgId = '',
    closeDrawer,
    overrideSelectedStep,
}: UploadDataProps) {
    const {
        userData,
        companyConfig,
        selectedAssesseeOrg,
        fetchUploadData,
        resendEmail,
        openMagicUpload,
        isMagicLink,
        pendingDataCount,
        isLoadingPendingData,
        getGeneratingReportProps,
        handleDataPendingClick,
        showDelayedPopup,
        isCreditBureauUpload,
    } = useUploadData({ isInvestor, userType, assesseeOrgId, mode: 'full' }) as FullReturn;

    return (
        <div style={isInvestor ? {} : { position: 'fixed' }} className={styles.UploadDataWrap}>
            {/* Banner if user's email is not verified */}
            {userData?.emailVerified === false &&
                !isMagicLink &&
                !isInvestor &&
                !isCreditBureauUpload && <EmailVerificationBanner onResend={resendEmail} />}

            <div
                className={clsx(
                    { [styles.NotCompany]: userData?.accountType !== USER_TYPE.COMPANY },
                    styles.UploadData,
                )}
            >
                <div className={styles.Right}>
                    {/* UploadJourney Tabs */}
                    <div
                        className={clsx(styles.UploadContainer, {
                            [styles.UploadContainerInvestor]: isInvestor,
                        })}
                    >
                        <div className={styles.UploadJourneyContainer}>
                            <UploadJourney
                                overrideSelectedStep={overrideSelectedStep}
                                companyConfig={companyConfig}
                                userType={userType}
                                assesseeOrgId={selectedAssesseeOrg}
                                fetchOnboardingData={fetchUploadData}
                                isInvestor={isInvestor}
                                closeDrawer={closeDrawer}
                                isCreditBureauUpload={isCreditBureauUpload}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Manage user drawer */}
            {companyConfig?.popupData?.showPopup && !isMagicLink && showDelayedPopup && (
                <ConfigurablePopup popupData={companyConfig?.popupData} />
            )}
            {companyConfig?.showDataPendingPill && !isMagicLink && (
                <div className={styles.GeneratingReportPill}>
                    <PendingDataPill
                        {...getGeneratingReportProps()}
                        isLoading={isLoadingPendingData}
                        onClick={handleDataPendingClick}
                    />
                </div>
            )}
        </div>
    );
}
