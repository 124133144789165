const namespace = '_COMMENTS_THREAD';

export const CONSTANTS = {
    SET_IS_FETCHING_DATA: `${namespace}_SET_IS_FETCHING_DATA`,
    SET_SHOW_COMMENTS_THREAD: `${namespace}_SET_SHOW_COMMENTS_THREAD`,

    INITIATE_CLOSE_COMMENTS_THREAD: `${namespace}_INITIATE_CLOSE_COMMENTS_THREAD`,
    CLOSE_COMMENTS_THREAD: `${namespace}_CLOSE_COMMENTS_THREAD`,
    SET_COMMENTS_THREAD_DATA: `${namespace}_SET_COMMENTS_THREAD_DATA`,

    ADD_COMMENT_TO_THREAD: `${namespace}_ADD_COMMENT_TO_THREAD`,
    SET_REFETCH_FLAG: `${namespace}_SET_REFETCH_FLAG`,
};
