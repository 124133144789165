import { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../../../../utils/utils';
import { getAlerts } from '../../../../../../../../store/dashboard/AlertEngine/alertEngine_apiHelpers';
import styles from './ChecksTable.module.scss';
import { CriterionRow } from '../../Checks';

type ChecksTableProps = {
    assesseOrgId: string;
    parentOrgId: string;
    rowData: CriterionRow[];
};

const ChecksTable = (props: ChecksTableProps) => {
    const { assesseOrgId, parentOrgId, rowData } = props;

    const getBannerStatusColors = (status: string) => {
        let color = '';
        let banner = '';
        switch (status) {
            case 'MET':
                color = '#00AA00';
                banner = '#E5FCDE';
                break;
            case 'NOT MET':
                color = '#DF666A';
                banner = '#FDF0EC';
                break;
            case 'NO DATA':
                color = '#BCBCBC';
                banner = '#F7F7F7';
                break;
        }
        return {
            color,
            banner,
        };
    };

    const renderRows = () => {
        if (isEmpty(rowData)) {
            return (
                <div className={styles.Row}>
                    <div>No checks available</div>
                </div>
            );
        } else {
            return (
                <>
                    {rowData.map((row, index) => {
                        return (
                            <div key={`checks-table-row-${index + 1}`} className={styles.Row}>
                                <div
                                    style={{
                                        background: getBannerStatusColors(row.criterionResult)
                                            .banner,
                                        color: getBannerStatusColors(row.criterionResult).color,
                                    }}
                                    className={styles.SeverityPill}
                                >
                                    {row.criterionResult}
                                </div>
                                <div className={styles.Description}>{row.description}</div>
                                <div className={styles.CategoryPill}>{row.category}</div>
                                <div className={styles.Date}>{row.alertDate}</div>
                            </div>
                        );
                    })}
                </>
            );
        }
    };

    return (
        <div className={styles.ChecksTableWrapper}>
            <div className={styles.TableWrapper}>
                <div className={styles.Headers}>
                    <div>Status</div>
                    <div>Description</div>
                    <div>Category</div>
                    <div>Date</div>
                </div>
                <div style={{ overflowY: 'auto', maxHeight: '40rem', height: 'fit-content' }}>
                    {renderRows()}
                </div>
            </div>
        </div>
    );
};
export default ChecksTable;
