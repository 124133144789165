import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { logoutUser } from './store/user/userV2/actions';
import { addTokenToHeaders } from './utils/axios-interceptor';
import axios from 'axios';
import { clearLocalStorageOnLogout } from './utils/utils';
import { useAppSelector } from './app/hooks';
import { USER_TYPE } from './utils/constants/user';
import { MULTIPLE_LOGIN_TOAST_ERROR } from './utils/constants/commonConstants';

/**
 * QueryParamsWatcher Component
 *
 * This component monitors query parameters across the application.
 * If any query parameter has a value of 'null' (as a string) or an actual null,
 * it automatically logs out the user, displays an error toast, and redirects
 * them to the login page.
 */

const QueryParamsWatcher = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = localStorage?.getItem?.('user_id');
    const userData = useAppSelector((state) => state?.user?.userData);

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const hasNullQueryParam = () =>
        Array.from(searchParams.values()).some((value) => value === 'null' || value === null);

    useEffect(() => {
        if (!hasNullQueryParam()) return;

        if (userId) addTokenToHeaders(axios, null, null, userId);

        dispatch(
            logoutUser(null, {
                onSuccess: () => {
                    toast.error(MULTIPLE_LOGIN_TOAST_ERROR);
                    navigateToLoginPage(userData?.accountType);
                    clearLocalStorageOnLogout();
                },
            }),
        );
    }, [searchParams, dispatch, navigate]);

    const navigateToLoginPage = (accountType: string) => {
        switch (accountType) {
            case USER_TYPE.COMPANY:
                navigate('/login');
                break;
            default:
                navigate('/investor/login');
                break;
        }
    };

    return null;
};

export default QueryParamsWatcher;
