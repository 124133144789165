import React, { useEffect, useRef, useState } from 'react';
import styles from './FinancialLoaderComponent.module.scss';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';
import clsx from 'clsx';

interface FinancialLoaderComponentProps {
    tableName: string;
    message: string;
    SideButton?: React.ReactNode;
    status: boolean;
}

const FinancialLoaderComponent: React.FC<FinancialLoaderComponentProps> = ({
    tableName,
    message,
    SideButton,
    status,
}) => {
    return (
        <div
            className={clsx(
                {
                    [styles.financialSummary]: tableName === 'Financial Summary',
                },
                styles.OverviewScreenContainer,
                tableName === 'Audited Financials' && styles.Margin,
            )}
        >
            <div className={styles.Header}>{tableName}</div>
            {status && <LoadingSpinner color="var(--primary-text-colour)" />}
            <div className={styles.MessageContainer}>{message}</div>
            <div>{SideButton}</div>
        </div>
    );
};

export default FinancialLoaderComponent;
