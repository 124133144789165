import clsx from 'clsx';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Cell,
    Legend,
    Label,
    LabelList,
} from 'recharts';
import styles from '../Graph.module.scss';
import {
    secondDataPointExists,
    thirdDataPointExists,
    formatPeriod,
} from '../../../../../store/dashboard/transformer';
import { useEffect, useState } from 'react';
import { CHART_NAMES } from '../../../../../store/dashboard/creditBureau/constants';
import { GRAPH_NAMES } from '../../../../../store/dashboard/constants';
/**
 * @Description Bar graph component for 2 values for every x-coordinate
 */

interface BarGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function BarGraph(props: BarGraphProps) {
    const { error, graphName } = props;
    const { colors, hasNegative, plots, metadata } = props.graphData;
    const data = plots[0]?.plotValues || [];

    const [linesExists, setLineExists] = useState({
        secondBarExists: false,
        thirdBarExists: false,
        fourthBarExists: false,
    });

    useEffect(() => {
        if (metadata?.legend) {
            const secondBarExists = data.find((d) => 'yValue2' in d);
            const thirdBarExists = data.find((d) => 'yValue3' in d);
            const fourthBarExists = data.find((d) => 'yValue4' in d);
            setLineExists({
                secondBarExists,
                thirdBarExists,
                fourthBarExists,
            });
        }
    }, [metadata?.legend]);

    const getBarGap = () => {
        if (linesExists.thirdBarExists || linesExists.fourthBarExists) {
            if (data.length === 1) return '46%';
            else if (data.length > 1 && data.length < 4) return '40%';
            else if (data.length > 4 && data.length < 8) return '35%';
            else if (data.length >= 8) return '18%';
            else return '20%';
        } else {
            if (data.length === 1) return '46%';
            else if (data.length > 1 && data.length < 4) return '40%';
            else if (data.length > 4 && data.length < 8) return '35%';
            else if (data.length >= 8) return '32%';
            else return '18%';
        }
    };

    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div className={styles.LegendContainer}>
                {payload.map((entry, index) => (
                    <div key={`item-${index}`} className={styles.SingleLegend}>
                        <div
                            style={{ background: entry?.color }}
                            className={styles.LegendIcon}
                        ></div>
                        {metadata?.legend?.[entry.value]}
                    </div>
                ))}
                {/* <div key={`item-last`} className={styles.SingleLegend}>
                    <div
                        style={{ background: colors?.secondary }}
                        className={styles.LegendIcon}
                    ></div>
                    {metadata?.legend?.yValue2}
                </div> */}
            </div>
        );
    };

    const isSingleDataPoint = () => {
        switch (graphName) {
            case GRAPH_NAMES.REVENUE_GROWTH:
            case GRAPH_NAMES.EXPENSES_CHANGE:
                return true;
            default:
                return !!!linesExists.secondBarExists;
        }
    };

    const showBarLabels = () => {
        switch (graphName) {
            case CHART_NAMES.ACCOUNTS_WITH_DELAYS:
            case CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_SIZE:
            case CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_TYPE:
                return true;
            default:
                return false;
        }
    };

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 30,
                    right: 15,
                    left: -25,
                    bottom: 5,
                }}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                {metadata?.legend ? (
                    //@ts-ignore
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 0 }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                        // {...(!linesExists.secondBarExists && { content: renderLegend })}
                        content={linesExists.secondBarExists ? renderLegend : <></>}
                    />
                ) : null}

                <XAxis
                    dataKey="xValue"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    tickFormatter={(val) => {
                        return formatPeriod(val);
                    }}
                    interval={0}
                />
                <YAxis
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    dx={5}
                    interval={1}
                    tickFormatter={(val) => {
                        if (Math.abs(val) > 1000) {
                            return `${val / 1000}k`;
                        }
                        return val;
                    }}
                    // domain={yDomain}
                    // domain={['auto', 'auto']}
                    // domain={['dataMin', 'dataMax']}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                <Tooltip
                    content={
                        <CustomTooltip
                            graphName={props.graphName}
                            graphData={props.graphData}
                            singleDataPoint={isSingleDataPoint()}
                        />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                <ReferenceLine y={0} stroke="#000000" strokeDasharray={'5 5'} />
                <Bar dataKey={'yValue'} radius={[4, 4, 4, 4]} fill={colors?.primary}>
                    {data.map((entry, i) => (
                        <Cell
                            key={i}
                            fill={
                                ['expensesChange', 'revenueGrowth'].includes(graphName)
                                    ? entry.yValue > 0
                                        ? colors?.primary
                                        : colors?.secondary
                                    : colors?.primary
                            }
                        />
                    ))}
                    {showBarLabels() && (
                        <LabelList position={'top'} fontSize={'12px'} fill={'black'} />
                    )}
                </Bar>

                {linesExists.secondBarExists && (
                    <Bar dataKey={'yValue2'} fill={colors?.secondary} radius={[4, 4, 4, 4]}>
                        {showBarLabels() && (
                            <LabelList position={'top'} fontSize={'12px'} fill={'black'} />
                        )}
                    </Bar>
                )}

                {linesExists.thirdBarExists && (
                    <Bar dataKey={'yValue3'} fill={colors?.tertiary} radius={[4, 4, 4, 4]}>
                        {showBarLabels() && (
                            <LabelList position={'top'} fontSize={'12px'} fill={'black'} />
                        )}
                    </Bar>
                )}
                {linesExists.fourthBarExists && (
                    <Bar dataKey={'yValue4'} fill={colors?.fourth} radius={[4, 4, 4, 4]}>
                        {showBarLabels() && (
                            <LabelList position={'top'} fontSize={'12px'} fill={'black'} />
                        )}
                    </Bar>
                )}
            </BarChart>
        </ResponsiveContainer>
    );
}

export default BarGraph;
