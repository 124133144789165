import React from 'react';
import './ToggleButton.scss';

export default function ToggleButton({
    checked,
    onChange,
    disabled = false,
    variant = 'default',
}: {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
    variant?: 'default' | 'success';
}) {
    return (
        <label className={`switch ${variant}`}>
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            <span className="slider"></span>
        </label>
    );
}
