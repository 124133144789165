import { Handlers } from '../../types/actions/types';
import { CONSTANTS } from './constants';

export const updateAssesseeOrgId = (payload) => {
    return {
        type: CONSTANTS.UPDATE_ASSESSEE_ORG_ID,
        payload,
    };
};

export const fetchDomainMetadata = (payload: DomainMetadataReqPayload) => {
    return {
        type: CONSTANTS.FETCH_DOMAIN_METADATA,
        payload,
    };
};

export const createAccount = (payload: CreateAccountReqPayload, handlers) => {
    return {
        type: CONSTANTS.CREATE_ACCOUNT,
        payload,
        handlers,
    };
};

export const saveApplicationDetails = (payload) => {
    return {
        type: CONSTANTS.SAVE_APPLICATION_DETAILS,
        payload,
    };
};
export const login = (payload: LoginWithPasswordPayload) => {
    return {
        type: CONSTANTS.LOGIN_WITH_PASSWORD,
        payload,
    };
};

export const generateOtp = (payload: GenerateOtpReqPayload) => {
    return {
        type: CONSTANTS.GENERATE_OTP,
        payload,
    };
};

export const verifyOtp = (payload: VerifyOtpPayload) => {
    return {
        type: CONSTANTS.VERIFY_OTP,
        payload,
    };
};

export const loginWithOtp = (payload: VerifyOtpPayload) => {
    return {
        type: CONSTANTS.LOGIN_WITH_OTP,
        payload,
    };
};

export const forgotPassword = (payload: ForgotPasswordPayload) => {
    return {
        type: CONSTANTS.FORGOT_PASSWORD,
        payload,
    };
};

export const setUserData = (payload: any) => {
    return {
        type: CONSTANTS.SET_USER_DATA,
        payload,
    };
};

export const resetUserData = () => {
    return {
        type: CONSTANTS.RESET_USER_DATA,
    };
};

export const updatePassword = (payload: UpdatePasswordPayload) => {
    return {
        type: CONSTANTS.UPDATE_PASSWORD,
        payload,
    };
};

export const fetchSummonAicaData = (payload: SummonAicaDataPayload, handlers) => {
    return {
        type: CONSTANTS.FETCH_SUMMON_AICA_DATA,
        payload,
        handlers,
    };
};

export const updateUserFromSummonAica = (payload: UpdateUserSummonAica) => {
    return {
        type: CONSTANTS.UPDATE_USER_FROM_SUMMON_AICA,
        payload,
    };
};

export const fetchInternalSummonAicaData = (
    payload: InternalSummonAicaDataPayload,
    handlers: Handlers,
) => {
    return {
        type: CONSTANTS.FETCH_INTERNAL_SUMMON_AICA_DATA,
        payload,
        handlers,
    };
};

export const setIp = (payload) => {
    return {
        type: CONSTANTS.SET_IP,
        payload,
    };
};

export type DomainMetadataReqPayload = {
    subdomainName: string;
    domainName: string;
};

export type CreateAccountReqPayload = {
    primaryUserName: string;
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    pan: string;
    password: string;
    websiteUrl?: string;
    sector?: string;
    phoneNumber: string;
    countryId: string;
    countryCode: string;
    journeyName: string;
    verifyToken?: string;
    signupToken?: string;
    // onSuccess: Function;
};

export type SaveApplicationDetailsPayload = {
    orgAdditionalDetails: {
        accountType: string;
        investorOrgId: string;
        orgId: string;
        companyName: string;
        recentMonthsRevenue: number;
        capitalExpectationAmount: number;
        capitalExpectationTimePeriod: string;
        companyAge: string;
        companyOfficePincode: string;
        companyType: { id: string; label: string };
    };
};

export type GenerateOtpReqPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    verifyToken?: string;
    signupToken?: string;
    onComplete?: Function;
    onSuccess: Function;
};

export type VerifyOtpPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    otp: string;
    // onSuccess: Function;
};

export type LoginWithPasswordPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    password: string;
    onComplete?: Function;
};

export type ForgotPasswordPayload = {
    primaryEmail: string;
    investorOrgId: string;
    accountType: string;
    verifyToken?: string;
    onSuccess: Function;
    onError: Function;
};

export type UpdatePasswordPayload = {
    email: string;
    passwordResetToken: string;
    password: string;
    onSuccess: Function;
    onError: Function;
};

export type SummonAicaDataPayload = {
    investorOrgId: string;
    assesseeOrgId: string;
};

export type InternalSummonAicaDataPayload = {
    parentOrgId: string;
    parentEmailId: string;
    userId?: string;
    userType: InternalSummonUserType;
    orgId?: string;
    requesterUserId: string;
    isMagicLink?: boolean;
};

export enum InternalSummonUserType {
    ASSESSEE = 'ASSESSEE',
    LENDER = 'LENDER',
    PARTNER = 'PARTNER',
    ADMIN_TEAM = 'ADMIN_TEAM',
}

export type UpdateUserSummonAica = {
    primaryEmail: string;
    userName: string;
    investorOrgId: string;
};
