import {
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    ComposedChart,
    Legend,
    ReferenceLine,
    Label,
} from 'recharts';
import clsx from 'clsx';
import styles from '../Graph.module.scss';

import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';

/**
 * @Description Line graph component for single value for every x-coordinate
 */

interface LineBarGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function LineBarGraph(props: LineBarGraphProps) {
    const { error } = props;
    const { colors, amount, hasNegative, plots, metadata } = props.graphData;
    const graphData = plots[0]?.plotValues || [];
    const data = !error ? amount : [];

    const getBarGap = () => {
        if (graphData.length === 1) return '47%';
        else if (graphData.length > 1 && graphData.length < 8) return '40%';
        else if (graphData.length >= 8) return '30%';
        else return '20%';
    };

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <ComposedChart
                data={graphData}
                margin={{
                    top: 30,
                    right: -15,
                    left: -20,
                    bottom: 5,
                }}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="period"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    // tickFormatter={(val) => formatPeriod(val)}
                    interval={0}
                />
                <YAxis
                    yAxisId="left"
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    dx={5}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    // tickFormatter={getYAxisLabel}
                >
                    {!!metadata?.yTitle2 && (
                        <Label
                            value={metadata?.yTitle2}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '10px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 20 }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                    />
                ) : null}
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {hasNegative ? <ReferenceLine y={0} yAxisId="left" stroke="#000000" /> : null}
                <Legend
                    verticalAlign="top"
                    height={30}
                    align="right"
                    wrapperStyle={{
                        fontSize: isMobile ? '10px' : '14px',
                        width: '100%',
                        top: 20,
                        left: -10,
                    }}
                    iconType="circle"
                />

                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={'yValue'}
                    stroke={colors?.primary}
                    dot={{ fill: colors?.primary }}
                    activeDot={{ fill: 'white', stroke: 'black' }}
                    strokeWidth={2}
                />
                <Bar
                    yAxisId="left"
                    dataKey={'yValue2'}
                    fill={colors?.secondary}
                    radius={[4, 4, 4, 4]}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default LineBarGraph;
