import { Editor, Transforms, Element as SlateElement } from 'slate';
import { useSlateStatic } from 'slate-react';
import Link from '../Elements/Link/Link';
import Image from '../Elements/Embed/Image';
import Table from '../Elements/Table/Table';
import TableWithoutHeader from '../Elements/Table/TableWithoutHeaders';
import editorStyles from '../TextEditor.module.scss';
import tableStyles from '../Elements/Table/TableSelector.module.scss';

const alignment = ['left', 'right', 'center'];
const list_types = ['orderedList', 'unorderedList'];

export const sizeMap = {
    small: '0.75em',
    normal: '1em',
    medium: '1.75em',
    huge: '2.5em',
};
export const fontFamilyMap = {
    sans: 'Helvetica,Arial, sans serif',
    serif: 'Georgia, Times New Roaman,serif',
    monospace: 'Monaco, Courier New,monospace',
};
export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = list_types.includes(format);
    const isAlignment = alignment.includes(format);

    if (isAlignment) {
        // Handle text alignment
        Transforms.setNodes(
            editor,
            { textAlign: format },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) },
        );
    } else if (isList) {
        // Handle list formatting
        Transforms.unwrapNodes(editor, {
            match: (n) =>
                list_types.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
            split: true,
        });

        Transforms.setNodes(
            editor,
            { type: isActive ? 'paragraph' : 'list-item' },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) },
        );

        if (!isActive) {
            Transforms.wrapNodes(editor, {
                type: format,
                children: [],
            });
        }
    } else {
        // Handle block type changes
        Transforms.setNodes(
            editor,
            { type: isActive ? 'paragraph' : format },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) },
        );
    }
};

export const addMarkData = (editor, data) => {
    Editor.addMark(editor, data.format, data.value);
};
export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};
export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);

    return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
};

export const activeMark = (editor, format) => {
    const defaultMarkData = {
        color: 'black',
        bgColor: 'black',
        fontSize: '12px',
        fontFamily: 'sans',
        lineHeight: '130%',
    };
    const marks = Editor.marks(editor);
    const defaultValue = defaultMarkData[format];
    return marks?.[format] !== '#ffffff' ? marks?.[format] : defaultValue;
};

export const getMarked = (leaf, children) => {
    let result;
    if (leaf.bold) {
        result = <strong style={{ fontFamily: 'Gilroy-SemiBold,sans-serif' }}>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.strikethrough) {
        children = <span style={{ textDecoration: 'line-through' }}>{children}</span>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    if (leaf.superscript) {
        children = <sup>{children}</sup>;
    }
    if (leaf.subscript) {
        children = <sub>{children}</sub>;
    }
    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>;
    }
    if (leaf.bgColor) {
        children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>;
    }
    if (leaf.fontSize) {
        // const size = sizeMap[leaf.fontSize];
        children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>;
    }
    if (leaf.fontFamily) {
        const family = fontFamilyMap[leaf.fontFamily];
        children = <span style={{ fontFamily: family }}>{children}</span>;
    }
    return result || children;
};

export const getBlock = (props) => {
    const { element, children } = props;
    const attributes = props.attributes ?? {};
    const style = { textAlign: element.textAlign };

    switch (element.type) {
        case 'headingOne':
            return (
                <h1 style={style} {...attributes} {...element.attr}>
                    {children}
                </h1>
            );
        case 'headingTwo':
            return (
                <h2 style={style} {...attributes} {...element.attr}>
                    {children}
                </h2>
            );
        case 'headingThree':
            return (
                <h3 style={style} {...attributes} {...element.attr}>
                    {children}
                </h3>
            );
        case 'blockquote':
            return (
                <blockquote style={style} {...attributes} {...element.attr}>
                    {children}
                </blockquote>
            );
        case 'list-item':
            return (
                <>
                    <div className="page-break"></div>
                    <li style={style} {...attributes} {...element.attr} className="dontbreak">
                        {children}
                    </li>
                </>
            );
        case 'orderedList':
            return (
                <ol type="1" style={style} {...attributes} className="dontbreak">
                    {children}
                </ol>
            );
        case 'unorderedList':
            return (
                <ul style={style} className={editorStyles.UnorderedList} {...attributes}>
                    {children}
                </ul>
            );
        case 'link':
            return <Link {...props} />;
        case 'table':
            return <Table {...props} />;
        case 'tableWithoutHeaders':
            return <TableWithoutHeader {...props} />;
        case 'table-row':
            return (
                <>
                    <tr style={style} {...attributes} className={tableStyles.TableRow}>
                        {children}
                    </tr>
                </>
            );
        case 'table-cell':
            return (
                <td
                    style={style}
                    {...element.attr}
                    {...attributes}
                    className={tableStyles.TableCell}
                >
                    {children}
                </td>
            );
        case 'image':
            return <Image {...props} />;
        case 'htmlCode':
            return <HtmlCode {...props} />;
        default:
            return (
                <div style={{ ...(style || {}) }} {...element.attr} {...attributes}>
                    {children}
                </div>
            );
    }
};
