import { useEffect, useRef, useState } from 'react';
import { getRiskIndicatorsData } from '../../store/investor/apiHelpers';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { downloadFile } from '../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import {
    AICA_GET_TASK_TRACKER_DATA,
    AION_INGEST_AML_PEP_DATA,
    RISK_DOT_COLOR_MAP,
    RISK_INDICATOR_STATUS,
    RISK_TYPES,
    TYPES,
    UW_BEFE,
    MAX_POLLING_ATTEMPTS,
} from './RiskIndicatorConstants';
import { companyBlack, userBlack } from '../../assets/hostedassets';
import styles from './RiskIndicators.module.scss';
import { useAppSelector } from '../../app/hooks';
import { TabModule } from '../../utils/constants/commonConstants';

export const useRiskIndicatorStates = ({ rows, setRows, autoFetch }) => {
    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);

    const [loading, setLoading] = useState<boolean>(true);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [lastUpdatedAt, setLastUpdatedAt] = useState<string | null>(null);
    const [pollingAttempts, setPollingAttempts] = useState<number>(0);
    const params = useParams();
    const pollingRef = useRef<NodeJS.Timeout | null>(null);
    const pollingArray = [RISK_INDICATOR_STATUS.PROCESSING, RISK_INDICATOR_STATUS.TODO, RISK_INDICATOR_STATUS.TODO_ASYNC];

    useEffect(() => {
        if (params?.id && autoFetch) {
            fetchRiskIndicators();
        }

        return () => {
            stopPolling();
        };
    }, [params.id, autoFetch]);

    useEffect(() => {
        if (modulePollingList.includes(TabModule.LEGAL_CHECKS)) {
            fetchRiskIndicators();
        }
    }, [modulePollingList]);

    const startPolling = () => {
        if (!pollingRef.current) {
            pollingRef.current = setInterval(() => {
                setPollingAttempts((prevAttempts) => {
                    const newAttempts = prevAttempts + 1;

                    if (newAttempts < MAX_POLLING_ATTEMPTS) {
                        fetchRiskIndicators();
                        return newAttempts;
                    } else {
                        stopPolling();
                        return newAttempts;
                    }
                });
            }, 10000);
        }
    };

    const stopPolling = () => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
            pollingRef.current = null;
        }
        setPollingAttempts(0);
        setLoading(false);
    };

    const fetchRiskIndicators = async () => {
        const payload = {
            orgId: params?.id ?? '',
        };
        setLoading(true);
        let response = await getRiskIndicatorsData(payload);

        if (response) {
            handleTaskTrackerData(response?.responseBody?.[AICA_GET_TASK_TRACKER_DATA]);
            processUwBefeData(response?.responseBody?.[UW_BEFE]);
        }
        if (!pollingRef.current) {
            setLoading(false);
        }
    };

    const handleTaskTrackerData = (taskTrackerData) => {
        if (taskTrackerData) {
            const pollingStatus = taskTrackerData?.taskTrackerData?.[AION_INGEST_AML_PEP_DATA];
            if (!pollingStatus || pollingArray.includes(pollingStatus)) {
                startPolling();
            } else {
                stopPolling();
            }
        }
    };

    const processUwBefeData = (uwBefeData) => {
        if (uwBefeData && Object.keys(uwBefeData).length > 0) {
            const formattedLastUpdatedAt = uwBefeData.lastUpdatedAt ? dayjs(uwBefeData.lastUpdatedAt).format('DD MMM YYYY') : null;

            setRows(uwBefeData?.data || []);
            setLastUpdatedAt(formattedLastUpdatedAt || null);
        } else {
            setRows([]);
            setLastUpdatedAt(null);
        }
    };

    const getStatusMessage = () => {
        if (loading) {
            return 'Processing (may take ~5 mins)';
        } else if (rows.length === 0) {
            return 'No Data Available';
        } else if (lastUpdatedAt) {
            return `Last Updated on ${lastUpdatedAt}`;
        }
        return '';
    };

    const downloadReport = async (docId, fileName: string) => {
        if (docId) {
            try {
                await downloadFile(Number(docId), () => {}, fileName);
            } catch (error) {
                console.error('Error downloading file', error);
            }
        }
    };

    const getRowImage = (type) => {
        switch (type) {
            case TYPES.ORGANIZATION:
                return <img src={companyBlack} alt="company" />;
            case TYPES.INDIVIDUAL:
                return <img src={userBlack} alt="user" />;
            default:
                return null;
        }
    };

    const RiskDotMessage = () => {
        return (
            <div className={styles.RiskDotMessage}>
                {RISK_TYPES.map((risk, index) => (
                    <div key={index} className={styles.RiskIndicators}>
                        <span className={styles.RiskDot} style={{ backgroundColor: risk.color }}></span>
                        <span>{risk.label}</span>
                    </div>
                ))}
            </div>
        );
    };

    const getRiskDot = (risk) => {
        let color;
        switch (true) {
            case risk === 0:
                color = RISK_DOT_COLOR_MAP.GREEN;
                break;
            case risk > 0 && risk < 100:
                color = RISK_DOT_COLOR_MAP.YELLOW;
                break;
            case risk === 100:
                color = RISK_DOT_COLOR_MAP.RED;
                break;
            default:
                color = RISK_DOT_COLOR_MAP.NOT_AVAILABLE;
        }
        return (
            <span
                style={{
                    backgroundColor: color,
                }}
                className={styles.RiskDot}
            ></span>
        );
    };

    return {
        loading,
        downloadReport,
        hoveredIndex,
        setHoveredIndex,
        lastUpdatedAt,
        getStatusMessage,
        getRowImage,
        RiskDotMessage,
        getRiskDot,
    };
};
