import clsx from 'clsx';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { PieChart, Pie, Tooltip, Cell, Label, ResponsiveContainer, Legend } from 'recharts';
import { LabelProps } from 'recharts';
import styles from '../Graph.module.scss';
import { getColorKeyFromIndex } from '../../../../../store/dashboard/transformer';

/**
 * @Description Normal Pie graph component
 */

interface CustomLabelProps extends LabelProps {
    totalProducts: string;
}
interface PieGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function CustomLabel({ viewBox, totalProducts }: CustomLabelProps) {
    //@ts-ignore
    const { cx, cy } = viewBox;
    return (
        <text
            x={cx}
            y={cy + 12}
            className="recharts-text recharts-label"
            textAnchor="middle"
            dominantBaseline="central"
        >
            <tspan x={cx} dy="-1.2em">
                Total Products
            </tspan>
            <tspan x={cx} dy="1.2em" fontWeight={'bold'}>
                {totalProducts}
            </tspan>
        </text>
    );
}
function PieGraph(props: PieGraphProps) {
    const { error } = props;
    const { plots, metadata, colors } = props.graphData;
    const data = plots[0]?.plotValues || [];
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <ResponsiveContainer width="60%" height={props.height}>
                <PieChart
                    data={data}
                    margin={{
                        top: 40,
                        right: 5,
                        left: -35,
                        bottom: 0,
                    }}
                    className={clsx({ [styles.DisabledGraphSvg]: error })}
                >
                    <Pie
                        data={data}
                        innerRadius={70}
                        outerRadius={80}
                        cornerRadius={8}
                        fill="#8884d8"
                        paddingAngle={2}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors?.[getColorKeyFromIndex(index)]}
                            />
                        ))}
                        <Label
                            width={30}
                            position="center"
                            content={
                                <CustomLabel totalProducts={metadata?.totalNoOfProducts ?? '-'} />
                            }
                        />
                    </Pie>
                    <Tooltip
                        content={
                            <CustomTooltip
                                graphName={props.graphName}
                                graphData={props.graphData}
                            />
                        }
                        wrapperStyle={{ outline: 'none' }}
                        cursor={{ fill: 'transparent' }}
                    />
                </PieChart>
            </ResponsiveContainer>
            <div style={{ marginTop: '50px', marginLeft: '-100px', width: '50%' }}>
                {Object.keys(metadata?.legend || {}).map((legendKey, index) => {
                    const truncatedName =
                        legendKey?.length > 25 ? `${legendKey?.slice(0, 25)}...` : legendKey;
                    return (
                        <div
                            key={`${legendKey}-${index}}`}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '6fr 1fr',
                                columnGap: '5px',
                                width: '100%',
                                marginBottom: '10px',
                            }}
                            title={legendKey}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: colors?.[getColorKeyFromIndex(index)],
                                    }}
                                ></div>
                                <div style={{ marginLeft: '10px' }}>{truncatedName}</div>
                            </div>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                {metadata?.legend?.[legendKey]}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default PieGraph;
