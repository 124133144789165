import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import OnePagerGenerate from '../OnePagerGenerateButton/OnePagerGenerate';
import InvestmentReport from '../InvestmentReport/InvestmentReport';
import { Button } from '../../_custom';
import { FILE_SHARE_STATUS, UPLOAD_FILES_PREFIXES } from '../DealDrawerConstants';
import { useParams } from 'react-router-dom';

function DealDrawerButtonComponents({ componentKey, formData, categoryId, handleChange, source }) {
    const { INVESTMENTREPORT__PREFIX, ONEPAGER_PREFIX } = UPLOAD_FILES_PREFIXES;
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const dealApplicationState = useAppSelector((state) => state.investor.dealApplicationDrawer);
    const documentsListKey = 'dealTerms.documents';
    const params = useParams();
    const handleDocWithPrefixUpdate = (fileInfo, documentPrefix) => {
        let updateFileObj = {
            docId: fileInfo?.docId,
            documentName: `${documentPrefix}${fileInfo?.fileName}`,
            action: FILE_SHARE_STATUS.SEND_TO_INVESTOR,
        };
        let docsToUpdate = [...(formData?.[documentsListKey] || [])];
        let existingfileIndex = docsToUpdate.findIndex((doc) => doc.documentName?.includes(documentPrefix));
        if (existingfileIndex >= 0) docsToUpdate[existingfileIndex] = updateFileObj;
        else docsToUpdate.push(updateFileObj);
        handleChange(documentsListKey, docsToUpdate);
    };

    const handleShareToggle = (actionStatus, documentPrefix) => {
        handleChange(
            documentsListKey,
            (formData[documentsListKey] || [])?.map((document) => ({
                ...document,
                ...(document?.documentName?.includes(documentPrefix) ? { action: actionStatus } : {}),
            })),
        );
    };
    const returnExistingData = () => {
        return (
            formData?.documents?.findLast((document) => document?.documentName?.includes(ONEPAGER_PREFIX)) ||
            formData[documentsListKey]?.findLast((document) => document?.documentName?.includes(ONEPAGER_PREFIX))
        );
    };
    const getRenderComponent = () => {
        switch (componentKey) {
            case 'generateOnePager':
                return (
                    <OnePagerGenerate
                        formData={formData}
                        source={source}
                        categoryId={categoryId}
                        flowId={16}
                        metadataUpdate={(fileInfo) => handleDocWithPrefixUpdate(fileInfo, ONEPAGER_PREFIX)}
                        onShareToggle={(action) => handleShareToggle(action, ONEPAGER_PREFIX)}
                        existingData={returnExistingData()}
                        assesseeOrgId={dealDrawerState?.companyId ?? dealApplicationState.dealBeneficiaryId ?? (params.id as string)}
                        isLocked={
                            dealDrawerState?.profileGenerationStatus === 'LOCKED' ||
                            dealApplicationState?.profileGenerationStatus === 'LOCKED'
                        }
                    />
                );
            case 'investmentReport':
                return (
                    <InvestmentReport
                        source={source}
                        assesseeOrgId={dealDrawerState?.companyId as string}
                        companyName={dealDrawerState.companyName as string}
                        isLocked={
                            dealDrawerState?.profileGenerationStatus === 'LOCKED' ||
                            dealApplicationState?.profileGenerationStatus === 'LOCKED'
                        }
                        metadataUpdate={(fileInfo) => handleDocWithPrefixUpdate(fileInfo, INVESTMENTREPORT__PREFIX)}
                        onShareToggle={(action) => handleShareToggle(action, INVESTMENTREPORT__PREFIX)}
                        existingData={formData[documentsListKey]?.findLast(
                            (document) => document?.documentName?.includes(INVESTMENTREPORT__PREFIX),
                        )}
                    />
                );
            default:
                return <Button text="Not Available" onClick={() => {}} style={{ marginTop: '0', width: '50%' }} disabled />;
        }
    };

    return <React.Fragment>{getRenderComponent()}</React.Fragment>;
}

export default DealDrawerButtonComponents;
