import { useGSTContext } from '../../Context/GstContext';
import styles from './GstErrorScreens.module.scss';
import WhiteRedirectSVG from '../../../../../../../../assets/Svg/WhiteRedirectSVG';
import { infoIconYellow } from '../../../../../../../../assets/hostedassets';
import Button from '../../../../../../../../common/_custom/Button/Button';

const AccessDenied = (
    <div>
        <div className={styles.YelloBanner}>
            <img src={infoIconYellow} height={10} width={10} alt={'icon'} />
            <p>
                <span>Access Denied:</span>
                Your GST account has not enabled access to API based fetch of reports. Please click
                here to enable API access
            </p>
        </div>
    </div>
);

const FacingIssue = (
    <div className={styles.YelloBanner}>
        <img src={infoIconYellow} height={10} width={10} alt={'icon'} />
        <p>
            <b>Access Denied:</b>
            Your GST account has not enabled access to API based fetch of reports. Please click here
            to know how to enable API access
        </p>
    </div>
);

const GstErrorScreens = (props) => {
    const { gstData, handleProceed, setStep, handleEnableViaManually, handleCloseModal } =
        useGSTContext();
    const { isAccessDenied } = props;
    const FacingIssueActions = (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Button
                style={{ marginTop: 0 }}
                text={'Close'}
                onClick={() => handleCloseModal()}
                variant={'secondary'}
            />
            <Button
                iconStyles={{ margin: '0' }}
                style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: '4px' }}
                icon={<WhiteRedirectSVG sx={{ margin: '0', height: '16px', width: '16px' }} />}
                text={'Enable access manually'}
                onClick={() => {
                    handleEnableViaManually();
                }}
                variant={'primary'}
            />
        </div>
    );

    return (
        <>
            <div className={styles.GstContentContainer}>
                <div className={styles.GstContainer}>
                    <p className={styles.Label}>{gstData?.state} </p>
                    <p className={styles.SubLabel}>
                        {gstData?.gstin_number ? `(${gstData?.gstin_number})` : ''}
                    </p>
                </div>
                {isAccessDenied ? AccessDenied : FacingIssue}
            </div>

            <div className={styles.Footer}>{FacingIssueActions}</div>
        </>
    );
};

export default GstErrorScreens;
