import { useGSTContext } from '../../Context/GstContext';
import LoadingSpinner from '../../../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import styles from '../GstErrorScreens/GstErrorScreens.module.scss';

const GstLoader = () => {
    const { gstData } = useGSTContext();
    return (
        <div className={styles.GstContentContainer}>
            <div className={styles.GstContainer}>
                <p className={styles.Label}>{gstData?.state} </p>
                <p className={styles.SubLabel}>
                    {gstData?.gstin_number ? `(${gstData?.gstin_number})` : ''}
                </p>
            </div>
            <div>
                <LoadingSpinner color={'var(--primary-text-colour)'} height={'3rem'} />
                <p style={{ margin: '1rem 0', textAlign: 'center', fontSize: '0.75rem' }}>
                    {' '}
                    Fetching GST data
                </p>
            </div>
        </div>
    );
};

export default GstLoader;
