import styles from './FilteredPills.module.scss';
import CrossIcon from '../../../../assets/Svg/CrossIcon';
import { useSearchParams } from 'react-router-dom';

type FilteredPillsProps = {
    filteredPills: {
        [key: string]: {
            value: string;
            label: string;
        }[];
    };
    setFilteredPills: React.Dispatch<
        React.SetStateAction<{
            assesseeOrgIdList: { value: string; label: string }[];
            userIds: { value: string; label: string }[];
            sources: { value: string; label: string }[];
        }>
    >;
};

const FilteredPills = (props: FilteredPillsProps) => {
    const { filteredPills, setFilteredPills } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const removePill = (pill: { value: string; label: string }) => {
        const newPills: any = { ...filteredPills };
        const key = Object.keys(filteredPills).find((k) =>
            filteredPills[k].some((p) => p.value === pill.value),
        ) as string;
        newPills[key] = newPills[key].filter((p) => p.value !== pill.value);
        // Check if filteredPills is empty
        if (newPills[key].length === 0) {
            searchParams.set('filters', 'removed');
            searchParams.delete('showUntaggedOrg');
            setSearchParams(searchParams);
        }
        setFilteredPills(newPills);
    };

    const clearAll = () => {
        searchParams.set('filters', 'removed');
        searchParams.delete('showUntaggedOrg');
        setSearchParams(searchParams);
        setFilteredPills({
            assesseeOrgIdList: [],
            userIds: [],
            sources: [],
        });
    };
    const checkIFPillsEmpty = () => {
        for (const key in filteredPills) {
            if (filteredPills[key].length > 0) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <div
                style={checkIFPillsEmpty() ? { height: 0, padding: 0 } : { height: 'auto' }}
                className={styles.FilteredPillsWrapper}
            >
                {Object.keys(filteredPills).map((pill) =>
                    filteredPills[pill].map((p) => (
                        <div key={p.value} className={styles.Pill}>
                            <span>{p.label}</span>
                            <span className={styles.CrossIcon} onClick={() => removePill(p)}>
                                <CrossIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="10"
                                    width="10"
                                />
                            </span>
                        </div>
                    )),
                )}
                {/*Create a clear all button*/}
                <div
                    style={checkIFPillsEmpty() ? { height: 0 } : { height: 'auto' }}
                    className={styles.ClearAllPill}
                    onClick={() => clearAll()}
                >
                    <span>Clear All</span>
                </div>
            </div>
        </>
    );
};

export default FilteredPills;
