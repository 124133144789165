import axios from 'axios';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { USER_TYPE } from '../utils/constants/user';
import { Mixpanel } from '../utils/mixpanel';
import { hotjarIdentify } from '../utils/hotjar';
import { addTokenToHeaders } from '../utils/axios-interceptor';
import { setUserData } from '../store/user/actions';
import { fetchConfigAfterLogin } from '../store/user/userV2/actions';
import { handleRedirection } from '../store/user/userV2/authSaga';
import MagicUpload from '../common/MagicUpload/MagicUpload';
import Topbar from '../common/Topbar/Topbar';
import { ManageUserDrawer } from '../modules/Company/UploadData/components/ManageUserDrawer';
import { INVALID_SESSION_ERROR_MESSAGE } from '../utils/constants/commonConstants';

const PORTFOLIO_PATHS = {
    DATA_ROOM: 'data-room',
    DEBT_PRODUCTS: 'debt-products',
    COMPANY_DEALS: 'company-deals',
} as const;

function CompanyPrivateRoutes() {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const manageUserDrawerState = useAppSelector((state) => state.company.showManageUser);

    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user);
    const userData = user.userData;
    const ip = user.ip;

    const [searchParams, setSearchParams] = useSearchParams();
    const orgId = searchParams.get('orgId') as string;
    const userId = searchParams.get('userId') as string;
    const token = searchParams.get('token') as string;
    const accountType = searchParams.get('accountType') as string;
    const parentOrgId = searchParams.get('parentOrgId') as string;
    const primaryEmail = searchParams.get('primaryEmail') as string;
    const userName = searchParams.get('userName') as string;
    const parentEmailId = searchParams.get('parentEmailId') as string;
    const userRole = searchParams.get('userRole') as string;

    const isSummon = !!(orgId && userId && token);

    const isPortfolioPage =
        location.pathname.includes(PORTFOLIO_PATHS.DATA_ROOM) ||
        location.pathname.includes(PORTFOLIO_PATHS.DEBT_PRODUCTS) ||
        location.pathname.includes(PORTFOLIO_PATHS.COMPANY_DEALS);

    useEffect(() => {
        const userData = localStorage.getItem('user_data') ?? sessionStorage.getItem('user_data');
        if (isSummon) {
            summonProcess();
        } else if (!userData) {
            toast.error(INVALID_SESSION_ERROR_MESSAGE);
            setTimeout(() => {
                navigate('/login');
            }, 1000);
        }
    }, [searchParams.get('orgId')]);

    const summonProcess = () => {
        // Set values in session storage
        sessionStorage.setItem('auth_token', token);
        sessionStorage.setItem('org_id', orgId);
        sessionStorage.setItem('user_id', userId);

        // Set values in headers
        addTokenToHeaders(axios, orgId, token, userId);

        // Remove query params
        searchParams.delete('orgId');
        searchParams.delete('userId');
        searchParams.delete('token');
        searchParams.delete('accountType');
        searchParams.delete('parentOrgId');
        searchParams.delete('primaryEmail');
        searchParams.delete('userName');
        searchParams.delete('parentEmailId');
        searchParams.delete('userRole');
        setSearchParams(searchParams);
        let isMagicLink = searchParams.get('configName') === 'MAGIC_LINK';
        dispatch(
            fetchConfigAfterLogin(
                { accountType, primaryEmail, investorOrgId: parentOrgId, userRole: userRole },
                {
                    onSuccess: (data) => {
                        const updatedUserData = {
                            accountType: accountType,
                            investorOrgId: data.investorOrgId,
                            assesseeOrgId: orgId,
                            primaryEmail: primaryEmail,
                            userId: userId,
                            userName: userName,
                            investorPrimaryEmail: data.investorPrimaryEmail,
                            applicationStatus: data.status,
                            lenderOrgId: data.lenderOrgId,
                        };
                        dispatch(setUserData(updatedUserData));
                        sessionStorage.setItem('user_data', JSON.stringify(updatedUserData));
                        handleRedirection(accountType, data.status, false, orgId, false, '', isMagicLink);
                    },
                },
            ),
        );
    };

    useEffect(() => {
        if (userData?.userId && userData?.assesseeOrgId) {
            hotjarIdentify(userData.userId, userData.assesseeOrgId);
        }
    }, [userData?.userId, userData?.assesseeOrgId]);

    useEffect(() => {
        const userType = userData?.accountType;
        if (userData && ip && userType === USER_TYPE.COMPANY) {
            Mixpanel.identify(userData?.userId);
            Mixpanel.people.set({
                investorId: userData?.investorOrgId,
                companyId: userData?.assesseeOrgId,
                ip: ip,
                userType: USER_TYPE.COMPANY,
                $name: userData?.userId,
                $distinct_id: userData?.userId,
            });
            Mixpanel.register({
                InvestorOrgId: userData?.investorOrgId,
                AssesseeOrgId: userData?.assesseeOrgId,
                UserId: userData?.userId,
                userType: USER_TYPE.COMPANY,
                name: userData?.userId,
            });
        }
    }, [userData?.assesseeOrgId, ip]);

    return (
        <div className="CompanyPrivateRoutes">
            {(searchParams.get('magicUpload') === 'true' || searchParams.get('needInfo') === 'true') && <MagicUpload />}
            {!isPortfolioPage && <Topbar />}
            {manageUserDrawerState && <ManageUserDrawer />}
            <Outlet />
        </div>
    );
}

export default CompanyPrivateRoutes;
