export enum RISK_HEADER_KEYS {
    AML_CHECK_KEY = 'AmlCheck',
    PEP_CHECK_KEY = 'PepCheck',
    SANCTION_CHECK_KEY = 'SanctionCheck',
    INDIAN_WATCHLISTS_KEY = 'Indian Watchlists',
    STATE_OWNED_ENTERPRISES_KEY = 'State Owned Enterprises',
    COUNTRY_REGIMES_WATCHLIST_KEY = 'Country Regimes Watchlist',
    INTERNATIONAL_WATCHLIST_KEY = 'International Watchlist',
    RISK_INDICATOR_KEY = 'riskProfile',
    APC_CHECK_KEY = 'ApcCheck',
}

export enum RISK_HEADER_VALUES {
    AML_CHECK = 'AML Penalties',
    PEP_CHECK = 'Political Exposed Person',
    SANCTION_CHECK = 'Global Sanctions',
    INDIAN_WATCHLISTS = 'Indian Watchlists',
    STATE_OWNED_ENTERPRISES = 'State Owned Enterprises',
    COUNTRY_REGIMES_WATCHLIST = 'Country Regimes Watchlist',
    INTERNATIONAL_WATCHLIST = 'Global Watchlists',
    APC_CHECK = 'Adverse Press Coverage',
}

export enum HEADER_MESSAGES {
    RISK_INDICATOR_MESSAGE = 'It aggregates key compliance checks, including high-risk jurisdictions, PEPs, sanctions, SOEs, adverse press coverage, and global watchlists. It streamlines risk assessment to identify and mitigate threats like fraud, corruption, and money laundering.',
    AML_CHECK_MESSAGE = 'The AML check refers to the AML Penalties check,  covers all money laundering events and the associated entities convicted over the past 24 years. This check ensures you are not working with someone involved in past money laundering activities.',
    PEP_CHECK_MESSAGE = 'PEP stands for Politically Exposed Persons, referring to individuals who hold or have held prominent public positions, along with their immediate family members and close associates. Because of their influence and access to significant financial resources, PEPs are considered higher-risk for money laundering, bribery, and corruption.',
    SANCTION_CHECK_MESSAGE = 'Global sanctions, which are restrictive measures imposed by governments or international organizations (e.g., the United Nations or European Union) on countries, entities, or individuals suspected of being involved in illicit activities like money laundering, terrorist financing, or human rights violations. These sanctions are designed to restrict financial transactions, trade, and activities supporting illegal operations.',
    INDIAN_WATCHLISTS_MESSAGE = 'The Country Watchlists Library is a curated set of country-specific, risk-relevant lists for screening. It enables to screen, identify, and act on potential risks from individuals, organizations, or entities listed in watchlists/blacklists/alerts by regional regulators, investigative bodies, and quasi-judicial authorities.This specific watchlist covers the mandatory lists enforced by Indian authorities such as the CBI, RBI, FIU, SEBI, etc.',
    STATE_OWNED_ENTERPRISES_MESSAGE = 'State-Owned Enterprises are businesses or corporations in which the government holds a majority or complete ownership stake. Due to their size, global reach, and involvement in large financial transactions, SOEs can be vulnerable to abuse by criminal actors for money laundering or terrorist financing and hence are considered to be high risk to work with.',
    COUNTRY_REGIMES_WATCHLIST_MESSAGE = 'This contains a list of high-risk jurisdictions as identified by entities such as FATF, HM Treasury, OFAC, and the Financial Action Task Force (FATF). This check is used to identify if a customer is based in a country with weak anti-money laundering (AML) and counter-terrorism financing measures or one considered a haven for money laundering.',
    INTERNATIONAL_WATCHLIST_MESSAGE = 'These are lists that, while not falling under global sanctions, should still be screened against as best practice to ensure no customers or business partners are listed.',
    APC_CHECK_MESSAGE = 'Adverse Press Coverage screens for negative media reports on individuals or entities, highlighting risks like fraud, corruption, money laundering, or regulatory violations. This check helps identify potential reputational and compliance risks early, ensuring informed decision-making.',
}

export const RISK_HEADERS = [
    { key: RISK_HEADER_KEYS.PEP_CHECK_KEY, label: RISK_HEADER_VALUES.PEP_CHECK },
    { key: RISK_HEADER_KEYS.SANCTION_CHECK_KEY, label: RISK_HEADER_VALUES.SANCTION_CHECK },
    { key: RISK_HEADER_KEYS.AML_CHECK_KEY, label: RISK_HEADER_VALUES.AML_CHECK },
    { key: RISK_HEADER_KEYS.APC_CHECK_KEY, label: RISK_HEADER_VALUES.APC_CHECK },
    { key: RISK_HEADER_KEYS.INDIAN_WATCHLISTS_KEY, label: RISK_HEADER_VALUES.INDIAN_WATCHLISTS },
    {
        key: RISK_HEADER_KEYS.INTERNATIONAL_WATCHLIST_KEY,
        label: RISK_HEADER_VALUES.INTERNATIONAL_WATCHLIST,
    },
    {
        key: RISK_HEADER_KEYS.STATE_OWNED_ENTERPRISES_KEY,
        label: RISK_HEADER_VALUES.STATE_OWNED_ENTERPRISES,
    },
    {
        key: RISK_HEADER_KEYS.COUNTRY_REGIMES_WATCHLIST_KEY,
        label: RISK_HEADER_VALUES.COUNTRY_REGIMES_WATCHLIST,
    },
];

export const RISK_MESSAGES_MAP: Record<RISK_HEADER_KEYS, string> = {
    [RISK_HEADER_KEYS.RISK_INDICATOR_KEY]: HEADER_MESSAGES.RISK_INDICATOR_MESSAGE,
    [RISK_HEADER_KEYS.AML_CHECK_KEY]: HEADER_MESSAGES.AML_CHECK_MESSAGE,
    [RISK_HEADER_KEYS.APC_CHECK_KEY]: HEADER_MESSAGES.APC_CHECK_MESSAGE,
    [RISK_HEADER_KEYS.PEP_CHECK_KEY]: HEADER_MESSAGES.PEP_CHECK_MESSAGE,
    [RISK_HEADER_KEYS.SANCTION_CHECK_KEY]: HEADER_MESSAGES.SANCTION_CHECK_MESSAGE,
    [RISK_HEADER_KEYS.INDIAN_WATCHLISTS_KEY]: HEADER_MESSAGES.INDIAN_WATCHLISTS_MESSAGE,
    [RISK_HEADER_KEYS.STATE_OWNED_ENTERPRISES_KEY]: HEADER_MESSAGES.STATE_OWNED_ENTERPRISES_MESSAGE,
    [RISK_HEADER_KEYS.COUNTRY_REGIMES_WATCHLIST_KEY]:
        HEADER_MESSAGES.COUNTRY_REGIMES_WATCHLIST_MESSAGE,
    [RISK_HEADER_KEYS.INTERNATIONAL_WATCHLIST_KEY]: HEADER_MESSAGES.INTERNATIONAL_WATCHLIST_MESSAGE,
};

export const RISK_DOT_COLOR_MAP = {
    GREEN: 'var(--risk-indicator-green)',
    YELLOW: 'var(--risk-indicator-yellow)',
    RED: 'var(--risk-indicator-red)',
    NOT_AVAILABLE: 'var(--risk-indicator-notAvailable)',
} as const;

export const RISK_TYPES = [
    { color: RISK_DOT_COLOR_MAP.RED, label: 'Confirmed Risk' },
    { color: RISK_DOT_COLOR_MAP.YELLOW, label: 'Potential Risk' },
    { color: RISK_DOT_COLOR_MAP.GREEN, label: 'No Risk' },
    { color: RISK_DOT_COLOR_MAP.NOT_AVAILABLE, label: 'Not Available' },
];

export enum TYPES {
    ORGANIZATION = 'organization',
    INDIVIDUAL = 'individual',
}

export const UW_BEFE = 'UW_FETCH_AML_PEP_DATA';
export const DOWNLOAD_ERROR_MESSAGE = 'Failed to download report. Please try again later.';
export const AICA_GET_TASK_TRACKER_DATA = 'AICA_GET_TASK_TRACKER_DATA';
export const AION_INGEST_AML_PEP_DATA = 'AION_INGEST_AML_PEP_DATA';
export enum RISK_INDICATOR_STATUS {
    PROCESSING = 'PROCESSING',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
    TODO = 'TODO',
    TODO_ASYNC = 'TODO_ASYNC',
}
export const MAX_POLLING_ATTEMPTS = 15;
