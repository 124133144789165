import React from 'react';
import styles from './NewsUpdates.module.scss';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { INVESTOR_OPEN_NEWS_ARTICLE } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../../../../../utils/dateUtils';
import { ctaPurpleRight } from '../../../../../../assets/hostedassets';
import dayjs from 'dayjs';
import AIBadge from '../AIBadge/AIBadge';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import clsx from 'clsx';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';

interface NewsUpdatesProps {
    newsInfo;
    accessType;
    isPDFReport?: boolean;
}

const FALLBACK_IMG = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/news-fallback.svg';

const NewsUpdates: React.FC<NewsUpdatesProps> = ({
    newsInfo,
    accessType = '',
    isPDFReport = false,
}) => {
    const onArticleClick = () => {
        Mixpanel.track(INVESTOR_OPEN_NEWS_ARTICLE, {
            Timestamp: getTimeStamp(),
            AICASection: accessType,
        });
    };

    if (!newsInfo?.news?.length) {
        return <></>;
    }

    const render = (
        <div className={styles.NewsUpdates}>
            <div className={styles.Top}>
                <div className={styles.Title}>News & Updates</div>
                <div className={styles.Right}>
                    <AIBadge />
                </div>
            </div>
            <div className={styles.Body}>
                <div className={clsx(styles.Articles, { [styles.PDFReport]: isPDFReport })}>
                    {newsInfo?.news?.slice?.(0, 4)?.map((article, i) => (
                        <div className={styles.SingleArticle} key={i}>
                            <div className={styles.Head}>
                                <div className={styles.Image}>
                                    <img
                                        src={article?.imageUrl ?? FALLBACK_IMG}
                                        alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = FALLBACK_IMG;
                                        }}
                                        width={'100%'}
                                    />
                                </div>

                                {article?.publishedDate && (
                                    <div className={styles.Date}>
                                        {dayjs(article?.publishedDate, 'DD-MM-YYYY', true).isValid()
                                            ? dayjs(article?.publishedDate, 'DD-MM-YYYY').format(
                                                  'MMM YY',
                                              )
                                            : article?.publishedDate}
                                    </div>
                                )}
                            </div>

                            <div className={styles.PublisherName}>{article.publisherName}</div>

                            <div className={styles.Text}>{article.title}</div>

                            <a
                                className={styles.cta}
                                href={article.redirectUrl}
                                target="_blank"
                                onClick={onArticleClick}
                            >
                                <img src={ctaPurpleRight} alt="" height={'14px'} />
                                View News
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    if (isPDFReport)
        return newsInfo?.news?.length &&
            reportSectionsToShow.includes(REPORT_SECTION_NAMES.NEWS_UPDATES) ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.NEWS_UPDATES}>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default NewsUpdates;
