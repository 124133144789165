import { Handlers } from '../../../types/actions/types';
import { CONSTANTS } from './constants';

export type CreateAccountV2Payload = {
    user: UserDataV2;
    platformId: string;
};
type UserDataV2 = {
    pan?: string;
    name?: string;
    inviteCode?: string;
    organizationName?: string;
    emailId?: string;
    investorId: string;
    accountType: string;
    accountTypes: string[];
    journeyName: string;
    password?: string;
    sector?: string | undefined;
    websiteUrl?: string | undefined;
    inviterEmailId?: string;
    mobileNumber: string;
    countryId: string;
    countryCode: string;
    assesseeOrgId?: string;
    inviteType?: string;
    triggerNotification?: boolean;
    partnerOrgId?: string;
    orgId?: string;
    orgDisplayName?: string;
};

export type LoginWithOtpAfterSignupPayload = {
    accountType: string;
    apiPayload: {
        user: {
            emailId: string;
            otp: string;
            medium: 'EMAIL' | 'SMS';
            investorId?: string;
            signUpToken?: string;
            accountType: string;
            accountTypes?: string[];
        };
        loginType: string;
        platformId: string;
    };
};
export type LoginWithPasswordPayloadV2 = {
    user: {
        emailId: string;
        password: string;
        investorId: string;
        accountType: string;
        accountTypes?: string[];
    };
    loginType: string;
    platformId: string;
    onComplete?: Function;
};

export type FetchConfigAfterLoginPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    userRole: string;
};

export type LoginWithOtpPayloadV2 = {
    user: {
        emailId: string;
        medium: 'EMAIL' | 'SMS';
        investorId: string;
        accountType: string;
        accountTypes: string[];
    };
    platformId: string;
    onSuccess?: Function;
    onError?: Function;
};
export type DomainMetadataReqPayloadV2 = {
    subdomainName: string;
    domainName: string;
    referrer: string;
};
export type SendResetLinkV2 = {
    user: {
        emailId: string;
        accountType: string;
        accountTypes: string[];
        investorId: string;
    };
    platformId: string;
    onSuccess: Function;
    onError: Function;
};
export type ResetPasswordV2 = {
    user: {
        emailId: string;
        password: string;
    };
    mfaValidationToken: string;
    onSuccess: Function;
    onError: Function;
};

export type LoginForSummonAicaPayload = {
    ssoOrgId: string;
    isDataRoom: boolean;
    apiPayload: {
        user: {
            authToken: string;
            investorId: string;
            accountType: string;
            accountTypes: string[];
        };
        loginType: string;
        source: string;
        platformId: string;
    };
};
export type InvestorSignupPayload = {
    orgInfo: {
        primaryEmail: string;
        orgName: string;
        primaryUserFirstName: string;
        primaryUserLastName: string;
        password: string;
        accountType: string;
        accountTypes: string[];
        phoneNumber: string;
        countryCode: string;
        countryId: string;
        investorOrgType: string;
    };
    onSuccess: Function;
    onError: Function;
};
export type InvestorLoginSignupPayload = {
    emailId: string;
    onSuccess: Function;
    onError: Function;
};

export type AddAssesseePayload = {
    payload: {
        user: {
            name: string;
            accountTypes: string[];
            emailId: string;
            mobileNumber: string;
            countryCode: string;
            accountType: string;
            investorId: string;
            countryId: string;
            organizationId: string;
            journeyName: string;
            triggerNotification: boolean;
        };
        platformId: string;
    };
    handlers: {
        onSuccess: Function;
        onError: Function;
    };
};

export type VerifyEmailPayload = {
    user: {
        emailId: string;
        medium: 'EMAIL' | 'SMS';
        investorId: string;
        signUpToken: string;
        accountType: string;
        accountTypes: string[];
    };
    platformId: string;
};

export type ResendVerificationEmailPayload = {
    user: {
        emailId: string;
        investorId: string;
        accountType: string;
        accountTypes: string[];
    };
    platformId: string;
};

export const loginForSummonAicaV2 = (payload: LoginForSummonAicaPayload) => {
    return {
        type: CONSTANTS.LOGIN_FOR_SUMMON_AICA_V2,
        payload,
    };
};

export const createAccountV2 = (payload: CreateAccountV2Payload, handlers) => {
    return {
        type: CONSTANTS.CREATE_ACCOUNT_V2,
        payload,
        handlers,
    };
};

export const verifyOtpV2 = (payload: LoginWithOtpAfterSignupPayload, handlers = {}) => {
    return {
        type: CONSTANTS.LOGIN_WITH_OTP_V2,
        payload,
        handlers,
    };
};

export const LoginV2 = (payload: LoginWithPasswordPayloadV2) => {
    return {
        type: CONSTANTS.LOGIN_WITH_PASSWORD_V2,
        payload,
    };
};

export const generateOTPV2 = (payload: LoginWithOtpPayloadV2) => {
    return {
        type: CONSTANTS.GENERATE_OTP_V2,
        payload,
    };
};
export const fetchDomainMetadataV2 = (payload: DomainMetadataReqPayloadV2) => {
    return {
        type: CONSTANTS.FETCH_DOMAIN_METADATA_V2,
        payload,
    };
};
export const forgotPasswordV2 = (payload: SendResetLinkV2) => {
    return {
        type: CONSTANTS.FORGOT_PASSWORD_V2,
        payload,
    };
};

export const resetPasswordV2 = (payload: ResetPasswordV2) => {
    return {
        type: CONSTANTS.RESET_PASSWORD_V2,
        payload,
    };
};

export const investorSignup = (payload: InvestorSignupPayload) => {
    return {
        type: CONSTANTS.INVESTOR_SIGNUP,
        payload,
    };
};

export const investorSignupLogin = (payload: InvestorLoginSignupPayload) => {
    return {
        type: CONSTANTS.INVESTOR_SIGNUP_LOGIN,
        payload,
    };
};

export const addAssessee = (payload: AddAssesseePayload) => {
    return {
        type: CONSTANTS.ASSESSEE_ADDITION,
        payload,
    };
};

export const logoutUser = (payload = null, handlers: Handlers) => {
    return {
        type: CONSTANTS.LOGOUT_USER,
        handlers,
    };
};

export const fetchConfigAfterLogin = (payload: FetchConfigAfterLoginPayload, handlers: Handlers) => {
    return {
        type: CONSTANTS.FETCHING_CONFIG_AFTER_LOGIN,
        payload,
        handlers,
    };
};

export const verifyEmail = (payload: VerifyEmailPayload, handlers: Handlers) => {
    return {
        type: CONSTANTS.VERIFY_EMAIL,
        payload,
        handlers,
    };
};

export const resendVerificationEmail = (payload: ResendVerificationEmailPayload, handlers: Handlers) => {
    return {
        type: CONSTANTS.RESEND_VERIFICATION_EMAIL,
        payload,
        handlers,
    };
};
