import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchLenderConfig, resetDealFilters } from '../../../store/investor/action';

import DealsList from './components/DealsList/DealsList';
import ManageDealDrawer from '../../../common/ManageDealDrawer/ManageDealDrawer';

import styles from './Deals.module.scss';
import DealTermsLoggingDrawer from './components/DealsList/DealTermsLoggingDrawer/DealTermsLoggingDrawer';
import { useSearchParams } from 'react-router-dom';
import { closeCommentsThread } from '../../../store/commentsThread/commentsThread_actions';
import useLegacyBusinessDataFetch from '../../DataRoom/useLegacyBusinessDataFetch';

interface DealsProps {
    screen: string;
}

export const DEALS_SCREENS = {
    GLOBAL_DEALS: 'GLOBAL_DEALS',
    COMPANY_DEALS: 'COMPANY_DEALS',
};

const Deals: React.FC<DealsProps> = ({ screen }) => {
    const dispatch = useAppDispatch();
    const [queryParams, setQueryParams] = useSearchParams();

    const userData = useAppSelector((state) => state.user.userData);
    const user = useAppSelector((state) => state.user);
    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);

    const { selectedCompany } = useLegacyBusinessDataFetch();

    // Combined state for deal terms logging
    const [dealTermsLogging, setDealTermsLogging] = useState({
        isDrawerOpen: false,
        investorDealId: '',
        borrowerName: '',
        source: '',
    });

    useEffect(() => {
        const paramMapping = {
            showDealTermsLoggingDrawer: 'isDrawerOpen',
            investorDealId: 'investorDealId',
            borrowerName: 'borrowerName',
            source: 'source',
        };

        if (queryParams.get('showDealTermsLoggingDrawer')) {
            const newState: typeof dealTermsLogging = { ...dealTermsLogging };

            Object.entries(paramMapping).forEach(([paramKey, stateKey]) => {
                newState[stateKey] = paramKey === 'showDealTermsLoggingDrawer' ? true : queryParams.get(paramKey) || '';
            });

            setDealTermsLogging(newState);
        }
    }, [queryParams]);

    useEffect(() => {
        dispatch(
            fetchLenderConfig({
                accountType: userData.accountType,
                primaryEmail: userData.primaryEmail,
                investorOrgId: userData.investorOrgId,
                userRole: user?.userData?.roles?.[0],
                configName: 'AICA_LENDER_DASHBOARD',
            }),
        );

        return () => {
            dispatch(closeCommentsThread());
        };
    }, []);

    useEffect(() => {
        return () => {
            if (screen === DEALS_SCREENS.COMPANY_DEALS) {
                dispatch(
                    resetDealFilters({
                        allStatusesSelected: true,
                    }),
                );
            }
        };
    }, [screen]);

    return (
        <div className={styles.DealsContainer}>
            <DealsList selectedCompany={selectedCompany} />
            {showDealDrawer && dealDrawerState.drawerSource !== 'ProfileButton' && <ManageDealDrawer source="Deals List Page" />}
            <DealTermsLoggingDrawer
                isOpen={dealTermsLogging.isDrawerOpen}
                onClose={() => {
                    setDealTermsLogging((prev) => ({ ...prev, isDrawerOpen: false }));
                    ['showDealTermsLoggingDrawer', 'investorDealId', 'borrowerName', 'source'].forEach((param) => {
                        queryParams.delete(param);
                    });
                    setQueryParams(queryParams);
                }}
                investorDealId={dealTermsLogging.investorDealId}
                borrowerName={dealTermsLogging.borrowerName}
                source={dealTermsLogging.source}
            />
        </div>
    );
};

export default Deals;
