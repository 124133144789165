import EditableDropdown from '../../../../common/EditableDropdown/EditableDropdown';
import styles from './AuditedFinancialsVisualisation.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
    fetchAuditedTableData,
    fetchMisTableOptions,
    updateAuditedTableData,
} from '../../../../store/company/action';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getTimeStamp } from '../../../../utils/dateUtils';
import ClassifiedTable from '../../components/Tables/ClassifiedTable/ClassifiedTable';
import expandMore from '../../../../assets/expand-more.svg';
import RawTable from '../../components/Tables/RawTable/RawTable';
import { toast } from 'react-toastify';
import { isEmpty, removeKeyAndConvertIdToNumber } from '../../../../utils/utils';
import { DATA_FETCH_STATUS } from '../../../../store/dashboard/constants';
import generating from '../../../../assets/audited-financials-icon.svg';
import loader from '../../../../assets/aica-loader.svg';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import whiteDownload from '../../../../assets/download-white.svg';
import { Button } from '../../../../common/_custom';
import { getAuditedFinancialsReportId } from '../../../../store/dashboard/auditedFinancials/actions';
import { downloadFile } from '../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import clsx from 'clsx';
import { updateQueryParams } from '../../../../utils/searchParamUtils';
import { ctaPurpleRight } from '../../../../assets/hostedassets';
import FinancialLoaderComponent from '../../../../common/FinancialLoader/FinancialLoaderComponent';
import A4Page from '../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../store/pdfReport/pdfReport_constants';
import AicaOverview from '../../../../common/AicaOverview/AicaOverview';
import { AUDITED_FINANCIALS } from '../Financials/Financials';

const AuditedFinancialsVisualisation = ({
    selectedMetric = '',
    isPDFReport = false,
    assesseeOrgId = '',
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [exporting, setExporting] = useState(false);

    const [tableType, setTableType] = useState<string>('');
    const queryParams = useParams();
    const [tableData, setTableData] = useState<any>([{}]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [changedData, setChangedData] = useState<any>([]);
    const [metric, setMetric] = useState<string>('');
    const [rawOptions, setRawOptions] = useState<any>([]);
    const [rawTableData, setRawTableData] = useState<any>([{}]);
    const [loading, setLoading] = useState<boolean>(true);
    const [metricOptions, setMetricOptions] = useState<string[]>([]);
    const [dataFormatOptions, setDataFormatOptions] = useState<string[]>([]);
    const [metricOptionsObject, setMetricsOptionsObject] = useState([]);
    const [loaderMessage, setLoaderMessage] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const pollingIntervalRef: any = useRef(null);

    const isProcessing = status === DATA_FETCH_STATUS.PROCESSING;
    const orgId = queryParams.id || assesseeOrgId;

    const fetchOptions = () => {
        setLoading(true);
        dispatch(
            fetchMisTableOptions({
                orgId: orgId || '',
                source: 'AicaAuditedFinancials',
                onSuccess: (res) => {
                    setRawOptions(res.data?.data?.[0]?.misOptions);
                    setMetricOptions(
                        res.data?.data?.[0]?.misOptions?.map((item) => {
                            return item.misDisplayName;
                        }),
                    );
                    setMetricsOptionsObject(res.data?.data?.[0]?.misOptions || []);
                    setDataFormatOptions(
                        res.data?.data?.[1].dataFormats.map((item) => item.dataFormat),
                    );
                },
            }),
        );
    };

    const fetchTableData = (isPolling = false) => {
        if (!isPolling) setLoading(true);
        dispatch(
            fetchAuditedTableData(
                {
                    type: 'auditedfinancials',
                    mis: metric,
                    orgId: orgId || '',
                    requestId: getTimeStamp(),
                    raw: false,
                },
                {
                    onError: () => {
                        setLoading(false);
                    },
                    onSuccess: (data, status) => {
                        setStatus(status?.auditedFinancialsStatus);
                        setLoading(false);
                        setTableData(data);
                    },
                },
            ),
        );
    };

    const fetchRawData = (row: { [key: string]: string }, header: { [key: string]: string }) => {
        if (header.name === 'Classification' && row?.[header.key] && !row.isAggregated) {
            setLoading(true);
            setTableType(row.classificationName);
            dispatch(
                fetchAuditedTableData(
                    {
                        type: 'auditedfinancials',
                        mis: metric,
                        orgId: orgId || '',
                        requestId: getTimeStamp(),
                        classificationId: row.classificationId,
                        raw: true,
                    },
                    {
                        onError: () => {
                            setLoading(false);
                        },
                        onSuccess: (data) => {
                            setLoading(false);
                            setRawTableData(data);
                        },
                    },
                ),
            );
        }
    };

    useEffect(() => {
        showLoaderMessage(!isEmpty(tableData?.headers));
    }, [loading]);

    useEffect(() => {
        if (metric) {
            fetchTableData();
            setIsEditing(false);
            setTableType('');
            setChangedData([]);
        }
    }, [metric]);

    useEffect(() => {
        orgId && fetchOptions();
    }, [orgId]);

    useEffect(() => {
        if (selectedMetric) return;
        if (status === DATA_FETCH_STATUS.PROCESSING) {
            pollingIntervalRef.current = setInterval(() => {
                fetchTableData(true);
            }, 5000);
        } else {
            clearInterval(pollingIntervalRef?.current);
        }
        showLoaderMessage(!isEmpty(tableData?.headers));
        return () => {
            clearInterval(pollingIntervalRef?.current);
        };
    }, [status, metric]);

    useEffect(() => {
        if (metricOptions.includes('Other Metric (MIS)')) {
            let temp = metricOptions;
            temp = temp.filter((item) => item !== 'Other Metric (MIS)');
            setMetricOptions(temp);
        }
    }, [metricOptions]);

    useEffect(() => {
        if (metricOptionsObject?.length && metricOptions?.length) {
            if (selectedMetric) setMetric(selectedMetric);
            else {
                let first = (metricOptionsObject as any).find(
                    (item: any) => item?.isDataAvailable === true,
                )?.misDisplayName;
                setMetric(first || metricOptions?.[0]);
            }
            //@ts-ignore
        }
    }, [metricOptionsObject, metricOptions, selectedMetric]);

    useEffect(() => {
        const tableHeadersLength = tableData?.headers?.length;
        if (!isPDFReport || !tableHeadersLength) return;

        if (tableHeadersLength > 1) {
            const headersForPDFReport = [
                tableData.headers[0],
                ...(tableHeadersLength > 3 ? [tableData.headers[tableHeadersLength - 3]] : []),
                ...(tableHeadersLength > 2 ? [tableData.headers[tableHeadersLength - 2]] : []),
                tableData.headers[tableHeadersLength - 1],
            ];

            if (JSON.stringify(tableData.headers) !== JSON.stringify(headersForPDFReport)) {
                setTableData((data) => ({
                    ...data,
                    headers: headersForPDFReport,
                }));
            }
        }
    }, [isPDFReport, tableData?.headers]);

    const handleSave = () => {
        dispatch(
            updateAuditedTableData({
                orgId: queryParams.id ?? '',
                metrics: metric,
                changedData: removeKeyAndConvertIdToNumber(changedData, 'name'),
                source: 'AicaAuditedFinancials',
                onSuccess: () => {
                    fetchTableData();
                    setIsEditing(false);
                    setChangedData([]);
                    setTableType('');
                    toast.success('Data saved successfully');
                },
                onError: () => {
                    toast.error('Failed to save data');
                },
            }),
        );
    };
    const onExportClick = () => {
        setExporting(true);
        queryParams.id &&
            dispatch(
                getAuditedFinancialsReportId(
                    {
                        assesseeOrgId: queryParams.id,
                    },
                    {
                        onSuccess: async (reportId) => {
                            await downloadFile(reportId, () => setExporting(false));
                        },
                        onError: () => {
                            setExporting(false);
                        },
                    },
                ),
            );
    };

    // const generatingCard = () => {
    //     return (
    //         <div className={styles.GeneratingData} style={{ height: '600px' }}>
    //             <img src={generating} width="100px" alt="" />
    //             Fetching Audited Financials
    //             <img src={loader} alt="" width="50px" />
    //         </div>
    //     );
    // };

    const showAllFinancialMetrics = () => {
        updateQueryParams(searchParams, setSearchParams, { showFinancialMetrics: true }, location);
    };

    function showLoaderMessage(tableData) {
        if (isPDFReport) return '';
        if (isProcessing || loading)
            setLoaderMessage('Fetching latest data from MCA (takes upto 24 hours)');
        else if (!tableData) setLoaderMessage('No Data Available');
    }

    const render = (
        <div>
            <div className={styles.HeadingContainer}>
                {!isPDFReport && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <h3>Financial Statements</h3>
                        <div className={styles.divider} />
                        <EditableDropdown
                            id={'MetricSelector'}
                            optionStyle={{ fontSize: '14px' }}
                            value={metric}
                            options={metricOptions}
                            setOutput={(val) => {
                                if (pollingIntervalRef.current)
                                    clearInterval(pollingIntervalRef.current);
                                setMetric(val);
                                // setTableType('');
                            }}
                            removeInput={true}
                            placeHolder={'Select Metric'}
                            optionsObject={metricOptionsObject}
                        />
                    </div>
                )}
                {!selectedMetric && (
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
                        <div
                            className={clsx(styles.AllFinancialMetrics)}
                            onClick={() => showAllFinancialMetrics()}
                        >
                            {' '}
                            <img src={ctaPurpleRight} alt={'upload'} />
                            View Financial Ratios
                        </div>
                        {/* <div
                        className={clsx(styles.ExportData, {
                            [styles.ExportDataDisabled]: exporting,
                        })}
                        onClick={() => onExportClick()}
                    >
                        <img
                            src={
                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/UploadDataInvestorProv.svg'
                            }
                            alt={'upload'}
                        />
                        Export Data
                    </div> */}
                    </div>
                )}
            </div>
            <>
                {!isPDFReport && (isProcessing || isEmpty(tableData?.headers) || loading) && (
                    <FinancialLoaderComponent
                        tableName="Audited Financials"
                        message={loaderMessage}
                        status={isProcessing || loading}
                    />
                )}
                {tableType && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '0.5rem 0',
                            padding: '0 0.5rem',
                        }}
                    >
                        <div className={styles.Breadcrumb}>
                            <span
                                className={styles.Blue}
                                onClick={() => {
                                    setTableType('');
                                }}
                            >
                                {metric}
                            </span>
                            <img src={expandMore} alt="exp" height="10px" />
                            {tableType}
                        </div>
                        {isEditing ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    fontFamily: 'Gilroy-Semibold,serif',
                                    fontSize: '14px',
                                }}
                            >
                                <div>Save Classification</div>
                                <button
                                    onClick={() => {
                                        setIsEditing(!isEditing);
                                        setChangedData([]);
                                    }}
                                    className={styles.CancelClassification}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => handleSave()}
                                    className={styles.SaveClassification}
                                >
                                    <img
                                        src={
                                            'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/saveClassificationInvestor.svg'
                                        }
                                        alt={'save'}
                                    />
                                    Save
                                </button>
                            </div>
                        ) : (
                            <div
                                onClick={() => setIsEditing(!isEditing)}
                                className={styles.EditClassification}
                            >
                                <img
                                    src={
                                        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/editClassification.svg'
                                    }
                                    alt="edit"
                                />
                                Edit Classification
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.TableContainer}>
                    {tableType && (
                        <>
                            <RawTable
                                data={rawTableData}
                                loading={loading}
                                freezeColumnEnd={2}
                                freezeColumnStart={0}
                                isEditing={isEditing}
                                metric={metric}
                                rawOptions={rawOptions}
                                changedData={changedData}
                                setChangedData={setChangedData}
                            />
                        </>
                    )}
                    {tableType === '' && !isEmpty(tableData?.headers) && (
                        <ClassifiedTable
                            data={tableData}
                            loading={false}
                            handleClick={fetchRawData}
                            metric={metric}
                            isPDFReport={isPDFReport}
                        />
                    )}
                </div>
            </>
        </div>
    );

    if (isPDFReport)
        return tableData?.headers?.length ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.AUDITED_FINANCIALS}>
                <div className={styles.Card}>
                    <div className={styles.Top}>
                        <div className={styles.Title}>
                            Financial Statements&nbsp;
                            <span className={styles.Secondary}>| {metric}</span>
                        </div>
                        <div className={styles.Right}>All values are in INR</div>
                    </div>
                    <div className={styles.Body}>{render}</div>
                </div>
            </A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default AuditedFinancialsVisualisation;
