import { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import ReactTooltip from 'react-tooltip';
import styles from '../../Graph.module.scss';
import {
    getColorKeyFromIndex,
    getHeatMapData,
} from '../../../../../../store/dashboard/transformer';
// import { graphDataType } from '../../../../../../store/Dashboard/types';

interface MapDataType {
    id: string;
    state: string;
    value: number;
}
const INDIA_TOPO_JSON = require('./india.topo.json');

const DEFAULT_COLOR = '#ffffff';

const getRandomInt = () => {
    let value = Math.random() * 100;
    return Number(value.toFixed(3));
};

const geographyStyle = {
    default: {
        outline: 'none',
    },
    hover: {
        // fill: '#2a52be',
        // fill: '#324ab2',
        fill: '#54579d',
        transition: 'all 250ms',
        outline: 'none',
    },
    pressed: {
        // fill: '#54579d',
        fill: '#324ab2',
        outline: 'none',
    },
};

// will generate random heatmap data on every call

interface IndiaCustomMapProps {
    height: number;
    width: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function IndiaCustomMap(props: IndiaCustomMapProps) {
    const scale = (window.innerWidth * 140) / 1920;
    const PROJECTION_CONFIG = {
        scale: scale,
        center: [82, 23], // always in [East Latitude, North Longitude]
    };

    const { graphData } = props;
    const { colors, metadata } = graphData;
    const plotValues = graphData?.plots?.[0]?.plotValues || [];
    const [tooltipContent, setTooltipContent] = useState('');
    const [totalCalculated, setTotalCalculated] = useState(0);
    const heatMapRanges = metadata?.heatMapRanges || [];
    const [data, setData] = useState<MapDataType[]>(
        getHeatMapData(plotValues, colors, heatMapRanges),
    );

    //light to dark color
    //@ts-ignore
    // const colorScales = scaleLinear().domain([0, 100]).range([colors?.primary, colors?.secondary]);

    useEffect(() => {
        if (plotValues?.length) {
            setTotalCalculated(
                plotValues?.reduce((acc, curr) => {
                    acc += !Number.isNaN(curr.value) ? curr.value : 0;
                    return acc;
                }, 0),
            );
        }
    }, [plotValues]);

    const onMouseEnter = (geo, current: MapDataType | undefined) => {
        const percentageCalc =
            current?.value && totalCalculated
                ? ((current?.value / totalCalculated) * 100).toFixed(2)
                : 0;
        return () => {
            setTooltipContent(
                `${geo.properties.name}: ${(current?.value || 0)?.toLocaleString('en-IN')} ${
                    percentageCalc ? '(' + percentageCalc + '%)' : ''
                }`,
            );
        };
    };

    const onMouseLeave = () => {
        setTooltipContent('');
    };

    return (
        <div className={styles.indiawrapper} style={{ height: `${props.height * 2 + 190}px` }}>
            <ReactTooltip>{tooltipContent}</ReactTooltip>
            <ComposableMap
                /* @ts-ignore */

                projectionConfig={PROJECTION_CONFIG}
                projection="geoMercator"
                width={props.width / 9.5}
                height={props.height}
                data-tip=""
            >
                <Geographies geography={INDIA_TOPO_JSON}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const current = data.find((s) => s.id === geo.id);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    /* @ts-ignore */
                                    fill={current?.color || DEFAULT_COLOR}
                                    style={geographyStyle}
                                    onMouseEnter={onMouseEnter(geo, current)}
                                    onMouseLeave={onMouseLeave}
                                />
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
            <div className={styles.mapDataWrapper}>
                {heatMapRanges?.map((range, i) => (
                    <div className={styles.datawrapper} key={`${range?.range}-${i}`}>
                        <div
                            className={styles.roundedDiv}
                            style={{ background: colors?.[getColorKeyFromIndex(i)] }}
                        ></div>
                        <p className={styles.mapBoldDatawrapper}>{range?.range}</p>

                        <p className={styles.mapLigitTextColor}>({range?.text})</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IndiaCustomMap;
