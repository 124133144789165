import { useEffect, useState, useRef } from 'react';
import { getAlerts } from '../../../../../../store/dashboard/AlertEngine/alertEngine_apiHelpers';
import styles from './AlertEngineTable.module.scss';
import { isEmpty } from '../../../../../../utils/utils';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';

type AlertEngineTableProps = {
    assesseOrgId: string;
    parentOrgId: string;
};
type AlertRow = {
    severity: string;
    description: string;
    category: string;
    alertDate: string;
};

const AlertEngineTable = (props: AlertEngineTableProps) => {
    const { assesseOrgId, parentOrgId } = props;
    const [totalRecords, setTotalRecords] = useState(0);
    const [rowData, setRowData] = useState<AlertRow[]>([]);
    const [isAlertGenerated, setAlertGenerated] = useState<boolean>(false);
    const [filters, setFilters] = useState({
        date: 1,
        severity: 1,
    });
    const [pageNumber, setPageNumber] = useState(1);
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const [isFetching, setIsFetching] = useState(false);

    const getBannerStatusColors = (status: string) => {
        let color = '';
        let banner = '';
        switch (status) {
            case 'SEVERE':
                color = '#FFFFFF';
                banner = '#DF666A';
                break;
            case 'HIGH':
                color = '#DF666A';
                banner = '#FDF0EC';
                break;
            case 'MEDIUM':
                color = '#D6BB0A';
                banner = '#FFFAD7';
                break;
            case 'LOW':
                color = '#00AA00';
                banner = '#E5FCDE';
                break;
        }
        return {
            color,
            banner,
        };
    };

    const getTableData = async (page: number) => {
        setIsFetching(true);
        const payload = {
            assesseOrgId,
            parentOrgId,
            alertTypes: ['ALERT', 'EWS_ALERT'],
            pageNumber: page,
            pageSize: 20,
        };
        const res = await getAlerts(payload);
        if (res) {
            setRowData((prevData) => [...prevData, ...res.alerts]);
            setTotalRecords(res.totalRecords);
            setAlertGenerated(res.alertsGenerated ?? false);
        }
        setIsFetching(false);
    };

    useEffect(() => {
        // Initial data load
        getTableData(pageNumber);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (!tableWrapperRef.current || isFetching) return;

            const { scrollTop, scrollHeight, clientHeight } = tableWrapperRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 10 && rowData.length < totalRecords) {
                // Load more data when user scrolls to bottom
                setPageNumber((prevPage) => prevPage + 1);
            }
        };

        const tableWrapper = tableWrapperRef.current;
        if (tableWrapper) {
            tableWrapper.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableWrapper) {
                tableWrapper.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isFetching, rowData, totalRecords]);

    useEffect(() => {
        if (pageNumber > 1) {
            getTableData(pageNumber);
        }
    }, [pageNumber]);

    const renderRows = () => {
        if (isEmpty(rowData)) {
            if (isAlertGenerated)
                return (
                    <div className={styles.Note}>
                        <div>No alerts detected for this company</div>
                    </div>
                );
            return (
                <div className={styles.Row}>
                    <div>No Alerts available</div>
                </div>
            );
        } else {
            return (
                <>
                    {rowData.map((row, index) => (
                        <div key={index} className={styles.Row}>
                            <div
                                style={{
                                    background: getBannerStatusColors(row.severity).banner,
                                    color: getBannerStatusColors(row.severity).color,
                                }}
                                className={styles.SeverityPill}
                            >
                                {row.severity}
                            </div>
                            <div className={styles.Description}>{row.description}</div>
                            <div className={styles.CategoryPill}>{row.category}</div>
                            <div className={styles.Date}>{row.alertDate}</div>
                        </div>
                    ))}
                </>
            );
        }
    };

    return (
        <div className={styles.AlertEngineTableWeapper}>
            <span>
                Showing {rowData.length} of {totalRecords} records
            </span>
            <div
                className={styles.TableWrapper}
                ref={tableWrapperRef}
                style={{ overflowY: 'auto', height: 'fit-content' }}
            >
                <div className={styles.Headers}>
                    <div>Severity</div>
                    <div>Description</div>
                    <div>Category</div>
                    <div>Date</div>
                </div>
                <div>
                    {renderRows()}
                    {isFetching && (
                        <div className={styles.Loading}>
                            <LoadingSpinner height={'3rem'} color={'#000000'} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AlertEngineTable;
