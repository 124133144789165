import styles from './ManageUser.module.scss';
import Button from '../../../common/_custom/Button/Button';
import ManageUserTable from './ManageUserTable/ManageUserTable';
import React from 'react';
import clsx from 'clsx';
import InviteTeammateModal from '../../../common/InviteTeammateModal/InviteTeammateModal';
import { plusWhite, plusWhite2 } from '../../../assets/hostedassets';
import AddUserModal from './ManageUserTable/components/AddUserModal';
import { isNil } from 'lodash';
import { useManageUser } from './useManageUser';
import OrgBasedUsersTable from './OrgBasedUsersTable/OrgBasedUsersTable';
import aicaLoader from '../../../assets/aica-loader.svg';
import SearchBox from '../Portfolio/components/CompaniesList/components/SearchBox/SearchBox';
import { MANAGE_USERS_TABS } from '../../../store/investor/constants';

type ManageUserProps = {
    isOrgDrawer?: boolean;
    selectedUsers?: any[];
    parentCategory?: string | null;
    selectedOrgName?: string;
};

const ManageUser = ({ isOrgDrawer = false, selectedUsers, parentCategory, selectedOrgName }: ManageUserProps) => {
    const {
        pageNo,
        params,
        showInviteTeammateModal,
        headers,
        rows,
        hasNextPage,
        showAddUserModal,
        currentRowUser,
        isCompanySide,
        uploadDataJourneyData,
        isRowsLoading,
        isHeadersLoading,
        setPageNo,
        setParams,
        setShowInviteTeammateModal,
        setShowAddUserModal,
        setCurrentRowUser,
        fetchRows,
        fetchDynamicHeaders,
        getTotalPages,
        getActionMenuItems,
        setSearchQuery,
        getActionConfig,
        getModalHeading,
    } = useManageUser(isOrgDrawer, selectedUsers, parentCategory);

    const { PARTNER, INVESTOR, LENDER } = MANAGE_USERS_TABS;

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        setPageNo(1);
        params.set('page', '1');
        setParams(params);
    };

    const renderHeader = () => {
        switch (true) {
            case isCompanySide: {
                const category = params.get('category')?.toString();
                const headingText = category === 'Assessee' ? 'Manage Team' : `Manage Users`;
                return (
                    <div className={styles.HeaderContainerForCompanySide}>
                        <h2 className={styles.HeadingTextForCompany}>{headingText}</h2>
                        <div className={styles.CompanyName}>{selectedOrgName || uploadDataJourneyData?.organizationName}</div>
                    </div>
                );
            }
            case !isCompanySide: {
                const category = params.get('category')?.toString();
                const showSearch = category === PARTNER || category === INVESTOR;
                const headingText = category === INVESTOR ? 'Manage Lenders' : `Manage ${category}`;

                return (
                    <div className={styles.HeaderContainer}>
                        <div className={styles.LeftSection}>
                            <h2 className={styles.HeadingText}>{headingText}</h2>
                        </div>
                        <div className={styles.RightSection}>
                            {showSearch && (
                                <div className={styles.SearchContainer}>
                                    <SearchBox
                                        onSearch={handleSearch}
                                        categoryParam={category || ''}
                                        location={window.location}
                                        accessTierParam=""
                                        placeholder={`Search by ${category === INVESTOR ? LENDER : PARTNER} Name (Min 3 char)`}
                                    />
                                </div>
                            )}
                            <Button
                                style={{ margin: 0 }}
                                icon={plusWhite}
                                text={getModalHeading(params.get('category')?.toString())}
                                onClick={() => {
                                    setShowInviteTeammateModal(true);
                                }}
                                variant={'purple'}
                            />
                        </div>
                    </div>
                );
            }
        }
    };

    const renderTable = () => {
        if (isHeadersLoading) {
            return (
                <div className={styles.LoadingContainer}>
                    <img src={aicaLoader} alt="Loading..." width={'80px'} />
                </div>
            );
        }

        if (isCompanySide) {
            return (
                <ManageUserTable
                    setShowAddUserModal={setShowAddUserModal}
                    setCurrentRowUser={setCurrentRowUser}
                    headers={headers}
                    rows={rows}
                    isRowsLoading={isRowsLoading}
                    isCompanySide={isCompanySide}
                />
            );
        }

        const category = params.get('category')?.toString();
        if ((category === 'Partners' || category === 'Investors') && !isCompanySide) {
            return (
                <OrgBasedUsersTable
                    headers={headers}
                    rows={rows}
                    isRowsLoading={isRowsLoading}
                    isSummonUser={!isNil(sessionStorage.getItem('auth_token'))}
                    actionMenuItems={getActionMenuItems()}
                    category={category === PARTNER ? 'Partner' : 'Lender'}
                    actionConfig={getActionConfig(category)}
                    fetchRows={fetchRows}
                />
            );
        } else {
            return (
                <ManageUserTable
                    setShowAddUserModal={setShowAddUserModal}
                    setCurrentRowUser={setCurrentRowUser}
                    headers={headers}
                    rows={rows}
                    isRowsLoading={isRowsLoading}
                    isCompanySide={isCompanySide}
                />
            );
        }
    };

    return (
        <div className={!isCompanySide ? styles.ManageUsers : styles.ManageUserForCompanySide}>
            {renderHeader()}
            <div
                className={clsx(styles.TableContainer, {
                    [styles.TableContainerCompanySide]: isCompanySide,
                })}
            >
                {renderTable()}
            </div>
            {!isCompanySide && (
                <>
                    <div className={styles.PaginationContainer}>
                        <div className={styles.PaginationText}>
                            {isRowsLoading ? 'Loading...' : `Page ${pageNo} of ${getTotalPages(params.get('category'))}`}
                        </div>
                        <div className={styles.ButtonContainer}>
                            <div
                                className={clsx({
                                    [styles.Disabled]: isRowsLoading || pageNo === 1,
                                    [styles.Btn]: true,
                                })}
                                onClick={() => {
                                    if (isRowsLoading || pageNo === 1) return;
                                    setPageNo(pageNo - 1);
                                    params.set('page', String(pageNo - 1));
                                    setParams(params);
                                }}
                            >
                                Previous
                            </div>
                            <div
                                className={clsx({
                                    [styles.Btn]: true,
                                    [styles.Disabled]: isRowsLoading || !hasNextPage,
                                })}
                                onClick={() => {
                                    if (isRowsLoading || !hasNextPage) return;
                                    setPageNo(pageNo + 1);
                                    params.set('page', String(pageNo + 1));
                                    setParams(params);
                                }}
                            >
                                Next
                            </div>
                        </div>
                    </div>
                    <AddUserModal
                        showAddUserModal={showAddUserModal}
                        setShowAddUserModal={setShowAddUserModal}
                        currentRowUser={currentRowUser}
                        onClose={() => setShowAddUserModal(false)}
                        fetchRows={fetchRows}
                        pageNo={pageNo}
                        getDynamicHeaders={fetchDynamicHeaders}
                    />
                    <InviteTeammateModal
                        isOpen={showInviteTeammateModal}
                        onClose={() => setShowInviteTeammateModal(false)}
                        fetchRows={fetchRows}
                        pageNo={pageNo}
                        getDynamicHeaders={fetchDynamicHeaders}
                        heading={getModalHeading(params.get('category')?.toString())}
                    />
                </>
            )}
        </div>
    );
};

export default ManageUser;
