import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAppSelector } from '../../../../../../app/hooks';

import DealsRow from './components/DealsRow/DealsRow';
import DealsHeader from './components/DealsHeader/DealsHeader';
import { USER_TYPE } from '../../../../../../utils/constants/user';

import styles from './DealsTable.module.scss';
import { AICA_DEAL_NAVBAR_VIEW } from '../../../../../../common/ManageDealDrawer/DealDrawerConstants';
import { getDealFormConfig } from '../../../../../../store/investor/apiHelpers';

type Props = {
    isLoading: boolean;
    emptyScreenComponent: React.ReactNode;
    loadingComponent: React.ReactNode;
    setScrollPos: (scrollPos: number) => void;
};

export interface HeaderConfig {
    id: string;
    label: string;
    greyText?: boolean;
    wrapText?: boolean;
    hideLabel?: boolean;
    type?: string;
    hideRow?: boolean;
}

const DealsTable: React.FC<Props> = ({
    isLoading,
    emptyScreenComponent,
    loadingComponent,
    setScrollPos,
}) => {
    const [headersConfig, setHeadersConfig] = useState<HeaderConfig[] | null>(null);
    const [collapsibleHeadersConfig, setCollapsibleHeadersConfig] = useState<HeaderConfig[] | null>(
        null,
    );
    const [lendersHeaders, setLendersHeaders] = useState<HeaderConfig[] | null>(null);

    const tableRef = useRef<HTMLDivElement>(null);

    const dealsData = useAppSelector((state) => state.investor.deals);
    const user = useAppSelector((state) => state.user);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const totalDealsCount = dealsData?.dealCount?.totalDeals ?? 0;

    const userType = user.userData.accountType;

    const overlayScreen = useMemo(() => {
        if (isLoading && loadingComponent)
            return <div className={styles.Loading}>{loadingComponent}</div>;
        else if (totalDealsCount <= 0)
            if (emptyScreenComponent)
                return <div className={styles.Loading}>{emptyScreenComponent}</div>;
    }, [isLoading, loadingComponent, emptyScreenComponent, totalDealsCount]);

    const getHeaders = () => {
        if (userType === USER_TYPE.LENDER) return lendersHeaders;
        return headersConfig;
    };

    const fetchConfig = async () => {
        try {
            await getDealFormConfig(
                {
                    accountType: user.userData.accountType,
                    investorOrgId: platformData?.investorOrgId,
                    primaryEmail: user.userData?.primaryEmail,
                    configName: AICA_DEAL_NAVBAR_VIEW,
                },
                {
                    onSuccess: (configData, orgName) => {
                        setHeadersConfig(configData?.headers ?? []);
                        setCollapsibleHeadersConfig(configData?.collapsibleHeaders ?? []);
                        setLendersHeaders(configData?.lendersHeaders ?? []);
                    },
                },
            );
        } catch (error) {
            console.error('Error fetching deal form config:', error);
        }
    };

    useEffect(() => {
        fetchConfig();
    }, [platformData, user]);

    return (
        <div className={styles.Container}>
            <div
                className={styles.TableContainer}
                onScroll={(e: any) => setScrollPos(e.target.scrollTop)}
                ref={tableRef}
            >
                <table className={styles.Table}>
                    {!!!isLoading && totalDealsCount > 0 && (
                        <>
                            <thead className={styles.TableHead}>
                                <DealsHeader headers={getHeaders()} />
                            </thead>

                            <tbody className={styles.TableBody}>
                                <DealsRow
                                    headers={getHeaders()}
                                    collapsibleHeaders={collapsibleHeadersConfig}
                                    rows={dealsData.data}
                                />
                            </tbody>
                        </>
                    )}
                    {overlayScreen}
                </table>
            </div>
        </div>
    );
};

export default DealsTable;
