import React from 'react';
import styles from './SectorIndustryOutlook.module.scss';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { INVESTOR_VIEW_SECTOR_REPORT } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../../../../../utils/dateUtils';
import AIBadge from '../AIBadge/AIBadge';
import dayjs from 'dayjs';
import { ctaPurpleRight } from '../../../../../../assets/hostedassets';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import clsx from 'clsx';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import sectorIcon from './news-fallback.svg';

dayjs.extend(CustomParseFormat);

interface SectorIndustryOutlookProps {
    sectorInfo;
    accessType;
    isPDFReport?: boolean;
}

const FALLBACK_IMG = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/news-fallback.svg';

const SectorIndustryOutlook: React.FC<SectorIndustryOutlookProps> = ({
    sectorInfo,
    accessType = '',
    isPDFReport = false,
}) => {
    const onViewReportClick = (redirectUrl) => {
        Mixpanel.track(INVESTOR_VIEW_SECTOR_REPORT, {
            Timestamp: getTimeStamp(),
            AICASection: accessType,
        });
    };

    if (!sectorInfo?.reports?.length) return <></>;

    const reportsToShow = sectorInfo?.reports?.slice(0, 4);

    const render = (
        <div className={clsx(styles.SectorIndustryOutlook, { [styles.PDFReport]: isPDFReport })}>
            <div className={styles.Top}>
                <div className={styles.Title}>Sector / Industry Outlook</div>
                <div className={styles.Right}>
                    <AIBadge />
                </div>
            </div>

            <div className={styles.Body}>
                <div className={clsx(styles.Container)}>
                    {reportsToShow.map((report, i) => (
                        <div className={styles.SingleReport} key={i}>
                            <a href={report?.redirectUrl} target="_blank">
                                <div className={styles.Header}>
                                    <div className={styles.Image}>
                                        <img
                                            src={isPDFReport ? sectorIcon : FALLBACK_IMG}
                                            alt=""
                                            width={'100%'}
                                        />
                                    </div>

                                    {report?.publishedDate && (
                                        <div className={styles.Date}>
                                            {dayjs(report?.publishedDate, 'DD-MM-YYYY').format(
                                                'MMM YYYY',
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className={styles.Title}>{report?.highlight}</div>
                                {report?.publisherName && (
                                    <div className={styles.Publisher}>
                                        by {report?.publisherName}
                                    </div>
                                )}

                                <div className={styles.Text}>{report?.description}</div>

                                <a
                                    className={styles.cta}
                                    href={report?.redirectUrl}
                                    target="_blank"
                                    onClick={() => onViewReportClick(report?.redirectUrl)}
                                >
                                    <img src={ctaPurpleRight} alt="" height={'14px'} />
                                    View Report
                                </a>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    if (isPDFReport)
        return reportsToShow?.length &&
            reportSectionsToShow.includes(REPORT_SECTION_NAMES.SECTOR_OUTLOOK) ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.SECTOR_OUTLOOK}>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default SectorIndustryOutlook;
