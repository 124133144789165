import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { fetchBankStatementDataService } from './service';

export async function fetchBankStatementData(payload, handlers) {
    try {
        const response = await fetchBankStatementDataService();
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess(response.data?.bankData || {});
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError();
        console.error('Error in fetching bank statement data', err);
    }
}
