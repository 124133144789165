import React, { useState, useEffect } from 'react';
import DirectorCibil from '../DirectorCibil/DirectorCibil';
import ToggleButton from '../../../../../common/ToggleButton/ToggleButton';
import styles from './CreditBureauTab.module.scss';
import DirectorDetails from '../DirectorDetails/DirectorDetails';
import { USER_TYPE } from '../../../../../utils/constants/user';

export const DIRECT_CIBIL_DATA = 'directorCibilData';

const CreditBureauTab = ({
    directorCibilData,
    updateFunction,
    userType,
    assesseeOrgId,
    categoryId,
    flowId,
    managementData,
    isInvestor,
}) => {
    const [lenderConsent, setLenderConsent] = useState(
        directorCibilData?.lenderDirectorCibilConsent || false,
    );
    const [borrowerConsent, setBorrowerConsent] = useState(
        directorCibilData?.borrowerDirectorCibilConsent || false,
    );
    const [consent, setConsent] = useState<boolean>(false);
    const hasCibiReportsData = directorCibilData?.lenderDirectorCibilReports?.filter(
        (report) => !report?.isDeleted,
    );
    useEffect(() => {
        setLenderConsent(directorCibilData?.lenderDirectorCibilConsent || false);
        setBorrowerConsent(directorCibilData?.borrowerDirectorCibilConsent || false);
    }, [directorCibilData]);

    useEffect(() => {
        setLenderConsent(directorCibilData?.lenderDirectorCibilConsent || false);
        setBorrowerConsent(directorCibilData?.borrowerDirectorCibilConsent || false);
    }, [directorCibilData]);

    const updateLenderCibilConsent = () => {
        const newConsentValue = !lenderConsent;
        setLenderConsent(newConsentValue);
        updateFunction(
            {
                lenderDirectorCibilConsent: newConsentValue,
            },
            DIRECT_CIBIL_DATA,
        );
    };

    return (
        <>
            {userType === USER_TYPE.INVESTOR ? (
                <div className={styles.Top}>
                    We have consent to fetch bureau details for directors
                    <ToggleButton
                        checked={borrowerConsent ? borrowerConsent : lenderConsent}
                        onChange={updateLenderCibilConsent}
                        disabled={
                            (lenderConsent || borrowerConsent) &&
                            (directorCibilData?.lenderDirectorCibilReports?.length > 0 ||
                                consent ||
                                managementData?.some((item) => item.cibilConsentStatus === true) ||
                                directorCibilData?.borrowerDirectorVerified)
                        }
                    />
                </div>
            ) : (
                <div></div>
            )}

            {(lenderConsent || userType === USER_TYPE.COMPANY) && (
                <>
                    <DirectorCibil
                        isInvestor={isInvestor}
                        directorCibilData={directorCibilData}
                        userType={userType}
                        updateFunction={updateFunction}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                    {userType === USER_TYPE.INVESTOR ? (
                        <>
                            <div className={styles.Separator}>
                                <div className={styles.Line}></div>
                                <div>Or</div>
                                <div className={styles.Line}></div>
                            </div>

                            <div
                                style={isInvestor ? { width: '100%', border: 'none' } : {}}
                                className={styles.Equafix}
                            >
                                <DirectorDetails isInvestorSide={true} setConsent={setConsent} />
                            </div>
                        </>
                    ) : (
                        <div></div>
                    )}
                </>
            )}
        </>
    );
};

export default CreditBureauTab;
