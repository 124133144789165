import { useParams, useSearchParams } from 'react-router-dom';
import { SendNeedInfoReminder } from '../../MagicUpload/MagicUploadHelper';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { WRITE } from '../../MagicUpload/Utils/MagicUploadConstants';
import { fetchUserFromOrgId } from '../../../store/investor/investorApiHelper';
import { InternalSummonUserType } from '../../../store/user/actions';
import { updateQueryParams } from '../../../utils/searchParamUtils';
import { useAppSelector } from '../../../app/hooks';

const useNeedInfoDrawerLogic = (setMiniDrawerOpen) => {
    const platformUserId = sessionStorage.getItem('platform_user_id') ?? localStorage.getItem('platform_user_id');
    const user = useAppSelector((state) => state.user.userData);
    const [userList, setUserList] = useState<{ [key: string]: string }[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [communicationMethod, setCommunicationMethod] = useState<{
        [key: string]: boolean;
    }>({
        EMAIL: false,
    });
    const params = useParams();
    const toggleMiniDrawer = () => {
        setMiniDrawerOpen((prev) => !prev);
    };
    const resetCommunicationMethods = () => {
        const resetState = {};
        Object.keys(communicationMethod).forEach((key) => {
            resetState[key] = false;
        });
        setCommunicationMethod(resetState);
        setLoading(false);
    };

    const sendNotification = async () => {
        setLoading(true);
        const payload = {
            assesseeOrgId: params.id as string,
            notificationMediums: Object.keys(communicationMethod).filter((key) => communicationMethod[key]),
            notificationMode: WRITE,
            notificationSenderUserId: platformUserId,
            parentOrgId: user.investorOrgId,
        };
        await SendNeedInfoReminder(payload, {
            onSuccess: () => {
                toast.success('Reminder sent successfully');
                updateQueryParams(
                    searchParams,
                    setSearchParams,
                    {
                        disableSendReminder: true,
                    },
                    location,
                );
                resetCommunicationMethods();
            },
            onError: () => {
                toast.error('Failed to send reminder');
                resetCommunicationMethods();
            },
        });
    };

    const getOrgUserList = async () => {
        const res = await fetchUserFromOrgId(params.id as string, InternalSummonUserType.ASSESSEE, true);
        if (res) {
            setUserList(res);
        }
    };

    useEffect(() => {
        getOrgUserList();
    }, [params.id]);

    return {
        toggleMiniDrawer,
        setCommunicationMethod,
        communicationMethod,
        sendNotification,
        userList,
        loading,
        getOrgUserList,
    };
};

export default useNeedInfoDrawerLogic;
