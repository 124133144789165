import axios from 'axios';
import { CustomRequest, GET, polusBaseUrl, POST } from '../../../utils/axios-interceptor';

export const fetchAlertNumber = (data: any) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${polusBaseUrl}/alerts/getMetaData`,
        data: data,
        headers: {
            ['Content-Type']: 'application/json',
        },
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const fetchAlerts = (data: any) => {
    const axiosPayload: CustomRequest = {
        method: GET,
        url: `${polusBaseUrl}/unifier/getAlerts`,
        params: {
            orgId: data.assesseOrgId,
            parentOrgId: data.parentOrgId,
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            alertTypes: data.alertTypes.join(','),
        },
        headers: {
            ['Content-Type']: 'application/json',
        },
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};
