export const COLORS = {
    // Backgrounds
    LIGHT_BG: '#F5F5F5',
    SUCCESS_LIGHT_BG: '#E6F7DD',
    LIGHT_GREY_BG: '#F7F7F7',

    LIGHT_RED_BG: '#FFEEEE',
    LIGHT_BLUE_BG: '#F3F8FF',
    DARK_GREY_BG: '#EBEBEB',
    PRIMARY_PURPLE: '#6021b3',

    // Text
    BLACK: '#000000',
    GREY: '#B4B4B4',
    DARK_GREY: '#4D4D4D',
    SUCCESS: '#49A71B',
    RED: '#DF666A',
    LIGHT_BLUE: '#2F8FFF',

    // Icons
    WHITE: '#FFFFFF',
};
