import React, { useEffect, useRef, useState } from 'react';
import styles from './ChargeDetails.module.scss';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../app/hooks';
import { DATA_FETCH_STATUS } from '../../../../../../store/dashboard/constants';
import { fetchChargeDetails } from '../../../../../../store/dashboard/creditBureau/actions';
import clsx from 'clsx';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import { TabModule } from '../../../../../../utils/constants/commonConstants';

interface ChargeDetailsProps {
    isPDFReport: boolean;
}

const CHARGEDETAILS_CONSTANTS = {
    CHARGE_HOLDER_NAME: 'Charge Holder Name',
    TOTAL_OPEN_CHARGES: 'Total Open Charges',
    TOTAL_CLOSED_CHARGES: 'Total Closed Charges',
    CHARGE_AMOUNT: 'Charge Amount',
};

const ChargeDetails: React.FC<ChargeDetailsProps> = ({ isPDFReport = false }) => {
    const dispatch = useDispatch();

    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);

    const [isLoading, setIsLoading] = useState(false);
    const chargeDetails = useAppSelector((state) => state.dashboard.creditBureau.mainData.chargeDetails);
    const rowData = chargeDetails?.plots?.[0]?.rowData;

    let pollingIntervalRef: any = useRef(null);

    useEffect(() => {
        fetchData(true);
    }, []);

    useEffect(() => {
        if (modulePollingList.includes(TabModule.OVERVIEW)) {
            fetchData();
        }
    }, [modulePollingList]);

    useEffect(() => {
        if (chargeDetails?.metadata?.status === DATA_FETCH_STATUS.PROCESSING) {
            pollingIntervalRef.current = setInterval(() => {
                fetchData();
            }, 5000);
        } else {
            clearInterval(pollingIntervalRef?.current);
        }

        return () => {
            clearInterval(pollingIntervalRef?.current);
        };
    }, [chargeDetails?.metadata?.status]);

    const fetchData = (isPolling = false) => {
        if (!isPolling) setIsLoading(true);
        dispatch(
            fetchChargeDetails(
                {
                    graphNames: ['mcaCharges'], // @todo: change this hardcoding to store value in future. Since the credit beaureu config is hardcoded in Investor Saga Line No. 30.
                    tabName: 'compliances',
                    startDate: '',
                    endDate: '',
                    tenure: 'Monthly',
                },
                {
                    onSuccess: () => {
                        setIsLoading(false);
                    },
                    onError: () => {
                        setIsLoading(false);
                    },
                },
            ),
        );
    };

    const getBannerStatusColors = (status: string) => {
        let color = '';
        let banner = '';
        switch (status) {
            case 'OPEN':
                color = '#DF666A';
                banner = '#FDF0EC';
                break;
            case 'CLOSED':
                color = '#999999';
                banner = '#E7E7E7';
                break;
        }
        return {
            color,
            banner,
        };
    };

    function convertNumber(numStr) {
        let cleanedNumStr = numStr.replace(/[₹,\s]/g, '');
        let number = parseFloat(cleanedNumStr);

        if (isNaN(number)) {
            return numStr;
        }
        let result;
        if (number >= 1e7) {
            result = (number / 1e7).toFixed(2) + ' Cr';
        } else {
            result = (number / 1e5).toFixed(2) + ' L';
        }
        return result;
    }

    if (!rowData?.length) {
        return <></>;
    }

    const render = (
        <div className={clsx(styles.ChargeDetails, { [styles.PDFReport]: isPDFReport })}>
            <div className={styles.Top}>
                <div className={styles.Title}>Charge Details</div>
                <div className={styles.Right}></div>
            </div>

            <div className={styles.Body}>
                <div className={styles.Table} style={{ ...(isPDFReport && { fontSize: '11px' }) }}>
                    <div
                        className={clsx({
                            [styles.Wrap]: isPDFReport,
                            [styles.ChargesHeaders]: true,
                        })}
                    >
                        {chargeDetails?.headers?.map((header, index) => {
                            return (
                                <div
                                    key={index}
                                    className={clsx({
                                        [styles.AmountHeader]: header.name === CHARGEDETAILS_CONSTANTS.CHARGE_AMOUNT,
                                    })}
                                >
                                    {header.name}
                                </div>
                            );
                        })}
                    </div>
                    {rowData?.map((row, index) => {
                        const chargeHolderName = row.amounts.find((amount) => amount.xValue === CHARGEDETAILS_CONSTANTS.CHARGE_HOLDER_NAME)
                            ?.rowVal;
                        const isTotalOpenCharges = chargeHolderName === CHARGEDETAILS_CONSTANTS.TOTAL_OPEN_CHARGES;
                        const isTotalClosedCharges = chargeHolderName === CHARGEDETAILS_CONSTANTS.TOTAL_CLOSED_CHARGES;
                        return (
                            <div
                                className={clsx(
                                    {
                                        [styles.TotalsRow]: isTotalOpenCharges || isTotalClosedCharges,
                                        [styles.FixHeight]: !isPDFReport,
                                    },
                                    styles.ChargesRows,
                                )}
                                key={index}
                            >
                                {row?.amounts?.map((rowItem, j) => {
                                    return (
                                        <div
                                            className={clsx(
                                                {
                                                    [styles.Amount]: rowItem?.xValue === CHARGEDETAILS_CONSTANTS.CHARGE_AMOUNT,
                                                    [styles.Total]:
                                                        (isTotalOpenCharges || isTotalClosedCharges) &&
                                                        (rowItem?.xValue === CHARGEDETAILS_CONSTANTS.CHARGE_HOLDER_NAME ||
                                                            rowItem?.xValue === CHARGEDETAILS_CONSTANTS.CHARGE_AMOUNT),
                                                    [styles.NoWrap]: !isPDFReport,
                                                },
                                                styles.RowItem,
                                            )}
                                            key={`${rowItem.rowVal}-${j}`}
                                            style={
                                                rowItem.xValue === 'Status'
                                                    ? {
                                                          padding: '0.25rem 0.75rem',
                                                          borderRadius: '0.5rem',
                                                          width: 'fit-content',
                                                          background: getBannerStatusColors(rowItem.rowVal).banner,
                                                          color: getBannerStatusColors(rowItem.rowVal).color,
                                                          transform: isPDFReport ? 'scale(0.8)' : '',
                                                      }
                                                    : {
                                                          textOverflow: isPDFReport ? 'clip' : 'ellipsis',
                                                      }
                                            }
                                            title={rowItem.rowVal}
                                        >
                                            {rowItem.rowVal.startsWith('₹') ? convertNumber(rowItem.rowVal) : rowItem.rowVal}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

    if (isPDFReport)
        return rowData?.length && reportSectionsToShow.includes(REPORT_SECTION_NAMES.CHARGE_DETAILS) ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.CHARGE_DETAILS}>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default ChargeDetails;
