import React, { useEffect, useState, useRef } from 'react';
import styles from './PendingDataPill.module.scss';
import clsx from 'clsx';
import TopRightArrowIcon from '../../../../assets/Svg/TopRightArrowIcon';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import ArrowRight from '../../../../assets/arrow-right.svg';
interface PendingDataPill {
    mainText?: string;
    timeText?: string;
    showSpinner?: boolean;
    isGenerating?: boolean;
    ctaText?: string;
    isLoading?: boolean;
    showPendingData?: boolean;
    onClick?: () => void;
}

const PendingDataPill = ({
    mainText,
    timeText,
    ctaText,
    showSpinner = true,
    isGenerating = true,
    isLoading = false,
    showPendingData = false,
    onClick,
}: PendingDataPill) => {
    const [isComplete, setIsComplete] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    // Handle text content transitions
    const [displayedMainText, setDisplayedMainText] = useState(mainText);
    const [displayedCtaText, setDisplayedCtaText] = useState(ctaText);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // Simplified text transition effect
    useEffect(() => {
        if (mainText !== displayedMainText || ctaText !== displayedCtaText) {
            setIsTransitioning(true);

            let fadeInTimer: NodeJS.Timeout;
            const fadeOutTimer = setTimeout(() => {
                setDisplayedMainText(mainText);
                setDisplayedCtaText(ctaText);

                fadeInTimer = setTimeout(() => {
                    setIsTransitioning(false);
                }, 300);
            }, 300);

            return () => {
                clearTimeout(fadeOutTimer);
                if (fadeInTimer) {
                    clearTimeout(fadeInTimer);
                }
            };
        }
    }, [mainText, ctaText]);

    // Simplified loading effect that ensures 5 second animation
    useEffect(() => {
        if (isLoading && !isAnimating) {
            setIsAnimating(true);
            setIsComplete(false);

            const timer = setTimeout(() => {
                setIsComplete(true);
                setIsAnimating(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isLoading]);

    const [displayedShowSpinner, setDisplayedShowSpinner] = useState(showSpinner);
    const [isIconTransitioning, setIsIconTransitioning] = useState(false);

    // Add icon transition effect
    useEffect(() => {
        if (showSpinner !== displayedShowSpinner) {
            setIsIconTransitioning(true);
            const fadeOutTimer = setTimeout(() => {
                setDisplayedShowSpinner(showSpinner);
                const fadeInTimer = setTimeout(() => {
                    setIsIconTransitioning(false);
                }, 300);
                return () => clearTimeout(fadeInTimer);
            }, 300);
            return () => clearTimeout(fadeOutTimer);
        }
    }, [showSpinner]);

    return (
        <div
            onClick={onClick}
            className={clsx(styles.Generating, {
                [styles.loading]: isAnimating,
                [styles.completed]: isComplete || (!isLoading && !isAnimating),
            })}
        >
            <div className={styles.Left}>
                {displayedMainText && (
                    <div
                        className={clsx(styles.MainText, {
                            [styles.transitioning]: isTransitioning,
                        })}
                    >
                        {displayedMainText}
                        <span
                            className={clsx(styles.Ellipsis, {
                                [styles.animating]: isGenerating,
                            })}
                        />
                    </div>
                )}
            </div>

            <div className={styles.Right}>
                {displayedCtaText && (
                    <span
                        className={clsx(styles.CtaText, {
                            [styles.transitioning]: isTransitioning,
                        })}
                    >
                        {displayedCtaText}
                    </span>
                )}

                {(showSpinner || showPendingData) && (
                    <div
                        className={clsx(styles.IconWrapper, {
                            [styles.transitioning]: isIconTransitioning,
                        })}
                    >
                        {displayedShowSpinner ? (
                            <LoadingSpinner color="var(--primary-text-colour)" height="24px" />
                        ) : (
                            <img
                                src={ArrowRight}
                                alt="Right Arrow"
                                style={{ width: '24px', height: '24px' }}
                            />
                        )}
                    </div>
                )}
            </div>
            {timeText && <div className={styles.Time}>{timeText}</div>}
        </div>
    );
};

export default PendingDataPill;
