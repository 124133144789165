import LoadingState from './components/LoadingState';
import styles from './AicaOverview.module.scss';
import useAicaOverview, { AicaOverviewData } from './useAicaOverview';
import AicaOverviewBody from './components/AicaOverviewBody/AicaOverviewBody';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

type AicaOverviewProps = {
    module: string;
    resourceId?: string;
    style?: React.CSSProperties;
    isPDFReport?: boolean;
    setAicaOverviewExists?: (exists: boolean) => void;
};

const AicaOverview = (props: AicaOverviewProps) => {
    const { module, resourceId, style = {}, isPDFReport = false, setAicaOverviewExists } = props;
    const { data, isLoading, error, feedback, sendFeedbackForOverview }: any = useAicaOverview(
        module,
        resourceId,
        isPDFReport,
    );

    useEffect(() => {
        if (isEmpty(data.observations) && isEmpty(data.risks) && isEmpty(data.recommendations)) {
            setAicaOverviewExists?.(false);
        } else {
            setAicaOverviewExists?.(true);
        }
    }, [data.observations, data.risks, data.recommendations]);

    // Early return if there's no data to display
    if (
        !isLoading &&
        isEmpty(data.observations) &&
        isEmpty(data.risks) &&
        isEmpty(data.recommendations)
    ) {
        return null;
    }

    const renderContent = () => {
        if (isLoading)
            return (
                <div className={styles.AicaOverviewWrapper}>
                    <LoadingState />
                </div>
            );
        if (!isLoading && data) {
            return (
                <AicaOverviewBody
                    data={data as AicaOverviewData}
                    feedback={feedback}
                    sendFeedbackForOverview={sendFeedbackForOverview}
                    isPDFReport={isPDFReport}
                />
            );
        }
    };

    return (
        <>
            {!error && (
                <div
                    style={{ ...style }}
                    className={clsx({ [styles.AnimationContainer]: !isPDFReport })}
                >
                    {renderContent()}
                </div>
            )}
        </>
    );
};

export default AicaOverview;
