import axios from 'axios';
import { polusBaseUrl, POST, uwBaseUrl } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function fetchBankStatementFromCashflowService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_BANK_STMT_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            // "endDate" : "2024-01-31",
            csv: false,
        },
    });
}

export function fetchFinancialMetricsServicePolus(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_FINANCIAL_METRICS_DATA_POLUS,
        method: POST,
        data: {
            requestPayload: {
                requestId: getTimeStamp(),
                orgId: payload?.orgId,
                type: 'auditedfinancials',
                startDate: payload?.startDate,
                endDate: payload?.endDate,
                frequency: 'Yearly',
                metricList: payload?.metricsList,
                taskStepsList: ['AION_AUDITED_FINANCIAL'],
                mis: 'Balance Sheet',
                raw: true,
                classificationId: 30,
            },
        },
    });
}

const API_ENDPOINTS = {
    FETCH_BANK_STMT_DATA: '/data/bankStatementPivotData',
    FETCH_FINANCIAL_METRICS_DATA: '/financials/getMetrics',
    FETCH_FINANCIAL_METRICS_DATA_POLUS: '/unifier/getAicaFinancialMetrics',
};
