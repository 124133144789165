import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './Dashboard.module.scss';
import TabSelector from '../../common/TabSelector/TabSelector';
import { fetchAicaGradeData, fetchGraphData, fetchHeadlineData, intializeChartsAction } from '../../store/dashboard/actions';
import DataSource from './components/DataSource/DataSource';
import StatCard from './components/StatCard/StatCard';
import Graph from './components/Graph/Graph';
import Overview from './DashboardModules/Overview/Overview';
import {
    fetchSingleCompanyDetails,
    setShowInviteUserModal,
    setShowRefreshReport,
    setShowUnlockAicaAndAddToLeads,
    setShowUnlockAicaConfirmation,
    setShowUpgradeToScan,
    updateNeedInfoFileNumber,
} from '../../store/investor/action';
import { USER_TYPE } from '../../utils/constants/user';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { initializeDatepickerDates } from '../../utils/dateUtils';
import { FEATURES } from '../../store/user/constants';
import CreditBureau from './DashboardModules/CreditBureau/CreditBureau';
import AlertEngine from './components/AlertEngine/AlertEngine';
import { Mixpanel } from '../../utils/mixpanel';
import { AICA_RELATED_PARTY } from '../../utils/constants/mixpanelEvents/investorEvents';
import TypeSelector from './components/TypeSelector/TypeSelector';
import BankDataTable from './DashboardModules/BankDataTable/BankDataTable';
import Financials from './DashboardModules/Financials/Financials';
import chartIcon from '../../assets/chart.svg';
import tableIcon from '../../assets/table.svg';
import UnlockScanModal from './components/UnlockScanModal/UnlockScanModal';
import { ACCESS_TIERS, ACCESS_TYPES, UnlockAICASourcePage } from '../../store/investor/constants';
import UnlockScanButton from './DashboardModules/Overview/components/Buttons/UnlockScan/UnlockScanButton';
import AddToScanButton from './DashboardModules/Overview/components/Buttons/AddToScan/AddToScanButton';
import InviteCompanyModal, { INVITE_SCREENS } from './components/InviteCompanyModal/InviteCompanyModal';
import OperatingInflowReconciliation from './DashboardModules/Reconciliation/OperatingInflowReconciliation/OperatingInflowReconciliation';
import DashboardHeader from './components/DashboardHeader/DashboardHeader';
import {
    mixpanelDashboardTabUpdates,
    mixpanelRegisterDataTypeChange,
    mixpanelRegisterTabChangeClick,
} from '../../store/dashboard/dashboard_mixpanel';
import ManageDealDrawer from '../../common/ManageDealDrawer/ManageDealDrawer';
import { pendingDataComplete, PendingDataIncomplete } from '../../assets/hostedassets';
import { createPortal } from 'react-dom';
import RelatedParties from './DashboardModules/RelatedParties/RelatedParties';
import ConfirmationPopup from '../../common/ConfirmationPopup/ConfirmationPopup';
import PortfolioSuccess from '../Investor/Portfolio/components/PortfolioSuccess/PortfolioSuccess';
import FinancialMetrics from './DashboardModules/Overview/components/FinancialMetrics/FinancialMetrics';
import { moveTierAndUnlockAICAReport } from '../../store/investor/apiHelpers';
import AicaOverview from '../../common/AicaOverview/AicaOverview';
import LegalCases from './components/DashboardTables/LegalCases/LegalCases';
import RevenueTables from './DashboardModules/RevenueTables/RevenueTables';
import { getDataGapDocs } from '../../common/MagicUpload/MagicUploadHelper';
import clsx from 'clsx';
import DebtDetails from './DashboardModules/DebtDetails/DebtDetails';
import useLegacyBusinessDataFetch from '../DataRoom/useLegacyBusinessDataFetch';
import A4Page from '../AicaPDFReport/A4Page/A4Page';
import { reportSectionsToShow } from '../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../store/pdfReport/pdfReport_constants';
import { getDebtProductsData } from '../../store/debtProducts/apiHelper_debtProducts';
import RefreshReport from './components/RefreshReport/RefreshReport';
import { unlockOrRefreshReport } from '../../store/investor/investorApiHelper';
import { closeCommentsThread } from '../../store/commentsThread/commentsThread_actions';

/**
 * @Description Dashboard landing component
 */

export const DASHBOARD_DATA_TYPES = {
    CHARTS: 'CHARTS',
    TABLE: 'TABLE',
};

export const dataTypeSelectorOptions = [
    {
        id: DASHBOARD_DATA_TYPES.CHARTS,
        icon: chartIcon,
        label: 'Chart',
    },
    {
        id: DASHBOARD_DATA_TYPES.TABLE,
        icon: tableIcon,
        label: 'Table',
    },
];

function Dashboard({ pdfReportSection = '', isPDFReport = false }) {
    const params = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedTabIdQuery = searchParams.get('selectedTab');
    const showPortfolioSuccess = searchParams.get('portfolioSuccess') === 'true';
    const showFinancialMetrics = searchParams.get('showFinancialMetrics');
    const dataTypeQuery = searchParams.get('dataType');
    const refreshReportQueryParam = searchParams.get('showRefreshReport');
    const investorData = useAppSelector((state) => state.investor);
    const dashboard = useAppSelector((state) => state.dashboard);
    const periodSelectorData = useAppSelector((state) => state.periodSelector);

    const showDealDrawer = investorData.dealDrawer.open;
    const source = investorData.dealDrawer.drawerSource;
    const unlockAicaReportDetails = investorData.unlockAicaReport;
    const unlockAicaAndAddToLeadsConfirmation = investorData.unlockAicaReportAndAddToLeads;
    const permissions = investorData.permissions;

    const dashboardDataNew = dashboard.dashboardReducer;
    const aicaGradeData = dashboardDataNew.aicaGradeData;
    const metricScoreGenerated = aicaGradeData.metricScoreGenerated ?? false;

    const [dashboardTab, setDashboardTab] = useState({
        selectedTab: '',
        tabType: '',
    });
    const {
        goBackToPortfolio,
        selectedCompany,
        setSelectedCompany,
        exportData,
        user,
        isUserRestricted,
        refreshReportStatus,
        downloadPDFStatus,
        refetchOverviewRef,
    } = useLegacyBusinessDataFetch({
        fetchAicaGrade: true,
    });
    const [dataType, setDataType] = useState(DASHBOARD_DATA_TYPES.CHARTS);
    const [showInviteCompanyModal, setShowInviteCompanyModal] = useState(false);
    const [showPDFReportSection, setShowPDFReportSection] = useState(true);
    const [triggerMain, setTriggerMain] = useState(false);

    const header = document.getElementById('header') ?? document.createElement('div');

    const isScanLockedForUser = permissions?.[ACCESS_TIERS.SCAN] === ACCESS_TYPES.LOCKED;
    const isDemoProfile = !!selectedCompany?.demoOrg;

    const dashboardConfig: any = investorData.config;
    const headlinePanelData = dashboardDataNew?.headlineData?.data;
    const dataSource = dashboardDataNew?.dataSource || {};

    const selectedTabConfig = dashboardConfig?.tabConfigs?.[dashboardTab.selectedTab];

    const chartsToShow = selectedTabConfig?.chartPanel?.cards || [];
    const chartsData = dashboardDataNew?.charts || {};
    const showAlerts = !!user.domainMetadata?.availableFeatures?.includes(FEATURES.ALERTS);
    const showUpgradeToScanModal = investorData.showUpgradeToScan;
    const showRefreshReport = investorData.showRefreshReport;
    const showDataGapCTA = permissions.showDataGap;

    const HEADLINE_PANEL_CONSTANTS = {
        OPEN_LEGAL_CASES: 'Open Legal Cases',
        COMPLIANCES: 'compliances',
    };

    const isGSTTab = ['revenue', 'vendorExpenses'].includes(dashboardTab.selectedTab);
    const generateSeparateOverview = ['overview', 'creditBureau', 'auditedFinancials'];
    const noGSTConnected = dataSource?.[1]?.text?.startsWith('0');

    const headlinePanelDataPollingRef: any = useRef();

    const initialiseHeadlinePanelPolling = () => {
        if (!headlinePanelDataPollingRef.current) {
            fetchHeadlinePanelData(false);
            headlinePanelDataPollingRef.current = setInterval(() => {
                fetchHeadlinePanelData(true);
            }, 10000);
        }
    };
    const pendingCount = useAppSelector((state) => state.investor.pendingDataCount);

    const getPendingDocumentsCount = async () => {
        await getDataGapDocs(
            {
                assesseeOrgId: params.id ?? '',
            },
            {
                onSuccess: (res) => {
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                },
            },
        );
    };

    useEffect(() => {
        if (pdfReportSection) {
            setDashboardTab({
                tabType: 'dynamic',
                selectedTab: pdfReportSection,
            });
        }
    }, [pdfReportSection]);

    const getDebtProductsDataToSaveInCache = async () => {
        const payload = {
            orgId: params.id as string,
            aggregatorId: user.userData.investorOrgId,
        };
        await getDebtProductsData(payload);
    };

    useEffect(() => {
        getPendingDocumentsCount();
        getDebtProductsDataToSaveInCache();

        return () => {
            dispatch(closeCommentsThread());
        };
    }, []);

    useEffect(() => {
        if (!showDealDrawer) {
            setTriggerMain(true);
        } else {
            setTriggerMain(false);
        }
    }, [showDealDrawer]);

    useEffect(() => {
        if (noGSTConnected && isGSTTab) setDataType(DASHBOARD_DATA_TYPES.TABLE);
    }, [noGSTConnected, dashboardTab]);

    const closeHeadlinePanelPolling = () => {
        clearInterval(headlinePanelDataPollingRef.current);
        headlinePanelDataPollingRef.current = null;
    };

    useEffect(() => {
        if (dataTypeQuery) setDataType(dataTypeQuery);
    }, [dataTypeQuery]);

    useEffect(() => {
        if (dashboardConfig?.selectedTabId && headlinePanelDataPollingRef.current) {
            closeHeadlinePanelPolling();
        }

        if (dashboardTab.selectedTab === 'relatedParty') {
            Mixpanel.track(AICA_RELATED_PARTY, {
                parentId: user.userData.investorOrgId,
                websiteUrl: window.location.href,
                companyOrgId: params.id,
            });
        }

        return () => {
            closeHeadlinePanelPolling();
        };
    }, [dashboardTab.selectedTab]);

    useEffect(() => {
        if (params.id) {
            Mixpanel.register({
                AssesseeOrgId: params.id,
            });
        }
    }, [params.id]);

    useEffect(() => {
        if (refreshReportQueryParam) {
            deleteFromSearchParams('showRefreshReport');
            dispatch(setShowRefreshReport(true));
        }
    }, [refreshReportQueryParam]);

    useEffect(() => {
        if (dashboardConfig?.tabs?.length && (selectedTabIdQuery || dashboardConfig?.selectedTabId)) {
            const selectedTabFromApi =
                dashboardConfig?.selectedTabId && dashboardConfig?.tabs?.find((tab) => tab.id === dashboardConfig?.selectedTabId);
            const selectedTabFromQuery = selectedTabIdQuery && dashboardConfig?.tabs?.find((tab) => tab.id === selectedTabIdQuery);
            !pdfReportSection &&
                setDashboardTab({
                    selectedTab: selectedTabFromQuery ? selectedTabFromQuery?.id : selectedTabFromApi?.id,
                    tabType: selectedTabFromQuery ? selectedTabFromQuery?.type : selectedTabFromApi?.type,
                });
        }
    }, [dashboardConfig?.selectedTabId, dashboardConfig?.tabs, selectedTabIdQuery]);
    useEffect(() => {
        if (dashboardTab.selectedTab && params.id) {
            appendSearchParams({ selectedTab: dashboardTab.selectedTab });
            dispatch(
                intializeChartsAction({
                    charts: chartsToShow,
                }),
            );
            fetchHeadlinePanelData();
        }
        //eslint-disable-next-line
    }, [dashboardTab.selectedTab, params.id, dataType]);

    useEffect(() => {
        if (dataType && dashboardTab.selectedTab) {
            mixpanelDashboardTabUpdates(dataType, dashboardTab, selectedCompany, user);
        }
    }, [dataType, dashboardTab.selectedTab]);

    useEffect(() => {
        //* If charts data is absent for the section, then PDF report section will be removed
        if (chartsToShow?.length && pdfReportSection && Object.keys(chartsData)?.length) {
            let allDataUnavailable = true;
            for (const chartName of chartsToShow) {
                if (chartsData[chartName]?.isFetched && chartsData[chartName]?.plots?.length) {
                    allDataUnavailable = false;
                    break;
                }
            }
            setShowPDFReportSection(!allDataUnavailable);
        }
    }, [chartsData, pdfReportSection, chartsToShow]);

    const appendSearchParams = (paramsToAppend) => {
        const existingParams = Object.fromEntries(searchParams.entries());
        const updatedParams = {
            ...existingParams,
            ...paramsToAppend,
        };
        setSearchParams(updatedParams, { state: location.state });
    };

    const deleteFromSearchParams = (paramKeyToRemove: string) => {
        let existingParams = Object.fromEntries(searchParams.entries());
        delete existingParams[paramKeyToRemove];
        setSearchParams(existingParams, { state: location.state });
    };

    const getGridRowHeight = (chartName, index) => {
        const pos = Math.floor(index / 2);
        const data = chartsData[chartName];
        const tileSize = data?.metadata?.tileSize || 0;
        if (tileSize > 1) return `${pos + 1} / ${pos + 1 + tileSize}`;
    };

    const getStaticComponent = () => {
        let TabComponent;

        switch (dashboardTab.selectedTab) {
            case 'overview':
                TabComponent = (
                    <Overview
                        selectedCompany={selectedCompany}
                        refetchOverviewRef={refetchOverviewRef}
                        appendSearchParams={appendSearchParams}
                        triggerMain={triggerMain}
                    />
                );
                break;
            case 'relatedParty':
                TabComponent = <RelatedParties />;
                break;
            case 'creditBureau':
                TabComponent = (
                    <CreditBureau
                        selectedTabConfig={selectedTabConfig}
                        getGridRowHeight={getGridRowHeight}
                        selectedCompany={selectedCompany}
                    />
                );
                break;
            case 'auditedFinancials':
                TabComponent = <Financials />;
                break;
            case 'reconciliation':
                TabComponent = <OperatingInflowReconciliation assesseeOrgId={params.id} />;
                break;
            case 'legalChecks':
                TabComponent = (
                    <LegalCases
                        assesseeOrgId={params.id ?? ''}
                        companyName={selectedCompany?.orgName || ''}
                        initialiseHeadlinePanelPolling={initialiseHeadlinePanelPolling}
                    />
                );
                break;
            case 'debtDetails':
                TabComponent = <DebtDetails />;
                break;
            default:
                TabComponent = <></>;
        }

        return (
            <>
                {!generateSeparateOverview.includes(dashboardTab.selectedTab) && (
                    <AicaOverview style={{ marginBottom: '1.5rem' }} module={dashboardTab.selectedTab} />
                )}
                {TabComponent}
            </>
        );
    };

    const fetchHeadlinePanelData = (isPolling = false) => {
        const [fromDate, previousMonthLastDate] = initializeDatepickerDates(periodSelectorData.selectedPeriod.name);
        dispatch(
            fetchHeadlineData(
                {
                    tabName: dashboardTab.selectedTab,
                    startDate: fromDate,
                    endDate: previousMonthLastDate,
                    tenure: periodSelectorData.selectedPeriod.name || '',
                    reportId: selectedCompany?.reportId || '',
                },
                { isPolling: isPolling },
            ),
        );
    };

    const fetchGraph = (graphName, isPollingCall = false) => {
        const [fromDate, toDate] = initializeDatepickerDates(periodSelectorData.selectedPeriod.name);
        const graphNameAry = [graphName];
        dispatch(
            fetchGraphData(
                {
                    graphNames: graphNameAry,
                    tabName: dashboardTab.selectedTab,
                    startDate: fromDate,
                    endDate: toDate,
                    tenure: periodSelectorData.selectedPeriod.name,
                    reportId: selectedCompany?.reportId || '',
                    // graphType: props.graphData.type,
                },
                {
                    isPollingCall: isPollingCall,
                },
            ),
        );
    };

    const getTableData = () => {
        switch (dashboardTab.selectedTab) {
            case 'bankingAndFinancials':
                return <BankDataTable />;
            case 'revenue':
            case 'vendorExpenses':
                return (
                    <>
                        <RevenueTables
                            tab={dashboardTab.selectedTab}
                            dataSource={dataSource}
                            fetchHeadlinePanelData={fetchHeadlinePanelData}
                        />
                        {/*<RevenueVendorExpenseTable tab={dashboardTab.selectedTab} />*/}
                    </>
                );
        }
    };

    const onUnlockReportSuccess = (callbackFunction = () => {}) => {
        refetchOverviewRef.current = true;
        dispatch(
            fetchSingleCompanyDetails(
                {
                    assesseeOrgId: params.id,
                    investorOrgId: user.domainMetadata?.investorOrgId,
                },
                {
                    onSuccess: (companyData) => {
                        callbackFunction?.();
                        setSelectedCompany(companyData);
                    },
                },
            ),
        );
        dispatch(
            fetchAicaGradeData({
                assesseeOrgId: params.id as string,
                investorOrgId: user.domainMetadata?.investorOrgId as string,
            }),
        );
    };

    const shouldRenderSecondA4Page = () => {
        const hasStatCardData = Object.keys(headlinePanelData || {}).some(
            (statName) => statName !== 'legalCases' && headlinePanelData?.[statName]?.value,
        );

        // Check if any Graph has data
        const hasGraphData = chartsToShow.some((chartName) => {
            const chartData = chartsData[chartName];
            return chartData?.isFetched ? chartData?.plots?.[0]?.plotValues?.length || chartData?.plots?.[0]?.yearMonthMap : true;
        });
        return hasStatCardData || hasGraphData;
    };

    const renderHeaderButton = () => {
        if (isUserRestricted === false) {
            if (isScanLockedForUser) return <UnlockScanButton onClick={() => dispatch(setShowUpgradeToScan(ACCESS_TIERS.SCAN))} />;
            else if (selectedCompany?.companyAccessType == ACCESS_TIERS.WATCH)
                return (
                    <AddToScanButton
                        onClick={() => {
                            dispatch(
                                setShowUnlockAicaAndAddToLeads({
                                    source: UnlockAICASourcePage.DASHBOARD_HEADER,
                                    showModal: true,
                                }),
                            );
                        }}
                        accessType={selectedCompany?.companyAccessType}
                        isDemoProfile={isDemoProfile}
                    />
                );
            else if (user.userData.accountType === USER_TYPE.INVESTOR && !isDemoProfile && showDataGapCTA) {
                return (
                    <>
                        <div
                            className={clsx({
                                [styles.PedingCTA]: (pendingCount as number) > 0 || pendingCount === null,
                                [styles.PendingDone]: pendingCount === 0,
                            })}
                            onClick={() => {
                                searchParams.set('needInfo', 'true');
                                searchParams.set('showNeedInfo', 'true');
                                searchParams.set('isCompany', 'true');
                                setSearchParams(searchParams);
                            }}
                        >
                            <img src={pendingCount === 0 ? pendingDataComplete : PendingDataIncomplete} alt="pendingDataComplete" />
                            <span>{pendingCount === 0 ? 'No Data Pending' : `${pendingCount ?? ''} Data Pending`}</span>
                        </div>
                    </>
                );
            }
        }
    };

    const renderAicaOverview = () => {
        if (
            [
                'compliances',
                'relatedParty',
                'creditBureau',
                'auditedFinancials',
                'revenue',
                'vendorExpenses',
                'bankingAndFinancials',
            ].includes(dashboardTab.selectedTab)
        ) {
            return <AicaOverview style={{ marginTop: '1.5rem' }} module={dashboardTab.selectedTab} isPDFReport={!!pdfReportSection} />;
        }
    };

    const renderDynamicTabContent = (isPDFReport = false) => (
        <>
            {!isPDFReport && renderAicaOverview()}
            {dataType === DASHBOARD_DATA_TYPES.CHARTS ? (
                <>
                    {isGSTTab && noGSTConnected ? (
                        <div className={styles.NoGSTData}>
                            {dashboardTab.selectedTab === 'revenue' ? 'Revenue from GST' : 'Vendor Expenses from GST'}
                            <div className={styles.Right}>Only Available for Connected GSTs</div>
                        </div>
                    ) : (
                        <>
                            <div
                                className={clsx(
                                    {
                                        [styles.CompliancesStats]: dashboardTab.selectedTab === HEADLINE_PANEL_CONSTANTS.COMPLIANCES,
                                        [styles.StatsPDFReport]: isPDFReport,
                                    },
                                    styles.Stats,
                                )}
                            >
                                {dashboardDataNew.loadingHeadline
                                    ? Array.from({
                                          length: dashboardTab.selectedTab === HEADLINE_PANEL_CONSTANTS.COMPLIANCES ? 3 : 4,
                                      }).map((_, index) => (
                                          <StatCard
                                              data={{}}
                                              key={`placeholder-${index}`}
                                              statName={`placeholder-${index}`}
                                              isLoading={true}
                                              isPDFReport={!!pdfReportSection}
                                          />
                                      ))
                                    : headlinePanelData &&
                                      dashboardTab.selectedTab !== 'bankingAndFinancials' &&
                                      Object.keys(headlinePanelData || {})
                                          ?.filter(
                                              (statName) =>
                                                  headlinePanelData[statName]?.title !== HEADLINE_PANEL_CONSTANTS.OPEN_LEGAL_CASES,
                                          )
                                          .map((statName) => (
                                              <StatCard
                                                  data={headlinePanelData?.[statName]}
                                                  key={statName}
                                                  statName={statName}
                                                  isLoading={false}
                                                  isPDFReport={!!pdfReportSection}
                                              />
                                          ))}
                            </div>

                            <div
                                className={clsx(styles.GraphsContainer, {
                                    [styles.GraphsContainerPDFReport]: isPDFReport,
                                })}
                            >
                                {chartsToShow?.map((chartName, index) => (
                                    <div
                                        key={`${chartName}-${index}}`}
                                        style={{
                                            gridRow: getGridRowHeight(chartName, index),
                                        }}
                                    >
                                        {!!chartsData[chartName] &&
                                            (chartsData[chartName]?.isFetched && isPDFReport
                                                ? (chartsData[chartName]?.plots?.[0]?.plotValues || [])?.length ||
                                                  chartsData[chartName]?.plots?.[0]?.yearMonthMap
                                                : true) && (
                                                <Graph
                                                    key={`${chartName}-${index}`}
                                                    graphData={chartsData[chartName]}
                                                    graphName={chartName}
                                                    selectedPeriod={periodSelectorData.selectedPeriod}
                                                    fetchGraph={fetchGraph}
                                                    user={user}
                                                    showAlerts={showAlerts}
                                                    initialiseHeadlinePanelPolling={initialiseHeadlinePanelPolling}
                                                    isPDFReport={isPDFReport}
                                                />
                                            )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>{getTableData()}</>
            )}
        </>
    );
    const render = (
        <div
            className={clsx(styles.Dashboard, {
                [styles.Animate]: !pdfReportSection,
                [styles.PDFReport]: isPDFReport,
            })}
        >
            <div className={styles.PageWrapper}>
                <div className={styles.Sidebar}>
                    <AlertEngine selectedCompany={selectedCompany} currentTab={dashboardTab.selectedTab} />
                    <TabSelector
                        tabs={dashboardConfig?.tabs}
                        currentTab={dashboardTab.selectedTab}
                        setCurrentTab={(tab) => {
                            mixpanelRegisterTabChangeClick(tab?.id, selectedCompany);
                            setDataType(tab?.id === 'bankingAndFinancials' ? DASHBOARD_DATA_TYPES.TABLE : DASHBOARD_DATA_TYPES.CHARTS);
                            setDashboardTab({ selectedTab: tab?.id, tabType: tab?.type });
                        }}
                        companyAccessType={selectedCompany?.companyAccessType || ''}
                        profileGenerationStatus={selectedCompany?.profileGenerationStatus || ''}
                        unlockedAt={selectedCompany?.unlockAt || ''}
                    />
                </div>
                <div className={styles.MainContent}>
                    <div className={styles.PeriodSelectorRow}>
                        <div className={styles.PeriodSelectorRowLeft}>
                            {['bankingAndFinancials', 'revenue', 'vendorExpenses'].includes(dashboardTab.selectedTab) && (
                                <TypeSelector
                                    options={dataTypeSelectorOptions}
                                    selectedOptionId={dataType}
                                    changeSelectedOption={(id) => {
                                        mixpanelRegisterDataTypeChange(id, selectedTabIdQuery as string, selectedCompany);
                                        setDataType(id);
                                        appendSearchParams({ dataType: id });
                                    }}
                                />
                            )}
                        </div>
                        {dashboardTab.tabType === 'dynamic' && (
                            <DataSource
                                dataSource={dataSource}
                                isLoading={dashboardDataNew.loadingHeadline}
                                selectedTab={dashboardTab.selectedTab}
                                isPDFReport={!!pdfReportSection}
                            />
                        )}
                    </div>
                    {!!(dashboardTab.tabType === 'static' && selectedCompany) && <>{getStaticComponent()}</>}
                    {dashboardTab.tabType === 'dynamic' && <>{renderDynamicTabContent()}</>}
                </div>

                {showUpgradeToScanModal && (
                    <UnlockScanModal
                        showUpgradeToScanModal={showUpgradeToScanModal}
                        openModal={showUpgradeToScanModal}
                        closeModal={() => {
                            dispatch(setShowUpgradeToScan(''));
                        }}
                    />
                )}

                {showInviteCompanyModal && (
                    <InviteCompanyModal
                        screen={INVITE_SCREENS.DASHBOARD}
                        modalState={showInviteCompanyModal}
                        closeModal={() => setShowInviteCompanyModal(false)}
                        assesseeOrgId={params.id}
                        investorOrgId={user.userData.investorOrgId}
                        platformId={user.domainMetadata?.productName}
                        selectedCompany={selectedCompany}
                        companyAccessType={selectedCompany?.companyAccessType}
                        openInviteUserModal={() => dispatch(setShowInviteUserModal(true))}
                    />
                )}

                {showDealDrawer && <ManageDealDrawer source={source === 'Debt Products' ? 'Debt Products' : 'AICA Report Page'} />}

                {showFinancialMetrics && (
                    <FinancialMetrics
                        screen="dashboard"
                        assesseeOrgId={params.id}
                        onClose={() => {
                            deleteFromSearchParams('showFinancialMetrics');
                        }}
                    />
                )}

                {unlockAicaReportDetails?.showUnlockAicaConfirmation && (
                    <ConfirmationPopup
                        title="Unlock AICA?"
                        subTitle="To add to portfolio and start monitoring you need to unlock AICA Report."
                        confirmCta="Unlock"
                        cancelCta="Cancel"
                        open={unlockAicaReportDetails?.showUnlockAicaConfirmation}
                        onConfirm={() => {
                            dispatch(setShowUnlockAicaConfirmation(false));
                            dispatch(setShowRefreshReport(true));
                        }}
                        onCancel={() => {
                            dispatch(setShowUnlockAicaConfirmation(false));
                        }}
                    />
                )}

                {showPortfolioSuccess && <PortfolioSuccess isOpen={showPortfolioSuccess} />}

                {unlockAicaAndAddToLeadsConfirmation.showConfirmationModal && (
                    <ConfirmationPopup
                        title={
                            unlockAicaAndAddToLeadsConfirmation.source === UnlockAICASourcePage.DASHBOARD_SIDEBAR
                                ? 'Unlock AICA?'
                                : 'Add to Leads?'
                        }
                        subTitle={
                            unlockAicaAndAddToLeadsConfirmation.source === UnlockAICASourcePage.DASHBOARD_SIDEBAR
                                ? 'To unlock AICA Report you need to add it to leads.'
                                : ''
                        }
                        confirmCta={
                            unlockAicaAndAddToLeadsConfirmation.source === UnlockAICASourcePage.DASHBOARD_SIDEBAR
                                ? 'Unlock and Add to Leads'
                                : 'Add to Leads'
                        }
                        cancelCta="Cancel"
                        open={unlockAicaAndAddToLeadsConfirmation.showConfirmationModal}
                        onConfirm={(onStart, onSuccess) => {
                            onStart?.();
                            moveTierAndUnlockAICAReport(params.id ?? '', ACCESS_TIERS.SCAN, user.userData.investorOrgId, () => {
                                onSuccess?.();
                                dispatch(
                                    setShowUnlockAicaAndAddToLeads({
                                        showModal: false,
                                    }),
                                );
                                onUnlockReportSuccess();
                                setShowInviteCompanyModal(true);
                            });
                        }}
                        onCancel={() => {
                            dispatch(
                                setShowUnlockAicaAndAddToLeads({
                                    showModal: false,
                                }),
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );

    if (pdfReportSection)
        return reportSectionsToShow.includes(REPORT_SECTION_NAMES.COMPLIANCES_SUMMARY) && showPDFReportSection ? (
            <>
                <A4Page sectionName={REPORT_SECTION_NAMES.COMPLIANCES_SUMMARY_OVERVIEW}>
                    <AicaOverview style={{ marginTop: '1.5rem' }} module={'compliances'} isPDFReport={!!pdfReportSection} />
                </A4Page>
                {shouldRenderSecondA4Page() && (
                    <A4Page sectionName={REPORT_SECTION_NAMES.COMPLIANCES_SUMMARY}>
                        <div className={styles.Title}>
                            Compliances Summary
                            <DataSource
                                dataSource={dataSource}
                                isLoading={dashboardDataNew.loadingHeadline}
                                selectedTab={dashboardTab.selectedTab}
                            />
                        </div>

                        {renderDynamicTabContent(!!pdfReportSection)}
                    </A4Page>
                )}
            </>
        ) : (
            <></>
        );
    else return render;
}

export default Dashboard;
