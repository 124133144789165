import { ROW_COLORS } from './constants/TableConstants';

export const addColorandIconToRow = (row) => {
    if (row.isAggregated) {
        switch (row.classificationName) {
            case 'PAT':
            case 'Profit After Tax':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'E + I - F - D - T',
                };
            case 'EBITDA':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'R - D - C - G',
                };
            case 'Total Costs':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'D + C + G',
                };
            case 'Gross Margin':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'R - D',
                };
            case 'Total Assets':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Tc + Ta + In',
                };
            case 'Total Current Assets':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Tr + C + Od + B + Oa',
                };
            case 'Total Non Current Assets':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Ta + In',
                };
            case 'Total Equities Liabilities':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Se + U + N + T + Sa + St + Ot + S + R',
                };
            case 'Total Liabilities':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Se + U + N + T + Sa + St + Ot',
                };
            case 'Total Current Liabilities':
                return {
                    ...row,
                    classification: row.classificationName,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'T + Sa + St + Ot',
                };
            case 'Total Non Current Liabilities':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Se + U + N',
                };
            case 'Net Cash Flow':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'O + I + Fi + Fo',
                };
            case 'Total Shareholder Funds':
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.GREY,
                    formula: 'Sh + R',
                };
        }
        return {
            ...row,
            color: ROW_COLORS.TEXT.BLACK,
            bg: ROW_COLORS.BG.GREY,
        };
    } else {
        switch (row?.classificationId) {
            case 1:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.RED,
                    bg: ROW_COLORS.BG.RED,
                };
            case 2:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Fi',
                };
            case 3:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'O',
                };
            case 4:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'I',
                };
            case 5:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'C',
                };
            case 6:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'D',
                };
            case 7:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'F',
                };
            case 8:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'G',
                };
            case 9:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Re',
                };
            case 10:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'B',
                };
            case 11:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'C',
                };
            case 12:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'In',
                };
            case 13:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Nc',
                };
            case 14:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Iv',
                };
            case 16:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Od',
                };
            case 17:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Oa',
                };
            case 18:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BROWN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Ot',
                };
            case 19:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'R',
                };
            case 20:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BROWN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Sa',
                };
            case 21:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Sh',
                };
            case 22:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BROWN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'St',
                };
            case 23:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Ta',
                };
            case 24:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BROWN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'T',
                };
            case 25:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'Tr',
                };
            case 26:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.ORANGE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'D',
                };
            case 27:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLUE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'I',
                };
            case 28:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.PURPLE,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'T',
                };
            case 30:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.RED,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'U',
                };
            case 31:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.RED,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'N',
                };
            case 32:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.DARK_GREEN,
                    bg: ROW_COLORS.BG.WHITE,
                    symbol: 'I',
                };
            default:
                return {
                    ...row,
                    color: ROW_COLORS.TEXT.BLACK,
                    bg: ROW_COLORS.BG.WHITE,
                };
        }
    }
};
