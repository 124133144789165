import React, { useRef, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import AIText from '../../../../AIText/AIText';
import CircularSearchBar from '../../CircularSearchBar/CircularSearchBar';
import styles from '../MagicUploadFilesTable.module.scss';
import OpenMuOrgIcon from '../../../../../assets/Svg/OpenMUOrgIcon';
import { MagicUploadFileData } from '../../../useFetchDataForMU';

interface Props {
    file: MagicUploadFileData & {
        emailSyncId?: string;
        masterId?: string;
    };
    orgName: string;
    rowIndex: number;
    isPreTagged: boolean;
    shouldAnimate: boolean;
    selectOrg: (option: any, file: MagicUploadFileData) => Promise<any>;
    orgList: any[];
    index: number | null;
    setIndex: (i: number) => void;
    setSearchText: (val: string) => void;
    isSelectingOrg: boolean;
    isMasterId?: Record<string, any>;
    viewReport: (id: string) => void;
}

const OrgNameCell: React.FC<Props> = ({
    file,
    orgName,
    rowIndex,
    isPreTagged,
    shouldAnimate,
    selectOrg,
    orgList,
    index,
    setIndex,
    setSearchText,
    isSelectingOrg,
    isMasterId,
    viewReport,
}) => {
    const animationRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = animationRef.current;
        if (!el) return;

        const handleAnimationEnd = () => {
            // Only remove animation classes, keep the wrapper classes
            if (el.classList.contains(styles.successAnimation)) {
                el.classList.remove(styles.successAnimation);
            }
            if (el.classList.contains(styles.errorAnimation)) {
                el.classList.remove(styles.errorAnimation);
            }
        };

        el.addEventListener('animationend', handleAnimationEnd);
        return () => el.removeEventListener('animationend', handleAnimationEnd);
    }, []);

    const animationDelay = `${rowIndex * 0.1}s`;
    const emailSyncIdOrMasterId = file?.emailSyncId || file?.masterId;

    // If orgName exists
    if (orgName) {
        return (
            <div
                ref={animationRef}
                style={
                    {
                        position: 'relative',
                        '--animation-delay': animationDelay,
                    } as React.CSSProperties
                }
                className={`${isPreTagged ? styles.AnimationWrapper : ''} ${shouldAnimate ? styles.successAnimation : ''}`}
            >
                {isPreTagged && (
                    <div className={isPreTagged ? styles.AnimatedImage : styles.dNone}>
                        <Player
                            src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/SuccessAnimationMU.json"
                            style={{ height: '1.5rem', width: '1.5rem' }}
                            autoplay
                            keepLastFrame
                            speed={4}
                        />
                    </div>
                )}
                <div className={isPreTagged ? styles.SuccessAnimatedPill : ''}>
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            viewReport(file?.orgId);
                        }}
                        title={orgName}
                        className={styles.OrgNamePill}
                    >
                        <OpenMuOrgIcon colour="var(--primary-text-colour)" height="22" width="22" />
                        <span className={styles.OrgNameText}>{orgName}</span>
                    </span>
                </div>
            </div>
        );
    }

    // If orgName is null or empty but a master ID is in progress
    if (
        (orgName == null || orgName.length === 0) &&
        emailSyncIdOrMasterId &&
        isMasterId &&
        emailSyncIdOrMasterId in isMasterId &&
        isMasterId[emailSyncIdOrMasterId] !== file.id
    ) {
        return (
            <AIText
                text="Tagging Organisation Name"
                style={{
                    marginLeft: '0.5rem',
                    fontFamily: 'Gilroy-Semibold',
                    fontSize: '0.875rem',
                }}
                loadingDotsStyle={{
                    color: 'var(--primary-text-colour)',
                    fontSize: '0.875rem',
                }}
                showLoadingDots
            />
        );
    }

    // Otherwise show error animation with search bar
    return (
        <div
            ref={animationRef}
            style={
                {
                    position: 'relative',
                    '--animation-delay': animationDelay,
                } as React.CSSProperties
            }
            className={`${isPreTagged ? styles.AnimationErrorWrapper : ''} ${shouldAnimate ? styles.errorAnimation : ''}`}
        >
            {isPreTagged && (
                <div className={isPreTagged ? styles.AnimatedImageError : styles.dNone}>
                    <Player
                        src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json"
                        style={{ height: '1.5rem', width: '1.5rem' }}
                        autoplay
                        keepLastFrame
                        speed={4}
                    />
                </div>
            )}
            <div className={isPreTagged ? styles.ErrorAnimatedPill : ''}>
                <CircularSearchBar
                    onAddLeadSuccess={(data) => selectOrg(data, file)}
                    onSelect={async (option) => {
                        return await selectOrg(option, file);
                    }}
                    options={index === rowIndex ? orgList : []}
                    placeholder="Search Name"
                    showList={index === rowIndex}
                    onChange={(value) => setSearchText(value)}
                    onFocus={() => setIndex(rowIndex)}
                    isSelectingOrg={isSelectingOrg || false}
                />
            </div>
        </div>
    );
};

export default OrgNameCell;
