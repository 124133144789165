import styles from '../../../MagicUpload/components/FilteredHeader/FilteredHeader.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../../../utils/constants/hooks';
import { GREY_DROPDOWN_CARET } from '../../../../assets/hostedassets';

type MultiSelectSearchDropdownProps = {
    options: { label: string; value: string }[];
    selectedItems: string[];
    toggleSelection: (option: string[]) => void;
    placeholder?: string;
    wrapperStyle?: React.CSSProperties;
};

const MultiSelectSearchDropdown = (props: MultiSelectSearchDropdownProps) => {
    const { options, selectedItems, toggleSelection, placeholder, wrapperStyle } = props;
    const [showFilters, setShowFilters] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownItemRef = useRef<HTMLDivElement>(null);
    const [filteredDropdownOptions, setFilteredDropdownOptions] = useState<
        | {
              label: string;
              value: string;
          }[]
        | null
    >(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(dropdownRef, () => {
        setShowFilters(false);
        setSearchText('');
    });

    const handleSelection = (option: { label: string; value: string }) => {
        if (selectedItems?.some((item) => item === option.value)) {
            toggleSelection(selectedItems.filter((item) => item !== option.value));
        } else if (selectedItems?.some((item) => item === option.label)) {
            toggleSelection(selectedItems.filter((item) => item !== option.label));
        } else {
            toggleSelection([...selectedItems, option.value]);
        }
    };

    useEffect(() => {
        if (searchText) {
            setFilteredDropdownOptions(options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase())));
        } else {
            setFilteredDropdownOptions(options);
        }
    }, [searchText]);

    useEffect(() => {
        if (showFilters) {
            let selectedOptions = options.filter((option) => selectedItems?.some((item) => item === option.value));
            let unselectedOptions = options.filter((option) => !selectedItems?.some((item) => item === option.value));
            setFilteredDropdownOptions([...selectedOptions, ...unselectedOptions]);
            if (dropdownItemRef.current) {
                dropdownItemRef.current.scrollTo({ behavior: 'smooth', top: 0 });
            }
            setTimeout(() => {
                if (dropdownRef.current) {
                    dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 200);
        }
    }, [options, showFilters]);

    return (
        <div
            ref={dropdownRef}
            style={
                selectedItems.length > 0
                    ? { width: '100%', background: 'white', border: '1px solid #c7c7c7', ...wrapperStyle }
                    : { width: '100%', ...wrapperStyle }
            }
            onClick={() => {
                setShowFilters(!showFilters);
                setSearchText('');
            }}
            className={styles.SearchBar}
        >
            {selectedItems?.length > 0 ? (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1ch' }}>
                        <span
                            style={{
                                background: 'var(--secondary-bg-colour)',
                                color: 'var(--primary-text-colour)',
                                maxWidth: '25ch',
                                display: 'flow',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            className={styles.SelectedItem}
                        >
                            {options.find((option) => option.value === selectedItems[0])?.label ||
                                options.find((option) => option.label === selectedItems[0])?.label}
                        </span>
                        {selectedItems.length > 1 && <span className={styles.AndMore}>+{selectedItems.length - 1} More</span>}
                    </div>
                    <img
                        style={showFilters ? { transform: 'rotate(180deg)' } : {}}
                        className={styles.DownArrow}
                        src={GREY_DROPDOWN_CARET}
                        alt="caret"
                    />
                </>
            ) : (
                <>
                    <span className={styles.PlaceholderText}>{placeholder}</span>
                    <img
                        style={showFilters ? { transform: 'rotate(180deg)' } : {}}
                        className={styles.DownArrow}
                        src={GREY_DROPDOWN_CARET}
                        alt="caret"
                    />
                </>
            )}

            <div
                ref={dropdownItemRef}
                className={styles.FilteredDropdownContainer}
                style={showFilters ? { maxHeight: '20rem', width: '100%' } : { maxHeight: 0, width: '100%' }}
            >
                <div style={{ width: '100%' }} className={styles.DropdownOptionsWrapper}>
                    <input
                        type="text"
                        placeholder="Search"
                        className={styles.SearchInputFilter}
                        value={searchText}
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <div className={styles.FilteredDropdownWrapper}>
                        {filteredDropdownOptions?.length && filteredDropdownOptions?.length > 0 ? (
                            filteredDropdownOptions?.map((option) => (
                                <div
                                    key={option.value}
                                    className={styles.FilteredDropdownItem}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleSelection(option);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <input
                                        type="checkbox"
                                        checked={selectedItems?.some((item) => item === option.value || item === option.label)}
                                        readOnly
                                    />
                                    <label className={styles.DropdownLabel}>{option.label}</label>
                                </div>
                            ))
                        ) : (
                            <div className={styles.NoData}>
                                <span>No Results</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiSelectSearchDropdown;
