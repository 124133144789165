import React from 'react';

import styles from '../SideDrawer.module.scss';

interface SideDrawerFooterProps {
    children?: React.ReactNode;
}

function SideDrawerFooter({ children }: SideDrawerFooterProps) {
    return <div className={styles.DrawerFooter}>{children}</div>;
}

export default SideDrawerFooter;
