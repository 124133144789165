export const alertsConst = {
    isShell: {
        type: 'isShell',
        alerts: 'Shell',
        description:
            "Company is marked as a Shell company by the Income Tax Department, Central Bureau of Investigation, or the Serious Fraud Investigations Office, or through Karza's Shell Entity Identification Service",
        severity: 'Severe',
        category: 'Compliance and Fraud',
    },
    isCompanyUnderLiquidation: {
        type: 'isCompanyUnderLiquidation',
        alerts: 'Company Under Liquidation',
        description: "Company Status is identified as being 'Under Liquidation'",
        severity: 'Severe',
        category: 'Financial Distress',
    },
    isMlm: {
        type: 'isMlm',
        alerts: 'MLM',
        description: 'Company is identified as Multi Level Marketing Company',
        severity: 'Severe',
        category: 'Compliance and Fraud',
    },
    isVanishing: {
        type: 'isVanishing',
        alerts: 'Vanishing',
        description: 'Company is identified as Vanishing Company',
        severity: 'Severe',
        category: 'Compliance and Fraud',
    },
    isHawala: {
        type: 'isHawala',
        alerts: 'Non-Genuine Dealers of Mahavat',
        description: 'Company is identified as Non-genuine dealer who have issued false invoices/bills without delivery of goods.',
        severity: 'Severe',
        category: 'Compliance and Fraud',
    },
    isSebiDebarred: {
        type: 'isSebiDebarred',
        alerts: 'SEBI Debarred',
        description:
            'Company barred from trade in the securities market for a specified period of time due to non-compliance, non disclosure or violation of the Securities Laws, or due to failure to comply with Stock Exchange rules.',
        severity: 'Severe',
        category: 'Regulatory Action',
    },
    isCompanyStrikedOff: {
        type: 'isCompanyStrikedOff',
        alerts: 'Company Striked Off',
        description: 'Company Status identified as being "Struck Off"',
        severity: 'High',
        category: 'Regulatory Compliance Filings',
    },
    isCompanyUnderStrikeOff: {
        type: 'isCompanyUnderStrikeOff',
        alerts: 'Company Under Strike Off',
        description: 'Company Status identified as being "Under the process of Striking Off"',
        severity: 'High',
        category: 'Regulatory Compliance Filings',
    },
    isCompanyStrikedOffUs248: {
        type: 'isCompanyStrikedOffUs248',
        alerts: 'Company Striked Off Us 248',
        description: 'Company struck off under Section 248',
        severity: 'High',
        category: 'Regulatory Compliance Filings',
    },
    isPEP: {
        type: 'isPEP',
        alerts: 'PEP',
        description:
            'Company/Individual holding public office or associated with Individuals holding public office or having political interests',
        severity: 'High',
        category: 'Risk Management',
    },
    isFormerPEP: {
        type: 'isFormerPEP',
        alerts: 'Former PEP',
        description:
            'Company/Individual holding public office or associated with Individuals formerly holding public office or having political interests',
        severity: 'High',
        category: 'Risk Management',
    },
    isProclaimedOffender: {
        type: 'isProclaimedOffender',
        alerts: 'Proclaimed Offender',
        description: 'Company/Individual in list of Proclaimed Offenders ',
        severity: 'High',
        category: 'Legal Action',
    },
    isBsePenalSuspended: {
        type: 'isBsePenalSuspended',
        alerts: 'BSE Penal Suspended',
        description: 'Company suspended from trading on BSE due to non-compliance with regulations',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isBseCompanySuspendedMoreThan7Years: {
        type: 'isBseCompanySuspendedMoreThan7Years',
        alerts: 'BSE Company Suspended More Than 7 Years',
        description: 'Company is suspended from trading for 7 years or more by BSE',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isBseUnderGsmSurveillance: {
        type: 'isBseUnderGsmSurveillance',
        alerts: 'BSE Under GSM Surveillance',
        description:
            'Company is under GSM Surveillance by BSE to protect the interest of investors, having a set criteria for inclusion of stocks under the GSM Framework.',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isBseDisciplinaryAction: {
        type: 'isBseDisciplinaryAction',
        alerts: 'BSE Disciplinary Action',
        description: 'Violation/Non-compliance by company has resulted in disciplinary action being initiated by them',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isBseDefaulter: {
        type: 'isBseDefaulter',
        alerts: 'BSE Defaulter',
        description: 'Individual is a defaulter declared by BSE',
        severity: 'High',
        category: 'Financial Distress',
    },
    isBseExpelled: {
        type: 'isBseExpelled',
        alerts: 'BSE Expelled',
        description: 'Individual is expelled by BSE under various Bylaws.',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseCompanySuspendedNonCompliance: {
        type: 'isNseCompanySuspendedNonCompliance',
        alerts: 'NSE Company Suspended Non Compliance',
        description: 'Individual is suspended by NSE for non compliance with regulations',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseCompanySuspended: {
        type: 'isNseCompanySuspended',
        alerts: 'NSE Company Suspended',
        description:
            'Individual is suspended by NSE on SEBI orders due to Failure in meeting the capital adequacy requirements for continued admittance to the trading membership of the Exchange',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseUnderGsmSurveillance: {
        type: 'isNseUnderGsmSurveillance',
        alerts: 'NSE Under GSM Surveillance',
        description:
            'Company is under GSM Surveillance by NSE in order to protect the interest of the investors,having a set criteria for inclusion of stocks under the GSM Framework.',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseUnderAsmSurveillance: {
        type: 'isNseUnderAsmSurveillance',
        alerts: 'NSE Under Asm Surveillance',
        description:
            'Company is under ASM Surveillance by NSE to protect the interest of investors, being an objective criterion decided jointly by SEBI and Stock Exchanges covering several parameters.',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseDefaulter: {
        type: 'isNseDefaulter',
        alerts: 'NSE Defaulter',
        description: 'Members of NSE declared Individual as defaulters under various bylaws',
        severity: 'High',
        category: 'Financial Distress',
    },
    isNseExpelled: {
        type: 'isNseExpelled',
        alerts: 'NSE Expelled',
        description: 'Individual expelled by NSE under various Bylaws',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isNseSuspended: {
        type: 'isNseSuspended',
        alerts: 'NSE Suspended',
        description:
            'Company suspended by NSE if found guilty of contravention, non-compliance, disobedience, disregard or evasion of any of the Bye Laws, Rules and Regulations,resolutions, orders, notices, directions or decisions or rulings.',
        severity: 'High',
        category: 'Regulatory Action',
    },
    isCompanyUnderProsecution: {
        type: 'isCompanyUnderProsecution',
        alerts: 'Company Under Prosecution',
        description: 'Prosecution initiated by the MCA against the Company.',
        severity: 'High',
        category: 'Legal Action',
    },
    isMcaDirectorUnderProsecution: {
        type: 'isMcaDirectorUnderProsecution',
        alerts: 'MCA Director Under Prosecution',
        description: 'Prosecution initiated by the MCA against the Director.',
        severity: 'High',
        category: 'Legal Action',
    },
    isSfioProclaimedOffender: {
        type: 'isSfioProclaimedOffender',
        alerts: 'Sfio Proclaimed Offender',
        description: 'Company and its associates declared are proclaimed offenders by SFIO',
        severity: 'High',
        category: 'Legal Action',
    },
    isSfioConvictedDirector: {
        type: 'isSfioConvictedDirector',
        alerts: 'Sfio Convicted Director',
        description: 'Directors convicted as per SFIO investigation.',
        severity: 'High',
        category: 'Legal Action',
    },
    isDormant: {
        type: 'isDormant',
        alerts: 'Dormant',
        description: 'Company is a dormant Company as defined by the Companies Act',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isNotFiled5inv: {
        type: 'isNotFiled5inv',
        alerts: 'Not Filed 5INV',
        description:
            'Companies has not filed Form 5INV (Statement of Unclaimed and Unpaid Accounts) under the Investor Protection and Education Fund.',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isCompanyActiveNonCompliant: {
        type: 'isCompanyActiveNonCompliant',
        alerts: 'Company Active Non Compliant',
        description: 'Company has not filed the form INC-22A (ACTIVE) (Mandatory for Companies registered on or before 2017)',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isDinDeactivated: {
        type: 'isDinDeactivated',
        alerts: 'DIN Deactivated',
        description: "Director's DIN Status is Deactivated due to non-filing of Form DIR 3 KYC",
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isDinDisabled: {
        type: 'isDinDisabled',
        alerts: 'DIN Disabled',
        description: "Director's DIN is Disabled",
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isDinDisqualified: {
        type: 'isDinDisqualified',
        alerts: 'DIN Disqualified',
        description:
            'Director is disqualified, including directors not eligible to be appointed as a director in any other public companies as per sec 274 1 (g) of Companies Act.',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isDinSurrendered: {
        type: 'isDinSurrendered',
        alerts: 'DIN Surrendered',
        description: "Director's DIN is Surrendered",
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isMcaCompanyDefaulter: {
        type: 'isMcaCompanyDefaulter',
        alerts: 'MCA Company Defaulter',
        description: 'Company is Defaulter for not filing Balance Sheet Forms or other irregularities.',
        severity: 'Medium',
        category: 'Financial Distress',
    },
    isMcaDirectorDefaulter: {
        type: 'isMcaDirectorDefaulter',
        alerts: 'MCA Director Defaulter',
        description: 'Director is in a Defaulting company.',
        severity: 'Medium',
        category: 'Financial Distress',
    },
    isSuspendedAtStockExchange: {
        type: 'isSuspendedAtStockExchange',
        alerts: 'Suspended At Stock Exchange',
        description: "Company is identified as 'Suspended at Stock Exchange'",
        severity: 'Medium',
        category: 'Regulatory Action',
    },
    isBseIlliquidSecurity: {
        type: 'isBseIlliquidSecurity',
        alerts: 'BSE Illiquid Security',
        description:
            'Companies Securities will be classified as illiquid if the following conditions are met: 1)Average daily turnover of less than Rs.2 lakhs calculated for previous two quarters 2)The Security is classified as illiquid at all Exchanges where it is traded',
        severity: 'Medium',
        category: 'Market Activity',
    },
    isDinLapsed: {
        type: 'isDinLapsed',
        alerts: 'DIN Lapsed',
        description: "Director's DIN is lapsed",
        severity: 'Low',
        category: 'Regulatory Compliance Filings',
    },
    isBseSebiRelaxationForMps: {
        type: 'isBseSebiRelaxationForMps',
        alerts: 'BSE SEBI Relaxation For MPS',
        description: 'Companies has been given relaxation from SEBI from applicability of minimum public shareholding (MPS) requirement.',
        severity: 'Low',
        category: 'Regulatory Action',
    },
    isBseCompanyDelisted: {
        type: 'isBseCompanyDelisted',
        alerts: 'BSE Company Delisted',
        description:
            'Companies is delisted by BSE where shares of those companies are removed from the stock exchange permanently for buying and selling purposes',
        severity: 'Low',
        category: 'Market Activity',
    },
    isBseMemberInactive: {
        type: 'isBseMemberInactive',
        alerts: 'BSE Member Inactive',
        description: 'Director is a Member of BSE who have been inactive.',
        severity: 'Low',
        category: 'Regulatory Action',
    },
    isNseCompanyDelisted: {
        type: 'isNseCompanyDelisted',
        alerts: 'NSE Company Delisted',
        description:
            'Companies is delisted by NSE where shares of those companies are removed from the stock exchange permanently for buying and selling purposes',
        severity: 'Low',
        category: 'Market Activity',
    },
    isEpfTransactionDefault: {
        type: 'isEpfTransactionDefault',
        alerts: 'EPF Transaction Default',
        description: 'Employee Provident Fund Payment not deposited by the Company.',
        severity: 'High',
        category: 'Statuary Filings',
    },
    isGstUnderCancellation: {
        type: 'isGstUnderCancellation',
        alerts: 'GST Under Cancellation',
        description: 'GST is Under Cancellation for the company',
        severity: 'High',
        category: 'Statuary Filings',
    },
    isIecBlackListed: {
        type: 'isIecBlackListed',
        alerts: 'IEC Black Listed',
        description: 'Import Export Code of the Company is blacklisted.',
        severity: 'High',
        category: 'Compliance and Fraud',
    },
    isGstFraud: {
        type: 'isGstFraud',
        alerts: 'GST Fraud',
        description:
            'Company was caught doing frauds like invoice related fraud, tax evasion. fake input tax credit claims, etc as per various news articles',
        severity: 'High',
        category: 'Compliance and Fraud',
    },
    isGstTransactionDelay: {
        type: 'isGstTransactionDelay',
        alerts: 'GST Transaction Delay',
        description: 'Delay in GSTR1/GSTR3B filings by Company',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isIecInDeniedEntityList: {
        type: 'isIecInDeniedEntityList',
        alerts: 'IEC In Denied Entity List',
        description: 'Import Export Code of the Company is in list of Denied Entity List.',
        severity: 'Medium',
        category: 'Compliance and Fraud',
    },
    isIecSuspended: {
        type: 'isIecSuspended',
        alerts: 'IEC Suspended',
        description: 'Import Export Code of the Company stands suspended.',
        severity: 'Medium',
        category: 'Regulatory Action',
    },
    isEpfTransactionDelay: {
        type: 'isEpfTransactionDelay',
        alerts: 'EPF Transaction Delay',
        description: 'Delay in depositing Provident Fund Payments by the Company',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isEpfClosed: {
        type: 'isEpfClosed',
        alerts: 'EPF Closed',
        description: 'Employees Provident Fund Registration is Closed by the Company',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isTanInactive: {
        type: 'isTanInactive',
        alerts: 'TAN Inactive',
        description: 'TAN Registration of the Company is Inactive.',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isTanTransactionDelay: {
        type: 'isTanTransactionDelay',
        alerts: 'TDS Payment Delay',
        description: 'Delay in depositing TDS Payments by Company.',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isLeiRegistrationRetired: {
        type: 'isLeiRegistrationRetired',
        alerts: 'LEI Registration Retired',
        description: 'Company has ceased its operation',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isPanInactive: {
        type: 'isPanInactive',
        alerts: 'Pan Inactive',
        description: 'PAN is inactive',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isGstTransactionDefault: {
        type: 'isGstTransactionDefault',
        alerts: 'GST Transaction Default',
        description: 'Default in GSTR1/GSTR3B filings by Company.',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isTanTransactionDefault: {
        type: 'isTanTransactionDefault',
        alerts: 'TDS Payment Default',
        description: 'Default in depositing TDS Payments by Company.',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isIecCancelled: {
        type: 'isIecCancelled',
        alerts: 'IEC Cancelled',
        description: 'Import Export Code of the Company is cancelled.',
        severity: 'Medium',
        category: 'Regulatory Action',
    },
    isGstCancelled: {
        type: 'isGstCancelled',
        alerts: 'GST Cancelled',
        description: 'GST Number cancelled for Company',
        severity: 'Medium',
        category: 'Statuary Filings',
    },
    isGstInactive: {
        type: 'isGstInactive',
        alerts: 'GST Inactive',
        description: 'GST Number Inactive for Company',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isGstProvisional: {
        type: 'isGstProvisional',
        alerts: 'GST Provisional',
        description: 'Provisional GST Number for Company',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isLeiRegistrationDuplicate: {
        type: 'isLeiRegistrationDuplicate',
        alerts: 'LEI Registration Duplicate',
        description: 'Duplicate LEI registration by Company',
        severity: 'Low',
        category: 'Regulatory Compliance Filings',
    },
    isLeiRegistrationLapsed: {
        type: 'isLeiRegistrationLapsed',
        alerts: 'LEI Registration Lapsed',
        description: 'LEI marked as lapsed for the company',
        severity: 'Low',
        category: 'Regulatory Compliance Filings',
    },
    isIbbi: {
        type: 'isIbbi',
        alerts: 'IBBI (Insolvency and Bankruptcy Board of India)',
        description:
            'Company is in the process of insolvency and bankruptcy proceedings as ordered by National Company Law Tribunal, or the Insolvency & Bankruptcy Board of India.',
        severity: 'Severe',
        category: 'Financial Distress',
    },
    isWilfulBankDefaulter: {
        type: 'isWilfulBankDefaulter',
        alerts: 'Wilful Defaulter',
        description:
            'Company is reported as Wilful Defaulter by Bank due to default in repayment of loan despite having the capacity to repay, or due to misutilization or siphoning of funds lent for the purpose of business.',
        severity: 'Severe',
        category: 'Financial Distress',
    },
    isCompanyUnderCirp: {
        type: 'isCompanyUnderCirp',
        alerts: 'Company Under CIRP (Corporate Insolvency Resolution Process)',
        description: 'Company is under Corporate Insolvency Resolution Process.',
        severity: 'High',
        category: 'Financial Distress',
    },
    isBifr: {
        type: 'isBifr',
        alerts: 'BIFR (Board for Industrial and Financial Reconstruction)',
        description:
            'Company appears on the Board for Industrial and Financial Reconstruction, mainly for sick or consistently loss making companies.',
        severity: 'High',
        category: 'Financial Distress',
    },
    isEpfRegisteredWithBifr: {
        type: 'isEpfRegisteredWithBifr',
        alerts: "EPF Registered With BIFR (Employees' Provident Fund)",
        description: 'Company referred to BIFR as per EPFO',
        severity: 'High',
        category: 'Financial Distress',
    },
    isEpfUnderLiquidation: {
        type: 'isEpfUnderLiquidation',
        alerts: 'EPF Under Liquidation',
        description: 'Provident Fund dues pending for the Company which is under Liquidation.',
        severity: 'High',
        category: 'Financial Distress',
    },
    isBankAuction: {
        type: 'isBankAuction',
        alerts: 'Bank Auction',
        description:
            'Company property is auctioned by the Bank on failure to repay amount of loan, defaulting on several repayments of outstanding interest and principal amount.',
        severity: 'High',
        category: 'Financial Distress',
    },
    isBankDefaulterSuitFiled: {
        type: 'isBankDefaulterSuitFiled',
        alerts: 'Suit Filed',
        description: 'Suit Filed by Bank against Company defaulting in repayment of loan amount reported by the Bank to CIBIL.',
        severity: 'High',
        category: 'Legal Action',
    },
    isSickUnit: {
        type: 'isSickUnit',
        alerts: 'Sick Unit',
        description:
            "Company appear in SIDBI's List of Sick Units, which consists of Micro, Small and Medium Enterprises classified as Non-viable due to NPAs.",
        severity: 'High',
        category: 'Financial Distress',
    },
    isBankDefaulterNonSuitFiled: {
        type: 'isBankDefaulterNonSuitFiled',
        alerts: 'Bank Defaulter Non Suit Filed',
        description: 'Company is defaulting in repayment of loan amount, however no suit has been filed by Bank towards the entity.',
        severity: 'High',
        category: 'Financial Distress',
    },
    isCreditRatingSuspended: {
        type: 'isCreditRatingSuspended',
        alerts: 'Credit Rating Suspended',
        description: 'Suspension of Credit Rating for the Company',
        severity: 'High',
        category: 'Financial Distress',
    },
    isRatedEntityNonCooperative: {
        type: 'isRatedEntityNonCooperative',
        alerts: 'Entity Non-Cooperative',
        description: 'Non co-operation by Company in providing information required by the Rating Agency.',
        severity: 'High',
        category: 'Regulatory Compliance Filings',
    },
    isCreditRatingDowngraded: {
        type: 'isCreditRatingDowngraded',
        alerts: 'Credit Rating Downgraded',
        description: "Company's Credit rating downgraded from previous ratings by Rating Agency.",
        severity: 'Medium',
        category: 'Financial Distress',
    },
    isCreditRatingOutlookNegative: {
        type: 'isCreditRatingOutlookNegative',
        alerts: 'Credit Rating Outlook Negative',
        description: 'A rating agency has given a negative outlook for the long term debt instruments of the Company.',
        severity: 'Medium',
        category: 'Financial Distress',
    },
    isCreditWatchWithDevelopingImplication: {
        type: 'isCreditWatchWithDevelopingImplication',
        alerts: 'Credit Watch With Developing Implication',
        description: 'The Company is being kept under a watch with developing implications over a short time period by a rating agency.',
        severity: 'Low',
        category: 'Financial Distress',
    },
    isCreditRatingWithdrawn: {
        type: 'isCreditRatingWithdrawn',
        alerts: 'Credit Rating Withdrawn',
        description: 'Withdrawal of Credit Rating for the Company',
        severity: 'Low',
        category: 'Financial Distress',
    },
    isDomainInvalid_: {
        type: 'isDomainInvalid_',
        alerts: 'Domain Invalid',
        description: 'Invalid Email Domain as disclosed by the Company',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isEmailInvalid_: {
        type: 'isEmailInvalid_',
        alerts: 'Email Invalid',
        description: 'Invalid Email ID disclosed by the Company',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isEmailDisposable_: {
        type: 'isEmailDisposable_',
        alerts: 'Email Disposable',
        description: 'Temporary/Disposable Email ID provided by the Company .',
        severity: 'Medium',
        category: 'Regulatory Compliance Filings',
    },
    isAddressQualityPoor_: {
        type: 'isAddressQualityPoor_',
        alerts: 'Generic Address',
        description: 'Incomplete or vague Registered Address provided by the Company.',
        severity: 'Low',
        category: 'Regulatory Compliance Filings',
    },
    isOffshoreLeak: {
        type: 'isOffshoreLeak',
        alerts: 'Offshore Leak',
        description:
            'Company is identified as offshore companies, foundations and trusts from the Panama Papers, Offshore Leaks, Bahamas Leaks and Paradise Papers investigations, published by the International Consortium of Investigative Journalists.',
        severity: 'Severe',
        category: 'Compliance and Fraud',
    },
    IsDirectorResigned: {
        type: 'IsDirectorResigned',
        alerts: 'Director Resignation(s)',
        description: 'Director/KMP resignation occurred in the immediately preceding year',
        severity: 'High',
        category: 'Regulatory Compliance Filings',
    },
};
