import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss';
import * as Sentry from '@sentry/react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

// Initialize Sentry
const initializeSentry = () => {
    try {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DNS,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.browserTracingIntegration(),
                // Sentry.replayIntegration({
                //     maskAllText: false,
                // }),
            ],
            // Tracing
            tracesSampleRate: 0.2,
        });
    } catch (e) {
        console.error(e);
    }
};
if (isProd) initializeSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

reportWebVitals();
