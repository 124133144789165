import React, { useEffect, useRef, useState } from 'react';
import styles from './RevenueVendorExpenseTable.module.scss';
import Headers from './components/Header';
import Rows from './components/Rows';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { fetchRevenueVendorExpenseTableData } from '../../../../../store/dashboard/revenueVendorExpenses/revenueVendorExpensesApiHelpers';
import Tooltip from '../../../../../common/_custom/Tooltip/Tooltip';
import { infoIconGreen, infoIconYellow, infoToolTip } from '../../../../../assets/hostedassets';
import clsx from 'clsx';
import { infoTooltipB2b, infoTooltTipVendorExpenses } from '../GSTR3BTable/gstr3Constants';

interface TableData {
    fetchSuccess: boolean;
    headers: { name: string; key: string }[];
    rows: any[];
    totalRecords: number;
    records: number;
}

function RevenueVendorExpenseTable({ tab = '', dataSource }) {
    const [fetchingData, setFetchingData] = useState(false);
    const [tableData, setTableData] = useState<TableData>({
        fetchSuccess: false,
        headers: [],
        rows: [],
        totalRecords: 0,
        records: 0,
    });
    const [pageNum, setPageNum] = useState(1);
    const tableRef = useRef(null);

    const noDataAvailable = tableData.fetchSuccess && !tableData?.rows?.length;
    const noGSTConnected = parseInt(dataSource?.[1]?.text?.[0]) === 0;

    const dataSourceList = dataSource?.[1]?.gstList || [];

    useEffect(() => {
        fetchTableData();
    }, []);

    const fetchTableData = () => {
        if (pageNum < 2) setFetchingData(true);
        fetchRevenueVendorExpenseTableData(
            {
                gstType: tab === 'revenue' ? 'REVENUE' : tab === 'vendorExpenses' ? 'EXPENSE' : '',
                excludeGstR3: tab === 'revenue',
                pageNum: pageNum,
                pageSize: 50,
            },
            {
                onSuccess: onDataFetchSuccess,
                onError: () => setFetchingData(false),
            },
        );
    };

    const onDataFetchSuccess = (data) => {
        if (pageNum > 1) {
            setTableData((tableData) => {
                const newRows = data?.rows || [];
                if (newRows?.length) {
                    tableData?.rows?.pop();
                    return {
                        ...tableData,
                        rows: [...tableData.rows, ...newRows],
                    };
                } else return tableData;
            });
        } else
            setTableData({
                fetchSuccess: true,
                ...data,
            });
        setFetchingData(false);
        setPageNum((pagenum) => pagenum + 1);
    };

    const getTitle = () => {
        switch (tab) {
            case 'revenue':
                return 'B2B Customer Summary';
            case 'vendorExpenses':
                return 'Vendor Expense From GST';
        }
    };

    // const getSecondaryText = () => {
    //     switch (tab) {
    //         case 'revenue':
    //             return '(Data from GSTR-1)';
    //         case 'vendorExpenses':
    //             return '(Data from GSTR-2)';
    //     }
    // };

    const getDataSource = () => {
        if (tab !== 'revenue') return <></>;
        const connectedGsts = dataSourceList.filter((gst) => gst?.connected);
        return (
            <div className={styles.DataSource}>
                <div className={styles.Content}>
                    Data Source: &nbsp;
                    <div
                        className={clsx(styles.DataSourceBadge, {
                            [styles.Green]: connectedGsts?.length >= dataSourceList?.length,
                        })}
                    >
                        {connectedGsts?.length} / {dataSourceList?.length} GST Connected
                        <Tooltip content={getGSTList()} direction={'left'}>
                            <img
                                src={
                                    connectedGsts?.length >= dataSourceList?.length
                                        ? infoIconGreen
                                        : infoIconYellow
                                }
                                alt=""
                                height="14px"
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    const getGSTList = () => {
        return (
            <div className={styles.GstList}>
                {dataSourceList.map((gst) => (
                    <div key={gst?.gstNumber} className={styles.GstItem}>
                        <div>
                            <span>{gst?.gstNumber}</span>
                            {/*<span>{gst?.gstStatus}</span>*/}
                        </div>
                        <div
                            className={clsx(styles.Status, {
                                [styles.Connected]: gst?.connected,
                            })}
                        >
                            {gst?.connected ? 'Connected' : 'Not Connected'}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.RevenueVendorExpenseTable}>
            <div
                className={clsx(styles.Top, {
                    [styles.TopNoData]: fetchingData || noDataAvailable,
                })}
            >
                <div className={styles.Left}>
                    <div className={styles.Title}>
                        <span className={styles.Main}>{getTitle()}</span>
                        <Tooltip
                            content={
                                tab === 'revenue' ? infoTooltipB2b : infoTooltTipVendorExpenses
                            }
                            direction={'right'}
                        >
                            <img src={infoToolTip} alt="info" height={'18px'} />
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.Right}>
                    {fetchingData ? (
                        <LoadingSpinner color={'#6021b3'} height={'20px'} />
                    ) : noDataAvailable ? (
                        'No Data Available'
                    ) : (
                        <>{getDataSource()}</>
                    )}
                </div>
            </div>
            {!noDataAvailable && !fetchingData && (
                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <InfiniteScroll
                            dataLength={tableData?.rows?.length}
                            hasMore={tableData.rows?.length + 1 < tableData.totalRecords}
                            next={fetchTableData}
                            loader={false}
                        >
                            <table className={styles.BankTable}>
                                <thead className={styles.TableHead}>
                                    <Headers data={tableData?.headers || []} />
                                </thead>
                                <tbody className={styles.TableBody}>
                                    <Rows
                                        rows={tableData?.rows}
                                        headers={tableData?.headers || []}
                                        tableRef={tableRef}
                                    />
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RevenueVendorExpenseTable;
