import React, { useState, useEffect, useRef } from 'react';
import styles from './File.module.scss';
import { USER_TYPE } from '../../../../../../utils/constants/user';

import {
    downloadIconDataRoom,
    downloadIconDataRoomHovered,
    downloadDarkGray,
    fileIconDataRoom,
    PURPLE_THREE_DOTS_VERTICAL,
} from '../../../../../../assets/hostedassets';
import threeDotVertical from '../../../../../../assets/three-dot-vertical.svg';
import useFetchDataRoomFiles from '../../../../useFetchDataRoomFiles';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import DocumentViewer from '../../../../../../common/DocumentViewer/DocumentViewer';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../app/hooks';

const File = ({
    name,
    lastUpdated,
    index,
    docId,
    masterFolderName,
    fetchFiles,
    isNew,
    // isRecurCompany,
}) => {
    const [downloadImgUrl, setDownloadImgUrl] = useState(downloadIconDataRoom);
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const dropdownRef: any = useRef(null);
    const threeDotsRef: any = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const fileViewType = searchParams.get('fileViewType');
    const isLender =
        useAppSelector((state) => state.user.userData.accountType) === USER_TYPE.LENDER;
    const islenderOrArchiveView = isLender || fileViewType === 'archived';

    const { handleFileClick, showFile, setShowFile, fileUrl, downloadTheFile, handleDeleteFile } =
        useFetchDataRoomFiles(fetchFiles);

    const calculateDropdownPosition = () => {
        if (threeDotsRef.current) {
            //@ts-ignore
            const rect = threeDotsRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX - 10,
            });
        }
    };

    useEffect(() => {
        if (showOtherOptions) {
            calculateDropdownPosition();
        }
    }, [showOtherOptions]);

    useEffect(() => {
        const handleScroll = () => {
            if (showOtherOptions) {
                setShowOtherOptions(false);
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [showOtherOptions]);

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                threeDotsRef.current &&
                !threeDotsRef.current?.contains(event.target)
            ) {
                setShowOtherOptions(false);
            }
        };
        if (showOtherOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showOtherOptions]);

    const dropdown = showOtherOptions
        ? createPortal(
              <div
                  ref={dropdownRef}
                  className={styles.OtherOptions}
                  style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
              >
                  <div
                      style={{ color: '#DF666A' }}
                      onClick={async (e) => {
                          e.stopPropagation();
                          if (docId) await handleDeleteFile([docId], name, masterFolderName, false);
                          if (fetchFiles) fetchFiles();
                          setShowOtherOptions(false);
                      }}
                  >
                      Delete
                  </div>
                  <div
                      style={{ color: '#DF666A' }}
                      onClick={async (e) => {
                          e.stopPropagation();
                          if (docId) await handleDeleteFile([docId], name, masterFolderName, true);
                          if (fetchFiles) fetchFiles();
                          setShowOtherOptions(false);
                      }}
                  >
                      Archive
                  </div>
              </div>,
              document.body,
          )
        : null;

    return (
        <div className={islenderOrArchiveView ? styles.fileLenderView : styles.file}>
            <div
                style={{
                    marginLeft: `${index * 1.25}rem`,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
                onClick={async () => {
                    if (docId) await handleFileClick(docId, name);
                }}
            >
                <span className={styles.FileIconContainer}>
                    <img src={fileIconDataRoom} alt={'folder'} />
                </span>
                <span className={styles.FileName}>{name}</span>
            </div>
            <span className={isNew ? styles.labelStatus : ''}>{isNew ? 'New' : ''}</span>
            <span className={styles.FileDate}>{lastUpdated}</span>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    if (docId) downloadTheFile(docId, name);
                }}
                className={styles.downloadIconContainer}
                onMouseEnter={() => setDownloadImgUrl(downloadDarkGray)}
                onMouseLeave={() => setDownloadImgUrl(downloadIconDataRoom)}
            >
                <img src={downloadImgUrl} alt={'icon'} />
            </span>
            {/* {!islenderOrArchiveView && <div className={styles.separator} />} */}
            {!islenderOrArchiveView && (
                <span
                    ref={threeDotsRef}
                    className={styles.ThreeDots}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowOtherOptions(!showOtherOptions);
                    }}
                >
                    <img
                        src={threeDotVertical}
                        style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                        alt={'More'}
                    />
                </span>
            )}
            {dropdown}
            {showFile && (
                <SideDrawer
                    heading={name}
                    showHeading={true}
                    width="75%"
                    allowClose
                    onClose={() => setShowFile(false)}
                >
                    <DocumentViewer fileUrl={fileUrl} />
                </SideDrawer>
            )}
        </div>
    );
};

export default File;
