import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import { fetchFundingHistoryDataService, getManagementProfileUserData } from './service';

export async function fetchManagementProfileUserData(payload: FetchManagementProfileDataPayload) {
    try {
        const response = await getManagementProfileUserData(payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.responseBody;
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occurred');
        }
    } catch (err) {
        console.error('Error in fetching user data', err);
    }
}

export async function fetchFundingHistoryData(payload: {
    orgId: string;
    assesseeOrgId: string;
    taskStepsList: string[];
}) {
    try {
        const response = await fetchFundingHistoryDataService(payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.responseBody || {};
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occurred');
        }
    } catch (err) {
        console.error('Error in fetching user data', err);
    }
}

type FetchManagementProfileDataPayload = {
    orgId: string;
    entityId: string;
    name: string;
    taskStepsList: string[];
};
