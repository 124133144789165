import dayjs from 'dayjs';
import clsx from 'clsx';
import styles from './CommentsBox.module.scss';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../app/hooks';
import { closeCommentsThread, setShowCommentsThread } from '../../../../../../../../../store/commentsThread/commentsThread_actions';
import { removeTooltipContent, setTooltipContent } from '../../../../../../../../../store/tooltip/tooltip_actions';
import { COMMENTS_THREAD_SOURCES } from '../../../../../../../../../store/commentsThread/commentsThread_reducer';
import { truncateTextTo2LineForComments } from '../../../../../../../../../utils/utils';
import useCommentsBoxFunctionality from './useCommentsBoxFunctionality';

const { DEAL, INVESTOR_DEAL, COMPANIES_LIST } = COMMENTS_THREAD_SOURCES;

type CommentsBoxProps = {
    comments: {
        text: string;
        activityBy: string;
        activityDate: string;
        userId: string;
    };
    assesseeOrgId: string;
    fetchData: Function | undefined;
    source: string;
    dealId?: string;
    index: number;
    dealIdForInvestorDeal?: string;
    borrowerName?: string;
    lenderName?: string;
};

const CommentsBox = ({
    comments,
    assesseeOrgId,
    fetchData,
    source,
    dealId,
    index,
    dealIdForInvestorDeal,
    borrowerName = '',
    lenderName = '',
}: CommentsBoxProps) => {
    const dispatch = useAppDispatch();

    const commentThreadState = useAppSelector((state) => state.commentsThreadReducer);
    const commentThreadSource = commentThreadState.source;
    const commentThreadDealId = commentThreadState.dealId;
    const commentThreadDealIdForInvestorDeal = commentThreadState.dealIdForInvestorDeal;

    const { editing, setEditing, handleCommentsChange, userComments, submitComment, setUserComments, setIsHovered, isHovered } =
        useCommentsBoxFunctionality(comments, assesseeOrgId, fetchData, source, dealId ?? '', dealIdForInvestorDeal ?? '');

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (!textarea) return;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [userComments?.text, editing]);

    const handleMouseLeave = (e) => {
        removeTooltipContent(dispatch);
    };

    const onCommentsBoxClick = (e) => {
        e.stopPropagation();
        if (commentThreadState.showCommentsThread) {
            //If the same comments thread is clicked again, close it
            //If any other is clicked, dialogue stays open, comments context changes
            if (
                (commentThreadSource === DEAL && dealId === commentThreadDealId) ||
                (commentThreadSource === INVESTOR_DEAL &&
                    dealIdForInvestorDeal === commentThreadDealIdForInvestorDeal &&
                    dealId === commentThreadDealId) ||
                (commentThreadSource === COMPANIES_LIST && assesseeOrgId === commentThreadState.assesseeOrgId)
            )
                dispatch(closeCommentsThread());
        }
        if (dealIdForInvestorDeal && dealId) {
            dispatch(
                setShowCommentsThread({
                    source: INVESTOR_DEAL,
                    dealIdForInvestorDeal: dealIdForInvestorDeal,
                    dealId: dealId,
                    headerText: borrowerName,
                    headerSecondaryText: lenderName,
                }),
            );
        } else if (dealId) {
            dispatch(
                setShowCommentsThread({
                    source: DEAL,
                    dealId: dealId,
                    headerText: borrowerName,
                }),
            );
        } else {
            dispatch(
                setShowCommentsThread({
                    source: COMPANIES_LIST,
                    assesseeOrgId: assesseeOrgId,
                }),
            );
        }
    };

    const renderCommentsBox = () => {
        if (editing === 'EDIT' || editing === 'NEW') {
            return (
                <>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsHovered(false);
                            setUserComments(comments);
                            setEditing('');
                        }}
                        className={styles.overlay}
                    />
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <div className={styles.CommentsEditBox}>
                            <textarea
                                ref={textareaRef}
                                onDragStart={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className={styles.TextArea}
                                value={userComments?.text ?? ''}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    handleCommentsChange(e);
                                }}
                            />
                            <div className={styles.ButtonContainer}>
                                <button
                                    className={styles.CancelButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsHovered(false);
                                        if (comments?.text) setUserComments(comments);
                                        else
                                            setUserComments({
                                                text: '',
                                                activityBy: '',
                                                activityDate: '',
                                                userId: '',
                                            });
                                        setEditing('');
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={styles.SubmitButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        submitComment();
                                        setIsHovered(false);
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div style={{ width: '100%', height: '100%' }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <div className={styles.CommentsBox} onClick={(e) => e.stopPropagation()}>
                    <div
                        className={clsx(styles.Container, {
                            [styles.ContainerHoverActive]:
                                (source === 'company' && assesseeOrgId === commentThreadState.assesseeOrgId) ||
                                (commentThreadSource === DEAL && dealId === commentThreadDealId) ||
                                (commentThreadSource === INVESTOR_DEAL && dealId === commentThreadDealId),
                        })}
                        onClick={onCommentsBoxClick}
                        onMouseEnter={(e) => {
                            if (comments?.text)
                                dispatch(
                                    setTooltipContent({
                                        content: comments?.text,
                                        target: e.currentTarget,
                                    }),
                                );
                        }}
                        onMouseLeave={handleMouseLeave}
                    >
                        {comments?.text ? (
                            <span
                                className={clsx(styles.CommentsText, {
                                    [styles.NotEmpty]: comments.text.length > 0,
                                })}
                            >
                                {truncateTextTo2LineForComments(comments.text)}
                                <div className={styles.Date}>
                                    {comments?.activityDate &&
                                        `${dayjs(comments?.activityDate).format('hh:mm A, DD MMM YYYY')} by ${comments.activityBy}`}
                                </div>
                            </span>
                        ) : (
                            <span className={styles.AddComment}>Add Comment</span>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    return <>{renderCommentsBox()}</>;
};
export default CommentsBox;
