import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import styles from './DirectorDetails.module.scss';
import DirectorInputField from './Components/DirectorInputField';
import { numberOnly } from '../../../../../utils/utils';
import clsx from 'clsx';
import { Input } from '../../../../../common/_custom';
import { useDispatch } from 'react-redux';
import { sendCibilConsentLink } from '../../../../../store/company/action';
import { useAppSelector } from '../../../../../app/hooks';
import { toast } from 'react-toastify';
import {
    GREEN_BG_WHITE_TICK_CIRCLE,
    orangeCross,
    uploadEquifax,
    verifyOrange,
} from '../../../../../assets/hostedassets';
import {
    lightBulbBlack,
    PURPLE_CIRCULAR_CROSS,
    VERIFY_GREY_CIRCLE,
    VERIFY_PURPLE_CIRCLE,
} from '../../../../../assets/hostedassets';
import { Mixpanel } from '../../../../../utils/mixpanel';
import {
    COMPANY_CB_ADD_DIRECTOR,
    COMPANY_CB_VERIFY_DIRECTOR,
} from '../../../../../utils/constants/mixpanelEvents/companyEvents';

import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { USER_TYPE } from '../../../../../utils/constants/user';
import CrossFilledIcon from '../../../../../assets/Svg/crossFilledIcon';
import FetchFillIcon from '../../../../../assets/Svg/FetchFilIcon';

const renderAsset = (asset: string | React.ReactNode) => {
    if (typeof asset === 'string') {
        return <img src={asset} alt="" className={styles.VerifyIcon} />;
    }
    return asset;
};

interface DirectorDetails {
    directorName: string;
    phoneNo: string;
    consentStatus?: boolean;
    lastInviteSentAt?: string;
    isNewDir?: boolean;
    isLoading?: boolean;
    isError: boolean;
    errorMessage: string;
    updatedAt?: number;
    cibilConsentStatus?: boolean;
}

interface DirectorDetailsProps {
    isInvestorSide?: boolean;
    setConsent?: (consent: boolean) => void;
}
function DirectorDetails({ isInvestorSide, setConsent }: DirectorDetailsProps) {
    const dispatch = useDispatch();
    const managementList = useAppSelector(
        (state: any) => state.company?.uploadJourneyData?.cibilSoftPullData?.managementList,
    );
    const { id } = useParams();
    const userData = useAppSelector((state) => state.user.userData);
    const [disableSendLink, setDisableSendLink] = useState<boolean>(true);

    const [directorDetails, setDirectorDetails] = useState<DirectorDetails[]>([
        {
            directorName: '',
            phoneNo: '',
            consentStatus: false,
            lastInviteSentAt: '',
            isNewDir: true,
            isError: false,
            errorMessage: '',
            isLoading: false,
            updatedAt: new Date().getTime(),
            cibilConsentStatus: false,
        },
    ]);
    const formatDataAndUpdateState = (managementList: any) => {
        const list = managementList.map((director: any) => {
            return {
                directorName: director.name,
                phoneNo: director.phoneNumber,
                consentStatus: director.cibilConsentStatus,
                lastInviteSentAt: director.lastInviteSentAt,
                updatedAt: director.updatedAt,
                cibilConsentStatus: director.cibilConsentStatus,
                isNewDir: false,
            };
        });
        setDirectorDetails(list);
    };

    useEffect(() => {
        if (managementList) {
            formatDataAndUpdateState(managementList);
        }
    }, [managementList]);

    const DIRECTOR_DETAILS = {
        HEADING: 'Credit Bureau',
        SUBHEADING:
            "By clicking verify you consent for soft check of credit bureau (Equifax) for following directors. Don't worry! It does not impact their credit score.",
    };
    const handleChange = (e: string, index: number, name: string) => {
        let value = e;
        if (name === 'phoneNo') {
            value = numberOnly(value);
            if (value.length > 10) {
                return;
            }

            if (value.length === 10 && directorDetails[index].directorName.trim() !== '') {
                setDisableSendLink(false);
            } else {
                setDisableSendLink(true);
            }

            if (value.length < 10 && value.length > 0) {
                setDirectorDetails((prev) => {
                    prev[index].isError = true;
                    prev[index].errorMessage = 'Please enter a valid phone number';
                    return prev;
                });
            } else if (value.length == 10 || value.length == 0) {
                setDirectorDetails((prev) => {
                    prev[index].isError = false;
                    prev[index].errorMessage = '';
                    return prev;
                });
            }
        } else if (name === 'directorName') {
            if (value.trim() !== '' && directorDetails[index].phoneNo.length === 10) {
                setDisableSendLink(false);
            } else {
                setDisableSendLink(true);
            }
        }

        const list: any = [...directorDetails];
        list[index][name] = value;
        setDirectorDetails(list);
    };
    const removeDirector = (index: number) => {
        const list = [...directorDetails];
        list.splice(index, 1);
        setDirectorDetails(list);
    };
    const addDirector = () => {
        Mixpanel.track(COMPANY_CB_ADD_DIRECTOR);
        const newDirector: DirectorDetails = {
            directorName: '',
            phoneNo: '',
            consentStatus: false,
            lastInviteSentAt: undefined,
            isNewDir: true,
            isError: false,
            errorMessage: '',
            isLoading: false,
        };
        setDirectorDetails((prev) => [...prev, newDirector]);
    };
    const sendLink = (
        name: string,
        phoneNo: string,
        setDisableSendLink: Function,
        index: number,
        flowType?: string,
    ) => {
        Mixpanel.track(COMPANY_CB_VERIFY_DIRECTOR);
        setDisableSendLink(false);
        setDirectorDetails((prev) =>
            prev.map((director, idx) =>
                idx === index ? { ...director, isLoading: true } : director,
            ),
        );

        dispatch(
            sendCibilConsentLink({
                assesseeOrgId: userData.assesseeOrgId ? userData.assesseeOrgId : id || '',
                directorName: name,
                phoneNumber: phoneNo,
                email: userData.primaryEmail,
                flowType,
                onSuccess: () => {
                    setDirectorDetails((prev) =>
                        prev.map((director, idx) =>
                            idx === index
                                ? {
                                      ...director,
                                      consentStatus: true,
                                      isNewDir: false,
                                      isLoading: false,
                                      updatedAt: new Date().getTime(),
                                  }
                                : { ...director },
                        ),
                    );
                    if (setConsent) {
                        setConsent(true);
                    }
                    setDisableSendLink(true);
                    toast.success('Verification Successful');
                    setDirectorDetails((prev) => {
                        prev[index].isError = false;
                        prev[index].errorMessage = '';
                        return prev;
                    });
                },
                onError: (message: string) => {
                    if (
                        message === 'Error in getting cibil report from Decentro' ||
                        message.includes('Inquiry Purpose is invalid')
                    ) {
                        toast.error(
                            "We're currently facing issues and are resolving them. Please try again later.",
                        );
                    } else {
                        setDirectorDetails((prev) =>
                            prev.map((director, idx) =>
                                idx === index
                                    ? {
                                          ...director,
                                          isLoading: false,
                                          isError: true,
                                          errorMessage: message,
                                      }
                                    : { ...director },
                            ),
                        );
                        setDisableSendLink(true);
                        setDirectorDetails((prev) =>
                            prev.map((director) =>
                                director.directorName === name
                                    ? { ...director, isLoading: false, error: message }
                                    : { ...director },
                            ),
                        );
                    }
                },
            }),
        );
    };

    const getVerifyIconLink = (director: DirectorDetails) => {
        if (!director.isNewDir) return VERIFY_GREY_CIRCLE;
        if (director.phoneNo.length === 10)
            return (
                <FetchFillIcon
                    colour="var(--primary-text-colour)"
                    height="15"
                    width="15"
                    className={styles.VerifyIcon}
                />
            );
        return VERIFY_GREY_CIRCLE;
    };

    return (
        <div>
            {isInvestorSide ? (
                <div className={styles.Heading}>
                    {renderAsset(uploadEquifax)}
                    <p>
                        Verify mobile no. of directors for a soft pull (Does not impact their
                        bureau)
                    </p>
                </div>
            ) : (
                <div>
                    <h3 className={styles.MainHeading}>{DIRECTOR_DETAILS.HEADING} </h3>
                    <div className={styles.MainBanner}>
                        {renderAsset(lightBulbBlack)}
                        <p className="subHeading">{DIRECTOR_DETAILS.SUBHEADING}</p>
                    </div>
                </div>
            )}
            <div className={styles.DirectorDetailsContainer}>
                {directorDetails?.map((director, index) => {
                    if (!director.isNewDir) {
                        return (
                            <div key={index}>
                                <DirectorInputField
                                    director={director}
                                    index={index}
                                    handleChange={handleChange}
                                    sendLink={sendLink}
                                    errorMessage={director.errorMessage}
                                    isError={director.isError}
                                    isLoading={director.isLoading}
                                    isInvestorSide={isInvestorSide}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div key={index} className={styles.dflex}>
                                <div style={{ width: '40%' }} className={styles.FormContainer}>
                                    <div style={{ margin: '0 0 1rem 0' }} className={styles.Field}>
                                        <Input
                                            value={director.directorName}
                                            style={{ margin: 0 }}
                                            onChange={(e) => {
                                                handleChange(e, index, 'directorName');
                                            }}
                                            placeholder="Director Name"
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '60%' }} className={styles.FormContainer}>
                                    <div style={{ margin: '0 0 1rem 0' }} className={styles.Field}>
                                        <Input
                                            name="phoneNo"
                                            value={director.phoneNo}
                                            style={{ margin: 0 }}
                                            onChange={(e) => {
                                                handleChange(e, index, 'phoneNo');
                                            }}
                                            disabled={false}
                                            placeholder="Director Mobile No."
                                            errorMessage={director.errorMessage}
                                            isValid={!director.isError}
                                        />
                                        {director.phoneNo &&
                                            (director.consentStatus ? (
                                                <div className={styles.BannerTextVerified}>
                                                    {renderAsset(GREEN_BG_WHITE_TICK_CIRCLE)}
                                                    <span>Fetched</span>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        sendLink(
                                                            director.directorName,
                                                            director.phoneNo,
                                                            setDisableSendLink,
                                                            index,
                                                            'NEW_DIRECTOR',
                                                        );
                                                    }}
                                                    className={clsx(styles.BannerText, {
                                                        [styles.DisabledBannerText]:
                                                            disableSendLink ||
                                                            director?.phoneNo?.length !== 10 ||
                                                            director.directorName.trim() === '',
                                                    })}
                                                >
                                                    {director.isLoading ? (
                                                        <LoadingSpinner
                                                            color={'var(--primary-text-colour)'}
                                                        />
                                                    ) : (
                                                        <div className={styles.VerifyImg}>
                                                            {renderAsset(
                                                                disableSendLink
                                                                    ? VERIFY_GREY_CIRCLE
                                                                    : getVerifyIconLink(director),
                                                            )}
                                                            <span>Fetch</span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <CrossFilledIcon
                                            colour="var(--primary-text-colour)"
                                            className={styles.NewDirRemove}
                                            onClick={() => removeDirector(index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
                <div className={styles.AddDirText}>
                    Add more directors for credit bureau check.{' '}
                    <span onClick={() => addDirector()} className={styles.AddDirCta}>
                        Add Director
                    </span>
                </div>
            </div>
        </div>
    );
}

export default DirectorDetails;
