import { useEffect, useState } from 'react';
import styles from './MetricRiskBadge.module.scss';
import {
    getRiskType,
    MetricRiskBadgeProps,
    RISK_MAP,
    RISK_TYPES,
    RiskMapType,
} from '../aicaGradeConstants';
import clsx from 'clsx';

function MetricRiskBadge({ score = null, isPDFReport = false }: MetricRiskBadgeProps) {
    const [selectedRiskType, setSelectedRiskType] = useState<RiskMapType | null>(null);

    useEffect(() => {
        setSelectedRiskType(RISK_MAP[getRiskType(score)]);
    }, [score]);

    return (
        <div
            className={clsx(styles.MetricRiskBadge, { [styles.MetricRiskPDFReport]: isPDFReport })}
            style={{
                background: selectedRiskType?.background,
                color: selectedRiskType?.color || 'white',
            }}
        >
            {selectedRiskType?.label !== 'Severe' && (
                <div className={styles.Icon} style={{ background: selectedRiskType?.color }}></div>
            )}
            <div className={styles.Label}>{selectedRiskType?.label}</div>
        </div>
    );
}

export default MetricRiskBadge;
