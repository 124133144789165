import { useEffect, useState } from 'react';
import styles from './OnePagerGenerateButton.module.scss';
import ToggleButton from '../../ToggleButton/ToggleButton';
import clsx from 'clsx';
import Tooltip from '../../_custom/Tooltip/Tooltip';
import { downloadFile } from '../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ctaPurpleRight, ctaWhiteRight, downloadIconDataRoomHovered } from '../../../assets/hostedassets';
import LoadingSpinner from '../../_custom/LoadingSpinner/LoadingSpinner';
import { Mixpanel } from '../../../utils/mixpanel';
import { AICA_GENERATE_ONE_PAGER } from '../../../utils/constants/mixpanelEvents/investorEvents';
import { FILE_SHARE_STATUS } from '../DealDrawerConstants';
import OnePager from '../../OnePager/OnePager';
import { closeOnePager, setOnePagerData } from '../../../store/investor/action';

function OnePagerGenerate({ formData, categoryId, flowId, metadataUpdate, assesseeOrgId, onShareToggle, existingData, isLocked, source }) {
    const dispatch = useAppDispatch();
    const [generated, setGenerated] = useState(false);
    const [editorExistingData, setEditorExistingData] = useState<null | Object>(null);
    const existingEditorContent = localStorage.getItem('one_pager_content');
    const existingEditorContentOrgId = localStorage.getItem('one_pager_assessee');
    const companyMainImg = useAppSelector((state) => state.user.domainMetadata?.mainImageUrl);
    const userData = useAppSelector((state) => state.user.userData);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const dealApplicationDrawerState = useAppSelector((state) => state.investor.dealApplicationDrawer);

    const onePagerState = useAppSelector((state) => state.investor.onePager);

    useEffect(() => {
        return () => {
            dispatch(closeOnePager());
        };
    }, []);

    const onGenerateClick = (isEdit = false) => {
        dispatch(
            setOnePagerData({
                open: true,
                source: 'CREATE_DEAL',
                assesseeOrgId: assesseeOrgId,
                dealFormData: formData,
                categoryId: categoryId,
                flowId: flowId,
            }),
        );
        Mixpanel.track(AICA_GENERATE_ONE_PAGER, {
            parentId: userData.investorOrgId,
            userId: userData.userId,
            userRole: userData.accountType,
            companyOrgId: dealDrawerState.companyId,
        });
    };

    const renderGenerateDealButton = () => {
        return (
            <div
                className={clsx(styles.GenerateButton, {
                    // [styles.Disabled]: disable,
                    [styles.Locked]: isLocked || (source === 'Deals List Page' && !existingData),
                })}
                onClick={() => onGenerateClick()}
            >
                {onePagerState.loading ? (
                    <LoadingSpinner height="15px" style={{ margin: 0 }} />
                ) : (
                    <img src={ctaWhiteRight} alt="" height={'15px'} />
                )}
                {onePagerState.loading ? 'Generating...' : 'Generate One Pager'}
            </div>
        );
    };

    const downloadOnePager = () => {
        existingData?.docId && downloadFile(Number(existingData?.docId), () => {}, existingData?.documentName?.replace('ONEPAGER_', ''));
    };

    const onMetadataUpdate = (fileInfo) => {
        setGenerated(true);
        dispatch(closeOnePager());
        metadataUpdate?.(fileInfo);
    };

    return (
        <div className={styles.OnePagerButton}>
            <div className={styles.GeneratedButton}>
                {existingData && source === 'Deals List Page' ? (
                    <div className={styles.Left} onClick={() => downloadOnePager()}>
                        <img
                            src={downloadIconDataRoomHovered}
                            alt=""
                            height={'12px'}
                            // style={{ transform: 'rotate(135deg)' }}
                        />
                        Download
                    </div>
                ) : !existingData && source === 'Deals List Page' ? (
                    <div>One Pager not generated</div>
                ) : (
                    <div
                        style={
                            dealDrawerState.readonly
                                ? {
                                      cursor: 'no-drop',
                                      filter: 'grayScale(1)',
                                      pointerEvents: 'none',
                                      opacity: '0.5',
                                  }
                                : {}
                        }
                        className={styles.Left}
                        onClick={() => onGenerateClick(true)}
                    >
                        <img src={ctaPurpleRight} alt="" height={'10px'} />
                        Edit Summary
                    </div>
                )}
                <div className={styles.Right}>
                    Share
                    <ToggleButton
                        checked={existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR}
                        onChange={() => {
                            if (!existingData?.action) onGenerateClick();
                            onShareToggle(
                                existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR
                                    ? FILE_SHARE_STATUS.SKIP_NOTIFICATION
                                    : FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                            );
                        }}
                        disabled={source === 'Deals List Page'}
                    />
                </div>
            </div>

            {onePagerState.open && (onePagerState.source === 'CREATE_DEAL' || source === 'debtProducts') && (
                <OnePager
                    // source={'CREATE_DEAL'}
                    metadataUpdate={onMetadataUpdate}
                    categoryId={categoryId}
                    flowId={flowId}
                />
            )}
        </div>
    );
}

export default OnePagerGenerate;
