import React, { useEffect, useRef, useState } from 'react';
import './A4.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    incrementPagesCountForSection,
    updatePagesCountForSection,
} from '../../../store/pdfReport/pdfReport_actions';
import { REPORT_SECTION_NAMES } from '../../../store/pdfReport/pdfReport_constants';
import poweredIcon from './powerebyaica.svg';

const MM_TO_PX = 3.7795275591; // Conversion factor for mm to px (approx)
const PAGE_HEIGHT_MM = 297;
const PAGE_MARGIN_MM = 8;
const PAGE_PADDING_PX = 10;

function A4Page({ children, sectionName = '' }) {
    const contentRef = useRef<HTMLDivElement>(null);
    const [pages, setPages] = useState(1);
    const dispatch = useAppDispatch();
    const pagesCount = useAppSelector(
        (state) => state.pdfReportReducer.reportSectionsObj?.[sectionName]?.pagesInSection,
    );
    const prevHeightRef = useRef(0);

    const splitPages = () => {
        const contentElement = contentRef.current;
        if (!contentElement) return;

        const contentHeight = contentElement.scrollHeight / MM_TO_PX;
        if (contentHeight) {
            if (contentHeight !== prevHeightRef.current) {
                prevHeightRef.current = contentHeight;
                const availableHeight = PAGE_HEIGHT_MM - 2 * PAGE_MARGIN_MM;
                const numPages = Math.ceil(contentHeight / availableHeight);
                setPages(numPages);
            }
        } else {
            setPages(0);
        }
    };

    useEffect(() => {
        if (!contentRef.current) return;

        splitPages();
        const resizeObserver = new ResizeObserver(() => {
            splitPages();
        });

        resizeObserver.observe(contentRef.current);

        return () => resizeObserver.disconnect();
    }, [children]);

    useEffect(() => {
        if (sectionName && pages) {
            if (
                [
                    REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_INDIVIDUAL,
                    REPORT_SECTION_NAMES.AUDITED_FINANCIALS,
                    REPORT_SECTION_NAMES.COMPLIANCES_SUMMARY,
                ].includes(sectionName)
            ) {
                dispatch(
                    incrementPagesCountForSection({
                        sectionName,
                    }),
                );
            } else {
                dispatch(
                    updatePagesCountForSection({
                        sectionName,
                        pagesCount: pages,
                    }),
                );
            }
        }

        return () => {
            dispatch(
                updatePagesCountForSection({
                    sectionName,
                    pagesCount: 0,
                }),
            );
        };
    }, [pages, sectionName]);

    return pages === 0 ? (
        <></>
    ) : (
        <div>
            <div className="A4-Page" style={{ height: `${pages * PAGE_HEIGHT_MM}mm` }}>
                <div className="A4-Content" ref={contentRef}>
                    {children}
                </div>

                <img className={'PoweredByAica'} src={poweredIcon} alt="Powered by AICA" />
            </div>
        </div>
    );
}

export default A4Page;
