import { useEffect, useState } from 'react';
import { DebtProductRowData, Metric } from './debtProductTypes';
import { useAppSelector } from '../../app/hooks';
import { USER_TYPE } from '../../utils/constants/user';
import { fetchConfigByConfigName } from '../../store/investor/investorApiHelper';
import { fetchAllPecData } from '../../store/investor/apiHelpers';
import { getCompanyDealMetrics, getCompanyMetrics, getDebtProductsData } from '../../store/debtProducts/apiHelper_debtProducts';
import { Mixpanel } from '../../utils/mixpanel';
import {
    CLICK_PROBABILITY_HELP,
    EXPAND_PRODUCT,
    OPEN_EDIT_PROFILE,
    VIEW_DEBT_PRODUCTS_PAGE,
} from '../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../utils/dateUtils';
import { convertToFirstLetterCapital, getPlatformUserId, isEmpty } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { setDebtProductsFilters } from '../../store/company/action';
import { useDispatch } from 'react-redux';
import useDADataTransformations, { MasterData } from '../../common/DealApplicationDrawer/customHooks/useDADataTransformations';
import { createDeal, getDealData, handleDealApplicationDrawer, updateDeal, updateDealApplicationData } from '../../store/investor/action';
import { fetchDataByConfig } from '../../common/ManageDealDrawer/ManageDealDrawerService';
import { polusBaseUrl } from '../../utils/axios-interceptor';
import { DEAL_CREATOR_TYPE } from '../../common/ManageDealDrawer/DealDrawerConstants';

interface DebtProductsState {
    headers: Array<{ id: string; label: string }>;
    rows: DebtProductRowData[];
}

export const AUDITED_FINANCIAL_KEY_METRIC: Metric[] = [
    'Revenue',
    'RevenueGrowthPercentage',
    'EbitaMargin',
    'PAT',
    'CashAndCashEquivalent',
    'TotalCurrentAssets',
    'TotalCurrentLiabilities',
    'NetWorkingCapital',
    'LongTermDebt',
    'ShortTermDebt',
    'TangibleNetWorth',
    'TotalCosts',
    'TradePayables',
    'TradeReceivables',
    'Inventories',
];

const useDebtProductLogic = ({ params, selectedCompany }) => {
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const [showDetailOverview, setShowDetailOverview] = useState<null | DebtProductRowData>(null);
    const [debtProductsData, setDebtProductsData] = useState<DebtProductsState>({
        headers: [],
        rows: [],
    });
    const dealApplicationDrawerState = useAppSelector((state) => state.investor.dealApplicationDrawer);
    const [noPecExists, setNoPECExists] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [defaultFilters, setDefaultFilters] = useState({});
    const [companyMetricsDataExists, setCompanyMetricsDataExists] = useState(false);
    const [companyMetricsData, setCompanyMetricsData] = useState<any>({});
    const [dealPayload, setDealPayload] = useState<{
        products: string[];
        lenders: Map<string, string[]>;
    }>({
        products: [],
        lenders: new Map(),
    });
    const [selectionCount, setSelectionCount] = useState(0);
    const [draftSelectionCount, setDraftSelectionCount] = useState(0);
    const debtProductsFilters = useAppSelector((state) => state.company.debtProductsFilters);

    const showEmptyScreen = noPecExists || !companyMetricsDataExists;

    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const user = useAppSelector((state) => state.user);
    const dealProducts = useAppSelector((state) => state.investor.dealApplicationDrawer.allProducts);
    const loggedInUser = user?.userData;
    const [savingDraft, setSavingDraft] = useState(false);
    const [dealId, setDealId] = useState('');

    const isLender = user.userData.accountType === USER_TYPE.LENDER;
    const { createDealData } = useDADataTransformations({ source: 'debtProducts' });
    const platformUserId = getPlatformUserId();
    useEffect(() => {
        Mixpanel.track(VIEW_DEBT_PRODUCTS_PAGE, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    }, []);

    const getAllProducts = () => {
        const payload = {
            aggregatorId: loggedInUser?.investorOrgId,
        };
        fetchDataByConfig(payload, 'GET_ALL_PRODUCTS', 'GET', polusBaseUrl).then((response) => {
            if (response) {
                const res = response.data.responseBody.data;
                let option = Object.values(res)?.map((option: any) => {
                    return { label: option.productName, value: option.productId };
                });
                dispatch(updateDealApplicationData({ allProducts: option }));
            }
        });
    };

    useEffect(() => {
        if (!dealProducts) {
            getAllProducts();
        }
    }, []);
    const fetchDraftDeal = () => {
        dispatch(
            getDealData(
                {
                    dealBeneficiaryId: params.id as string,
                },
                {
                    onSuccess: (res) => {
                        let lenderMap = new Map();
                        Object.keys(res?.dealReceiverDetailMap)?.forEach((key) => {
                            lenderMap.set(key, res?.dealReceiverDetailMap[key]?.products);
                        });
                        setDealPayload({
                            products: res?.products,
                            lenders: lenderMap,
                        });
                        if (!isEmpty(res.dealTerms) && res.dealStatus !== 'DRAFT_DELETED') {
                            setCompanyMetricsData({ dealTerms: res.dealTerms });
                        }
                        setDealId(res?.id);
                        // Calculate the number of checkboxes selected
                        if (res.dealStatus !== 'DRAFT_DELETED') {
                            let masterProductList = new Set(res.products.map((product) => product));
                            let lenderCount = 0;
                            Object.keys(res?.dealReceiverDetailMap)?.forEach((key) => {
                                res?.dealReceiverDetailMap[key]?.products.forEach((product) => {
                                    masterProductList.add(product);
                                    lenderCount++;
                                });
                            });
                            setSelectionCount(masterProductList.size + lenderCount);
                            setDraftSelectionCount(masterProductList.size + lenderCount);
                        }
                    },
                    onError: () => {
                        setDealPayload({
                            products: [],
                            lenders: new Map(),
                        });
                    },
                },
            ),
        );
    };

    useEffect(() => {
        if (!dealApplicationDrawerState.open) {
            fetchDraftDeal();
        }
    }, [dealApplicationDrawerState.open]);

    useEffect(() => {
        if (searchParams.get('filters') !== 'true') {
            dispatch(setDebtProductsFilters({}));
        }
    }, [searchParams.get('filters')]);

    useEffect(() => {
        if (user.userData.investorOrgId && isEmpty(defaultFilters)) {
            fetchHeadersFromConfig();
            fetchCompanyMetrics(false);
        }
        if (!isEmpty(defaultFilters)) {
            fetchPECDataAndDebtProductsData(defaultFilters);
            fetchDraftDeal();
        }
    }, [user.userData.investorOrgId, defaultFilters]);

    const fetchHeadersFromConfig = () => {
        fetchConfigByConfigName({
            accountType: user.userData.accountType,
            investorOrgId: user.userData.investorOrgId,
            primaryEmail: user.userData.primaryEmail,
            assesseeOrgId: params.id as string,
            userRole: user.userData?.roles?.[0],
            configName: 'AICA_DEBT_PRODUCT',
        })
            .then((configResponse) => {
                let headers = [{ id: 'caret', label: '-' }, ...(configResponse?.additionalDetailsConfig?.columns || [])];
                // add element in the second place
                headers.splice(1, 0, { id: 'select', label: '' });
                setDebtProductsData((existing) => ({
                    ...existing,
                    headers: headers,
                }));
                setShowFilters(configResponse?.additionalDetailsConfig?.filters?.showFilters);
                let updatedFilters: Record<string, Record<string, number>> = {};
                const filterPages = configResponse.additionalDetailsConfig.filters.pages || [];

                filterPages.forEach((page) => {
                    page.config.forEach((section) => {
                        // Ensure the sectionKey exists in updatedFilters as an object
                        if (!updatedFilters[section.sectionKey]) {
                            updatedFilters[section.sectionKey] = {};
                        }

                        Object.entries(section.config).forEach(([key, value]: [string, any]) => {
                            if (value.minimumValue !== undefined && value.maximumValue !== undefined) {
                                const capitalizedKey = convertToFirstLetterCapital(key);
                                updatedFilters[section.sectionKey][`minimum${capitalizedKey}`] = value.minimumValue;
                                updatedFilters[section.sectionKey][`maximum${capitalizedKey}`] = value.maximumValue;
                            }
                        });
                    });
                });
                updatedFilters.filters['filterType'] = configResponse?.additionalDetailsConfig?.filters.filterType;
                setDefaultFilters(updatedFilters);
            })
            .catch(() => {});
    };

    const fetchCompanyMetrics = async (isClearDeal: boolean) => {
        let companyMetricsDataExists = false;
        let companyMetricsDataExistsInAuditedFinancials = false;

        const hasValidData = (data: Record<string, any>) => {
            return Object.entries(data || {}).some(([key, value]) => key !== 'externalRating' && value !== null && value !== '');
        };

        Promise.all([
            getCompanyMetrics({
                assesseeOrgId: params.id as string,
            }).then((data) => {
                if (!companyMetricsData?.dealTerms || isClearDeal) {
                    setCompanyMetricsData(data ?? {});
                }
                if (Object.keys(data || {}).length) {
                    companyMetricsDataExists = true;
                }
            }),

            getCompanyDealMetrics({
                requestPayload: {
                    requestId: getTimeStamp(),
                    parentOrgId: user.userData.investorOrgId,
                    orgId: params.id as string,
                    type: 'auditedfinancials',
                    startDate: null,
                    endDate: null,
                    frequency: 'Yearly',
                    metricList: AUDITED_FINANCIAL_KEY_METRIC,
                    taskStepsList: ['AION_AUDITED_FINANCIAL'],
                    mis: 'Balance Sheet',
                    raw: true,
                    classificationId: 30,
                },
            }).then((data) => {
                if (hasValidData(data)) {
                    companyMetricsDataExistsInAuditedFinancials = true;
                }
            }),
        ])
            .then(() => {
                if (companyMetricsDataExists || companyMetricsDataExistsInAuditedFinancials) {
                    setCompanyMetricsDataExists(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching company metrics:', error);
            });
    };

    const updateDealDataState = (value: any, key: string) => {
        setDealPayload((prevState) => {
            // Clone previous products and lenders (Map) to avoid direct mutation
            const products = [...prevState.products];
            const lenders = new Map(prevState.lenders);
            // Track how many items we're adding or removing
            let selectionDelta = 0;

            if (key === 'product') {
                // Toggle product in `products` array
                const index = products.indexOf(value);
                if (index > -1) {
                    // Item already present -> remove it
                    products.splice(index, 1);
                    // Remove the product from all lenders and update selectionDelta
                    lenders.forEach((existingProducts, lenderId) => {
                        const existingIndex = existingProducts.indexOf(value);
                        if (existingIndex > -1) {
                            existingProducts.splice(existingIndex, 1);
                            lenders.set(lenderId, existingProducts);
                            selectionDelta -= 1;
                        }
                        if (existingProducts.length === 0) {
                            lenders.delete(lenderId);
                        }
                    });
                    selectionDelta -= 1;
                } else {
                    // Item not present -> add it
                    products.push(value);
                    selectionDelta += 1;
                }
            } else if (key === 'investor') {
                // Toggle productId for a lenderId in the `lenders` Map
                const { lenderId, productId } = value;
                if (!products.includes(productId)) {
                    products.push(productId);
                    selectionDelta += 1;
                }
                const existingProducts = lenders.get(lenderId) || [];

                const existingIndex = existingProducts.indexOf(productId);
                if (existingIndex > -1) {
                    // This productId already exists for lenderId -> remove
                    existingProducts.splice(existingIndex, 1);
                    selectionDelta -= 1;

                    // If lender has no more products, remove the lender from the map
                    if (existingProducts.length === 0) {
                        lenders.delete(lenderId);
                    } else {
                        lenders.set(lenderId, existingProducts);
                    }
                } else {
                    // This productId does not exist -> add it
                    lenders.set(lenderId, [...existingProducts, productId]);
                    selectionDelta += 1;
                }
            }
            // Update the dealPayload first
            const newDealPayload = {
                ...prevState,
                products,
                lenders,
            };

            // Then adjust selectionCount by however many we added/removed
            setSelectionCount((prev) => prev + selectionDelta);

            return newDealPayload;
        });
    };

    const clearSelection = () => {
        setDealPayload({
            products: [],
            lenders: new Map(),
        });
        setSelectionCount(0);
    };
    const saveSelection = (dealStatus: string, dealId: string) => {
        // Set DRAFT spinner only for DRAFT status
        if (dealStatus === 'DRAFT') {
            setSavingDraft(true);
        }

        // 1. Prepare the base input to createDealData
        const payload = {
            dealTerms: companyMetricsData.dealTerms,
            productEnums: Array.from(new Set(dealPayload.products.map((product) => product))),
            lenderDetails: dealPayload.lenders,
        };
        // 2. Create the masterData from payload
        const masterData = createDealData(payload);

        // 3. Generate createDealPayload based on status
        const createDealPayload = getCreateDealPayload({
            dealStatus,
            dealId,
            masterData,
            platformData,
            params,
            loggedInUser,
            platformUserId,
        });

        // 4. Define a generic success handler for create/update
        const handleDealOperationSuccess = async (newDealId?: string) => {
            // if createDeal returned a newDealId
            if (newDealId) {
                setDealId(newDealId);
            }

            // If DRAFT_DELETED, reset everything
            if (dealStatus === 'DRAFT_DELETED') {
                setCompanyMetricsData({});
                setDealPayload({ products: [], lenders: new Map() });
                setSelectionCount(0);
                setDraftSelectionCount(0);
                await fetchCompanyMetrics(true);
                setTimeout(() => {
                    setSavingDraft(false);
                }, 3000);
                return;
            }

            // Otherwise, just update counters and spinner
            setDraftSelectionCount(selectionCount);
            setTimeout(() => {
                setSavingDraft(false);
            }, 3000);
        };

        // 5. Dispatch the create/update calls
        // For DRAFT or DRAFT_DELETED
        if (dealStatus === 'DRAFT' || dealStatus === 'DRAFT_DELETED') {
            // If deal already exists -> update; otherwise -> create
            if (dealId) {
                dispatch(
                    updateDeal(createDealPayload, {
                        onSuccess: () => handleDealOperationSuccess(),
                        onError: () => {
                            setTimeout(() => {
                                setSavingDraft(false);
                            }, 3000);
                        },
                    }),
                );
            } else {
                dispatch(
                    createDeal(createDealPayload, {
                        onSuccess: (newDealId: string) => handleDealOperationSuccess(newDealId),
                        onError: () => {
                            setTimeout(() => {
                                setSavingDraft(false);
                            }, 3000);
                        },
                    }),
                );
            }
        } else {
            // For other statuses -> always update, then open the drawer
            dispatch(
                updateDeal(createDealPayload, {
                    onSuccess: () => {
                        handleDealOperationSuccess();
                        dispatch(
                            handleDealApplicationDrawer({
                                open: true,
                                width: '60%',
                                source: 'debtProducts',
                                heading: 'Create Deal',
                                subHeading: selectedCompany?.orgName,
                                profileGenerationStatus: selectedCompany.profileGenerationStatus,
                                dealId,
                            }),
                        );
                    },
                }),
            );
        }
    };

    /**
     * Helper function to generate the payload for deal creation/updation
     */
    function getCreateDealPayload({
        dealStatus,
        dealId,
        masterData,
        platformData,
        params,
        loggedInUser,
        platformUserId,
    }: {
        dealStatus: string;
        dealId: string;
        masterData: MasterData | undefined;
        platformData: any;
        params: any;
        loggedInUser: any;
        platformUserId: string;
    }) {
        let basePayload: any = {
            dealTerms: {
                ...masterData?.dealTerms,
                loanTypes: masterData?.dealTerms?.['loanTypes']?.map((loanType: any) => {
                    return dealProducts?.find((product: any) => product.value === loanType)?.label;
                }),
            },
            dealProposerId: platformData?.investorOrgId,
            dealBeneficiaryId: params.id as string,
            dealCreatedBy: loggedInUser?.userName,
            userId: platformUserId,
        };
        if (dealId) {
            basePayload.dealId = dealId;
        }
        if (!dealId) {
            basePayload.owner = {
                name: loggedInUser?.userName,
                userId: getPlatformUserId(),
                activityBy: loggedInUser?.userName,
                activityDate: new Date().toISOString(),
                ownerType: DEAL_CREATOR_TYPE,
            };
        }

        if (dealStatus === 'DRAFT_DELETED') {
            return {
                ...basePayload,
                products: [],
                dealTerms: {},
                dealReceiverDetailMap: {},
                dealStatus: 'DRAFT_DELETED',
            };
        }

        // For DRAFT and other statuses leading to the same structure
        return {
            ...basePayload,
            products: masterData?.products,
            dealReceiverDetailMap: masterData?.dealReceiverDetailMap
                ? Object.fromEntries(
                      Array.from(masterData.dealReceiverDetailMap).map(([key, value]) => [
                          key,
                          {
                              dealTerms: {
                                  ...value.dealTerms,
                                  loanTypes: value.productEnums?.map((loanType: any) => {
                                      return dealProducts?.find((product: any) => product.value === loanType)?.label;
                                  }),
                              },
                              products: value.productEnums,
                          },
                      ]),
                  )
                : {},
            dealStatus: 'DRAFT',
        };
    }

    const fetchPECDataAndDebtProductsData = (payload?: any) => {
        setLoading(true);
        fetchAllPecData({
            requestPayload: {
                aggregatorId: user.userData?.investorOrgId,
                investmentPreferenceType: 'PEC',
                ...(isLender && { lenderId: user.userData?.lenderOrgId }),
            },
        })
            .then((PECdata) => {
                if (PECdata?.length) {
                    setNoPECExists(false);
                    dispatch(setDebtProductsFilters(payload));
                    fetchDebtProductsData(payload);
                } else {
                    setNoPECExists(true);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const fetchDebtProductsData = (payload?: any) => {
        getDebtProductsData({
            orgId: params.id as string,
            aggregatorId: user.userData.investorOrgId,
            ...(payload ? payload : isEmpty(debtProductsFilters) ? {} : { filters: debtProductsFilters }),
        })
            .then((data) => {
                const debtProductsWithLenders = data?.filter((rowdata) => !!rowdata?.lenders?.length) || [];
                const debtProductsWithoutLenders = data?.filter((rowdata) => !rowdata?.lenders?.length) || [];

                setDebtProductsData((e) => ({
                    ...e,
                    rows: [...debtProductsWithLenders, ...debtProductsWithoutLenders],
                }));
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error in fetching Debt Products Data', err);
                setLoading(false);
            });
    };

    const registerMixpanelForRowExpand = (rowData) => {
        Mixpanel.track(EXPAND_PRODUCT, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            productId: rowData?.productId,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    };

    const registerMixpanelForUserHelpClick = (rowData, isParentRow = false) => {
        Mixpanel.track(CLICK_PROBABILITY_HELP, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            productId: rowData?.productId,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
            ...(!isParentRow && {
                lender_id: rowData?.lenderId,
                lender_name: rowData?.lenderName,
            }),
        });
    };

    const registerMixpanelForEditProfileClicked = (source) => {
        Mixpanel.track(OPEN_EDIT_PROFILE, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            source: source,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    };

    return {
        loading,
        noPecExists,
        companyMetricsDataExists,
        showDetailOverview,
        setShowDetailOverview,
        debtProductsData,
        showEmptyScreen,
        dealDrawerState,
        setDebtProductsData,
        fetchPECDataAndDebtProductsData,
        fetchCompanyMetrics,
        updateDealDataState,
        dealPayload,
        selectionCount,
        clearSelection,
        saveSelection,
        savingDraft,
        dealId,
        draftSelectionCount,
        //Mixpanel
        registerMixpanelForRowExpand,
        registerMixpanelForUserHelpClick,
        registerMixpanelForEditProfileClicked,
        showFilters,
    };
};

export default useDebtProductLogic;
