import React, { useEffect, useMemo } from 'react';

import styles from '../../ManageDealDrawer.module.scss';
import { convertToFirstLetterCapital, isEmpty } from '../../../../utils/utils';
import {
    CONFIG_STEP,
    CONFIG_TYPE,
    CUSTOM_COMPONENT_MAP,
    DEBT_PRODUCT_FILTERS,
    IMPORTANCE_LEVELS,
    PEC_IMPORTANCE_CONSTANTS,
    UPLOAD_FILES_PREFIXES,
} from '../../DealDrawerConstants';
import Dropdown from '../../../_custom/Dropdown';
import MultiSelectDropdown from '../../../_custom/MultiSelectDropdown/MultiSelectDropdown';
import { Button, Input } from '../../../_custom';
import InvestorDealShare from '../../InvestorDealShare/InvestorDealShare';
import UploadCard from '../../../../modules/Company/UploadJourney/components/UploadCard/UploadCard';
import DealDrawerButtonComponents from '../../DealDrawerButtonComponents/DealDrawerButtonComponents';
import MultiSelectSearchDropdown from '../MultiSelectSearchDropdown/MultiSelectSearchDropdown';
import { getDataByConfig } from '../../ManageDealDrawerHelper';
import { useAppSelector } from '../../../../app/hooks';
import { RenderModal } from './RenderModal';
import PlusIcon from '../../../../assets/Svg/PlusIcon';
import { getFiscalYearSuffix } from '../../../../utils/dateUtils';
import { Mixpanel } from '../../../../utils/mixpanel';
import { ESTIMATE_METRIC } from '../../../../utils/constants/mixpanelEvents/companyEvents';

interface InputRendererProps {
    sectionKey: string;
    fieldKey: string;
    config: any;
    formData: Record<string, any>;
    handleChange: (key: string, value: any) => void;
    dealDrawerState: any;
    lenderList: { id: string; name: string }[];
    loanTypeOptions: Record<string, any>;
    sectorList: any[];
    negativeSectorList: any[];
    source: string;
    step: number;
    setDisablePrimary: (value: boolean) => void;
    auditedfinancialsMapping: Record<string, { year?: number; value: string }>;
    isWebsiteValid: boolean;
    setIsWebsiteValid: (value: boolean) => void;
    getInputValidation: (value: string, key: string) => boolean;
    getButtonIcon: (text: string) => JSX.Element;
    getButtonText: () => string;
    validateWebsite: (value: string) => void;
    isWebsitePrefilled: boolean;
    negativeLenderList: any[];
}

interface RangeValidationResult {
    isValid: boolean;
    errorMessage: string;
    importanceMissing: boolean;
}

export const InputRenderer: React.FC<InputRendererProps> = ({
    sectionKey,
    fieldKey,
    config,
    source,
    step,
    loanTypeOptions,
    handleChange,
    dealDrawerState,
    lenderList,
    formData,
    sectorList,
    negativeSectorList,
    setDisablePrimary,
    auditedfinancialsMapping,
    isWebsiteValid,
    getInputValidation,
    getButtonIcon,
    getButtonText,
    validateWebsite,
    setIsWebsiteValid,
    isWebsitePrefilled,
    negativeLenderList,
}) => {
    // Build form keys
    const formKey = sectionKey ? `${sectionKey}.${fieldKey}` : fieldKey;
    const importanceKey = sectionKey
        ? `${sectionKey}.importance${convertToFirstLetterCapital(fieldKey)}`
        : `importance${convertToFirstLetterCapital(fieldKey)}`;

    const user = useAppSelector((state) => state.user);
    const drawerContent = useAppSelector((state) => state.investor.dealApplicationDrawer);
    const [options, setOptions] = React.useState<{ label: string; value: string }[]>([]);
    const [showModal, setShowModal] = React.useState(false);

    // Check if we need to show an importance dropdown
    const hasImportance = config.importance === true;

    const fetchData = async () => {
        const res = await getDataByConfig(
            {
                parentOrgId: user.userData.investorOrgId,
            },
            config.api,
            'GET',
        );
        if (res) {
            let temp: any = [];
            Object.keys(res.lenderMap.ADMIN).forEach((key) => {
                temp.push({ label: res?.lenderMap?.ADMIN[key], value: key });
            });
            setOptions(temp);
        }
    };

    const validateRange = (
        minValue: string | undefined,
        maxValue: string | undefined,
        hasImportance: boolean,
        importanceValue?: string,
    ): RangeValidationResult => {
        let isValid = true;
        let errorMessage = '';
        let importanceMissing = false;

        if (!isEmpty(minValue) && !isEmpty(maxValue)) {
            isValid = Number(minValue) <= Number(maxValue);
            errorMessage = isValid ? '' : 'Invalid range';
            if (isValid && hasImportance && !importanceValue) {
                importanceMissing = true;
            }
        } else if (
            hasImportance &&
            importanceValue &&
            importanceValue !== PEC_IMPORTANCE_CONSTANTS.NOT_NEEDED &&
            (!minValue || !maxValue)
        ) {
            isValid = false;
            errorMessage = 'Required';
        }
        if (!isValid) setDisablePrimary(true);
        else setDisablePrimary(false);

        return { isValid, errorMessage, importanceMissing };
    };

    useEffect(() => {
        if (config.api && source === DEBT_PRODUCT_FILTERS) {
            fetchData();
        }
    }, [config.api]);

    useEffect(() => {
        let options: { value: string; label: string }[] = [];
        if (formKey === 'dealTerms.loanTypes' || formKey === 'preferredTerms.loanTypes') {
            const loanTypeOptionsTransfomred = Object.values(loanTypeOptions).map((item: any) => ({
                value: item.productName,
                label: item.productName,
            }));
            options = loanTypeOptionsTransfomred;
        } else if (formKey === 'dealTerms.negativeLenderList') {
            options = negativeLenderList.map((item) => ({
                label: item.label,
                value: item.label,
            }));
        }
        setOptions(options);
    }, [sectionKey, loanTypeOptions, negativeLenderList]);

    switch (config?.type) {
        case CONFIG_TYPE.SEARCH_DROPDOWN:
        case CONFIG_TYPE.DROPDOWN: {
            // Example logic for a basic dropdown
            const hasError =
                !!formData[formKey] && !formData[importanceKey] && formData[importanceKey] !== PEC_IMPORTANCE_CONSTANTS.NOT_NEEDED;
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div className={styles.DflexColumn} style={{ width: '100%', gap: '0.25rem' }}>
                        <Dropdown
                            enableSearch={config.type === CONFIG_TYPE.SEARCH_DROPDOWN}
                            selectedOption={{
                                id: formData[formKey],
                                label: formData[formKey],
                            }}
                            optionsList={(config.dropdownOptions || []).map((opt: any) => ({
                                id: opt._id,
                                label: opt.label,
                            }))}
                            placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                            label={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                            itemClickHandler={(option) => handleChange(formKey, option.id)}
                            style={{ width: '100%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={config.errorMessage}
                        />
                        {auditedfinancialsMapping[formKey] && (
                            <span className={styles.financialYearLabel}>
                                {auditedfinancialsMapping[formKey]?.year
                                    ? `Audited Data FY’${getFiscalYearSuffix(auditedfinancialsMapping[formKey].year)}: `
                                    : 'AICA Report: '}
                                <span>{`${auditedfinancialsMapping[formKey]?.value ?? ''}${config.unit ? ` ${config.unit}` : ''}`}</span>
                            </span>
                        )}
                    </div>
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            placeholder={'Importance'}
                            style={{ width: '30%' }}
                            hasError={hasError}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT: {
            const isValid = formData[formKey] && !formData[importanceKey];
            const isAllDone =
                !formData[formKey] && formData[importanceKey] && formData[importanceKey] !== PEC_IMPORTANCE_CONSTANTS.NOT_NEEDED;
            const selectedOptions = (formData[formKey] as string[]) || [];
            const options = (config.dropdownOptions || []).map((opt: any) => ({
                id: opt._id,
                label: opt.label,
            }));

            const isValidSelection = selectedOptions.every((selectedId) => options.some((option) => option.id === selectedId));
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <MultiSelectDropdown
                        hasError={isAllDone}
                        errorMessage={'Required'}
                        WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                        selectedOptions={isValidSelection ? selectedOptions : []}
                        options={options}
                        placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                        onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                        disabled={dealDrawerState?.readonly}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            placeholder={'Importance'}
                            hasError={isValid}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTI_MULTIPLE_SELECT: {
            /*
                This is a combination of multiple MutiSelectDropdowns 
                and a single Dropdown for importance, click the + button 
                to add more MultiSelectDropdowns. Max fields can be set in the config.
            */

            const options = (config.dropdownOptions || []).map((opt: any) => ({
                id: opt._id,
                label: opt.label,
            }));

            // Check if the last field is empty
            const isLastFieldEmpty = formData[formKey]?.[formData[formKey].length - 1]?.length === 0;

            return (
                <div>
                    {(formData[formKey] || [[]]).map((selectedOptions, index) => (
                        <div className={styles.DflexMutiDropDowns} key={index}>
                            {index > 0 && <span>or</span>}

                            <MultiSelectDropdown
                                hasError={config.isRequired && selectedOptions.length === 0}
                                errorMessage={'Required'}
                                WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                                selectedOptions={selectedOptions}
                                options={options}
                                placeholder={
                                    index === 0 ? (config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`) : ''
                                }
                                onChange={(newSelectedOptions) => {
                                    const updatedSelections = [...(formData[formKey] || [])];
                                    updatedSelections[index] = newSelectedOptions;
                                    const filteredSelections =
                                        updatedSelections.length > 1
                                            ? updatedSelections.filter((selection, idx) => idx === 0 || selection.length > 0)
                                            : updatedSelections;

                                    handleChange(formKey, filteredSelections);
                                }}
                                disabled={dealDrawerState?.readonly}
                            />

                            {hasImportance && index === 0 && (
                                <Dropdown
                                    selectedOption={{
                                        id: formData[importanceKey],
                                        label: formData[importanceKey],
                                    }}
                                    listStyles={{ minWidth: '0' }}
                                    style={{ width: '30%' }}
                                    placeholder={'Importance'}
                                    hasError={!formData[importanceKey]}
                                    errorMessage={'Required'}
                                    optionsList={IMPORTANCE_LEVELS}
                                    itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                                />
                            )}
                        </div>
                    ))}

                    {formData[formKey]?.length < config.maxFields && (
                        <Button
                            text={config.ctaText}
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                /*Add a new empty array to formData[formKey] to render a 
                                    new MultiSelectDropdown if Last Fields is not empty */
                                if (!isLastFieldEmpty) {
                                    handleChange(formKey, [...(formData[formKey] || []), []]);
                                }
                            }}
                            variant={'white'}
                            disabled={isLastFieldEmpty}
                            style={{
                                marginTop: '1.5rem',
                                border: 'none',
                                outline: 'none',
                                color: isLastFieldEmpty ? 'rgba(203, 203, 203, 1)' : 'rgba(96, 33, 179, 1)',
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '0.3rem',
                                padding: '0',
                                cursor: isLastFieldEmpty ? 'not-allowed' : 'pointer',
                                backgroundColor: 'transparent',
                            }}
                            icon={
                                <PlusIcon
                                    colour={isLastFieldEmpty ? 'rgba(203, 203, 203, 1)' : 'rgba(96, 33, 179, 1)'}
                                    width={'12'}
                                    height={'12'}
                                />
                            }
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTI_MULTIPLE_SELECT_WITH_SEARCH: {
            /*
                This is a combination of multiple MutiSelectDropdowns 
                and a single Dropdown for importance, click the + button 
                to add more MultiSelectDropdowns. Max fields can be set in the config.
            */
            let isValid = formData[formKey] && !formData[importanceKey];
            const options = useMemo(() => {
                return (config?.dropdownOptions || []).map((opt: any) => ({
                    label: opt.label,
                    value: opt.label,
                }));
            }, [config?.dropdownOptions]);

            // Check if the last field is empty
            const isLastFieldEmpty = formData[formKey]?.[formData[formKey].length - 1]?.length === 0;

            return (
                <div>
                    {(formData[formKey] || [[]]).map((selectedOptions, index) => (
                        <div className={styles.DflexMutiDropDowns} key={index}>
                            {index > 0 && <span>or</span>}
                            <MultiSelectSearchDropdown
                                options={options}
                                selectedItems={selectedOptions}
                                placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                                toggleSelection={(newSelectedOptions) => {
                                    const updatedSelections = [...(formData[formKey] || [])];
                                    updatedSelections[index] = newSelectedOptions;
                                    const filteredSelections =
                                        updatedSelections.length > 1
                                            ? updatedSelections.filter((selection, idx) => idx === 0 || selection.length > 0)
                                            : updatedSelections;

                                    handleChange(formKey, filteredSelections);
                                }}
                                wrapperStyle={index === 0 ? { width: '100%' } : { width: '70%' }}
                            />

                            {hasImportance && index === 0 && (
                                <Dropdown
                                    selectedOption={{
                                        id: formData[importanceKey],
                                        label: formData[importanceKey],
                                    }}
                                    listStyles={{ minWidth: '0' }}
                                    style={{ width: '30%' }}
                                    placeholder={'Importance'}
                                    hasError={isValid}
                                    errorMessage={'Required'}
                                    optionsList={IMPORTANCE_LEVELS}
                                    itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                                />
                            )}
                        </div>
                    ))}

                    {formData[formKey]?.length < config.maxFields && (
                        <Button
                            text={config.ctaText}
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                /*Add a new empty array to formData[formKey] to render a 
                                    new MultiSelectDropdown if Last Fields is not empty */
                                if (!isLastFieldEmpty) {
                                    handleChange(formKey, [...(formData[formKey] || []), []]);
                                }
                            }}
                            variant={'white'}
                            disabled={isLastFieldEmpty}
                            style={{
                                marginTop: '1.5rem',
                                border: 'none',
                                outline: 'none',
                                color: isLastFieldEmpty ? 'rgba(203, 203, 203, 1)' : 'rgba(96, 33, 179, 1)',
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '0.3rem',
                                padding: '0',
                                cursor: isLastFieldEmpty ? 'not-allowed' : 'pointer',
                                backgroundColor: 'transparent',
                            }}
                            icon={
                                <PlusIcon
                                    colour={isLastFieldEmpty ? 'rgba(203, 203, 203, 1)' : 'rgba(96, 33, 179, 1)'}
                                    width={'12'}
                                    height={'12'}
                                />
                            }
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.RANGE: {
            let minKey = `minimum${convertToFirstLetterCapital(fieldKey)}`;
            let maxKey = `maximum${convertToFirstLetterCapital(fieldKey)}`;
            if (sectionKey) {
                minKey = `${sectionKey}.${minKey}`;
                maxKey = `${sectionKey}.${maxKey}`;
            }
            const { isValid, errorMessage, importanceMissing } = validateRange(
                formData[minKey],
                formData[maxKey],
                hasImportance,
                formData[importanceKey],
            );

            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div style={hasImportance ? { width: '70%' } : { width: '100%' }} className={styles.RangeInput}>
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[minKey] ?? ''}
                            placeholder="Minimum"
                            onChange={(value) => handleChange(minKey, value)}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitContainer={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[maxKey] ?? ''}
                            placeholder="Maximum"
                            onChange={(value) => handleChange(maxKey, value)}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitContainer={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                    </div>
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            placeholder={'Importance'}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            hasError={importanceMissing}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.RANGE_FILTER: {
            let minKey = `minimum${convertToFirstLetterCapital(fieldKey)}`;
            let maxKey = `maximum${convertToFirstLetterCapital(fieldKey)}`;
            if (sectionKey) {
                minKey = `${sectionKey}.${minKey}`;
                maxKey = `${sectionKey}.${maxKey}`;
            }
            let isDone = formData[minKey] || formData[maxKey];
            let { isValid, errorMessage, importanceMissing } = validateRange(
                formData[minKey],
                formData[maxKey],
                hasImportance,
                formData[importanceKey],
            );

            return (
                <div className={isDone ? styles.DflexFiltersDone : styles.DflexFilters} style={{ width: '100%' }}>
                    <div className={styles.InputLabel} style={{ width: '100%' }}>
                        {config.label}
                        <Button
                            text={'Clear'}
                            type={'reset'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleChange(minKey, '');
                                handleChange(maxKey, '');
                            }}
                            variant={'white'}
                            style={{
                                visibility: isDone ? 'visible' : 'hidden',
                                marginTop: 0,
                                border: '1px solid #E7E7E7',
                                color: '#4D4D4D',
                                fontSize: '0.75rem',
                                padding: '0.25rem 0.75rem',
                            }}
                        />
                    </div>
                    <div style={hasImportance ? { width: '70%' } : { width: '100%' }} className={styles.RangeInput}>
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[minKey]}
                            placeholder="Minimum"
                            onChange={(value) => {
                                if (value > 100) return;
                                handleChange(minKey, value);
                            }}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitContainer={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[maxKey]}
                            placeholder="Maximum"
                            onChange={(value) => {
                                if (value > 100) return;
                                handleChange(maxKey, value);
                            }}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitContainer={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                    </div>
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_API_FILTER: {
            const isDone = formData[formKey];
            return (
                <div className={isDone ? styles.DflexFiltersDone : styles.DflexFilters} style={{ width: '100%' }}>
                    <div className={styles.InputLabel} style={{ width: '100%' }}>
                        {config.label}
                        <Button
                            text={'Clear'}
                            type={'reset'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleChange(formKey, '');
                            }}
                            variant={'white'}
                            style={{
                                visibility: isDone ? 'visible' : 'hidden',
                                marginTop: 0,
                                border: '1px solid #E7E7E7',
                                color: '#4D4D4D',
                                fontSize: '0.75rem',
                                padding: '0.25rem 0.75rem',
                            }}
                        />
                    </div>
                    <MultiSelectSearchDropdown
                        options={options}
                        selectedItems={(formData[formKey] as string[]) || []}
                        placeholder={'All'}
                        toggleSelection={(selectedOptions) => {
                            if (selectedOptions.length > 0) {
                                handleChange(formKey, selectedOptions);
                            } else {
                                handleChange(formKey, '');
                            }
                        }}
                    />
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_API_FILTER_INLINE:
            const isNegativeLenderList = formKey === 'dealTerms.negativeLenderList';
            return (
                <MultiSelectSearchDropdown
                    options={options}
                    selectedItems={
                        isNegativeLenderList
                            ? negativeLenderList
                                  .filter((lender) => {
                                      const selectedIds = (formData[formKey] as string[]) || [];
                                      if (Array.isArray(lender.value)) {
                                          return lender.value.some((id) => selectedIds.includes(id));
                                      } else {
                                          return selectedIds.includes(lender.value);
                                      }
                                  })
                                  .map((lender) => lender.label)
                            : (formData[formKey] as string[]) || []
                    }
                    placeholder={config.placeholder}
                    toggleSelection={(selectedOptions) => {
                        if (selectedOptions.length > 0) {
                            if (isNegativeLenderList) {
                                const allSelectedLenderIds: string[] = [];
                                for (const selectedOption of selectedOptions) {
                                    const selectedLender = negativeLenderList.find((item) => item.label === selectedOption);
                                    if (selectedLender) {
                                        if (Array.isArray(selectedLender.value)) {
                                            allSelectedLenderIds.push(...selectedLender.value);
                                        } else {
                                            allSelectedLenderIds.push(selectedLender.value);
                                        }
                                    }
                                }
                                handleChange(formKey, allSelectedLenderIds);
                            } else {
                                handleChange(formKey, selectedOptions);
                            }
                        } else {
                            handleChange(formKey, '');
                        }
                    }}
                />
            );

        case CONFIG_TYPE.MULTIPLE_SELECT_WITH_SEARCH: {
            let isValid = formData[formKey] && !formData[importanceKey];
            const options = useMemo(() => {
                return (config?.dropdownOptions || []).map((opt: any) => ({
                    label: opt.label,
                    value: opt.label,
                }));
            }, [config?.dropdownOptions]);
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <MultiSelectSearchDropdown
                        options={options}
                        selectedItems={(formData[formKey] as string[]) || []}
                        placeholder={config.placeholder}
                        toggleSelection={(selectedOptions) => {
                            if (selectedOptions.length > 0) {
                                handleChange(formKey, selectedOptions);
                            } else {
                                handleChange(formKey, '');
                            }
                        }}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            placeholder={'Importance'}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            hasError={isValid}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_API: {
            // This fetches data from an API (e.g., sector list or loan type options).
            // We assume you have them in `loanTypeOptions` or `sectorList`.
            let isValid = formData[formKey] && !formData[importanceKey];
            let isAllDone =
                !formData[formKey] && formData[importanceKey] && formData[importanceKey] !== PEC_IMPORTANCE_CONSTANTS.NOT_NEEDED;

            let options: { id: string; label: string }[] = [];
            // Example logic: if BORROWER_PROFILE => sectorList
            // if deal or preferred => loanTypeOptions, etc.
            if (sectionKey === CONFIG_STEP.preferredTerms || sectionKey === CONFIG_STEP.dealTerms) {
                options = Object.values(loanTypeOptions).map((item: any) => ({
                    id: item.productName,
                    label: item.productName,
                }));
            } else if (fieldKey === 'negativeSector') {
                options = negativeSectorList?.map((sec: any) => ({
                    id: sec._id,
                    label: sec.label,
                }));
            } else if (sectionKey === CONFIG_STEP.borrowerProfile) {
                options = sectorList?.map((sec: any) => ({
                    id: sec._id,
                    label: sec.label,
                }));
            }
            const selectedOptions = (formData[formKey] as string[]) || [];
            const isValidSelection = selectedOptions?.every((selectedId) => options.some((option) => option.id === selectedId));
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <MultiSelectDropdown
                        hasError={isAllDone}
                        errorMessage={'Required'}
                        WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                        selectedOptions={isValidSelection ? selectedOptions : []}
                        options={options}
                        placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                        onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                        disabled={dealDrawerState?.readonly}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            placeholder={'Importance'}
                            hasError={isValid}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.INPUT_WITH_MODAL_BUTTON: {
            const isWebsiteField = formKey === 'borrowerProfile.website';
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div className={styles.DflexColumn} style={{ width: '100%', gap: '0.25rem', position: 'relative' }}>
                        <Input
                            key={fieldKey}
                            type={config.type}
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[formKey] ?? ''}
                            placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                            onChange={(value) => handleChange(formKey, value)}
                            unit={config.unit}
                            style={hasImportance ? { width: '70%', marginTop: 0 } : { width: '100%', marginTop: 0 }}
                            unitContainer={{ paddingRight: '8.125rem' }}
                            unitStyle={{ paddingLeft: '3.125rem' }}
                            disabled={dealDrawerState?.readonly || (isWebsiteField && isWebsitePrefilled)}
                            errorMessage={
                                isWebsiteField ? (isWebsitePrefilled ? '' : config?.errorMessage || '') : config?.errorMessage || ''
                            }
                            isValid={
                                isWebsiteField
                                    ? isWebsitePrefilled
                                        ? true
                                        : getInputValidation(formData[formKey], formKey)
                                    : getInputValidation(formData[formKey], formKey)
                            }
                        />
                        {config?.showButton && (!isWebsiteField || !isWebsitePrefilled) && (
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: auditedfinancialsMapping[formKey] ? '15px' : '-2px',
                                    padding: '0.375rem',
                                }}
                            >
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (config?.buttonText == 'verifyWebsite') {
                                            validateWebsite(formData[formKey]);
                                        } else {
                                            setShowModal(true);
                                            Mixpanel.track(ESTIMATE_METRIC, {
                                                Metric: fieldKey,
                                                Email: user.userData.primaryEmail,
                                                pnoId: user.userData.investorOrgId,
                                                Current_url: window.location.href,
                                                Company_Name: drawerContent.subHeading,
                                            });
                                        }
                                    }}
                                    text={config?.buttonText == 'verifyWebsite' ? getButtonText() : config?.buttonText}
                                    icon={getButtonIcon(config?.buttonText)}
                                    iconStyles={{ paddingTop: '0.08rem' }}
                                    style={{
                                        backgroundColor: isWebsiteField
                                            ? getButtonText() === 'Verified'
                                                ? '#E1FFE1'
                                                : '#F9F5FF'
                                            : '#F9F5FF',
                                        color: isWebsiteField ? (getButtonText() === 'Verified' ? '#49A71B' : '#6021B3') : '#6021B3',
                                        borderRadius: '4px',
                                        fontSize: '0.75rem',
                                        border: 'none',
                                        padding: '0.375rem',
                                        width: '5.6875rem',
                                        height: '2.5rem',
                                    }}
                                />
                            </div>
                        )}
                        {auditedfinancialsMapping[formKey] && (
                            <span className={styles.financialYearLabel}>
                                {auditedfinancialsMapping[formKey]?.year
                                    ? `Audited Data FY’${getFiscalYearSuffix(auditedfinancialsMapping[formKey].year)}: `
                                    : 'AICA Report: '}
                                <span>{`${auditedfinancialsMapping[formKey]?.value ?? ''}${config.unit ? ` ${config.unit}` : ''}`}</span>
                            </span>
                        )}
                    </div>
                    {showModal && (
                        <RenderModal
                            show={showModal}
                            formKey={formKey}
                            onClose={() => setShowModal(false)}
                            modalDetails={config?.modalDetails}
                            formData={formData}
                            sectionKey={sectionKey}
                            handleChange={handleChange}
                            auditedfinancialsMapping={auditedfinancialsMapping}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_WITH_INPUT: {
            // This is a combination of a dropdown values and the corresponding input field
            const isAllDone = !formData[formKey] && formData[importanceKey];
            /*
            This key checks if we need to render the same input fields multiple times for those
            that have the same placeholder. 
            */
            const preventDuplicateInputs = config?.preventDuplicateInputs;
            const renderedPlaceholders: string[] = [];
            const selectedOptions = formData[formKey]?.map((item: { type: string }) => item.type) || [];
            const options = (config?.dropdownOptions || []).map((opt: any) => ({
                id: opt._id,
                label: opt.label,
            }));

            return (
                <div className={styles.DflexColumn}>
                    <MultiSelectDropdown
                        hasError={isAllDone}
                        errorMessage={'Required'}
                        WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                        selectedOptions={selectedOptions}
                        options={options}
                        placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                        disabled={dealDrawerState?.readonly}
                        onChange={(selected: string[]) => {
                            const updatedFormData = selected.map((type) => {
                                const existing = formData[formKey]?.find((item: any) => item.type === type);
                                return existing || { type, value: '' };
                            });
                            handleChange(formKey, updatedFormData);
                        }}
                    />

                    {config?.dropdownOptions?.map((opt: any) => {
                        const isSelected = selectedOptions.includes(opt.label);
                        const inputObj = opt.inputObj;

                        if (isSelected && inputObj) {
                            if (
                                !preventDuplicateInputs ||
                                (preventDuplicateInputs && !renderedPlaceholders.includes(inputObj.placeholder))
                            ) {
                                renderedPlaceholders.push(inputObj.placeholder);
                                return (
                                    <Input
                                        type={inputObj.type}
                                        allowNegative={inputObj.allowNegative ?? false}
                                        allowDecimal={inputObj.allowDecimal ?? true}
                                        value={formData[formKey]?.find((item: any) => item.type === opt.label)?.value || ''}
                                        placeholder={inputObj.isRequired ? inputObj.placeholder : `${inputObj.placeholder} (Optional)`}
                                        onChange={(value) => {
                                            const updatedFormData = formData[formKey]?.map((item: any) =>
                                                item.type === opt.label ? { ...item, value } : item,
                                            );
                                            handleChange(formKey, updatedFormData);
                                        }}
                                        unit={inputObj.unit}
                                        style={{ marginTop: 0, width: '100%' }}
                                        disabled={dealDrawerState?.readonly}
                                    />
                                );
                            }
                        }
                        return null;
                    })}
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_WITH_INPUT_AND_SEARCH: {
            // This is a combination of a dropdown values and the corresponding input field
            const isAllDone = !formData[formKey] && formData[importanceKey];
            /*
            This key checks if we need to render the same input fields multiple times for those
            that have the same placeholder. 
            */
            const preventDuplicateInputs = config?.preventDuplicateInputs;
            const renderedPlaceholders: string[] = [];
            const selectedOptions = formData[formKey] || [];
            const options = useMemo(() => {
                return (config?.dropdownOptions || []).map((opt: any) => ({
                    id: opt._id,
                    label: opt.label,
                    value: opt.label,
                }));
            }, [config?.dropdownOptions]);

            return (
                <div className={styles.DflexColumn}>
                    <MultiSelectSearchDropdown
                        options={options}
                        selectedItems={(formData[formKey]?.map((item) => item.type) as string[]) || []}
                        placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                        toggleSelection={(selectedOptions) => {
                            if (selectedOptions.length > 0) {
                                const updatedFormData = selectedOptions.map((item) => {
                                    const existing = formData[formKey]?.find((selectedItem: any) => selectedItem.type === item);
                                    return existing || { type: item, value: '' };
                                });
                                handleChange(formKey, updatedFormData);
                            } else {
                                handleChange(formKey, '');
                            }
                        }}
                    />

                    {config?.dropdownOptions?.map((opt: any) => {
                        const isSelected = selectedOptions.map((selectedOption) => selectedOption.type).includes(opt.label);
                        const inputObj = opt.inputObj;

                        if (isSelected && inputObj) {
                            if (
                                !preventDuplicateInputs ||
                                (preventDuplicateInputs && !renderedPlaceholders.includes(inputObj.placeholder))
                            ) {
                                renderedPlaceholders.push(inputObj.placeholder);
                                return (
                                    <Input
                                        type={inputObj.type}
                                        allowNegative={inputObj.allowNegative ?? false}
                                        allowDecimal={inputObj.allowDecimal ?? true}
                                        value={formData[formKey]?.find((item: any) => item.type === opt.label)?.value || ''}
                                        placeholder={inputObj.isRequired ? inputObj.placeholder : `${inputObj.placeholder} (Optional)`}
                                        onChange={(value) => {
                                            const updatedFormData = formData[formKey]?.map((item: any) =>
                                                item.type === opt.label ? { ...item, value } : item,
                                            );
                                            handleChange(formKey, updatedFormData);
                                        }}
                                        unit={inputObj.unit}
                                        style={{ marginTop: 0, width: '100%' }}
                                        disabled={dealDrawerState?.readonly}
                                    />
                                );
                            }
                        }
                        return null;
                    })}
                </div>
            );
        }

        case CONFIG_TYPE.DROPDOWN_WITH_CUSTOM_COMPONENT: {
            const isValid = formData[formKey] && !formData[importanceKey];
            const CUSTOM_COMPONENT = CUSTOM_COMPONENT_MAP[config.component];
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div className={styles.DflexColumn} style={{ width: '100%', gap: '0.25rem', position: 'relative' }}>
                        <CUSTOM_COMPONENT
                            placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                            value={formData[formKey] ?? ''}
                            onChange={(value) => handleChange(formKey, value)}
                            errorMessage={config?.errorMessage ?? ''}
                            isValid={isValid}
                            disabled={dealDrawerState?.readonly}
                            style={{ marginTop: 0 }}
                            optionsStyle={{
                                width: '100%',
                                maxHeight: '230px',
                                marginTop: '0.5rem',
                            }}
                        />
                    </div>

                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.INVESTOR_TOGGLE:
        case CONFIG_TYPE.RECUR_INVESTOR_TOGGLE: {
            // Example using InvestorDealShare
            return (
                <InvestorDealShare
                    source={config.type}
                    lenderList={lenderList}
                    networkLabel={config.placeholder}
                    totalInvestors={lenderList.length}
                    showInvestorList={config.type === CONFIG_TYPE.INVESTOR_TOGGLE}
                    selectedInvestors={(formData.dealReceiverIds as string[]) || []}
                    updateSelectedInvestors={(shared) => handleChange('dealReceiverIds', shared)}
                    style={{ marginTop: '-24px' }}
                />
            );
        }

        case CONFIG_TYPE.UPLOAD: {
            // Example using UploadCard
            const existingFiles = (formData[formKey] || []).filter(
                (doc: any) => doc.documentName?.includes(UPLOAD_FILES_PREFIXES.ATTACHMENT_PREFIX),
            );

            return (
                <UploadCard
                    assesseeOrgId={dealDrawerState?.companyId as string}
                    categoryId={config?.documents?.components?.[0]?.categoryId}
                    flowId={16} // or pass from hook
                    existingFiles={existingFiles.map((doc: any) => ({
                        docId: doc.docId,
                        docName: doc.documentName,
                    }))}
                    metadataUpdate={(fileInfo) =>
                        handleChange(formKey, [
                            ...(formData[formKey] || []),
                            {
                                docId: fileInfo.docId,
                                documentName: `ATTACHMENT_${fileInfo.fileName}`,
                                action: 'SEND_TO_INVESTOR',
                            },
                        ])
                    }
                    fileDeleteHandler={(fileInfo) =>
                        handleChange(
                            formKey,
                            (formData[formKey] || []).filter((doc: any) => doc.docId !== fileInfo.docId),
                        )
                    }
                    allowMultiple
                    disabled={dealDrawerState?.readonly}
                    disableDelete={dealDrawerState?.readonly}
                    style={hasImportance ? { width: '70%' } : { width: '100%' }}
                    fileNamePrefix={UPLOAD_FILES_PREFIXES.ATTACHMENT_PREFIX}
                />
            );
        }

        case CONFIG_TYPE.BUTTON: {
            // If you have custom button components or actions
            return (
                <DealDrawerButtonComponents
                    componentKey={fieldKey}
                    formData={formData}
                    source={source}
                    categoryId={config?.documents?.components?.[0]?.categoryId}
                    handleChange={handleChange}
                />
            );
        }

        default: {
            // Basic text/number input
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div className={styles.DflexColumn} style={{ width: '100%', gap: '0.25rem' }}>
                        <Input
                            key={fieldKey}
                            type={config.type}
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            allowZero={config.allowZero ?? true}
                            value={formData[formKey] ?? ''}
                            placeholder={config.isRequired ? config.placeholder : `${config.placeholder} (Optional)`}
                            onChange={(value) => handleChange(formKey, value)}
                            unit={config.unit}
                            style={hasImportance ? { width: '70%', marginTop: 0 } : { width: '100%', marginTop: 0 }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={config?.errorMessage || ''}
                            isValid={getInputValidation(formData[formKey], formKey)}
                        />
                        {/*Show audited financials date and its values*/}
                        {auditedfinancialsMapping[formKey] && (
                            <span className={styles.financialYearLabel}>
                                {auditedfinancialsMapping[formKey]?.year
                                    ? `Audited Data FY’${getFiscalYearSuffix(auditedfinancialsMapping[formKey].year)}: `
                                    : 'AICA Report: '}
                                <span>{`${auditedfinancialsMapping[formKey]?.value ?? ''}${config.unit ? ` ${config.unit}` : ''}`}</span>
                            </span>
                        )}
                    </div>
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }
    }
};
