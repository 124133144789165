import DashboardHeader from '../Dashboard/components/DashboardHeader/DashboardHeader';
import { useEffect, useState } from 'react';
import useLegacyBusinessDataFetch from './useLegacyBusinessDataFetch';
import FileExplorer from './Components/FileExplorer/FileExplorer';
import styles from './DataRoom.module.scss';
import LoadingSpinner from '../../common/_custom/LoadingSpinner/LoadingSpinner';
import { createPortal } from 'react-dom';
import useCallApiToFetchFileData from './useCallApiToFetchFileData';
import { ACCESS_TIERS, ACCESS_TYPES } from '../../store/investor/constants';
import { useAppSelector } from '../../app/hooks';
import UnlockScanButton from '../Dashboard/DashboardModules/Overview/components/Buttons/UnlockScan/UnlockScanButton';
import { useDispatch } from 'react-redux';
import { setDealDrawer, setShowUpgradeToScan, updateNeedInfoFileNumber } from '../../store/investor/action';
import AddToScanButton from '../Dashboard/DashboardModules/Overview/components/Buttons/AddToScan/AddToScanButton';
import { USER_TYPE } from '../../utils/constants/user';
import { pendingDataComplete, PendingDataIncomplete } from '../../assets/hostedassets';
import ManageDealDrawer from '../../common/ManageDealDrawer/ManageDealDrawer';
import { getDataGapDocs } from '../../common/MagicUpload/MagicUploadHelper';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import MagicUploadNotifications from '../../common/MagicUploadNotifications/MagicUploadNotifications';

export type FileNode = {
    name: string;
    type: 'file' | 'folder';
    docId?: number;
    updatedAt: string;
    innerFiles?: FileNode[];
    isNew?: any;
    newFilePresent?: boolean;
};

const DataRoom = () => {
    const { exportData, isUserRestricted, selectedCompany, refreshReportStatus, downloadPDFStatus, goBackToPortfolio } =
        useLegacyBusinessDataFetch();
    const header = document.getElementById('header') ?? document.createElement('div');
    const {
        fileData,
        isFetchingData,
        fetchFiles,
        activeFilecount,
        archivedFilecount,
        newFilePresent,
        // , isRecurCompany
    } = useCallApiToFetchFileData(selectedCompany?.orgName);
    const investorData = useAppSelector((state) => state.investor);
    const permissions = investorData.permissions;
    const isScanLockedForUser = permissions?.[ACCESS_TIERS.SCAN] === ACCESS_TYPES.LOCKED;
    const dispatch = useDispatch();
    const [showInviteCompanyModal, setShowInviteCompanyModal] = useState(false);
    const [showAddToScanModal, setShowAddToScanModal] = useState('');
    const isDemoProfile = !!selectedCompany?.demoOrg;
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useAppSelector((state) => state.user);
    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const pendingCount = useAppSelector((state) => state.investor.pendingDataCount);
    const showDataGapCTA = permissions.showDataGap;

    const getPendingDocumentsCount = async () => {
        await getDataGapDocs(
            {
                assesseeOrgId: params.id ?? '',
            },
            {
                onSuccess: (res) => {
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                },
            },
        );
    };

    useEffect(() => {
        getPendingDocumentsCount();
    }, []);

    const renderHeaderButton = () => {
        if (isUserRestricted === false) {
            if (isScanLockedForUser) return <UnlockScanButton onClick={() => dispatch(setShowUpgradeToScan(ACCESS_TIERS.SCAN))} />;
            else if (selectedCompany?.companyAccessType == ACCESS_TIERS.WATCH)
                return (
                    <AddToScanButton
                        onClick={() => {
                            setShowInviteCompanyModal(true);
                            setShowAddToScanModal('scan');
                        }}
                        accessType={selectedCompany?.companyAccessType}
                        isDemoProfile={isDemoProfile}
                    />
                );
            else if (user.userData.accountType === USER_TYPE.INVESTOR && !isDemoProfile && showDataGapCTA) {
                return (
                    <>
                        <div
                            className={clsx({
                                [styles.PedingCTA]: (pendingCount as number) > 0 || pendingCount === null,
                                [styles.PendingDone]: pendingCount === 0,
                            })}
                            onClick={() => {
                                searchParams.set('needInfo', 'true');
                                searchParams.set('showNeedInfo', 'true');
                                searchParams.set('isCompany', 'true');
                                setSearchParams(searchParams);
                            }}
                        >
                            <img src={pendingCount === 0 ? pendingDataComplete : PendingDataIncomplete} alt="pendingDataComplete" />
                            <span>{pendingCount === 0 ? 'No Data Pending' : `${pendingCount ?? ''} Data Pending`}</span>
                        </div>
                    </>
                );
            }
        }
    };

    const openCreateDealDrawer = () => {
        dispatch(
            setDealDrawer({
                open: true,
                companyId: selectedCompany?.assesseeOrgId,
                companyName: selectedCompany?.orgName,
                profileGenerationStatus: selectedCompany?.profileGenerationStatus,
            }),
        );
    };

    return (
        <div className={styles.DataRoomWrapper}>
            <MagicUploadNotifications source={'FILE'} />

            {showDealDrawer && <ManageDealDrawer source="Data Room Page" />}

            <div className={styles.DataRoomContainer}>
                {isFetchingData ? (
                    <div className={styles.LoadingContainer}>
                        <LoadingSpinner color={'#4D4D4D'} height={'50px'} />
                    </div>
                ) : (
                    <FileExplorer
                        // isRecurCompany={isRecurCompany ?? false}
                        fetchFiles={fetchFiles ?? (() => {})}
                        activeFilecount={activeFilecount}
                        archivedFilecount={archivedFilecount}
                        exportData={exportData}
                        data={fileData ?? []}
                        selectedCompany={selectedCompany}
                        newFilePresent={newFilePresent}
                    />
                )}
            </div>
        </div>
    );
};

export default DataRoom;
