import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../../../../../../app/hooks';
import { updateCompany, updateDeal, updateInvestorDeal } from '../../../../../../../../../store/investor/action';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getPlatformUserId } from '../../../../../../../../../utils/utils';

const useCommentsBoxFunctionality = (
    comments: { text: string; activityBy: string; activityDate: string; userId: string },
    assesseeOrgId: string,
    fetchData: Function | undefined,
    source: string,
    dealId: string,
    dealIdForInvestorDeal: string,
) => {
    const dispatch = useDispatch();
    const portfolioData = useAppSelector((state) => state.investor.portfolio);
    const userData = useAppSelector((state) => state.user.userData);
    const [isHovered, setIsHovered] = useState(false);

    const selectedCategory = portfolioData.fetchedType;
    const [params] = useSearchParams();
    const accessTier = params.get('type') || '';
    const [editing, setEditing] = useState<'NEW' | 'EDIT' | ''>('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [userComments, setUserComments] = useState<{
        text: string;
        activityBy: string;
        activityDate: string;
        userId: string;
    }>({
        text: '',
        activityBy: '',
        activityDate: '',
        userId: '',
    });
    useEffect(() => {
        if (comments?.text) {
            setUserComments(comments);
        }
    }, [comments]);
    const userName = useAppSelector((state) => state.user.userData.userName);
    const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUserComments({
            ...userComments,
            text: e.target.value,
        });
    };
    const submitComment = () => {
        let payload = {
            text: '',
            activityBy: '',
            activityDate: '',
            userId: '',
        };
        if (userComments.text) {
            payload = {
                ...userComments,
                activityBy: userName,
                activityDate: new Date().toISOString(),
                userId: getPlatformUserId(),
            };
        }
        if (source === 'company') {
            //api call to submit the comment
            dispatch(
                updateCompany(
                    {
                        companyComment: payload,
                        orgId: assesseeOrgId,
                    },
                    {
                        onSuccess: () => {
                            setEditing('');
                        },
                    },
                ),
            );
        } else if (source === 'investor-deal') {
            //api call to submit the comment
            dispatch(
                updateInvestorDeal({
                    dealId: dealIdForInvestorDeal,
                    investorDealId: dealId,
                    comment: payload,
                    lastUpdatedBy: userName,
                    owner: {
                        name: userData.userName,
                        userId: userData.userId,
                        activityBy: userData.userName,
                        activityDate: new Date().toISOString(),
                        ownerType: userData.accountType,
                    },
                }),
            );
            setEditing('');
        } else if (source === 'deal') {
            dispatch(
                updateDeal({
                    dealId,
                    comment: payload,
                    lastUpdatedBy: userName,
                }),
            );
            setEditing('');
        }
    };
    return {
        editing,
        setEditing,
        handleCommentsChange,
        userComments,
        submitComment,
        setUserComments,
        isHovered,
        setIsHovered,
        textareaRef,
    };
};

export default useCommentsBoxFunctionality;
