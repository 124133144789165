import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    fetchDirectorsOverview,
    initializeCreditBureauCharts,
    fetchDirectorBureauCibil,
} from '../../../../store/dashboard/creditBureau/actions';
import KeyOfficialsCreditSummary from './components/KeyOfficialsCreditSummary/KeyOfficialsCreditSummary';
import Report from './components/Report/Report';
import styles from './CreditBureau.module.scss';

import { toast } from 'react-toastify';
import AicaOverview from '../../../../common/AicaOverview/AicaOverview';
import { updateQueryParams } from '../../../../utils/searchParamUtils';

export const COMBINED_REPORT = 'creditSummaryCombinedReport';
export const DETAILED_REPORT = 'keyAccountsDetailedReport';

function CreditBureau({ selectedTabConfig, selectedCompany, getGridRowHeight }) {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const location = useLocation();

    const selectedReportQuery = searchParams.get('report');
    const selectedBureauType = searchParams.get('bureauType');
    const [selectedDirectorIdQuery, setSelectedDirectorIdQuery] = useState<string | undefined>();

    const [selectedDirector, setSelectedDirector] = useState<any>(null);
    const [selectedReport, setSelectedReport] = useState('');
    const selectedReportConfig = selectedTabConfig?.[selectedReport];
    const creditBureauData = useAppSelector((state) => state.dashboard.creditBureau);
    const directorsList = creditBureauData?.mainData?.listDirectorsOverview || [];
    useEffect(() => {
        selectedReportConfig && dispatch(initializeCreditBureauCharts(selectedReportConfig));
    }, [selectedReportConfig]);

    useEffect(() => {
        if (searchParams.get('directorId'))
            setSelectedDirectorIdQuery(searchParams.get('directorId')?.toString());
    }, [searchParams]);

    const updateSelectedDirectorAndReport = () => {
        if (creditBureauData.loadingDirectorsOverviewList) {
            return;
        }

        if (selectedDirectorIdQuery && selectedReportQuery) {
            const selectedDirector = directorsList.find(
                (d) => d.directorId === selectedDirectorIdQuery,
            );

            if (!selectedDirector) {
                toast.error('Director not found');
            } else {
                setSelectedDirector(selectedDirector);
                setSelectedReport(selectedReportQuery);
            }
        } else if (selectedReportQuery === 'creditSummaryCombinedReport') {
            setSelectedReport(selectedReportQuery);
        }
    };

    useEffect(() => {
        updateSelectedDirectorAndReport();
    }, [selectedReportQuery, selectedDirectorIdQuery, directorsList]);

    useEffect(() => {
        if (!selectedCompany?.assesseeOrgId) return;

        const payload = {
            orgId: selectedCompany.assesseeOrgId,
            reportId: selectedCompany.reportId || '',
            type: selectedBureauType !== 'All' ? selectedBureauType : null,
        };
        if (selectedBureauType === 'CIBIL') {
            dispatch(fetchDirectorBureauCibil(payload));
        } else {
            dispatch(fetchDirectorsOverview(payload));
        }
    }, [selectedCompany?.assesseeOrgId, selectedCompany?.reportId, selectedBureauType]);

    const showReport = (reportType, director: any = null) => {
        if (director?.directorId) {
            // setSelectedDirector(director);
            searchParams.set('directorId', director.directorId);
        } else {
            searchParams.delete('directorId');
        }
        searchParams.set('report', reportType);
        updateQueryParams(searchParams, setSearchParams, {}, location);
        // setSelectedReport(reportType);
    };

    const resetQueryParams = () => {
        searchParams.delete('report');
        searchParams.delete('directorId');
        updateQueryParams(searchParams, setSearchParams, {}, location);
    };

    return (
        <div className={styles.CreditBureau}>
            {!selectedReport ? (
                <>
                    <AicaOverview
                        style={{ marginBottom: '0.5rem' }}
                        module={searchParams.get('selectedTab') ?? ''}
                    />
                    <KeyOfficialsCreditSummary
                        showReport={showReport}
                        setSelectedReport={setSelectedReport}
                        isLoading={creditBureauData?.loadingDirectorsOverviewList}
                        listDirectorsOverview={directorsList}
                        assesseeOrgId={params?.id}
                        orgName={selectedCompany?.orgName}
                        selectedCompany={selectedCompany}
                    />
                </>
            ) : (
                <Report
                    selectedReport={selectedReport}
                    selectedDirector={selectedDirector}
                    setSelectedReport={setSelectedReport}
                    selectedReportConfig={selectedReportConfig}
                    selectedCompany={selectedCompany}
                    getGridRowHeight={getGridRowHeight}
                    resetQueryParams={resetQueryParams}
                />
            )}
        </div>
    );
}

export default CreditBureau;
