import { INVESTOR_ROUTES, LENDER_ROUTES } from './routesConst';

export type uiStyleType = {
    buttons?: {
        primary?: {
            textColour?: string;
            backgroundColour?: string;
        };
        secondary?: {
            textColour?: string;
            borderColour?: string;
            backgroundColour?: string;
        };
        tertiary?: {
            textColour?: string;
        };
    };
    tabs?: {
        active?: {
            textColour?: string;
            backgroundColour?: string;
        };
        inactive?: {
            textColour?: string;
            backgroundColour?: string;
        };
    };
    backgrounds?: {
        primary?: {
            backgroundColour?: string;
        };
        secondary?: {
            backgroundColour?: string;
        };
    };
    texts?: {
        primary?: {
            textColour?: string;
        };
    };
    borders?: {
        primary?: {
            borderColour?: string;
        };
    };
};

export enum ROOT_TYPE {
    BORROWER = 'borrower',
    INVESTOR = 'investor',
    LENDER = 'lender',
}

export const getRouteType = (currentPath: string): ROOT_TYPE.BORROWER | ROOT_TYPE.LENDER | ROOT_TYPE.INVESTOR => {
    if (Object.values(INVESTOR_ROUTES).some((route) => currentPath.startsWith(route))) {
        return ROOT_TYPE.INVESTOR;
    }
    if (Object.values(LENDER_ROUTES).some((route) => currentPath.startsWith(route))) {
        return ROOT_TYPE.LENDER;
    }
    return ROOT_TYPE.BORROWER;
};

export const defaultUiStyle: uiStyleType = {
    buttons: {
        primary: {
            textColour: '#FFFFFF',
            backgroundColour: '#6021b3',
        },
        secondary: {
            textColour: '#6021b3',
            borderColour: '#c7c7c7',
            backgroundColour: '#FFFFFF',
        },
        tertiary: {
            textColour: '#6021b3',
        },
    },
    tabs: {
        active: {
            textColour: '#6021b3',
            backgroundColour: '#f9f5ff',
        },
        inactive: {
            textColour: '#AAAAAA',
            backgroundColour: '#f7f7f7',
        },
    },
    backgrounds: {
        primary: {
            backgroundColour: '#6021b3',
        },
        secondary: {
            backgroundColour: '#f9f5ff',
        },
    },
    texts: {
        primary: {
            textColour: '#6021b3',
        },
    },
    borders: {
        primary: {
            borderColour: '#6021b3',
        },
    },
};

export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const TIME_DATE_FORMAT = 'hh:mm A, DD MMM YYYY';

export const MULTIPLE_LOGIN_TOAST_ERROR = 'You’re already logged in from another tab. Please log in here to continue.';
export const INVALID_SESSION_ERROR_MESSAGE = 'Session Expired ,Please Log-in Again';

export const TAB_TO_TASK_TRACKER_MAP: { [key: string]: string[] } = {
    overview: [
        'AION_KARZA_MCA_LIVE',
        'AION_FETCH_MCA_CHARGES_DATA',
        'AION_FETCH_SHAREHOLDERS_DATA',
        'AION_FETCH_SHAREHOLDING_PATTERN_DATA',
        'AION_FETCH_FUNDING_HISTORY',
        'AION_AUDITED_FINANCIAL_CHECK',
        'AION_AUDITED_FINANCIAL_CHECK_BEFORE_MCA_REFRESH',
        'AION_AUDITED_FINANCIAL_CHECK_AFTER_MCA_REFRESH',
    ],
    relatedParty: ['AION_INGEST_RELATED_PARTIES_DATA'],
    compliances: ['AION_GST_TRANSACTIONS_FETCH_INGEST'],
    legalChecks: ['AION_FETCH_LITIGATION_CLASSIFICATION_DATA', 'AION_INGEST_AML_PEP_DATA'],
    auditedFinancials: ['AION_AUDITED_FINANCIAL_UNLOCK'],
    creditBureau: ['AION_FETCH_MCA_CHARGES_DATA'], // Setting to MCA Charges for now since it should be open on report unlock
};

export enum TabModule {
    OVERVIEW = 'overview',
    RELATED_PARTY = 'relatedParty',
    COMPLIANCES = 'compliances',
    LEGAL_CHECKS = 'legalChecks',
    AUDITED_FINANCIALS = 'auditedFinancials',
    CREDIT_BUREAU = 'creditBureau',
    REVENUE = 'revenue',
    VENDOR_EXPENSES = 'vendorExpenses',
    BANKING_AND_FINANCIALS = 'bankingAndFinancials',
    reconciliation = 'reconciliation',
}
