import React, { useEffect } from 'react';
import CommonComponent from './components/Common';
import styles from './EmployeeAndWebsiteTraffic.module.scss';
import dayjs from 'dayjs';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
    CartesianGrid,
    LabelList,
} from 'recharts';

import CustomTooltip from '../../../../components/Graph/components/CustomTooltip/CustomTooltip';
import { EmployeeData, WebsiteTrafficData } from '../../../../../../store/dashboard/overview/types';
import { shortNumberFormat } from '../../../../../../utils/utils';
import { ShimmerThumbnail, ShimmerTitle, ShimmerText } from 'react-shimmer-effects';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { useAppDispatch } from '../../../../../../app/hooks';
import { updatePagesCountForSection } from '../../../../../../store/pdfReport/pdfReport_actions';

interface EmployeeAndWebsiteTrafficProps {
    employeeData?: EmployeeData;
    websiteTraffic?: WebsiteTrafficData;
    isLoading?: boolean;
    isPDFReport?: boolean;
}

const websiteTrafficTooltip =
    'The Website Traffic graph displays the monthly visitors on the website of a company.\n\nWebsite Traffic is an indicator of the interest the company is generating for it’s product, as well as the revenue being earned by the company. In case the website traffic is declining over time, a deeper analysis is needed on the relevance and popularity of the products, sub-optimal use of SEO and social media analytics, and inefficient page layout with slow loading speeds.';

const EmployeeAndWebsiteTraffic: React.FC<EmployeeAndWebsiteTrafficProps> = ({
    employeeData,
    websiteTraffic,
    isLoading,
    isPDFReport = false,
}) => {
    const dispatch = useAppDispatch();
    const showEmployeeData = !!employeeData?.momEmployeeData?.length;
    const showWebsiteTrafficData = !!websiteTraffic?.momWebsiteTraffic?.length;
    const dateFormatter = (val: string) => (val ? dayjs(val, 'YYYYMM').format("MMM'YY") : '');

    useEffect(() => {
        if (isPDFReport) {
            if (showEmployeeData) {
                dispatch(
                    updatePagesCountForSection({
                        sectionName: REPORT_SECTION_NAMES.EMPLOYEE_TREND_CHART,
                        pagesCount: 1,
                    }),
                );
            }
            if (showWebsiteTrafficData) {
                dispatch(
                    updatePagesCountForSection({
                        sectionName: REPORT_SECTION_NAMES.WEBSITE_TRAFFIC_CHART,
                        pagesCount: 1,
                    }),
                );
            }
        }
    }, [isPDFReport, showEmployeeData, showWebsiteTrafficData]);

    const customLabelRenderer = (props: any) => {
        const { x, y, value, index, payload } = props;
        const position = index === payload?.length - 1 ? 'insideLeft' : 'insideLeft';
        const dy = -5;
        const fontSize = '9px';

        return (
            <text
                x={x}
                y={y}
                dy={dy}
                fontSize={fontSize}
                textAnchor={index === payload?.length - 1 ? 'end' : 'start'}
            >
                {value}
            </text>
        );
    };

    const getChartData = (data: Array<any> = [], dataKey: string) => {
        const stroke = dataKey === 'employeeCount' ? '#3856FF' : '#6021B3';
        return (
            <ResponsiveContainer width="100%" height={250}>
                <AreaChart data={data} margin={{ top: 30, right: 10, left: -30, bottom: -10 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0.1%" stopColor={stroke} stopOpacity={0.3} />
                            <stop offset="95%" stopColor={stroke} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid
                        stroke="#E6D9FF"
                        vertical={false}
                        strokeDasharray="0 0"
                        horizontal={true}
                        horizontalCoordinatesGenerator={(props) =>
                            props.height > 250 ? [10, 20, 30] : [10, 80, 160]
                        }
                    />
                    <XAxis
                        dataKey="yearMonth"
                        tick={{ fontSize: '10px' }}
                        axisLine={{ stroke: '#000000' }}
                        tickFormatter={dateFormatter}
                    />
                    <YAxis
                        tick={{ fontSize: '9px' }}
                        axisLine={{ stroke: '#ffffff' }}
                        tickLine={false}
                        interval={1}
                        tickFormatter={shortNumberFormat}
                        dx={-2}
                    />
                    <Tooltip
                        content={
                            <CustomTooltip
                                graphName={
                                    dataKey === 'employeeCount'
                                        ? 'Employee Count'
                                        : 'Website Traffic'
                                }
                                graphData={data}
                                headingFormatter={dateFormatter}
                            />
                        }
                        wrapperStyle={{ outline: 'none' }}
                        cursor={{ fill: 'transparent' }}
                    />

                    <Area
                        type="natural"
                        dataKey={dataKey}
                        stroke={stroke}
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        dot={{ fill: '#322C60' }}
                        activeDot={{ fill: 'white', stroke: '#322C60' }}
                        strokeWidth={2}
                        connectNulls
                    >
                        {isPDFReport && (
                            <LabelList
                                dataKey={dataKey}
                                content={(props) =>
                                    customLabelRenderer({ ...props, payload: data })
                                } // dy={-5}
                                // style={{ fontSize: '9px' }}
                            />
                        )}
                    </Area>
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    const renderEmployeeContent = (data: EmployeeData) => {
        const firstDataPoint = data?.momEmployeeData?.find(
            (data) => data?.employeeCount !== undefined,
        );
        const lastDataPoint = [...(data?.momEmployeeData || [])]
            .reverse()
            .find((data) => data?.employeeCount !== undefined);

        const getPeriodText = () => {
            const dataLength = data?.momEmployeeData?.length || 0;
            const lastDataPoint = data?.momEmployeeData?.[dataLength - 1];
            if (lastDataPoint && lastDataPoint?.yearMonth)
                return `as of ${dateFormatter(lastDataPoint?.yearMonth)}`;
            return '';
        };

        return (
            <CommonComponent
                title="Employees"
                tooltipText=""
                row2Content={
                    <div className={styles.Row2}>
                        <div
                            className={styles.EmployeeRange}
                            style={{ fontSize: isPDFReport ? '11px' : '' }}
                        >
                            {data?.currentEmployeeRange || '-'}
                        </div>
                        <div
                            className={styles.Period}
                            style={{ fontSize: isPDFReport ? '11px' : '' }}
                        >
                            {getPeriodText()}
                        </div>
                        {!!data?.change && <div className={styles.Trend}>{data.change}</div>}
                        <div className={styles.Subtext}>{data?.trendText || ''}</div>
                    </div>
                }
                chart={getChartData(data.momEmployeeData || [], 'employeeCount')}
                isPDFReport={isPDFReport}
            />
        );
    };

    const renderWebsiteTrafficContent = (data: WebsiteTrafficData) => {
        const firstDataPoint = data?.momWebsiteTraffic?.find((data) => data?.traffic !== undefined);
        const lastDataPoint = [...(data?.momWebsiteTraffic || [])]
            .reverse()
            .find((data) => data?.traffic !== undefined);

        return (
            <CommonComponent
                title="Website Traffic"
                tooltipText={websiteTrafficTooltip}
                row2Content={
                    <div
                        className={styles.Row2}
                        style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                    >
                        {!!data?.change && <div className={styles.Percentage}>{data.change}</div>}
                        <div className={styles.Subtext}>
                            {data?.trendText || ''}&nbsp;
                            {dateFormatter(firstDataPoint?.yearMonth ?? '')} to{' '}
                            {dateFormatter(lastDataPoint?.yearMonth ?? '')}
                        </div>
                    </div>
                }
                chart={getChartData(data.momWebsiteTraffic || [], 'traffic')}
                isPDFReport={isPDFReport}
            />
        );
    };

    if (!showEmployeeData && !showWebsiteTrafficData) {
        return <></>;
    }

    const render = (
        <div className={styles.EmployeeAndWebsiteTraffic}>
            {showEmployeeData && employeeData && renderEmployeeContent(employeeData)}
            {showWebsiteTrafficData &&
                websiteTraffic &&
                renderWebsiteTrafficContent(websiteTraffic)}
        </div>
    );

    if (isPDFReport)
        return (showEmployeeData && employeeData) || (showWebsiteTrafficData && websiteTraffic) ? (
            <A4Page>
                {!!(showEmployeeData && employeeData) &&
                    reportSectionsToShow.includes(REPORT_SECTION_NAMES.EMPLOYEE_TREND_CHART) && (
                        <div className={styles.Card}>
                            <div className={styles.Top}>
                                <div className={styles.Title}>
                                    {REPORT_SECTION_NAMES.EMPLOYEE_TREND_CHART}
                                </div>
                            </div>
                            <div className={styles.Body}>{renderEmployeeContent(employeeData)}</div>
                        </div>
                    )}
                {!!(showWebsiteTrafficData && websiteTraffic) &&
                    reportSectionsToShow.includes(REPORT_SECTION_NAMES.WEBSITE_TRAFFIC_CHART) && (
                        <div className={styles.Card} style={{ marginTop: '24px' }}>
                            <div className={styles.Top}>
                                <div className={styles.Title}>
                                    {REPORT_SECTION_NAMES.WEBSITE_TRAFFIC_CHART}
                                </div>
                            </div>
                            <div className={styles.Body}>
                                {renderWebsiteTrafficContent(websiteTraffic)}
                            </div>
                        </div>
                    )}
            </A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default EmployeeAndWebsiteTraffic;
