import { PDF_REPORT_CONSTANTS } from './pdfReport_constants';

export const initialisePDFReportSectionsObj = (payload: string[], assesseeOrgId: string) => {
    return {
        type: PDF_REPORT_CONSTANTS.INITIALISE_PDF_REPORT,
        payload,
        assesseeOrgId,
    };
};

export const resetPDFReport = () => {
    return {
        type: PDF_REPORT_CONSTANTS.RESET_PDF_REPORT,
    };
};

export const updatePagesCountForSection = (payload: {
    sectionName: string;
    pagesCount: number;
}) => {
    return {
        type: PDF_REPORT_CONSTANTS.SET_PAGES_COUNT_FOR_SECTION,
        payload,
    };
};

export const incrementPagesCountForSection = (payload: { sectionName: string }) => {
    return {
        type: PDF_REPORT_CONSTANTS.INC_PAGES_COUNT_FOR_SECTION,
        payload,
    };
};
