import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import { fetchDataByConfig } from './ManageDealDrawerService';

export async function getDataByConfig(payload: any, url: string, httpMethod: string, baseUrl?: string) {
    try {
        let response = await fetchDataByConfig(payload, url, httpMethod, baseUrl);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data || [];
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error('Error in fetching debt products data');
                return [];
        }
    } catch (err) {
        console.error('Error in fetching debt products data', err);
        return [];
    }
}
