import { useState } from 'react';
import { COUNTRY_CODE, EMAIL, FULL_NAME, PHONE, TRIGGER_NOTIFICATION } from '../../../utils/constants/companyLogin';
import { addAssessee } from '../../../store/user/userV2/actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { toast } from 'react-toastify';
import { InviteUserFormValidations } from '../../../utils/constants/formValidations';

interface CountryCodeData {
    countryId: string;
    countryName: string;
    countryCode: string;
    callingCode: string;
    minCallingNumberLength: number;
    maxCallingNumberLength: number;
}

interface FormData {
    [FULL_NAME]: string;
    [EMAIL]: string;
    [PHONE]: string;
    [COUNTRY_CODE]: CountryCodeData | null;
    [TRIGGER_NOTIFICATION]: boolean;
}

const useAddAssessee = (assesseeOrgId, closeModal) => {
    const [formData, setFormData] = useState<FormData>({
        [FULL_NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
        [TRIGGER_NOTIFICATION]: false,
    });
    const user = useAppSelector((state) => state.user);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const inviteUserCallback = useAppSelector((state) => state.investor.inviteUserCallback);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const disabled = !(formData[FULL_NAME] && formData[EMAIL] && formData[PHONE] && formData[COUNTRY_CODE]);
    const [formErrors, setFormErrors] = useState({
        [FULL_NAME]: false,
        [EMAIL]: false,
        [PHONE]: false,
    });
    const changeFormValue = (value, key) => {
        setFormErrors((errors) => ({
            ...errors,
            [key]: false,
        }));
        setFormData((formData) => ({
            ...formData,
            [key]: value,
        }));
    };
    const onInviteUserClick = () => {
        setFormErrors({
            [FULL_NAME]: !InviteUserFormValidations.isFullNameValid(formData[FULL_NAME]),
            [EMAIL]: !InviteUserFormValidations.isEmailValid(formData[EMAIL]),
            [PHONE]: !InviteUserFormValidations.isPhoneValid(formData[PHONE], formData[COUNTRY_CODE]),
        });
        if (!InviteUserFormValidations.allValid(formData)) return;
        setLoading(true);
        dispatch(
            addAssessee({
                payload: {
                    user: {
                        emailId: formData[EMAIL],
                        mobileNumber: formData[PHONE],
                        name: formData[FULL_NAME],
                        countryCode: formData[COUNTRY_CODE]?.countryCode ?? '',
                        accountType: 'ASSESSEE',
                        accountTypes: ['ASSESSEE'],
                        journeyName: 'ADD_BORROWER_USER',
                        countryId: formData[COUNTRY_CODE]?.countryId ?? '',
                        investorId: user.userData.investorOrgId,
                        organizationId: assesseeOrgId,
                        triggerNotification: formData[TRIGGER_NOTIFICATION],
                    },
                    platformId: domainMetadata?.productName ?? '',
                },
                handlers: {
                    onSuccess: () => {
                        toast.success('User invited successfully');
                        closeModal();
                        setLoading(false);
                        inviteUserCallback?.();
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            }),
        );
    };

    return {
        formData,
        changeFormValue,
        disabled,
        loading,
        onInviteUserClick,
        setFormData,
        formErrors,
    };
};

export default useAddAssessee;
