import axios from 'axios';
import { GET, polusBaseUrl } from '../../../../../../utils/axios-interceptor';

export function fetchAllTypeOrgList(dealProposerId: string) {
    return axios({
        baseURL: polusBaseUrl,
        url: '/unifier/getAllTypeOrgList',
        method: GET,
        params: {
            dealProposerId,
        },
    });
}

export function fetchDealTermsLogs(investorDealId: string) {
    return axios({
        baseURL: polusBaseUrl,
        url: '/unifier/getDealTermsLog',
        method: GET,
        params: {
            investorDealId,
        },
    });
}

export function createOrUpdateDealTermsLog(payload: any) {
    return axios({
        baseURL: polusBaseUrl,
        url: '/unifier/createOrUpdateDealTermsLog',
        method: 'POST',
        data: payload,
    });
}
