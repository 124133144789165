import useFetchNotificationDetails from './useFetchNotificationDetails';
import PersistentConfigToolTip from '../PersistentConfigToolTip/PersistentConfigToolTip';
import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';
import { Button } from '../_custom';
import { whiteArrowTilted } from '../../assets/hostedassets';
import { useSearchParams } from 'react-router-dom';

const MagicUploadNotifications = ({ source }: { source: 'ORG' | 'FILE' }) => {
    const { untaggedCount } = useFetchNotificationDetails(source);
    const [searchParams, setSearchParams] = useSearchParams();
    const [notificationContent, setNotificationContent] = React.useState({
        icon: <></>,
        heading: <></>,
        message: '',
        buttonContainer: <></>,
    });

    const ButtonContainer = () => {
        switch (source) {
            case 'ORG':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '1rem',
                            justifyContent: 'end',
                            gap: '1rem',
                        }}
                        onClick={() => {}}
                    >
                        <Button
                            text={'Dismiss'}
                            onClick={() => {}}
                            variant={'secondary'}
                            style={{ margin: 0 }}
                        />
                        <Button
                            text={'Tag Organisation'}
                            onClick={() => {
                                searchParams.set('magicUpload', 'true');
                                searchParams.set('tabState', 'new');
                                searchParams.set('showUntaggedOrg', 'true');
                                setSearchParams(searchParams);
                            }}
                            variant={'primary'}
                            icon={whiteArrowTilted}
                            iconStyles={{ marginRight: '0.5rem', height: '1rem' }}
                            style={{ margin: 0 }}
                        />
                    </div>
                );
            case 'FILE':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '1rem',
                            justifyContent: 'end',
                            gap: '1rem',
                        }}
                        onClick={() => {}}
                    >
                        <Button
                            text={'Dismiss'}
                            onClick={() => {}}
                            variant={'secondary'}
                            style={{ margin: 0 }}
                        />
                        <Button
                            text={'Tag Data Type'}
                            onClick={() => {
                                searchParams.set('magicUpload', 'true');
                                searchParams.set('tabState', 'new');
                                searchParams.set('filters', 'removed');
                                setSearchParams(searchParams);
                            }}
                            variant={'primary'}
                            icon={whiteArrowTilted}
                            iconStyles={{ marginRight: '0.5rem', height: '1rem' }}
                            style={{ margin: 0 }}
                        />
                    </div>
                );
        }
    };

    const createNotificationData = () => {
        switch (source) {
            case 'ORG':
                setNotificationContent({
                    icon: (
                        <Player
                            src={
                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json'
                            }
                            style={{
                                height: '2rem',
                                width: '2rem',
                            }}
                            autoplay
                            keepLastFrame
                            speed={4}
                        />
                    ),
                    heading: (
                        <div style={{ display: 'flex', alignItems: 'start', gap: '0.5rem' }}>
                            <span style={{ margin: 0, marginTop: '5px' }}>
                                {untaggedCount} files shared recently by you might get lost!
                            </span>
                        </div>
                    ),
                    message:
                        'These files will not show up in data room of any organisation. Please review and tag organisation.',
                    buttonContainer: ButtonContainer(),
                });
                break;
            case 'FILE':
                setNotificationContent({
                    icon: (
                        <Player
                            src={
                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json'
                            }
                            style={{
                                height: '2rem',
                                width: '2rem',
                            }}
                            autoplay
                            keepLastFrame
                            speed={4}
                        />
                    ),
                    heading: (
                        <div style={{ display: 'flex', alignItems: 'start', gap: '0.5rem' }}>
                            <span style={{ margin: 0, marginTop: '5px' }}>
                                Data type not tagged for {untaggedCount} files!
                            </span>
                        </div>
                    ),
                    message:
                        'These files might not show in the correct folder. Please review and tag data type.',
                    buttonContainer: ButtonContainer(),
                });
        }
    };

    useEffect(() => {
        createNotificationData();
    }, [source, untaggedCount]);

    return (
        <>
            {untaggedCount !== 0 && (
                <PersistentConfigToolTip
                    icon={notificationContent.icon}
                    tooltipStyles={{ minWidth: '27rem' }}
                    heading={notificationContent.heading}
                    message={notificationContent.message}
                    anchorId={'notificationAnchor'}
                    CustomComponent={notificationContent.buttonContainer}
                    showArrow={false}
                />
            )}
            <div
                id={'notificationAnchor'}
                style={{ position: 'absolute', top: '5rem', right: '24%' }}
            />
        </>
    );
};

export default MagicUploadNotifications;
