import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './FormSideStepper.module.scss';
import { DEBT_PRODUCT_FILTERS, STEPPER_STATUS } from '../ManageDealDrawer/DealDrawerConstants';

// You can adjust these to match your app’s exact shape or interface
type NavigationStatus = string;

type StepConfig = {
    text: string;
    navigation: NavigationStatus;
    isViewOnly: boolean;
};

type FormSideStepperProps = {
    hideStepper: boolean;
    stepperConfig: StepConfig[];
    onStepClick: (index: number) => void;
    /**
     * This boolean typically indicates if the
     * current step is "valid" or "completed".
     * If `true`, you unlock the next step, etc.
     */
    statusTrigger: boolean;
    /**
     * The currently active step, 1-indexed.
     */
    step: number;
    /**
     * If `true`, we filter out any steps not marked `isViewOnly`.
     */
    isViewOnly: boolean;
    /**
     * If `true`, all steps become unlocked (complete form).
     */
    checkCompleteForm: boolean;
    isWebsiteValid: boolean;
    isLinkedInValid: boolean;
    source: string;
};

const FormSideStepper: React.FC<FormSideStepperProps> = (props) => {
    const { stepperConfig, onStepClick, statusTrigger, step, isViewOnly, checkCompleteForm, isWebsiteValid, isLinkedInValid, source } =
        props;

    const [activeStep, setActiveStep] = useState<number>(1);
    const [steppers, setSteppers] = useState<StepConfig[]>([]);
    const [stepStatus, setStepStatus] = useState<NavigationStatus[]>([]);

    // When `isViewOnly` changes, we either show only those steps or all steps
    useEffect(() => {
        if (isViewOnly) {
            setSteppers(stepperConfig.filter((item) => item.isViewOnly));
        } else {
            setSteppers(stepperConfig);
        }
    }, [isViewOnly, stepperConfig]);

    // Whenever the `step` prop changes, update `activeStep`
    useEffect(() => {
        setActiveStep(step);
    }, [step]);

    /**
     * Update the navigation status of each step
     * based on `statusTrigger` and `checkCompleteForm`.
     */
    const handleStepStatus = () => {
        let newStepStatus = [...stepStatus];

        // If entire form is complete, unlock all steps
        if (!isWebsiteValid || !isLinkedInValid) {
            for (let i = 0; i < newStepStatus.length; i++) {
                if (i === step - 1) {
                    newStepStatus[i] = STEPPER_STATUS.UNLOCKED;
                } else {
                    newStepStatus[i] = STEPPER_STATUS.LOCKED;
                }
            }
            setStepStatus(newStepStatus);
            return;
        } else if (source === 'Debt Products') {
            newStepStatus = steppers.map(() => STEPPER_STATUS.UNLOCKED);
        }
        if (checkCompleteForm) {
            newStepStatus = steppers.map(() => STEPPER_STATUS.UNLOCKED);
        }

        // If in viewOnly mode, also unlock all
        if (isViewOnly) {
            newStepStatus = steppers.map(() => STEPPER_STATUS.UNLOCKED);
        } else {
            // Otherwise, if the user "completes" the current step (statusTrigger),
            // we unlock the next step. If not, we lock steps after the current one.
            if (statusTrigger) {
                newStepStatus[step] = STEPPER_STATUS.UNLOCKED;
            } else {
                // Lock all steps from the current step onward
                for (let i = step; i < newStepStatus.length; i++) {
                    newStepStatus[i] = STEPPER_STATUS.LOCKED;
                }
            }
        }
        setStepStatus(newStepStatus);
    };
    /**
     * Initialize stepStatus on first render and
     * update it whenever `statusTrigger`, `step`, or `steppers` change.
     */
    useEffect(() => {
        // if we haven't set it yet, initialize from stepperConfig
        if (stepStatus.length === 0) {
            setStepStatus(steppers.map((item) => item.navigation));
        } else {
            handleStepStatus();
        }
    }, [statusTrigger, step, steppers, isWebsiteValid, isLinkedInValid]);

    return (
        <div style={props.hideStepper ? { display: 'none' } : {}} className={styles.FormStepperWrapper}>
            <div className={styles.StepperContainer}>
                {steppers.map((item, index) => {
                    const isUnlocked = stepStatus[index] === STEPPER_STATUS.UNLOCKED;
                    const isActive = activeStep === index + 1;
                    const isLocked = stepStatus[index] === STEPPER_STATUS.LOCKED;

                    return (
                        <div
                            key={index}
                            onClick={() => {
                                if (isUnlocked) {
                                    setActiveStep(index + 1);
                                    onStepClick(index + 1);
                                }
                            }}
                            className={clsx({
                                [styles.StepperItem]: isUnlocked,
                                [styles.StepperItemActive]: isActive,
                                [styles.StepperItemDisabled]: isLocked,
                            })}
                        >
                            <div className={styles.StepperText}>
                                <span>{index + 1}. </span>
                                <span>{item.text}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FormSideStepper;
