import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { fetchAlertNumber, fetchAlerts } from './service';

export const getAlertNumber = async (data: any) => {
    try {
        const res = await fetchAlertNumber(data);
        const responseData = res.data?.responseData;
        if (responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            return res.data;
        }
        return false;
    } catch (err) {
        return false;
    }
};

export const getAlerts = async (data: any) => {
    try {
        const res = await fetchAlerts(data);
        const responseData = res.data?.responseData;
        if (responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            return res.data.responseBody;
        }
        return false;
    } catch (err) {
        return false;
    }
};
