import React, { useState } from 'react';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowManageUserDrawer } from '../../../../store/company/action';
import ManageUser from '../../../Investor/ManageUser/ManageUser';
import Button from '../../../../common/_custom/Button/Button';
import styles from './ManageUserDrawer.module.scss';
import AddUserModal from '../../../Investor/ManageUser/ManageUserTable/components/AddUserModal';
import { COLORS } from '../../../../utils/constants/colors';

type ManageUserDrawerProps = {
    onClose?: (shouldRefetch?: boolean) => void;
    selectedUsers?: any[];
    parentCategory?: string | null;
    isOrgDrawer?: boolean;
    selectedOrgName?: string;
};

export const ManageUserDrawer = ({
    onClose,
    selectedUsers: initialSelectedUsers,
    parentCategory,
    isOrgDrawer = false,
    selectedOrgName,
}: ManageUserDrawerProps) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers || []);
    const [userAdded, setUserAdded] = useState(false);
    const organizationId = searchParams.get('orgId');
    const currentRowUser = { organizationId };

    const handleClose = () => {
        if (onClose) {
            onClose(userAdded);
        } else {
            searchParams.delete('category');
            searchParams.delete('page');
            setSearchParams(searchParams);
            dispatch(setShowManageUserDrawer(false));
        }
    };

    const handleUserAdded = (newUser: any) => {
        setSelectedUsers((prev) => [...(prev || []), newUser]);
        setUserAdded(true);
    };

    return (
        <>
            <SideDrawer
                onClose={handleClose}
                style={{
                    backgroundColor: COLORS.LIGHT_GREY_BG,
                    overflowY: 'auto',
                }}
                iconStyle={{ zIndex: 20 }}
                width={'70%'}
            >
                <div className={styles.DrawerContent}>
                    <ManageUser
                        isOrgDrawer={isOrgDrawer}
                        selectedUsers={selectedUsers}
                        parentCategory={parentCategory}
                        selectedOrgName={selectedOrgName}
                    />
                    {isOrgDrawer && (
                        <div className={styles.DrawerFooter}>
                            <div className={styles.ButtonContainer}>
                                <Button text="Close" onClick={handleClose} variant="secondary" />
                                <Button
                                    text="Add User"
                                    onClick={() => setShowAddUserModal(true)}
                                    variant="purple"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </SideDrawer>
            {showAddUserModal && (
                <AddUserModal
                    showAddUserModal={showAddUserModal}
                    setShowAddUserModal={setShowAddUserModal}
                    currentRowUser={currentRowUser}
                    onClose={() => setShowAddUserModal(false)}
                    fetchRows={() => Promise.resolve()}
                    pageNo={1}
                    onUserAdded={handleUserAdded}
                />
            )}
        </>
    );
};
