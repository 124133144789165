import axios from 'axios';
import { polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function fetchRelatedPartyDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_RELATED_PARTY_DATA,
        method: POST,
        data: {
            requestPayload: {
                requestId: getTimeStamp(),
                ...payload,
            },
        },
    });
}

const API_ENDPOINTS = {
    FETCH_RELATED_PARTY_DATA: '/unifier/getRelatedParties',
};
