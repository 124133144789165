import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { setShowManageUserDrawer } from '../../../../store/company/action';
import { fetchOwners, GetOwnerDataPayload, updateCompany } from '../../../../store/investor/action';
import { GREY_DROPDOWN_CARET } from '../../../../assets/hostedassets';
import ActionMenu from '../../../../common/ActionMenu/ActionMenu';
import StatusDropdown, {
    StatusDropdownOption,
} from '../../Portfolio/components/CompaniesList/components/Table/components/StatusDropdown/StatusDropdown';
import { OrgBasedUser, OrgBasedUsersTableProps, OrgUser } from './OrgBasedUsersTable';
import styles from '../ManageUserTable/ManageUserTable.module.scss';
import { COLORS } from '../../../../utils/constants/colors';
import { fetchCountryCodes } from '../../../../store/company/apiHelpers';
import { closeCommentsThread, setShowCommentsThread } from '../../../../store/commentsThread/commentsThread_actions';
import { COMMENTS_THREAD_SOURCES } from '../../../../store/commentsThread/commentsThread_reducer';
import { truncateTextTo2LineForComments } from '../../../../utils/utils';
import dayjs from 'dayjs';
import { removeTooltipContent, setTooltipContent } from '../../../../store/tooltip/tooltip_actions';

export const useOrgBasedUsersTable = ({
    isRowsLoading,
    isSummonUser,
    actionMenuItems,
    category,
    actionConfig,
    fetchRows,
}: OrgBasedUsersTableProps) => {
    const dispatch = useDispatch();
    const manageUserDrawerState = useAppSelector((state) => state.company.showManageUser);
    const user = useAppSelector((state) => state.user);
    const owners = useAppSelector((state) => state.investor.ownerDropdownOptions);
    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const commentsThreadState = useAppSelector((state) => state.commentsThreadReducer);

    const [selectedOrgUsers, setSelectedOrgUsers] = useState<OrgUser[]>([]);
    const [params, setParams] = useSearchParams();
    const [ownerDropdownData, setOwnerDropdownData] = useState({});
    const [localOwners, setLocalOwners] = useState<Record<string, string>>({});
    const [selectedOrgName, setSelectedOrgName] = useState<string>('');
    const [countryCodes, setCountryCodes] = useState<any[]>([]);

    const originalCategory = params.get('category');

    const handleManageUsers = (users: OrgUser[], orgId: string, orgName: string) => {
        const usersWithCallingCode = users.map((user) => ({
            ...user,
            mobileNumber:
                user.countryCode && user.mobileNumber && countryCodes.find((cc) => cc.countryCode === user.countryCode)?.callingCode
                    ? `+${countryCodes.find((cc) => cc.countryCode === user.countryCode)?.callingCode} ${user.mobileNumber}`
                    : user.mobileNumber,
        }));

        setSelectedOrgUsers(usersWithCallingCode);
        setSelectedOrgName(orgName);
        dispatch(setShowManageUserDrawer(true));
        params.set('orgId', orgId);
        setParams(params);
    };

    useEffect(() => {
        fetchCountryCodes({ onSuccess: (list) => setCountryCodes(list) });

        return () => {
            dispatch(closeCommentsThread());
        };
    }, []);

    useEffect(() => {
        if (originalCategory) dispatch(closeCommentsThread());
    }, [originalCategory]);

    const handleDrawerClose = (shouldRefetch?: boolean) => {
        dispatch(setShowManageUserDrawer(false));
        params.delete('orgId');
        setParams(params);
        if (shouldRefetch) {
            fetchRows(Number(params.get('page')) || 1);
        }
    };

    const handleDealDrawerClose = () => {
        fetchRows(Number(params.get('page')) || 1);
        params.delete('orgId');
        setParams(params);
    };

    const fetchOwnerDetails = () => {
        let payload: GetOwnerDataPayload = {
            investorOrgId: user.userData.investorOrgId,
        };
        dispatch(fetchOwners(payload));
    };

    useEffect(() => {
        fetchOwnerDetails();
    }, []);

    const truncateName = (name: string, maxLength: number = 10) => {
        return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
    };

    const prepareOwnerDropdownData = () => {
        const ownersData = {};
        owners.forEach((owner) => {
            if (!owner.name) return;
            ownersData[owner.userId] = {
                label: truncateName(owner.name),
                value: owner.userId,
                backgroundColor: COLORS.LIGHT_BG,
                labelColor: COLORS.BLACK,
                customCaret: GREY_DROPDOWN_CARET,
            };
        });
        return ownersData;
    };

    useEffect(() => {
        if (owners.length > 0) setOwnerDropdownData(prepareOwnerDropdownData());
    }, [owners]);

    const updateOwner = (orgId: string, owner: any) => {
        setLocalOwners((prev) => ({
            ...prev,
            [orgId]: owner.name,
        }));
        dispatch(
            updateCompany({
                orgId: orgId,
                orgOwner: owner,
            }),
        );
    };

    const getOwnerDropdownOption = (orgId: string, name: string): any => {
        const localOwnerName = localOwners[orgId];
        const displayName = localOwnerName || name;

        if (Object.values(ownerDropdownData).find((owner: any) => owner.label === truncateName(displayName))) {
            return Object.values(ownerDropdownData).find((owner: any) => owner.label === truncateName(displayName));
        } else {
            return {
                label: 'Select',
                value: '-',
                backgroundColor: '#F5F5F5',
                labelColor: '#B4B4B4',
                customCaret: GREY_DROPDOWN_CARET,
            };
        }
    };

    const onCommentsClick = (row) => {
        if (commentsThreadState.showCommentsThread) {
            if (
                commentsThreadState.source === COMMENTS_THREAD_SOURCES.MANAGE_PARTNERS_LENDERS &&
                commentsThreadState.organizationId === row.organizationId
            ) {
                dispatch(closeCommentsThread());
            }
        }
        dispatch(
            setShowCommentsThread({
                source: COMMENTS_THREAD_SOURCES.MANAGE_PARTNERS_LENDERS,
                organizationId: row.organizationId,
                headerText: row.organizationName,
            }),
        );
    };

    const onMouseEnterComment = (e, text) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(
            setTooltipContent({
                content: text,
                target: e.currentTarget,
            }),
        );
    };

    const onMouseLeaveComment = () => {
        removeTooltipContent(dispatch);
    };

    const getRowText = (row: OrgBasedUser, header: { id: number; label: string }) => {
        if (isRowsLoading) return '';

        const labelMap: Record<string, () => React.ReactNode> = {
            [`${category} Name`]: () => <div title={row?.organizationDisplayName || '-'}>{row?.organizationDisplayName || '-'}</div>,

            'Lender Type': () => <div title={row?.organizationType || '-'}>{row?.organizationType || '-'}</div>,

            Users: () => {
                if (!row?.userData || row.userData.length === 0) return '-';
                const userCount = row.userData.length;
                const firstUser = row.userData?.[0];

                return (
                    <div className={styles.UsersCell} role="button" title={row.userData.map((user) => user.name).join(`, \n`)}>
                        <span className={styles.FirstUser}>{firstUser?.name || '-'}</span>
                        {userCount > 1 && <span className={styles.RemainingCount}>{`, +${userCount - 1} more`}</span>}
                    </div>
                );
            },
            [`${category} Owner`]: () => (
                <div style={{ position: 'relative' }} title={row?.organizationOwner || 'Select'}>
                    <StatusDropdown
                        dropdownOptions={Object.values(
                            ownerDropdownData ?? {
                                label: 'Select',
                                value: '-',
                                backgroundColor: '#F5F5F5',
                                labelColor: '#B4B4B4',
                                customCaret: GREY_DROPDOWN_CARET,
                            },
                        )}
                        selectedOption={getOwnerDropdownOption(row.organizationId, row?.organizationOwner)}
                        selectOption={(option: StatusDropdownOption) =>
                            updateOwner(row.organizationId, {
                                name: option.label,
                                userId: option.value,
                                activityBy: user.userData.userName,
                                activityDate: new Date().toISOString(),
                            })
                        }
                        disabled={isSummonUser}
                    />
                </div>
            ),
            'Internal Comments': () => (
                <div
                    className={styles.CommentsBox}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCommentsClick(row);
                    }}
                >
                    {!row?.comment ? (
                        <div className={styles.AddComment}>Add comment</div>
                    ) : (
                        <div
                            className={styles.Comment}
                            onMouseEnter={(e) => onMouseEnterComment(e, row?.comment?.text)}
                            onMouseLeave={onMouseLeaveComment}
                        >
                            {truncateTextTo2LineForComments(row?.comment?.text, true)}
                            <div className={styles.Date}>
                                {row?.comment?.activityDate &&
                                    `${dayjs(row?.comment?.activityDate).format('hh:mm A, DD MMM YYYY')} by ${row?.comment?.activityBy}`}
                            </div>
                            {}
                        </div>
                    )}
                </div>
            ),
            Actions: () => (
                <div>
                    <ActionMenu
                        triggerPayload={{
                            organizationId: row?.organizationId,
                            organizationName: row?.organizationName,
                            selectedUsers: row?.userData,
                            userId: row?.userData?.[0]?.userId,
                            userType: row?.userData?.[0]?.userType,
                        }}
                        rowData={{
                            mainConfig: {
                                actionConfig,
                            },
                        }}
                        actionMenu={{
                            ...actionMenuItems,
                            'Manage User': {
                                action: (payload) =>
                                    handleManageUsers(payload?.selectedUsers, payload?.organizationId, payload?.organizationName),
                            },
                        }}
                        source="MANAGE_USERS"
                        disabled={isSummonUser}
                    />
                </div>
            ),
        };

        return labelMap?.[header.label]?.() ?? '-';
    };

    return {
        // States
        manageUserDrawerState,
        selectedOrgUsers,
        selectedOrgName,
        showDealDrawer,
        dealDrawerState,

        // Constants
        originalCategory,

        // Methods
        handleManageUsers,
        handleDrawerClose,
        getRowText,
        handleDealDrawerClose,
        commentsThreadState,
    };
};
