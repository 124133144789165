import { useState } from 'react';
import { FileNode } from './DataRoom';
import { getFolderStructure } from '../../store/dataRoom/apiHelper';
import {
    downloadFile,
    downloadFileUsingURLHelper,
    getPresignedUrl,
} from '../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { toast } from 'react-toastify';
import { getTimeStamp } from '../../utils/dateUtils';
import { Mixpanel } from '../../utils/mixpanel';
import { useAppSelector } from '../../app/hooks';
import {
    AICA_DATA_ROOM_FILE_DOWNLOADED,
    AICA_DATA_ROOM_FILE_VIEWED,
    AICA_DATA_ROOM_NEW_FILE_DOWNLOADED,
} from '../../utils/constants/mixpanelEvents/investorEvents';
import { getObjAndFileNameS3 } from '../../utils/utils';
import { deleteDoc } from '../../common/MagicUpload/MagicUploadHelper';
import { useParams } from 'react-router-dom';

const useFetchDataRoomFiles = (fetchFiles?: Function) => {
    const [showFile, setShowFile] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [downloadingNew, setDownloadingNew] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>('');
    const user = useAppSelector((state) => state.user.userData);
    const companyName = useAppSelector((state) => state.investor.selectedCompany)?.orgName;
    const userEmail = user.primaryEmail;
    const userName = user.userName;
    const params = useParams();

    const handleFileClick = async (docId: number | string, name: string) => {
        if (!isNaN(Number(docId))) {
            const res = await getPresignedUrl({ docId: Number(docId) }, false);
            if (res) {
                Mixpanel.track(AICA_DATA_ROOM_FILE_VIEWED, {
                    userEmail,
                    companyName,
                    userName,
                    fileType: res.fileType,
                });
                if (res.fileType === 'application/pdf') {
                    setShowFile(true);
                    setFileUrl(res.uri);
                } else {
                    await downloadFile(docId, () => {}, name);
                }
            } else {
                toast.error('Error in fetching file');
            }
        } else {
            const { obj, extention }: any = getObjAndFileNameS3(docId);
            const bucketName = obj?.bucket;
            const res = await downloadFileUsingURLHelper(obj.url, bucketName);
            if (res) {
                Mixpanel.track(AICA_DATA_ROOM_FILE_VIEWED, {
                    userEmail,
                    companyName,
                    userName,
                    fileType: extention,
                });
                const fileRes = await fetch(res);
                if (fileRes.status === 200) {
                    const blob = await fileRes.blob();
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name ?? 'file';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error('Error in downloading file');
                }
            }
        }
    };

    const downloadTheFile = async (docId: number | string, name: string) => {
        if (!isNaN(Number(docId))) {
            await downloadFile(docId, () => {}, name);
            fetchFiles && fetchFiles();
        } else {
            const { obj }: any = getObjAndFileNameS3(docId);
            const bucketName = obj?.bucket;

            const res = await downloadFileUsingURLHelper(obj.url, bucketName);
            if (res) {
                const fileRes = await fetch(res);
                if (fileRes.status === 200) {
                    const blob = await fileRes.blob();
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name ?? 'file';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error('Error in downloading file');
                }
            }
        }
    };
    const downloadTheFolder = async (data: FileNode[], name: string, isForNewDownload: boolean) => {
        isForNewDownload ? setDownloadingNew(true) : setDownloading(true);
        const payload = {
            parentPath: name,
            requestId: getTimeStamp(),
            fileNodes: data,
            isForNewDownload,
            downloadByOrgId: localStorage.getItem('user_org_id'),
        };
        Mixpanel.track(isForNewDownload ? AICA_DATA_ROOM_NEW_FILE_DOWNLOADED : AICA_DATA_ROOM_FILE_DOWNLOADED, {
            userEmail,
            companyName,
            userName,
        });
        const res = await getFolderStructure(payload);
        if (res) {
            let blob = new Blob([res], { type: 'octet/stream' });
            let fileName = `${name}.zip`;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            toast.success('Folder Downloaded Successfully');
            isForNewDownload ? setDownloadingNew(false) : setDownloading(false);
        } else {
            toast.error('Error in fetching folder');
            isForNewDownload ? setDownloadingNew(false) : setDownloading(false);
        }
        fetchFiles && fetchFiles();
    };

    const handleDeleteFile = async (
        docIds: (number | string)[], // Ensure this can accept both number and string types
        name: string,
        masterFolderName: string,
        isArchive: boolean = false,
    ) => {
        let result = false;

        const payload = {
            docIds: docIds.map(String), // Ensure all docIds are strings
            requestId: getTimeStamp(), // Assuming getTimeStamp() returns a valid timestamp string
            orgId: params.id, // Your orgId from params
            pnoId: user.investorOrgId, // Your pnoId from user
            docType: masterFolderName, // The type of document you're working with
            archive: isArchive, // boolean indicating whether to archive or delete
        };

        // Call deleteDoc with the constructed payload
        await deleteDoc(payload, {
            onSuccess: () => {
                result = true;
                isArchive ? toast.success('Archived successfully') : toast.success('Deleted successfully');
                if (fetchFiles) fetchFiles();
            },
        });

        return result;
    };
    return {
        showFile,
        handleFileClick,
        setShowFile,
        fileUrl,
        downloadTheFile,
        downloadTheFolder,
        downloading,
        handleDeleteFile,
        downloadingNew,
    };
};

export default useFetchDataRoomFiles;
