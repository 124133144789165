import axios from 'axios';
import { aicaBaseUrl, polusBaseUrl } from '../../utils/axios-interceptor';

export function fetchDataByConfig(payload: any, url: string, httpMethod: string, baseUrl?: string) {
    return axios({
        baseURL: baseUrl ?? aicaBaseUrl,
        url: API_ENDPOINTS[url],
        method: httpMethod,
        [httpMethod === 'POST' ? 'data' : 'params']: {
            ...payload,
        },
    });
}

export const fetchAllLenderProducts = (payload: any) => {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_ALL_LENDER_PRODUCTS,
        method: 'POST',
        data: payload,
    });
};

const API_ENDPOINTS = {
    GET_ALL_LENDERS: '/account/getAllLenders',
    GET_ALL_PRODUCTS: '/unifier/getAllProducts',
    GET_ALL_LENDER_PRODUCTS: '/unifier/getAllLenderProducts',
};
