import React from 'react';
import { Input, Modal, Button } from '../../../_custom';
import styles from '../../ManageDealDrawer.module.scss';
import { evaluateFormula } from '../../../../utils/utils';
import { getFiscalYearSuffix } from '../../../../utils/dateUtils';
import clsx from 'clsx';

type ModalDetails = {
    heading: string;
    subHeading: string;
    formula: {
        fallback: number;
        condition: string;
        expression: string;
    };
    inputObj: Record<
        string,
        {
            label: string;
            subLabel?: string;
            type: string;
            isRequired?: boolean;
            placeholder: string;
            unit?: string;
            disabled?: boolean;
            allowDecimal?: boolean;
            allowNegative?: boolean;
        }
    >;
    ctaButton: Record<
        string,
        {
            text: string;
            variant: string;
        }
    >;
};

type RenderModalProps = {
    show: boolean;
    onClose: () => void;
    modalDetails: ModalDetails;
    formData: Record<string, any>;
    sectionKey: string;
    formKey: string;
    handleChange: (key: string, value: any) => void;
    auditedfinancialsMapping: Record<string, { year?: number; value: string }>;
};

export const RenderModal: React.FC<RenderModalProps> = ({
    show,
    onClose,
    modalDetails,
    formData,
    sectionKey,
    formKey,
    handleChange,
    auditedfinancialsMapping,
}) => {
    const isAllDone = Object.keys(modalDetails.inputObj).every((key) => {
        const input = modalDetails.inputObj[key];
        return !input.isRequired || !!formData[`${sectionKey}.${key}`];
    });

    return (
        <Modal
            open={show}
            onClose={onClose}
            style={{
                height: (() => {
                    const inputCount = Object.keys(modalDetails?.inputObj || {}).length;
                    const hasAuditedData = Object.keys(auditedfinancialsMapping).some(
                        (key) => key.startsWith(`${sectionKey}.`) || key === sectionKey,
                    );

                    if (hasAuditedData) {
                        return inputCount >= 3 ? '32rem' : '30rem';
                    }
                    return inputCount >= 3 ? '30rem' : '28.125rem';
                })(),
            }}
            closeIconStyle={{
                marginTop: '1.5rem',
            }}
        >
            <div
                className={clsx(styles.ModalContainer, {
                    [styles.ModalContainerExtraGap]: Object.keys(modalDetails?.inputObj ?? {}).length === 2,
                    [styles.ModalContainerExtraWithLessGap]: Object.keys(modalDetails?.inputObj ?? {}).length >= 3,
                    [styles.ModalContainerWithAuditedData]: Object.keys(auditedfinancialsMapping).some((key) =>
                        key.startsWith(`${sectionKey}.`),
                    ),
                })}
            >
                <div className={styles.ModalHeader}>{modalDetails?.heading}</div>
                <div className={styles.SubHeading}>{modalDetails?.subHeading}</div>
                {/* Render the modal Inputs */}
                <div className={styles.InputList}>
                    {modalDetails?.inputObj &&
                        Object.entries(modalDetails.inputObj).map(([key, input]: [string, any], index: number) => (
                            <div className={styles.InputItem} key={index}>
                                {/* <div
                                        className={styles.InputLabel}
                                        style={{ marginTop: '1.5rem' }}
                                    >
                                        <div>{input.label}</div>
                                        {input.subLabel && (
                                            <div className={styles.SubLabel}>{input.subLabel}</div>
                                        )}
                                    </div> */}
                                <div className={styles.InputField}>
                                    <Input
                                        key={index}
                                        type={input.type}
                                        allowDecimal={input.allowDecimal ?? false}
                                        allowNegative={input.allowNegative ?? false}
                                        placeholder={input.isRequired ? input.placeholder : `${input.placeholder} (Optional)`}
                                        value={formData[`${sectionKey}.${key}`] ?? ''}
                                        onChange={(value) => handleChange(`${sectionKey}.${key}`, value)}
                                        unit={input.unit}
                                        disabled={input.disabled}
                                        style={{
                                            marginTop: '1.5rem',
                                        }}
                                    />
                                    {auditedfinancialsMapping[`${sectionKey}.${key}`] && (
                                        <span className={styles.financialYearLabel}>
                                            {auditedfinancialsMapping[`${sectionKey}.${key}`]?.year
                                                ? `Audited Data FY'${getFiscalYearSuffix(
                                                      auditedfinancialsMapping[`${sectionKey}.${key}`].year,
                                                  )}: `
                                                : 'AICA Report: '}
                                            <span>
                                                {`${auditedfinancialsMapping[`${sectionKey}.${key}`]?.value ?? ''}${
                                                    input.unit ? ` ${input.unit}` : ''
                                                }`}
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles.CtaButtons}>
                    {modalDetails?.ctaButton &&
                        Object.entries(modalDetails.ctaButton).map(([key, button]: [string, any], index: number) => (
                            <Button
                                key={index}
                                text={button.text}
                                variant={button.variant}
                                disabled={button.variant === 'primary' && !isAllDone}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (button.variant === 'primary') {
                                        // Calculate the values based upon the condition from the formula
                                        const variables = Object.keys(formData).reduce(
                                            (acc, key) => {
                                                if (key.startsWith(`${sectionKey}.`)) {
                                                    const variableName = key.replace(`${sectionKey}.`, '');
                                                    acc[variableName] = parseFloat(formData[key]) || 0;
                                                }
                                                return acc;
                                            },
                                            {} as Record<string, number>,
                                        );
                                        const formula = modalDetails.formula;
                                        if (formula) {
                                            const result = evaluateFormula(formula, variables);
                                            const finalResult = Number.isInteger(result)
                                                ? result.toString()
                                                : parseFloat(result?.toFixed(4)).toString();
                                            handleChange(`${formKey}`, finalResult);
                                        }
                                    }
                                    onClose();
                                }}
                                style={{
                                    width: '50%',
                                    marginTop: '0',
                                    ...(button.variant === 'primary' ? { color: '#FFFFFF' } : {}),
                                }}
                            />
                        ))}
                </div>
            </div>
        </Modal>
    );
};
