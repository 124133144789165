import { companyBlack, userBlack, warning } from '../../../../assets/hostedassets';

export const AION_INGEST_RELATED_PARTIES_DATA = 'AION_INGEST_RELATED_PARTIES_DATA';

export const SEVERITY_ENUM = {
    0: 'NA',
    1: 'LOW',
    2: 'MEDIUM',
    3: 'HIGH',
    4: 'SEVERE',
};

export const SEVERITY_DEG = {
    NA: 0,
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
    SEVERE: 4,
};

export const getImage = (type: string) => {
    switch (type) {
        case 'PERSON':
            return userBlack;
        case 'COMPANY':
        case 'LIMITED_LIABILITY_PARTNERSHIP':
            return companyBlack;
        default:
            return warning;
    }
};

export function getRelatedPartyHeaders(isPDFReport = false) {
    return [
        { key: 'relatedPartyName', label: 'Related Party Name' },
        {
            key: 'relation',
            label: 'Relation',
            tooltip:
                'It describes the type of connection between the company and the related party, such as ownership, control, common directors etc.',
        },
        {
            key: 'relationTo',
            label: 'Relation To',
        },
        {
            key: 'risk',
            label: 'Risk',
            tooltip:
                'It displays the potential risk associated with the related party, categorized by severity (Severe, High, Medium, Low).',
        },
        ...(!isPDFReport ? [{ key: 'alerts', label: 'Alerts' }] : []),
    ];
}

export const getBannerStatusColors = (status: string) => {
    let color = '#BCBCBC';
    let banner = '#F7F7F7';
    switch (status) {
        case 'SEVERE':
            color = '#FFFFFF';
            banner = '#DF666A';
            break;
        case 'HIGH':
            color = '#DF666A';
            banner = '#FFF4F5';
            break;
        case 'MEDIUM':
            color = '#FF7A0E';
            banner = '#FFF4EB';
            break;
        case 'LOW':
            color = '#FFB800';
            banner = '#FFFCEB';
            break;
        default:
            return { color: '#BCBCBC', banner: '#F7F7F7' };
    }
    return {
        color,
        banner,
    };
};

export const getEdgeTypeLabels = (edgeType: string) => {
    switch (edgeType) {
        case 'DIRECTOR':
            return 'Directors';
        case 'FORMER_DIRECTOR':
            return 'Former Directors';
        case 'HOLDING_COMPANY':
            return 'Holding Companies';
        case 'SUBSIDIARY':
            return 'Subsidiaries';
        case 'JOINT_VENTURE':
            return 'Joint Ventures';
        case 'ASSOCIATE':
            return 'Associates';
        case 'SISTER_CONCERN':
            return 'Sister Concerns';
        case 'SHAREHOLDER':
            return 'Shareholders';
        default:
            return edgeType;
    }
};

export const relationTypeMap = {
    PERSON: {
        COMPANY: {
            DIRECTOR: 'is a Director',
            FORMER_DIRECTOR: 'is a Former Director',
        },
        LIMITED_LIABILITY_PARTNERSHIP: {
            DIRECTOR: 'is a Director',
            FORMER_DIRECTOR: 'is a Former Director',
        },
    },
    COMPANY: {
        PERSON: {
            DIRECTOR: 'has a Director',
            FORMER_DIRECTOR: 'has a Former Director',
            SHAREHOLDER: 'has a Shareholder',
        },
        COMPANY: {
            HOLDING_COMPANY: 'has a Holding Company',
            SUBSIDIARY: 'is a Subsidiary',
            SISTER_CONCERN: 'has a Sister Concern',
            JOINT_VENTURE: 'has a Joint Venture',
            ASSOCIATE: 'is an Associate',
        },
        LIMITED_LIABILITY_PARTNERSHIP: {
            HOLDING_COMPANY: 'has a Holding Company',
            SUBSIDIARY: 'is a Subsidiary',
            SISTER_CONCERN: 'has a Sister Concern',
            JOINT_VENTURE: 'has a Joint Venture',
            ASSOCIATE: 'is an Associate',
        },
    },
    LIMITED_LIABILITY_PARTNERSHIP: {
        PERSON: {
            DIRECTOR: 'has a Director',
            FORMER_DIRECTOR: 'has a Former Director',
            SHAREHOLDER: 'has a Shareholder',
        },
        COMPANY: {
            HOLDING_COMPANY: 'has a Holding Company',
            SUBSIDIARY: 'is a Subsidiary',
            SISTER_CONCERN: 'has a Sister Concern',
            JOINT_VENTURE: 'has a Joint Venture',
            ASSOCIATE: 'is an Associate',
        },
        LIMITED_LIABILITY_PARTNERSHIP: {
            HOLDING_COMPANY: 'has a Holding Company',
            SUBSIDIARY: 'is a Subsidiary',
            SISTER_CONCERN: 'has a Sister Concern',
            JOINT_VENTURE: 'has a Joint Venture',
            ASSOCIATE: 'is an Associate',
        },
    },
};
