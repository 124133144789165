import styles from './CheckBox.module.scss';

type CheckBoxProps = {
    label: string;
    checked: boolean;
    onCheck: (checked: boolean) => void;
    variant: 'primary' | 'secondary' | 'tertiary';
    needPadding?: boolean;
    style?: React.CSSProperties;
    labelStyles?: React.CSSProperties;
};

const CheckBox = (props: CheckBoxProps) => {
    const { label, checked, onCheck, variant, needPadding, labelStyles } = props;
    return (
        <div
            className={`${styles.CheckBox} ${needPadding ? styles.NeedPadding : ''}`}
            style={props.style}
        >
            <input
                type="checkbox"
                id={label}
                checked={checked}
                onChange={(e) => onCheck(e.target.checked)}
            />
            <label htmlFor={label} style={labelStyles} className={styles[variant]}>
                {label}
            </label>
        </div>
    );
};

export default CheckBox;
