import axios from 'axios';
import { POST } from '../../../utils/axios-interceptor';

export function fetchSearchResults(payload: any, cancelToken?: any) {
    return axios({
        method: POST,
        url: API_ENDPOINTS.GLOBAL_SEARCH,
        data: payload,
        cancelToken, // Attach the token if present
    });
}

const API_ENDPOINTS = {
    GLOBAL_SEARCH: '/portfolio/global/search',
};
