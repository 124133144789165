import axios from 'axios';
import { polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function fetchRevenueVendorExpenseTableDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_REVENUE_VENDOR_EXPENSE_DATA,
        method: POST,
        data: {
            ...payload,
            requestId: getTimeStamp(),
        },
    });
}

export function fetchGstr3bDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_GSTR3B_DATA,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
                requestId: getTimeStamp(),
            },
        },
    });
}

export function getGstReportsService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_GST_REPORTS,
        method: POST,
        data: {
            ...payload,
            requestId: getTimeStamp(),
        },
    });
}

const API_ENDPOINTS = {
    FETCH_REVENUE_VENDOR_EXPENSE_DATA: '/data/gstInvoicesPivotData',
    FETCH_GSTR3B_DATA: '/unifier/getGstr3bData',
    GET_GST_REPORTS: 'data/org/gst/getGSTReports',
};
