import { ShowCommentsThreadAction, ShowCommentsThreadActionPayload } from './commentsThread';
import { CONSTANTS } from './commentsThread_constants';

export const setIsFetchingData = (payload: boolean) => ({
    type: CONSTANTS.SET_IS_FETCHING_DATA,
    payload,
});

export const setShowCommentsThread = (payload: ShowCommentsThreadActionPayload): ShowCommentsThreadAction => ({
    type: CONSTANTS.SET_SHOW_COMMENTS_THREAD,
    payload,
});

export const resetAndHideCommentsThread = () => ({
    type: CONSTANTS.CLOSE_COMMENTS_THREAD,
});

export const closeCommentsThread = () => ({
    type: CONSTANTS.INITIATE_CLOSE_COMMENTS_THREAD,
});

export const setCommentsThreadData = (payload: { data: any[]; headerText?: string }) => ({
    type: CONSTANTS.SET_COMMENTS_THREAD_DATA,
    payload,
});

export const addCommentToThread = (payload: any) => ({
    type: CONSTANTS.ADD_COMMENT_TO_THREAD,
    payload,
});

export const setRefetchFlags = (payload: { flagKey: string; status: boolean }) => ({
    type: CONSTANTS.SET_REFETCH_FLAG,
    payload,
});
