import React, { useState } from 'react';

import { Button, Modal } from '../_custom';
import { PURPLE_WARNING_CIRCLE } from '../../assets/hostedassets';

import styles from './ConfirmationPopup.module.scss';

type Props = {
    title: string;
    subTitle: string;
    confirmCta: string;
    cancelCta: string;
    open: boolean;
    onConfirm: (onStart?: Function, onSuccess?: Function) => void;
    onCancel: () => void;
};

const ConfirmationPopup: React.FC<Props> = ({
    title,
    subTitle,
    confirmCta,
    cancelCta,
    open,
    onConfirm,
    onCancel,
}) => {
    const [loading, setLoading] = useState(false);
    return (
        <Modal open={open} onClose={onCancel} size="flexible" withoutCloseButton>
            <div className={styles.ConfirmationPopup}>
                <div className={styles.WarningIcon}>
                    <img src={PURPLE_WARNING_CIRCLE} alt="Confirmation Icon" width={'40px'} />
                </div>
                <div className={styles.ConfirmationText}>
                    <h3 className={styles.Title}>{title}</h3>
                    <p className={styles.SubTitle}>{subTitle}</p>
                </div>
                <div className={styles.CtaWrap}>
                    <Button
                        text={cancelCta}
                        onClick={onCancel}
                        variant="purpleinverted"
                        style={{ marginTop: '0' }}
                    />
                    <Button
                        text={confirmCta}
                        onClick={() => {
                            onConfirm(
                                () => setLoading(true),
                                () => setLoading(false),
                            );
                        }}
                        variant="purple"
                        loading={loading}
                        style={{ marginTop: '0' }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationPopup;
