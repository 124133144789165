import React from 'react';
import styles from './KeyCompetitors.module.scss';
import AIBadge from '../AIBadge/AIBadge';
import { COMPETITOR_IMG_FALLBACK, organisationIcon } from '../../../../../../assets/hostedassets';
import linkedIn from '../../../../../../assets/linkedin.svg';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import clsx from 'clsx';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import orgIcon from './orgIcon.svg';

interface KeyCompetitorsProps {
    competitorsInfo;
    isPDFReport: boolean;
}

const KeyCompetitors: React.FC<KeyCompetitorsProps> = ({
    competitorsInfo,
    isPDFReport = false,
}) => {
    if (!competitorsInfo?.competitors?.length) return <></>;
    const render = (
        <div className={clsx(styles.KeyCompetitors, { [styles.PDFReport]: isPDFReport })}>
            <div className={styles.Top}>
                <div className={styles.Title}>Key Competitors</div>
                <div className={styles.Right}>
                    <AIBadge />
                </div>
            </div>

            <div className={styles.Body}>
                <div className={clsx(styles.Container)}>
                    {competitorsInfo?.competitors?.map((competitor, i) => (
                        <div className={styles.Card} key={i}>
                            <div className={styles.Image}>
                                <img
                                    src={isPDFReport ? orgIcon : competitor?.imageUrl || orgIcon}
                                    alt="logo"
                                    width="100%"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = orgIcon;
                                    }}
                                />
                            </div>

                            <div className={styles.Title}>
                                {competitor.displayName ?? competitor.name ?? '-'}
                            </div>

                            <div className={styles.Text}>{competitor?.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    if (isPDFReport)
        return competitorsInfo?.competitors?.length &&
            reportSectionsToShow.includes(REPORT_SECTION_NAMES.KEY_COMPETITORS) ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.KEY_COMPETITORS}>{render}</A4Page>
        ) : (
            <></>
        );
    else return render;
};

export default KeyCompetitors;
