import { ConfigBlock, FieldConfig } from '../Components/Page/PageTypes';
import { DealRecieverDetailMap, MasterData } from './useDADataTransformations';
import { convertToFirstLetterCapital, isEmpty } from '../../../utils/utils';

/**
 * The result of validating the form data against the config blocks.
 */
interface ValidationResult {
    isValid: boolean;
    errors: string[];
}

/**
 * Validate the data in formData against a list of config blocks.
 *
 * @param configBlocks - An array of configuration blocks to validate against
 * @param formData - The MasterData from your hook (products, dealTerms, dealReceiverDetailMap, etc.)
 */
export function validateFormData(
    configBlocks: ConfigBlock[],
    formData: MasterData,
): ValidationResult {
    const errors: string[] = [];

    /**
     * A helper function to get the data segment for a particular sectionKey.
     * If `sectionKey` is undefined or null, we treat `formData` as the direct container.
     */
    function getSectionData(sectionKey: string | null | undefined): any {
        if (!sectionKey) {
            // If there's no sectionKey, just return the top-level formData
            return formData;
        }

        // For a typical scenario:
        // If sectionKey === 'dealTerms', then we use formData.dealTerms
        // If sectionKey === 'products', etc., adjust to your structure
        // Change the logic below if you store certain sectionKeys in different places.
        return (formData as any)[sectionKey];
    }

    /**
     * Validate a single field against the form data.
     *
     * @param fieldKey - The key in the config
     * @param fieldConfig - The metadata for the field
     * @param sectionData - The subset of formData that corresponds to the configBlock's sectionKey
     * @param prefixForErrors - A prefix to help clarify which section/investor we’re validating (optional)
     */
    function validateField(
        fieldKey: string,
        fieldConfig: FieldConfig,
        sectionData: any,
        prefixForErrors: string = '',
    ) {
        if (fieldConfig.type === 'range') {
            const capitalizedKey = convertToFirstLetterCapital(fieldKey ?? '');
            const minKey = `minimum${capitalizedKey}`;
            const maxKey = `maximum${capitalizedKey}`;

            const minVal = sectionData?.[minKey];
            const maxVal = sectionData?.[maxKey];
            if (!isEmpty(minVal) && !isEmpty(maxVal)) {
                if (Number(minVal) > Number(maxVal)) {
                    errors.push(
                        `${prefixForErrors}Minimum value of "${fieldKey}" should be less than or equal to maximum value.`,
                    );
                }
            }
        }

        // If the field is required, check if data is present and valid
        if (fieldConfig.isRequired) {
            if (fieldConfig.type === 'range') {
                // For a "range", we might store the min/max as "minimum<FieldKey>" / "maximum<FieldKey>"
                const capitalizedKey = convertToFirstLetterCapital(fieldKey ?? '');
                const minKey = `minimum${capitalizedKey}`;
                const maxKey = `maximum${capitalizedKey}`;

                const minVal = sectionData?.[minKey];
                const maxVal = sectionData?.[maxKey];

                if (!minVal || !maxVal) {
                    errors.push(
                        `${prefixForErrors}Range field "${fieldKey}" is required but missing minimum or maximum value.`,
                    );
                }
                // Additional numeric checks can go here (e.g., minVal <= maxVal).
            } else {
                const value = sectionData?.[fieldKey];
                // For arrays (multi-select), you might check .length === 0
                // For strings or numbers, you might check null/undefined or empty string
                if (
                    value == null ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'string' && !value.trim())
                ) {
                    errors.push(
                        `${prefixForErrors}Field "${fieldKey}" is required but is missing or empty.`,
                    );
                }
            }
        }
    }

    // Main loop over the configBlocks
    configBlocks?.forEach((configBlock) => {
        const sectionData = getSectionData(configBlock.sectionKey);

        // For each field in the configBlock, validate
        Object.entries(configBlock.config).forEach(([fieldKey, fieldConfig]) => {
            validateField(fieldKey, fieldConfig, sectionData);
        });
    });

    return {
        isValid: errors.length === 0,
        errors,
    };
}

export const validateDealRecievers = (lenders: DealRecieverDetailMap) => {
    const errors: string[] = [];
    for (const [key, value] of lenders) {
        if (value.productEnums.length === 0) {
            errors.push(`Product is required for ${key}`);
        }
        if (Object.keys(value.dealTerms).length === 0) {
            errors.push(`Deal terms are required for ${key}`);
        }
        if (value?.dealTerms?.['loanTypes'].length === 0) {
            errors.push(`Loan types are required for ${key}`);
        }
    }
    return {
        isValid: errors.length === 0,
        errors,
    };
};
