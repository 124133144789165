import React, { useEffect, useRef, useState } from 'react';
import styles from './FundingHistory.module.scss';
import { fetchFundingHistoryData } from '../../../../../../store/dashboard/overview/overview_apiHelper';
import { useParams } from 'react-router-dom';
import { downloadDarkGray } from '../../../../../../assets/hostedassets';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { downloadFile } from '../../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useAppSelector } from '../../../../../../app/hooks';
import { TabModule } from '../../../../../../utils/constants/commonConstants';

interface FundingHistoryData {
    fundingHistoryData: FundingHistoryRow[];
    lastUpdatedAt: string;
}

type FundingHistoryRow = {
    allotmentDate: string;
    docId: string;
    fundingAmount: string;
    id: string;
    instrument: string;
    orgId: string;
    valuation: string;
    updatedAt: string;
    createdAt: string;
};

const headers = [
    { id: 'allotmentDate', label: 'Allotment Date' },
    { id: 'fundingType', label: 'Funding Type' },
    { id: 'fundingAmount', label: 'Funding Amount' },
    { id: 'valuation', label: 'Valuation' },
    { id: 'instrument', label: 'Instruments' },
    { id: 'docId', label: '' },
];

const AION_FETCH_FUNDING_HISTORY = 'AION_FETCH_FUNDING_HISTORY';

function FundingHistory({ isPDFReport = false }) {
    const MAX_POLLS = 10;
    const POLLING_TIME_INTERVAL_MS = 20000;
    const params = useParams();

    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);

    const [loading, setLoading] = useState(true);
    const [fundingHistory, setFundingHistory] = useState<null | FundingHistoryData>(null);
    const [taskTrackerStatus, setTaskTrackerStatus] = useState(null);

    const [fileDownloadingIndex, setFileDownloadingIndex] = useState<number | null>(null);

    const taskTrackerFailed = taskTrackerStatus === 'FAILED';
    const ingestingData = taskTrackerStatus === 'TODO' || taskTrackerStatus === 'PROCESSING';
    const showFundingData = !ingestingData && !!fundingHistory?.fundingHistoryData?.length;

    const pollingRef = useRef<any>(null);
    const pollingCountRef = useRef(0);

    useEffect(() => {
        fetchData();

        return () => {
            pollingRef.current && clearInterval(pollingRef.current);
        };
    }, []);

    useEffect(() => {
        if (modulePollingList.includes(TabModule.OVERVIEW)) {
            fetchData();
        }
    }, [modulePollingList]);

    const fetchData = (isPolling = false) => {
        if (!isPolling) setLoading(true);
        else pollingCountRef.current += 1;
        fetchFundingHistoryData({
            orgId: params.id as string,
            assesseeOrgId: params.id as string,
            taskStepsList: [AION_FETCH_FUNDING_HISTORY],
        })
            .then((res) => {
                const taskStatus = res?.taskTrackerData?.[AION_FETCH_FUNDING_HISTORY];
                setFundingHistory({
                    fundingHistoryData: res?.fundingHistoryData || [],
                    lastUpdatedAt: res?.lastUpdatedDate?.[0]?.lastUpdatedAt || '',
                });
                setTaskTrackerStatus(res?.taskTrackerData?.[AION_FETCH_FUNDING_HISTORY] || 'NOT_AVAILABLE');

                if (['TODO', 'PROCESSING'].includes(taskStatus) && !pollingRef.current) {
                    pollingRef.current = setInterval(() => {
                        fetchData(true);
                    }, POLLING_TIME_INTERVAL_MS);
                } else if ((['COMPLETE', 'FAILED'].includes(taskStatus) && pollingRef.current) || pollingCountRef.current > MAX_POLLS) {
                    clearInterval(pollingRef.current);
                }
            })
            .catch((err) => {
                console.error('Error in fetch funding history', err);
            })
            .finally(() => setLoading(false));
    };

    const downloadAttachment = (docId, rowIndex) => {
        setFileDownloadingIndex(rowIndex);
        downloadFile(Number(docId)).finally(() => {
            setFileDownloadingIndex(null);
        });
    };

    const getRowItem = (headerId, fundingData, rowIndex) => {
        switch (headerId) {
            case 'docId':
                return (
                    <div className={styles.DocIconContainer}>
                        {fileDownloadingIndex === rowIndex ? (
                            <LoadingSpinner height={'16px'} color={'#6021b3'} style={{ margin: 0, padding: 0 }} />
                        ) : (
                            <div className={styles.DownloadContainer}>
                                <img
                                    alt={'err'}
                                    className={styles.DownloadIcon}
                                    src={downloadDarkGray}
                                    height={'14.5px'}
                                    onClick={() => {
                                        downloadAttachment(fundingData?.docId, rowIndex);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                );
            default:
                return fundingData?.[headerId] ?? '-';
        }
    };

    if (['TODO', 'PROCESSING', 'NOT_AVAILABLE', null].includes(taskTrackerStatus)) return <></>;

    const render = (
        <div className={styles.FundingHistory}>
            <div className={styles.Top} style={{ borderBottom: showFundingData ? '' : 'none' }}>
                <div className={styles.Title}>Funding History</div>
                <div className={styles.Right}>
                    {ingestingData ? (
                        <LoadingSpinner height={'20px'} color={'#6021b3'} />
                    ) : showFundingData ? (
                        dayjs(fundingHistory?.lastUpdatedAt).isValid() ? (
                            <div className={styles.LastUpdated}>
                                Last updated on {dayjs(fundingHistory?.lastUpdatedAt).format('DD MMM YYYY')}
                            </div>
                        ) : (
                            ''
                        )
                    ) : (
                        <div className={styles.LastUpdated}>
                            {taskTrackerFailed ? 'No data available' : 'No funding information available'}
                        </div>
                    )}
                </div>
            </div>

            {showFundingData && (
                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <div className={styles.Headers}>
                            {headers.map((header) => (
                                <div
                                    className={clsx(styles.Header, {
                                        [styles.AlignRight]: ['fundingAmount', 'valuation'].includes(header.id),
                                    })}
                                    key={header.id}
                                >
                                    {header.label}
                                </div>
                            ))}
                        </div>
                        <div className={styles.Rows}>
                            {fundingHistory?.fundingHistoryData?.map((fundingData, rowIndex) => (
                                <div className={styles.Row} key={rowIndex}>
                                    {headers.map((header) => (
                                        <div
                                            key={header.id}
                                            className={clsx(styles.RowItem, {
                                                [styles.AlignRight]: ['fundingAmount', 'valuation'].includes(header.id),
                                            })}
                                        >
                                            {getRowItem(header?.id, fundingData, rowIndex)}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    if (isPDFReport) return <></>;
    else return render;
}

export default FundingHistory;
