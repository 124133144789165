import React, { useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Cell,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import clsx from 'clsx';

import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';
import styles from '../Graph.module.scss';

interface WaterfallGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function WaterfallGraph(props: WaterfallGraphProps) {
    const { error, height } = props;
    const { colors, hasNegative, plots, metadata } = props.graphData;
    const graphData = plots[0]?.plotValues || [];
    const [showTooltip, setShowTooltip] = useState(false);

    const getFillColor = (index) => {
        switch (index) {
            case 0:
                return colors?.primary;
            case 1:
                return colors?.secondary;
            case 2:
                return colors?.tertiary;
            case 3:
                return colors?.fourth;
            case 4:
                return colors?.fifth;
        }
    };
    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                    top: 30,
                    right: 15,
                    left: -25,
                    bottom: 5,
                }}
                barCategoryGap="25%"
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="xValue"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    interval={0}
                />
                <YAxis
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                />
                {hasNegative ? <ReferenceLine y={0} stroke="#000000" /> : null}

                <Tooltip
                    content={
                        showTooltip ? (
                            <CustomTooltip
                                graphName={props.graphName}
                                graphData={props.graphData}
                            />
                        ) : (
                            <></>
                        )
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />

                {graphData?.map((datapoint, index) => {
                    return index < graphData?.length - 1 ? (
                        <ReferenceLine
                            key={index}
                            stroke="#BCBCBC"
                            segment={[
                                {
                                    x: datapoint.period,
                                    y:
                                        index === 0
                                            ? datapoint.value
                                            : datapoint.initialValue + datapoint.value,
                                },
                                {
                                    x: graphData[index + 1]?.period,
                                    y:
                                        index === 0
                                            ? datapoint.value
                                            : datapoint.initialValue + datapoint.value,
                                },
                            ]}
                            strokeDasharray="3 3"
                        />
                    ) : null;
                })}

                <Bar
                    dataKey="yValue"
                    fill="transparent"
                    radius={[8, 8, 8, 8]}
                    stackId="a"
                    name="no-render"
                />
                <Bar
                    dataKey="yValue2"
                    fill="#000000"
                    radius={[6, 6, 6, 6]}
                    stackId="a"
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                >
                    {graphData?.map((item, index) => {
                        return <Cell key={index} fill={getFillColor(index)} />;
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default WaterfallGraph;
