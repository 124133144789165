import React from 'react';
import './Formula.scss';

interface FormulaProps {
    string: string;
}

function Formula({ string }: FormulaProps) {
    const stringArray = string?.split(' ');
    return (
        <div className="Formula">
            {stringArray?.length &&
                stringArray.map((char, i) => {
                    if (char === '+' || char === '-') return char;
                    else {
                        return (
                            <span className="Character" key={i}>
                                {char}
                            </span>
                        );
                    }
                })}
        </div>
    );
}

export default Formula;
