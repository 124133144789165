import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
    additionalDocumentsUpdateHelper,
    creditBureauUpdate,
    financialReportsUpdate,
    gstReportsUpdate,
} from '../../../store/company/apiHelpers';
// import { COMPANY_UPLOAD_TABS } from '../../../store/company/constants';
import { UpdateCreditBureauPayload } from '../../../store/company/service';
import { USER_TYPE } from '../../../utils/constants/user';
import AdditionalDocuments from './components/AdditionalDocuments/AdditionalDocuments';
import Alerts from './components/Alerts/Alerts';
import BankAccounts from './components/BankAccounts2/BankAccounts';
import CompanyCMR from './components/CompanyCMR/CompanyCMR';
import DirectorCibil from './components/DirectorCibil/DirectorCibil';
import Financials from './components/Financials/Financials';
import Finished from './components/Finished/Finished';
import GstFetch from './components/GstFetch/GstFetch';
import RawBankStatements from './components/RawBankStatements/RawBankStatements';
import UploadFooter from './components/UploadFooter/UploadFooter';
import UploadTabs from './components/UploadTabs/UploadTabs';
import styles from './UploadJourney.module.scss';
import { STEP_COMPLETION, UPLOAD_COMPONENTS } from './uploadJourneyUtils';
import ProvisionalFinancials from './components/ProvisionalFinancials/ProvisionalFinancials';
import GSTUpload from './components/GSTUpload/GSTUpload';
import CompanyKYC from './components/CompanyKYC/CompanyKYC';
import DirectorKyc from './components/DirectorKyc/DirectorKyc';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreditBureauTab from './components/CreditBureau/CreditBureauTab';
import { Tab } from '../../../common/TabSelector/TabSelector';
import clsx from 'clsx';
const { COMPANY_CMR, DIRECTOR_CIBIL, GST_REPORTS, BANK_STATEMENTS, FINANCIALS } = UPLOAD_COMPONENTS;

function UploadJourney({
    companyConfig,
    userType,
    assesseeOrgId,
    fetchOnboardingData,
    isInvestor,
    closeDrawer,
    overrideSelectedStep,
    isCreditBureauUpload = false,
}) {
    const uploadContainerRef = useRef<HTMLDivElement | null>(null);
    const configData = useAppSelector((state) => state.company.config);
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const [selectedStep, setSelectedStep] = useState('');
    const [uploadTabs, setUploadTabs] = useState<Tab[] | []>([]);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (overrideSelectedStep) {
            setSelectedStep(overrideSelectedStep);
            setUploadTabs(configData?.tabs || []);
            return;
        }
    }, [configData, overrideSelectedStep]);

    const fetchDropdownOptions = () => {
        let options: { label: string; id: number }[] = [];
        if (uploadJourneyData?.constitutionType && configData?.tabConfigMetadata) {
            options = configData?.tabConfigMetadata?.[uploadJourneyData?.constitutionType]?.additionalDocuments?.map((doc, index) => {
                return { label: doc, id: index };
            });
        }
        return options;
    };

    useEffect(() => {
        if (searchParams.get('configName') !== 'MAGIC_LINK') {
            if (uploadJourneyData?.constitutionType && configData?.tabs && configData?.tabConfigMetadata) {
                let acceptedTabList = configData?.tabConfigMetadata?.[uploadJourneyData?.constitutionType].tabs;
                setUploadTabs(configData?.tabs?.filter((tab) => acceptedTabList?.includes(tab.id)));
            } else {
                setUploadTabs(configData?.tabs || []);
            }
        }
    }, [uploadJourneyData]);

    useEffect(() => {
        if (searchParams.get('configName') === 'MAGIC_LINK' && configData?.tabConfigMetadata?.['MAGIC_LINK']) {
            let acceptedTabList: any = configData?.tabConfigMetadata?.['MAGIC_LINK'].tabs;
            setUploadTabs(configData?.tabs?.filter((tab) => acceptedTabList?.includes(tab.id)) ?? []);
        }
    }, [configData, searchParams.get('configName')]);

    useEffect(() => {
        if (configData?.selectedTabId && !overrideSelectedStep) setSelectedStep(configData?.selectedTabId ?? uploadTabs?.[0]?.id ?? '');
    }, [configData, uploadTabs.length]);

    let selectedTabConfig = configData?.tabConfigs?.[selectedStep];
    const flowId = configData?.flowId || 16;

    const creditBureauUpdateFunction = async (payload, updateKey) => {
        await creditBureauUpdate(
            {
                assesseeOrgId: assesseeOrgId,
                ...payload,
            },
            updateKey,
        );
    };

    const gstReportsUpdateFunction = async (payload, isDelete = false) => {
        await gstReportsUpdate({
            assesseeOrgId: assesseeOrgId,
            ...payload,
        });
    };

    const financialReportsUpdateFunction = async (payload) => {
        await financialReportsUpdate({
            assesseeOrgId: assesseeOrgId,
            ...payload,
        });
    };

    const additionalDocumentsUpdateFunction = async (payload) => {
        await additionalDocumentsUpdateHelper({
            assesseeOrgId: assesseeOrgId,
            additionalDoc: {
                ...payload,
            },
        });
    };

    const getRenderComponent = (componentName, categoryId) => {
        switch (componentName) {
            // case COMPANY_CMR:
            //     return (
            //         <CompanyCMR
            //             cmrData={uploadJourneyData?.cmrData}
            //             updateFunction={creditBureauUpdateFunction}
            //             userType={userType}
            //             assesseeOrgId={assesseeOrgId}
            //             categoryId={categoryId}
            //             flowId={flowId}
            //         />
            //     );
            case DIRECTOR_CIBIL:
                return (
                    <CreditBureauTab
                        directorCibilData={uploadJourneyData?.directorCibilData}
                        userType={userType}
                        updateFunction={creditBureauUpdateFunction}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                        isInvestor={isInvestor}
                        managementData={uploadJourneyData?.cibilSoftPullData?.managementList}
                    />
                );
            case UPLOAD_COMPONENTS.BANK_STATEMENTS:
            case UPLOAD_COMPONENTS.FETCH_BANK_ACCOUNTS:
                return (
                    <>
                        <BankAccounts
                            bankAccounts={uploadJourneyData?.bankAccountStatementList}
                            assesseeOrgId={assesseeOrgId}
                            fetchOnboardingData={fetchOnboardingData}
                            uploadContainerRef={uploadContainerRef}
                            categoryId={categoryId}
                            flowId={flowId}
                            isInvestor={isInvestor}
                            userType={userType}
                        />
                        {!!uploadJourneyData?.bankStatementRawData?.length && (
                            <RawBankStatements
                                bankStatementsData={uploadJourneyData?.bankStatementRawData}
                                userType={userType}
                                assesseeOrgId={assesseeOrgId}
                                isInvestor={isInvestor}
                                fetchOnboardingData={fetchOnboardingData}
                                categoryId={categoryId}
                                flowId={flowId}
                            />
                        )}
                    </>
                );
            case UPLOAD_COMPONENTS.GST_REPORTS:
                return (
                    <>
                        <GstFetch
                            isInvestor={isInvestor}
                            userType={userType}
                            rawGstData={uploadJourneyData?.gstMasterList || {}}
                            fetchData={fetchOnboardingData}
                            categoryId={categoryId}
                            flowId={flowId}
                            gstReportsData={uploadJourneyData?.gstReportData || {}}
                            updateFunction={gstReportsUpdateFunction}
                            assesseeOrgId={assesseeOrgId}
                        />
                    </>
                );
            case UPLOAD_COMPONENTS.FINANCIALS:
                return (
                    <Financials
                        isInvestor={isInvestor}
                        financialsData={uploadJourneyData?.financialReportData || {}}
                        updateFunction={financialReportsUpdateFunction}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            case UPLOAD_COMPONENTS.PROVISIONAL_FINANCIALS:
                return <ProvisionalFinancials isInvestor={userType === USER_TYPE.INVESTOR} categoryId={categoryId} flowId={flowId} />;
            case UPLOAD_COMPONENTS.ADDITONAL_DOCUMENTS:
                return (
                    <AdditionalDocuments
                        isInvestor={isInvestor}
                        dropDownOptions={fetchDropdownOptions()}
                        additionalDocsData={uploadJourneyData?.additionalDocsData || {}}
                        updateFunction={additionalDocumentsUpdateFunction}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            // return <Finished />;
            case UPLOAD_COMPONENTS.COMPANY_KYC:
                return (
                    <CompanyKYC
                        isInvestor={isInvestor}
                        constitutionType={uploadJourneyData?.constitutionType}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            case UPLOAD_COMPONENTS.DIRECTOR_KYC:
                return (
                    <DirectorKyc
                        isInvestor={isInvestor}
                        constitutionType={uploadJourneyData?.constitutionType}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                    />
                );
            default:
        }
    };

    return (
        <div className={styles.UploadJourney}>
            <div className={styles.Top}>
                {searchParams.get('configName') !== 'MAGIC_LINK' && !isCreditBureauUpload && (
                    <UploadTabs
                        selectedStep={selectedStep}
                        setSelectedStep={setSelectedStep}
                        uploadTabs={uploadTabs}
                        isInvestor={isInvestor}
                        uploadJourneyData={uploadJourneyData}
                    />
                )}

                <div
                    className={clsx(styles.UploadContainer, {
                        [styles.UploadContainerInvestor]: userType !== USER_TYPE.COMPANY,
                    })}
                    ref={uploadContainerRef}
                >
                    <Alerts userType={userType} selectedStep={selectedStep} uploadJourneyData={uploadJourneyData} />
                    <div
                        className={clsx(styles.Component, {
                            [styles.InvestorComponent]: userType !== USER_TYPE.COMPANY,
                        })}
                    >
                        {selectedTabConfig?.components?.map((component, i) => (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                                key={`${component.name}-${i}`}
                            >
                                {getRenderComponent(component.name, component.categoryId)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/*<div className={styles.Footer}>*/}
            {/*    <UploadFooter*/}
            {/*        selectedStep={selectedStep}*/}
            {/*        setSelectedStep={setSelectedStep}*/}
            {/*        uploadTabs={uploadTabs}*/}
            {/*        assesseeOrgId={assesseeOrgId}*/}
            {/*        isInvestor={isInvestor}*/}
            {/*        closeDrawer={closeDrawer}*/}
            {/*        // disableSubmit={!STEP_COMPLETION.isAllComplete(uploadJourneyData)}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}

export default UploadJourney;
