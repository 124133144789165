import noCompaniesIcon from '../../../../../assets/no-companies-icon.svg';

import { Button } from '../../../../../common/_custom';

import styles from './EmptyDeals.module.scss';
import generating from '../../../../../assets/audited-financials-icon.svg';
import { useAppDispatch } from '../../../../../app/hooks';
import { handleDealApplicationDrawer } from '../../../../../store/investor/action';
import { useLocation } from 'react-router-dom';

const EmptyDeals = ({ handleClick, filterApplied, selectedCompany }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const isCompanyDealsPage = location.pathname.includes('company-deals');
    const openCreateDeal = () => {
        dispatch(
            handleDealApplicationDrawer({
                open: true,
                source: 'createDeal',
                heading: 'Create Deal',
                subHeading: selectedCompany.orgName,
                dealBeneficiaryId: selectedCompany.assesseeOrgId,
                width: '67%',
                profileGenerationStatus: selectedCompany?.profileGenerationStatus,
            }),
        );
    };
    return filterApplied ? (
        <div className={styles.EmptyDeals}>
            <img src={generating} width="156.48px" height="123.05" alt="" />
            No Results Found
        </div>
    ) : (
        <div className={styles.EmptyDeals}>
            <img src={noCompaniesIcon} alt="noComp" height="50px" />
            <div className={styles.Title}>Deals will appear here</div>
            {isCompanyDealsPage ? (
                <>
                    <div className={styles.Subtext}>Your deals will appear here once created. You can add or edit deal at any time.</div>
                    <Button text={'Create Deal'} variant={'primary'} onClick={openCreateDeal} />
                </>
            ) : (
                <>
                    <div className={styles.Subtext}>
                        Your deals will appear here once added. You can set up or edit your investment preferences at any time.
                    </div>
                    <Button text={'Investment Preferences'} variant={'primary'} onClick={handleClick} />
                </>
            )}
        </div>
    );
};

export default EmptyDeals;
