import clsx from 'clsx';
import { useEffect, useState } from 'react';
import dropdownBlack from '../../../../../../assets/dropdown-black.svg';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { fetchBankStatementFromCashflow } from '../../../../../../store/dashboard/financials/financialsApiHelpers';
import EmptyBank from '../../../BankDataTable/components/EmptyBank/EmptyBank';
import styles from './FinancialsFromBanking.module.scss';
import { ROW_NAMES } from './rowNames';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import AIBadge from '../../../Overview/components/AIBadge/AIBadge';

const {
    UNCLASSIFIED,
    FINANCING_INFLOW,
    INVESTING,
    INTRA_COMPANY,
    OPERATING,
    FINANCING_OUTFLOW,
    OPENING_BALANCE,
    NET_CASH_FLOW,
    CLOSING_BALANCE,
} = ROW_NAMES;

function FinancialsFromBanking() {
    const [tableData, setTableData] = useState<any>();
    const [fetchingData, setFetchingData] = useState(false);

    const [selectedRow, setSelectedRow] = useState<any>(null);
    const noData = !!!tableData?.headers?.length;
    useEffect(() => {
        fetchTableData();
    }, []);

    const fetchTableData = async () => {
        setFetchingData(true);
        await fetchBankStatementFromCashflow(
            {},
            {
                onSuccess: (data) => {
                    setFetchingData(false);
                    // const sample = require('./data.json');
                    // let tableData = sample.data.data;
                    const headers = data?.headers;
                    const transformedRows = data?.rows?.map((row, index) => ({
                        ...row,
                        amountsMap: row?.amounts?.reduce((map, amount) => {
                            map[amount?.period] = amount?.amount;
                            return map;
                        }, {}),
                        subRows: row?.subRows?.map((subrow) => ({
                            ...subrow,
                            amountsMap: subrow?.amounts?.reduce((map, amount) => {
                                map[amount?.period] = amount?.amount;
                                return map;
                            }, {}),
                        })),
                    }));
                    setTableData({
                        headers: headers,
                        rows: transformedRows,
                    });
                },
                onError: () => {
                    setFetchingData(false);
                },
            },
        );
    };

    const getRowColor = (rowName: string = '') => {
        switch (rowName) {
            case FINANCING_INFLOW:
                return '#00AA00';

            case INTRA_COMPANY:
            case OPERATING:
            case FINANCING_OUTFLOW:
            case INVESTING:
                return '#E3752B';

            case OPENING_BALANCE:
            case NET_CASH_FLOW:
            case CLOSING_BALANCE:
                return '#000000';
        }
    };

    const getRowLabel = (rowName: string = '') => {
        switch (rowName) {
            case UNCLASSIFIED:
                return 'U';
            case FINANCING_INFLOW:
                return 'F';
            case INVESTING:
                return 'I';

            case INTRA_COMPANY:
                return 'T';
            case OPERATING:
                return 'O';
            case FINANCING_OUTFLOW:
                return 'W';

            case OPENING_BALANCE:
                return 'B';
            case NET_CASH_FLOW:
                return 'N';
            case CLOSING_BALANCE:
                return 'C';
        }
    };

    const isSummaryRow = (rowName: string) => {
        switch (rowName) {
            case NET_CASH_FLOW:
                return true;
            case CLOSING_BALANCE:
                return true;
            case OPENING_BALANCE:
                return true;
            default:
                return false;
        }
    };

    const renderRowItem = (row, header, j) => (
        <>
            {j === 0 && (
                <div
                    className={styles.Label}
                    style={{
                        color: getRowColor(row?.name),
                        borderColor: getRowColor(row?.name),
                    }}
                >
                    {getRowLabel(row?.name)}
                </div>
            )}
            <span
                style={{
                    color: j === 0 ? getRowColor(row?.name) : '',
                }}
            >
                {header?.key === 'name' ? row?.name ?? '-' : row?.amountsMap?.[header?.name] ?? '-'}
            </span>

            {j === 0 && !row?.isAggregate && (
                <img
                    src={dropdownBlack}
                    alt=""
                    width="10px"
                    style={{
                        transform: 'rotate(-90deg)',
                    }}
                />
            )}
        </>
    );

    const getFormulaForHover = (rowName) => {
        return (
            <div className={styles.FormulaContainer}>
                <div className={styles.Label}>N</div>=<div className={styles.Label}>C</div>-
                <div className={styles.Label}>B</div>
            </div>
        );
    };

    return (
        <div className={styles.FinancialsFromBanking}>
            <div style={{ margin: '24px 0 16px 0' }} className={styles.Title}>
                <div>
                    <span
                        className={selectedRow && styles.Active}
                        onClick={() => {
                            selectedRow && setSelectedRow(null);
                        }}
                    >
                        Cash Flow Statement
                    </span>{' '}
                    &nbsp;
                    {selectedRow && `> ${selectedRow?.name}`}
                </div>
                <AIBadge />
            </div>
            <div className={styles.TableContainer}>
                {fetchingData ? (
                    <LoadingSpinner height="60px" style={{ marginTop: '100px' }} color="black" />
                ) : (
                    <>
                        {noData ? (
                            <EmptyBank text="Data Not Available" />
                        ) : (
                            <table className={styles.Table}>
                                <thead className={styles.TableHead}>
                                    <tr className={styles.TableHeadRow}>
                                        {tableData?.headers?.map((header) => (
                                            <th className={styles.TableHeadRowItem}>
                                                {header?.name?.trim().replace(' ', "'")}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className={styles.TableBody}>
                                    {selectedRow ? (
                                        selectedRow?.subRows?.map((row, i) => (
                                            <tr className={styles.TableBodyRow}>
                                                {tableData?.headers?.map((header, j) => (
                                                    <td
                                                        key={`${header?.name}-${j}`}
                                                        className={styles.TableBodyRowItem}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {header?.key === 'name'
                                                            ? row?.name ?? '-'
                                                            : row?.amountsMap?.[header?.name] ??
                                                              '-'}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : (
                                        <>
                                            {tableData?.rows?.map((row, i) => (
                                                <tr className={styles.TableBodyRow}>
                                                    {tableData?.headers?.map((header, j) => (
                                                        <td
                                                            key={`${header?.name}-${j}`}
                                                            className={clsx(
                                                                styles.TableBodyRowItem,
                                                                {
                                                                    [styles.SummaryRow]:
                                                                        isSummaryRow(row?.name),
                                                                    [styles.SummaryRowTopBorder]:
                                                                        row?.name === NET_CASH_FLOW,
                                                                },
                                                            )}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (j === 0 && !row?.isAggregate) {
                                                                    setSelectedRow(row);
                                                                }
                                                            }}
                                                        >
                                                            {j === 0 &&
                                                            row?.name === NET_CASH_FLOW ? (
                                                                <Tooltip
                                                                    content={getFormulaForHover(
                                                                        row?.name,
                                                                    )}
                                                                    direction="top"
                                                                    fitContent
                                                                >
                                                                    <div className={styles.RowItem}>
                                                                        {renderRowItem(
                                                                            row,
                                                                            header,
                                                                            j,
                                                                        )}
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div className={styles.RowItem}>
                                                                    {renderRowItem(row, header, j)}
                                                                </div>
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default FinancialsFromBanking;
