import { Handlers } from '../../types/actions/types';
import { CONSTANTS, UnlockAICASourcePage } from './constants';
import { DealApplicationDrawerState, DealDrawerState, DealFilters, OnePagerData } from './reducer';
import {
    CompanyUpdatePayload,
    DealUpdatePayload,
    FetchAllInvestmentPreferencesPayload,
    FetchDealMetricsPayload,
    FetchDealsPayload,
    FetchInvestmentPreferencesPayload,
    FetchLenderDealsPayload,
    InvestorAccessTypePermissionServicePayload,
    InvestorDealUpdatePayload,
    getAllDealsService,
} from './service';
import { MasterData } from '../../common/DealApplicationDrawer/customHooks/useDADataTransformations';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import { filter } from 'lodash';

export function fetchInvestorConfig(payload: FetchInvestorConfigPayload) {
    return {
        type: CONSTANTS.FETCH_INVESTOR_CONFIG,
        payload,
    };
}

export function fetchLenderConfig(payload: FetchInvestorConfigPayload) {
    return {
        type: CONSTANTS.FETCH_LENDER_CONFIG,
        payload,
    };
}

export function fetchPortfolioData(payload: FetchPortfolioDataPayload) {
    return {
        type: CONSTANTS.FETCH_PORTFOLIO_DATA,
        payload,
    };
}

export function updateUntaggedMuFileNumber(uploadCount: number) {
    return {
        type: CONSTANTS.UPDATE_UNTAGGED_MU_FILE_NUMBER,
        payload: uploadCount,
    };
}

export function updateNeedInfoFileNumber(uploadCount: number) {
    return {
        type: CONSTANTS.UPDATE_NEED_INFO_FILE_NUMBER,
        payload: uploadCount,
    };
}

export function fetchOwners(payload: GetOwnerDataPayload) {
    return {
        type: CONSTANTS.FETCH_OWNER_DATA,
        payload,
    };
}

export function fetchInvestorAccessTypePermissions(payload: InvestorAccessTypePermissionServicePayload, handlers: Handlers) {
    return {
        type: CONSTANTS.FETCH_INVESTOR_PERMISSIONS,
        payload,
        handlers,
    };
}

export function fetchSingleCompanyDetails(payload, handlers) {
    return {
        type: CONSTANTS.FETCH_SINGLE_COMPANY_DETAILS,
        payload,
        handlers,
    };
}

export function initiateReportGeneration(payload: InitiateReportGenerationPayload) {
    return {
        type: CONSTANTS.INITIATE_REPORT_GENERATION,
        payload,
    };
}

export function inviteCompany(payload: InviteCompanyPayload, handlers) {
    return {
        type: CONSTANTS.INVITE_COMPANY,
        payload,
        handlers,
    };
}

export const inviteCompanyV2 = (payload: InviteCompanyPayloadV2, handlers) => {
    return {
        type: CONSTANTS.INVITE_COMPANY_V2,
        payload,
        handlers,
    };
};

export function changeSelectedFetchType(payload: any) {
    return {
        type: CONSTANTS.SET_PORTFOLIO_SELECTED_FETCH_TYPE,
        payload,
    };
}

export function changePortfolioPageNum(payload: any) {
    return {
        type: CONSTANTS.SET_PORTFOLIO_PAGE_NUMBER,
        payload,
    };
}

export function setShowUpgradeToScan(payload: string) {
    return {
        type: CONSTANTS.SET_SHOW_UPGRADE_TO_SCAN,
        payload,
    };
}

export function setShowRefreshReport(payload: boolean) {
    return {
        type: CONSTANTS.SET_SHOW_REFRESH_REPORT,
        payload,
    };
}

export function setShowUnlockAicaConfirmation(payload: any) {
    return {
        type: CONSTANTS.SET_SHOW_UNLOCK_AICA_CONFIRMATION,
        payload,
    };
}

export function updateCompany(payload: CompanyUpdatePayload, handlers?: Handlers) {
    return {
        type: CONSTANTS.UPDATE_COMPANY,
        payload,
        handlers,
    };
}

export function createDeal(payload: any, handlers) {
    return {
        type: CONSTANTS.CREATE_DEAL,
        payload,
        handlers,
    };
}

export function setDealDrawer(payload: DealDrawerState) {
    return {
        type: CONSTANTS.SET_DEAL_DRAWER,
        payload,
    };
}
export function setDealFilterDrawer(payload: DealDrawerState) {
    return {
        type: CONSTANTS.SET_DEAL_FILTER_DRAWER,
        payload,
    };
}

export function getMetricsForDealCreation(payload: FetchDealMetricsPayload, handlers) {
    return {
        type: CONSTANTS.METRICS_FOR_DEAL_CREATION,
        payload,
        handlers,
    };
}

export function getPecMatchingData(payload: any, handlers) {
    return {
        type: CONSTANTS.PEC_MATCHING,
        payload,
        handlers,
    };
}

const setIsFetchingDeals = (payload: boolean) => ({
    type: CONSTANTS.SET_IS_FETCHING_DEALS,
    payload,
});

const fetchDealsSuccess = (payload: any) => ({
    type: CONSTANTS.FETCH_DEALS_SUCCESS,
    payload,
});

export const getAllDeals = (payload: {
    dealStatuses?: string[];
    dealProposerId: string;
    pageNumber: number;
    pageSize: number;
    ownerUserId: string;
    dealBeneficiaryId?: string;
    filters?: {
        dealBeneficiaryIds?: string[];
        dealReceivers?: string[];
        dealOwners?: string[];
        uwAnalysts?: string[];
        statuses?: string[];
        dcmOwners?: string[];
    };
}) => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch(setIsFetchingDeals(true));
            const state = getState();

            const response = await getAllDealsService({
                ...payload,
                dealStatuses: payload.dealStatuses ?? [],
                filters: {
                    ...payload.filters,
                },
            });

            const responseData = response?.data?.responseData;
            switch (responseData?.responseCode) {
                case JAVA_API_STATUS.SUCCESS:
                    dispatch(fetchDealsSuccess(response.data.responseBody ?? {}));
                    break;
                case JAVA_API_STATUS.ERROR:
                default:
                    toast.error(responseData?.responseMessage || SOMETHING_WRONG);
            }
        } catch (error) {
            console.error('Error fetching deals:', error);
        } finally {
            dispatch(setIsFetchingDeals(false));
        }
    };
};

export function getAllLenderDeals(payload: FetchLenderDealsPayload) {
    return {
        type: CONSTANTS.GET_ALL_LENDER_DEALS,
        payload,
    };
}

export function updateDeal(payload: DealUpdatePayload | any, handlers?: Handlers) {
    return {
        type: CONSTANTS.UPDATE_DEAL,
        payload,
        handlers,
    };
}

export function updateInvestorDeal(payload: InvestorDealUpdatePayload, handlers?: Handlers) {
    return {
        type: CONSTANTS.UPDATE_INVESTOR_DEAL,
        payload,
        handlers,
    };
}

export function getDealData(payload: { dealId?: string; dealBeneficiaryId?: string }, handlers) {
    return {
        type: CONSTANTS.GET_DEAL_DATA,
        payload,
        handlers,
    };
}

export function getLenderDealData(payload: { dealId: string }, handlers) {
    return {
        type: CONSTANTS.GET_LENDER_DEAL_DATA,
        payload,
        handlers,
    };
}

export function getInvestmentPreference(payload: { investmentPreferenceId: string }, handlers) {
    return {
        type: CONSTANTS.GET_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function getAllInvestmentPreferences(payload: FetchAllInvestmentPreferencesPayload, handlers) {
    return {
        type: CONSTANTS.GET_ALL_INVESTMENT_PREFERENCES,
        payload,
        handlers,
    };
}

export function updateInvestmentPreference(payload: any, handlers) {
    return {
        type: CONSTANTS.UPDATE_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function createInvestmentPreference(payload: any, handlers) {
    return {
        type: CONSTANTS.CREATE_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function setShowUnlockAicaAndAddToLeads(payload: ShowUnlockAicaAndAddToLeadsPayload) {
    return {
        type: CONSTANTS.SET_SHOW_UNLOCK_AICA_AND_ADD_TO_LEADS,
        payload,
    };
}

export function setShowInviteUserModal(payload: boolean) {
    return {
        type: CONSTANTS.SET_SHOW_INVITE_USER,
        payload,
    };
}

export function setShowInviteUserWithCallback(payload: { show: boolean; onSuccess: () => void }) {
    return {
        type: CONSTANTS.SET_SHOW_INVITE_USER_WITH_CALLBACK,
        payload,
    };
}

export function fetchTabLockStatus(payload: FetchTabLockStatusPayload) {
    return {
        type: CONSTANTS.FETCH_TAB_LOCK_STATUS,
        payload,
    };
}

export function setOnePagerData(payload: OnePagerData | { loading: boolean }) {
    return {
        type: CONSTANTS.SET_ONE_PAGER_DATA,
        payload,
    };
}

export function handleDealApplicationDrawer(payload: DealApplicationDrawerState) {
    return {
        type: CONSTANTS.OPEN_DEAL_APPLICATION_DRAWER,
        payload,
    };
}

export function updateDealApplicationData(payload: {
    masterData?: MasterData;
    allProducts?: {
        label: string;
        value: string;
    }[];
}) {
    return {
        type: CONSTANTS.UPDATE_DEAL_APPLICATION_DATA,
        payload,
    };
}

export function setModulePollingList(payload: string[]) {
    return {
        type: CONSTANTS.SET_MODULE_POLLING_LIST,
        payload,
    };
}

export function setTaskTrackerMap(payload: { [taskTrackerStep: string]: { status: string; dateCreated: number } }) {
    return {
        type: CONSTANTS.SET_TASK_TRACKER_MAP,
        payload,
    };
}

export function closeOnePager() {
    return {
        type: CONSTANTS.CLOSE_ONE_PAGER,
    };
}

export const setDealFilters = (filters: DealFilters) => ({
    type: CONSTANTS.SET_DEAL_FILTERS,
    payload: filters,
});

export const resetDealFilters = (payload: { allStatusesSelected?: boolean } = {}) => ({
    type: CONSTANTS.RESET_DEAL_FILTERS,
    payload,
});

export type FetchInvestorConfigPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    assesseeOrgId?: string;
    reportId?: string;
    userRole?: string;
    configName?: string;
};

export type FetchOrgBasedUsersPayload = {
    pageSize?: number;
    pageNumber?: number;
    orgId: string;
    userType: string[];
    orgName?: string;
};

export type FetchPortfolioDataPayload = {
    portfolioTypeToFetch: string;
    pageNumber: number;
    investorOrgId: string;
    companyAccessType: string;
    partnerUserId?: string;
    companyName?: string;
};

export type GetOwnerDataPayload = {
    investorOrgId: string;
};

export type InviteCompanyPayload = {
    accountType: string;
    primaryEmail: string;
    orgDisplayName: string;
    pan: string;
    journeyName: string;
    websiteUrl?: string;
    sector?: string;
    investorOrgId: string;
    phoneNumber: string;
    countryId: string;
    countryCode: string;
    // primaryUserName: string;
};
export type InviteCompanyPayloadV2 = {
    user: {
        pan: string;
        name: string;
        emailId: string;
        investorId: string;
        accountType: string;
        password: string;
        journeyName: string;
        sector?: string;
        mobileNumber: string;
        countryId: string;
        countryCode: string;
    };
    platformId: string;
};

export type InitiateReportGenerationPayload = {
    assesseeOrgId: string;
    portfolioTypeToFetch: string;
    pageNumber: number;
    investorOrgId: string;
};

export type ShowUnlockAicaAndAddToLeadsPayload = {
    showModal: boolean;
    assesseeOrgId?: string;
    orgName?: string;
    source?: UnlockAICASourcePage;
};

export type FetchTabLockStatusPayload = {
    tabsList: string[];
    assesseeOrgId: string;
};

export type OnePagerShowPayload = {
    show: boolean;
    assesseeOrgId: string;
    dealFormData?: any;
};
