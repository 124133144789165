// src/components/ManageDealDrawer/ManageDealDrawer.tsx

import React, { useEffect, useRef, useState } from 'react';
import SideDrawer from '../_custom/SideDrawer/SideDrawer';
import Form from '../_custom/Form/Form';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';
import { DEBT_PRODUCT_FILTERS } from './DealDrawerConstants';

// Custom hook
import { useManageDealDrawer } from './useManageDealDrawer';

// Subcomponents
import { ManageDealDrawerHeader } from './Components/ManageDealDrawerHeader';
import { ManageDealDrawerFooter } from './Components/ManageDealDrawerFooter';
import FormSideStepper from '../FormSideStepper/FormSideStepper';
import { FormSection } from './Components/Sections/FormSection';

// Styles
import styles from './ManageDealDrawer.module.scss';

type Props = {
    source: string;
    onClose?: Function;
};

const ManageDealDrawer: React.FC<Props> = ({ source, onClose }) => {
    const {
        formData,
        handleChange,
        dealDrawerState,
        lenderList,
        loanTypeOptions,
        sectorList,
        negativeSectorList,
        step,
        disablePrimary,
        disableSecondary,
        loadingPrefilledData,
        formConfig,
        stepperConfig,
        investorName,
        closeDrawer,
        handlePrimary,
        handleSecondary,
        handleStepperClick,
        setDisablePrimary,
        isFromComplete,
        dealDrawerBodyRef,
        stepContainerRef,
        auditedfinancialsMapping,
        isWebsiteValid,
        setIsWebsiteValid,
        isWebsitePrefilled,
        isLinkedInValid,
        getInputValidation,
        getButtonIcon,
        getButtonText,
        validateWebsite,
        negativeLenderList,
    } = useManageDealDrawer({ source, onClose });

    // Determine the config for the current step
    const currentConfig = formConfig?.[step - 1];

    // 1) Keep track of the previous step
    const prevStepRef = useRef<number>(step);

    // 2) We'll store our animation class in local state
    const [animationClass, setAnimationClass] = useState<string>('');

    useEffect(() => {
        if (step > prevStepRef.current) {
            // Step is going from lower to higher => Slide down
            setAnimationClass('slideUp');
        } else if (step < prevStepRef.current) {
            // Step is going from higher to lower => Slide up
            setAnimationClass('slideDown');
        }

        // Save the new step as the "previous" step
        prevStepRef.current = step;
        const timer = setTimeout(() => {
            setAnimationClass('fadeIn');
        }, 300);

        return () => clearTimeout(timer);
    }, [step]);

    return (
        <SideDrawer dontClose width={dealDrawerState.isPec ? '75%' : source === DEBT_PRODUCT_FILTERS ? '40%' : '67%'} onClose={closeDrawer}>
            <ManageDealDrawerHeader
                drawerLabel={dealDrawerState?.drawerLabel ?? 'Create Deal'}
                investorName={dealDrawerState.companyName ?? investorName}
            />

            {currentConfig && !loadingPrefilledData ? (
                <Form>
                    <div className={styles.ManageDealDrawer} ref={dealDrawerBodyRef}>
                        <div className={styles.DealsFormWrapper}>
                            <FormSideStepper
                                hideStepper={stepperConfig.length === 0}
                                step={step}
                                stepperConfig={stepperConfig}
                                onStepClick={handleStepperClick}
                                statusTrigger={!disablePrimary}
                                isViewOnly={dealDrawerState?.readonly ?? false}
                                checkCompleteForm={isFromComplete}
                                isWebsiteValid={isWebsiteValid}
                                isLinkedInValid={isLinkedInValid}
                                source={source}
                            />

                            {/* Step Container */}
                            <div className={`${styles.Step} ${animationClass ? styles[animationClass] : ''}`}>
                                <div
                                    style={
                                        source === DEBT_PRODUCT_FILTERS
                                            ? {
                                                  boxShadow: 'none',
                                                  background: 'transparent',
                                                  padding: 0,
                                              }
                                            : {}
                                    }
                                    className={styles.StepContainer}
                                    ref={stepContainerRef}
                                >
                                    {currentConfig?.text && dealDrawerState.readonly && (
                                        <div className={styles.SectionHeading}>
                                            {/* Show the step number only on the first section within that step */}
                                            {`${step}.`} {currentConfig?.text}
                                        </div>
                                    )}

                                    {/* Optionally, show a subheading or instructions for the first section */}
                                    {currentConfig?.subText && dealDrawerState.readonly && (
                                        <div className={styles.StepHeading}>
                                            <span className={styles.StepHeadingSecondaryText}>{currentConfig.subText}</span>
                                        </div>
                                    )}
                                    {/* Render each "section" inside this step */}
                                    {currentConfig?.['config']?.map((section: any, index: number) => (
                                        <FormSection
                                            key={section.text}
                                            setDisablePrimary={setDisablePrimary}
                                            section={section}
                                            step={step}
                                            sectionIndex={index}
                                            totalSteps={formConfig.length}
                                            formData={formData}
                                            handleChange={handleChange}
                                            dealDrawerState={dealDrawerState}
                                            lenderList={lenderList}
                                            loanTypeOptions={loanTypeOptions}
                                            sectorList={sectorList}
                                            negativeSectorList={negativeSectorList}
                                            source={source}
                                            auditedfinancialsMapping={auditedfinancialsMapping}
                                            isWebsiteValid={isWebsiteValid}
                                            isWebsitePrefilled={isWebsitePrefilled}
                                            setIsWebsiteValid={setIsWebsiteValid}
                                            getInputValidation={getInputValidation}
                                            getButtonIcon={getButtonIcon}
                                            getButtonText={getButtonText}
                                            validateWebsite={validateWebsite}
                                            negativeLenderList={negativeLenderList}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    {!dealDrawerState.readonly && (
                        <ManageDealDrawerFooter
                            currentConfig={currentConfig}
                            disablePrimary={disablePrimary}
                            onPrimary={handlePrimary}
                            onSecondary={handleSecondary}
                            disableSecondary={disableSecondary}
                        />
                    )}
                </Form>
            ) : (
                <LoadingSpinner className={styles.DealDrawerLoader} color="var(--primary-text-colour)" height="50px" />
            )}
        </SideDrawer>
    );
};

export default ManageDealDrawer;
