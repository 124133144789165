import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dropdown from '../../../../common/_custom/Dropdown';
import { DropdownOption } from '../../../../common/_custom/Dropdown/dropdownTypes';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { fetchBankStatementData } from '../../../../store/dashboard/bankStatement/apiHelper';
import styles from './BankDataTable.module.scss';
import EmptyBank from './components/EmptyBank/EmptyBank';
import Header from './components/Header';
import Rows from './components/Rows';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { bankIconBlack } from '../../../../assets/hostedassets';
import arrowRight from '../../../../assets/arrow-right.svg';
import { updateQueryParams } from '../../../../utils/searchParamUtils';

type BankTableData = {
    isFetched: boolean;
    accounts?: string;
    summaryData?: SingleAccountData[];
};
type SingleAccountData = {
    accountNumber: string;
    bankName: string;
    headers: any[];
    rows: any[];
};

function BankDataTable() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState<BankTableData>({
        isFetched: false,
    });
    const [selectedAccount, setSelectedAccount] = useState<null | DropdownOption>(null);
    const [loadingData, setLoadingData] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const noBanksConnected = tableData?.isFetched && !tableData?.summaryData?.length;
    const location = useLocation();
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (selectedAccount) {
            appendSeachParams(`${selectedAccount.id}`);
        }
    }, [selectedAccount]);

    const appendSeachParams = (selectedBankId = '', selectedTab = '', financialsType = '') => {
        const paramsToUpdate = {
            ...(selectedBankId && { selectedBankId }),
            ...(selectedTab && { selectedTab }),
            ...(financialsType && { financialsType }),
        };

        updateQueryParams(searchParams, setSearchParams, paramsToUpdate, location);
    };

    const fetchData = () => {
        setLoadingData(true);
        fetchBankStatementData(
            {},
            {
                onSuccess: (data: any = {}) => {
                    setLoadingData(false);
                    setTableData((tableData) => ({
                        ...tableData,
                        isFetched: true,
                        ...data,
                    }));

                    const firstAccount = data?.summaryData?.[0];
                    let selectedAccountFromParams = data?.summaryData?.find(
                        (account) =>
                            searchParams.get('selectedBankId') ===
                            (account?.accountNumber || account?.bankName),
                    );
                    if (selectedAccountFromParams) {
                        setSelectedAccount({
                            id:
                                selectedAccountFromParams?.accountNumber ||
                                selectedAccountFromParams?.bankName,
                            label: `${selectedAccountFromParams?.bankName}${
                                selectedAccountFromParams?.accountNumber
                                    ? ': ' + selectedAccountFromParams?.accountNumber
                                    : ''
                            }`,
                        });
                    } else if (firstAccount) {
                        setSelectedAccount({ id: 'Aggregated', label: 'Aggregated' });
                    }
                },
                onError: () => {
                    setTableData((tableData) => ({
                        ...tableData,
                        isFetched: true,
                    }));
                },
            },
        );
    };

    const dropdownOptions = useMemo(
        () =>
            tableData?.summaryData?.map((accountData) => ({
                id: accountData.accountNumber || accountData.bankName,
                label: `${accountData.bankName}${
                    accountData.accountNumber ? ': ' + accountData.accountNumber : ''
                }`,
            })),
        [tableData?.summaryData],
    );

    const selectedTableData = useMemo(
        () =>
            tableData?.summaryData?.find(
                (accountData) =>
                    accountData.accountNumber === selectedAccount?.id ||
                    accountData.bankName === selectedAccount?.id,
            ),
        [selectedAccount],
    );

    const navigateToCashflowFromBanking = () => {
        appendSeachParams('', 'auditedFinancials', 'cashflowFromBanking');
    };
    const noData = !!!selectedTableData?.headers?.length;
    return (
        <div className={styles.BankTableContainer} ref={tableRef}>
            {loadingData ? (
                <LoadingSpinner height="60px" style={{ marginTop: '100px' }} color="black" />
            ) : (
                <>
                    {!noBanksConnected && (
                        <div className={styles.Top}>
                            <div className={styles.Left}>
                                <img src={bankIconBlack} alt="bank" />
                                <div>Bank Account:</div>

                                <Dropdown
                                    optionsList={dropdownOptions || []}
                                    selectedOption={selectedAccount}
                                    itemClickHandler={(selectedOption) => {
                                        setLoadingData(true);
                                        setSelectedAccount(selectedOption);
                                        setTimeout(() => {
                                            setLoadingData(false);
                                        }, 300);
                                    }}
                                    style={{ width: selectedAccount ? '280px' : '220px' }}
                                    placeholder={'Select Account'}
                                    variant="white"
                                    size="tiny"
                                />
                            </div>

                            <div className={styles.Right} onClick={navigateToCashflowFromBanking}>
                                View Cashflow From Banking
                                <img src={arrowRight} alt="" height={'16px'} />
                            </div>
                        </div>
                    )}
                    <div className={styles.Content}>
                        {noData ? (
                            <EmptyBank
                                text={
                                    noBanksConnected
                                        ? 'No Bank Accounts Connected'
                                        : selectedTableData
                                        ? 'Data Not Available'
                                        : 'Please select the bank account to view'
                                }
                            />
                        ) : (
                            <div className={styles.TableContainer}>
                                <table className={styles.BankTable}>
                                    <thead className={styles.TableHead}>
                                        <Header data={selectedTableData?.headers} />
                                    </thead>
                                    <tbody className={styles.TableBody}>
                                        <Rows
                                            rows={selectedTableData?.rows}
                                            headers={selectedTableData?.headers}
                                            tableRef={tableRef}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default BankDataTable;
