export const OFFERS_TEXT = {
    PAGE_TITLE: 'Offers',
    NO_OFFERS: 'No offers available',
} as const;

export const OFFERS_CONFIG = {
    PAGE_SIZE: 50,
    BUTTON_HEIGHT: '40px',
} as const;

export const CAPITAL_REQUIREMENT_BUTTON = 'Capital Requirement';
