import { useEffect, useRef, useState } from 'react';
import styles from './AicaGradeBar.module.scss';
import rectange from './rectange.svg';
import clsx from 'clsx';
import { getRiskType, RISK_MAP, RISK_TYPES, RiskMapType } from '../aicaGradeConstants';

function AicaGradeBar({ grade, isPDFReport = false }: { grade: null | number; isPDFReport: boolean }) {
    const gradeUnavailable = grade === null || grade === undefined;

    const [riskType, setRiskType] = useState<null | RiskMapType>(null);
    const [xPosPercent, setXPosPercent] = useState('50%');
    const barRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (gradeUnavailable) return;
        setRiskType(RISK_MAP[getRiskType(grade)]);
        getXPosition();

        const observer = new ResizeObserver(() => {
            getXPosition();
        });
        if (barRef.current) {
            observer.observe(barRef.current);
        }
        return () => observer.disconnect();
    }, [grade, barRef.current]);

    function getXPosition() {
        if (gradeUnavailable) return 0;
        const clampedGrade = Math.min(Math.max(0, grade), 4);
        const barWidth = document.getElementById('aica-grade-bar')?.getBoundingClientRect().width || 0;
        const barGap = 10;

        let basePosition;
        if (clampedGrade <= 1) {
            basePosition = 0;
        } else if (clampedGrade <= 2) {
            basePosition = 1;
        } else if (clampedGrade <= 3) {
            basePosition = 2;
        } else {
            basePosition = 3;
        }
        const lowerBound = basePosition;
        const fractionalValue = clampedGrade - lowerBound;
        const xDisplacementForFraction = fractionalValue * barWidth;
        const startPos = basePosition > 0 ? (barWidth + barGap) * basePosition : 0;

        setXPosPercent(`calc(${startPos - (clampedGrade >= 4 ? barGap : 0) + xDisplacementForFraction}px)`);
    }

    return (
        <div className={clsx(styles.AicaGradeBar, { [styles.GradeBarPDFReport]: isPDFReport })}>
            <div className={styles.BarContainer} id="aica-grade-bar-container">
                <div className={styles.Bar} id="aica-grade-bar" ref={barRef}>
                    <div
                        className={styles.BarFill}
                        style={{
                            background: grade && grade >= 0 ? RISK_MAP[RISK_TYPES.LOW].color : 'none',
                        }}
                    ></div>
                    <div className={styles.BarLabel}>Low</div>
                </div>
                <div className={styles.Bar}>
                    <div
                        className={styles.BarFill}
                        style={{
                            background: grade && grade > 1 ? RISK_MAP[RISK_TYPES.MEDIUM].color : 'none',
                        }}
                    ></div>
                    <div className={styles.BarLabel}>Medium</div>
                </div>
                <div className={styles.Bar}>
                    <div
                        className={styles.BarFill}
                        style={{
                            background: grade && grade > 2 ? RISK_MAP[RISK_TYPES.HIGH].color : 'none',
                        }}
                    ></div>
                    <div className={styles.BarLabel}>High</div>
                </div>

                <div className={styles.Bar}>
                    <div
                        className={styles.BarFill}
                        style={{
                            background: grade && grade > 3 ? RISK_MAP[RISK_TYPES.SEVERE].background : 'none',
                        }}
                    ></div>
                    <div className={styles.BarLabel}>Severe</div>
                </div>

                <img
                    src={rectange}
                    className={clsx(styles.BarStopper, { [styles.BarStopperNA]: gradeUnavailable })}
                    height="30px"
                    style={{ left: `calc(${xPosPercent} - 2px)` }}
                />
                <div
                    className={clsx(styles.BarValue, { [styles.BarValueNA]: gradeUnavailable })}
                    style={{
                        left: `calc(${xPosPercent})`,
                        background: riskType?.background,
                        color: riskType?.color || 'white',
                    }}
                >
                    {riskType?.label ?? 'N/A'}
                </div>
            </div>
        </div>
    );
}

export default AicaGradeBar;
