import styles from '../AicaOverview.module.scss';
import { GeneratingAicaOverviewLoader } from '../../../assets/hostedassets';
import AIText from '../../AIText/AIText';

const LoadingState = () => {
    return (
        <div className={styles.LoadingContianer}>
            <img src={GeneratingAicaOverviewLoader} alt="Generating AICA Overview" />
            <AIText
                text="Generating AICA Overview"
                style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                }}
                showLoadingDots={true}
                loadingDotsStyle={{ color: 'var(--primary-text-colour)', fontSize: '1rem' }}
            />
        </div>
    );
};

export default LoadingState;
