import styles from '../MagicUploadFilesTable.module.scss';
import React from 'react';

const EmptyDataState = ({ tabState, isCompany }) => {
    return (
        <>
            {Array.from({ length: tabState !== 'all' ? 20 : 7 }).map((_, index) => {
                return (
                    <tr className={styles.MURowEmpty} key={index}>
                        {isCompany ? (
                            <td style={{ width: '20.5rem' }}>&nbsp;</td>
                        ) : (
                            <td style={{ width: '20rem' }}>&nbsp;</td>
                        )}
                        {!isCompany && <td style={{ width: '310px' }}>&nbsp;</td>}
                        <td style={{ width: '310px' }}>&nbsp;</td>
                        <td style={{ width: '7.1rem' }}>&nbsp;</td>
                        <td style={{ width: '9.5rem' }}>&nbsp;</td>
                        <td style={{ width: '8.688rem' }}>&nbsp;</td>
                        <td style={{ width: '3rem' }}>&nbsp;</td>
                    </tr>
                );
            })}
        </>
    );
};

export default EmptyDataState;
