import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getAllDeals } from '../../../../store/investor/action';
import { getPlatformUserId } from '../../../../utils/utils';
import { getCompanyMetrics } from '../../../../store/debtProducts/apiHelper_debtProducts';
import { OFFERS_CONFIG } from './OffersConstants';
import { fetchCompanyUploadConfig } from '../../../../store/company/action';
import { DealStatus } from '../../../../store/investor/reducer';

export const useCompanyOffers = () => {
    const dispatch = useAppDispatch();
    const [companyMetrics, setCompanyMetrics] = useState<any>(null);

    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const assesseeId = useAppSelector((state) => state.user.userData.assesseeOrgId);
    const userData = useAppSelector((state) => state.user.userData);
    const dealsData = useAppSelector((state) => state.investor.deals);
    const fetchingDeals = useAppSelector((state) => state.investor.fetchingDeals);
    const statusToShow = useAppSelector((state) => state.company.config?.borrowerOffers?.statusToShow);
    useEffect(() => {
        if (!platformData) return;
        if (fetchingDeals) return;
        if (!statusToShow) return;

        const platformId = getPlatformUserId();

        dispatch(
            getAllDeals({
                filters: {
                    statuses: Object.values(DealStatus),
                    dealBeneficiaryIds: [assesseeId],
                },
                dealProposerId: platformData.investorOrgId,
                pageNumber: 1,
                pageSize: OFFERS_CONFIG.PAGE_SIZE,
                ownerUserId: platformId,
                dealBeneficiaryId: assesseeId,
            }),
        );
    }, [platformData, statusToShow, assesseeId]);

    const fetchMetrics = async () => {
        if (assesseeId) {
            const metrics = await getCompanyMetrics({ assesseeOrgId: assesseeId });
            setCompanyMetrics(metrics);
        }
    };

    useEffect(() => {
        fetchMetrics();
    }, [assesseeId]);

    useEffect(() => {
        if (assesseeId && userData.investorOrgId && userData.investorPrimaryEmail) {
            dispatch(
                fetchCompanyUploadConfig({
                    accountType: userData.accountType,
                    primaryEmail: userData.primaryEmail ?? userData.investorPrimaryEmail,
                    investorOrgId: userData.investorOrgId,
                    configName: 'AICA_BORROWER_ONBOARDING_DATA_BY_BORROWER',
                    assesseeOrgId: assesseeId,
                }),
            );
        }
    }, [assesseeId, userData.investorOrgId, userData.investorPrimaryEmail]);

    return {
        dealsData,
        fetchingDeals,
        companyMetrics,
    };
};
