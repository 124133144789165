import HighLevelIcon from '../../assets/Svg/HighLevelicon';
import MediumLevelIcon from '../../assets/Svg/MediumLevelIcon';
import LowLevelIcon from '../../assets/Svg/LowLevelIcon';
import CompanyLegalNameSearch from '../CompanyLegalNameSearch/CompanyLegalNameSearch';

export const EXCLUDED_LABEL_TYPES = ['recur-investor-toggle', 'investor-toggle'];
export const AICA_CREATE_DEAL_DETAILS = 'AICA_CREATE_DEAL_DETAILS';
export const AICA_INVESTMENT_PREFERENCE = 'AICA_INVESTMENT_PREFERENCE';
export const AICA_COMPANY_PROFILE = 'AICA_COMPANY_PROFILE';
export const DEBT_PRODUCT_FILTERS = 'Debt Products Filters';
export const AICA_YOUR_PROFILE = 'AICA_YOUR_PROFILE';
export const PROFILE_BUTTON = 'ProfileButton';
export const PROFILE_LABEL = 'Your Profile';
export const ORG_USERS_TABLE = 'OrgUsersTable';
export const CREATE_INVESTMENT_PREFERENCE = 'CreateInvestmentPreference';
export const AICA_DEAL_NAVBAR_VIEW = 'AICA_DEAL_NAVBAR_VIEW';

export const PROFILE = 'PROFILE';

export const UPLOAD_FILES_PREFIXES = {
    ONEPAGER_PREFIX: 'ONEPAGER_',
    ATTACHMENT_PREFIX: 'ATTACHMENT_',
    INVESTMENTREPORT__PREFIX: 'INVESTMENTREPORT_',
};

export const FILE_SHARE_STATUS = {
    SEND_TO_INVESTOR: 'SEND_TO_INVESTOR',
    SKIP_NOTIFICATION: 'SKIP_NOTIFICATION',
};

export const AICA_GET_COMPANY_SECTOR_LIST = 'AICA_GET_COMPANY_SECTOR_LIST';

export enum CONFIG_STEP {
    PREFERRED_TERMS = 'PREFERRED_TERMS',
    BORROWER_PROFILE = 'BORROWER_PROFILE',
    DEAL_TERMS = 'DEAL_TERMS',
    SELECT_INVESTORS = 'SELECT_INVESTORS',

    preferredTerms = 'preferredTerms',
    borrowerProfile = 'borrowerProfile',
    dealTerms = 'dealTerms',
}

export enum CONFIG_TYPE {
    SEARCH_DROPDOWN = 'search-dropdown',
    DROPDOWN = 'dropdown',
    NUMBER = 'number',
    MULTIPLE_SELECT = 'multiple-select',
    MULTIPLE_SELECT_WITH_SEARCH = 'multiple-select-with-search',
    MULTIPLE_SELECT_WITH_INPUT = 'multiple-select-with-input',
    MULTIPLE_SELECT_WITH_INPUT_AND_SEARCH = 'multiple-select-with-input-and-search',
    RANGE = 'range',
    MULTIPLE_SELECT_API = 'multiple-select-api',
    MULTIPLE_SELECT_API_FILTER = 'multiple-select-api-filter',
    MULTIPLE_SELECT_API_FILTER_INLINE = 'multiple-select-api-filter-inline',
    RECUR_INVESTOR_TOGGLE = 'recur-investor-toggle',
    INVESTOR_TOGGLE = 'investor-toggle',
    UPLOAD = 'upload',
    BUTTON = 'button',
    SELECT_API = 'select-api',
    SELECT_SECTOR_API = 'select-sector-api',
    RANGE_FILTER = 'range-filter',
    INPUT_WITH_MODAL_BUTTON = 'input-with-modal-button',
    MULTI_MULTIPLE_SELECT = 'multi-multiple-select',
    MULTI_MULTIPLE_SELECT_WITH_SEARCH = 'multi-multiple-select-with-search',
    DROPDOWN_WITH_CUSTOM_COMPONENT = 'dropdown_with_custom_component',
    DATE = 'date',
}

export const CUSTOM_COMPONENT_MAP: Record<string, React.FC<any>> = {
    COMPANY_LEGAL_NAME_SEARCH: CompanyLegalNameSearch,
};

export const DEFAULT_OWNER_TYPE = 'dealOwner';
export const DEAL_CREATOR_TYPE = 'dealCreator';

export const IMPORTANCE_LEVELS = [
    {
        id: 'Critical',
        label: 'Critical',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/CriticalIcon.svg',
    },
    {
        id: 'High',
        label: 'High',
        icon: <HighLevelIcon colour="var(--primary-text-colour)" height="12" width="12" style={{ marginTop: '4px' }} />,
    },
    {
        id: 'Medium',
        label: 'Medium',
        icon: <MediumLevelIcon colour="var(--primary-text-colour)" height="12" width="12" style={{ marginTop: '4px' }} />,
    },
    {
        id: 'Low',
        label: 'Low',
        icon: <LowLevelIcon colour="var(--primary-text-colour)" height="12" width="12" style={{ marginTop: '5px' }} />,
    },
    {
        id: 'Not Needed',
        label: 'Not Needed',
        icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/notInterestedIcon.svg',
    },
];

export const USER_TYPE = {
    ADMIN_TEAM: 'ADMIN_TEAM',
    COMPANY: 'COMPANY',
    INVESTOR: 'INVESTOR',
    LENDER: 'LENDER',
    PARTNER: 'PARTNER',
};

export const STEPPER_STATUS = {
    UNLOCKED: 'UNLOCKED',
    LOCKED: 'LOCKED',
};

export const PEC_STATUS = {
    PEC: 'PEC',
    PEC_DUPLICATED: 'PEC Duplicated',
    PEC_CREATED: 'PEC Created',
};

export const PEC_IMPORTANCE_CONSTANTS = {
    CRITICAL: 'Critical',
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
    NOT_NEEDED: 'Not Needed',
};
