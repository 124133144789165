import Filters from './Filters/Filters';
import expandMore from '../../../../../../assets/expand-more.svg';
import styles from './Report.module.scss';
import RiskBadge from '../../../../../../common/Badges/RiskBadge/RiskBadge';
import clsx from 'clsx';
import { Button } from '../../../../../../common/_custom';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import Graph from '../../../../components/Graph/Graph';
import {
    fetchChartData,
    fetchCibilOverview,
} from '../../../../../../store/dashboard/creditBureau/actions';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { COMBINED_REPORT, DETAILED_REPORT } from '../../CreditBureau';
import { capitalizeWords } from '../../../../../../utils/utils';
import TypeSelector from '../../../../components/TypeSelector/TypeSelector';
import { DASHBOARD_DATA_TYPES, dataTypeSelectorOptions } from '../../../..';
import CreditSummaryTable from '../CreditSummaryTable/CreditSummaryTable';
import CreditSummaryBar from './CreditSummaryBar/CreditSummaryBar';
import AicaOverview from '../../../../../../common/AicaOverview/AicaOverview';
import { leftPurpleArrow, uploadCibil, uploadEquifax } from '../../../../../../assets/hostedassets';

function Report({
    selectedReport,
    selectedDirector,
    setSelectedReport,
    selectedReportConfig,
    selectedCompany,
    getGridRowHeight,
    resetQueryParams,
}) {
    const dispatch = useAppDispatch();
    const creditBureauData = useAppSelector((state) => state.dashboard.creditBureau);
    const chartsData = creditBureauData.charts;
    const cibilOverviewData = creditBureauData.cibilOverviewData;
    const directorsList = creditBureauData?.mainData?.listDirectorsOverview || [];

    const user = useAppSelector((state) => state.user);
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataType, setDataType] = useState(DASHBOARD_DATA_TYPES.CHARTS);
    const bureauType =
        searchParams.get('bureauType') == 'All' ? null : searchParams.get('bureauType');
    const directorId = searchParams.get('directorId');

    useEffect(() => {
        fetchCibilOverviewData();
        return () => resetQueryParams();
    }, []);

    const getRiskClassname = (isDpd: boolean, isFirstTwo: boolean = false) => {
        return clsx({
            [styles.MetricValue]: true,
            [styles.LowRiskBadge]: !isDpd,
            [styles.MediumRiskBadge]: false,
            [styles.HighRiskBadge]: isDpd,
            [styles.GrayPill]: isFirstTwo,
        });
    };
    function getImageSource(director, bureauType) {
        switch (director?.creditBureau || bureauType?.toUpperCase()) {
            case 'CIBIL':
                return uploadCibil;
            case 'EQUIFAX':
                return uploadEquifax;
            default:
                return undefined;
        }
    }

    const fetchCibilOverviewData = () => {
        const directorId = searchParams.get('directorId');
        dispatch(
            fetchCibilOverview({
                assesseeOrgId: selectedCompany?.assesseeOrgId,
                reportId: selectedCompany?.reportId || '',
                ...(directorId && { directorId: directorId }),
                type: searchParams.get('bureauType'),
            }),
        );
    };

    const fetchGraph = (graphName) => {
        const graphNameAry = [graphName];
        const directorId = searchParams.get('directorId');
        let type: string | null = null;
        if (!directorId) {
            type = searchParams.get('bureauType') == 'All' ? null : searchParams.get('bureauType');
        }
        dispatch(
            fetchChartData({
                graphNames: graphNameAry,
                tabName: 'creditBureau',
                startDate: '',
                endDate: '',
                tenure: '',
                reportId: selectedCompany?.reportId || '',
                ...(directorId && { directorId: directorId }),
                type: type,
                // graphType: props.graphData.type,
            }),
        );
    };

    const getReportTitle = () => {
        switch (selectedReport) {
            case COMBINED_REPORT:
                return 'Combined Report';
            case DETAILED_REPORT:
                return selectedDirector ? selectedDirector?.name : 'Detailed Report';
            default:
                return '';
        }
    };

    // const getSectionHeader = () => {
    //     switch (selectedReport) {
    //         case COMBINED_REPORT:
    //             return '';
    //         case DETAILED_REPORT:
    //             return selectedDirector
    //                 ? `Credit Bureau Report For ${capitalizeWords(selectedDirector?.name)}`
    //                 : 'Detailed Report';
    //         default:
    //             return '';
    //     }
    // };

    const keyOfficialsCount = () => {
        const directorsCount = directorsList?.length;
        if (selectedReport === COMBINED_REPORT && directorsCount) {
            return ` (${directorsCount > 1 ? 'all ' : ''}${directorsCount} key official${
                directorsCount > 1 ? 's' : ''
            })`;
        }
        return '';
    };

    const renderTableSection = () => {
        return (
            <div className={styles.TableDataTypeSection}>
                <CreditSummaryTable assesseeOrgId={selectedCompany?.assesseeOrgId} />
            </div>
        );
    };

    const getRiskVariantFromRiskCategory = (riskCategory: string): 'Low' | 'Medium' | 'High' => {
        switch (riskCategory) {
            case 'Low Risk':
                return 'Low';
            case 'Moderate Risk':
                return 'Medium';
            case 'High Risk':
                return 'High';
            default:
                return 'Low';
        }
    };

    const renderChartsSection = () => {
        return (
            <div className={styles.ChartDataTypeSection}>
                <div className={styles.Section}>
                    <div className={styles.TopCard}>
                        <div className={styles.Top}>
                            <div className={styles.Title}>{getReportTitle()}</div>
                            <div className={styles.Right}>
                                <CreditSummaryBar
                                    minScore={cibilOverviewData?.creditScoreMin}
                                    maxScore={cibilOverviewData?.creditScoreMax}
                                />
                                {(bureauType || directorId) && (
                                    <img
                                        src={getImageSource(
                                            selectedDirector,
                                            bureauType?.toUpperCase(),
                                        )}
                                        alt="source"
                                        className={
                                            selectedDirector?.directorId || bureauType
                                                ? styles.Image
                                                : styles.NoImage
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.Body}>
                            <div className={styles.ChartsPanel}>
                                <div className={styles.Card}>
                                    <div className={styles.Metrics}>
                                        <div>
                                            Total active loan accounts
                                            <div
                                                className={clsx(
                                                    styles.MetricValue,
                                                    styles.GrayPill,
                                                )}
                                            >
                                                {cibilOverviewData?.totalActiveAccounts}
                                            </div>
                                        </div>
                                        <div>
                                            Total sanction amount (as of today)
                                            <div
                                                className={clsx(
                                                    styles.MetricValue,
                                                    styles.GrayPill,
                                                )}
                                            >
                                                ₹
                                                {cibilOverviewData?.totalSanctionedAmount?.toLocaleString(
                                                    'en-IN',
                                                    { maximumFractionDigits: 2 },
                                                )}
                                                L
                                            </div>
                                        </div>
                                        <div>
                                            Credit utilisation (as of today)
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.creditUtilisation ||
                                                        0 < 30,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.creditUtilisation ||
                                                        0 >= 30,
                                                })}
                                            >
                                                {Math.round(
                                                    parseFloat(
                                                        `${cibilOverviewData?.creditUtilisation}`,
                                                    ),
                                                )}
                                                %
                                            </div>
                                        </div>
                                        <div>
                                            Enquiries in past 90 days
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.enquiriesInPast90Days ||
                                                        0 < 10,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.enquiriesInPast90Days ||
                                                        0 >= 10,
                                                })}
                                            >
                                                {cibilOverviewData?.enquiriesInPast90Days} enquiries
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.Card}>
                                    <div className={styles.Metrics}>
                                        <div>
                                            No. of accounts with DPDs
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.noOfDpdAccounts ||
                                                        0 <= 0,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.noOfDpdAccounts || 0 > 0,
                                                })}
                                            >
                                                {cibilOverviewData?.noOfDpdAccounts} accounts
                                            </div>
                                        </div>
                                        <div>
                                            Total no. of DPDs recorded
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.totalDpdsRecorded ||
                                                        0 <= 0,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.totalDpdsRecorded ||
                                                        0 > 0,
                                                })}
                                            >
                                                {cibilOverviewData?.totalDpdsRecorded} days
                                            </div>
                                        </div>
                                        <div>
                                            Current overdue amount
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.totalOverdueAmount ||
                                                        0 <= 0,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.totalOverdueAmount ||
                                                        0 > 0,
                                                })}
                                            >
                                                ₹
                                                {cibilOverviewData?.totalOverdueAmount?.toLocaleString(
                                                    'en-IN',
                                                    { maximumFractionDigits: 2 },
                                                )}
                                                L
                                            </div>
                                        </div>
                                        <div>
                                            Highest DPD recorded till date (no. of days)
                                            <div
                                                className={clsx(styles.MetricValue, {
                                                    [styles.LowRiskBadge]:
                                                        cibilOverviewData?.highestDpdRecorded ||
                                                        0 <= 0,
                                                    [styles.HighRiskBadge]:
                                                        cibilOverviewData?.highestDpdRecorded ||
                                                        0 > 0,
                                                })}
                                            >
                                                {cibilOverviewData?.highestDpdRecorded} days
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.ChartSections}>
                    {selectedReportConfig?.chartPanel?.sections?.map((section, i) => (
                        <div className={styles.ChartSection} key={`${section.title}-${i}`}>
                            {/* <div className={styles.SectionTitle}>{section.title}</div> */}
                            <div className={styles.GraphsContainer}>
                                {section?.cards?.map((chartName, i) => (
                                    <div
                                        key={`${chartName}-${i}`}
                                        style={{
                                            gridColumn:
                                                chartsData?.[chartName]?.metadata?.tileSize === 2
                                                    ? '1/3'
                                                    : '',
                                        }}
                                    >
                                        {!!chartsData?.[chartName] && (
                                            <Graph
                                                key={`${chartName}-${i}`}
                                                graphData={chartsData[chartName]}
                                                graphName={chartName}
                                                fetchGraph={() => fetchGraph(chartName)}
                                                selectedPeriod={''}
                                                user={user}
                                                showAlerts={true}
                                                horizontalChart={
                                                    chartsData?.[chartName]?.metadata?.tileSize ===
                                                    2
                                                }
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const getRenderComponent = () => {
        switch (dataType) {
            case DASHBOARD_DATA_TYPES.CHARTS:
                return <>{renderChartsSection()}</>;
            case DASHBOARD_DATA_TYPES.TABLE:
                return <>{renderTableSection()}</>;
            default:
                return 'no';
        }
    };

    return (
        <div className={clsx(styles.Report)}>
            <div className={styles.Breadcrumb}>
                <span
                    className={styles.Blue}
                    onClick={() => {
                        setSelectedReport('');
                        resetQueryParams();
                    }}
                >
                    <img src={leftPurpleArrow} alt="" />
                    Credit Summary
                </span>
                <img src={expandMore} alt="exp" height="10px" />
                {getReportTitle()}
            </div>

            <div className={styles.TypeSelector}>
                <TypeSelector
                    options={dataTypeSelectorOptions}
                    selectedOptionId={dataType}
                    changeSelectedOption={(id) => setDataType(id)}
                />
            </div>

            <AicaOverview
                module="cibilIndividual"
                style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
                resourceId={searchParams.get('directorId') as string}
            />

            {getRenderComponent()}
        </div>
    );
}

export default Report;
