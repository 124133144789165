import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import styles from './Topbar.module.scss';
import { INVESTOR_ROUTES, LENDER_ROUTES } from '../../utils/constants/routesConst';
import { ACCESS_TIERS, ACCESS_TYPES } from '../../store/investor/constants';
import React, { useEffect, useState } from 'react';
import TabSlider, { Tab } from './TabSlider/TabSilder';
import ProfileButton from './ProfileButton/ProfileButton';
import { USER_TYPE } from '../../utils/constants/user';
import {
    AicaLogo,
    DEALS_TOPBAR_ICON_GREY,
    DEALS_TOPBAR_ICON_PURPLE,
    dealsIconSelected,
    dealsIconUnSelected,
    FIND_TOPBAR_ICON_GREY,
    FIND_TOPBAR_ICON_PURPLE,
    greyLeads,
    greyProspects,
    LEADS_TOPBAR_ICON_GREY,
    LEADS_TOPBAR_ICON_PURPLE,
    magicUploadIconColor,
    PORTFOLIO_TOPBAR_ICON_GREY,
    PORTFOLIO_TOPBAR_ICON_PURPLE,
    purpleLeads,
    purpleProspects,
} from '../../assets/hostedassets';
import { updateUntaggedMuFileNumber } from '../../store/investor/action';
import { useDispatch } from 'react-redux';
import { getUntaggedCount } from '../MagicUpload/MagicUploadHelper';
import InvestmentPreferenceIcon from '../../assets/Svg/InvestmentPreferenceIcon';
import { InviteUserIcon } from '../../assets/Svg/InviteUserIcon';
import { TopbarReturn, useUploadData } from '../../modules/Company/UploadData/useUploadData';
import PersistentConfigToolTip from '../PersistentConfigToolTip/PersistentConfigToolTip';
import { Button } from '../_custom';
import WhiteBackGroundPencilIcon from '../../assets/Svg/WhiteBackGroundPencilIcon';
import { getCompanyMetrics } from '../../store/debtProducts/apiHelper_debtProducts';
import { CAPITAL_REQUIREMENT_BUTTON } from '../../modules/Company/CompanyOffers/components/OffersConstants';
import AddAssessee from '../../modules/Company/AddAssessee/AddAssessee';
import { Mixpanel } from '../../utils/mixpanel';
import UploadDataIcon from '../../assets/Svg/UploadDataIcon';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import AIText from '../AIText/AIText';
import { closeCommentsThread } from '../../store/commentsThread/commentsThread_actions';

export const tabOptions: Tab[] = [
    {
        id: ACCESS_TIERS.WATCH,
        label: 'Find',
        img: FIND_TOPBAR_ICON_GREY,
        selectedImg: FIND_TOPBAR_ICON_PURPLE,
        route: INVESTOR_ROUTES.WATCH,
        isLocked: true,
    },
    {
        id: ACCESS_TIERS.SCAN,
        label: 'Leads',
        img: LEADS_TOPBAR_ICON_GREY,
        selectedImg: LEADS_TOPBAR_ICON_PURPLE,
        route: INVESTOR_ROUTES.SCAN,
        isLocked: true,
    },
    {
        id: ACCESS_TIERS.DEALS,
        label: 'Deals',
        img: DEALS_TOPBAR_ICON_GREY,
        selectedImg: DEALS_TOPBAR_ICON_PURPLE,
        route: INVESTOR_ROUTES.DEALS,
        isLocked: true,
    },
    {
        id: ACCESS_TIERS.TRACK,
        label: 'Portfolio',
        img: dealsIconUnSelected,
        selectedImg: dealsIconSelected,
        route: INVESTOR_ROUTES.MONITOR,
        isLocked: true,
    },
];

const TOPBAR_CONSTANTS = {
    TOOLTIP: {
        HEADING: 'Requirement is missing',
        MESSAGE: 'Add your expected terms to help us understand your requirement.',
        STORAGE_KEY: 'capital_requirement_tooltip_closed',
    },
    BUTTONS: {
        MAGIC_UPLOAD: 'Magic Upload',
        INVITE_USER: 'Invite User',
        INVESTMENT_PREFERENCES: 'Investment Preferences',
    },
} as const;

function Topbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isCompany = location.pathname.includes('company');
    const isOffersTab = location.pathname.includes('offers');
    const user = useAppSelector((state) => state.user);
    const userType = user.userData.accountType;
    const commentThreadReducer = useAppSelector((state) => state.commentsThreadReducer);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const logoUrl = useAppSelector((state) => state.user.domainMetadata?.logoUrl);
    const investor = useAppSelector((state) => state.investor);
    const permissions = useAppSelector((state) => state.investor.permissions);
    const dashboardConfig: any = investor.config;
    const demoOrgId = user.domainMetadata?.demoOrgId;
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const [showSearch, setShowSearch] = useState(false);
    const showMu = !(isPartner || user.userData.accountType === USER_TYPE.ADMIN_TEAM) && permissions.showMagicUpload;
    const isLender = useAppSelector((state) => state.user.userData.accountType) === USER_TYPE.LENDER;
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);
    const { openInviteBorrower, closeInviteBorrower, showInviteBorrower, companyConfig, openCapitalRequirementDrawer } = useUploadData({
        userType: USER_TYPE.COMPANY,
        mode: 'topbar',
    }) as TopbarReturn;
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);

    const [searchParams, setSearchParams] = useSearchParams();
    const isMagicLink = searchParams.get('configName') === 'MAGIC_LINK';
    const [navbarItems, setNavbarItems] = useState<Tab[]>(
        isMagicLink ? [] : isCompany ? companyConfig?.navBarOptions || [] : [...tabOptions],
    );
    const [selected, setSelected] = useState<Tab | null>(tabOptions[0]);
    const [companyMetrics, setCompanyMetrics] = useState<any>(null);
    const assesseeId = useAppSelector((state) => state.user.userData.assesseeOrgId);

    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipDelay, setTooltipDelay] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTooltipDelay(true);
        }, 8000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (commentThreadReducer.showCommentsThread && selected) {
            dispatch(closeCommentsThread());
        }
    }, [selected]);

    useEffect(() => {
        const offersTab = companyConfig?.navBarOptions?.find((tab) => tab.id === 'OFFERS');
        const isOffersLocked = offersTab?.isLocked || offersTab?.isHidden;

        setShowTooltip(
            !localStorage.getItem(TOPBAR_CONSTANTS.TOOLTIP.STORAGE_KEY) &&
                isCompany &&
                !isMagicLink &&
                !isOffersTab &&
                (!companyMetrics || Object.keys(companyMetrics).length === 0) &&
                !isOffersLocked &&
                tooltipDelay,
        );
    }, [isCompany, isMagicLink, isOffersTab, companyMetrics, companyConfig?.navBarOptions, tooltipDelay]);

    const handleTooltipClose = () => {
        localStorage.setItem(TOPBAR_CONSTANTS.TOOLTIP.STORAGE_KEY, 'true');
        setShowTooltip(false);
    };

    const openPecDrawer = () => {
        navigate(LENDER_ROUTES.MANAGE_ELIGIBILITY);
    };

    const getUntaggedStatus = async () => {
        const res = getUntaggedCount(
            {
                pnoId: user.userData.investorOrgId,
            },
            {
                onSuccess: (res) => {
                    dispatch(updateUntaggedMuFileNumber(res));
                },
            },
        );
    };

    useEffect(() => {
        getUntaggedStatus();
    }, []);

    useEffect(() => {
        if (permissions && permissions.permissionsFetched && !isCompany) {
            const tabOptions = [
                {
                    id: ACCESS_TIERS.WATCH,
                    label: 'Prospects',
                    img: greyProspects,
                    selectedImg: purpleProspects,
                    route: INVESTOR_ROUTES.WATCH,
                    isLocked: permissions.WATCH === ACCESS_TYPES.LOCKED,
                    isHidden: permissions.WATCH === ACCESS_TYPES.HIDDEN,
                },
                {
                    id: ACCESS_TIERS.SCAN,
                    label: 'Leads',
                    img: greyLeads,
                    selectedImg: purpleLeads,
                    route: INVESTOR_ROUTES.SCAN,
                    isLocked: permissions.SCAN === ACCESS_TYPES.LOCKED,
                    isHidden: permissions.SCAN === ACCESS_TYPES.HIDDEN,
                },
                {
                    id: ACCESS_TIERS.TRACK,
                    label: 'Portfolio',
                    img: PORTFOLIO_TOPBAR_ICON_GREY,
                    selectedImg: PORTFOLIO_TOPBAR_ICON_PURPLE,
                    route: INVESTOR_ROUTES.MONITOR,
                    isLocked: permissions.TRACK === ACCESS_TYPES.LOCKED || isPartner,
                    isHidden: permissions.TRACK === ACCESS_TYPES.HIDDEN || isPartner,
                },
                {
                    id: ACCESS_TIERS.DEALS,
                    label: 'Deals',
                    img: dealsIconUnSelected,
                    selectedImg: dealsIconSelected,
                    route: INVESTOR_ROUTES.DEALS,
                    isLocked: permissions.DEALS === ACCESS_TYPES.LOCKED,
                    isHidden: permissions.DEALS === ACCESS_TYPES.HIDDEN || isPartner || isCompany || isLender,
                },
            ];
            setNavbarItems(tabOptions);

            // Find the matching tab based on current location
            const currentTab = tabOptions.find((tab) => location.pathname.includes(tab.route));
            setSelected(currentTab || tabOptions[0]);

            // Navigation logic
            if (permissions.WATCH === ACCESS_TYPES.LOCKED || (isPartner && location.pathname === '/investor/app/watch')) {
                if (permissions.SCAN !== ACCESS_TYPES.LOCKED) {
                    navigate('/investor/app/scan');
                }
            } else if (permissions.SCAN === ACCESS_TYPES.LOCKED && location.pathname === '/investor/app/scan') {
                if (permissions.WATCH !== ACCESS_TYPES.LOCKED) {
                    navigate('/investor/app/watch');
                }
            }
        } else if (isCompany && companyConfig?.navBarOptions && !isMagicLink) {
            const mappedNavBarOptions = companyConfig?.navBarOptions.map((option) => ({
                ...option,
                img: getImageForKey(option?.img),
                selectedImg: getImageForKey(option?.selectedImg),
            }));

            setNavbarItems(mappedNavBarOptions);

            const currentPath = location.pathname;
            const matchingTab = mappedNavBarOptions.find((tab) => currentPath.startsWith(tab?.route));
            setSelected(matchingTab || mappedNavBarOptions[0]);
        }
    }, [permissions, permissions.permissionsFetched, isPartner, isCompany, companyConfig?.navBarOptions, location.pathname, navigate]);
    useEffect(() => {
        if (isCompany) {
            return;
        }
        const companyAccessType = searchParams?.get?.('type')?.toLowerCase?.();
        const tabToSelect = tabOptions?.find((option) => option?.id?.toLowerCase?.() === companyAccessType);
        if (location.pathname.includes('manage-user')) {
            setSelected(null);
        } else if (tabToSelect && !location.pathname.includes('manage-user')) {
            setSelected(tabToSelect);
        }
    }, [location, permissions.permissionsFetched]);

    useEffect(() => {
        if (isCompany) {
            // Company tab selection logic
            const currentPath = location.pathname;
            const activeTabOptions = companyConfig?.navBarOptions;

            const matchingTab = activeTabOptions?.find((tab) => {
                // Remove trailing slashes for comparison
                const tabRoute = tab?.route;
                const currentRoute = currentPath;
                return currentRoute.startsWith(tabRoute);
            });

            // If drawer is open, maintain current tab selection
            if (dealDrawerState?.open) {
                return;
            }

            if (matchingTab) {
                setSelected(matchingTab);
                Mixpanel.track('Borrower Nav Bar Clicked', {
                    'Module Name': matchingTab.label,
                    'PNO ID': user.userData.investorOrgId,
                    'User Email': user.userData.primaryEmail,
                    'Initial Referrer': document.referrer,
                });
            } else {
                setSelected(null);
            }
        } else {
            // Non-company tab selection logic
            const companyAccessType = searchParams?.get?.('type')?.toLowerCase?.();
            const tabToSelect = tabOptions?.find((option) => option?.id?.toLowerCase?.() === companyAccessType);
            if (tabToSelect && !location.pathname.includes('manage-user')) {
                setSelected(tabToSelect);
            } else {
                setSelected(null);
            }
        }
    }, [
        location.pathname,
        isCompany,
        searchParams,
        companyConfig?.navBarOptions,
        dealDrawerState?.open,
        permissions.permissionsFetched,
        companyMetrics,
        user.userData.investorOrgId,
        user.userData.primaryEmail,
    ]);

    const navigateBasedOnAccountType = (accountType: string) => () => {
        switch (accountType) {
            case USER_TYPE.LENDER:
                return navigate(LENDER_ROUTES.DEALS);
            default:
                return navigate(INVESTOR_ROUTES.WATCH);
        }
    };

    const getImageForKey = (imageKey: string) => {
        // If imageKey is not a string (i.e., it's already a component), return it as is
        if (typeof imageKey !== 'string') {
            return imageKey;
        }

        const imageMap = {
            UPLOAD_DATA_ICON_GREY: <UploadDataIcon stroke="#8a8b9f" />,
            UPLOAD_DATA_ICON_PURPLE: <UploadDataIcon stroke="#6021b3" />,
            DEALS_TOPBAR_ICON_GREY: DEALS_TOPBAR_ICON_GREY,
            DEALS_TOPBAR_ICON_PURPLE: DEALS_TOPBAR_ICON_PURPLE,
        };

        return imageMap[imageKey] || imageKey;
    };

    useEffect(() => {
        const fetchMetrics = async () => {
            if (assesseeId) {
                const metrics = await getCompanyMetrics({ assesseeOrgId: assesseeId });
                setCompanyMetrics(metrics);
            }
        };
        fetchMetrics();
    }, [assesseeId]);

    const renderRightSideButton = () => {
        switch (true) {
            case showMu:
                return (
                    <>
                        {(user.userData.accountType === USER_TYPE.ADMIN_TEAM || user.userData.accountType === USER_TYPE.INVESTOR) && (
                            <GlobalSearch showSearch={showSearch} setShowSearch={setShowSearch} />
                        )}
                        <div style={{ position: 'relative' }}>
                            <div
                                className={styles.MagicUploadButton}
                                onClick={() => {
                                    searchParams.set('magicUpload', 'true');
                                    setSearchParams(searchParams);
                                }}
                            >
                                <img src={magicUploadIconColor} alt={'MagicUploadIcon'} height={'16px'} width={'16px'} />
                                <AIText text={TOPBAR_CONSTANTS.BUTTONS.MAGIC_UPLOAD} style={{ fontSize: '0.875rem' }} />
                            </div>
                            {untaggedCount > 0 && <div className={styles.redDot}>{untaggedCount}</div>}
                        </div>
                    </>
                );
            case isLender:
                return (
                    <div className={styles.SampleReport} onClick={openPecDrawer}>
                        <InvestmentPreferenceIcon colour={'var(--primary-text-colour'} height="16" width="20" />
                        <span className={styles.Text}>{TOPBAR_CONSTANTS.BUTTONS.INVESTMENT_PREFERENCES}</span>
                    </div>
                );
            case isCompany && !isMagicLink:
                return (
                    <>
                        <div className={styles.InviteUser} onClick={() => openInviteBorrower()}>
                            <InviteUserIcon color="var(--secondary-button-text)" width="16" height="16" />
                            {TOPBAR_CONSTANTS.BUTTONS.INVITE_USER}
                        </div>
                        <div style={{ position: 'relative' }}>
                            <div
                                className={styles.MagicUploadButton}
                                onClick={() => {
                                    searchParams.set('magicUpload', 'true');
                                    searchParams.set('isCompany', 'true');
                                    setSearchParams(searchParams);
                                }}
                            >
                                <img src={magicUploadIconColor} alt={'MagicUploadIcon'} height={'16px'} width={'16px'} />
                                <AIText text={TOPBAR_CONSTANTS.BUTTONS.MAGIC_UPLOAD} />
                            </div>
                            {/* {untaggedCount > 0 && (
                                <div className={styles.redDot}>{untaggedCount}</div>
                            )} */}
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.Topbar}>
            <div className={styles.Left}>
                <div
                    style={{
                        height: '100%',
                        width: 'auto',
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={logoUrl || AicaLogo}
                        alt="beta"
                        width="auto"
                        style={{ marginLeft: '5px', cursor: 'pointer', height: '1.5rem' }}
                        onClick={navigateBasedOnAccountType(user.userData.accountType)}
                    />
                </div>
                {/*{!isLender && !isPartner && !isCompany && (*/}
                {/*    <div*/}
                {/*        className={clsx({*/}
                {/*            [styles.Active]: dealsSelected,*/}
                {/*            [styles.Disabled]: dealsTab[0].isLocked,*/}
                {/*            [styles.Element]: true,*/}
                {/*        })}*/}
                {/*    >*/}
                {/*        <div*/}
                {/*            className={clsx(*/}
                {/*                {*/}
                {/*                    [styles.ActiveTab]: dealsSelected,*/}
                {/*                    [styles.DisabledTab]: dealsTab[0].isLocked,*/}
                {/*                    ActiveElement: dealsSelected,*/}
                {/*                },*/}
                {/*                styles.ActiveDeals,*/}
                {/*            )}*/}
                {/*            onClick={handleActiveDeals}*/}
                {/*        >*/}
                {/*            <Player*/}
                {/*                autoplay*/}
                {/*                loop*/}
                {/*                src={*/}
                {/*                    dealsSelected !== null*/}
                {/*                        ? activeDealsJson*/}
                {/*                        : DealsTopBarBlinkingDotGrey*/}
                {/*                }*/}
                {/*                style={{*/}
                {/*                    height: '150px',*/}
                {/*                    width: '150px',*/}
                {/*                    position: 'absolute',*/}
                {/*                    left: '-40%',*/}
                {/*                    top: '-155%',*/}
                {/*                }}*/}
                {/*            />*/}
                {/*            <span>Active Deals</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <div className={styles.Center}>
                {!showSearch && (
                    <TabSlider
                        selected={selected}
                        onChange={(tab) => {
                            setSelected(tab);
                        }}
                        navbarItems={navbarItems}
                    />
                )}
            </div>
            <div className={styles.Right}>
                {renderRightSideButton()}
                <ProfileButton />
            </div>

            {showTooltip && (
                <PersistentConfigToolTip
                    heading={TOPBAR_CONSTANTS.TOOLTIP.HEADING}
                    message={TOPBAR_CONSTANTS.TOOLTIP.MESSAGE}
                    position="bottom"
                    anchorId="offers-tab"
                    offset={22}
                    onClose={handleTooltipClose}
                    CustomComponent={
                        <Button
                            variant="purple"
                            text={CAPITAL_REQUIREMENT_BUTTON}
                            onClick={() => {
                                openCapitalRequirementDrawer('tool-tip-upload-data');
                                handleTooltipClose();
                            }}
                            size="small"
                            style={{ height: '40px', margin: '0px' }}
                            iconStyles={{ marginRight: 8, marginBottom: 5 }}
                            icon={<WhiteBackGroundPencilIcon width={20} height={20} fill="black" />}
                        />
                    }
                />
            )}

            {showInviteBorrower && (
                <AddAssessee modalOpen={showInviteBorrower} closeModal={closeInviteBorrower} assesseeOrgId={user.userData.assesseeOrgId} />
            )}
        </div>
    );
}

export default Topbar;
