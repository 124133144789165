import { Handlers } from '../../../types/actions/types';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { getLegalCasesDataService } from './service';

export const fetchLegalCasesData = async (payload: FetchLegalCasesPayload, handlers: Handlers) => {
    try {
        const res = await getLegalCasesDataService(payload);
        const responseData = res.data?.responseData;
        switch (responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(res.data?.legalCasesResponse || {});
                return;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
        }
    } catch (err) {
        handlers?.onError?.();
    }
};

type FetchLegalCasesPayload = {
    assesseeOrgId: string;
    requestId: string;
};
