import UploadCard from '../UploadCard/UploadCard';
import styles from './Financials.module.scss';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_AUDITED_UPLOAD } from '../../../../../utils/constants/mixpanelEvents/companyEvents';

function Financials({
    financialsData,
    assesseeOrgId,
    updateFunction,
    categoryId,
    flowId,
    isInvestor,
}) {
    const updateFinancialReportsOnFileUpload = async (fileData) => {
        await updateFunction({
            auditedFinancialReport: {
                docId: fileData.docId,
                docName: fileData.fileName,
            },
        });
        Mixpanel.track(COMPANY_AUDITED_UPLOAD);
    };

    const deleteFile = async (fileData) => {
        await updateFunction({
            auditedFinancialReport: {
                docId: fileData.docId,
                docName: fileData.docName,
                isDeleted: true,
            },
        });
    };
    return (
        <div style={isInvestor ? { width: '80%' } : {}} className={styles.Financials}>
            <div className={styles.Title}>Audited Financial Statements</div>

            <div className={styles.Text}>
                Upload audited financials for previous financial year (supported file formats: pdf,
                xls, csv, xlsx, zip).
                {/* Learn more */}
            </div>

            <UploadCard
                existingFiles={financialsData?.auditedFinancialReports || []}
                assesseeOrgId={assesseeOrgId}
                metadataUpdate={updateFinancialReportsOnFileUpload}
                // categoryId={21}
                categoryId={categoryId}
                flowId={flowId}
                // flowId={16}
                fileDeleteHandler={deleteFile}
            />
        </div>
    );
}

export default Financials;
