import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import OnePager from '../../common/OnePager/OnePager';
import useLegacyBusinessDataFetch from '../../modules/DataRoom/useLegacyBusinessDataFetch';
import { createPortal } from 'react-dom';
import DashboardHeader from '../../modules/Dashboard/components/DashboardHeader/DashboardHeader';
import { useDispatch } from 'react-redux';
import {
    setShowUpgradeToScan,
    setShowUnlockAicaAndAddToLeads,
    updateNeedInfoFileNumber,
    setShowRefreshReport,
} from '../../store/investor/action';
import { ACCESS_TIERS, ACCESS_TYPES, UnlockAICASourcePage } from '../../store/investor/constants';
import UnlockScanButton from '../../modules/Dashboard/DashboardModules/Overview/components/Buttons/UnlockScan/UnlockScanButton';
import AddToScanButton from '../../modules/Dashboard/DashboardModules/Overview/components/Buttons/AddToScan/AddToScanButton';
import { errorIconRed, pendingDataComplete } from '../../assets/hostedassets';
import clsx from 'clsx';
import { useEffect } from 'react';
import { getDataGapDocs } from '../../common/MagicUpload/MagicUploadHelper';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from '../../scss/companyDashboardParent.module.scss';
import RefreshReport from '../../modules/Dashboard/components/RefreshReport/RefreshReport';

function CompanyDashboardParent() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const params = useParams();
    const header = document.getElementById('header') ?? document.createElement('div');

    const {
        exportData,
        isUserRestricted,
        selectedCompany,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        onUnlockReportSuccess,
    } = useLegacyBusinessDataFetch();
    const user = useAppSelector((state) => state.user);
    const pendingCount = useAppSelector((state) => state.investor.pendingDataCount);
    const permissions = useAppSelector((state) => state.investor.permissions);
    const showDataGapCTA = permissions.showDataGap;

    const isScanLockedForUser = permissions?.[ACCESS_TIERS.SCAN] === ACCESS_TYPES.LOCKED;
    const isDemoProfile = !!selectedCompany?.demoOrg;
    const onePagerState = useAppSelector((state) => state.investor.onePager);
    const showRefreshReport = useAppSelector((state) => state.investor.showRefreshReport);

    const getPendingDocumentsCount = async () => {
        await getDataGapDocs(
            {
                assesseeOrgId: params.id ?? '',
            },
            {
                onSuccess: (res) => {
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                },
            },
        );
    };

    useEffect(() => {
        getPendingDocumentsCount();
    }, []);

    const openDataPendingDrawer = () => {
        searchParams.set('needInfo', 'true');
        searchParams.set('showNeedInfo', 'true');
        searchParams.set('isCompany', 'true');
        setSearchParams(searchParams);
    };

    const renderHeaderButton = () => {
        if (!isUserRestricted) {
            if (isScanLockedForUser) {
                return <UnlockScanButton onClick={() => dispatch(setShowUpgradeToScan(ACCESS_TIERS.SCAN))} />;
            } else if (selectedCompany?.companyAccessType === ACCESS_TIERS.WATCH) {
                return (
                    <AddToScanButton
                        onClick={() => {
                            dispatch(
                                setShowUnlockAicaAndAddToLeads({
                                    source: UnlockAICASourcePage.DASHBOARD_HEADER,
                                    showModal: true,
                                }),
                            );
                        }}
                        accessType={selectedCompany?.companyAccessType}
                        isDemoProfile={isDemoProfile}
                    />
                );
            } else if (user.userData.accountType === 'INVESTOR' && !isDemoProfile && showDataGapCTA) {
                return (
                    <div
                        className={clsx({
                            [styles.PendingCTA]: (pendingCount as number) > 0 || pendingCount === null,
                            [styles.PendingDone]: pendingCount === 0,
                        })}
                        onClick={openDataPendingDrawer}
                    >
                        <img
                            src={pendingCount === 0 ? pendingDataComplete : errorIconRed}
                            alt="pendingDataComplete"
                            height={'20px'}
                            style={{ marginBottom: '2px' }}
                        />
                        <span>{pendingCount === 0 ? 'No Data Pending' : `${pendingCount ?? ''} Data Pending`}</span>
                    </div>
                );
            }
        }
        return null;
    };

    return (
        <div>
            {createPortal(
                <DashboardHeader
                    exportData={exportData}
                    isUserRestricted={isUserRestricted}
                    selectedCompany={selectedCompany}
                    refreshReportStatus={refreshReportStatus}
                    downloadPDFStatus={downloadPDFStatus}
                    goBackToPortfolio={goBackToPortfolio}
                    renderHeaderButton={renderHeaderButton}
                />,
                header,
            )}
            <Outlet />
            {onePagerState.open && ['REPORT_TOP_BAR', 'PORTFOLIO'].includes(onePagerState.source as string) && <OnePager />}

            {showRefreshReport && (
                <RefreshReport
                    profileGenerationStatus={selectedCompany?.profileGenerationStatus ?? ''}
                    onUnlockReportSuccess={onUnlockReportSuccess}
                    close={() => {
                        dispatch(setShowRefreshReport(false));
                    }}
                />
            )}
        </div>
    );
}

export default CompanyDashboardParent;
