import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../BankDataTable.module.scss';

interface RowsProps {
    rows: any;
    headers: any;
    tableRef: any;
}

function Rows(props: RowsProps) {
    const { rows, headers, tableRef } = props;
    const [fullRowColspan, setFullRowColSpan] = useState(1);
    const bounceTransactionsStartIndex = 8;
    const creditsAndDebitsStartIndex = 13;
    const paymentMethodStartIndex = 17;

    useEffect(() => {
        if (tableRef?.current) {
            let colspan = 1;
            const mainCellWidth = 330;
            const dataCellWidth = 150;
            const tableWidth = tableRef?.current?.offsetWidth || 600;
            colspan += Math.round((tableWidth - mainCellWidth) / dataCellWidth) + 1;
            setFullRowColSpan(colspan);
        }
    }, [tableRef?.current]);

    const addSeparatorRow = (index) => {
        switch (index) {
            case creditsAndDebitsStartIndex:
            case bounceTransactionsStartIndex:
            case paymentMethodStartIndex:
                return true;
            // case summaryRowsStartIndex:
            //     return true;
            default:
                return false;
        }
    };

    const transformedRows = rows?.map((row, index) => ({
        ...row,
        amountsMap: row?.amounts?.reduce((map, amount) => {
            map[amount?.period] = amount?.amountFormatted;
            return map;
        }, {}),
    }));

    const formatAmount = (amount = '') => {
        if (!amount) return '-';
        else {
            let val = parseFloat(amount);
            if (val)
                return val < 0
                    ? `(${Math.round(-1 * val)?.toLocaleString('en-IN')})`
                    : Math.round(val)?.toLocaleString();
            else return amount;
        }
    };

    const formatNumbers = (number: string): string => {
        if (!number) return '-';
        else if (number.startsWith('-')) return `(${number.slice(1)})`;
        return number;
    };

    return (
        <>
            {transformedRows?.map?.((row, i) => (
                <React.Fragment key={i}>
                    {addSeparatorRow(i) && (
                        <>
                            <tr
                                className={clsx(styles.SeparatorRow)}
                                key={`${row?.classification}-${i}`}
                            >
                                <td className={styles.SeparatorRowItem} colSpan={fullRowColspan}>
                                    {i === bounceTransactionsStartIndex && 'Bounce Transactions'}
                                    {i === creditsAndDebitsStartIndex && 'Credits and Debits'}
                                    {i === paymentMethodStartIndex && 'Payment Method'}
                                </td>
                            </tr>
                        </>
                    )}
                    <tr key={i} className={clsx(styles.TableBodyRow)}>
                        {headers?.map((header, j) => (
                            <td
                                className={clsx(styles.TableBodyRowItem, {
                                    [styles.negativeValue]:
                                        row?.amountsMap?.[header?.name]?.startsWith('-'),
                                })}
                                key={`${header?.name}-${i}`}
                            >
                                {header?.key === 'classification'
                                    ? row?.classification
                                    : formatNumbers(row?.amountsMap?.[header?.name])}
                            </td>
                        ))}
                    </tr>
                </React.Fragment>
            ))}
        </>
    );
}

export default Rows;
