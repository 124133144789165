import { PopupData } from '../../common/ConfigurablePopup/ConfigurablePopup';
import { CONSTANTS } from './constants';

interface CompanyReducer {
    fetchingConfig: boolean;
    fetchingUploadJourneyData: boolean;
    config: null | CompanyConfig;
    uploadJourneyData: null | any;
    debtProductsFilters: any;
    isUploading: boolean;
    showInviteBorrower: boolean;
    showManageUser: boolean;
}

interface NavBarOption {
    label: string;
    img: string;
    selectedImg: string;
    route: string;
    isLocked: boolean;
    elementId: string;
    isHidden: boolean;
    identifier?: string;
}

interface BorrowerOffers {
    statusToShow: string[];
}

interface CompanyConfig {
    tabsCount: number;
    tabs: Tab[];
    selectedTabId: string;
    tabConfigMetadata: {
        [key: string]: {
            companyKyc: string[];
            directorKyc: string[];
            additionalDocuments: string[];
            tabs: string[];
        };
    };
    tabConfigs: any;
    flowId: number;
    dataConfig: any;
    popupData: PopupData;
    navBarOptions: NavBarOption[];
    showDataPendingPill: boolean;
    borrowerOffers: BorrowerOffers;
}

type Tab = {
    id: string;
    label: string;
};

const initialState: CompanyReducer = {
    fetchingConfig: false,
    fetchingUploadJourneyData: false,
    debtProductsFilters: {},
    config: null,
    uploadJourneyData: null,
    isUploading: false,
    showInviteBorrower: false,
    showManageUser: false,
};

const setIsFetchingConfig = (state, action) => {
    return {
        ...state,
        fetchingConfig: action.payload,
    };
};

const setIsFetchingUploadJourneyData = (state, action) => {
    return {
        ...state,
        fetchingUploadJourneyData: action.payload,
    };
};

const fetchCompanyConfigSuccess = (state, action) => {
    return {
        ...state,
        fetchingConfig: false,
        config: {
            ...action.payload.mainConfig,
        },
    };
};

const fetchUploadJourneyDataSuccess = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...action.payload,
        },
    };
};

const updateCreditBureauCMR = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            cmrData: {
                ...state.uploadJourneyData?.cmrData,
                ...action.payload.data,
            },
        },
    };
};

const updateCreditBureauDirectorCibil = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            directorCibilData: {
                ...state.uploadJourneyData?.directorCibilData,
                ...action.payload.data,
            },
        },
    };
};

const updateGstReports = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            gstReportData: {
                ...state.uploadJourneyData?.gstReportData,
                ...action.payload.data,
            },
        },
    };
};

const updateFinancialReports = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            financialReportData: {
                ...state.uploadJourneyData?.financialReportData,
                ...action.payload.data,
            },
        },
    };
};

const updateAdditionalDocuments = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            additionalDocsData: {
                ...state.additionalDocsData,
                additionalDocs: action?.payload?.data ? [...action.payload.data] : [],
            },
        },
    };
};
const updateCibilSoftPullData = (state, action) => {
    return {
        ...state,
        uploadJourneyData: {
            ...state.uploadJourneyData,
            cibilSoftPullData: {
                ...state.uploadJourneyData?.cibilSoftPullData,
                ...action.payload.data,
            },
        },
    };
};
const uploadStatusUpdate = (state, action) => {
    return {
        ...state,
        isUploading: action.payload,
    };
};

const setShowInviteBorrower = (state, action) => {
    return {
        ...state,
        showInviteBorrower: action.payload,
    };
};

const setShowManageUser = (state, action) => {
    return {
        ...state,
        showManageUser: action.payload,
    };
};

const reducer = (state: CompanyReducer = initialState, action): CompanyReducer => {
    switch (action.type) {
        case CONSTANTS.SET_IS_FETCHING_CONFIG:
            return setIsFetchingConfig(state, action);
        case CONSTANTS.SET_IS_FETCHING_UPLOAD_JOURNEY:
            return setIsFetchingUploadJourneyData(state, action);
        case CONSTANTS.FETCH_COMPANY_CONFIG_SUCCESS:
            return fetchCompanyConfigSuccess(state, action);
        case CONSTANTS.FETCH_UPLOAD_JOURNEY_DATA_SUCCESS:
            return fetchUploadJourneyDataSuccess(state, action);
        case CONSTANTS.UPDATE_CREDIT_BUREAU_CMR:
            return updateCreditBureauCMR(state, action);
        case CONSTANTS.UPDATE_CREDIT_BUREAU_CIBIL:
            return updateCreditBureauDirectorCibil(state, action);
        case CONSTANTS.UPDATE_GST_REPORTS:
            return updateGstReports(state, action);
        case CONSTANTS.UPDATE_FINANCIAL_REPORTS:
            return updateFinancialReports(state, action);
        case CONSTANTS.UPDATE_ADDITIONAL_DOCS:
            return updateAdditionalDocuments(state, action);
        case CONSTANTS.SEND_CIBIL_CONSENT_LINK_SUCCESS:
            return updateCibilSoftPullData(state, action);
        case CONSTANTS.UPDATE_UPLOADING_STATUS:
            return uploadStatusUpdate(state, action);
        case CONSTANTS.SET_DEBT_PRODUCTS_FILTERS:
            return {
                ...state,
                debtProductsFilters: action.payload,
            };
        case CONSTANTS.SET_SHOW_INVITE_USER:
            return setShowInviteBorrower(state, action);
        case CONSTANTS.SET_SHOW_MANAGE_USER_DRAWER:
            return setShowManageUser(state, action);
        default:
            return state;
    }
};

export default reducer;
