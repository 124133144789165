import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import CompanyLogin from '../modules/Company/CompanyLogin/CompanyLogin';
import UploadData from '../modules/Company/UploadData/UploadData';
import * as Sentry from '@sentry/react';
import { BASE_ROUTES, COMPANY_ROUTES, INVESTOR_ROUTES, LENDER_ROUTES } from '../utils/constants/routesConst';
import { ACCESS_TIERS, RESTRICTED_ROLES } from '../store/investor/constants';

import { USER_TYPE } from '../utils/constants/user';
import { changeFavicon, getDomain, getSubdomain, history, isRestrictedUser, navigateUserAfterUnauth } from '../utils/utils';
import CompanyPrivateRoutes from './CompanyPrivateRoutes';
import { fetchDomainMetadataV2 } from '../store/user/userV2/actions';
import InvestorSignup from '../modules/Investor/InvestorSignup/InvestorSignup';
import CibilVerification from '../modules/Company/CibilVerification/CibilVerification';
import SummonAica from '../modules/SummonAica/SummonAica';
import PageNotFound from './404';
import PartnerSignup from '../modules/Investor/PartnerSignup/PartnerSignup';
import { hotjarInitialize } from '../utils/hotjar';
import DataRoom from '../modules/DataRoom/DataRoom';
import DomainNotFound from './DomainNotFound';
import { defaultUiStyle, getRouteType, ROOT_TYPE, uiStyleType } from '../utils/constants/commonConstants';
import { useApplyTheme } from '../utils/customHooks';
import DebtProducts from '../modules/DebtProducts/DebtProducts';
import CompanyDashboardParent from './containerRoutes/CompanyDashboardParent';
import MagicLink from '../modules/Company/MagicLink/MagicLink';
import AicaPDFReport from '../modules/AicaPDFReport/AicaPDFReport';
import CompanyOffers from '../modules/Company/CompanyOffers/CompanyOffers';
import { DEALS_SCREENS } from '../modules/Investor/Deals/Deals';

const BaseRoute = lazy(() => import('./BaseRoute'));
const InvestorPrivateRoutes = lazy(() => import('./InvestorPrivateRoutes'));
const LenderPrivateRoutes = lazy(() => import('./LenderPrivateRoutes'));
const Dashboard = lazy(() => import('../modules/Dashboard'));
const InvestorLogin = lazy(() => import('../modules/Investor/InvestorLogin/InvestorLogin'));
const Portfolio = lazy(() => import('../modules/Investor/Portfolio/Portfolio'));
const Deals = lazy(() => import('../modules/Investor/Deals/Deals'));
const ManageUser = lazy(() => import('../modules/Investor/ManageUser/ManageUser'));
const ManageEligibility = lazy(() => import('../modules/Investor/ManageEligibility/ManageEligibility'));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

/**
 * @Description
 * Root File that holds all the routes and respective components.
 */

function RootRoutes() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useAppSelector((state) => state.user);
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const faviconUrl = user.domainMetadata?.faviconUrl;
    const isLender = useAppSelector((state) => state.user.userData.accountType) === 'LENDER';
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;

    const authCodeParam = searchParams.get('code');

    history.navigate = navigate;
    history.location = location;

    const borrowerStyle = useAppSelector((state) => state.user.domainMetadata?.borrower);
    const lenderStyle = useAppSelector((state) => state.user.domainMetadata?.lender);
    const investorStyle = useAppSelector((state) => state.user.domainMetadata?.pno);

    const isUiStyle = borrowerStyle || lenderStyle || investorStyle;
    const [currentUiStyle, setCurrentUIStyle] = useState<uiStyleType | undefined>({
        buttons: {
            primary: {},
            secondary: {},
            tertiary: {},
        },
        tabs: {
            active: {},
            inactive: {},
        },
        backgrounds: {
            primary: {},
            secondary: {},
        },
        texts: {
            primary: {},
        },
        borders: {
            primary: {},
        },
    });

    useEffect(() => {
        const userType = getRouteType(location.pathname);
        let newUiStyle: uiStyleType = defaultUiStyle;

        switch (userType) {
            case ROOT_TYPE.BORROWER:
                newUiStyle = borrowerStyle || defaultUiStyle;
                break;
            case ROOT_TYPE.LENDER:
                newUiStyle = lenderStyle || defaultUiStyle;
                break;
            case ROOT_TYPE.INVESTOR:
                newUiStyle = investorStyle || defaultUiStyle;
                break;
            default:
                newUiStyle = defaultUiStyle;
                break;
        }
        setCurrentUIStyle(newUiStyle);
    }, [location.pathname, isUiStyle]);

    useApplyTheme(currentUiStyle);

    useEffect(() => {
        getDomainMetadata();
        hotjarInitialize();
    }, []);

    useEffect(() => {
        if (authCodeParam) {
            navigate(`/investor/app/scan?code=${authCodeParam}`);
        } else {
            console.log('No authorization code found');
        }
    }, []);

    useEffect(() => {
        if (faviconUrl) changeFavicon(faviconUrl);
    }, [faviconUrl]);

    useEffect(() => {
        if (user?.userData?.userId) {
            window.addEventListener('storage', onStorageChange);
        }
        return () => {
            window.removeEventListener('storage', onStorageChange);
        };
    }, [user?.userData?.userId]);

    const onStorageChange = () => {
        const userDataFromSessionStorage = sessionStorage.getItem('user_data')
            ? JSON.parse(sessionStorage.getItem('user_data') || '')
            : null;
        if (userDataFromSessionStorage?.userId) {
            if (user.userData.userId && userDataFromSessionStorage.userId !== user.userData.userId) {
                navigateUserAfterUnauth(location, navigate);
            }
        } else {
            const userDataFromLocalStorage = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
            if (userDataFromLocalStorage?.userId && user.userData.userId && userDataFromLocalStorage?.userId !== user.userData.userId) {
                navigateUserAfterUnauth(location, navigate);
            }
        }
    };

    const getDomainMetadata = () => {
        dispatch(
            fetchDomainMetadataV2({
                subdomainName: getSubdomain(),
                domainName: getDomain(),
                referrer: location.pathname,
            }),
        );
    };

    const renderRegisterRoutes = () => (
        <SentryRoutes>
            <Route path="*" element={<PageNotFound />} />
            <Route path={BASE_ROUTES.NOT_FOUND} element={<DomainNotFound />} />
            <Route path={INVESTOR_ROUTES.SIGNUP} element={<InvestorSignup />} />
            <Route path={INVESTOR_ROUTES.LOGIN} element={<InvestorSignup isLogin />} />
        </SentryRoutes>
    );

    const renderRestrictedRoutes = () => (
        <SentryRoutes>
            <Route path="*" element={<PageNotFound />} />
            <Route path={BASE_ROUTES.NOT_FOUND} element={<DomainNotFound />} />
            <Route path={INVESTOR_ROUTES.SUMMON_AICA} element={<SummonAica />} />
            <Route path={INVESTOR_ROUTES.LOGIN} element={<InvestorLogin />} />
            <Route path={INVESTOR_ROUTES.APP} element={<InvestorPrivateRoutes />}>
                <Route element={<CompanyDashboardParent />}>
                    <Route path={INVESTOR_ROUTES.PORTFOLIO_DETAILS} element={<Dashboard />} />
                    <Route path={INVESTOR_ROUTES.DATA_ROOM_DETAILS} element={<DataRoom />} />
                </Route>
            </Route>
        </SentryRoutes>
    );

    const renderLenderRoutes = () => (
        <SentryRoutes>
            <Route path="*" element={<PageNotFound />} />
            <Route path={BASE_ROUTES.NOT_FOUND} element={<DomainNotFound />} />
            <Route path={BASE_ROUTES.LOGIN} element={<CompanyLogin />} />
            <Route path={BASE_ROUTES.INVESTOR} element={<BaseRoute userType={USER_TYPE.INVESTOR} />} />
            <Route path={INVESTOR_ROUTES.LOGIN} element={<InvestorLogin />} />
            <Route path={INVESTOR_ROUTES.PARTNER_SIGNUP} element={<PartnerSignup additionalDetailsPage={false} />} />
            <Route path={INVESTOR_ROUTES.PARTNER_SIGNUP_ADDITIONAL_DETAILS} element={<PartnerSignup additionalDetailsPage={true} />} />
            <Route path={INVESTOR_ROUTES.SIGNUP} element={<InvestorLogin signUp={true} />} />
            <Route path={INVESTOR_ROUTES.SUMMON_AICA} element={<SummonAica />} />
            <Route path={INVESTOR_ROUTES.APP} element={<InvestorPrivateRoutes />}>
                <Route element={<CompanyDashboardParent />}>
                    <Route path={INVESTOR_ROUTES.PORTFOLIO_DETAILS} element={<Dashboard />} />
                    <Route path={INVESTOR_ROUTES.DATA_ROOM_DETAILS} element={<DataRoom />} />
                </Route>
            </Route>
            <Route path={LENDER_ROUTES.APP} element={<LenderPrivateRoutes />}>
                <Route path={INVESTOR_ROUTES.DEALS} element={<Deals screen={DEALS_SCREENS.GLOBAL_DEALS} />} />
                <Route path={LENDER_ROUTES.MANAGE_ELIGIBILITY} element={<ManageEligibility />} />
            </Route>
        </SentryRoutes>
    );

    const renderDefaultRoutes = () => (
        <SentryRoutes>
            <Route path="*" element={<PageNotFound />} />
            <Route path={BASE_ROUTES.NOT_FOUND} element={<DomainNotFound />} />
            <Route path={BASE_ROUTES.CIBIL_VERIFICATION} element={<CibilVerification />} />
            <Route path={BASE_ROUTES.HOME} element={<CompanyLogin />} />
            <Route path={BASE_ROUTES.LOGIN} element={<CompanyLogin isLogin={true} />} />
            <Route path={BASE_ROUTES.ADDITIONAL_DETAILS} element={<CompanyLogin applicationDetailPage={true} />} />
            <Route path={BASE_ROUTES.RESET_PASSOWRD} element={<InvestorLogin resetPassword={true} />} />
            <Route path={BASE_ROUTES.MAGIC_LINK} element={<MagicLink />} />
            <Route path={BASE_ROUTES.COMPANY} element={<CompanyPrivateRoutes />}>
                <Route path={COMPANY_ROUTES.UPLOAD} element={<UploadData userType={USER_TYPE.COMPANY} closeDrawer={() => {}} />} />
                <Route path={COMPANY_ROUTES.OFFERS} element={<CompanyOffers />} />
            </Route>
            <Route path={BASE_ROUTES.INVESTOR} element={<BaseRoute userType={USER_TYPE.INVESTOR} />} />
            <Route path={INVESTOR_ROUTES.LOGIN} element={<InvestorLogin />} />
            <Route path={INVESTOR_ROUTES.PARTNER_SIGNUP} element={<PartnerSignup additionalDetailsPage={false} />} />
            <Route path={INVESTOR_ROUTES.PARTNER_SIGNUP_ADDITIONAL_DETAILS} element={<PartnerSignup additionalDetailsPage={true} />} />
            <Route path={INVESTOR_ROUTES.SIGNUP} element={<InvestorLogin signUp={true} />} />
            <Route path={INVESTOR_ROUTES.SUMMON_AICA} element={<SummonAica />} />
            <Route path={INVESTOR_ROUTES.APP} element={<InvestorPrivateRoutes />}>
                {!isPartner && <Route path={INVESTOR_ROUTES.MANAGE_USER} element={<ManageUser />} />}
                <Route path={INVESTOR_ROUTES.WATCH} element={<Portfolio accessTier={ACCESS_TIERS.WATCH} />} />
                {!isPartner && <Route path={INVESTOR_ROUTES.MANAGE_ELIGIBILITY} element={<ManageEligibility />} />}
                <Route path={INVESTOR_ROUTES.SCAN} element={<Portfolio accessTier={ACCESS_TIERS.SCAN} />} />
                <Route path={INVESTOR_ROUTES.MONITOR} element={<Portfolio accessTier={ACCESS_TIERS.TRACK} />} />
                <Route path={INVESTOR_ROUTES.DEALS} element={<Deals screen={DEALS_SCREENS.GLOBAL_DEALS} />} />
                <Route element={<CompanyDashboardParent />}>
                    <Route path={INVESTOR_ROUTES.PORTFOLIO_DETAILS} element={<Dashboard />} />
                    <Route path={INVESTOR_ROUTES.DATA_ROOM_DETAILS} element={<DataRoom />} />
                    <Route path={INVESTOR_ROUTES.DEBT_PRODUCTS} element={<DebtProducts />} />
                    <Route path={INVESTOR_ROUTES.COMPANY_DEALS} element={<Deals screen={DEALS_SCREENS.COMPANY_DEALS} />} />
                </Route>

                <Route path={INVESTOR_ROUTES.PDF_REPORT} element={<AicaPDFReport />} />
            </Route>
            <Route path={LENDER_ROUTES.APP} element={<LenderPrivateRoutes />}>
                <Route path={INVESTOR_ROUTES.DEALS} element={<Deals screen={DEALS_SCREENS.GLOBAL_DEALS} />} />
            </Route>
        </SentryRoutes>
    );

    const renderRoutes = () => {
        if (getSubdomain()?.includes('register-')) {
            return renderRegisterRoutes();
        } else if (isUserRestricted) {
            return renderRestrictedRoutes();
        } else if (isLender) {
            return renderLenderRoutes();
        } else {
            return renderDefaultRoutes();
        }
    };

    return <Suspense fallback={<></>}>{renderRoutes()}</Suspense>;
}

export default RootRoutes;
