import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../../app/hooks';
import { getCompanyConfigData } from '../../../../../../store/company/service';
import { getDealTermsLogs, postCreateOrUpdateDealTermsLog } from '../Store/DealsHelper';
import { STATUS_DROPDOWN_OPTIONS } from '../DealsTable/components/DealsRow/DealsRow';
import { StatusDropdownOption } from '../../../../Portfolio/components/CompaniesList/components/Table/components/StatusDropdown/StatusDropdown';
import { DealTermsLogType, DealTermsLogs } from './DealTermsLoggingDrawer';
import { useDealsList } from '../useDealsList';

interface UseDealTermsLoggingDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    investorDealId: string;
}

export const useDealTermsLoggingDrawer = ({ isOpen, onClose, investorDealId }: UseDealTermsLoggingDrawerProps) => {
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const primaryEmail = useAppSelector((state) => state.user.userData.primaryEmail);
    const userData = useAppSelector((state) => state.user.userData);
    const { setRefreshDeals } = useDealsList();

    const [dealTermsLoggingConfig, setDealTermsLoggingConfig] = useState<any>(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [dealTermsLogs, setDealTermsLogs] = useState<DealTermsLogs>({ dealTermsLogs: [] });
    const [runtimeLogs, setRuntimeLogs] = useState<DealTermsLogType[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<StatusDropdownOption>(STATUS_DROPDOWN_OPTIONS.SHARED);
    const [hasChanges, setHasChanges] = useState(false);
    const [expandedLogStatus, setExpandedLogStatus] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [deletingLogIds, setDeletingLogIds] = useState<Set<string>>(new Set());

    const latestLogRef = useRef<HTMLDivElement>(null);
    const originalLogsRef = useRef<DealTermsLogs>({ dealTermsLogs: [] });

    const primaryCtaText = dealTermsLoggingConfig?.mainConfig?.additionalDetailsConfig?.pages?.[0]?.ctas?.primary?.text;
    const secondaryCtaText = dealTermsLoggingConfig?.mainConfig?.additionalDetailsConfig?.pages?.[0]?.ctas?.secondary?.text;
    const headerCtsText = dealTermsLoggingConfig?.mainConfig?.additionalDetailsConfig?.pages?.[0]?.ctas?.header?.text;

    const fetchConfig = async () => {
        try {
            setIsLoading(true);
            const res = await getCompanyConfigData({
                accountType: userData.accountType,
                investorOrgId: investorOrgId,
                primaryEmail: primaryEmail,
                configName: 'AICA_DEAL_LOGGING_DETAILS',
            });
            if (res.data) {
                setDealTermsLoggingConfig(res.data);
            }
        } catch (error) {
            toast.error('Error fetching deal terms logging config');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDealTermsLogsData = async () => {
        try {
            await getDealTermsLogs(investorDealId, {
                onSuccess: (data) => {
                    const newLogs = { dealTermsLogs: data };
                    setDealTermsLogs(newLogs);
                    originalLogsRef.current = newLogs;
                },
                onError: () => {
                    toast.error('Failed to fetch deal terms logs');
                },
            });
        } catch (error) {
            toast.error('Error fetching deal terms logs');
        } finally {
            setIsLoading(false);
        }
    };

    const handleStatusChange = (option: StatusDropdownOption) => {
        setSelectedStatus(option);
        setHasChanges(true);

        const allLogs = [...(dealTermsLogs?.dealTermsLogs || []), ...runtimeLogs];
        const lastLog = allLogs[allLogs.length - 1];

        const newLog: DealTermsLogType = {
            tempId: `temp-${Date.now()}`,
            investorDealId: lastLog.investorDealId,
            dealId: lastLog.dealId,
            logStatus: option.value,
            logDate: new Date().toISOString().substring(0, 10),
            dealTerms: { ...lastLog.dealTerms, logDate: new Date().toISOString().substring(0, 10), documents: [] },
            owner: {
                name: userData.userName,
                userId: userData.userId,
                activityBy: userData.userName,
                activityDate: new Date().toISOString(),
                ownerType: userData.accountType,
            },
        };

        setRuntimeLogs((prev) => [...prev, newLog]);
        setExpandedLogStatus(newLog.tempId || null);

        setTimeout(() => {
            latestLogRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }, 320);
    };

    const sourceBasedActions = (source: string) => {
        if (source === 'View Deal') {
            const allLogs = [...(dealTermsLogs?.dealTermsLogs || []), ...runtimeLogs];
            const latestLog = allLogs[allLogs.length - 1];
            if (latestLog) {
                setExpandedLogStatus(latestLog.id || latestLog.tempId || null);
                setTimeout(() => {
                    latestLogRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }, 600);
            }
        } else {
            setExpandedLogStatus(null);
        }
    };

    const handleLogUpdate = (updatedLog: DealTermsLogType) => {
        if (updatedLog.id) {
            setDealTermsLogs({
                dealTermsLogs: dealTermsLogs.dealTermsLogs.map((log) => (log.id === updatedLog.id ? updatedLog : log)),
            });
        } else {
            setRuntimeLogs((prev) => prev.map((log) => (log.tempId === updatedLog.tempId ? updatedLog : log)));
        }
    };

    const handleDeleteRuntimeLog = (tempId: string) => {
        setRuntimeLogs((prev) => prev.filter((log) => log.tempId !== tempId));
    };

    const handleDropdownToggle = (log: DealTermsLogType, isExpanded: boolean) => {
        const logIdentifier = log.id || log.tempId || null;
        setExpandedLogStatus(isExpanded ? logIdentifier : null);
    };

    const handleClose = () => {
        setExpandedLogStatus(null);
        setRuntimeLogs([]);
        setDealTermsLogs(originalLogsRef.current);
        setHasChanges(false);
        onClose();
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const combinedLogs = {
                requestPayload: {
                    dealTermsLogs: [...(dealTermsLogs?.dealTermsLogs || []), ...runtimeLogs],
                },
            };

            combinedLogs.requestPayload.dealTermsLogs.forEach((log) => {
                log.logDate = new Date(log.logDate).toISOString().split('T')[0];
            });

            await postCreateOrUpdateDealTermsLog(combinedLogs, {
                onSuccess: () => {
                    setHasChanges(false);
                    setExpandedLogStatus(null);
                    setRefreshDeals((prev) => !prev);
                    onClose();
                },
                onError: () => {
                    toast.error('Failed to save deal terms logs');
                },
            });
        } catch (error) {
            toast.error('Error saving deal terms logs');
        } finally {
            setIsLoading(false);
            setRuntimeLogs([]);
        }
    };

    const handleCancel = () => {
        setDealTermsLogs(originalLogsRef.current);
        setRuntimeLogs([]);
        setExpandedLogStatus(null);
        setIsFormValid(false);
        onClose();
    };

    const handleDeleteWithAnimation = (tempId: string) => {
        // First collapse the dropdown
        setExpandedLogStatus(null);

        // Wait for dropdown collapse animation to complete
        setTimeout(() => {
            // Then start the deletion animation
            setDeletingLogIds((prev) => new Set([...prev, tempId]));

            // Finally remove the item after the deletion animation
            setTimeout(() => {
                handleDeleteRuntimeLog(tempId);
                setDeletingLogIds((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(tempId);
                    return newSet;
                });
            }, 500); // Deletion animation duration
        }, 700); // Dropdown collapse duration
    };

    useEffect(() => {
        if (originalLogsRef.current && dealTermsLogs) {
            const stringifyValue = (obj: any): any => {
                if (obj === null || obj === undefined) return String(obj);
                if (typeof obj !== 'object') return String(obj);
                if (Array.isArray(obj)) {
                    return obj.map(stringifyValue);
                }
                const result: any = {};
                for (const key in obj) {
                    result[key] = stringifyValue(obj[key]);
                }
                return result;
            };

            const originalStringified = stringifyValue(originalLogsRef.current);
            const currentStringified = stringifyValue(dealTermsLogs);

            const hasLogChanges = JSON.stringify(originalStringified) !== JSON.stringify(currentStringified);
            const hasRuntimeLogChanges = runtimeLogs.length > 0;
            setHasChanges(hasLogChanges || hasRuntimeLogChanges);
        }
    }, [dealTermsLogs, runtimeLogs]);

    useEffect(() => {
        setIsInitialLoading(true);
        Promise.all([fetchConfig(), isOpen && investorDealId ? fetchDealTermsLogsData() : Promise.resolve()]).finally(() => {
            setIsInitialLoading(false);
        });
    }, [isOpen, investorDealId]);

    return {
        // States
        dealTermsLoggingConfig,
        isFormValid,
        dealTermsLogs,
        runtimeLogs,
        selectedStatus,
        hasChanges,
        expandedLogStatus,
        isLoading,
        isInitialLoading,
        latestLogRef,
        deletingLogIds,

        // Constants
        primaryCtaText,
        secondaryCtaText,
        headerCtsText,

        // Methods
        setIsFormValid,
        handleStatusChange,
        handleLogUpdate,
        handleDeleteRuntimeLog,
        handleDropdownToggle,
        handleClose,
        handleSave,
        sourceBasedActions,
        handleCancel,
        setExpandedLogStatus,
        handleDeleteWithAnimation,
    };
};
