import React, { useState, useEffect } from 'react';
import styles from '../GstFetch/GstFetch.module.scss';
import FetchGstModal from '../FetchGstModal/FetchGstModal';

import step_done from '../../../../../assets/step-done.svg';
import step_not_done from '../../../../../assets/step-not-done.svg';
import fetch_purple from '../../../../../assets/fetch-purple.svg';
import { fetchOrangeIcon, GREEN_BG_WHITE_TICK_CIRCLE, gstFetchPurple } from '../../../../../assets/hostedassets';
import clsx from 'clsx';
import GSTModal from './GSTModal/Components/GSTModal';
import { GstContext } from './GSTModal/Context/GstContext';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_GST_FETCH_GST } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import FetchIcon from '../../../../../assets/Svg/FetchIcon';
import { useSearchParams } from 'react-router-dom';
import GSTUpload from '../GSTUpload/GSTUpload';

export default function GstFetch({
    rawGstData,
    fetchData,
    isInvestor,
    uploadJourneyData,
    gstReportsData,
    updateFunction,
    categoryId,
    flowId,
    assesseeOrgId,
}: any) {
    const [gstInfo, setGstInfo] = useState({
        state: '',
        gstin_number: '',
    });
    const [showModal, setShowModal] = useState(false);
    const [searchParams] = useSearchParams();

    const fetchGst = (gstData: any) => {
        Mixpanel.track(COMPANY_GST_FETCH_GST);
        setGstInfo({
            state: gstData.state ?? '',
            gstin_number: gstData.gstin,
        });
        setShowModal(true);
    };
    const gstData = Array.isArray(rawGstData)
        ? [...rawGstData].sort((a, b) => {
              return a.status === 'INACTIVE' ? 1 : -1;
          })
        : [];

    const hasInactiveGst = gstData.some((gst) => gst.status === 'INACTIVE');

    const renderCheck = (check: any) => {
        if (check) return <img src={step_done} alt="" style={{ width: '20px' }} />;
        return <img src={step_not_done} alt="" style={{ width: '20px' }} />;
    };

    return (
        <>
            <GstContext gstValues={{ showModal, setShowModal, gstData: { ...gstInfo, fetchData: fetchData } }}>
                <div style={isInvestor ? { width: '80%' } : {}} className={styles.GstFetch}>
                    {gstData.map((gst, index) => (
                        <div className={styles.Card} style={isInvestor ? { width: '80%' } : {}} key={gst.gstin}>
                            {index === 0 && (
                                <>
                                    <div className={styles.CardHeader}>GST Report</div>
                                    <div className={styles.CardSubHeader}>Fetch reports directly from GST portal (view only access).</div>
                                </>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className={styles.Place}>
                                    {gst.state && <span style={{ marginRight: '0.5ch' }}>{gst.state}</span>}
                                    {gst.gstin && <span>({gst.gstin})</span>}
                                    {gst.status === 'INACTIVE' && <span className={styles.InactivePill}>Inactive</span>}
                                </div>

                                {gst.status === 'INACTIVE' ? (
                                    <div className={styles.DisabledBtn}>
                                        <span>Upload below</span>
                                    </div>
                                ) : (
                                    <div
                                        className={clsx(styles.FetchBtn, {
                                            [styles.FetchSuccessful]: gst.connected,
                                        })}
                                        onClick={() => {
                                            if (gst.connected) return;
                                            fetchGst(gst);
                                        }}
                                    >
                                        <img
                                            src={gst.connected ? GREEN_BG_WHITE_TICK_CIRCLE : gstFetchPurple}
                                            alt=""
                                            height={14}
                                            width={14}
                                        />
                                        {gst.connected ? 'Fetched' : 'Fetch'}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}

                    {/*{searchParams.get('configName') !== 'MAGIC_LINK' && (*/}
                    {/*    <div className={styles.Separator}>*/}
                    {/*        <div className={styles.Line}></div>*/}
                    {/*        <div>Or</div>*/}
                    {/*        <div className={styles.Line}></div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <GSTModal />
                </div>
            </GstContext>

            {searchParams.get('configName') !== 'MAGIC_LINK' && (
                <GSTUpload
                    isInvestor={isInvestor}
                    gstReportsData={gstReportsData}
                    updateFunction={updateFunction}
                    categoryId={categoryId}
                    flowId={flowId}
                    assesseeOrgId={assesseeOrgId}
                    hasInactiveGst={hasInactiveGst}
                />
            )}
        </>
    );
}
