import styles from '../../RiskIndicators.module.scss';
import { downloadIconDataRoom, downloadIconDataRoomHovered } from '../../../../assets/hostedassets';
import { useRiskIndicatorStates } from '../../useRiskIndicatorStates';
import { toast } from 'react-toastify';
import { DOWNLOAD_ERROR_MESSAGE } from '../../RiskIndicatorConstants';
import { Row } from '../../RiskIndicators';
import { Header } from './RiskIndicatorHeaders';

interface RiskIndicatorRowsProps {
    rows: Row[];
    setRows: React.Dispatch<React.SetStateAction<Row[]>>;
    headers: Header[];
    isPDFReport?: boolean;
}

const RiskIndicatorRows = ({
    rows,
    setRows,
    headers,
    isPDFReport = false,
}: RiskIndicatorRowsProps) => {
    const { downloadReport, hoveredIndex, setHoveredIndex, getRowImage, getRiskDot } =
        useRiskIndicatorStates({
            rows,
            setRows,
            autoFetch: false,
        });

    return (
        <>
            {rows?.map((row, index) => (
                <div className={styles.TableRow} key={index}>
                    <div className={styles.StickyRows}>
                        <div className={styles.Name}>
                            <span>{getRowImage(row?.type)}</span>
                            <span title={row?.name} className={styles.NameSpan}>
                                {row?.name}
                            </span>
                        </div>
                        {!isPDFReport && (
                            <span
                                className={styles.DownloadReport}
                                onClick={async () => {
                                    if (!row?.docId) return;
                                    try {
                                        await downloadReport(row?.docId, row?.name);
                                    } catch (error) {
                                        toast.error(DOWNLOAD_ERROR_MESSAGE);
                                    }
                                }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                style={{
                                    cursor: row?.docId ? 'pointer' : 'not-allowed',
                                    opacity: row?.docId ? 1 : 0.6,
                                }}
                            >
                                <img
                                    src={
                                        hoveredIndex === index
                                            ? downloadIconDataRoomHovered
                                            : downloadIconDataRoom
                                    }
                                    alt={'icon'}
                                />
                            </span>
                        )}
                    </div>
                    {headers.map((header, headerIndex) => (
                        <div className={styles.TableRowItem} key={headerIndex}>
                            {getRiskDot(row?.riskProfile?.[header.key])}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default RiskIndicatorRows;
