import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import { exportRawFromUnderwritingAndPolus } from '../../../../../../../../store/company/apiHelpers';
import { handleDealApplicationDrawer, setDealDrawer, updateDeal, updateInvestorDeal } from '../../../../../../../../store/investor/action';
import { DealStatus, FetchDealsData, InvestorDealData } from '../../../../../../../../store/investor/reducer';
import { GREEN_DROPDOWN_CARET, GREY_DROPDOWN_CARET, RED_DROPDOWN_CARET } from '../../../../../../../../assets/hostedassets';
import { USER_TYPE } from '../../../../../../../../utils/constants/user';
import ActionMenu from '../../../../../../../../common/ActionMenu/ActionMenu';
import StatusDropdown, {
    StatusDropdownOption,
} from '../../../../../../Portfolio/components/CompaniesList/components/Table/components/StatusDropdown/StatusDropdown';
import styles from './DealsRow.module.scss';
import { downloadFile } from '../../../../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { UPLOAD_FILES_PREFIXES } from '../../../../../../../../common/ManageDealDrawer/DealDrawerConstants';
import CommentsBox from '../../../../../../Portfolio/components/CompaniesList/components/Table/components/CommentsBox/CommentsBox';
import CaretIcon from '../../../../../../../../assets/Svg/CaretIcon';
import { HeaderConfig } from '../../DealsTable';
import { COLORS } from '../../../../../../../../utils/constants/colors';
import { Mixpanel } from '../../../../../../../../utils/mixpanel';
import TopRightArrowIcon from '../../../../../../../../assets/Svg/TopRightArrowIcon';
import { getPlatformUserId } from '../../../../../../../../utils/utils';
import { COMMENTS_THREAD_SOURCES } from '../../../../../../../../store/commentsThread/commentsThread_reducer';

export type ActionMenuConst = {
    [x: string]: { action: Function };
};

type Props = {
    headers: HeaderConfig[] | null;
    collapsibleHeaders: HeaderConfig[] | null;
    rows: FetchDealsData[];
};

export const STATUS_DROPDOWN_OPTIONS = {
    [DealStatus.SHARED]: {
        label: 'Shared',
        value: DealStatus.SHARED,
        backgroundColor: 'var(--secondary-bg-colour)',
        labelColor: 'var(--primary-text-colour)',
        customCaret: <CaretIcon colour={'var(--primary-text-colour)'} height="14" width="14" />,
    },
    [DealStatus.NEED_INFO_PRE_IPA]: {
        label: 'Need Info (Pre IPA)',
        value: DealStatus.NEED_INFO_PRE_IPA,
        backgroundColor: COLORS.LIGHT_RED_BG,
        labelColor: COLORS.RED,
        customCaret: RED_DROPDOWN_CARET,
    },
    [DealStatus.IPA_AWAITED]: {
        label: 'IPA Awaited',
        value: DealStatus.IPA_AWAITED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.IPA_RECEIVED]: {
        label: 'IPA Received',
        value: DealStatus.IPA_RECEIVED,
        backgroundColor: COLORS.SUCCESS_LIGHT_BG,
        labelColor: COLORS.SUCCESS,
        customCaret: GREEN_DROPDOWN_CARET,
    },
    [DealStatus.IPA_ACCEPTED]: {
        label: 'IPA Accepted',
        value: DealStatus.IPA_ACCEPTED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.NEED_INFO_POST_IPA]: {
        label: 'Need Info (Post IPA)',
        value: DealStatus.NEED_INFO_POST_IPA,
        backgroundColor: COLORS.LIGHT_RED_BG,
        labelColor: COLORS.RED,
        customCaret: RED_DROPDOWN_CARET,
    },
    [DealStatus.SL_AWAITED]: {
        label: 'SL Awaited',
        value: DealStatus.SL_AWAITED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.SL_RECEIVED]: {
        label: 'SL Received',
        value: DealStatus.SL_RECEIVED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.SL_ACCEPTED]: {
        label: 'SL Accepted',
        value: DealStatus.SL_ACCEPTED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.NEED_INFO_POST_SL]: {
        label: 'Need Info (Post SL)',
        value: DealStatus.NEED_INFO_POST_SL,
        backgroundColor: COLORS.LIGHT_RED_BG,
        labelColor: COLORS.RED,
        customCaret: RED_DROPDOWN_CARET,
    },
    [DealStatus.DISBURSAL_PENDING]: {
        label: 'Disbursal Pending',
        value: DealStatus.DISBURSAL_PENDING,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.DISBURSED]: {
        label: 'Disbursed',
        value: DealStatus.DISBURSED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.FEE_RECEIVED]: {
        label: 'Fee Received',
        value: DealStatus.FEE_RECEIVED,
        backgroundColor: COLORS.DARK_GREY_BG,
        labelColor: COLORS.DARK_GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.UNRESPONSIVE_PRE_IPA]: {
        label: 'Unresponsive (Pre IPA)',
        value: DealStatus.UNRESPONSIVE_PRE_IPA,
        backgroundColor: COLORS.LIGHT_BG,
        labelColor: COLORS.GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.UNRESPONSIVE_POST_IPA]: {
        label: 'Unresponsive (Post IPA)',
        value: DealStatus.UNRESPONSIVE_POST_IPA,
        backgroundColor: COLORS.LIGHT_BG,
        labelColor: COLORS.GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.PASSED_PRE_IPA]: {
        label: 'Passed (Pre IPA)',
        value: DealStatus.PASSED_PRE_IPA,
        backgroundColor: COLORS.LIGHT_BG,
        labelColor: COLORS.GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
    [DealStatus.PASSED_POST_IPA]: {
        label: 'Passed (Post IPA)',
        value: DealStatus.PASSED_POST_IPA,
        backgroundColor: COLORS.LIGHT_BG,
        labelColor: COLORS.GREY,
        customCaret: GREY_DROPDOWN_CARET,
    },
};

const DealsRow: React.FC<Props> = ({ headers, collapsibleHeaders, rows }) => {
    const commentThreadState = useAppSelector((state) => state.commentsThreadReducer);
    const ACTION_MENU_ITEMS: ActionMenuConst = {
        'Edit Deal': {
            action: (payload, source) => {
                editTerms(payload?.dealBeneficiaryId, payload?.borrowerName, payload?.dealId);
            },
        },
        'Deal Terms': {
            action: (payload) => {
                setDealTermsQueryParams({ dealId: payload?.dealId, borrowerName: payload?.borrowerName }, 'Deal Terms');
            },
        },
        'Borrower Profile': {
            action: (payload, source) => {
                if (userType === USER_TYPE.LENDER || source !== 'deal') {
                    viewLenderBorrowerProfile(payload?.dealBeneficiaryId, payload?.borrowerName, payload?.dealId);
                } else {
                    viewBorrowerProfile(payload?.dealBeneficiaryId, payload?.borrowerName, payload?.dealId);
                }
            },
        },
        'AICA Report': {
            action: (payload) => viewReport(payload?.dealBeneficiaryId),
        },
        'Download Data': {
            action: (payload) => exportData(payload?.dealBeneficiaryId, payload?.borrowerName),
        },
        'One Pager': {
            action: (payload) => {
                let onePagerDoc = payload?.documents?.find((doc) => doc.documentName?.includes(UPLOAD_FILES_PREFIXES.ONEPAGER_PREFIX));
                onePagerDoc?.docId &&
                    downloadFile(
                        Number(onePagerDoc.docId),
                        () => {},
                        onePagerDoc?.documentName?.replace?.(UPLOAD_FILES_PREFIXES.ONEPAGER_PREFIX, ''),
                    );
            },
        },
        'Investment Report': {
            action: (payload) => {
                let investmentReportDoc = payload?.documents?.find(
                    (doc) => doc.documentName?.includes(UPLOAD_FILES_PREFIXES.INVESTMENTREPORT__PREFIX),
                );
                investmentReportDoc?.docId &&
                    downloadFile(
                        Number(investmentReportDoc.docId),
                        () => {},
                        investmentReportDoc?.documentName?.replace?.(UPLOAD_FILES_PREFIXES.INVESTMENTREPORT__PREFIX, 'Investment_Report_'),
                    );
            },
        },
    };
    // Constants

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useSearchParams();

    const loggerInUser = useAppSelector((state) => state.user.userData);
    const user = useAppSelector((state) => state.user);
    const owners = useAppSelector((state) => state.investor.ownerDropdownOptions);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const [ownerDropdownData, setOwnerDropdownData] = useState({});
    const userType = user.userData.accountType;
    const [dropDownOpen, setDropDownOpen] = useState<number | null>(null);

    const [collapsedMap, setCollapsedMap] = useState<{ [key: string]: boolean }>({});
    const [showActionMenu, setShowActionMenu] = useState<string>();
    const platformUserId = getPlatformUserId();
    const prepareOwnerDropdownData = () => {
        const ownersData = {};
        owners.forEach((owner) => {
            if (!owner.name) return;
            ownersData[owner.userId] = {
                label: owner?.name,
                value: owner.userId,
                backgroundColor: COLORS.LIGHT_BG,
                labelColor: COLORS.BLACK,
                customCaret: GREY_DROPDOWN_CARET,
            };
        });
        return ownersData;
    };

    useEffect(() => {
        if (owners.length > 0) setOwnerDropdownData(prepareOwnerDropdownData());
    }, [owners]);

    const getStatusDropdownOption = (status: string): StatusDropdownOption => {
        const option = STATUS_DROPDOWN_OPTIONS?.[status];
        if (!option) return STATUS_DROPDOWN_OPTIONS[DealStatus.SHARED];
        return option;
    };

    const getOwnerDropdownOption = (name: string): any => {
        let option = Object.values(ownerDropdownData).find((owner: any) => owner.label === name);
        if (option) return option;
        else {
            if (isPartner)
                return {
                    label: '-',
                    value: '-',
                    backgroundColor: '#fff',
                    labelColor: COLORS.BLACK,
                    customCaret: GREY_DROPDOWN_CARET,
                };
            return {
                label: 'Select',
                value: '-',
                backgroundColor: COLORS.LIGHT_BG,
                labelColor: COLORS.GREY,
                customCaret: GREY_DROPDOWN_CARET,
            };
        }
    };

    const setDealTermsQueryParams = (payload: { dealId: string; borrowerName: string }, source: string) => {
        queryParams.set('showDealTermsLoggingDrawer', 'true');
        queryParams.set('investorDealId', payload.dealId);
        queryParams.set('borrowerName', payload.borrowerName);
        queryParams.set('source', source);
        setQueryParams(queryParams);
    };

    const handleDealTermsClick = (row: FetchDealsData | InvestorDealData) => {
        setDealTermsQueryParams({ dealId: row?.dealId, borrowerName: row?.borrowerName }, 'View Deal');
    };

    const updateDealStatus = (dealId: string, status: DealStatus) => {
        dispatch(
            updateDeal({
                dealId,
                dealStatus: status as string,
                lastUpdatedBy: loggerInUser?.userName,
                userId: user.userData.userId,
            }),
        );
    };

    const updateDealOwner = (dealInfo) => {
        dispatch(updateDeal(dealInfo));
    };

    const updateInvestorDealStatus = (dealId: string | undefined, investorDealId: string, status: DealStatus, source?: string) => {
        dispatch(
            updateInvestorDeal({
                dealId,
                investorDealId,
                dealStatus: status as string,
                lastUpdatedBy: loggerInUser?.userName,
                source,
                owner: {
                    name: user.userData.userName,
                    userId: user.userData.userId,
                    activityBy: user.userData.userName,
                    activityDate: new Date().toISOString(),
                    ownerType: user.userData.accountType,
                },
            }),
        );
    };

    const onRowClick = (
        event: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement, MouseEvent>,
        row: FetchDealsData | InvestorDealData,
    ) => {
        event.stopPropagation();
        if (userType === USER_TYPE.LENDER) viewReport(row.dealBeneficiaryId);
        setCollapsedMap({ ...collapsedMap, [row.dealId]: !(collapsedMap[row.dealId] ?? true) });
    };

    const getRowText = (payload: {
        row: FetchDealsData | InvestorDealData;
        headerId: string;
        source: 'deal' | 'investor-deal';
        deal?: FetchDealsData;
        rowIndex?: number;
        index: number;
        headerType?: string;
    }) => {
        const { row, headerId, source, deal, index, headerType, rowIndex } = payload;
        if (headerId === 'comment') {
            return (
                <CommentsBox
                    index={index}
                    source={source}
                    comments={
                        row.comment ?? {
                            text: '',
                            activityBy: '',
                            activityDate: '',
                            userId: '',
                        }
                    }
                    assesseeOrgId={row.dealBeneficiaryId}
                    fetchData={() => {}}
                    dealIdForInvestorDeal={deal?.dealId}
                    dealId={row.dealId}
                    borrowerName={row.borrowerName}
                    lenderName={row?.lenderName}
                />
            );
        }
        if (headerId === 'actions')
            return (
                <ActionMenu
                    onThreeDotsClick={() => setDropDownOpen(rowIndex ?? index)}
                    triggerPayload={row}
                    actionMenu={ACTION_MENU_ITEMS}
                    rowData={row ?? deal}
                    source={source}
                />
            );
        if (headerId === 'collapsible')
            return (
                <button
                    className={clsx(styles.DropdownButton, {
                        [styles.Open]: !(collapsedMap[row.dealId] ?? true),
                    })}
                    onClick={(e) => onRowClick(e, row)}
                >
                    <img src={GREY_DROPDOWN_CARET} alt="Dropdown" />
                </button>
            );
        if (headerId === 'dealStatus') {
            return (
                <StatusDropdown
                    dropdownOptions={Object.values(STATUS_DROPDOWN_OPTIONS)}
                    selectedOption={getStatusDropdownOption(row?.[headerId])}
                    selectOption={(option: StatusDropdownOption) => {
                        if (option.value === row?.dealStatus) return;

                        if (userType === USER_TYPE.LENDER)
                            updateInvestorDealStatus(undefined, row?.dealId, option.value as DealStatus, userType);
                        else if (source === 'deal') updateDealStatus(row?.dealId, option.value as DealStatus);
                        else if (deal) updateInvestorDealStatus(deal?.dealId, row?.dealId, option.value as DealStatus);
                    }}
                />
            );
        }
        if (headerId === 'blankCol') {
            return <div className={styles.BlankCol} />;
        }
        if (headerId === 'dealTerms') {
            return (
                <div
                    className={styles.DealTermsContainer}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDealTermsClick(row);
                    }}
                >
                    <div className={styles.DealTermsIcon}>
                        <TopRightArrowIcon width={14} height={14} style={{ color: '#000000' }} />
                    </div>
                    <div className={styles.DealTermsText}>View Deal</div>
                </div>
            );
        }
        if (headerType === 'owner') {
            return (
                <StatusDropdown
                    dropdownOptions={Object.values(
                        ownerDropdownData ?? {
                            label: 'Select',
                            value: '-',
                            backgroundColor: '#F5F5F5',
                            labelColor: '#B4B4B4',
                            customCaret: GREEN_DROPDOWN_CARET,
                        },
                    )}
                    selectedOption={getOwnerDropdownOption(row?.owners?.[headerId])}
                    selectOption={(option: StatusDropdownOption) => {
                        updateDealOwner({
                            dealId: row?.dealId,
                            owner: {
                                name: option.label,
                                userId: option.value,
                                activityBy: user.userData.userName,
                                activityDate: new Date().toISOString(),
                                ownerType: headerId,
                            },
                            lastUpdatedBy: loggerInUser?.userName,
                            userId: platformUserId,
                        });
                    }}
                    disabled={source === 'investor-deal' || isPartner}
                    source={source}
                />
            );
        }
        if (Array.isArray(row?.[headerId])) {
            if (row?.[headerId].length > 1)
                return (
                    <div>
                        {row?.[headerId][0]}, <span className={styles.More}>+{row?.[headerId].length - 1} more</span>
                    </div>
                );
            return row?.[headerId].join(', ');
        }
        if (!row?.[headerId]) return '-';
        return row?.[headerId] || '';
    };

    const getTitleText = (row: FetchDealsData | InvestorDealData, headerId: string) => {
        if (headerId === 'comment') return '';
        if (Array.isArray(row?.[headerId])) return row?.[headerId].join(', ');
        return row?.[headerId] || '';
    };

    const viewReport = (id: string) => {
        navigate(`/investor/app/portfolio/${id}?${queryParams.toString()}`, {
            state: {
                ...location.state,
                from: userType === USER_TYPE.LENDER ? 'DEALS' : 'TRACK',
            },
        });
    };

    const editTerms = (companyId, companyName, dealId) => {
        Mixpanel.track('Edit Deal', {
            userEmail: user.userData.primaryEmail,
            companyName: companyName,
            pnoId: user.userData.investorOrgId,
        });
        dispatch(
            handleDealApplicationDrawer({
                open: true,
                dealId: dealId,
                dealBeneficiaryId: companyId,
                heading: 'Edit Deal',
                subHeading: companyName,
                source: 'editDeal',
                width: '67%',
            }),
        );
        queryParams.set('dealCreated', 'true');
        setQueryParams(queryParams);
    };

    const viewDealTerms = (companyId: string, companyName: string, dealId: string) => {
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: 'Deal Terms',
                companyId,
                companyName,
                dealId,
                readonly: true,
                step: 'DEAL_TERMS',
            }),
        );
    };

    const viewLenderDealTerms = (companyId: string, companyName: string, dealId: string) => {
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: 'Deal Terms',
                companyId,
                companyName,
                lenderDealId: dealId,
                readonly: true,
                step: 'DEAL_TERMS',
            }),
        );
    };

    const viewBorrowerProfile = (companyId: string, companyName: string, dealId: string) => {
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: 'Borrower Profile',
                companyId,
                companyName,
                dealId,
                readonly: true,
                step: 'BORROWER_PROFILE',
            }),
        );
    };

    const viewLenderBorrowerProfile = (companyId: string, companyName: string, dealId: string) => {
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: 'Borrower Profile',
                companyId,
                companyName,
                lenderDealId: dealId,
                readonly: true,
                step: 'BORROWER_PROFILE',
            }),
        );
    };

    const exportData = (orgId: string, orgName: string) => {
        exportRawFromUnderwritingAndPolus(
            {
                assesseeOrgId: orgId,
                orgName,
            },
            {},
        );
    };

    const toggleActionMenu = (dealId: string) => {
        setShowActionMenu(showActionMenu === dealId ? undefined : dealId);
    };

    return (
        <>
            {rows?.map((row, index) => (
                <>
                    <tr
                        className={clsx(styles.Row, {
                            [styles.RowHoveredCommentActive]:
                                commentThreadState.source === COMMENTS_THREAD_SOURCES.DEAL && row?.dealId === commentThreadState.dealId,
                        })}
                        style={dropDownOpen === index ? { zIndex: 10000 } : {}}
                        key={`row-${index}`}
                        onClick={(e) => onRowClick(e, row)}
                    >
                        {headers?.map((header, i) => (
                            <td
                                className={clsx(
                                    {
                                        [styles.HeadersHeadRowItem]: header.id === 'borrowerName',
                                        [styles.LenderHeadersHeadRowItem]: header.id === 'borrowerName' && userType === USER_TYPE.LENDER,
                                        [styles.HeadersDrawdownRowItem]: header.id === 'collapsible',
                                        [styles.lenderRow]: userType === USER_TYPE.LENDER,
                                        [styles.StickyActions]: header.id === 'actions',
                                    },
                                    {
                                        [styles.RowItem]: header.id != 'comment',
                                        [styles.CollapsibleRowItem]: header.id === 'collapsible',
                                        [styles.CommentCell]: header.id === 'comment',
                                    },
                                )}
                                key={`column-${i}`}
                            >
                                <div
                                    title={getTitleText(row, header.id)}
                                    className={clsx({
                                        [styles.WrapText]: header.wrapText ?? false,
                                        [styles.GreyText]: header.greyText,
                                        [styles.HideRow]: header.hideRow,
                                        [styles.CommentDiv]: header.id === 'comment',
                                    })}
                                >
                                    {getRowText({
                                        row,
                                        headerId: header.id,
                                        source: 'deal',
                                        index: index,
                                        headerType: header.type,
                                    })}
                                </div>
                            </td>
                        ))}
                    </tr>
                    {!(collapsedMap[row.dealId] ?? true) &&
                        (row.investorDeals?.length ?? 0) > 0 &&
                        row.investorDeals.map((investorDeal, rowIndex) => (
                            <tr
                                className={clsx(styles.Row, styles.CollapsibleRow)}
                                key={`collapsible-row-${rowIndex}`}
                                onClick={() => toggleActionMenu(investorDeal.dealId)}
                                style={dropDownOpen === index + 100 + rowIndex ? { zIndex: 1000 } : {}}
                            >
                                {collapsibleHeaders?.map((header, i) => (
                                    <td
                                        className={clsx({
                                            [styles.RowItem]: header.id !== 'comment',
                                            [styles.Indent]: header.id === 'lenderName',
                                            [styles.CommentCell]: header.id === 'comment',
                                            [styles.StickyActions]: header.id === 'actions',
                                            [styles.CollapsibleHeaderHeadRowItem]: header.id === 'lenderName',
                                            [styles.CollapsibleHeaderDrawdownRowItem]: header.id === 'dummy',
                                        })}
                                        key={`collapsible-column-${i}`}
                                    >
                                        <div
                                            title={getTitleText(row, header.id)}
                                            className={clsx({
                                                [styles.WrapText]: header.wrapText ?? false,
                                                [styles.GreyText]: header.greyText,
                                                [styles.HideRow]: header.hideRow,
                                                [styles.CommentDiv]: header.id === 'comment',
                                            })}
                                        >
                                            {getRowText({
                                                row: investorDeal,
                                                headerId: header.id,
                                                source: 'investor-deal',
                                                deal: row,
                                                index: index + 1,
                                                rowIndex: index + 100 + rowIndex,
                                                headerType: header.type,
                                            })}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                </>
            ))}
        </>
    );
};

export default DealsRow;
