import { TooltipProps } from 'recharts';
// import { GRAPH_NAMES } from '../../../../../../store/Dashboard/constants';
// import { formatPeriod } from '../../../../../../utils/utils';
// import { graphDataType } from '../../../../../../store/Dashboard/types';
import styles from './CustomTooltip.module.scss';
// import { getColorKeyFromDataKey } from '../../../../../../store/DashboardNew/transformer';
import { getColorKeyFromDataKey } from '../../../../../../store/dashboard/transformer';
import { CHART_NAMES } from '../../../../../../store/dashboard/creditBureau/constants';

interface CustomTooltipProps extends TooltipProps<number, string> {
    graphName: string;
    graphData?: any;
    singleDataPoint?: boolean;
    headingFormatter?: Function;
    valueFormatter?: Function;
}

function CustomTooltip(props: CustomTooltipProps) {
    const {
        payload,
        label,
        graphData,
        singleDataPoint = false,
        headingFormatter,
        graphName,
        valueFormatter,
    } = props;
    const showTotalRepayments = graphName === CHART_NAMES.REPAYMENTS_OVERVIEW;

    const getColor = (data) => {
        const colorKeyName = getColorKeyFromDataKey(data?.dataKey);
        if (singleDataPoint) {
            if (data?.value > 0) return graphData?.colors?.primary;
            else return graphData?.colors?.secondary;
        }
        return graphData?.colors?.[colorKeyName];
    };

    const getKeyLabel = (data) => {
        if (singleDataPoint) {
            if (data?.value > 0) return graphData?.metadata?.legend?.['yValue'];
            else return graphData?.metadata?.legend?.['yValue2'];
        }
        return graphData?.metadata?.legend?.[data?.dataKey];
    };

    const getDataUnit = (data) => {
        switch (graphData?.metadata?.yType) {
            case 'Percent':
                return '%';
            case 'Lacs':
                return ' Lacs';
            case 'Cr':
                return ' Cr';
            default:
                return '';
        }
    };

    const getDataValue = (data) => {
        let prependText = '';
        let value = data?.payload?.[data.dataKey];
        switch (graphData?.metadata?.yType) {
            case 'Percent':
                value = isNaN(data?.payload?.[data.dataKey])
                    ? data?.payload?.[data.dataKey] || '-'
                    : Math.round(data?.payload?.[data.dataKey]);
                break;
            case 'Lacs':
            case 'Cr':
                prependText = '₹';
                break;
        }
        if (valueFormatter) value = valueFormatter?.(value) ?? value;
        return `${prependText}${value}`;
    };

    const getHeader = () => {
        let val = props.graphData?.metadata?.cardType !== 'piechart' ? label : payload?.[0]?.name;
        if (headingFormatter) val = headingFormatter?.(val) || '';
        return val;
    };

    const getTotalVal = () => {
        const sum = payload
            ? payload.reduce((accumulator, data: any) => accumulator + data?.value || 0, 0)
            : 0;
        return sum?.toFixed(2);
    };

    return (
        <div className={styles.CustomTooltip}>
            <div className={styles.TooltipHeader}>{getHeader()}</div>

            <div className={styles.TooltipBody}>
                {showTotalRepayments && (
                    <div className={styles.DataRow}>
                        <div>
                            <div className={styles.TotalLabel}>Total Repayments</div>
                        </div>

                        <div className={styles.Value}>{getTotalVal()}</div>
                    </div>
                )}
                {payload?.length &&
                    payload?.reverse()?.map((data, index) => {
                        if (data.color !== 'transparent') {
                            return (
                                <div className={styles.DataRow} key={index}>
                                    {!!getKeyLabel(data) && (
                                        <div>
                                            <div
                                                className={styles.Color}
                                                style={{ background: getColor(data) }}
                                            ></div>
                                            <div className={styles.Label}>{getKeyLabel(data)}</div>
                                        </div>
                                    )}

                                    <div className={styles.Value}>{`${getDataValue(
                                        data,
                                    )}${getDataUnit(data)}`}</div>
                                </div>
                            );
                        } else return null;
                    })}
            </div>
        </div>
    );
}

export default CustomTooltip;
