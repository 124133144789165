// plugins/withExcelPaste.ts
import { Editor, Transforms, Descendant } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';

interface TableCellNode {
    type: 'table-cell';
    children: Descendant[];
}

interface TableRowNode {
    type: 'table-row';
    children: TableCellNode[];
}

interface TableNode {
    type: 'table';
    children: TableRowNode[];
}

/**
 * A helper to transform the DOM <table> into Slate node structure.
 */
function createTableNodeFromDom(tableElement: HTMLTableElement): TableNode {
    // Helper function to sanitize HTML content
    const sanitizeContent = (html: string): string => {
        const div = document.createElement('div');
        div.textContent = html;
        return div.textContent || '';
    };
    const rowNodes: TableRowNode[] = Array.from(tableElement.querySelectorAll('tr')).map((row) => {
        const cellNodes: TableCellNode[] = Array.from(row.querySelectorAll('td, th')).map(
            (cell) => {
                // Handle nested tables
                if (cell.querySelector('table')) {
                    console.warn('Nested tables are not supported and will be flattened');
                }

                return {
                    type: 'table-cell',
                    children: [
                        {
                            type: 'paragraph',
                            children: [{ text: sanitizeContent(cell.innerHTML) }],
                        },
                    ],
                };
            },
        );
        // Validate row structure
        if (cellNodes.length === 0) {
            console.warn('Empty table row detected');
        }

        return {
            type: 'table-row',
            children: cellNodes,
        };
    });

    return {
        type: 'table',
        children: rowNodes,
    };
}

/**
 * A plugin that intercepts the `insertData` method to detect if the clipboard
 * contains a table (e.g., from Excel) and, if so, inserts a Slate-compatible table node.
 */
function withExcelPaste<T extends Editor>(editor: T): T & ReactEditor & HistoryEditor {
    const e = editor as T & ReactEditor & HistoryEditor;
    const { insertData } = e;

    e.insertData = (data: DataTransfer) => {
        const html = data.getData('text/html');
        if (html?.includes('<table')) {
            const parsed = new DOMParser().parseFromString(html, 'text/html');
            const tableElements = parsed.querySelectorAll('table');

            if (tableElements.length > 0) {
                try {
                    // Support multiple tables
                    const tableNodes = Array.from(tableElements).map((tableElement) =>
                        createTableNodeFromDom(tableElement as HTMLTableElement),
                    );

                    // Batch insert for better performance
                    Transforms.insertNodes(e, tableNodes);
                    return;
                } catch (error) {
                    console.error('Failed to process pasted table:', error);
                    // Fallback to default paste behavior
                    insertData(data);
                    return;
                }
            }
        }
        // Fallback if no table is found
        insertData(data);
    };

    return e;
}

export default withExcelPaste;
