import axios from 'axios';
import { polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function fetchOperatingInflowTableDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_OPERATING_INFLOW_DATA,
        method: POST,
        data: {
            ...payload,
            requestId: getTimeStamp(),
        },
    });
}

const API_ENDPOINTS = {
    FETCH_OPERATING_INFLOW_DATA: '/data/recon/gstBankStatments',
};
