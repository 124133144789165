import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { getSearchResults } from './ApiContainers/GlobalSearchHelpers';
import { useAppSelector } from '../../app/hooks';
import axios, { CancelTokenSource } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlatformUserId } from '../../utils/utils';
import { USER_TYPE } from '../../utils/constants/user';

const useSearchResultFetcher = () => {
    const [searchText, setSearchText] = useState('');
    const platformUserId = getPlatformUserId();
    const [searchResults, setSearchResults] = useState<{
        [key: string]: {
            orgId: string;
            orgName: string;
            websiteUrl: string;
        }[];
    } | null>(null);
    const userData = useAppSelector((state) => state.user.userData);
    const navigate = useNavigate();

    // A ref to keep track of the current cancel token source
    const cancelTokenRef: MutableRefObject<CancelTokenSource | null> = useRef(null);

    useEffect(() => {
        if (searchText.length === 0) {
            setSearchResults(null);
        }
        // We only start searching if text length > 3
        if (searchText.length <= 2) {
            return;
        }

        // Create a debouncer (1 second = 1000 ms)
        const debounceTimer = setTimeout(() => {
            // If a previous request is in-flight, cancel it
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to new search query');
            }

            // Create a fresh CancelToken source
            const source = axios.CancelToken.source();
            cancelTokenRef.current = source;
            let payload: any = {
                parentOrgId: userData.investorOrgId,
                globalSearchText: searchText,
                cancelToken: source.token,
            };
            if (userData.accountType !== USER_TYPE.INVESTOR) payload.userId = platformUserId;

            // Fire the API call with the new token
            getSearchResults(payload)
                .then((res) => {
                    if (res) {
                        const groupedData = mapAccessType(res);
                        setSearchResults(groupedData);
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        // Handle error from server or network
                        console.error('Error in search request:', error);
                    }
                });
        }, 300);

        // Cleanup function: clear the debounce timer and cancel any in-flight request
        return () => {
            clearTimeout(debounceTimer);
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Cleanup: component unmount or effect re-run');
            }
        };
    }, [searchText]);

    function mapAccessType(res) {
        if (!res) {
            return {};
        }

        // Use Array.reduce to accumulate objects by their "companyAccessType"
        return res.reduce((acc, org) => {
            const { companyAccessType, orgId, orgName, websiteUrl } = org;

            // Make sure the key exists in our accumulator
            if (!acc[companyAccessType]) {
                acc[companyAccessType] = [];
            }

            // Push the relevant info into the array
            acc[companyAccessType].push({
                orgId,
                orgName,
                websiteUrl,
            });

            return acc;
        }, {});
    }

    // Handling User Navigation
    const navigateUser = (orgId: string, permissionType: string) => {
        if (!orgId) {
            toast.error('No organization found');
            return;
        }
        navigate(
            `/investor/app/portfolio/${orgId}?fetchType=all&page=1&type=${permissionType?.toLowerCase()}&tab=aicaReport&selectedTab=overview`,
            { replace: true },
        );
    };

    const getTextForKey = (key: string) => {
        switch (key) {
            case 'SCAN':
                return 'Leads';
            case 'TRACK':
                return 'Portfolio';
            case 'WATCH':
                return 'Prospects';
            default:
                return key;
        }
    };

    return {
        searchText,
        setSearchText,
        searchResults,
        navigateUser,
        setSearchResults,
        getTextForKey,
    };
};

export default useSearchResultFetcher;
