import React from 'react';

const CaretIcon = ({ colour = '#2F8FFF', width = '20', height = '20' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.375 1.25L6 5.625L1.625 1.25"
                stroke={colour}
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CaretIcon;
