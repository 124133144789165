export const ROW_COLORS = {
    TEXT: {
        WHITE: '#FFFFFF',
        ORANGE: '#FF6231',
        GREEN: '#00AA00',
        RED: '#D95656',
        YELLOW: '#E3752B',
        BLUE: '#4C8DFF',
        PURPLE: '#6021B3',
        BLACK: '#000000',
        BROWN: '#A72800',
        DARK_GREEN: '#1C8A0A',
    },
    BG: {
        RED: '#FFEDED',
        WHITE: '#FFFFFF',
        GREY: '#e3e3e3',
        GREEN: '#ECFCD8',
    },
};
