import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import {
    fetchBankStatementFromCashflowService,
    fetchFinancialMetricsServicePolus,
} from './financialsServices';

export async function fetchBankStatementFromCashflow(payload, handlers) {
    try {
        const response = await fetchBankStatementFromCashflowService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess(response.data?.data || {});
                return;
            case JAVA_API_STATUS.ERROR:
                // handlers?.onError();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        // handlers?.onError();
        console.error('Error in fetching bank statements from cashflow', err);
    }
}

export async function fetchFinancialMetrics(payload: FinancialMetricsPayload, handlers) {
    try {
        const response = await fetchFinancialMetricsServicePolus(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess(response.data?.responseBody || {});
                return;
            // case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError();
        console.error('Error in fetching bank statements from cashflow', err);
    }
}

type FinancialMetricsPayload = {
    orgId: string;
    startDate?: string | null;
    endDate?: string | null;
    frequency: 'Yearly' | 'Monthly';
    metricsList: string[];
};
