import clsx from 'clsx';
import styles from './CategorySelector.module.scss';

type Props = {
    readonly categoryOptions: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly count?: number;
    }>;
    readonly selectedCategory: string | null;
    readonly changeSelectedTab: (category: string) => void;
};

function CategorySelector({ categoryOptions, selectedCategory, changeSelectedTab }: Props) {
    return (
        <div className={styles.CategorySelector}>
            {categoryOptions.map((category) => (
                <div
                    className={clsx(styles.Category, {
                        [styles.SelectedCategory]: selectedCategory === category.id,
                    })}
                    key={category.id}
                    onClick={() => changeSelectedTab(category.id)}
                >
                    {category.label}
                    <span className={styles.grey}>&nbsp;{category.count}</span>
                </div>
            ))}
        </div>
    );
}

export default CategorySelector;
