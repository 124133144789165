import React from 'react';
import CompanyOffersTable from './components/CompanyOffersTable/CompanyOffersTable';
import styles from './CompanyOffers.module.scss';
import { Button } from '../../../common/_custom';
import WhiteBackGroundPencilIcon from '../../../assets/Svg/WhiteBackGroundPencilIcon';
import { OffersReturn, useUploadData } from '../UploadData/useUploadData';
import { USER_TYPE } from '../../../common/ManageDealDrawer/DealDrawerConstants';
import { useCompanyOffers } from './components/useCompanyOffers';
import {
    OFFERS_TEXT,
    OFFERS_CONFIG,
    CAPITAL_REQUIREMENT_BUTTON,
} from './components/OffersConstants';

interface Offer {
    name: string;
    product: string;
    createDate: string;
    amount: number;
    status: string;
}

const CompanyOffers: React.FC = () => {
    const { openCapitalRequirementDrawer } = useUploadData({
        userType: USER_TYPE.COMPANY,
        mode: 'offers',
    }) as OffersReturn;
    const { dealsData, fetchingDeals, companyMetrics } = useCompanyOffers();

    return (
        <div className={styles.offersPage}>
            <div className={styles.offersPageContainer}>
                <div className={styles.pageHeader}>
                    <span className={styles.heading}>{OFFERS_TEXT.PAGE_TITLE}</span>
                    {companyMetrics && Object.keys(companyMetrics).length > 0 && (
                        <Button
                            variant="purple"
                            text={CAPITAL_REQUIREMENT_BUTTON}
                            onClick={() => openCapitalRequirementDrawer('empty-data-centre')}
                            size="small"
                            style={{ height: OFFERS_CONFIG.BUTTON_HEIGHT, margin: '0px' }}
                            iconStyles={{ marginRight: 8, marginBottom: 5 }}
                            icon={<WhiteBackGroundPencilIcon width={20} height={20} fill="black" />}
                        />
                    )}
                </div>
                <div className={styles.offersTableContainer}>
                    <CompanyOffersTable
                        rows={dealsData?.data || []}
                        loading={fetchingDeals}
                        openCapitalRequirementDrawer={openCapitalRequirementDrawer}
                        showEmptyStateButton={
                            !companyMetrics || Object.keys(companyMetrics).length === 0
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyOffers;
