import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { ctaPurpleRight } from '../../../../../../assets/hostedassets';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import { setShowAicaGradeDrawer } from '../../../../../../store/dashboard/actions';
import AicaGradeBar from './AicaGradeBar/AicaGradeBar';
import AicaGradeSideDrawer from './AicaGradeSideDrawer/AicaGradeSideDrawer';
import styles from './AICAGradev2.module.scss';
import MetricRiskBadge from './MetricRiskBadge/MetricRiskBadge';
import infoIcon from '../../../../../../assets/info.svg';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import clsx from 'clsx';

function AICAGradeV2({ isPDFReport = false }) {
    const dispatch = useAppDispatch();
    const aicaGradeData = useAppSelector((state) => state.dashboard.dashboardReducer.aicaGradeData);
    const metricScoreGenerated = aicaGradeData.metricScoreGenerated;
    const companyGrade = aicaGradeData.companyGrade;
    const gradeUnavailable = companyGrade === null;
    const isLoading = aicaGradeData.isLoading;

    function getGradeDisplayValue() {
        if (!aicaGradeData.companyGrade || aicaGradeData.companyGrade === 'Unrated') return null;
        return aicaGradeData.companyGrade;
    }

    const showRiskParameters = () => {
        dispatch(setShowAicaGradeDrawer(true));
    };

    if (!metricScoreGenerated) return <></>;
    const render = (
        <div className={clsx(styles.AICAGrade, { [styles.PDFReport]: isPDFReport })}>
            <div className={styles.Top}>
                <div className={styles.Title}>Risk Grade</div>
                {!isPDFReport && (
                    <div className={styles.Right}>
                        <div className={styles.cta} onClick={showRiskParameters}>
                            <img src={ctaPurpleRight} alt="" height={'14px'} />
                            View Risk Parameters
                        </div>
                    </div>
                )}
            </div>

            <div className={styles.Body}>
                <div className={styles.Container}>
                    <div className={styles.Left}>
                        <div>
                            <AicaGradeBar
                                grade={aicaGradeData.companyScore}
                                isPDFReport={isPDFReport}
                            />
                            <div className={styles.Subtext}>
                                Indicates creditworthiness, calculated by aggregating scores across
                                Business Operations, Financial, Management, Governance, and Legal
                                and Regulatory checks.
                            </div>
                        </div>
                        <div className={styles.cta} onClick={showRiskParameters}>
                            <img src={ctaPurpleRight} alt="" height={'14px'} />
                            View Risk Parameters
                        </div>
                    </div>
                    <div className={styles.Right}>
                        {aicaGradeData?.headers?.map((data, i) => (
                            <div className={styles.Metric} key={`${data?.displayName}-${i}`}>
                                <div className={styles.MetricLeft}>
                                    {data?.displayName}

                                    {!!data?.tooltipDescription && !isPDFReport && (
                                        <Tooltip
                                            content={data.tooltipDescription}
                                            direction="right"
                                            style={{ width: '250px' }}
                                        >
                                            <img src={infoIcon} alt="score" height="14px" />
                                        </Tooltip>
                                    )}
                                </div>
                                <div className={styles.MetricRight}>
                                    <MetricRiskBadge
                                        score={data?.headerScore}
                                        isPDFReport={isPDFReport}
                                    />
                                </div>
                            </div>
                        ))}
                        {/* <div className={styles.cta} onClick={showRiskParameters}>
                            <img src={ctaPurpleRight} alt="" height={'14px'} />
                            View Risk Parameters
                        </div> */}
                    </div>
                </div>
            </div>

            <AicaGradeSideDrawer
                showDrawer={aicaGradeData.showAicaGradeDrawer}
                closeDrawer={() => {
                    dispatch(setShowAicaGradeDrawer(false));
                }}
            />
        </div>
    );

    if (isPDFReport)
        return metricScoreGenerated &&
            reportSectionsToShow.includes(REPORT_SECTION_NAMES.AICA_GRADE) ? (
            <A4Page sectionName={REPORT_SECTION_NAMES.AICA_GRADE}>
                {render}
                <AicaGradeSideDrawer isPDFReport />
            </A4Page>
        ) : (
            <></>
        );
    else return render;
}

export default AICAGradeV2;
