import React, { useEffect, useState } from 'react';
import styles from './ShareHoldingPattern.module.scss';
import { fetchShareholdingPatternData } from '../../../../../../store/dashboard/overview/actions';
import { useAppDispatch } from '../../../../../../app/hooks';
import { isEmpty } from 'lodash';

import {
    downloadIconDataRoom,
    downloadIconDataRoomHovered,
} from '../../../../../../assets/hostedassets';
import { downloadFile } from '../../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';

interface ShareHoldingPatternProps {
    isPDFReport?: boolean;
}
interface ShareholdingMetadata {
    financialEndDate?: string;
    mnemosyneDocId?: string;
}

const ShareHoldingPattern: React.FC<ShareHoldingPatternProps> = ({ isPDFReport = false }) => {
    const dispatch = useAppDispatch();
    const [rowData, setRowData] = useState([]);
    const [metaData, setMetaData] = useState<ShareholdingMetadata>({});
    const [downloadShareHoldingData, setDownloadShareHoldingData] = useState(false);

    useEffect(() => {
        fetchShareholdingData();
    }, []);

    const fetchShareholdingData = () => {
        dispatch(
            fetchShareholdingPatternData({
                graphNames: ['shareholdingPattern'], // @todo: change this hardcoding to store value in future. Since the credit beaureu config is hardcoded in Investor Saga Line No. 30.
                tabName: 'overview',
                startDate: '',
                endDate: '',
                tenure: 'Monthly',
                onSuccess: (res) => {
                    setRowData(res?.shareholdingPattern?.plots?.[0]?.plotValues ?? []);
                    setMetaData(res?.shareholdingPattern?.metadata ?? {});
                },
            }),
        );
    };

    const handleDownload = async (docId: number) => {
        setDownloadShareHoldingData(true);
        try {
            await downloadFile(docId);
        } catch (error: unknown) {
            toast.error('Error downloading file.');
        } finally {
            setDownloadShareHoldingData(false);
        }
    };

    if (!rowData?.length) {
        return <></>;
    }

    const formatFinancialDate = (date: string) => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return `As of ${new Intl.DateTimeFormat('en-GB', options).format(dateObj)}`;
    };

    const render = (
        <div className={styles.ShareHoldingPattern}>
            <div className={styles.Top}>
                <div className={styles.Title}>Shareholding Pattern</div>
                {/* Only show MetaData section if either financialEndDate or mnemosyneDocId is present */}
                {(metaData?.financialEndDate || metaData?.mnemosyneDocId) && (
                    <div className={styles.MetaData}>
                        {/* Show date only if financialEndDate is present */}
                        {metaData?.financialEndDate && (
                            <span className={styles.Date}>
                                {formatFinancialDate(metaData.financialEndDate)}
                            </span>
                        )}
                        {/* Show download only if mnemosyneDocId is present */}
                        {metaData?.mnemosyneDocId && (
                            <div
                                className={styles.DownloadWrapper}
                                onClick={() => handleDownload(Number(metaData?.mnemosyneDocId))}
                            >
                                {downloadShareHoldingData ? (
                                    <LoadingSpinner color="purple" height="15px" />
                                ) : (
                                    <img src={downloadIconDataRoomHovered} alt="" />
                                )}
                                <span className={styles.DownloadText}>Download Details</span>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className={styles.Body}>
                <div className={styles.Table}>
                    <div className={styles.ShpHeaders}>
                        <div>Name</div>
                        <div>Type</div>
                        <div>Shareholding(%)</div>
                        <div>Appointment Date</div>
                    </div>
                    <div className={styles.ShpRows}>
                        {isEmpty(rowData) ? (
                            <div className={styles.ShpRow}>
                                <div>No data available</div>
                            </div>
                        ) : (
                            rowData.map((row: any, index) => (
                                <div key={index} className={styles.ShpRow}>
                                    <div>{row.rowVal ?? '-'}</div>
                                    <div>{row.rowVal2 ?? '-'}</div>
                                    <div>{row.rowVal3 ?? '-'}</div>
                                    <div>{row.rowVal4 === '' ? '-' : row.rowVal4}</div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    if (isPDFReport) return <></>;
    return render;
    // if (isPDFReport) return !isEmpty(rowData) ? <A4Page>{render}</A4Page> : <></>;
    // else return render;
};

export default ShareHoldingPattern;
