import React from 'react';

const WhiteBackGroundPencilIcon = (props) => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.49967 0.335938C3.81301 0.335938 0.833008 3.31594 0.833008 7.0026C0.833008 10.6893 3.81301 13.6693 7.49967 13.6693C11.1863 13.6693 14.1663 10.6893 14.1663 7.0026C14.1663 3.31594 11.1863 0.335938 7.49967 0.335938ZM9.56634 3.71594C9.65967 3.71594 9.75301 3.74927 9.83301 3.8226L10.6797 4.66927C10.833 4.81594 10.833 5.04927 10.6797 5.18927L10.013 5.85594L8.64634 4.48927L9.31301 3.8226C9.37967 3.74927 9.47301 3.71594 9.56634 3.71594ZM8.25301 4.87594L9.62634 6.24927L5.58634 10.2893H4.21301V8.91594L8.25301 4.87594Z"
                fill="white"
            />
        </svg>
    );
};

export default WhiteBackGroundPencilIcon;
