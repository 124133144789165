import clsx from 'clsx';
import styles from '../Table.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import {
    fetchInternalSummonAicaData,
    InternalSummonAicaDataPayload,
    InternalSummonUserType,
    updateAssesseeOrgId,
} from '../../../../../../../../store/user/actions';
import ActionButton from './ActionButton/ActionButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Mixpanel } from '../../../../../../../../utils/mixpanel';
import { AICA_INVESTOR_UPLOAD_DATA, INVESTOR_DOWNLOAD_DATA } from '../../../../../../../../utils/constants/mixpanelEvents/investorEvents';
import dayjs from 'dayjs';
import StatusDropdown, { StatusDropdownOption } from './StatusDropdown/StatusDropdown';
import {
    ALERT_ICON,
    ALERT_ICON_DISABLED,
    BLUE_DROPDOWN_CARET,
    GREEN_DROPDOWN_CARET,
    GREY_DROPDOWN_CARET,
    ORANGE_DROPDOWN_CARET,
    RED_DROPDOWN_CARET,
    WHITE_DROPDOWN_CARET,
} from '../../../../../../../../assets/hostedassets';
import { CompanyStatus } from '../../../../../../../../store/investor/service';
import {
    handleDealApplicationDrawer,
    setDealDrawer,
    setOnePagerData,
    setShowUnlockAicaConfirmation,
    updateCompany,
} from '../../../../../../../../store/investor/action';
import { getDomainFromUrl, getPlatformUserId, openCompanySummonTab } from '../../../../../../../../utils/utils';
import { exportRawFromUnderwritingAndPolus } from '../../../../../../../../store/company/apiHelpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateQueryParams } from '../../../../../../../../utils/searchParamUtils';
import { USER_TYPE } from '../../../../../../../../utils/constants/user';
import {
    ACCESS_TIERS,
    COMPANIES_LIST_TAB,
    MonitoringStatus,
    PROFILE_GENERATION_STATUS,
    RiskGrade,
} from '../../../../../../../../store/investor/constants';
import { moveCompanyToNewTier } from '../../../../../../../../store/investor/investorApiHelper';
import CommentsBox from './CommentsBox/CommentsBox';
import { getInvestmentReport } from '../../../../../../../../store/investor/apiHelpers';
import { toast } from 'react-toastify';
import { FILE_SHARE_STATUS } from '../../../../../../../../common/ManageDealDrawer/DealDrawerConstants';
import { GENERATION_STATUS } from '../../../../../../../../common/ManageDealDrawer/InvestmentReport/InvestmentReport';
import { downloadFile } from '../../../../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { Player } from '@lottiefiles/react-lottie-player';
import CaretIcon from '../../../../../../../../assets/Svg/CaretIcon';
import { COLORS } from '../../../../../../../../utils/constants/colors';
import { INVESTOR_ROUTES } from '../../../../../../../../utils/constants/routesConst';
import { removeTooltipContent, setTooltipContent } from '../../../../../../../../store/tooltip/tooltip_actions';

interface RowsProps {
    headers: any;
    triggerReportGeneration?: Function;
    rows: any;
    showUploader?: Function;
    viewReport: Function;
    fetchData?: Function;
    refreshData?: Function;
    exportData?: Function;
    accessTier: string;
}

export const TOGGLE_MONITORING = 'TOGGLE_MONITORING';
export const VIEW_REPORT = 'VIEW_REPORT';
export const VIEW_ONE_PAGER = 'VIEW_ONE_PAGER';
export const UNLOCK_REPORT = 'UNLOCK_REPORT';
export const INVITE_USER = 'INVITE_USER';
export const SUMMON = 'SUMMON';
export const CREATE_DEAL = 'CREATE_DEAL';
export const DOWNLOAD_IR = 'DOWNLOAD_IR';
export const DOWNLOAD_RAW = 'DOWNLOAD_RAW';
export const UPLOAD_DATA = 'UPLOAD_DATA';
export const ADD_TO_PORTFOLIO = 'ADD_TO_PORTFOLIO';
export const VIEW_DATA_ROOM = 'VIEW_DATA_ROOM';
export const REPORT_TOP_BAR = 'REPORT_TOP_BAR';
export const GENERATE_PDF_REPORT = 'GENERATE_PDF_REPORT';

const DEAL_STATUS_DROPDOWN_OPTIONS = {
    [CompanyStatus.NEW]: {
        label: 'New',
        value: CompanyStatus.NEW,
        backgroundColor: 'var(--secondary-bg-colour)',
        labelColor: 'var(--primary-text-colour)',
        customCaret: <CaretIcon colour={'var(--primary-text-colour)'} height="12" width="12" />,
    },
    [CompanyStatus.ONGOING]: {
        label: 'Ongoing',
        value: CompanyStatus.ONGOING,
        backgroundColor: '#F3F8FF',
        labelColor: '#2F8FFF',
        customCaret: BLUE_DROPDOWN_CARET,
    },
    [CompanyStatus.ACCEPTED]: {
        label: 'Accepted',
        value: CompanyStatus.ACCEPTED,
        backgroundColor: '#E6F7DD',
        labelColor: '#49A71B',
        customCaret: GREEN_DROPDOWN_CARET,
    },
    [CompanyStatus.REJECTED]: {
        label: 'Rejected',
        value: CompanyStatus.REJECTED,
        backgroundColor: '#F5F5F5',
        labelColor: '#B4B4B4',
        customCaret: GREY_DROPDOWN_CARET,
    },
    [CompanyStatus.DEAL_CREATED]: {
        label: 'Deal Created',
        value: CompanyStatus.DEAL_CREATED,
        backgroundColor: '#E6F7DD',
        labelColor: '#49A71B',
        customCaret: GREEN_DROPDOWN_CARET,
    },
};

const RISK_GRADE_DROPDOWN_OPTIONS = {
    [RiskGrade.SEVERE]: {
        label: 'SEVERE',
        value: RiskGrade.SEVERE,
        backgroundColor: '#DF666A',
        labelColor: '#FFFFFF',
        customCaret: WHITE_DROPDOWN_CARET,
    },
    [RiskGrade.HIGH]: {
        label: 'HIGH',
        value: RiskGrade.HIGH,
        backgroundColor: '#FFF4F5',
        labelColor: '#DF666A',
        customCaret: RED_DROPDOWN_CARET,
    },
    [RiskGrade.MEDIUM]: {
        label: 'MEDIUM',
        value: RiskGrade.MEDIUM,
        backgroundColor: '#FFFCEB',
        labelColor: '#FFB800',
        customCaret: ORANGE_DROPDOWN_CARET,
    },
    [RiskGrade.LOW]: {
        label: 'LOW',
        value: RiskGrade.LOW,
        backgroundColor: '#E7FFDB',
        labelColor: '#49A71B',
        customCaret: GREEN_DROPDOWN_CARET,
    },
};

function Rows({ headers, rows, viewReport, accessTier, fetchData }: RowsProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector((state) => state.user);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const investorConfig = useAppSelector((state) => state.investor.config);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const isinvestor = user.userData.accountType === USER_TYPE.INVESTOR;
    const isTeam = user.userData.accountType === USER_TYPE.ADMIN_TEAM;
    const owners = useAppSelector((state) => state.investor.ownerDropdownOptions);
    const onePagerLoading = useAppSelector((state) => state.investor.onePager.loading);
    const commentsThreadState = useAppSelector((state) => state.commentsThreadReducer);

    const [ownerDropdownData, setOwnerDropdownData] = useState({});
    const pollingCount = useRef(0);
    const apiPollingRef: any = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showDropdownIndex, setShowDropdownIndex] = useState<number | null>(null);
    const tdRef = useRef<HTMLTableCellElement | null>(null);
    const currentType = searchParams.get('type')?.toString() || INVESTOR_ROUTES.SCAN;
    const DEFAULT_STATUS = 'default';

    const onToggleDropdown = (index: number | null) => {
        setShowDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        return () => {
            if (apiPollingRef?.current) clearInterval(apiPollingRef.current);
        };
    }, []);

    const [queryParams, setQueryParams] = useSearchParams();
    const handleRowClick = (index, row) => {
        if (isPartner) {
            onToggleDropdown(index);
        } else {
            viewReport(row?.['assesseeOrgId']);
        }
    };
    const prepareOwnerDropdownData = () => {
        const ownersData = {};
        owners.forEach((owner) => {
            if (!owner.name) return;
            ownersData[owner.userId] = {
                label: owner?.name,
                value: owner.userId,
                backgroundColor: COLORS.LIGHT_BG,
                labelColor: COLORS.BLACK,
                customCaret: GREY_DROPDOWN_CARET,
            };
        });
        return ownersData;
    };

    useEffect(() => {
        if (owners.length > 0) setOwnerDropdownData(prepareOwnerDropdownData());
    }, [owners]);

    const handleActionClick = (action, rowData) => {
        switch (action) {
            case VIEW_ONE_PAGER:
                if (!onePagerLoading)
                    return dispatch(
                        setOnePagerData({
                            open: true,
                            source: 'PORTFOLIO',
                            assesseeOrgId: rowData?.assesseeOrgId,
                        }),
                    );
                break;
            case VIEW_REPORT:
                return viewReport(rowData?.assesseeOrgId);
            case DOWNLOAD_RAW:
                return downloadRawData(rowData?.assesseeOrgId, rowData?.orgName);
            case UPLOAD_DATA:
                return uploadData(rowData?.assesseeOrgId);
            case CREATE_DEAL: {
                dispatch(
                    handleDealApplicationDrawer({
                        open: true,
                        source: 'createDeal',
                        heading: 'Create Deal',
                        subHeading: rowData.orgName,
                        dealBeneficiaryId: rowData.assesseeOrgId,
                        width: '67%',
                        profileGenerationStatus: rowData?.profileGenerationStatus,
                    }),
                );
                searchParams.set('dealCreated', 'true');
                setSearchParams(searchParams);
                return;
            }
            case DOWNLOAD_IR:
                toast.warn(
                    'Generating Investment Report.\n' + "It will automatically download within 2 mins, please don't close this tab.",
                );
                return fetchInvestmentReport(rowData, pollingCount);
            case INVITE_USER:
                return addUser(rowData?.assesseeOrgId);
            case SUMMON:
                return summon(rowData?.assesseeOrgId);
            case ADD_TO_PORTFOLIO:
                return addCompanyToPortfolio(rowData?.assesseeOrgId, rowData?.orgName, rowData?.profileGenerationStatus);
            case TOGGLE_MONITORING:
                return updateMonitoringStatus(
                    rowData?.assesseeOrgId,
                    rowData?.monitoringStatus === MonitoringStatus.ACTIVE ? MonitoringStatus.INACTIVE : MonitoringStatus.ACTIVE,
                );
            case VIEW_DATA_ROOM:
                return navigateTODataRoom(rowData?.assesseeOrgId);
            case GENERATE_PDF_REPORT:
                return openPDFReport(rowData?.assesseeOrgId);
        }
    };

    const getAvailableStatusOptions = () => {
        const statusConfig = investorConfig?.additionalDetailsConfig?.status;
        if (!statusConfig) return [];
        return statusConfig?.[currentType]?.dropdownOptions || statusConfig?.[DEFAULT_STATUS]?.dropdownOptions;
    };

    const availableOptions = useMemo(() => getAvailableStatusOptions(), [investorConfig?.additionalDetailsConfig?.status, currentType]);

    const openPDFReport = (assesseeOrgId) => {
        window.open(`/investor/app/pdf-report/${assesseeOrgId}`, '_blank');
    };

    const navigateTODataRoom = (orgId: string) => {
        return navigate(`/investor/app/data-room/${orgId}?tab=dataRoom`);
    };

    const addCompanyToPortfolio = (orgId: string, orgName: string, profileGenerationStatus: string): void => {
        // If the profile is locked, ask the user to unlock it first
        if (profileGenerationStatus === PROFILE_GENERATION_STATUS.LOCKED) {
            dispatch(setShowUnlockAicaConfirmation({ showUnlockAicaConfirmation: true, orgId, orgName }));
            return;
        }

        // If the profile is unlocked, add the company to the portfolio
        moveCompanyToNewTier(
            {
                assesseeOrgId: orgId,
                inviteType: ACCESS_TIERS.TRACK,
            },
            {
                onSuccess: () => {
                    toast.success(`Added to Portfolio: ${orgName}`);
                    queryParams.append('portfolioSuccess', 'true');
                    updateQueryParams(queryParams, setQueryParams, {}, location);
                },
            },
        );
    };

    const summon = (orgId: string) => {
        const platformUserId = getPlatformUserId();
        const payload: InternalSummonAicaDataPayload = {
            parentOrgId: platformData?.investorOrgId as string,
            parentEmailId: user.userData.investorPrimaryEmail,
            orgId,
            userType: InternalSummonUserType.ASSESSEE,
            requesterUserId: platformUserId,
        };
        dispatch(
            fetchInternalSummonAicaData(payload, {
                onSuccess: (userData) => {
                    openCompanySummonTab(userData);
                },
            }),
        );
    };

    const fetchInvestmentReport = async (rowData, pollingCount) => {
        pollingCount.current += 1;
        await getInvestmentReport(
            { assesseeOrgId: rowData?.assesseeOrgId },
            {
                onSuccess: (data) => {
                    const reportGenStatus = data?.reportGenerationStatus;
                    if (pollingCount.current >= 5) {
                        clearInterval(apiPollingRef.current);
                        toast.error('Error in generating Investment Report');
                    } else {
                        if (reportGenStatus === GENERATION_STATUS.IN_PROGRESS) {
                            if (apiPollingRef.current) return;
                            apiPollingRef.current = setInterval(() => {
                                fetchInvestmentReport(rowData, pollingCount);
                            }, 10000);
                        } else if (reportGenStatus === GENERATION_STATUS.SUCCESS) {
                            if (apiPollingRef.current) clearInterval(apiPollingRef.current);
                            onInvestmentReportReceived({
                                fileName: data?.fileName,
                                docId: data?.fileUrl,
                                action: FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                            });
                        }
                    }
                },
                onError: () => {
                    toast.error('Error in generating Investment Report');
                },
            },
        );
    };

    const onInvestmentReportReceived = (fileData) => {
        downloadGeneratedReport(fileData?.docId);
    };

    const downloadGeneratedReport = (documentId = '') => {
        downloadFile(
            Number(documentId),
            () => {
                toast.success('Investment Report downloaded successfully');
            },
            `Investment_Report`,
            () => {
                toast.error('Error in downloading Investment Report');
            },
        );
    };

    const updateOwner = (assesseeOrgId, owner) => {
        dispatch(
            updateCompany({
                orgId: assesseeOrgId,
                orgOwner: owner,
            }),
        );
    };

    const uploadData = (assesseeOrgId) => {
        Mixpanel.track(AICA_INVESTOR_UPLOAD_DATA);
        dispatch(updateAssesseeOrgId({ assesseeOrgId: assesseeOrgId }));
        updateQueryParams(queryParams, setQueryParams, { upload: 'true', id: assesseeOrgId }, location);
    };
    const addUser = (assesseeOrgId) => {
        dispatch(updateAssesseeOrgId({ assesseeOrgId: assesseeOrgId }));
        updateQueryParams(queryParams, setQueryParams, { addUser: 'true', id: assesseeOrgId }, location);
    };

    const downloadRawData = (orgId, orgName) => {
        Mixpanel.track(INVESTOR_DOWNLOAD_DATA);
        exportRawFromUnderwritingAndPolus(
            {
                assesseeOrgId: orgId,
                orgName,
            },
            {},
        );
    };

    const getStatusDropdownOption = (status: string): StatusDropdownOption => {
        const option = availableOptions?.[status];
        if (!option) return DEAL_STATUS_DROPDOWN_OPTIONS?.[CompanyStatus.NEW];
        return option;
    };

    const updateCompanyStatus = (assesseeOrgId: string, companyStatus: { status: CompanyStatus; userId: string; activityDate: string }) => {
        dispatch(updateCompany({ orgId: assesseeOrgId, companyStatus }));
    };

    const updateRiskGrade = (assesseeOrgId: string, riskGrade: RiskGrade) => {
        dispatch(updateCompany({ orgId: assesseeOrgId, companyRiskGrade: riskGrade }));
    };

    const updateMonitoringStatus = (assesseeOrgId: string, monitoringStatus: MonitoringStatus) => {
        dispatch(
            updateCompany(
                { orgId: assesseeOrgId, monitoringStatus },
                {
                    onSuccess: () => {
                        fetchData?.();
                    },
                },
            ),
        );
    };

    const showAlerts = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, assesseeOrgId: string) => {
        event.stopPropagation();
        updateQueryParams(queryParams, setQueryParams, { alerts: 'true', orgId: assesseeOrgId }, location);
    };

    const getRowText = (row, headerId = '', index) => {
        switch (headerId) {
            case 'orgName': {
                if (accessTier === ACCESS_TIERS.TRACK) {
                    return (
                        <div className={styles.MonitoringWrap}>
                            {row?.['monitoringStatus'] === MonitoringStatus.ACTIVE ? (
                                <Player
                                    src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/blinking-green.json"
                                    background="transparent"
                                    loop
                                    style={{
                                        width: '18px',
                                        height: '18px',
                                        marginRight: '6px',
                                        marginLeft: '-4px',
                                    }}
                                    autoplay
                                    speed={2}
                                />
                            ) : (
                                <div className={clsx(styles.MonitoringDot, styles.Red)} />
                            )}
                            <span>{row?.[headerId]}</span>
                        </div>
                    );
                }
                return row?.[headerId];
            }
            case 'alertCount': {
                const alertCount = parseInt(row?.[headerId]) || 0;
                let alertText = 'No Alerts';
                if (alertCount === 1) alertText = '1 Alert';
                else if (alertCount > 1) alertText = `${alertCount} Alerts`;
                return (
                    <button
                        className={clsx(styles.AlertCount, {
                            [styles.Disabled]: row?.['monitoringStatus'] === MonitoringStatus.INACTIVE || alertCount === 0,
                        })}
                        onClick={(e) => showAlerts(e, row?.['assesseeOrgId'])}
                    >
                        <img
                            src={row?.['monitoringStatus'] === MonitoringStatus.ACTIVE && alertCount > 0 ? ALERT_ICON : ALERT_ICON_DISABLED}
                            alt={alertText}
                        />
                        {alertText}
                    </button>
                );
            }
            case 'lastUpdated':
            case 'dateCreated':
                return dayjs(row?.[headerId])?.format('DD MMM YYYY');
            case 'unlockAt':
                return (
                    <span
                        className={clsx({
                            [styles.DisabledDate]: row?.['monitoringStatus'] === MonitoringStatus.INACTIVE,
                        })}
                    >
                        {dayjs(row?.[headerId])?.format('DD MMM YYYY')}
                    </span>
                );
            case 'websiteUrl': {
                if (row?.[headerId]?.length > 25)
                    return (
                        <div title={row?.[headerId]} className={styles.WebsiteUrl}>
                            {getDomainFromUrl(row?.[headerId])}
                        </div>
                    );
                return <span style={{ padding: '0 0.5rem' }}>{getDomainFromUrl(row?.[headerId]) || ''}</span>;
            }
            case 'companyStatus':
                return (
                    <StatusDropdown
                        dropdownOptions={Object.values(getAvailableStatusOptions() || {})}
                        selectedOption={getStatusDropdownOption(row?.[headerId])}
                        selectOption={(option: StatusDropdownOption) =>
                            updateCompanyStatus(row?.['assesseeOrgId'], {
                                status: option.value as CompanyStatus,
                                userId: getPlatformUserId(),
                                activityDate: new Date().toISOString(),
                            })
                        }
                        disabled={isPartner}
                    />
                );
            case 'companyRiskGrade':
                return (
                    <StatusDropdown
                        dropdownOptions={Object.values(RISK_GRADE_DROPDOWN_OPTIONS)}
                        selectedOption={RISK_GRADE_DROPDOWN_OPTIONS[row?.[headerId]] ?? RiskGrade.SEVERE}
                        selectOption={(option: StatusDropdownOption) => updateRiskGrade(row?.['assesseeOrgId'], option.value as RiskGrade)}
                        disabled={isPartner}
                    />
                );
            case 'orgOwner':
                return (
                    <StatusDropdown
                        dropdownOptions={Object.values(
                            ownerDropdownData ?? {
                                label: 'Select',
                                value: '-',
                                backgroundColor: '#F5F5F5',
                                labelColor: '#B4B4B4',
                                customCaret: GREEN_DROPDOWN_CARET,
                            },
                        )}
                        selectedOption={getOwnerDropdownOption(row?.[headerId])}
                        selectOption={(option: StatusDropdownOption) =>
                            updateOwner(row?.['assesseeOrgId'], {
                                name: option.label,
                                userId: option.value,
                                activityBy: user.userData.userName,
                                activityDate: new Date().toISOString(),
                            })
                        }
                        disabled={isPartner}
                    />
                );
            case 'companyComment':
                return (
                    <CommentsBox
                        index={index}
                        source={'company'}
                        comments={row?.[headerId]}
                        assesseeOrgId={row?.assesseeOrgId}
                        fetchData={fetchData}
                    />
                );
            default:
                return row?.[headerId];
        }
    };
    const handleMouseEnter = (e, text, headerId) => {
        //Showing tooltip only for orgName and referredBy
        if (['orgName', 'referredBy'].includes(headerId)) {
            const target = e.currentTarget;
            if (target.scrollWidth > target.clientWidth) {
                dispatch(
                    setTooltipContent({
                        content: text,
                        target: e.currentTarget,
                        placement: 'right',
                    }),
                );
            }
        }
    };

    const handleMouseLeave = (headerId) => {
        if (['orgName', 'referredBy'].includes(headerId)) {
            removeTooltipContent(dispatch);
        }
    };

    const getOwnerDropdownOption = (name: string): any => {
        if (Object.values(ownerDropdownData).find((owner: any) => owner.label === name)) {
            return Object.values(ownerDropdownData).find((owner: any) => owner.label === name);
        } else {
            if (isPartner)
                return {
                    label: '-',
                    value: '-',
                    backgroundColor: '#fff',
                    labelColor: COLORS.BLACK,
                    customCaret: GREY_DROPDOWN_CARET,
                };
            return {
                label: 'Select',
                value: '-',
                backgroundColor: COLORS.LIGHT_BG,
                labelColor: COLORS.GREY,
                customCaret: GREY_DROPDOWN_CARET,
            };
        }
    };

    return (
        <>
            {rows?.map((row, index) => (
                <tr
                    className={clsx(styles.Row, {
                        [styles.HoverState]: (isPartner && row?.companyAccessType != 'WATCH') || isinvestor || isTeam,
                        [styles.HoverStateCommentsActive]:
                            commentsThreadState.showCommentsThread && row?.assesseeOrgId === commentsThreadState.assesseeOrgId,
                    })}
                    key={index}
                    onClick={() => handleRowClick(index, row)}
                >
                    {headers.map((header, i) => {
                        return (
                            <td
                                className={clsx(styles.RowItem, {
                                    [styles.RowItem_Ellipsis]: header.id === 'orgName' || header.id === 'referredBy',
                                    [styles.ThreeDotRowItem]: header.id === 'actions',
                                    [styles.CommentRowItem]: header.id === 'companyComment',
                                })}
                                key={`${header.id}`}
                                ref={tdRef}
                                onMouseEnter={(e) => handleMouseEnter(e, row?.[header.id], header.id)}
                                onMouseLeave={() => handleMouseLeave(header.id)}
                            >
                                {header.id === 'actions' ? (
                                    <ActionButton
                                        rowIndex={index}
                                        dataLength={rows?.length || 0}
                                        row={row}
                                        action={row?.['action']}
                                        handleActionClick={handleActionClick}
                                        accessTier={accessTier}
                                        isOpen={showDropdownIndex === index}
                                        onToggleDropdown={onToggleDropdown}
                                        tdRef={tdRef}
                                    />
                                ) : (
                                    getRowText(row, header.id, index)
                                )}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </>
    );
}

export default Rows;
