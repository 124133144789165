import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    fetchCompanyUploadConfig,
    fetchUploadJourneyData,
    setShowInviteBorrowerModal,
    setShowManageUserDrawer,
} from '../../../store/company/action';
import { clearLocalStorageOnLogout } from '../../../utils/utils';
import { getDataGapDocs, getUntaggedCount } from '../../../common/MagicUpload/MagicUploadHelper';
import {
    setDealDrawer,
    updateNeedInfoFileNumber,
    updateUntaggedMuFileNumber,
} from '../../../store/investor/action';
import { resendVerificationEmail } from '../../../store/user/userV2/actions';
import { toast } from 'react-toastify';
import { Mixpanel } from '../../../utils/mixpanel';

// Define return types for each mode
export type OffersReturn = {
    openCapitalRequirementDrawer: (referer: string) => void;
};

export type TopbarReturn = {
    openInviteBorrower: () => void;
    closeInviteBorrower: () => void;
    showInviteBorrower: boolean;
    companyConfig: any;
    openCapitalRequirementDrawer: (referer: string) => void;
};

export type MinimalReturn = {
    openCapitalRequirementDrawer: (referer: string) => void;
    openInviteBorrower: () => void;
    closeInviteBorrower: () => void;
};

export type FullReturn = {
    userData: any;
    companyConfig: any;
    showInviteBorrower: boolean;
    selectedOrg: any;
    pendingDataCount: number;
    isLoadingPendingData: boolean;
    openCapitalRequirementDrawer: () => void;
    openInviteBorrower: () => void;
    closeInviteBorrower: () => void;
    investorData: any;
    resendEmail: () => void;
    domainMetadata: any;
    uploadJourneyData: any;
    manageUserDrawerState: boolean;
    untaggedCount: number;
    selectedAssesseeOrg: string;
    isMagicLink: boolean;
    fetchingUploadJourneyData: boolean;
    openMagicUpload: () => void;
    openManageUserDrawer: () => void;
    closeManageUserDrawer: () => void;
    getGeneratingReportProps: () => {
        mainText: string;
        ctaText: string;
        showSpinner: boolean;
        isGenerating: boolean;
    };
    handleDataPendingClick: () => void;
    fetchUploadData: () => void;
    showDelayedPopup: boolean;
    isCreditBureauUpload: boolean;
};

interface UseUploadDataProps {
    isInvestor?: boolean;
    userType?: string;
    assesseeOrgId?: string;
    mode?: 'full' | 'minimal' | 'offers' | 'topbar';
}

/**
 * Encapsulates side-effect logic and data-fetching for the UploadData component.
 */
export function useUploadData({
    isInvestor = false,
    userType,
    assesseeOrgId = '',
    mode = 'full',
}: UseUploadDataProps): OffersReturn | TopbarReturn | MinimalReturn | FullReturn {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();

    const user = useAppSelector((state) => state.user);
    const isCompany = location.pathname.includes('company');
    const userData = user.userData;
    const investorData = useAppSelector((state) => state.investor);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const showInviteBorrower = useAppSelector((state) => state.company.showInviteBorrower);
    const manageUserDrawerState = useAppSelector((state) => state.company.showManageUser);
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);
    const isMagicLink = searchParams.get('configName') === 'MAGIC_LINK';
    const isCreditBureauUpload = searchParams.get('selectedTab') === 'creditBureau';
    const fetchingUploadJourneyData = useAppSelector(
        (state) => state.company.fetchingUploadJourneyData,
    );
    const companyConfig = useAppSelector((state) => state.company.config);

    // Local states
    const [selectedOrg, setSelectedOrg] = useState<any>(mode === 'full' ? null : undefined);
    const [isLoadingPendingData, setIsLoadingPendingData] = useState(
        mode === 'full' ? true : undefined,
    );
    const [showLoadingState, setShowLoadingState] = useState(true);
    const [pendingDataCount, setPendingDataCount] = useState(
        mode === 'full' ? undefined : undefined,
    );
    const [showDelayedPopup, setShowDelayedPopup] = useState(false);

    // Derived values
    const selectedAssesseeOrg =
        searchParams.get('id') || assesseeOrgId || userData.assesseeOrgId || id || '';

    /**
     * Fetch company config + upload data
     */
    const fetchConfigAndUploadData = () => {
        dispatch(
            fetchCompanyUploadConfig({
                accountType: userType ?? '',
                primaryEmail: userData.primaryEmail ?? userData.investorPrimaryEmail,
                investorOrgId: userData.investorOrgId,
                configName: isInvestor
                    ? 'AICA_BORROWER_ONBOARDING_DATA_BY_LENDER'
                    : 'AICA_BORROWER_ONBOARDING_DATA_BY_BORROWER',
                assesseeOrgId: selectedAssesseeOrg || userData.assesseeOrgId || '',
            }),
        );
    };

    /**
     * Fetch the uploadJourneyData from API
     */
    const fetchUploadData = () => {
        dispatch(
            fetchUploadJourneyData({
                accountType: userType,
                assesseeOrgId: selectedAssesseeOrg || userData.assesseeOrgId || '',
            }),
        );
    };

    /**
     * Effect: Fetch config when necessary conditions are met
     */
    useEffect(() => {
        if (mode !== 'full') return;

        if (
            (userData.assesseeOrgId || selectedAssesseeOrg) &&
            userData.investorOrgId &&
            ((!isInvestor && userData.investorPrimaryEmail) ||
                (isInvestor && userData.primaryEmail))
        ) {
            fetchConfigAndUploadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mode,
        userData.assesseeOrgId,
        userData.primaryEmail,
        userData.investorOrgId,
        userData.investorPrimaryEmail,
        isInvestor,
        selectedAssesseeOrg,
    ]);

    /**
     * Effect: Pick the selected company if in Investor mode
     */
    useEffect(() => {
        const companiesList = investorData?.portfolio?.companiesList;
        if (selectedAssesseeOrg && companiesList?.length) {
            const foundOrg = companiesList.find(
                (company) => company?.assesseeOrgId === selectedAssesseeOrg,
            );
            if (foundOrg) {
                setSelectedOrg(foundOrg);
            }
        }
    }, [selectedAssesseeOrg, investorData]);

    useEffect(() => {
        if (!isCompany) return;
        if (fetchingUploadJourneyData) return;
        let timeoutId: NodeJS.Timeout | undefined;
        if (uploadJourneyData === null) {
            timeoutId = setTimeout(() => {
                toast.error('Unauthorized access. Logging out user.');
                clearLocalStorageOnLogout();
                navigate('/login');
            }, 2000);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [uploadJourneyData, fetchingUploadJourneyData, navigate]);

    /**
     * Resend verification email
     */
    const resendEmail = () => {
        dispatch(
            resendVerificationEmail(
                {
                    user: {
                        emailId: userData.primaryEmail,
                        investorId: userData.investorOrgId,
                        accountType: userData.accountType,
                        accountTypes: [userData.accountType],
                    },
                    platformId: domainMetadata?.productName ?? '',
                },
                {
                    onSuccess: () => {
                        toast.success('Verification email sent successfully');
                    },
                },
            ),
        );
    };

    /**
     * Open Magic Upload by setting query params
     */
    const openMagicUpload = () => {
        searchParams.set('magicUpload', 'true');
        searchParams.set('isCompany', 'true');
        setSearchParams(searchParams);
    };

    /**
     * Open or close the Invite Borrower modal
     */
    const openInviteBorrower = () => {
        dispatch(setShowInviteBorrowerModal(true));
    };
    const closeInviteBorrower = () => {
        dispatch(setShowInviteBorrowerModal(false));
    };

    /**
     * Open or close Manage User Drawer
     */
    const openManageUserDrawer = () => {
        dispatch(setShowManageUserDrawer(true));
    };
    const closeManageUserDrawer = () => {
        dispatch(setShowManageUserDrawer(false));
    };

    /**
     * Open Capital Requirement Drawer
     */
    const openCapitalRequirementDrawer = (referer: string) => {
        Mixpanel.track('Capital Requirement Clicked', {
            'PNO ID': userData.investorOrgId,
            'User Email': userData.primaryEmail,
            Referer: referer,
        });
        dispatch(
            setDealDrawer({
                open: true,
                drawerLabel: 'Capital Requirement',
                drawerSource: 'Debt Products', // Because we are using the same drawer for Debt Products
            }),
        );
    };

    /**
     * Get pending documents count
     */
    const getPendingDocumentsCount = async () => {
        if (!userData.assesseeOrgId) return;

        await getDataGapDocs(
            {
                assesseeOrgId: userData.assesseeOrgId,
            },
            {
                onSuccess: (res) => {
                    setPendingDataCount(res.pendingCount);
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                    setIsLoadingPendingData(false);
                },
                onError: () => {
                    setIsLoadingPendingData(false);
                },
            },
        );
    };

    useEffect(() => {
        if (mode !== 'full') return;

        setIsLoadingPendingData(true);
        setShowLoadingState(true);
        // Set a timer for 5 seconds to show loading state irrespetive of the api response
        const loadingTimer = setTimeout(() => {
            setShowLoadingState(false);
        }, 5000);

        getPendingDocumentsCount();

        return () => clearTimeout(loadingTimer);
    }, [userData.assesseeOrgId, mode]);

    /**
     * Get generating report props
     */
    const getGeneratingReportProps = () => {
        if (showLoadingState) {
            return {
                mainText: 'Checking for pending data & clarifications...',
                ctaText: '',
                showSpinner: true,
                isGenerating: true,
            };
        }

        if (pendingDataCount && pendingDataCount > 0) {
            return {
                mainText: `${pendingDataCount} data & clarifications pending`,
                ctaText: ' View List',
                showSpinner: false,
                isGenerating: false,
                showPendingData: true,
            };
        }

        return {
            mainText: 'No pending data, submit additional?',
            ctaText: ' Upload',
            showSpinner: false,
            isGenerating: false,
            showPendingData: true,
        };
    };

    const handleDataPendingClick = () => {
        // Track the event in Mixpanel
        Mixpanel.track('Data Pending Viewed', {
            'Borrower ID': userData?.assesseeOrgId,
            'User Email': userData?.primaryEmail,
            'PNO ID': userData?.investorOrgId,
        });

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('needInfo', 'true');
        newSearchParams.set('showNeedInfo', 'true');
        newSearchParams.set('isCompany', 'true');
        setSearchParams(newSearchParams, {
            replace: true,
            state: location.state,
        });
    };

    useEffect(() => {
        if (mode !== 'full') return;

        if (companyConfig?.popupData?.showPopup && !isMagicLink) {
            const timer = setTimeout(() => {
                setShowDelayedPopup(true);
            }, 15000);

            return () => clearTimeout(timer);
        }
    }, [companyConfig?.popupData?.showPopup, isMagicLink, mode]);

    // Return typed objects based on mode
    switch (mode) {
        case 'offers':
            return {
                openCapitalRequirementDrawer,
            };
        case 'topbar':
            return {
                openInviteBorrower,
                closeInviteBorrower,
                showInviteBorrower: showInviteBorrower || false,
                companyConfig,
                openCapitalRequirementDrawer,
            };
        case 'minimal':
            return {
                openCapitalRequirementDrawer,
                openInviteBorrower,
                closeInviteBorrower,
            };
        default:
            return {
                userData,
                companyConfig,
                showInviteBorrower: showInviteBorrower || false,
                selectedOrg,
                pendingDataCount,
                isLoadingPendingData,
                openCapitalRequirementDrawer,
                openInviteBorrower,
                closeInviteBorrower,
                investorData,
                resendEmail,
                domainMetadata,
                uploadJourneyData,
                manageUserDrawerState,
                untaggedCount,
                selectedAssesseeOrg,
                isMagicLink,
                fetchingUploadJourneyData,
                openMagicUpload,
                openManageUserDrawer,
                closeManageUserDrawer,
                getGeneratingReportProps,
                handleDataPendingClick,
                fetchUploadData,
                showDelayedPopup,
                isCreditBureauUpload,
            };
    }
}
