import {
    getSignedUrl,
    saveDocMetaDataInDocService,
    uploadFile,
} from '../../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { toast } from 'react-toastify';
import { fetchAllDocMap, updateDocMetadata } from '../../MagicUploadHelper';
import { addTimeStamp } from '../../../../modules/Company/UploadJourney/components/UploadCard/utils/utils';
import { getSubdomain } from '../../../../utils/utils';
import useFetchDataForMU from '../../useFetchDataForMU';
import { Mixpanel } from '../../../../utils/mixpanel';
import { getTimeStamp } from '../../../../utils/dateUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    checkIfOfficeFileIsEncrypted,
    checkIfPdfIsEncrypted,
    checkIfZipIsEncrypted,
    OfficeFileTypes,
    PASSWORD_PROTECTION_STATUS,
} from '../../Utils/PasswordProtectionUtils';
import dayjs from 'dayjs';
import { MIME_TYPES } from '../../Utils/MagicUploadConstants';

const useMnemosyneUpload = (tabState, fileData, setFileData, categoryId, setLoading?: Dispatch<SetStateAction<boolean>> | undefined) => {
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const { fetchFileData, getUntaggedStatus, setUpdatedFileData } = useFetchDataForMU(
        tabState,
        fileData,
        setFileData,
        false, // Set autoFetch to false to avoid duplicate API calls
    );
    const userId = sessionStorage.getItem('platform_user_id') ?? localStorage.getItem('platform_user_id');
    const [filesToUpload, setFilesToUpload] = React.useState<any>([]);
    const [allFilesMap, setAllFilesMap] = React.useState({});
    const loggedInUser = useAppSelector((state) => state.user.userData);
    const [params, setParams] = useSearchParams();
    const [fetchAgain, setFetchAgain] = useState(false);
    const isCompany = params.get('isCompany') === 'true';
    const MAX_FILE_SIZE_MB = 200;
    const searchParams = useParams();
    const assesseeOrgId = useAppSelector((state) => state.user?.userData?.assesseeOrgId) ?? searchParams.id;

    // Function to generate a temporary unique ID for new files
    const generateTempId = (fileName) => `temp_${getTimeStamp()}_${fileName}`;

    useEffect(() => {
        if (platformData?.investorOrgId) {
            fetchDuplicateHashes();
        }
    }, [platformData?.investorOrgId]);

    const fetchDuplicateHashes = async () => {
        await fetchAllDocMap(
            {
                pnoId: platformData?.investorOrgId,
            },
            {
                onSuccess: (res) => {
                    setAllFilesMap(res.fileMetaData);
                },
                onerror: () => {},
            },
        );
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await handleFileChange(event.dataTransfer.files);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const getContentTypeForFile = (file) => {
        let fileExtension = file.name.split('.').pop().toLowerCase();
        return MIME_TYPES[fileExtension] ?? MIME_TYPES.oct_stream;
    };

    const getSignedUrlFromS3 = async (fileItem) => {
        setLoading?.(true);
        const { tempId, file } = fileItem;
        const uploadConfig = {
            assesseeOrgId: assesseeOrgId,
            categoryId: categoryId,
            mnemosyneOrgId: platformData?.investorOrgId,
        };
        try {
            const signResponse = await getSignedUrl({ file }, uploadConfig);
            if (!signResponse) {
                toast.error('Error in uploading file');
                // Remove the file from fileData
                setUpdatedFileData({ [tempId]: undefined });
                setFilesToUpload((prev) => prev.filter((item) => item.tempId !== tempId));
                setLoading?.(false);
                return;
            }
            // Commented this since we have moved this logic to backend.
            if (allFilesMap[signResponse.fileChecksum]) {
                toast.error('Duplicate File not uploaded - ' + file.name);
                // Remove the file from fileData
                setUpdatedFileData({ [tempId]: undefined });
                setFilesToUpload((prev) => prev.filter((item) => item.tempId !== tempId));
                setLoading?.(false);
                return;
            }
            mainUploadFunction(fileItem, signResponse);
        } catch (err) {
            toast.error('Error in uploading file');
            // Remove the file from fileData
            setUpdatedFileData({ [tempId]: undefined });
            setFilesToUpload((prev) => prev.filter((item: any) => item.tempId !== tempId));
            setLoading?.(false);
        }
    };

    const mainUploadFunction = async (fileItem, signResponse) => {
        const { tempId, file } = fileItem;
        try {
            const uploadResponse = await uploadFile(file, signResponse?.signedUrl);
            if (!uploadResponse.url) {
                throw new Error('Error in uploading file');
            }
            const passwordProtectionStatus = fileData.find((f) => f.id === tempId)?.passwordProtectionStatus;
            const input_saveMetadata = {
                categoryId: categoryId,
                mnemosyneOrgId: platformData?.investorOrgId,
                fileName: file.name,
                fileSizeKb: file?.size / 1_000,
                flowId: 16,
                fileType: file.type,
            };
            const docData = await saveDocMetaDataInDocService({
                input: input_saveMetadata,
                orgId: assesseeOrgId,
            });
            updateDocMetadata(
                {
                    docId: docData?.id,
                    fileName: addTimeStamp(file.name),
                    originalFileName: file.name,
                    contentType: file.type !== '' ? file.type : getContentTypeForFile(file),
                    fileChecksum: signResponse.fileChecksum,
                    userId: userId,
                    pnoId: platformData?.investorOrgId,
                    ...(isCompany && { orgId: assesseeOrgId }),
                    passwordProtectionStatus: passwordProtectionStatus,
                },
                {
                    onSuccess: async (res) => {
                        await fetchDuplicateHashes();
                        if (res?.id) {
                            Mixpanel.track('File Uploaded', {
                                fileName: file.name,
                                uploadedBy: loggedInUser.userName,
                                source: 'Website',
                                AicaDomain: getSubdomain(),
                            });
                            await getUntaggedStatus();
                            setFilesToUpload((prev) => prev.filter((item: any) => item.tempId !== tempId));

                            let updatedFileInfo: any = {
                                id: res.id,
                                fileName: file.name,
                                status: 'processing',
                                passwordProtectionStatus: res.passwordProtectionStatus ?? passwordProtectionStatus,
                                zip: file.type === MIME_TYPES.zip,
                            };
                            if (passwordProtectionStatus === PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED) {
                                updatedFileInfo.source = 'app';
                                updatedFileInfo.uploadedBy = loggedInUser.userName;
                                updatedFileInfo.lastUpdated = dayjs().format('DD MMM YYYY');
                            }
                            // Update the fileData
                            setUpdatedFileData({
                                [tempId]: undefined,
                                [res.id]: updatedFileInfo,
                            });
                            setLoading?.(false);
                        } else {
                            throw new Error('Error in uploading file');
                        }
                    },
                    onError: (error) => {
                        toast.error(error);
                        // Remove the file from fileData
                        setUpdatedFileData({ [tempId]: undefined });
                        setFilesToUpload((prev) => prev.filter((item: any) => item.tempId !== tempId));
                        setLoading?.(false);
                    },
                },
            );
        } catch (err) {
            toast.error('Error in uploading file');
            setUpdatedFileData({ [tempId]: undefined });
            setFilesToUpload((prev) => prev.filter((item: any) => item.tempId !== tempId));
            setLoading?.(false);
        }
    };

    const handleClick = () => {
        document.getElementById('fileInput')?.click();
    };

    useEffect(() => {
        if (filesToUpload.length === 0 && fetchAgain) {
            fetchFileData(0);
            setFetchAgain(false);
        }
    }, [filesToUpload]);

    const handleFileChange = async (files) => {
        const maxSizeBytes = MAX_FILE_SIZE_MB * 1024 * 1024;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].size > maxSizeBytes) {
                    toast.error(`File size exceeds ${MAX_FILE_SIZE_MB}MB limit: ${files[i].name}`);
                    return;
                }
            }

            const newFilesToUpload: { tempId: string; file: any }[] = [];
            const updatedFilesData = {};

            // Convert FileList to Array
            const filesArray = Array.from(files);

            // Map over files and perform checks
            const fileChecks = filesArray.map(async (file: any) => {
                const tempId = generateTempId(file.name);
                let isPasswordProtected: unknown = false;
                if (file.type === MIME_TYPES.pdf) {
                    isPasswordProtected = await checkIfPdfIsEncrypted(file);
                } else if (OfficeFileTypes.includes(file.type)) {
                    isPasswordProtected = await checkIfOfficeFileIsEncrypted(file);
                } else if (file.type === MIME_TYPES.zip) {
                    isPasswordProtected = await checkIfZipIsEncrypted(file);
                }
                // Proceed with adding the file
                updatedFilesData[tempId] = {
                    id: tempId,
                    fileName: file.name,
                    status: 'processing',
                    zip: file.type === MIME_TYPES.zip,
                    passwordProtectionStatus: isPasswordProtected
                        ? PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED
                        : PASSWORD_PROTECTION_STATUS.NOT_PASSWORD_PROTECTED,
                };
                newFilesToUpload.push({ tempId, file });
            });

            // Wait for all checks to complete
            await Promise.all(fileChecks);

            // Update fileData map
            if (Object.keys(updatedFilesData).length > 0) {
                setUpdatedFileData(updatedFilesData);
                setFilesToUpload((prev) => [...prev, ...newFilesToUpload]);
            }
        }
    };

    useEffect(() => {
        if (filesToUpload && filesToUpload.length > 0) {
            // Start the upload process by getting the signed URL
            getSignedUrlFromS3(filesToUpload[0]);
        }
    }, [filesToUpload]);

    return {
        handleFileChange,
        handleDragOver,
        handleDrop,
        handleDragLeave,
        handleClick,
    };
};

export default useMnemosyneUpload;
