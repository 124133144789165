import React, { useState } from 'react';
import styles from './ManagementProfile.module.scss';
import user from '../../../../../../assets/user.png';

import { ctaPurpleRight } from '../../../../../../assets/hostedassets';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import ManagementUserProfile from './components/ManagementUserProfile';
import { toTitleCase } from '../../../../../../utils/utils';
import clsx from 'clsx';
import dayjs from 'dayjs';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../../../store/pdfReport/pdfReport_constants';
import { reportSectionsToShow } from '../../../../../AicaPDFReport/AicaPDFReport';

interface SingleProfile {
    age: string;
    designation: string;
    din: string;
    directorSince: string;
    name: string;
    imageUrl?: string;
    isActive?: boolean;
    linkedinUrl?: string;
    tenureBeginDate?: number;
    tenureEndDate?: number;
}

function ManagementProfile({
    profiles,
    redirectToCreditBureau,
    redirectToCompliances,
    isPDFReport,
}: any) {
    const [showUserProfile, setShowUserProfile] = useState<null | SingleProfile>(null);

    if (!profiles?.length) {
        return <></>;
    }

    const render = (
        <div className={styles.ManagementProfile}>
            <div className={styles.Top}>
                <div className={styles.Title}>Management Profile</div>
                <div className={styles.Right}></div>
            </div>
            <div className={clsx(styles.Body, { [styles.PDFReport]: isPDFReport })}>
                {profiles?.map((profile: SingleProfile, i) => (
                    <div className={styles.Card} key={`${profile?.name}-${i}`}>
                        <div>
                            <div className={styles.CardTop}>
                                <div className={styles.Image}>
                                    <img
                                        className={styles.MainImg}
                                        src={profile?.imageUrl || user}
                                        alt="profile"
                                        width="100%"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = user;
                                        }}
                                    />
                                </div>
                                {profile?.isActive !== null && profile?.isActive !== undefined && (
                                    <div
                                        className={clsx(styles.Badge, {
                                            [styles.Active]: profile?.isActive,
                                            [styles.Former]: !profile?.isActive,
                                        })}
                                    >
                                        {profile?.isActive ? 'Active' : 'Former'}
                                    </div>
                                )}
                            </div>

                            <div className={styles.Name}>
                                {profile?.name && profile?.name?.length > 60 ? (
                                    <Tooltip direction="bottom" content={profile?.name} fitContent>
                                        {`${toTitleCase(profile?.name?.substring?.(0, 60))}...`}
                                    </Tooltip>
                                ) : (
                                    toTitleCase(profile?.name)
                                )}
                            </div>
                        </div>

                        <div className={styles.Fields}>
                            <div className={styles.Field}>
                                <div className={styles.FieldName}>Designation</div>
                                <div className={styles.FieldVal}>
                                    {' '}
                                    {profile?.designation || '-'}
                                </div>
                            </div>

                            <div className={styles.Field}>
                                <div className={styles.FieldName}>Tenure</div>
                                <div className={styles.FieldVal}>
                                    {(profile?.isActive === null ||
                                        profile?.isActive === undefined) &&
                                    !profile?.tenureBeginDate &&
                                    !profile?.tenureEndDate ? (
                                        <>NA</>
                                    ) : (
                                        <>
                                            {profile?.tenureBeginDate &&
                                            dayjs(profile?.tenureBeginDate).isValid()
                                                ? dayjs(profile?.tenureBeginDate).format('MMM YYYY')
                                                : ''}{' '}
                                            -{' '}
                                            {profile?.tenureEndDate &&
                                            dayjs(profile?.tenureEndDate).isValid()
                                                ? dayjs(profile?.tenureEndDate).format('MMM YYYY')
                                                : profile?.isActive
                                                ? 'Present'
                                                : 'NA'}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={styles.Field}>
                                <div className={styles.FieldName}>Age</div>
                                <div className={styles.FieldVal}>{profile?.age || '-'}</div>
                            </div>
                        </div>

                        <div className={styles.cta} onClick={() => setShowUserProfile(profile)}>
                            <img src={ctaPurpleRight} alt="" />
                            View Details
                        </div>
                    </div>
                ))}
            </div>

            {showUserProfile && (
                <SideDrawer onClose={() => setShowUserProfile(null)} width={'60%'}>
                    <ManagementUserProfile
                        showUserProfile={showUserProfile}
                        redirectToCreditBureau={redirectToCreditBureau}
                        redirectToCompliances={redirectToCompliances}
                    />
                </SideDrawer>
            )}
        </div>
    );

    if (isPDFReport)
        return (
            <>
                {!!profiles?.length &&
                reportSectionsToShow?.includes(REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_SUMMARY) ? (
                    <A4Page sectionName={REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_SUMMARY}>
                        {render}
                    </A4Page>
                ) : (
                    <></>
                )}
                {reportSectionsToShow?.includes(
                    REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_INDIVIDUAL,
                ) && profiles?.length ? (
                    profiles?.map((profile: SingleProfile, i) => (
                        <A4Page
                            key={i}
                            sectionName={REPORT_SECTION_NAMES.MANAGEMENT_PROFILE_INDIVIDUAL}
                        >
                            <ManagementUserProfile
                                showUserProfile={profile}
                                redirectToCreditBureau={redirectToCreditBureau}
                                redirectToCompliances={redirectToCompliances}
                                isPDFReport={isPDFReport}
                            />
                        </A4Page>
                    ))
                ) : (
                    <></>
                )}
            </>
        );
    else return render;
}

export default ManagementProfile;
