import isEmpty from 'lodash/isEmpty';
import {
    AicaOverviewExperiementBlack,
    AicaOverviewLightBulbPurple,
    AicaOverviewRiskRed,
    arrowDownPurple,
    GeneratingAicaOverviewLoader,
    LIKE_ICON,
} from '../../../../assets/hostedassets';
import styles from './AicaOverviewBody.module.scss';
import { AicaOverviewData } from '../../useAicaOverview';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Tooltip from '../../../_custom/Tooltip/Tooltip';
import { AICAOverviewFeedbackType } from '../../../../store/company/action';
import clsx from 'clsx';
import AIBadge from '../../../../modules/Dashboard/DashboardModules/Overview/components/AIBadge/AIBadge';

type AicaoverviewBodyProps = {
    data: AicaOverviewData;
    feedback:
        | {
              feedbackType: AICAOverviewFeedbackType;
              feedback: string;
          }
        | undefined;
    sendFeedbackForOverview: (feedbackType: AICAOverviewFeedbackType) => void;
    isPDFReport?: boolean;
};

const AicaOverviewBody = (props: AicaoverviewBodyProps) => {
    const { data, feedback, sendFeedbackForOverview, isPDFReport = false } = props;
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (isPDFReport) setShowMore(true);
    }, [isPDFReport]);

    // If there is no data to show, return null
    if (isEmpty(data.observations) && isEmpty(data.risks) && isEmpty(data.recommendations))
        return null;

    return (
        <div className={clsx(styles.OverviewBodyWrapper, { [styles.isPDFReport]: isPDFReport })}>
            <div className={styles.Header}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={GeneratingAicaOverviewLoader} alt="Generating AICA Overview" />
                    <h3>AICA Overview</h3>
                    {!isEmpty(data?.moduleName) && (
                        <>
                            <div className={styles.Separator} />
                            <h4>{data.moduleName}</h4>
                        </>
                    )}
                </div>
                <div className={styles.RightSection}>
                    {!isPDFReport && (
                        <h6>Created on {dayjs(new Date(data.createdAt)).format('DD MMM YYYY')}</h6>
                    )}
                    <AIBadge />
                </div>
            </div>
            <div className={showMore ? `${styles.Body} ${styles.BodyExpanded}` : styles.Body}>
                <div className={styles.UpperSection}>
                    {!isEmpty(data.observations) && (
                        <div className={styles.Left}>
                            <div
                                className={styles.title}
                                style={{ fontSize: isPDFReport ? '11px' : '' }}
                            >
                                <img
                                    src={AicaOverviewExperiementBlack}
                                    alt="AICA Overview"
                                    height={15}
                                    width={15}
                                />
                                <span>Observations</span>
                            </div>
                            <ul
                                className={styles.Observations}
                                style={{
                                    ...(isPDFReport && { fontSize: '11px', lineHeight: '1rem' }),
                                }}
                            >
                                {data.observations?.map((observation, index) => (
                                    <li key={index}>{observation}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {!isEmpty(data.risks) && (
                        <div className={styles.Right}>
                            <div
                                className={styles.title}
                                style={{ fontSize: isPDFReport ? '11px' : '' }}
                            >
                                <img
                                    src={AicaOverviewRiskRed}
                                    alt="AICA Overview"
                                    height={15}
                                    width={15}
                                />
                                <span style={{ color: '#DF666A' }}>Potential Risks</span>
                            </div>
                            <ul
                                className={styles.Risks}
                                style={{
                                    ...(isPDFReport && { fontSize: '11px', lineHeight: '1rem' }),
                                }}
                            >
                                {data.risks?.map((risk, index) => <li key={index}>{risk}</li>)}
                            </ul>
                        </div>
                    )}
                </div>
                {!isEmpty(data.recommendations) && (
                    <div className={styles.LowerSection}>
                        <div className={styles.title}>
                            <img
                                src={AicaOverviewLightBulbPurple}
                                alt="AICA Overview"
                                height={15}
                                width={15}
                            />
                            <span
                                style={{
                                    color: 'var(--primary-text-colour)',
                                    fontSize: isPDFReport ? '11px' : '',
                                }}
                            >
                                Recommendations
                            </span>
                        </div>
                        <ul
                            className={styles.Recommendations}
                            style={{
                                ...(isPDFReport && { fontSize: '11px', lineHeight: '1rem' }),
                            }}
                        >
                            {data.recommendations?.map((recommendation, index) => (
                                <li key={index}>{recommendation}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className={styles.InfoSection}>
                    {!isPDFReport && (
                        <div className={styles.ReviewSection}>
                            <div className={styles.ReviewTitle}>Was this overview helpful?</div>
                            <div className={styles.ReviewButtonWrap}>
                                <Tooltip
                                    content="Like"
                                    direction="top"
                                    style={{ textAlign: 'center', top: '-2.5rem', left: '1.2rem' }}
                                    small
                                    fitContent
                                >
                                    <button
                                        className={clsx(styles.ReviewButton, {
                                            [styles.Active]:
                                                feedback?.feedbackType ===
                                                AICAOverviewFeedbackType.POSITIVE,
                                        })}
                                        onClick={() =>
                                            sendFeedbackForOverview(
                                                AICAOverviewFeedbackType.POSITIVE,
                                            )
                                        }
                                    >
                                        <img src={LIKE_ICON} alt="Like" />
                                    </button>
                                </Tooltip>

                                <Tooltip
                                    content="Dislike"
                                    direction="top"
                                    style={{ textAlign: 'center', top: '-2.5rem', left: '1.2rem' }}
                                    small
                                    fitContent
                                >
                                    <button
                                        className={clsx(styles.ReviewButton, styles.Dislike, {
                                            [styles.Active]:
                                                feedback?.feedbackType ===
                                                AICAOverviewFeedbackType.NEGATIVE,
                                        })}
                                        onClick={() =>
                                            sendFeedbackForOverview(
                                                AICAOverviewFeedbackType.NEGATIVE,
                                            )
                                        }
                                    >
                                        <img src={LIKE_ICON} alt="Dislike" />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    <div className={styles.OverviewNote}>
                        AICA can make mistakes. Check important info.
                    </div>
                </div>
            </div>
            {!isPDFReport && (
                <div className={showMore ? styles.Footer : styles.FooterAbs}>
                    <button onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'See less' : 'See more'}
                        <img
                            src={arrowDownPurple}
                            style={showMore ? { transform: 'rotate(180deg)' } : {}}
                            className={styles.arrow}
                            alt="See More"
                            height={8}
                            width={13}
                        />
                    </button>
                </div>
            )}
        </div>
    );
};

export default AicaOverviewBody;
