import clsx from 'clsx';
import styles from './TypeSelector.module.scss';

function TypeSelector({ options = [], selectedOptionId, changeSelectedOption }: any) {
    return (
        <div className={styles.TypeSelector}>
            {options.map((option: any) => (
                <div
                    className={clsx(styles.Option, {
                        [styles.SelectedOption]: option.id === selectedOptionId,
                    })}
                    key={option.id}
                    onClick={() => changeSelectedOption(option.id)}
                >
                    {!!option.icon && (
                        <img src={option.icon} alt="" height="16px" className={styles.Icon} />
                    )}
                    {option.label}
                </div>
            ))}
        </div>
    );
}

export default TypeSelector;
