import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styles from '../../GlobalSearch.module.scss';
import CrossIcon from '../../../../assets/Svg/CrossIcon';
import { GlobalSearchIcon } from '../../../../assets/hostedassets';
import { useOutsideClick } from '../../../../utils/constants/hooks';

function SearchBarAndIcon(props: {
    showSearch: boolean;
    onCrossIconClick: () => void;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
    onSearchIconClick: () => void;
}) {
    const inputRef = useRef<HTMLInputElement>(null);
    useOutsideClick(inputRef, () => {
        props.onCrossIconClick();
    });
    useEffect(() => {
        if (props.showSearch) {
            setTimeout(() => {
                inputRef.current?.focus();
            });
        }
    }, [props.showSearch]);
    return (
        <>
            <input
                ref={inputRef}
                value={props.searchText}
                onChange={(e) => props.setSearchText(e.target.value)}
                type="text"
                placeholder="Search by company name or website (min 3 char)"
                className={props.showSearch ? styles.ShowSearch : styles.HideSearch}
                id={'globalSearch'}
            />
            {props.showSearch && (
                <span
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        props.onCrossIconClick();
                    }}
                    className={styles.CrossIcon}
                >
                    <CrossIcon height={'12px'} width={'12px'} colour={'#6021b3'} />
                </span>
            )}
            <img
                className={props.showSearch ? styles.HideSearchIcon : styles.ShowSearchIcon}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    props.onSearchIconClick();
                }}
                src={GlobalSearchIcon}
                alt={'icon'}
            />
        </>
    );
}

export default SearchBarAndIcon;
