import React from 'react';
import styles from './RevenueTables.module.scss';
import RevenueVendorExpenseTable from './RevenueVendorExpenseTable/RevenueVendorExpenseTable';
import Gstr3BTable from './GSTR3BTable/GSTR3BTable';

function RevenueTables({ tab, dataSource, fetchHeadlinePanelData }) {
    return (
        <div className={styles.RevenueTables}>
            {tab === 'revenue' && (
                <Gstr3BTable
                    dataSource={dataSource}
                    fetchHeadlinePanelData={fetchHeadlinePanelData}
                />
            )}
            <RevenueVendorExpenseTable tab={tab} dataSource={dataSource} />
        </div>
    );
}

export default RevenueTables;
