import {
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Bar,
    ResponsiveContainer,
    ComposedChart,
    Legend,
    ReferenceLine,
    Label,
} from 'recharts';
import clsx from 'clsx';
import styles from '../Graph.module.scss';

import CustomTooltip from './CustomTooltip/CustomTooltip';
import { isMobile } from '../../../../../utils/utils';

/**
 * @Description Line graph component for single value for every x-coordinate
 */

interface DashedLineBarGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function DashedLineBarGraph(props: DashedLineBarGraphProps) {
    const { error } = props;
    const { colors, amount, hasNegative, plots, metadata } = props.graphData;
    const graphData = plots[0]?.plotValues || [];
    const data = !error ? amount : [];

    const getBarGap = () => {
        if (graphData.length === 1) return '60%';
        else if (graphData.length > 1 && graphData.length < 8) return '60%';
        else if (graphData.length >= 8) return '40%';
        else return '20%';
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div>
                {payload.reverse().map((entry, index) => (
                    <span key={`item-${index}`}>
                        <div
                            style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: entry.color,
                            }}
                        ></div>
                        {entry.value}
                    </span>
                ))}
            </div>
        );
    };
    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <ComposedChart
                data={graphData}
                margin={{
                    top: 30,
                    right: -15,
                    left: -20,
                    bottom: 5,
                }}
                barCategoryGap={getBarGap()}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="xValue"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    // tickFormatter={(val) => formatPeriod(val)}
                    interval={0}
                />
                <YAxis
                    yAxisId="left"
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    interval={1}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '10px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                {/* <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    // tickFormatter={getYAxisLabel}
                /> */}
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: -20, right: 20 }}
                        // iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                        // content={renderLegend}
                        // payload={data.map(
                        //     (item, index) => ({
                        //         id: item.name,
                        //         type: "square",
                        //         value: `${item.name} (${item.value}%)`,
                        //         color: colors[index % colors.length]
                        //     })
                        // )}
                        payload={[
                            { value: 'yValue', type: 'line', color: colors?.primary },
                            { value: 'yValue2', type: 'circle', color: colors?.secondary },
                            { value: 'yValue3', type: 'circle', color: colors?.tertiary },
                            { value: 'yValue4', type: 'circle', color: colors?.fourth },
                            { value: 'yValue5', type: 'circle', color: colors?.fifth },
                        ]}
                    />
                ) : null}
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {hasNegative ? <ReferenceLine y={0} yAxisId="left" stroke="#000000" /> : null}
                {/* <Legend
                    verticalAlign="top"
                    height={30}
                    align="right"
                    wrapperStyle={{
                        fontSize: isMobile ? '10px' : '14px',
                        width: '100%',
                        top: 0,
                        left: -20,
                    }}
                    iconType="circle"
                /> */}

                <Bar
                    yAxisId="left"
                    dataKey={'yValue2'}
                    fill={colors?.secondary}
                    radius={[4, 4, 4, 4]}
                    stackId="a"
                />
                <Bar
                    yAxisId="left"
                    dataKey={'yValue3'}
                    fill={colors?.tertiary}
                    radius={[4, 4, 4, 4]}
                    stackId="a"
                />
                <Bar
                    yAxisId="left"
                    dataKey={'yValue4'}
                    fill={colors?.fourth}
                    radius={[4, 4, 4, 4]}
                    stackId="a"
                />
                <Bar
                    yAxisId="left"
                    dataKey={'yValue5'}
                    fill={colors?.fifth}
                    radius={[4, 4, 4, 4]}
                    stackId="a"
                />
                <Line
                    yAxisId="left"
                    type="linear"
                    dataKey={'yValue'}
                    stroke={colors?.primary}
                    dot={{ fill: colors?.primary, strokeDasharray: '' }}
                    activeDot={{ fill: 'white', stroke: 'black' }}
                    strokeWidth={2}
                    strokeDasharray="4 4"
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default DashedLineBarGraph;
