import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import {
    AddCompanyToScanPayload,
    AddCompanyToWatchlistPayload,
    addToWatchlistService,
    fetchOrgInfoService,
    fetchOrgUser,
    getAllTaskTrackerDataService,
    getInvestorConfig,
    getOnePagerDataService,
    getOnePagerTemplateDataService,
    inviteCompanyUserService,
    moveCompanyToNewTierService,
    saveOnePagerTemplateForAssesseeService,
    unlockAicaForCompanyService,
    UnlockAICAReportPayload,
} from './service';
import { Handlers } from '../../types/actions/types';
import { FetchInvestorConfigPayload } from './action';

export async function addCompanyToWatchlist(payload: AddCompanyToWatchlistPayload, handlers) {
    try {
        const response = await addToWatchlistService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data);
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Unable to add. Please try again later.');
        }
    } catch (err) {
        handlers?.onError();
        toast.warn('Unable to add. Please try again later.');
    }
}

export async function moveCompanyToNewTier(payload: AddCompanyToScanPayload, handlers: Handlers = {}) {
    try {
        const response = await moveCompanyToNewTierService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
                throw new Error(responseData.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
        throw err;
    }
}

export async function inviteCompanyUser(payload: AddCompanyToScanPayload, handlers: Handlers) {
    try {
        const response = await inviteCompanyUserService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        toast.warn('Unable to add. Please try again later.');
    }
}

export async function unlockOrRefreshReport(payload: UnlockAICAReportPayload, handlers: Handlers) {
    try {
        const response = await unlockAicaForCompanyService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
        toast.warn('Something went wrong');
    }
}

export async function fetchConfigByConfigName(payload: FetchInvestorConfigPayload) {
    try {
        const response = await getInvestorConfig(payload);
        const responseData = response.data?.responseData;

        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response.data?.mainConfig;
            case JAVA_API_STATUS.ERROR:
            default:
                return null;
        }
    } catch (err) {
        console.error('Error in fetching config: ', payload.configName, '\nError: ', err);
    }
}

export async function fetchUserFromOrgId(orgId: string, accountType: string, noToast = false) {
    try {
        const response = await fetchOrgUser({ orgId, accountType });
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response.data?.userDetails;
            case JAVA_API_STATUS.ERROR:
                if (!noToast) toast.error(responseData?.responseMessage || 'Some error occoured');
            default:
                return null;
        }
    } catch (err) {
        console.error('Error in fetching user for orgId: ', orgId, ' accountType: ', accountType, '\nError: ', err);
    }
}

export async function getAllTaskTrackerData(payload: { orgId: string }, handlers: Handlers) {
    try {
        const response = await getAllTaskTrackerDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response.data?.responseBody?.taskTrackerData);
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
    }
}

//ONE PAGER

export async function getOnePagerData(payload: { orgId: string; startDate: string; endDate: string }) {
    try {
        const response = await getOnePagerDataService(payload);
        const responseData = response?.data?.responseData;
        if (responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response?.data?.responseBody || {};
        } else {
            throw new Error('Error in fetching one pager data: ' + JSON.stringify(responseData || {}));
        }
    } catch (error) {
        console.error('Error in fetching one pager data', error);
        return {};
    }
}

export async function getOnePagerTemplateData(payload: { assesseeOrgId: string; investorOrgId: string }) {
    try {
        const response = await getOnePagerTemplateDataService(payload);
        const responseData = response?.data?.responseData;
        if (responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return {
                onePagerData: response?.data || {},
                templateData: response?.data?.onePagerData?.onePagerData || [],
            };
        } else {
            throw new Error('Error in fetching one pager template: ' + JSON.stringify(responseData || {}));
        }
    } catch (error) {
        console.error('Error in fetching one pager template', error);
        return { onePagerData: {}, templateData: [] };
    }
}

export async function saveOnePagerTemplateData(payload: {
    assesseeOrgId: string;
    investorOrgId: string;
    onePagerData: Object[];
    email: string;
}) {
    try {
        const response = await saveOnePagerTemplateForAssesseeService(payload);
        const responseData = response?.data?.responseData;
        if (responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response?.data?.responseBody || {};
        } else {
            throw new Error('Error in saving one pager template: ' + JSON.stringify(responseData || {}));
        }
    } catch (error) {
        console.error('Error in saving one pager template', error);
        return {};
    }
}

export async function fetchOrgInfoById(payload: { orgId: string }) {
    try {
        const response = await fetchOrgInfoService(payload);
        const responseData = response?.data?.responseData;
        if (responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response?.data?.orgInfo || {};
        } else {
            throw new Error('Error in fetching org info by id: ' + JSON.stringify(responseData || {}));
        }
    } catch (error) {
        console.error('Error in fetching org info by id ', error);
        return {};
    }
}
