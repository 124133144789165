import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import { fetchDebtDetailsDataService, fetchDebtDocsService } from './debtDetails_service';

export async function fetchDebtDetailsData(payload) {
    try {
        const response = await fetchDebtDetailsDataService(payload);
        const responseData = response.data?.responseData;
        const debtScheduleData = response?.data?.responseBody?.debtSchedule || {};
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return {
                    rows: debtScheduleData?.data?.rows || debtScheduleData?.data?.data?.rows || [],
                    totals:
                        debtScheduleData?.data?.totals ||
                        debtScheduleData?.data?.data?.totals ||
                        {},
                    updatedAt:
                        debtScheduleData?.updatedAt || debtScheduleData?.data?.updatedAt || null,
                };
            case JAVA_API_STATUS.ERROR:
                console.error('Error in fetching debt details data', response);
                return null;
        }
    } catch (err) {
        console.error('Error in fetching debt details data', err);
        return null;
    }
}

export async function fetchDebtDocsData(payload) {
    try {
        const response = await fetchDebtDocsService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return {
                    debtDocsAica: response?.data?.debtDocsAica || [],
                    debtDocsRrf: response?.data?.debtDocsDataRoom?.docData || [],
                };
            case JAVA_API_STATUS.ERROR:
                console.error('Error in fetching debt details data', response);
                return null;
        }
    } catch (err) {
        console.error('Error in fetching debt details data', err);
        return null;
    }
}
