import axios from 'axios';
import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { ORG_MASTER_STATUS, USER_TYPE } from '../../utils/constants/user';
import { history } from '../../utils/utils';
import { BASE_ROUTES, COMPANY_ROUTES, INVESTOR_ROUTES } from '../../utils/constants/routesConst';
import { addTokenToHeaders, JAVA_API_STATUS, SOMETHING_WRONG } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import { Mixpanel } from '../../utils/mixpanel';

const call = Effects.call;
const put = Effects.put;
const takeLatest = Effects.takeLatest;

function handleRedirection(accountType, orgMasterStatus) {
    if (accountType === USER_TYPE.INVESTOR)
        history.navigate(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.SCAN}`);
    else if (accountType === USER_TYPE.COMPANY) {
        if (orgMasterStatus !== ORG_MASTER_STATUS.ADDITIONAL_DETAIL_COMPLETE)
            history.navigate(BASE_ROUTES.ADDITIONAL_DETAILS);
        else history.navigate(COMPANY_ROUTES.UPLOAD);
    }
}

function getUserDataObject(actionPayload, responseData) {
    return {
        accountType: actionPayload?.accountType,
        investorOrgId: actionPayload?.investorOrgId,
        primaryEmail: actionPayload?.primaryEmail,
        // aicaCoins: response.data?.aicaCoins,
        userId: responseData?.userId,
        userName: responseData?.userName,
        assesseeOrgId: responseData?.assesseeOrgId,
        investorPrimaryEmail: responseData?.investorPrimaryEmail,
        investorName: responseData?.investorName,
        applicationStatus: responseData?.status,
    };
}

function* fetchDomainMetadata(action) {
    try {
        yield put({
            type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA,
            payload: true,
        });
        const response = yield call(apiService.fetchDomainMetadataService, action.payload);
        const responseData = response?.data?.responseData;

        const investorOrgId = response?.data?.investorOrgId;
        investorOrgId && Mixpanel.identify(investorOrgId);
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_DOMAIN_METADATA_SUCCESS,
                    payload: response?.data?.domainData || {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* createAccount(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_CREATING_ACCOUNT,
            payload: true,
        });
        const response = yield call(apiService.createAccountService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.CREATE_ACCOUNT_SUCCESS,
                    payload: response?.data || {},
                });
                action.handlers?.onSuccess?.(response?.data?.signupToken);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_CREATING_ACCOUNT,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_CREATING_ACCOUNT,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* saveApplicationDetails(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_SAVING_APPLICATION_DETAILS,
            payload: true,
        });
        const accountType = action.payload.orgAdditionalDetails?.accountType;
        const response = yield call(apiService.saveApplicationDetails, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.SET_IS_SAVING_APPLICATION_DETAILS,
                    payload: false,
                });
                yield put({
                    type: CONSTANTS.SET_APPLICATION_STATUS,
                    payload: ORG_MASTER_STATUS.ADDITIONAL_DETAIL_COMPLETE,
                });
                if (accountType === USER_TYPE.COMPANY) history.navigate(COMPANY_ROUTES.UPLOAD);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_SAVING_APPLICATION_DETAILS,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_SAVING_APPLICATION_DETAILS,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* generateOtp(action) {
    try {
        yield put({
            type: CONSTANTS.SET_GENERATING_OTP,
            payload: true,
        });
        const response = yield call(apiService.generateOtpService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GENERATE_OTP_SUCCESS,
                    payload: {},
                });
                action.payload?.onSuccess?.();
                toast.success('OTP sent to email');
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_GENERATING_OTP,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_GENERATING_OTP,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    } finally {
        action.payload?.onComplete?.();
    }
}

function* verifyOtp(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: true,
        });
        const accountType = action.payload.accountType;
        const response = yield call(apiService.verifyOtpService, action.payload);
        const responseData = response.data?.responseData;
        const orgMasterStatus = response.data?.status;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.investorOrgId,
                    response.data.authToken,
                    response.data.userId,
                );
                // const userData = {
                //     accountType: action.payload.accountType,
                //     investorOrgId: action.payload.investorOrgId,
                //     primaryEmail: action.payload.primaryEmail,
                //     // aicaCoins: response.data?.aicaCoins,
                //     userId: response.data?.userId,
                //     userName: response.data?.userName,
                //     assesseeOrgId: response.data?.assesseeOrgId,
                //     investorPrimaryEmail: response.data?.investorPrimaryEmail,
                //     applicationStatus: orgMasterStatus,
                // };
                const userData = getUserDataObject(action?.payload, response?.data);
                yield put({
                    type: CONSTANTS.VERIFY_OTP_SUCCESS,
                    payload: {
                        data: {},
                        userData: userData,
                    },
                });
                localStorage.setItem('user_data', JSON.stringify(userData));
                handleRedirection(accountType, orgMasterStatus);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* loginWithOtp(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: true,
        });
        const accountType = action.payload.accountType;
        const response = yield call(apiService.loginWithOtp, action.payload);
        const responseData = response.data?.responseData;
        const orgMasterStatus = response.data?.status;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.investorOrgId,
                    response.data.authToken,
                    response.data.userId,
                );
                // const userData = {
                //     accountType: action.payload.accountType,
                //     investorOrgId: action.payload.investorOrgId,
                //     primaryEmail: action.payload.primaryEmail,
                //     // aicaCoins: response.data?.aicaCoins,
                //     userId: response.data?.userId,
                //     userName: response.data?.userName,
                //     assesseeOrgId: response.data?.assesseeOrgId,
                //     investorPrimaryEmail: response.data?.investorPrimaryEmail,
                //     applicationStatus: orgMasterStatus,
                // };
                const userData = getUserDataObject(action?.payload, response?.data);
                localStorage.setItem('user_data', JSON.stringify(userData));
                yield put({
                    type: CONSTANTS.VERIFY_OTP_SUCCESS,
                    payload: {
                        data: {},
                        userData: userData,
                    },
                });
                handleRedirection(accountType, orgMasterStatus);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* loginWithPassword(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: true,
        });
        const accountType = action.payload.accountType;
        const response = yield call(apiService.loginWithPassword, action.payload);
        const responseData = response?.data?.responseData;
        const orgMasterStatus = response?.data?.status;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.investorOrgId,
                    response.data.authToken,
                    response.data.userId,
                );
                // toast.success('Successfully Logged In');
                // const userData = {
                //     accountType: action.payload.accountType,
                //     investorOrgId: action.payload.investorOrgId,
                //     primaryEmail: action.payload.primaryEmail,
                //     // aicaCoins: response.data?.aicaCoins,
                //     userId: response.data?.userId,
                //     userName: response.data?.userName,
                //     assesseeOrgId: response.data?.assesseeOrgId,
                //     investorPrimaryEmail: response.data?.investorPrimaryEmail,
                //     applicationStatus: orgMasterStatus,
                // };
                const userData = getUserDataObject(action?.payload, response?.data);
                localStorage.setItem('user_data', JSON.stringify(userData));
                yield put({
                    type: CONSTANTS.LOGIN_WITH_PASSWORD_SUCCESS,
                    payload: {
                        data: response.data,
                        userData: userData,
                    },
                });
                handleRedirection(accountType, orgMasterStatus);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    } finally {
        action.payload?.onComplete?.();
    }
}

function* forgotPassword(action) {
    try {
        const response = yield call(apiService.forgotPasswordService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success('Password reset link sent. Please check your mail.');
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* updatePassword(action) {
    try {
        const response = yield call(apiService.updatePasswordService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success(responseData?.responseMessage || 'Password successfully reset');
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchSummonAicaData(action) {
    try {
        const response = yield call(apiService.getSummonAicaData, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response?.data?.data);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.handlers?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchInternalSummonAicaData(action) {
    try {
        const response = yield call(apiService.getInternalSummonAicaData, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS: {
                const userData = {
                    orgId: action.payload.orgId,
                    parentOrgId: action.payload.parentOrgId,
                    primaryEmail: response.data.user.emailId,
                    parentEmailId: action.payload.parentEmailId,
                    accountType: response.data.user.accountType,
                    userId: response.data.user.userId,
                    userName: response.data.user.name,
                    authToken: response.data.user.authToken,
                    userRole: response.data.user.roles?.[0] ?? '',
                    inviteCode: response.data.user.inviteCode,
                    platformUserId: response.data.user.platformUserId,
                };
                action.handlers?.onSuccess?.(userData, response.data.magicLink);
                break;
            }
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_DOMAIN_METADATA, fetchDomainMetadata);
    yield takeLatest(CONSTANTS.CREATE_ACCOUNT, createAccount);
    yield takeLatest(CONSTANTS.SAVE_APPLICATION_DETAILS, saveApplicationDetails);
    yield takeLatest(CONSTANTS.LOGIN_WITH_PASSWORD, loginWithPassword);
    yield takeLatest(CONSTANTS.GENERATE_OTP, generateOtp);
    yield takeLatest(CONSTANTS.VERIFY_OTP, verifyOtp);
    yield takeLatest(CONSTANTS.LOGIN_WITH_OTP, loginWithOtp);
    yield takeLatest(CONSTANTS.FORGOT_PASSWORD, forgotPassword);
    yield takeLatest(CONSTANTS.UPDATE_PASSWORD, updatePassword);
    yield takeLatest(CONSTANTS.FETCH_SUMMON_AICA_DATA, fetchSummonAicaData);
    yield takeLatest(CONSTANTS.FETCH_INTERNAL_SUMMON_AICA_DATA, fetchInternalSummonAicaData);
}
