import clsx from 'clsx';
import styles from './YearMonthTable.module.scss';

interface YearMonthTableProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function YearMonthTable(props: YearMonthTableProps) {
    const { graphData } = props;
    const plotValues = graphData?.plots?.[0]?.yearMonthMap || [];
    const data = plotValues;
    const years = Array.from(new Set(Object.keys(data).map((date) => date.split('-')[0])));
    return (
        <div className={styles.YearMonthTable} style={{ height: props.height }}>
            <div className={styles.Headers}>
                <div className={styles.Header}>Year</div>
                {headerMonths.map((month) => (
                    <div className={styles.Header} key={month}>
                        {getMonthName(Number.parseInt(month))}
                    </div>
                ))}
            </div>

            <div className={styles.Rows}>
                {years.map((year) => (
                    <div className={styles.Row} key={year}>
                        <div className={styles.Data}>{year}</div>
                        {headerMonths.map((month) => (
                            <div className={styles.Data}>
                                <div
                                    className={clsx(styles.Badge, {
                                        [styles.Empty]: !data[`${year}-${month}`],
                                    })}
                                >
                                    {data[`${year}-${month}`] || '-'}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date
        .toLocaleString('en-US', {
            month: 'long',
        })
        ?.substring(0, 3);
}

export default YearMonthTable;
const headerMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
