import React, { useEffect } from 'react';
import Folder from '../Folder/Folder';
import File from '../File/File';
import styles from './FileTree.module.scss';
import { FileNode } from '../../../../DataRoom';
import { convertEpochToDate } from '../../../../../../utils/utils';

interface FileTreeProps {
    data: FileNode[];
    index: number;
    masterFolderName?: string;
    fetchFiles: Function;
    // isRecurCompany: boolean;
}

const FileTree: React.FC<FileTreeProps> = ({
    data,
    index,
    masterFolderName,
    fetchFiles,
    // isRecurCompany,
}) => {
    return (
        <div className={styles.fileTree}>
            {data.map((item) =>
                item.type === 'folder' ? (
                    <Folder
                        // isRecurCompany={isRecurCompany}
                        fetchFiles={fetchFiles}
                        index={index + 1}
                        key={item.name}
                        name={item.name}
                        innerFiles={item.innerFiles ?? []}
                        lastUpdated={item.updatedAt ? convertEpochToDate(item.updatedAt) : '-'}
                        parentFolderName={masterFolderName ?? ''}
                        isNew={item.isNew}
                    />
                ) : (
                    <File
                        // isRecurCompany={isRecurCompany}
                        fetchFiles={fetchFiles}
                        masterFolderName={masterFolderName ?? ''}
                        docId={item.docId ?? null}
                        index={index + 1}
                        key={item.name}
                        name={item.name}
                        lastUpdated={item.updatedAt ? convertEpochToDate(item.updatedAt) : '-'}
                        isNew={item.isNew}
                    />
                ),
            )}
        </div>
    );
};

export default FileTree;
