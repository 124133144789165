import yellowStars from '../../../../../../assets/yellow-stars.svg';
import styles from './AIBadge.module.scss';

const aiGenIcon = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/ai-gen-icon.svg';

function AIBadge({ alternateText }: { alternateText?: string }) {
    return (
        <div className={styles.AIBadge}>
            <img src={aiGenIcon} alt="" className={styles.Img} />
            <div>{alternateText ? alternateText : 'AI Generated'}</div>
        </div>
    );
}

export default AIBadge;
