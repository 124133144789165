import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView, InView } from 'react-intersection-observer';
import { ShimmerText, ShimmerThumbnail } from 'react-shimmer-effects';
import { isMobile } from '../../../../utils/utils';
import { useAppDispatch } from '../../../../app/hooks';
import { DATA_FETCH_STATUS, GRAPH_TYPES } from '../../../../store/dashboard/constants';
import {
    LineGraph,
    StackedBarGraph,
    WaterfallGraph,
    LineBarGraph,
    BrushGraph,
    BarGraph,
    PieGraph,
    PieGraphCustomTooltip,
    VerticalBarGraph,
    IndiaCustomMap,
    LineGraphWithArea,
} from './components';
import Tooltip from '../../../../common/_custom/Tooltip/Tooltip';

import infoIcon from '../../../../assets/info.svg';
import infoBlackIcon from '../../../../assets/info-black.svg';
import styles from './Graph.module.scss';
import { useUpdateEffect } from '../../../../utils/customHooks';
import trendUp from '../../../../assets/trend-up.svg';
import trendDown from '../../../../assets/trend-down.svg';
import { fetchGraphData, resetIsFetched } from '../../../../store/dashboard/actions';
import MonthlyView from './components/MonthlyView';
import { initializeDatepickerDates } from '../../../../utils/dateUtils';
import { PortfolioCompanyRow } from '../../../../store/investor/reducer';
import YearMonthTable from './components/YearMonthTable/YearMonthTable';
import DashedLineBarGraph from './components/DashedLineBarGraph';
import generating from '../../../../assets/audited-financials-icon.svg';
import loader from '../../../../assets/aica-loader.svg';
import clsx from 'clsx';

interface GraphProps {
    graphData: any;
    graphName: string;
    selectedPeriod: any;
    user: any;
    showAlerts: boolean;
    horizontalChart?: boolean;
    fetchGraph: Function;
    initialiseHeadlinePanelPolling?: Function;
    isPDFReport?: boolean;
}

function Graph(props: GraphProps) {
    const { isLoading, isFetched, incompleteData, metadata, plots, fetchError, alert } =
        props.graphData;

    const graphDataExists = (plots?.[0]?.plotValues || [])?.length || plots?.[0]?.yearMonthMap;
    const isDataGenerationInProgess =
        metadata?.status === DATA_FETCH_STATUS.PROCESSING && !props.isPDFReport;
    const errorMessage = metadata?.customErrorMessage;
    const {
        graphName,
        user,
        selectedPeriod,
        fetchGraph,
        horizontalChart = false,
        initialiseHeadlinePanelPolling,
        isPDFReport = false,
    } = props;
    const { ref, inView } = useInView({ threshold: 0 });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const alertRef = useRef(null);
    const [error, setError] = useState(false);
    let pollingIntervalRef: any = useRef(null);

    useEffect(() => {
        if (isPDFReport) {
            fetchGraphsInView(true, true);
        }
    }, [isPDFReport]);

    useEffect(() => {
        if (true && !isFetched) {
            fetchGraphsInView(inView, true);
        }
    }, [user.infoFetched, isFetched]);

    useUpdateEffect(() => {
        dispatch(resetIsFetched(props.graphName));
        fetchGraphsInView(inView, false);
    }, [selectedPeriod]);

    useEffect(() => {
        if (isFetched && metadata?.status === DATA_FETCH_STATUS.PROCESSING) {
            initialiseHeadlinePanelPolling?.();
            pollingIntervalRef.current = setInterval(() => {
                fetchGraphsInView(inView, false, true);
            }, 5000);
        } else {
            clearInterval(pollingIntervalRef?.current);
        }

        return () => {
            if (pollingIntervalRef?.current) clearInterval(pollingIntervalRef?.current);
        };
    }, [isFetched, metadata?.status]);

    //** Height Calculation for individual graph **//
    const graphRef = useRef<any>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (graphRef.current) {
            horizontalChart
                ? setHeight((graphRef.current.offsetWidth * 2) / 8)
                : setHeight((graphRef.current.offsetWidth * 2) / 4);
        }
        //eslint-disable-next-line
    }, [graphRef.current, isFetched]);

    const fetchGraphsInView = (inView, fetchOnPeriodChange, isPollingCall = false) => {
        if (
            (inView && fetchOnPeriodChange) ||
            (inView && !isFetched) ||
            (inView && isPollingCall)
        ) {
            fetchGraph(graphName, isPollingCall);
        }
    };

    const renderGraph = () => {
        switch (metadata?.cardType) {
            case GRAPH_TYPES.LINE:
                return (
                    <LineGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.AREA_CHART:
                return (
                    <LineGraphWithArea
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.STACKBAR:
                return (
                    <StackedBarGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.WATERFALL:
                return (
                    <WaterfallGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.LINEBAR:
                return (
                    <LineBarGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.DASHEDLINEBAR:
                return (
                    <DashedLineBarGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.BARCHART:
                return (
                    <BarGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.PIECHART:
                return (
                    <PieGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.PIECHARTCUSTOMTOOLTIP:
                return (
                    <PieGraphCustomTooltip
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.HORIZONTAL_BARCHART:
                return (
                    <VerticalBarGraph
                        height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            case GRAPH_TYPES.MONTHLYVIEW:
                return (
                    <MonthlyView
                        height={height}
                        width={graphRef?.current?.offsetWidth}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                        isPDFReport={isPDFReport}
                    />
                );
            case GRAPH_TYPES.HEATMAP:
                return (
                    <IndiaCustomMap
                        graphData={props.graphData}
                        graphName={graphName}
                        height={height}
                        width={graphRef?.current?.offsetWidth}
                        error={false}
                    />
                );
            case GRAPH_TYPES.YEAR_MONTH_TABLE:
                return (
                    <YearMonthTable
                        height={height}
                        // height={height}
                        error={error}
                        graphData={props.graphData}
                        graphName={graphName}
                    />
                );
            default:
                return <></>;
        }
    };

    const Loader = () => {
        return (
            <div ref={graphRef}>
                <React.Fragment>
                    <ShimmerText line={2} gap={10} />
                    <ShimmerThumbnail height={0.94 * height} rounded />
                </React.Fragment>
            </div>
        );
    };

    const generatingCard = () => {
        return (
            <div className={styles.GeneratingData} style={{ height: height }}>
                <img src={generating} width="100px" alt="" />
                Fetching {metadata?.title}
                <img src={loader} alt="" width="50px" />
            </div>
        );
    };

    const navigateToFinancials = () => {
        props.graphData.linkToFinancials && navigate(props.graphData.linkToFinancials);
    };

    return (
        <InView
            as="div"
            onChange={(inView) => {
                fetchGraphsInView(inView, false);
            }}
            style={{ height: '100%' }}
        >
            <div className={styles.Graph} ref={ref}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className={clsx(styles.GraphBody, { [styles.Animate]: !isPDFReport })}>
                        <div className={styles.GraphHeader}>
                            <div
                                className={styles.Info}
                                style={{
                                    ...(isPDFReport && {
                                        transform: 'scale(0.7)',
                                        transformOrigin: 'left top',
                                    }),
                                }}
                            >
                                <div className={styles.Label}>
                                    {metadata?.title}

                                    {!!metadata?.infoText && (
                                        <Tooltip content={metadata?.infoText} direction="right">
                                            <img src={infoIcon} alt="score" height="14px" />
                                        </Tooltip>
                                    )}
                                </div>

                                <div className={styles.InfoRow}>
                                    {!!metadata?.primaryText && (
                                        <div className={styles.Value}>{metadata?.primaryText}</div>
                                    )}
                                    <div
                                        className={styles.Date}
                                        style={{
                                            marginLeft: !metadata?.primaryText ? '0' : '',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {metadata?.secondaryText
                                            ? metadata?.secondaryText
                                            : isDataGenerationInProgess
                                            ? ''
                                            : !graphDataExists
                                            ? 'Insufficient Data'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            ref={graphRef}
                            className={`${styles.SingleGraphContainer} ${
                                metadata?.cardType === GRAPH_TYPES.YEAR_MONTH_TABLE &&
                                styles.OverflowY
                            }`}
                            style={{ marginTop: isPDFReport ? '0' : '' }}
                        >
                            {fetchError ? (
                                <div className={styles.FetchError} style={{ height: height }}>
                                    <div
                                        className={styles.FetchErrorBtn}
                                        onClick={() => fetchGraph()}
                                    >
                                        Retry Graph Fetch
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {isDataGenerationInProgess ? (
                                        <>{generatingCard()}</>
                                    ) : (
                                        <>{renderGraph()}</>
                                    )}

                                    {/* {props.showAlerts && (
                                        <div className={styles.Alert} ref={alertRef}>
                                            {!!alert ? (
                                                <>
                                                    <img
                                                        src={
                                                            alert?.trendIndicator === 'Up'
                                                                ? trendUp
                                                                : trendDown
                                                        }
                                                        alt="trend"
                                                        height={'20px'}
                                                    />
                                                    <div className={styles.Text}>
                                                        {alert?.text ?? ''}
                                                    </div>
                                                    <div className={styles.Date}>
                                                        {alert?.date ?? '-'}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src={infoBlackIcon}
                                                        alt="trend"
                                                        height={'20px'}
                                                    />
                                                    <div className={styles.Text}>
                                                        No alerts detected for this graph
                                                    </div>
                                                    <div className={styles.Date}></div>
                                                </>
                                            )}
                                        </div>
                                    )} */}

                                    {!graphDataExists &&
                                    metadata?.status !== DATA_FETCH_STATUS.PROCESSING ? (
                                        errorMessage ? (
                                            <div className={styles.DisabledGraph}>
                                                <div className={styles.DisabledGraphMessage}>
                                                    <span className={styles.Link}>
                                                        {errorMessage}
                                                    </span>
                                                    {/* <span>&nbsp; to view {metadata?.title}</span> */}
                                                </div>
                                            </div>
                                        ) : !fetchError ? (
                                            <div className={styles.DisabledGraph}>
                                                <div className={styles.DisabledGraphMessage}>
                                                    <span className={styles.Link}>
                                                        Insufficient Data Points
                                                    </span>
                                                    {/* <span>&nbsp; to view {metadata?.title}</span> */}
                                                </div>
                                            </div>
                                        ) : null
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </InView>
    );
}

export default Graph;
