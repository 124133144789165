import clsx from 'clsx';
import React from 'react';
import { USER_TYPE } from '../../../../../utils/constants/user';
import CategorySelector from '../../../../../common/CategorySelector/CategorySelector';
import DealsTable from './DealsTable/DealsTable';
import EmptyDeals from '../EmptyDeals/EmptyDeals';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import SearchBox from '../../../Portfolio/components/CompaniesList/components/SearchBox/SearchBox';
import styles from './DealsList.module.scss';
import { useDealsList } from './useDealsList';
import { Button } from '../../../../../common/_custom';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { handleDealApplicationDrawer, setDealDrawer, setDealFilterDrawer } from '../../../../../store/investor/action';
import { debtProdFilters } from '../../../../../assets/hostedassets';
import { checkIfAnyFilterSelected } from '../../../../../utils/utils';
import { useParams } from 'react-router-dom';
// import DealFiltersDrawer from '../../../../../common/DealFiltersDrawer/DealFiltersDrawer';

const DealsList: React.FC<any> = ({ selectedCompany }) => {
    const {
        showDealDrawer,
        dealDrawerState,
        fetchingDeals,
        dealsData,
        userType,
        displayedOrgList,
        selectedCategory,
        accessTierParam,
        LENGTH,
        CATEGORY_OPTIONS,
        setScrollPos,
        getTotalCount,
        setSelectedCategory,
        changePageNumber,
        handleSearch,
        handleOrgSelect,
        redirectToPreferences,
    } = useDealsList();

    const LoadingTableData = <LoadingSpinner color="var(--primary-text-colour)" height="40px" />;

    const dispatch = useAppDispatch();
    const params = useParams();
    const isLender = userType === USER_TYPE.LENDER;
    const isCompanyDashboardDealsPage = !!params.id;
    const dealFilters = useAppSelector((state) => state.investor.dealFilters);
    const NoResultComponent = (
        <EmptyDeals
            handleClick={redirectToPreferences}
            selectedCompany={selectedCompany}
            filterApplied={checkIfAnyFilterSelected(dealFilters)}
        />
    );

    return (
        <div className={styles.DealsList}>
            <div className={styles.Top}>
                <div className={styles.LeftSection}>
                    <div className={styles.SectionTitle}>Deals</div>
                    {/* <CategorySelector
                        categoryOptions={CATEGORY_OPTIONS}  
                        selectedCategory={selectedCategory}
                        changeSelectedTab={setSelectedCategory}
                    /> */}
                </div>
                <div className={styles.RightSection}>
                    {userType !== USER_TYPE.LENDER && !isCompanyDashboardDealsPage && (
                        <SearchBox
                            onSearch={handleSearch}
                            categoryParam={''}
                            location={location}
                            accessTierParam={accessTierParam || ''}
                            showDropdown={true}
                            dropdownOptions={displayedOrgList.map((org) => ({
                                id: org.assesseeOrgId,
                                label: org.orgName,
                            }))}
                            onOptionSelect={handleOrgSelect}
                        />
                    )}
                    {userType !== USER_TYPE.LENDER && (
                        <Button
                            text={checkIfAnyFilterSelected(dealFilters) ? 'Filters Applied' : 'Add Filters'}
                            onClick={() => {
                                dispatch(
                                    handleDealApplicationDrawer({
                                        open: true,
                                        source: 'DEAL_FILTERS',
                                        heading: 'Filters',
                                        subHeading: '',
                                        width: '520px',
                                    }),
                                );
                            }}
                            icon={debtProdFilters}
                            style={{
                                marginTop: 0,
                                ...(!checkIfAnyFilterSelected(dealFilters)
                                    ? {}
                                    : {
                                          background: '#F2E8FF',
                                          border: '1px solid transparent',
                                      }),
                            }}
                            variant={'purpleinverted'}
                        />
                    )}
                </div>
            </div>
            <div style={isLender ? { height: 'calc(100vh - 18.5rem)' } : {}} className={styles.TableContainer}>
                <DealsTable
                    isLoading={fetchingDeals}
                    emptyScreenComponent={NoResultComponent}
                    loadingComponent={LoadingTableData}
                    setScrollPos={setScrollPos}
                />

                <div className={styles.PageNumber}>
                    <div>
                        {`Showing ${getTotalCount() > 0 && dealsData?.pageNumber > 0 ? LENGTH * (dealsData.pageNumber - 1) + 1 : 0} - ${
                            getTotalCount() > 0 && dealsData?.pageNumber > 0 ? Math.min(LENGTH * dealsData.pageNumber, getTotalCount()) : 0
                        } of ${getTotalCount() || 0}`}
                    </div>

                    <div>
                        <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/poweredByAica.svg" alt="" width={'150px'} />
                    </div>
                    <div className={styles.Buttons}>
                        <div
                            className={clsx(styles.Btn, {
                                [styles.Disabled]: !(dealsData.pageNumber > 1),
                            })}
                            onClick={() => changePageNumber(dealsData.pageNumber - 1)}
                        >
                            Previous
                        </div>
                        <div
                            className={clsx(styles.Btn, {
                                [styles.Disabled]: !dealsData?.hasNextPage,
                            })}
                            onClick={() => changePageNumber(dealsData.pageNumber + 1)}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DealsList;
