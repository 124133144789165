export const ROW_NAMES = {
    UNCLASSIFIED: 'Unclassified (INR)',
    FINANCING_INFLOW: 'Financing Inflow (INR)',
    INVESTING: 'Investing (INR)',
    INTRA_COMPANY: 'Intra Company (INR)',
    OPERATING: 'Operating (INR)',
    FINANCING_OUTFLOW: 'Financing Outflow (INR)',
    OPENING_BALANCE: 'Opening Balance (INR)',
    NET_CASH_FLOW: 'Net Cash Flow (INR)',
    CLOSING_BALANCE: 'Closing Balance (INR)',
};
