import { useEffect, useRef, useState } from 'react';
import styles from './LegalCases.module.scss';
import { fetchLegalCasesData } from '../../../../../store/dashboard/tables/tables_apiHelpers';
import { getTimeStamp } from '../../../../../utils/dateUtils';
import { HEADERS, LegalCasesData, headers_filedAgainst, headers_filedBy } from './legalCasesConstants';
import greenTick from '../../../../../assets/green-tick.svg';
import clsx from 'clsx';
import { convertToFirstLetterCapital, decodeHtmlEntities } from '../../../../../utils/utils';
import generating from '../../../../../assets/audited-financials-icon.svg';
import loader from '../../../../../assets/aica-loader.svg';
import { DATA_FETCH_STATUS } from '../../../../../store/dashboard/constants';
import { downloadIconDataRoom, downloadIconDataRoomHovered } from '../../../../../assets/hostedassets';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { useAppSelector } from '../../../../../app/hooks';
import { useParams } from 'react-router-dom';
import { getFolderStructure } from '../../../../../store/dataRoom/apiHelper';
import { toast } from 'react-toastify';
import RiskIndicators from '../../../../RiskIndicators/RiskIndicators';
import A4Page from '../../../../AicaPDFReport/A4Page/A4Page';
import { REPORT_SECTION_NAMES } from '../../../../../store/pdfReport/pdfReport_constants';
import AicaOverview from '../../../../../common/AicaOverview/AicaOverview';
import { TabModule } from '../../../../../utils/constants/commonConstants';

const STATUS = {
    DISPOSED: 'Disposed',
    PENDING: 'Pending',
    NOT_AVAILABLE: 'Not Available',
};

function LegalCases({
    assesseeOrgId,
    companyName,
    initialiseHeadlinePanelPolling,
    isPDFReport = false,
}: {
    assesseeOrgId: string;
    companyName: string;
    initialiseHeadlinePanelPolling?: Function;
    isPDFReport?: boolean;
}) {
    const [legalCasesData, setLegalCasesData] = useState<null | LegalCasesData>();
    const [hoveredIndex, setHoveredIndex] = useState<string | null>(null);
    const legalCasesByCompanyOrDirector = legalCasesData?.legalCasesFiledByCompanyOrDirector || [];
    const legalCasesAgainstCompanyOrDirector = legalCasesData?.legalCasesFiledAgainstCompanyOrDirector || [];
    const lastFetchedDate = legalCasesData?.lastFetchedDate || null;
    let pollingIntervalRef: any = useRef(null);
    const isSoleProp = useAppSelector((state) => state.investor?.selectedCompany?.isSoleProp);
    const userData = useAppSelector((state) => state.user.userData);
    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);
    const params = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (modulePollingList.includes(TabModule.LEGAL_CHECKS)) {
            fetchData();
        }
    }, [modulePollingList]);

    useEffect(() => {
        if (legalCasesData?.status && legalCasesData?.status === DATA_FETCH_STATUS.PROCESSING) {
            initialiseHeadlinePanelPolling?.();
            pollingIntervalRef.current = setInterval(() => {
                fetchData();
            }, 5000);
        } else {
            clearInterval(pollingIntervalRef?.current);
        }

        return () => {
            clearInterval(pollingIntervalRef?.current);
        };
    }, [legalCasesData?.status]);

    const fetchData = async () => {
        await fetchLegalCasesData(
            {
                assesseeOrgId: assesseeOrgId,
                requestId: getTimeStamp(),
            },
            {
                onSuccess: (data) => {
                    setLegalCasesData(data);
                },
                onError: () => {},
            },
        );
    };

    const getStatusText = (status: string) => {
        switch (status) {
            case STATUS.DISPOSED:
                return 'CLOSED';
            case STATUS.PENDING:
                return 'OPEN';
            case STATUS.NOT_AVAILABLE:
                return '-';
        }
    };

    const downloadCaseFunction = async (docIds, name, caseType) => {
        const fileNodes = docIds.map((docId) => ({
            name: null,
            type: 'file',
            innerFiles: [],
            docId: docId,
            updatedAt: Date.now(),
        }));
        const payload = {
            parentPath: 'CaseFiles',
            requestId: getTimeStamp(),
            fileNodes: fileNodes,
        };
        Mixpanel.track('AICA_downloadCase_Details', {
            parentId: userData?.investorOrgId,
            websiteUrl: window.location.href,
            userId: userData?.userId,
            userRole: userData?.accountType,
            caseType: caseType,
            companyOrgId: params.id,
        });
        const res = await getFolderStructure(payload);
        if (res) {
            let blob = new Blob([res], { type: 'octet/stream' });
            let fileName = `${name}.zip`;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            toast.success('Folder Downloaded Successfully');
        } else {
            toast.error('Error in fetching folder');
        }
    };

    const renderRiskIndicators = () => <RiskIndicators isPDFReport={isPDFReport} />;

    const renderLegalCasesAgainstCompanyOrDirector = () => (
        <div className={clsx(styles.Container, { [styles.PDFReportContainer]: isPDFReport })}>
            <div className={styles.HeaderContainer}>
                <div className={styles.Title}>Legal cases filed against Company / Director</div>
                {lastFetchedDate != null ? <div className={styles.UpdateDate}>Last Updated on {lastFetchedDate}</div> : null}
            </div>

            <div className={styles.TableWrapper}>
                <table className={styles.Table}>
                    <thead className={styles.TableHead}>
                        <tr className={styles.TableHeadRow}>
                            {headers_filedAgainst.map((header, i) => (
                                <th
                                    key={header.key}
                                    className={styles.TableHeadRowItem}
                                    style={{
                                        whiteSpace: isPDFReport ? 'normal' : 'nowrap',
                                        padding: '0 10px',
                                    }}
                                >
                                    {header.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.TableBody} style={{ fontSize: isPDFReport ? '11px' : '' }}>
                        {legalCasesAgainstCompanyOrDirector?.length ? (
                            legalCasesAgainstCompanyOrDirector?.map((legalCase, i) =>
                                legalCase?.cases?.length ? (
                                    (isPDFReport ? legalCase?.cases?.slice(0, 10) : legalCase?.cases)?.map((singleCase, j) => {
                                        const uniqueKey = `${i}-${j}`;
                                        return (
                                            <tr className={styles.TableBodyRow} key={uniqueKey}>
                                                {headers_filedAgainst?.map((header, i) => (
                                                    <td
                                                        key={header.key}
                                                        className={styles.TableBodyRowItem}
                                                        style={{
                                                            overflowWrap: isPDFReport ? 'normal' : 'anywhere',
                                                            padding: isPDFReport ? '0 10px' : '',
                                                        }}
                                                    >
                                                        {header?.key === HEADERS.STATUS ? (
                                                            <div
                                                                className={clsx({
                                                                    [styles.Status]: header.key === HEADERS.STATUS,
                                                                    [styles.StatusOpen]:
                                                                        header.key === HEADERS.STATUS &&
                                                                        singleCase?.[header?.key] === 'Pending',
                                                                    [styles.StatusClosed]:
                                                                        header.key === HEADERS.STATUS &&
                                                                        singleCase?.[header?.key] === 'Disposed',
                                                                    // [styles.StatusNotAvailable]:
                                                                    //     header.key === HEADERS.STATUS &&
                                                                    //     singleCase?.[header?.key] ===
                                                                    //     'Not Available',
                                                                })}
                                                                style={{
                                                                    margin: isPDFReport ? '10px 0' : '',
                                                                    fontSize: isPDFReport ? '10px' : '',
                                                                }}
                                                            >
                                                                {getStatusText(singleCase?.[header?.key])}
                                                            </div>
                                                        ) : header?.key === HEADERS.CASE_NUM ? (
                                                            <span
                                                                className={styles.Truncate}
                                                                title={singleCase?.[header?.key] || '-'}
                                                                style={{
                                                                    padding: isPDFReport ? '10px 0' : '',
                                                                }}
                                                            >
                                                                {singleCase?.[header?.key] || '-'}
                                                            </span>
                                                        ) : header?.key === HEADERS.DOCIDS ? (
                                                            Array.isArray(singleCase?.[header?.key]) &&
                                                            singleCase?.[header?.key].length > 0 ? (
                                                                <span
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        downloadCaseFunction(
                                                                            singleCase?.[header?.key],
                                                                            singleCase?.[HEADERS.CASE_NUM],
                                                                            singleCase?.[HEADERS.CASE_CATEGORY],
                                                                        );
                                                                    }}
                                                                    className={styles.downloadIconContainer}
                                                                    onMouseEnter={() => setHoveredIndex(uniqueKey)}
                                                                    onMouseLeave={() => setHoveredIndex(null)}
                                                                >
                                                                    <img
                                                                        src={
                                                                            hoveredIndex === uniqueKey
                                                                                ? downloadIconDataRoomHovered
                                                                                : downloadIconDataRoom
                                                                        }
                                                                        alt={'icon'}
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )
                                                        ) : (
                                                            <span
                                                                className={styles.Truncate}
                                                                style={{
                                                                    padding: isPDFReport ? '10px 0' : '',
                                                                }}
                                                                title={convertToFirstLetterCapital(
                                                                    decodeHtmlEntities(singleCase?.[header?.key]) || '-',
                                                                )}
                                                            >
                                                                {convertToFirstLetterCapital(
                                                                    decodeHtmlEntities(singleCase?.[header?.key]) || '-',
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className={styles.TableBodyRow}>
                                        <td colSpan={7} className={styles.TableBodyRowItem}>
                                            <div className={styles.NoCases}>
                                                <img src={greenTick} height={'16px'} />
                                                No Legal Cases filed against{' '}
                                                {legalCase.name === 'Company'
                                                    ? convertToFirstLetterCapital(companyName)
                                                    : convertToFirstLetterCapital(legalCase.name)}
                                            </div>
                                        </td>
                                    </tr>
                                ),
                            )
                        ) : (
                            <tr className={styles.TableBodyRow}>
                                <td colSpan={5} className={clsx(styles.TableBodyRowItem, styles.Nodata)}>
                                    No data available to show
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={styles.Note}>
                <b>*Note :</b> The cases listed above are based on an AI match of the company's legal name and its directors (please verify
                names).
            </div>
        </div>
    );

    const renderLegalCasesByCompanyOrDirector = () => (
        <div className={clsx(styles.Container, { [styles.PDFReportContainer]: isPDFReport })}>
            <div className={styles.HeaderContainer}>
                <div className={styles.Title}>Legal cases filed by Company / Director</div>
                {lastFetchedDate != null ? <div className={styles.UpdateDate}>Last Updated on {lastFetchedDate}</div> : null}
            </div>
            <div className={styles.TableWrapper}>
                <table className={styles.Table}>
                    <thead className={styles.TableHead}>
                        <tr className={styles.TableHeadRow}>
                            {headers_filedBy.map((header, i) => (
                                <th
                                    key={header.key}
                                    className={styles.TableHeadRowItem}
                                    style={{
                                        whiteSpace: isPDFReport ? 'normal' : 'nowrap',
                                        padding: '0 10px',
                                    }}
                                >
                                    {header.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={styles.TableBody} style={{ fontSize: isPDFReport ? '11px' : '' }}>
                        {legalCasesByCompanyOrDirector?.length ? (
                            legalCasesByCompanyOrDirector?.map((legalCase, i) =>
                                legalCase?.cases?.length ? (
                                    (isPDFReport ? legalCase?.cases?.slice(0, 10) : legalCase?.cases)?.map((singleCase, j) => {
                                        const uniqueKey = `${i}-${j}`;
                                        return (
                                            <tr className={styles.TableBodyRow} key={uniqueKey}>
                                                {headers_filedBy?.map((header) => (
                                                    <td key={header.key} className={styles.TableBodyRowItem}>
                                                        {header?.key === HEADERS.STATUS ? (
                                                            <div
                                                                className={clsx({
                                                                    [styles.Status]: header.key === HEADERS.STATUS,
                                                                    [styles.StatusOpen]:
                                                                        header.key === HEADERS.STATUS &&
                                                                        singleCase?.[header?.key] === 'Pending',
                                                                    [styles.StatusClosed]:
                                                                        header.key === HEADERS.STATUS &&
                                                                        singleCase?.[header?.key] === 'Disposed',
                                                                    // [styles.StatusNotAvailable]:
                                                                    //     header.key === HEADERS.STATUS &&
                                                                    //     singleCase?.[header?.key] ===
                                                                    //     'Not Available',
                                                                })}
                                                            >
                                                                {getStatusText(singleCase?.[header?.key])}
                                                            </div>
                                                        ) : header?.key === HEADERS.CASE_NUM ? (
                                                            <span
                                                                className={styles.Truncate}
                                                                title={singleCase?.[header?.key] || '-'}
                                                                style={{
                                                                    padding: isPDFReport ? '0px 0' : '',
                                                                }}
                                                            >
                                                                {singleCase?.[header?.key] || '-'}
                                                            </span>
                                                        ) : header?.key === HEADERS.DOCIDS ? (
                                                            Array.isArray(singleCase?.[header?.key]) &&
                                                            singleCase?.[header?.key].length > 0 ? (
                                                                <span
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        downloadCaseFunction(
                                                                            singleCase?.[header?.key],
                                                                            singleCase?.[HEADERS.CASE_NUM],
                                                                            singleCase?.[HEADERS.CASE_CATEGORY],
                                                                        );
                                                                    }}
                                                                    className={styles.downloadIconContainer}
                                                                    onMouseEnter={() => setHoveredIndex(uniqueKey)}
                                                                    onMouseLeave={() => setHoveredIndex(null)}
                                                                >
                                                                    <img
                                                                        src={
                                                                            hoveredIndex === uniqueKey
                                                                                ? downloadIconDataRoomHovered
                                                                                : downloadIconDataRoom
                                                                        }
                                                                        alt={'icon'}
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )
                                                        ) : (
                                                            <span
                                                                className={styles.Truncate}
                                                                title={convertToFirstLetterCapital(
                                                                    decodeHtmlEntities(singleCase?.[header?.key]) || '-',
                                                                )}
                                                            >
                                                                {convertToFirstLetterCapital(singleCase?.[header?.key] || '-')}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className={styles.TableBodyRow}>
                                        <td colSpan={7} className={styles.TableBodyRowItem}>
                                            <div className={styles.NoCases}>
                                                <img src={greenTick} height={'16px'} />
                                                No legal cases filed by{' '}
                                                {legalCase.name === 'Company'
                                                    ? convertToFirstLetterCapital(companyName)
                                                    : convertToFirstLetterCapital(decodeHtmlEntities(legalCase.name))}
                                            </div>
                                        </td>
                                    </tr>
                                ),
                            )
                        ) : (
                            <tr className={styles.TableBodyRow}>
                                <td colSpan={5} className={clsx(styles.TableBodyRowItem, styles.Nodata)}>
                                    No data available to show
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={styles.Note}>
                <b>*Note :</b> The cases listed above are based on an AI match of the company's legal name and its directors (please verify
                names).
            </div>
        </div>
    );
    const render = (
        <div className={clsx(styles.LegalCases)}>
            {renderRiskIndicators()}
            {!isSoleProp && legalCasesData?.status === DATA_FETCH_STATUS.PROCESSING ? (
                <div className={styles.Container}>
                    <div className={styles.Title}>Legal Cases</div>
                    <div className={styles.GeneratingData} style={{ height: '300px' }}>
                        <img src={generating} width="100px" alt="" />
                        Fetching Legal Cases Data
                        <img src={loader} alt="" width="50px" />
                    </div>
                </div>
            ) : (
                <>
                    {renderLegalCasesAgainstCompanyOrDirector()}
                    {renderLegalCasesByCompanyOrDirector()}
                </>
            )}
            {/* <div className={styles.Container}>
                <div className={styles.Title}>Legal Cases</div>
                <div className={styles.GeneratingData} style={{ height: '300px' }}>
                    <img src={generating} width="100px" alt="" />
                    Fetching Legal Cases Data
                    <img src={loader} alt="" width="50px" />
                </div>
            </div> */}
        </div>
    );

    if (isPDFReport)
        return (
            <>
                {legalCasesAgainstCompanyOrDirector?.length ? (
                    <A4Page sectionName={REPORT_SECTION_NAMES.LEGAL_CASES}>
                        <div className={styles.PDFTitle}>Legal Cases</div>
                        <AicaOverview module={'legalChecks'} isPDFReport style={{ marginBottom: '24px' }} />
                        {renderLegalCasesAgainstCompanyOrDirector()}
                    </A4Page>
                ) : (
                    <></>
                )}
                {legalCasesByCompanyOrDirector?.length ? (
                    <A4Page sectionName={REPORT_SECTION_NAMES.LEGAL_CASES}>{renderLegalCasesByCompanyOrDirector()}</A4Page>
                ) : (
                    <></>
                )}
                {renderRiskIndicators()}
            </>
        );
    else return render;
}

export default LegalCases;
