import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import greyLock from '../../assets/grey-lock-2.svg';
import styles from './TabSelector.module.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';
import { fetchTabLockStatus, setModulePollingList, setShowRefreshReport, setTaskTrackerMap } from '../../store/investor/action';
import RefreshIcon from '../../assets/Svg/RefreshIcon';
import { getAllTaskTrackerData } from '../../store/investor/investorApiHelper';
import { TAB_TO_TASK_TRACKER_MAP, TabModule } from '../../utils/constants/commonConstants';
import { Button } from '../_custom';

interface TabSelectorProps {
    tabs: Tab[];
    currentTab: string;
    setCurrentTab: Function;
    companyAccessType: string;
    profileGenerationStatus: string;
    unlockedAt: string;
}

export interface Tab {
    id?: string;
    label?: string;
    link?: string;
}

function TabSelector({
    tabs = [],
    currentTab,
    setCurrentTab,
    companyAccessType,
    profileGenerationStatus,
    unlockedAt,
}: Readonly<TabSelectorProps>) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const dashboardConfig: any = useAppSelector((state) => state.investor.config);
    const tabLockStatus = useAppSelector((state) => state.investor.tabLockStatus);
    const permissions = useAppSelector((state) => state.investor.permissions);
    const taskTrackerMap = useAppSelector((state) => state.investor.taskTrackerMap);
    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);
    const secondaryModuleList = dashboardConfig?.tabs?.filter((tab: any) => tab.category === 'secondary').map((tab: any) => tab.id);
    const onboardingTabs = permissions?.onboardingTabs;

    const [showTooltipIndex, setShowTooltipIndex] = useState<boolean | number>(false);
    const [unlockBtnHovered, setUnlockBtnHovered] = useState(false);
    const [timerText, setTimerText] = useState('');
    let timerRef: any = useRef(null);
    let refreshTimerRef: any = useRef(null);

    const isProfileLocked = profileGenerationStatus === 'LOCKED';

    const unlockTime = new Date(unlockedAt);
    const showTimer = new Date() < new Date(unlockTime.getTime() + 15 * 60 * 1000);

    useEffect(() => {
        if (unlockedAt && showTimer) {
            timerRef.current = setInterval(() => {
                getTimer();
            }, 1000);
        }

        return () => {
            clearInterval(timerRef?.current);
        };
    }, [unlockedAt, showTimer]);

    useEffect(() => {
        if (tabs?.length && params.id)
            dispatch(
                fetchTabLockStatus({
                    tabsList: tabs.map((tab) => tab?.id ?? '') || [],
                    assesseeOrgId: params.id,
                }),
            );
    }, [tabs, params.id]);

    /**
     * This function checks if all steps for a module are complete or failed.
     * It returns true if all steps are complete or failed, otherwise false.
     * This is used to stop the polling after all steps are complete or failed.
     *
     * @returns boolean - true if all steps are complete or failed, otherwise false.
     */
    const checkStepsCompleteOrFailed = () => {
        if (!isEmpty(taskTrackerMap)) {
            const steps = modulePollingList.map((module) => TAB_TO_TASK_TRACKER_MAP[module]).flat();
            return steps.every((step) => taskTrackerMap[step]?.status === 'COMPLETE' || taskTrackerMap[step]?.status === 'FAILED');
        }
        return false;
    };

    /**
     * This function updates the module polling list based on the task tracker map.
     * It checks if all steps for a module are complete or failed and removes the module from the polling list if so.
     * This is used to show the polling icon for the module.
     *
     * @param taskTrackerMap - The task tracker map containing the task tracker steps and their statuses.
     */
    const updateModulePollingList = (taskTrackerMap: { [taskTrackerStep: string]: { status: string; dateCreated: number } }) => {
        const completeOrFailedSteps: string[] = [];
        for (const module of modulePollingList) {
            const steps = TAB_TO_TASK_TRACKER_MAP[module]
                .map((step) => taskTrackerMap[step]?.status)
                .filter((step) => step !== null && step !== undefined);
            const isStepCompleteOrFailed = steps.every((step) => step === 'COMPLETE' || step === 'FAILED');
            if (isStepCompleteOrFailed) completeOrFailedSteps.push(module);
        }
        const newModulePollingList = modulePollingList.filter((module) => !completeOrFailedSteps.includes(module));
        dispatch(setModulePollingList(newModulePollingList));
    };

    /**
     * This function handles the task tracker callback.
     * It checks if all steps for a module are complete or failed and stops the polling if so.
     *
     * @param startTime - The start time of the polling.
     * @param twoMinutesInMs - The two minutes in milliseconds.
     * @param taskTrackerMap - The task tracker map containing the task tracker steps and their statuses.
     */
    const handleTaskTrackerCallback = (
        startTime: number,
        twoMinutesInMs: number,
        taskTrackerMap: { [taskTrackerStep: string]: { status: string; dateCreated: number } },
    ) => {
        if (checkStepsCompleteOrFailed()) {
            clearInterval(refreshTimerRef?.current);
        }

        if (Date.now() - startTime >= twoMinutesInMs) {
            clearInterval(refreshTimerRef?.current);
        }

        updateModulePollingList(taskTrackerMap);
    };

    /**
     * This function sets the interval for the task tracker polling.
     * It fetches the task tracker data every 5 seconds and updates the module polling list.
     */
    useEffect(() => {
        if (!isEmpty(modulePollingList) && params.id) {
            const startTime = Date.now();
            const twoMinutesInMs = 2 * 60 * 1000;

            refreshTimerRef.current = setInterval(() => {
                fetchAllTaskTrackerData(params.id as string, (taskTrackerMap) => {
                    handleTaskTrackerCallback(startTime, twoMinutesInMs, taskTrackerMap);
                });
            }, 5000);
        }

        return () => {
            if (refreshTimerRef?.current) {
                clearInterval(refreshTimerRef.current);
            }
        };
    }, [modulePollingList]);

    useEffect(() => {
        if (params.id) {
            fetchAllTaskTrackerData(params.id, () => {});
        }
    }, [params.id]);

    /**
     * This function fetches the task tracker data.
     * It fetches the task tracker data from the API and updates the task tracker map.
     *
     * @param orgId - The organization ID.
     * @param callbackFunction - The callback function to be called with the task tracker map.
     */
    const fetchAllTaskTrackerData = (
        orgId: string,
        callbackFunction: (taskTrackerMap: { [taskTrackerStep: string]: { status: string; dateCreated: number } }) => void,
    ) => {
        getAllTaskTrackerData(
            { orgId },
            {
                onSuccess: (response: any) => {
                    dispatch(setTaskTrackerMap(response));
                    callbackFunction(response);
                },
                onError: (error: any) => {
                    console.error('Failed to fetch task tracker data', error);
                },
            },
        );
    };

    const getTimer = () => {
        const timeRemaining = new Date(unlockTime.getTime() + 6 * 60 * 1000).getTime() - new Date().getTime();
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        let dots = '';
        switch (seconds % 4) {
            case 0:
                dots = '...';
                break;
            case 1:
                dots = '..';
                break;
            case 2:
                dots = '.';
                break;
            default:
                break;
        }

        if (minutes <= 0 && seconds <= 0) clearInterval(timerRef?.current);
        else setTimerText(`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>{`Generating${dots}`}</div>
                <div>{`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</div>
            </div>
        );
    };

    const handleClick = (tab) => {
        if (!isTabLocked(tab?.id)) {
            setCurrentTab(tab);
            if (tab?.link) {
                navigate(tab?.link);
            }
        } else if (secondaryModuleList.includes(tab?.id)) {
            dispatch(setShowRefreshReport(true));
        }
    };

    const isTabLocked = useCallback(
        (tabId: string) => {
            if (Array.isArray(secondaryModuleList) && !secondaryModuleList.includes(tabId))
                return !!((isProfileLocked && !onboardingTabs?.includes(tabId)) || tabLockStatus?.[tabId]?.isLocked);

            // Check if any step is complete or failed for secondary modules
            let anyStepCompleteOrFailed = false;
            if (!isEmpty(taskTrackerMap)) {
                const taskTrackerSteps = TAB_TO_TASK_TRACKER_MAP[tabId];
                anyStepCompleteOrFailed = taskTrackerSteps?.some(
                    (step) => taskTrackerMap[step]?.status === 'COMPLETE' || taskTrackerMap[step]?.status === 'FAILED',
                );
            }
            return !!(
                (isProfileLocked && !onboardingTabs?.includes(tabId)) ||
                !anyStepCompleteOrFailed ||
                tabLockStatus?.[tabId]?.isLocked
            );
        },
        [isProfileLocked, onboardingTabs, taskTrackerMap, tabLockStatus, secondaryModuleList],
    );

    const openRefreshReport = () => {
        dispatch(setShowRefreshReport(true));
    };

    const getLockedTabTooltipText = (tabId: TabModule) => {
        let message = '';
        switch (tabId) {
            case TabModule.OVERVIEW:
                message = 'Discover key relationships, risk levels, and actionable alerts';
                break;
            case TabModule.COMPLIANCES:
                message = 'Monitor GST and EPF filing compliances.';
                break;
            case TabModule.LEGAL_CHECKS:
                message = 'View legal cases involving the company or directors.';
                break;
            case TabModule.AUDITED_FINANCIALS:
                message = 'Analyse latest financial statements and ratios.';
                break;
            case TabModule.CREDIT_BUREAU:
                message = 'Access credit scores, past repayments track record';
                break;
            default:
                message = 'Locked, please unlock AICA to access this module.';
                break;
        }
        return tabLockStatus?.[tabId]?.lockedMessage ?? message;
    };

    return (
        <div className={styles.TabContainer}>
            <div className={styles.MenuItems} role="tablist">
                <div className={styles.UnlockAICA}>
                    {modulePollingList.length > 0 ? (
                        <div className={styles.FetchingBtn}>
                            Fetching Data
                            <LoadingSpinner color="var(--primary-text-colour)" height="16px" />
                        </div>
                    ) : (
                        <Button
                            onClick={openRefreshReport}
                            icon={
                                <RefreshIcon
                                    colour={'var(--primary-text-colour)'}
                                    style={{ cursor: 'pointer', marginRight: '4px' }}
                                    height="14"
                                    width="14"
                                />
                            }
                            text={'Unlock/Refresh'}
                            variant="secondary"
                            style={{ marginTop: '0', width: '100%' }}
                        />
                    )}
                </div>
                {tabs?.map((tab, i) => (
                    <div className={clsx(styles.MenuItem)} key={i}>
                        <div
                            className={clsx({
                                [styles.InvesteeTabLinksActive]: currentTab === tab?.id,
                                [styles.Tab]: true,
                            })}
                            key={tab?.id}
                            onClick={() => handleClick(tab)}
                            onMouseEnter={() => isTabLocked(tab?.id as string) && setShowTooltipIndex(i + 1)}
                            onMouseLeave={() => showTooltipIndex === i + 1 && setShowTooltipIndex(false)}
                        >
                            <div
                                data-toggle="tab"
                                className={clsx({
                                    [styles.InvesteeTabLinks]: true,
                                })}
                            >
                                <span>{tab?.label}</span>
                                {isTabLocked(tab?.id as string) && !modulePollingList.includes(tab?.id as string) && (
                                    <img src={greyLock} alt="" height="16px" />
                                )}

                                {modulePollingList.includes(tab?.id as string) && (
                                    <div className={styles.PollingIcon}>
                                        <LoadingSpinner color="var(--primary-text-colour)" height="16px" />
                                    </div>
                                )}

                                {showTooltipIndex === i + 1 && (
                                    <div className={styles.TooltipInfo}>
                                        <div className={styles.TooltipBody}>
                                            {getLockedTabTooltipText(tab?.id as TabModule)}
                                            <img
                                                className={styles.TooltipPointer}
                                                src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/left-triangle-white.svg'}
                                                alt=""
                                                height="10px"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <img
                    style={{ margin: '8px auto 4px auto' }}
                    src={'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/poweredByAica.svg'}
                    alt="poweredByAica"
                    width="90%"
                />
            </div>
        </div>
    );
}

export default TabSelector;
