import clsx from 'clsx';
import styles from '../../RelatedParties.module.scss';
import { getImage, relationTypeMap } from '../../relatedParty_constants';
import { toTitleCase } from '../../../../../../utils/utils';
import { alertIcon, alertIconDisabled } from '../../../../../../assets/hostedassets';

function SecondDegreeRow({ isPDFReport, entityEntry, row, handleAlertsClick }) {
    const getRelationType = (parentType: string, childType: string, edgeType: string) => {
        return relationTypeMap[parentType]?.[childType]?.[edgeType] || '';
    };

    return (
        <tr className={clsx(styles.FirstDegreeRow, styles.SecondDegreeRow)} key={row?.fromKid}>
            <td className={styles.RowItem}></td>

            <td className={styles.RowItem}>{getRelationType(entityEntry?.type, row?.type, row?.edgeType) || ''}</td>

            <td className={styles.RowItem}>
                <div className={styles.EntityNameContainer}>
                    <div className={styles.EntityNamePill}>
                        {!isPDFReport && (
                            <img
                                src={getImage(row?.type)}
                                alt="ent"
                                height={'14px'}
                                style={{
                                    marginTop: '2px',
                                }}
                            />
                        )}
                        <span className={styles.Text}>{toTitleCase(row?.fromName) || '-'}</span>
                    </div>
                </div>
            </td>

            <td className={styles.RowItem}>
                <div
                    className={clsx(styles.Risk, {
                        [styles.Risk_Severe]: row?.risk === 'SEVERE',
                        [styles.Risk_High]: row?.risk === 'HIGH',
                        [styles.Risk_Medium]: row?.risk === 'MEDIUM',
                        [styles.Risk_Low]: row?.risk === 'LOW',
                    })}
                >
                    {row?.risk && row?.risk !== 'null' ? toTitleCase(row?.risk) : 'NA'}
                </div>
            </td>

            {!isPDFReport && (
                <td className={styles.RowItem}>
                    <div
                        className={clsx(styles.Alerts, {
                            [styles.Alerts_Disabled]: !row?.alerts?.length,
                        })}
                        onClick={() => {
                            handleAlertsClick(row);
                        }}
                    >
                        <img
                            src={row?.alerts?.length ? alertIcon : alertIconDisabled}
                            alt="alert"
                            style={{
                                margin: '0px 5px 2px 0',
                            }}
                        />
                        <span>{row?.alerts?.length} Alerts</span>
                    </div>
                </td>
            )}
        </tr>
    );
}

export default SecondDegreeRow;
