import { getUntaggedCountPerUser } from '../MagicUpload/MagicUploadHelper';
import { getPlatformUserId } from '../../utils/utils';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const useFetchNotificationDetails = (source: 'ORG' | 'FILE') => {
    const userDetails = useAppSelector((state) => state.user.userData);
    const [untaggedCount, setUntaggedCount] = useState(0);
    const setCookie = (name: string, value: string | number) => {
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000); // Set cookie to expire in 1 day
        const expires = 'expires=' + date.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    };
    const getUntaggedFilesCount = async () => {
        await getUntaggedCountPerUser(
            {
                userId: getPlatformUserId(),
                pnoId: userDetails.investorOrgId,
                timeStamp: dayjs().valueOf(),
                filterBy: source,
            },
            {
                onSuccess: (res) => {
                    if (res !== 0) setCookie('lastNotificationCheck', dayjs().valueOf());
                    setUntaggedCount(res);
                },
                onError: () => {
                    console.error('Error fetching untagged files count');
                },
            },
        );
    };
    useEffect(() => {
        // Check if the cookie exists if yes then check if 4 hours have passed
        const lastNotificationCheck = document.cookie
            .split('; ')
            .find((row) => row.startsWith('lastNotificationCheck='))
            ?.split('=')[1];
        if (lastNotificationCheck) {
            const lastCheckDate = dayjs(parseInt(lastNotificationCheck));
            if (dayjs().diff(lastCheckDate, 'hour') >= 4) {
                getUntaggedFilesCount();
            }
        } else {
            getUntaggedFilesCount();
        }
    }, [source]);
    return { untaggedCount };
};

export default useFetchNotificationDetails;
