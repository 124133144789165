import { INVESTOR_ROLES, MANAGE_USERS_TABS, RESTRICTED_ROLES, USER_TYPES } from '../store/investor/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DealFilters } from '../store/investor/reducer';

export const LAKH = 100000;
export const CRORE = 10000000;

export const APP_ENV = {
    PRODUCTION: 'production',
    DEV: 'development',
    STAGING: 'staging',
};

export const history: any = {
    navigate: null,
    location: null,
};

export const isMobile = window.innerWidth < 800;
export const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
export const websiteUrlRegex = new RegExp(
    /^(?:(ftp|http|https):\/\/)?(?:([a-zA-Z0-9-]+)\.)?(?:[\w-]+\.)+[a-z]{2,}(?:\.[a-z]{2,})?(?:\/)?$/,
);
export const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.[a-z.]+\/(in|company|school|groups)\/[A-Za-z0-9_-]+(\/)?(\?.*)?$/;

export let is_prod = process.env.REACT_APP_ENVIRONMENT === 'production' && location.hostname !== 'localhost';

export function isEmpty(value: any) {
    return (
        value === undefined ||
        value === null ||
        // || value === NaN
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && isNaN(Number(value)))
    );
}

export function getSubdomain() {
    const url = window.location.toString();
    const regex = /^https:\/\/([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s?]*)?(?:\?[^#\s]*)?$/;
    const match = url.match(regex);
    if (match && match.length > 1) {
        return match?.[1]?.split('.')?.[0];
    }

    if (process.env.REACT_APP_ENVIRONMENT !== APP_ENV.PRODUCTION) return 'aica-dev';
    return '';
}

export function getDomain() {
    if (process.env.REACT_APP_ENVIRONMENT !== APP_ENV.PRODUCTION) return 'founderlink.ai';
    const hostname = window.location.hostname;
    let parts = hostname.split('.');
    if (parts.length <= 2) return hostname;

    parts = parts.slice(-3);
    if (['co', 'com'].indexOf(parts[1]) > -1) return parts.join('.');

    return parts.slice(-2).join('.');
}

export function convertArrayToMap(arr, key): {}[] {
    return arr.reduce(function (map, obj) {
        map[obj[key]] = obj;
        return map;
    }, {});
}

export function getInitialsFromName(string, takeLastWord = true) {
    if (!string) return '';

    const names = string.trim().split(/\s+/);
    let initials = names[0]?.substring(0, 1).toUpperCase() || '';

    if (names.length > 1) {
        const secondInitial = takeLastWord ? names[names.length - 1]?.substring(0, 1) : names[1]?.substring(0, 1);
        initials += secondInitial?.toUpperCase() || '';
    }

    return initials;
}

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export function getFeatureStatus(availableFeatures: string[] = [], featureName) {
    let value: any = '';
    availableFeatures.map((obj) => {
        const featureKey = Object.keys(obj ?? {})[0];
        if (featureKey === featureName) value = Object?.values(obj)?.[0] || '';
    });
    return value;
}

export const validateGSTUserName = (gstUserName: string) => {
    let isValid = true;
    let message = '';
    if (!gstUserName.match(/^([a-zA-Z0-9_\-.]){8,15}$/)) {
        isValid = false;
        message = 'Please enter a valid username';
    }
    return {
        isValid,
        message,
    };
};

export const clearLocalStorageOnLogout = () => {
    localStorage.clear();
};

export const handleBackgroundScroll = (isScrollable: boolean) => {
    if (isScrollable) {
        document.body.style.overflow = 'auto';
    } else {
        document.body.style.overflow = 'hidden';
    }
};

export const convertToTitleCase = (input = '') => {
    // Check if input contains underscore
    if (input?.includes?.('_')) {
        // Split input by underscore and capitalize each word
        return input
            ?.split?.('_')
            ?.map?.((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
            ?.join(' ');
    } else {
        // Split?. camelCase input? into individual words and capitalize them
        return input
            ?.split?.(/(?=[A-Z])/)
            ?.map?.((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
            ?.join(' ');
    }
};

export const getFromLocalStorage = (keyName: string = '') => {
    if (keyName) {
        return localStorage.getItem(keyName) ? JSON.parse(localStorage.getItem(keyName) || '') : null;
    }
    return null;
};

export const getPlatformUserId = (): string => {
    if (sessionStorage.getItem('platform_user_id')) {
        return sessionStorage.getItem('platform_user_id') ?? '';
    }
    return localStorage.getItem('platform_user_id') ?? '';
};

export const isRestrictedUser = (role: null | string, restrictedRoles: string[]) => {
    if (isEmpty(role)) {
        return false;
    }
    if (restrictedRoles.includes(role ?? '')) {
        return true;
    }
    return false;
};
export const numberOnly = (value) => {
    return value.replace(/[^0-9]/g, '');
};

export function convertToFirstLetterCapital(string) {
    return string.toLowerCase().replace(/\b\w/g, function (letter) {
        return letter.toUpperCase();
    });
}

export function capitalizeWords(str = '') {
    return str?.toLowerCase()?.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
}

export function decodeHtmlEntities(html) {
    return html?.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
}

export function removeKeyAndConvertIdToNumber(array, keyToRemove) {
    array.forEach((obj) => {
        delete obj[keyToRemove];
    });
    return array;
}

export function shortNumberFormat(number) {
    if (Math.abs(number) >= 1.0e6) {
        return (Math.abs(number) / 1.0e6).toFixed(1) + 'M';
    } else if (Math.abs(number) >= 1.0e3) {
        return (Math.abs(number) / 1.0e3).toFixed(0) + 'k';
    } else {
        return number.toString();
    }
}

export function formatWebsiteUrl(websiteUrl = '') {
    let modifiedString = websiteUrl;
    if (websiteUrl?.charAt(websiteUrl?.length - 1) === '/') modifiedString = websiteUrl?.substring(0, websiteUrl?.length - 1);
    return modifiedString?.replace('https://', '')?.replace('http://', '')?.replace('www.', '') || '';
}

export function changeFavicon(newFaviconUrl: string) {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = newFaviconUrl;
}

export function setRolesForUser(role = '') {
    if (RESTRICTED_ROLES.includes(role)) {
        switch (role) {
            case INVESTOR_ROLES.RESTRICTED_UNDERWRITER_ACCESS:
                return 'Underwriter';
            case INVESTOR_ROLES.RESTRICTED_INVESTOR_ACCESS:
                return 'RRF Investor';
        }
    }
    return 'AICA_Investor';
}

export function unflatten(data: { [key: string]: any }) {
    const result = {};
    Object.keys(data).forEach((key) => {
        const keys = key.split('.');
        keys.reduce((r: any, e: string, j) => {
            if (!r[e]) {
                if (Number.isNaN(Number(keys[j + 1]))) {
                    if (keys.length - 1 === j) {
                        if (data[key] !== null && data[key] !== undefined) r[e] = data[key];
                    } else {
                        r[e] = {};
                    }
                } else {
                    r[e] = [];
                }
            }
            return r[e];
        }, result);
    });
    return result;
}

export const flatten = (obj: { [key: string]: any }, prefix = '') => {
    let flattened = {};

    for (let key in obj) {
        if (obj?.hasOwnProperty(key)) {
            let newKey = prefix ? `${prefix}.${key}` : key;

            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                Object.assign(flattened, flatten(obj[key], newKey));
            } else {
                flattened[newKey] = obj[key];
            }
        }
    }

    return flattened;
};

export const convertEpochToDate = (epoch: number | string) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const utcTime = dayjs.utc(epoch);
    const istTime = utcTime.tz('Asia/Kolkata');

    return istTime.format('hh:mm A, DD MMM YYYY');
};

export function getObjAndFileNameS3(url) {
    if (Number.isInteger(Number(url))) {
        const obj = { url };
        return { obj, fileName: 'file', extention: null };
    }
    const commonStringInUrl = 'amazonaws.com/';
    const indexOfCommonString = url?.indexOf(commonStringInUrl);
    const bucket = getBucketNameFromS3Url(url);
    const urlToSend = url.slice(indexOfCommonString + commonStringInUrl.length);
    const obj = { url: urlToSend, bucket: bucket };
    const fileName = urlToSend.substr(urlToSend.indexOf('/') + 1);

    const extentionArr = obj?.url?.split('.');
    const extention = extentionArr[extentionArr?.length - 1];
    return { obj, fileName, extention };
}

export function getBucketNameFromS3Url(url) {
    const urlParts = url.split('/');
    if (urlParts.length >= 3 && urlParts[2].endsWith('.amazonaws.com')) {
        const urlParts1 = urlParts[2].split('.');
        if (urlParts1.length > 0) {
            return urlParts1[0];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function validateCIN(cin: string): boolean {
    const cinPattern = new RegExp(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/);
    return cinPattern.test(cin);
}

/**
 * Pass details in query params to be stored in session storage
 *
 * @param summonDetails
 */
export function openCompanySummonTab(userData: {
    accountType: string;
    parentOrgId: string;
    orgId: string;
    primaryEmail: string;
    userId: string;
    userName: string;
    parentEmailId: string;
    authToken: string;
    userRole: string;
}): void {
    const { orgId, userId, authToken, accountType, parentOrgId, primaryEmail, userName, parentEmailId } = userData;
    // Open a new tab with the summon details in query params
    const summonUrl = `${
        window.location.origin
    }/company/upload?orgId=${orgId}&userId=${userId}&token=${authToken}&accountType=${accountType}&parentOrgId=${parentOrgId}&primaryEmail=${encodeURIComponent(
        primaryEmail,
    )}&userName=${encodeURIComponent(userName)}&parentEmailId=${encodeURIComponent(parentEmailId)}&userRole=${userData.userRole}`;
    window.open(summonUrl, '_blank');
}

/**
 * Pass details in query params to be stored in session storage
 *
 * @param summonDetails
 */
export function openUserSummonTab(userData: {
    accountType: string;
    parentOrgId: string;
    orgId: string;
    primaryEmail: string;
    userId: string;
    userName: string;
    parentEmailId: string;
    authToken: string;
    userRole: string;
    inviteCode: string;
    platformUserId: string;
    referrer?: string;
}): void {
    const { orgId, userId, authToken, accountType, parentOrgId, primaryEmail, userName, parentEmailId, inviteCode, platformUserId } =
        userData;
    // Open a new tab with the summon details in query params
    let summonUrl = `${
        window.location.origin
    }/investor/app/scan?orgId=${orgId}&userId=${userId}&token=${authToken}&accountType=${accountType}&parentOrgId=${parentOrgId}&primaryEmail=${encodeURIComponent(
        primaryEmail,
    )}&userName=${encodeURIComponent(userName)}&parentEmailId=${encodeURIComponent(parentEmailId)}&userRole=${
        userData.userRole
    }&inviteCode=${encodeURIComponent(inviteCode)}&platformUserId=${platformUserId}&summonInternal=true`;
    if (userData.referrer) {
        summonUrl += `&referrer=${userData.referrer}`;
    }
    window.open(summonUrl, '_blank');
}

export function toTitleCase(str: string): string {
    if (!str) return '';
    return str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

/**
 * Get domain from url
 */
export function getDomainFromUrl(url: string): string {
    if (!url) return '';
    try {
        const urlObj = new URL(url);
        return urlObj.hostname.replace('www.', '');
    } catch (error) {
        return url;
    }
}

export function getHashFromId(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

/**
 * Returns a human-readable string representing how long ago a date was
 * @param date - ISO 8601 date string
 * @returns "Today", "Yesterday", or "X days ago"
 * @throws Error if date is invalid or in the future
 */
export function returnDaysAgo(date: string): string {
    if (!date) {
        throw new Error('Date is required');
    }

    const parsedDate = dayjs(date);
    if (!parsedDate.isValid()) {
        throw new Error('Invalid date format');
    }

    const now = dayjs();
    if (parsedDate.isAfter(now)) {
        throw new Error('Future dates are not supported');
    }

    const diff = now.diff(parsedDate, 'day');
    return diff === 1 ? 'Yesterday' : diff === 0 ? 'Today' : `${diff} days ago`;
}

/**
 * Safely evaluates a formula based on a given condition and expression, substituting variables with their values.
 * @param formula - An object containing:
 *   - `fallback`: (Optional) The fallback number to return if the condition is not met.
 *   - `condition`: (Optional) A string representing the condition to evaluate.
 *   - `expression`: A string representing the expression to evaluate.
 * @param variables - A record of variable names and their numeric values.
 * @returns The result of the evaluated expression if the condition is met, otherwise the fallback value.
 */
export function evaluateFormula(formula, variables) {
    if (!formula.expression) {
        throw new Error('Expression is required for evaluation.');
    }

    const replaceVariables = (str: string) =>
        str.replace(/(\w+)/g, (match) => {
            return variables.hasOwnProperty(match) ? variables[match] : 0;
        });

    const conditionWithValues = formula.condition ? replaceVariables(formula.condition) : 'true';
    const expressionWithValues = replaceVariables(formula.expression);

    try {
        const conditionFn = new Function(`return ${conditionWithValues};`);
        const expressionFn = new Function(`return ${expressionWithValues};`);

        return conditionFn() ? expressionFn() : formula.fallback ?? 0;
    } catch (error) {
        console.error('Invalid formula evaluation', error);
    }
}
export const checkIfAnyFilterSelected = (filters: DealFilters) => {
    const filtersToCheck = ['statuses', 'dealOwners', 'dealReceivers', 'uwAnalysts', 'dcmOwners'] as const;
    type FilterKey = (typeof filtersToCheck)[number];

    return filtersToCheck.some((filter: FilterKey) => Array.isArray(filters[filter]) && (filters[filter] as string[])?.length > 0);
};

export const navigateUserAfterUnauth = (location, navigate) => {
    if (location.pathname.includes('investor/')) navigate('/investor/login');
    else if (location.pathname.includes('lender/')) navigate('/investor/login');
    else navigate('/login');
};

export const truncateTextTo2LineForComments = (text: string, smaller = true) => {
    const lines = text.split('\n');
    if (lines.length === 1) {
        // If there are no newline characters, show up to 60 characters with "..."
        return lines[0].length > 60 ? lines[0].slice(0, smaller ? 50 : 60) + '...' : lines[0];
    }
    // If there are newline characters, truncate each line after 50 characters and show up to 2 lines
    const truncatedLines = lines.slice(0, 2).map((line) => (line.length > 50 ? line.slice(0, smaller ? 35 : 50) + '...' : line));

    return truncatedLines.join('\n') + (lines.length > 2 ? '...' : '');
};

export const getUserTypeList = (userType: string | null, parentCategory?: string | null) => {
    const effectiveCategory = userType || parentCategory;

    switch (effectiveCategory) {
        case MANAGE_USERS_TABS.PARTNER:
            return [USER_TYPES.PARTNER_TYPE];
        case MANAGE_USERS_TABS.INVESTOR:
            return [USER_TYPES.LENDER];
        case MANAGE_USERS_TABS.TEAM:
            return [USER_TYPES.INVESTOR_TYPE, USER_TYPES.ADMIN_TEAM];
        case MANAGE_USERS_TABS.COMPANY:
            return [USER_TYPES.COMPANY_TYPE];
        default:
            return [];
    }
};
