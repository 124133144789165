import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setModulePollingList } from '../../../../store/investor/action';
import { unlockOrRefreshReport } from '../../../../store/investor/investorApiHelper';
import { TAB_TO_TASK_TRACKER_MAP } from '../../../../utils/constants/commonConstants';

import { Button, CheckBox } from '../../../../common/_custom';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import SideDrawerFooter from '../../../../common/_custom/SideDrawer/components/SideDrawerFooter';

import greyLock from '../../../../assets/grey-lock-2.svg';
import styles from './RefreshReport.module.scss';

type Props = {
    close: () => void;
    onUnlockReportSuccess: () => void;
    profileGenerationStatus: string;
};

dayjs.extend(relativeTime);

const RefreshReport: React.FC<Props> = ({ close, onUnlockReportSuccess, profileGenerationStatus }) => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const orgId: string = params.id as string;
    const user = useAppSelector((state) => state.user);
    const dashboardConfig: any = useAppSelector((state) => state.investor.config);
    const permissions = useAppSelector((state) => state.investor.permissions);
    const tabLockStatus = useAppSelector((state) => state.investor.tabLockStatus);
    const taskTrackerMap = useAppSelector((state) => state.investor.taskTrackerMap);
    const secondaryModuleList = dashboardConfig?.tabs?.filter((tab: any) => tab.category === 'secondary') || [];
    const modulePollingList = useAppSelector((state) => state.investor.modulePollingList);

    const onboardingTabs = permissions?.onboardingTabs;
    const isProfileLocked = profileGenerationStatus === 'LOCKED';

    const [unlocking, setUnlocking] = useState(false);
    const [selectedModules, setSelectedModules] = useState<Set<string>>(new Set());

    const isTabLocked = useCallback(
        (tabId: string) => {
            let anyStepCompleteOrFailed = false;
            if (!isEmpty(taskTrackerMap)) {
                const taskTrackerSteps = TAB_TO_TASK_TRACKER_MAP[tabId];
                anyStepCompleteOrFailed = taskTrackerSteps?.some(
                    (step) => taskTrackerMap[step]?.status === 'COMPLETE' || taskTrackerMap[step]?.status === 'FAILED',
                );
            }
            return !!(
                (isProfileLocked && !onboardingTabs?.includes(tabId)) ||
                !anyStepCompleteOrFailed ||
                tabLockStatus?.[tabId]?.isLocked
            );
        },
        [isProfileLocked, onboardingTabs, taskTrackerMap, tabLockStatus],
    );

    const handleModuleSelection = (module: string) => {
        const newSelectedModules = new Set<string>(selectedModules);

        if (module === 'all') {
            if (selectedModules.size === secondaryModuleList.length) {
                newSelectedModules.clear();
            } else {
                secondaryModuleList.forEach((module) => newSelectedModules.add(module.id));
            }
        } else if (selectedModules.has(module)) {
            newSelectedModules.delete(module);
        } else {
            newSelectedModules.add(module);
        }

        setSelectedModules(newSelectedModules);
    };

    const onPrimary = () => {
        setUnlocking(true);
        unlockOrRefreshReport(
            {
                assesseeOrgId: orgId,
                investorOrgId: user.domainMetadata?.investorOrgId ?? '',
                tabs: Array.from(selectedModules),
            },
            {
                onSuccess: () => {
                    setUnlocking(false);
                    dispatch(setModulePollingList(Array.from(selectedModules)));
                    if (profileGenerationStatus === 'LOCKED') {
                        onUnlockReportSuccess();
                    }
                    close();
                },
                onError: () => {
                    setUnlocking(false);
                },
            },
        ).finally(() => {
            setUnlocking(false);
        });
    };

    const renderModuleSelectionTable = useMemo(() => {
        return (
            <table className={styles.ModuleSelectionTable}>
                <thead className={styles.ModuleSelectionTableHead}>
                    <tr>
                        <th style={{ width: '10%' }}>
                            <CheckBox
                                checked={selectedModules.size === secondaryModuleList.length}
                                label=""
                                onCheck={() => handleModuleSelection('all')}
                                variant="primary"
                            />
                        </th>
                        <th>List of modules</th>
                        <th
                            style={{
                                textAlign: 'end',
                                paddingRight: '1rem',
                            }}
                        >
                            Last fetched
                        </th>
                    </tr>
                </thead>

                <tbody className={styles.ModuleSelectionTableBody}>
                    {secondaryModuleList.map((module) => {
                        let lastFetchedInWords = '-';
                        const moduleTasks = TAB_TO_TASK_TRACKER_MAP[module.id];
                        if (taskTrackerMap && moduleTasks) {
                            const lastFetched = moduleTasks
                                .filter((task) => taskTrackerMap[task]?.status === 'COMPLETE')
                                .map((task) => taskTrackerMap[task]?.dateCreated)
                                .filter(Boolean)
                                .sort((a, b) => b - a)[0];
                            lastFetchedInWords = lastFetched ? dayjs(lastFetched).fromNow() : '-';
                        }
                        return (
                            <tr key={module.id}>
                                <td>
                                    <CheckBox
                                        checked={selectedModules.has(module.id)}
                                        label=""
                                        onCheck={() => handleModuleSelection(module.id)}
                                        variant="primary"
                                    />
                                </td>
                                <td>
                                    <div className={styles.ModuleName}>
                                        {module.label}{' '}
                                        {isTabLocked(module?.id as string) && !modulePollingList.includes(module?.id as string) && (
                                            <img className={styles.LockIcon} src={greyLock} alt="" height="16px" />
                                        )}
                                    </div>
                                </td>
                                <td className={styles.LastFetched}>{lastFetchedInWords}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }, [secondaryModuleList, selectedModules, taskTrackerMap]);

    return (
        <SideDrawer
            showHeading
            heading={<div className={styles.DrawerHeader}>Unlock/Refresh Report</div>}
            onClose={() => close()}
            width="40%"
        >
            <div className={styles.RefreshReportContainer} style={{ height: '100%' }}>
                <div className={styles.RefreshReportContent}>
                    {renderModuleSelectionTable}
                    <p className={styles.RefrestNote}>
                        User consent-based data from GST, Bank Statements etc. will be updated automatically when available.
                    </p>
                </div>

                <SideDrawerFooter>
                    <div className={styles.FooterContainer}>
                        <Button
                            style={{ marginTop: 0 }}
                            type="submit"
                            disabled={selectedModules.size === 0}
                            loading={unlocking}
                            onClick={onPrimary}
                            variant="primary"
                            text="Update"
                        />
                        <Button style={{ marginTop: 0 }} type="submit" onClick={close} variant="secondary" text="Cancel" />
                    </div>
                </SideDrawerFooter>
            </div>
        </SideDrawer>
    );
};

export default RefreshReport;
