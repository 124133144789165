import React, { useEffect, useState } from 'react';
import styles from './ConfigurablePopup.module.scss';
import { Mixpanel } from '../../utils/mixpanel';
import { useAppSelector } from '../../app/hooks';

interface ContentBlock {
    type: 'text' | 'image' | 'link' | 'heading' | 'divider';
    value: string;
    label?: string;
}

export interface PopupData {
    contentBlocks: ContentBlock[];
    showPopup: boolean;
}

const ConfigurablePopup: React.FC<{ popupData?: PopupData }> = ({ popupData }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const userData = useAppSelector((state) => state.user.userData);

    useEffect(() => {
        if (popupData?.showPopup) {
            const showTimeout = setTimeout(() => {
                setIsVisible(true);
            }, 10000);

            return () => clearTimeout(showTimeout);
        }
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isClosing) {
            timeoutId = setTimeout(() => {
                setIsVisible(false);
                setIsClosing(false);
            }, 700);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isClosing]);

    const handleDismiss = async () => {
        Mixpanel.track('Popup Dismissed', {
            'User ID': userData?.userId,
            'PNO ID': userData?.investorOrgId,
        });

        setIsClosing(true);
    };

    const handleContentClick = async () => {
        Mixpanel.track('Popup Clicked', {
            'User ID': userData?.userId,
            'PNO ID': userData?.investorOrgId,
        });
    };

    if (!isVisible || !popupData) return null;

    return (
        <div
            className={`${styles.popupContainer} ${isClosing ? styles.closing : ''}`}
            onClick={handleContentClick}
        >
            <button className={styles.closeButton} onClick={handleDismiss}>
                &times;
            </button>
            <div className={styles.popupContent}>
                {popupData.contentBlocks.map((block, index) => {
                    switch (block.type) {
                        case 'text':
                            return (
                                <p key={index} className={styles.textBlock}>
                                    {block.value}
                                </p>
                            );

                        case 'heading':
                            return (
                                <h5 key={index} className={styles.headingBlock}>
                                    {block.value}
                                </h5>
                            );

                        case 'divider':
                            return <div key={index} className={styles.divider}></div>;

                        case 'image':
                            return (
                                <img
                                    key={index}
                                    src={block.value}
                                    alt="Popup"
                                    className={styles.image}
                                />
                            );

                        case 'link':
                            return (
                                <a
                                    key={index}
                                    href={block.value}
                                    className={styles.ctaButton}
                                    target="_blank"
                                >
                                    {block.label || 'Learn More'}
                                </a>
                            );

                        default:
                            return null;
                    }
                })}
            </div>
        </div>
    );
};

export default ConfigurablePopup;
