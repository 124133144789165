import React, { useEffect, useState } from 'react';
import styles from './OperatingInflowReconciliation.module.scss';
import { fetchOperatingInflowReconData } from '../../../../../store/dashboard/reconciliation/reconciliationApiHelpers';
import { useParams } from 'react-router-dom';
import Header from './components/Header';
import Rows from './components/Rows';
import AIBadge from '../../Overview/components/AIBadge/AIBadge';
import { ShimmerText, ShimmerThumbnail } from 'react-shimmer-effects';
import Tooltip from '../../../../../common/_custom/Tooltip/Tooltip';

function OperatingInflowReconciliation({ assesseeOrgId }) {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any>(null);

    useEffect(() => {
        assesseeOrgId && fetchData();
    }, [assesseeOrgId]);

    const fetchData = () => {
        setLoading(true);
        fetchOperatingInflowReconData(
            {
                orgId: params.id,
                gstType: 'REVENUE',
            },
            {
                onSuccess: (data) => {
                    setTableData(data);
                    setLoading(false);
                },
                onError: () => {
                    setLoading(false);
                },
            },
        );
    };

    const Loader = () => {
        return (
            <div style={{ background: 'white', padding: '16px', marginTop: '16px' }}>
                <React.Fragment>
                    <ShimmerThumbnail height={250} rounded className={styles.ShimmerBody} />
                </React.Fragment>
            </div>
        );
    };

    const NoData = () => {
        return <div className={styles.Nodata}>No Data Available</div>;
    };

    return (
        <div className={styles.OperatingInflowReconciliation}>
            <div className={styles.Title}>
                <div className={styles.Left}>
                    Operating Inflow Reconciliation
                    {!!tableData?.dateRange && (
                        <>
                            {tableData?.dateRange?.length > 45 ? (
                                <Tooltip content={tableData?.dateRange} direction="bottom">
                                    <div className={styles.Date}>
                                        ({tableData?.dateRange?.substring(0, 45)}...)
                                    </div>
                                </Tooltip>
                            ) : (
                                <div className={styles.Date}>({tableData?.dateRange})</div>
                            )}
                        </>
                    )}
                </div>
                <AIBadge />
            </div>
            <div className={styles.TableContainer}>
                {loading ? (
                    <Loader />
                ) : (
                    <table className={styles.OperatingInflowTable}>
                        {!!tableData?.headers?.length && (
                            <thead className={styles.TableHead}>
                                <Header data={tableData?.headers} />
                            </thead>
                        )}
                        {!!tableData?.rows?.length && (
                            <tbody className={styles.TableBody}>
                                <Rows
                                    rows={tableData?.rows}
                                    headers={tableData?.headers}
                                    tableRef={null}
                                    assesseeOrgId={assesseeOrgId}
                                />
                            </tbody>
                        )}
                    </table>
                )}
                {!loading && !!!tableData?.rows?.length && <NoData />}
            </div>
        </div>
    );
}

export default OperatingInflowReconciliation;
