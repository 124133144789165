import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import SlateEditor from '../../../TextEditor/TextEditor';
import { Button, Modal } from '../../../_custom';
import styles from './DealSummaryEditor.module.scss';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import {
    getSignedUrl,
    saveDocMetaDataInDocService,
    uploadFile,
} from '../../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { toast } from 'react-toastify';
import { getTimeStampForFileName } from '../../../../utils/dateUtils';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchImageFromS3AndConvertToBase64 } from '../../../../utils/s3';
import { aicaGreyBase64_2, regenerateIcon } from '../../../../assets/hostedassets';
import { Descendant } from 'slate';
import {
    CREATE_DEAL,
    REPORT_TOP_BAR,
} from '../../../../modules/Investor/Portfolio/components/CompaniesList/components/Table/components/Rows';
import dayjs from 'dayjs';
import { TIME_DATE_FORMAT } from '../../../../utils/constants/commonConstants';
import { closeCommentsThread, resetAndHideCommentsThread } from '../../../../store/commentsThread/commentsThread_actions';

function DealSummaryEditor({
    source,
    openModal,
    closeModal,
    categoryId,
    flowId,
    assesseeOrgId,
    metadataUpdate,
    generating,
    editorInitialData,
    editorReady,
    savingData,
    saveOnePagerTemplateDataForOrg,
    setShowRegenConfirmModal,
    userInfo,
}) {
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const contentToPrint = useRef(null);
    const editorContent = useRef<Descendant[]>([]);
    const companyName = useAppSelector((state) => state.investor.dealDrawer.companyName);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const mainImageUrl = domainMetadata?.mainImageUrl;
    const orgName = domainMetadata?.orgName;
    const [triggerSave, setTriggerSave] = useState(false);

    useEffect(() => {
        dispatch(resetAndHideCommentsThread());
    }, []);

    useEffect(() => {
        if (triggerSave) {
            setTriggerSave(false);
        }
    }, [triggerSave, editorContent, assesseeOrgId]);

    const getHTMLElementForExport = async () => {
        let clonedElement: any;
        try {
            const el = document?.getElementById('element-to-be-downloaded-as-pdf');

            clonedElement = el?.cloneNode(true);
            clonedElement.style.backgroundImage = aicaGreyBase64_2;
            clonedElement.style.backgroundRepeat = 'repeat';
            clonedElement.style.backgroundSize = '250px auto';

            if (mainImageUrl) {
                const response: any = await fetchImageFromS3AndConvertToBase64(mainImageUrl);

                if (response) {
                    const topDiv = document.createElement('div');
                    const bottomDiv = document.createElement('div');

                    const topImg = document.createElement('img');

                    topDiv.style.display = 'flex';
                    topDiv.style.justifyContent = 'flex-end';

                    topImg.src = response;
                    topImg.alt = '';
                    topImg.height = 30;
                    topImg.crossOrigin = 'true';
                    topImg.style.transform = 'translateY(40px)';

                    bottomDiv.style.fontSize = '24px';
                    bottomDiv.style.fontWeight = '500';
                    bottomDiv.style.color = '#D2D2D2';

                    bottomDiv.innerHTML = `
                        <span>Built on AICA by ${orgName}</span>
                    `;
                    topDiv.appendChild(topImg);

                    // bottomDiv.appendChild(bottomImg);
                    clonedElement.insertBefore(topDiv, clonedElement.firstChild);

                    clonedElement.appendChild(bottomDiv);
                }
            }

            clonedElement.remove();
        } catch (err) {
            console.error('error', err);
        }
        return clonedElement;
    };

    const removePageBreaks = () => {
        const elements = document.querySelectorAll('#page-break-line');
        elements.forEach((hr, index) => {
            const hrElement = hr as HTMLElement;
            hrElement.style.visibility = 'hidden';
        });
    };

    const showPageBreaks = () => {
        const elements = document.querySelectorAll('#page-break-line');
        elements.forEach((hr, index) => {
            const hrElement = hr as HTMLElement;
            hrElement.style.visibility = 'visible';
        });
    };

    const handlePreview = useReactToPrint({
        onPrintError: (error) => console.error(error),
        content: () => contentToPrint.current,
        documentTitle: 'Print This Document',
        removeAfterPrint: true,
        print: async (printIframe: HTMLIFrameElement) => {
            removePageBreaks();
            const getElementForExport = await getHTMLElementForExport();

            try {
                // setLoadingPreview(true);
                const document = printIframe.contentDocument;
                if (document) {
                    var opt = {
                        padding: 20,
                        margin: 5,
                        filename: 'deal_summary.pdf',
                        html2canvas: { scale: 2, useCORS: true, dpi: 300, allowTaint: false },
                        jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
                    };
                    html2pdf()
                        .from(getElementForExport)
                        .set(opt)
                        .toPdf()
                        .output('bloburl')
                        .then((bloburl) => {
                            window.open(bloburl, '_blank');
                            setLoadingPreview(false);
                        });
                }
            } catch (err) {
                console.error('Error in generating preview', err);
            } finally {
                showPageBreaks();
            }
        },
    });

    const handleSaveToDb = useReactToPrint({
        onPrintError: (error) => console.error('React to print error: ', error),
        content: () => contentToPrint.current,
        documentTitle: 'Print This Document',
        removeAfterPrint: true,
        print: async (printIframe: HTMLIFrameElement) => {
            try {
                const document = printIframe.contentDocument;
                const getElementForExport = await getHTMLElementForExport();

                if (document) {
                    const html = document.getElementById('element-to-be-downloaded-as-pdf');

                    var opt = {
                        margin: 5,
                        padding: 20,
                        filename: 'check.pdf',
                        // image: { type: 'jpeg', quality: 0.20 },
                        html2canvas: { scale: 2, useCORS: true, dpi: 300, allowTaint: false },
                        jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
                    };

                    html2pdf()
                        .from(getElementForExport)
                        .set(opt)
                        .toPdf()
                        .output('blob')
                        .then(function (res) {
                            var file = new File([res], 'one_pager_report.pdf', {
                                type: 'application/pdf',
                            });
                            getSignedUrlFromS3(file);
                        });
                }
            } catch (err) {
                console.error('Error in saving File', err);
            }
        },
    });

    const getSignedUrlFromS3 = async (file) => {
        const uploadConfig = {
            assesseeOrgId: assesseeOrgId,
            categoryId: categoryId,
            mnemosyneOrgId: domainMetadata?.investorOrgId,
        };
        const fileData = {
            file: {
                name: `${companyName ? companyName + '_' : ''}DealSummary_${getTimeStampForFileName()}.pdf`,
                type: 'application/pdf',
                size: file.size,
            },
        };
        try {
            const signResponse = await getSignedUrl(fileData, uploadConfig);
            uploadFileAfter(signResponse.signedUrl, file, fileData);
        } catch (err) {
            setSaving(false);
            console.error('Error in getting signed url from s3', err);
            toast.error('Some error occoured, please try again later');
        }
    };

    const handleClose = () => {
        dispatch(closeCommentsThread());
        if (source !== CREATE_DEAL) {
            setTriggerSave(true);
            saveOnePagerTemplateDataForOrg(editorContent.current);
        } else if (source === CREATE_DEAL) {
            setSaving(true);
            handleSaveToDb(null, () => contentToPrint?.current);
        } else {
            closeModal();
        }
    };

    const uploadFileAfter = async (url, file, fileData) => {
        const uploadResponse = await uploadFile(file, url);
        const input_saveMetadata = {
            categoryId: categoryId,
            mnemosyneOrgId: domainMetadata?.investorOrgId,
            fileName: fileData.file.name,
            fileSizeKb: fileData?.file?.size / 1_000,
            flowId: flowId,
            fileType: fileData.file.type,
        };
        const docData = await saveDocMetaDataInDocService({
            input: input_saveMetadata,
            orgId: assesseeOrgId,
        });

        const fileInfo = {
            url: uploadResponse?.url,
            fileName: fileData.file.name,
            docId: docData?.id,
        };
        metadataUpdate(fileInfo);
        setSaving(false);
        toast.success('Successfully Saved');
        closeModal();
    };

    function generateUserMessage(userInfo: { userName: string; onePagerData: { lastUpdatedAt: string } }) {
        if (!userInfo?.userName || !userInfo?.onePagerData?.lastUpdatedAt) {
            return '';
        }
        const formattedDate = dayjs(userInfo.onePagerData.lastUpdatedAt).format(TIME_DATE_FORMAT);

        return `Last edited on ${formattedDate} by ${userInfo.userName}`;
    }

    const handlePreviewClick = (e) => {
        e.preventDefault();
        setLoadingPreview(true);
        handlePreview(null, () => contentToPrint?.current);
    };

    const handleSaveAndClose = (e) => {
        e.preventDefault();
        setSaving(true);
        setTriggerSave(true);
        dispatch(closeCommentsThread());
        if (source === CREATE_DEAL) {
            handleSaveToDb(null, () => contentToPrint?.current);
        }
        saveOnePagerTemplateDataForOrg(editorContent.current);
    };

    return openModal ? (
        <Modal
            size="full"
            open={openModal}
            style={{ padding: 0, fontSize: '12px', lineHeight: '140%', position: 'absolute' }}
            persistent
            withoutCloseButton
        >
            <div className={styles.Container}>
                <div className={styles.Top}>
                    {!!editorReady && (
                        <SlateEditor
                            source={source}
                            editorInitialData={editorInitialData}
                            targetRef={contentToPrint}
                            editorContentRef={editorContent}
                            assesseeOrgId={assesseeOrgId}
                            generating={generating}
                            onClose={handleClose}
                            savingData={savingData}
                        />
                    )}
                    <div className={styles.Footer}>
                        <div>
                            <div
                                className={styles.Regen}
                                onClick={() => {
                                    !generating && setShowRegenConfirmModal(true);
                                }}
                            >
                                <img src={regenerateIcon} alt={'re'} height={'24px'} />
                                {generating ? 'Regenerating' : 'Regenerate'}
                            </div>
                        </div>

                        <div className={styles.Text}>*Right-click tables to modify: add rows, add columns, or delete</div>

                        <div className={styles.Text}>{generateUserMessage(userInfo)}</div>

                        <div className={styles.Buttons}>
                            <Button
                                text="Preview"
                                variant="purpleinverted"
                                onClick={handlePreviewClick}
                                style={{ margin: '0 20px 0 0 ' }}
                                loading={loadingPreview}
                            />
                            <Button
                                text="Save & Close"
                                variant="primary"
                                onClick={handleSaveAndClose}
                                style={{ marginTop: '0' }}
                                loading={saving}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.Comments} id="onepager-comment-container">
                    {/* //Container Element Where comments thread is rendered via portal */}
                </div>
            </div>
        </Modal>
    ) : (
        <></>
    );
}

export default DealSummaryEditor;
