import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../../utils/axios-interceptor';
import {
    fetchGstr3bDataService,
    fetchRevenueVendorExpenseTableDataService,
    getGstReportsService,
} from './revenueVendorExpensesService';
import { Handlers } from '../../../types/actions/types';

export async function fetchRevenueVendorExpenseTableData(payload, handlers: Handlers) {
    try {
        const response = await fetchRevenueVendorExpenseTableDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.data || {});
                return;
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
    }
}

export async function fetchGstr3bData(payload, handlers: Handlers) {
    try {
        const response = await fetchGstr3bDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.data || {});
                return response?.data?.responseBody;
            case JAVA_API_STATUS.ERROR:
            // return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
    }
}

export async function getGSTReports(payload, handlers: Handlers) {
    try {
        const response = await getGstReportsService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data?.data || {});
                return response?.data?.gstReports || [];
            case JAVA_API_STATUS.ERROR:
            // return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
    }
}
