import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../utils/utils';
import EmptyBank from '../../../DashboardModules/BankDataTable/components/EmptyBank/EmptyBank';
import styles from './RawTable.module.scss';
import loaderSvg from '../../../../../assets/aica-loader.svg';

type RawTableProps = {
    data: {
        headers: { [key: string]: string }[];
        rows: { [key: string]: string }[];
    };
    loading: boolean;
    freezeColumnStart: number;
    freezeColumnEnd: number;
    isEditing: boolean;
    rawOptions: any;
    dataFormatOptions?: string[];
    metric: string;
    changedData: { [key: string]: string }[];
    setChangedData: React.Dispatch<React.SetStateAction<{ [key: string]: {} }[]>>;
};

const RawTable = (props: RawTableProps) => {
    const {
        data,
        freezeColumnStart,
        freezeColumnEnd,
        isEditing,
        rawOptions,
        dataFormatOptions,
        metric,
        changedData,
        setChangedData,
    } = props;
    const [headers, setHeaders] = useState<{ [key: string]: string }[]>([]);
    const [classificationOptions, setClassificationOptions] = useState<any>([]);
    const [rows, setRows] = useState<{ [key: string]: string }[]>([]);
    useEffect(() => {
        setHeaders(data?.headers ?? []);
        setRows(data?.rows ?? []);
    }, [data]);
    const getLeftOffset = (i: number) => {
        if (i >= freezeColumnStart && i <= freezeColumnEnd) {
            if (i === freezeColumnEnd) {
                return { left: `${i * 10}rem`, borderRight: '1px solid #e0e0e0' };
            }
            return { left: `${i * 10}rem` };
        } else {
            return {};
        }
    };
    const fetchClassificationRowOptions = () => {
        let tempObj = { ...classificationOptions };
        rawOptions?.forEach((item: any) => {
            if (item.misDisplayName === metric) {
                let temp = item.ledger_classifications.map((classification) => {
                    return classification.name;
                });
                tempObj = temp;
            }
        });
        setClassificationOptions(tempObj);
    };
    const handleChange = (value: string, row: { [key: string]: string }) => {
        let temp: { [key: string]: string | number } = {};
        let isAlreadyChanged = changedData.findIndex((item) => item.id === row?.id);
        if (isAlreadyChanged !== -1) {
            changedData[isAlreadyChanged].classificationId = rawOptions
                .find((item) => item.misDisplayName === metric)
                ?.ledger_classifications.find((item) => item.name === value)?.id;
            changedData[isAlreadyChanged].name = value;
            setChangedData([...changedData]);
            return;
        } else {
            temp.id = row.id;
            temp.classificationId = rawOptions
                .find((item) => item.misDisplayName === metric)
                ?.ledger_classifications.find((item) => item.name === value)?.id;
            temp.name = value;
        }
        setChangedData([...changedData, temp]);
    };
    const handleDataFormatChange = (value: string, row: { [key: string]: string }) => {
        let temp: { [key: string]: string | number } = {};
        let isAlreadyChanged = changedData.findIndex((item) => item.id === row?.id);
        if (isAlreadyChanged !== -1) {
            changedData[isAlreadyChanged].dataFormat = value;
            setChangedData([...changedData]);
            return;
        } else {
            temp.id = row.id;
            temp.dataFormat = value;
        }
        setChangedData([...changedData, temp]);
    };
    useEffect(() => {
        fetchClassificationRowOptions();
    }, [metric]);
    const renderCell = (
        row: { [key: string]: string },
        header: { [key: string]: string },
        j: number,
        i: number,
    ) => {
        if (isEditing) {
            if (header.name === 'Classification') {
                let classificationValue =
                    changedData?.find((item) => item.id === row?.id)?.name ?? row[header.key];
                return (
                    <td style={getLeftOffset(j)} className={styles.SelectContainer}>
                        <select
                            onChange={(e) => handleChange(e.target.value, row)}
                            value={classificationValue}
                            style={getLeftOffset(j)}
                            className={styles.RawTableEditableField}
                        >
                            {classificationOptions.map((option: string) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </td>
                );
            } else if (header.name === 'Data Format') {
                let dataFormatValue =
                    changedData?.find((item) => item.id === row?.id)?.dataFormat ?? row[header.key];
                return (
                    <td style={getLeftOffset(j)} className={styles.SelectContainer}>
                        <select
                            onChange={(e) => handleDataFormatChange(e.target.value, row)}
                            value={dataFormatValue}
                            style={getLeftOffset(j)}
                            className={styles.RawTableEditableField}
                        >
                            {dataFormatOptions?.map((option: string) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </td>
                );
            } else {
                return (
                    <td
                        style={getLeftOffset(j)}
                        className={
                            j >= freezeColumnStart && j <= freezeColumnEnd
                                ? styles.FrozenCell
                                : styles.TableCell
                        }
                        key={j}
                    >
                        {row[header.key] ?? '-'}
                    </td>
                );
            }
        } else {
            return (
                <td
                    style={getLeftOffset(j)}
                    className={
                        j >= freezeColumnStart && j <= freezeColumnEnd
                            ? styles.FrozenCell
                            : styles.TableCell
                    }
                    key={j}
                >
                    {row[header.key] ?? '-'}
                </td>
            );
        }
    };
    return (
        <>
            {isEmpty(headers) ? (
                <div className={styles.DataNotAvailable}>
                    <EmptyBank style={{ margin: 0 }} text={'Data not available'} />
                </div>
            ) : props.loading ? (
                <div className={styles.LoadingContainer}>
                    <img
                        className={styles.LoaderImg}
                        src={loaderSvg}
                        alt="Loading..."
                        height={'30%'}
                        width={'30%'}
                    />
                </div>
            ) : (
                <div className={styles.TableContainer}>
                    <table className={styles.Table}>
                        <thead className={styles.TableHeaders}>
                            <tr className={styles.TableHeader}>
                                {headers.map((header, i) => (
                                    <th
                                        style={getLeftOffset(i)}
                                        className={
                                            i >= freezeColumnStart && i <= freezeColumnEnd
                                                ? styles.FrozenCell
                                                : styles.HeaderCell
                                        }
                                        key={i}
                                    >
                                        {header.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={styles.TableBody}>
                            {rows.map((row, i) => (
                                <tr className={styles.TableRow} key={i}>
                                    {headers.map((header, j) => renderCell(row, header, j, i))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default RawTable;
