import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { fetchOwners, getAllDeals, getAllLenderDeals, GetOwnerDataPayload } from '../../../../../store/investor/action';
import { DEAL_LIST_TABS } from '../../../../../store/investor/constants';
import { DealStatus } from '../../../../../store/investor/reducer';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { updateQueryParams } from '../../../../../utils/searchParamUtils';
import { checkIfAnyFilterSelected, getPlatformUserId } from '../../../../../utils/utils';
import { INVESTOR_ROUTES, LENDER_ROUTES } from '../../../../../utils/constants/routesConst';
import { getOrgList } from '../../../../../common/MagicUpload/MagicUploadHelper';
import { getAllTypeOrgList } from './Store/DealsHelper';

const LENGTH = 50;
const { ALL, APPROVED, PASSED } = DEAL_LIST_TABS;

export const useDealsList = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useSearchParams();

    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const fetchingDeals = useAppSelector((state) => state.investor.fetchingDeals);
    const dealsData = useAppSelector((state) => state.investor.deals);
    const user = useAppSelector((state) => state.user);
    const userData = useAppSelector((state) => state.user.userData);
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const userType = user.userData.accountType;
    const dealFilters = useAppSelector((state) => state.investor.dealFilters);

    const [scrollPos, setScrollPos] = useState<number>(0);
    const [orgList, setOrgList] = useState<any[]>([]);
    const [orgListWithDeals, setOrgListWithDeals] = useState<any[]>([]);
    const [filteredOrgList, setFilteredOrgList] = useState<any[]>([]);
    const [displayedOrgList, setDisplayedOrgList] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>(ALL);
    const [searchText, setSearchText] = useState<string>('');
    const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [refreshDeals, setRefreshDeals] = useState<boolean>(false);

    const pageParam = queryParams.get('page');
    const accessTierParam = queryParams.get('type');
    const refetchCompanyDeals = JSON.parse(queryParams.get('refetchCompanyDeals') || 'false');

    const ALL_STATUS = Object.keys(DealStatus).map((key) => DealStatus[key] as string);

    const redirectToPreferences = () => {
        const baseRoute = userType === USER_TYPE.LENDER ? LENDER_ROUTES : INVESTOR_ROUTES;
        navigate(`${baseRoute.APP}/${baseRoute.MANAGE_ELIGIBILITY}`);
    };

    const CATEGORY_OPTIONS = [
        { id: ALL, label: 'All', count: dealsData?.dealCount?.totalDeals, statuses: ALL_STATUS },
        {
            id: APPROVED,
            label: 'Approved',
            count: dealsData?.dealCount?.approvedDeals,
            statuses: [DealStatus.IPA_RECEIVED],
        },
        {
            id: PASSED,
            label: 'Passed',
            count: dealsData?.dealCount?.passedDeals,
            statuses: [DealStatus.PASSED_PRE_IPA],
        },
    ];

    useEffect(() => {
        if (orgList.length > 0 && orgListWithDeals.length > 0) {
            const filteredOrgs = orgList.filter((org) => orgListWithDeals.includes(org.assesseeOrgId));
            setFilteredOrgList(filteredOrgs);
            setDisplayedOrgList(filteredOrgs);
        }
    }, [orgList, orgListWithDeals]);

    useEffect(() => {
        updateQueryParams(
            queryParams,
            setQueryParams,
            {
                ...(dealsData?.pageNumber && { page: dealsData.pageNumber }),
                ...(!accessTierParam && { type: 'deals' }),
            },
            location,
        );
    }, [selectedCategory, dealsData?.pageNumber]);

    useEffect(() => {
        if (!platformData) return;
        if (fetchingDeals) return;

        if (userType === USER_TYPE.LENDER) {
            dispatch(
                getAllLenderDeals({
                    dealStatuses: CATEGORY_OPTIONS.find((category) => category.id === selectedCategory)?.statuses ?? ALL_STATUS,
                    dealReceiverId: user.userData.lenderOrgId as string,
                    pageNumber: pageParam ? parseInt(pageParam) : 1,
                    pageSize: 50,
                }),
            );
            if (refreshDeals) {
                setRefreshDeals(false);
            }
        } else {
            let platformId = getPlatformUserId();
            dispatch(
                getAllDeals({
                    dealProposerId: platformData.investorOrgId,
                    pageNumber: pageParam ? parseInt(pageParam) : 1,
                    pageSize: 50,
                    ownerUserId: platformId,
                    dealBeneficiaryId: selectedOrgId || params.id || undefined,
                    filters: {
                        dealBeneficiaryIds: selectedOrgId || params.id ? [selectedOrgId || (params.id as string)] : [],
                        ...dealFilters,
                    },
                }),
            );
            if (refreshDeals) {
                setRefreshDeals(false);
            }
        }
    }, [platformData, pageParam, selectedCategory, userType, selectedOrgId, dealFilters, refreshDeals]);

    useEffect(() => {
        if (!platformData?.investorOrgId) return;

        getAllTypeOrgList(platformData.investorOrgId, {
            onSuccess: (data) => {
                setOrgListWithDeals(data);
            },
            onError: () => {
                setOrgListWithDeals([]);
            },
        });
    }, [platformData]);

    const getOrganizationList = useCallback(() => {
        getOrgList(
            { pnoId: investorOrgId },
            {
                onSuccess: (res) => {
                    setOrgList(res);
                },
            },
        );
    }, [investorOrgId]);

    const fetchOwnerDetail = useCallback(() => {
        let payload: GetOwnerDataPayload = {
            investorOrgId: userData.investorOrgId,
        };
        dispatch(fetchOwners(payload));
    }, [userData.investorOrgId]);

    useEffect(() => {
        getOrganizationList();
        fetchOwnerDetail();
    }, [getOrganizationList, fetchOwnerDetail]);

    useEffect(() => {
        if (refetchCompanyDeals) {
            setRefreshDeals(true);
            queryParams.delete('refetchCompanyDeals');
            setQueryParams(queryParams);
        }
    }, [refetchCompanyDeals]);

    const changePageNumber = (newPageNumber: number) => {
        setQueryParams({ page: newPageNumber.toString() });
    };

    const getTotalCount = () => {
        // check if filter applied
        if (checkIfAnyFilterSelected(dealFilters)) {
            return dealsData?.dealCount?.filteredDeals;
        }
        switch (selectedCategory) {
            case ALL:
                return dealsData?.dealCount?.totalDeals;
            case APPROVED:
                return dealsData?.dealCount?.approvedDeals;
            case PASSED:
                return dealsData?.dealCount?.passedDeals;
            default:
                return 0;
        }
    };

    const handleSearch = (text: string) => {
        setSearchText(text);
        if (text === '') {
            setSelectedOrgId(null);
            setDisplayedOrgList(filteredOrgList);
        } else {
            const searchResults = filteredOrgList.filter((org) => org.orgName.toLowerCase().includes(text.toLowerCase()));
            setDisplayedOrgList(searchResults);
        }
    };

    const handleOrgSelect = (orgId: string) => {
        setSelectedOrgId(orgId);
        setSearchText('');
        queryParams.set('page', '1');
        setQueryParams(queryParams);
    };

    useEffect(() => {
        if (selectedOrgId) {
            queryParams.set('orgId', selectedOrgId);
        } else {
            queryParams.delete('orgId');
        }
        setQueryParams(queryParams);
    }, [selectedOrgId]);

    useEffect(() => {
        const orgIdFromUrl = queryParams.get('orgId');
        if (orgIdFromUrl !== selectedOrgId) {
            setSelectedOrgId(orgIdFromUrl);
        }
    }, [queryParams]);

    return {
        // States
        showDealDrawer,
        dealDrawerState,
        fetchingDeals,
        dealsData,
        userType,
        scrollPos,
        displayedOrgList,
        selectedCategory,
        accessTierParam,
        LENGTH,
        CATEGORY_OPTIONS,
        showFilters,
        dealFilters,
        selectedOrgId,
        // Methods
        setScrollPos,
        setSelectedCategory,
        getTotalCount,
        changePageNumber,
        handleSearch,
        handleOrgSelect,
        redirectToPreferences,
        setRefreshDeals,
    };
};
