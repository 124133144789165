// src/components/ManageDealDrawer/Sections/FormSection.tsx

import React from 'react';
import { DEBT_PRODUCT_FILTERS, EXCLUDED_LABEL_TYPES } from '../../DealDrawerConstants';
import { InputRenderer } from './InputRenderer';
import styles from '../../ManageDealDrawer.module.scss';

interface FormSectionProps {
    section: any;
    step: number;
    sectionIndex: number;
    totalSteps: number;
    formData: Record<string, any>;
    handleChange: (key: string, value: any) => void;
    dealDrawerState: any;
    lenderList: { id: string; name: string }[];
    loanTypeOptions: Record<string, any>;
    sectorList: any[];
    negativeSectorList: any[];
    source: string;
    setDisablePrimary: (value: boolean) => void;
    auditedfinancialsMapping: Record<string, { year?: number; value: string }>;
    isWebsiteValid: boolean;
    setIsWebsiteValid: (value: boolean) => void;
    getInputValidation: (value: string, key: string) => boolean;
    getButtonIcon: (text: string) => JSX.Element;
    getButtonText: () => string;
    validateWebsite: (value: string) => void;
    isWebsitePrefilled: boolean;
    negativeLenderList: any[];
}

export const FormSection: React.FC<FormSectionProps> = ({
    section,
    step,
    sectionIndex,
    source,
    totalSteps,
    formData,
    handleChange,
    dealDrawerState,
    sectorList,
    negativeSectorList,
    loanTypeOptions,
    lenderList,
    setDisablePrimary,
    auditedfinancialsMapping,
    isWebsiteValid,
    getInputValidation,
    getButtonIcon,
    getButtonText,
    validateWebsite,
    setIsWebsiteValid,
    isWebsitePrefilled,
    negativeLenderList,
}) => {
    return (
        <div className={styles.Section}>
            {section?.text && (
                <div className={styles.SectionHeading}>
                    {/* Show the step number only on the first section within that step */}
                    {sectionIndex === 0 && `${step}.`} {section?.text}
                </div>
            )}

            {/* Optionally, show a subheading or instructions for the first section */}
            {sectionIndex === 0 && section?.infoText && (
                <div className={styles.StepHeading}>
                    <span className={styles.StepHeadingSecondaryText}>{section.infoText}</span>
                </div>
            )}

            {(section.text || section.infoText) && <div className={styles.HorizontalSeparator} />}

            <div className={styles.InputList}>
                {Object.keys(section?.config)?.map((key: string) => {
                    const fieldConfig = section.config[key];

                    // If the type is in EXCLUDED_LABEL_TYPES, you might skip the label
                    // Or handle any "dependency" logic here if needed.

                    return (
                        <div
                            style={source === DEBT_PRODUCT_FILTERS ? { borderBottom: 'none', marginTop: '0' } : {}}
                            className={styles.InputItem}
                            key={`input-${key}`}
                        >
                            {!EXCLUDED_LABEL_TYPES.includes(fieldConfig?.type) && source !== DEBT_PRODUCT_FILTERS && (
                                <div className={styles.InputLabel}>
                                    <div>{fieldConfig.label}</div>
                                    {fieldConfig.subLabel && <div className={styles.SubLabel}>{fieldConfig.subLabel}</div>}
                                </div>
                            )}
                            <div style={{ width: '100%' }}>
                                <InputRenderer
                                    sectionKey={section.sectionKey}
                                    setDisablePrimary={setDisablePrimary}
                                    fieldKey={key}
                                    config={fieldConfig}
                                    formData={formData}
                                    handleChange={handleChange}
                                    dealDrawerState={dealDrawerState}
                                    lenderList={lenderList}
                                    loanTypeOptions={loanTypeOptions}
                                    sectorList={sectorList}
                                    negativeSectorList={negativeSectorList}
                                    source={source}
                                    step={step}
                                    auditedfinancialsMapping={auditedfinancialsMapping}
                                    isWebsiteValid={isWebsiteValid}
                                    setIsWebsiteValid={setIsWebsiteValid}
                                    getInputValidation={getInputValidation}
                                    getButtonIcon={getButtonIcon}
                                    getButtonText={getButtonText}
                                    validateWebsite={validateWebsite}
                                    isWebsitePrefilled={isWebsitePrefilled}
                                    negativeLenderList={negativeLenderList}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
