import { useGSTContext } from '../Context/GstContext';
import styles from './GSTModal.module.scss';
import GstOtp from './GstOtp/GstOtp';
import GstLoader from './GSTLoader/GstLoader';
import GstConsent from './GstConsent/GstConsent';
import GstErrorScreens from './GstErrorScreens/GstErrorScreens';
import { Modal } from '../../../../../../../common/_custom';

const EnumObject = {
    GstConsent: (props) => <GstConsent {...props} />,
    Loader: (props) => <GstLoader {...props} />,
    GstErrorScreens: (props) => <GstErrorScreens {...props} />,
    GstOtp: (props) => <GstOtp {...props} />,
};

const GSTModal = () => {
    const { showModal, handleCloseModal, handleBack, step } = useGSTContext();
    const Component = EnumObject[step.component](step.props);
    return (
        <Modal
            size={'flexible'}
            style={{ padding: '1rem' }}
            onClose={handleCloseModal}
            open={showModal}
        >
            <div style={{ width: '23rem' }}>
                <h3
                    className={styles.Heading}
                    onClick={() => {
                        step.component !== 'GstConsent' && handleBack(step.component);
                    }}
                >
                    GST Fetch
                </h3>
                {Component}
            </div>
        </Modal>
    );
};

export default GSTModal;
