import React from 'react';
// import dataSourceArrow from '../../../../assets/data-source-arrow.svg';
// import dataSourceArrowDisabled from '../../../../assets/data-source-arrow-disabled.svg';
import styles from './DataSource.module.scss';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import clsx from 'clsx';
import { infoIconGreen, infoIconYellow } from '../../../../assets/hostedassets';
import GstConnectionPopup from '../../DashboardModules/RevenueTables/GSTConnectionPopup/GstConnectionPopup';
import Tooltip from '../../../../common/_custom/Tooltip/Tooltip';

interface DataSourceProps {
    dataSource: Object;
    isLoading: boolean;
    selectedTab: string;
    isPDFReport?: boolean;
}

function DataSource({ dataSource, isLoading, selectedTab, isPDFReport = false }: DataSourceProps) {
    const keys = Object.keys(dataSource || {});
    const gstList = dataSource?.[1]?.gstList || [];
    const connectedGSTs = gstList.filter((gst) => gst?.connected);
    const isGstTab = ['revenue', 'vendorExpenses']?.includes(selectedTab);

    const isNotConnected = (value) => {
        return value?.[0] === '0';
    };

    const getGSTListForPopup = () => {
        const gstList = dataSource?.[1]?.gstList || [];
        return gstList?.map((gst) => ({
            gstin: gst?.gstNumber,
            connectionStatus: gst?.connected ? 'Connected' : 'Not Connected',
        }));
    };

    const getTextForGst = () => {
        const connectedGSTCount = connectedGSTs?.length;
        const totalGSTCount = gstList?.length;
        return `${connectedGSTCount}/${totalGSTCount}`;
    };

    return (
        <div className={styles.DataSource}>
            {!!keys?.length && (
                <>
                    {isLoading ? (
                        <LoadingSpinner color="#c9c9c9" height="20px" />
                    ) : (
                        dataSource && (
                            <div
                                className={clsx(styles.Content, {
                                    [styles.Animate]: !isPDFReport,
                                })}
                            >
                                {isGstTab ? 'GST Connected:' : 'Data Source'}
                                {keys.map((key) => (
                                    <div
                                        key={key}
                                        className={clsx(styles.SingleSource, {
                                            // [styles.NotConnected]: isNotConnected(
                                            //     dataSource[key]?.text,
                                            // ),
                                            [styles.NotAvailable]:
                                                isGstTab && isNotConnected(dataSource[key]?.text),
                                        })}
                                    >
                                        {dataSource[key]?.text}
                                        &nbsp;
                                        {isGstTab && (
                                            <Tooltip
                                                content={
                                                    <GstConnectionPopup
                                                        gstList={getGSTListForPopup()}
                                                    />
                                                }
                                                direction="top"
                                                style={{
                                                    left: '-200px',
                                                    top: '25px',
                                                }}
                                            >
                                                <img
                                                    src={
                                                        !isNotConnected(dataSource[key]?.text)
                                                            ? infoIconGreen
                                                            : infoIconYellow
                                                    }
                                                    alt={''}
                                                    height={'14px'}
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    );
}

export default DataSource;
