import { CONSTANTS } from './tooltip_constants';
let hideTooltipTimeout: any = null;

export const setTooltipContent = (payload: TooltipPayload) => {
    if (hideTooltipTimeout) {
        clearTimeout(hideTooltipTimeout);
        hideTooltipTimeout = null;
    }

    if (!payload.target || !payload.content) return { type: '' };

    const rect = payload.target.getBoundingClientRect();
    return {
        type: CONSTANTS.SET_TOOLTIP_DATA,
        payload: {
            showTooltip: true,
            position: {
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
                height: rect.height,
            },
            content: payload.content,
            placement: payload.placement,
        },
    };
};

export const removeTooltipContent = (dispatch) => {
    if (hideTooltipTimeout) {
        clearTimeout(hideTooltipTimeout);
    }
    hideTooltipTimeout = setTimeout(() => {
        dispatch({ type: CONSTANTS.REMOVE });
    }, 100);
};

export const cancelRemoveTooltipContent = () => {
    if (hideTooltipTimeout) {
        clearTimeout(hideTooltipTimeout);
        hideTooltipTimeout = null;
    }
    return { type: CONSTANTS.CANCEL_REMOVE };
};

interface TooltipPayload {
    target: HTMLElement | null;
    content: string | JSX.Element | null;
    placement?: 'top' | 'bottom' | 'left' | 'right';
}
