import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    AICAOverviewFeedbackType,
    fetchAICAOverviewData,
    sendAICAOverviewFeedback,
} from '../../store/company/action';
import { useParams } from 'react-router-dom';
import { isEmpty } from '../../utils/utils';

export type AicaOverviewData = {
    orgId: string;
    moduleId: string;
    moduleName: string;
    observations: string[];
    recommendations: string[];
    risks: string[];
    feedback: {
        feedbackType: AICAOverviewFeedbackType;
        feedback: string;
    };
    stateMachineStatus: string;
    createdAt: string;
};

const useAicaOverview = (module: string, resourceId?: string, isPDFReport: boolean = false) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const params = useParams();
    const [data, setData] = useState<AicaOverviewData | {}>({});
    const [feedback, setFeedback] = useState<{
        feedbackType: AICAOverviewFeedbackType;
        feedback: string;
    }>();
    const [pollCount, setPollCount] = useState(0);
    const pollRef = useRef<any>(null);

    const moduleMap = {
        overview: 'OVERVIEW',
        compliances: 'COMPLIANCE',
        creditBureau: 'CREDIT_BUREAU',
        relatedParty: 'RELATED_PARTY',
        auditedFinancials: 'AUDITED_FINANCIALS',
        revenue: 'GST_REVENUE',
        vendorExpenses: 'GST_VENDOR_EXPENSE',
        bankingAndFinancials: 'BANKING',
        cibilIndividual: 'CIBIL_INDIVIDUAL',
        legalChecks: 'LEGAL_CHECKS',
    };

    // Polling logic adjustment
    const fetchData = useCallback(() => {
        dispatch(
            fetchAICAOverviewData(
                {
                    orgId: params.id ?? '',
                    moduleId: moduleMap[module],
                    resourceId,
                },
                {
                    onSuccess: (response: AicaOverviewData) => {
                        if (response.stateMachineStatus === 'PENDING' && !isPDFReport) {
                            if (!pollRef.current) {
                                pollRef.current = setInterval(() => {
                                    setPollCount((prevCount) => prevCount + 1); // Use functional state update
                                }, 5000);
                            }
                        } else {
                            if (isPDFReport) setIsLoading(false);

                            clearInterval(pollRef.current);
                            pollRef.current = null;
                            setPollCount(0);
                            if (response.stateMachineStatus === 'FAILED')
                                setError('Summary generation failed!');
                            else {
                                setData(response);
                                if (!isEmpty(response.feedback?.feedbackType)) {
                                    setFeedback(response.feedback);
                                }
                                setError(null);
                            }
                        }
                    },
                    onError: (err) => {
                        if (pollRef.current) {
                            clearInterval(pollRef.current);
                            pollRef.current = null;
                        }
                        setPollCount(0);
                        setError(err);
                        setIsLoading(false);
                    },
                },
            ),
        );
    }, [dispatch, params.id, module]);

    const sendFeedbackForOverview = (type: AICAOverviewFeedbackType) => {
        // Send feedback to the backend
        dispatch(
            sendAICAOverviewFeedback(
                {
                    orgId: params.id ?? '',
                    moduleId: moduleMap[module],
                    resourceId,
                    feedback: {
                        feedbackType: type,
                        feedback: '',
                    },
                },
                {
                    onSuccess: () => {
                        setFeedback({
                            feedbackType: type,
                            feedback: '',
                        });
                    },
                },
            ),
        );
    };

    // Start polling when pollCount changes, and stop after a limit
    useEffect(() => {
        if (pollCount > 18) {
            clearInterval(pollRef.current);
            setError('Polling limit exceeded');
            pollRef.current = null;
        } else if (pollCount > 0) {
            fetchData(); // Fetch data during each poll cycle
        }
    }, [pollCount, fetchData]);

    useEffect(() => {
        setData({});
        if (pollCount === 0 && !pollRef.current) {
            setError(null);
            fetchData();
        }
    }, [module]);

    useEffect(() => {
        setIsLoading(true);
        let timeout;
        if (!isEmpty(data)) {
            timeout = setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [data]);

    return {
        data,
        isLoading,
        error,
        feedback,
        sendFeedbackForOverview,
    };
};

export default useAicaOverview;
