import React, { useEffect, useState } from 'react';
import profileStyles from './UserProfile.module.scss';
import user from '../../../../../../../assets/user.png';
import LoadingSpinner from '../../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { fetchManagementProfileUserData } from '../../../../../../../store/dashboard/overview/overview_apiHelper';
import { useParams } from 'react-router-dom';
import { toTitleCase } from '../../../../../../../utils/utils';
import greenTick from '../../../../../../../assets/green-tick.svg';
import linkedin from '../../../../../../../assets/linkedin.svg';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ctaPurpleRight, userBlack, alertIcon } from '../../../../../../../assets/hostedassets';

type DirectorShip = {
    dateOfAppointment?: string;
    entityId?: string;
    entityName?: string;
    fromDate?: string;
    toDate?: string;
};

type WorkEx = {
    companyName?: string;
    companyUrl?: string;
    endDate?: string;
    startDate?: string;
};

type Education = {
    endDate?: string;
    schoolName?: string;
    startDate?: string;
};

interface UserData {
    address?: string;
    age?: string;
    creditScore?: number;
    directorId?: string;
    designation?: string;
    dob?: string;
    din?: string;
    directorSince?: string;
    imageUrl?: string;
    isActive?: boolean;
    gender?: string;
    legalCasesCount?: number;
    name?: string;
    pan?: string;
    subTitle?: string;
    tenureBeginDate?: number;
    tenureEndDate?: number;
    currentEntities?: DirectorShip[];
    formerEntities?: DirectorShip[];
    workExperience?: WorkEx[];
    education?: Education[];
    linkedinUrl?: string;
    followersCount?: string;
    phone?: string;
    email?: string;
    riskIndicatorCount?: number;
}

const AION_FETCH_LITIGATION_CLASSIFICATION_DATA = 'AION_FETCH_LITIGATION_CLASSIFICATION_DATA';

const ManagementUserProfile = ({
    showUserProfile,
    redirectToCreditBureau = (val) => {},
    redirectToCompliances = () => {},
    isPDFReport = false,
}) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState<null | UserData>(null);
    const [litigationProcessed, setLitigationProcessed] = useState(false);

    const showLinkedinSection = !!(
        userData?.linkedinUrl ||
        userData?.followersCount ||
        userData?.workExperience?.length ||
        userData?.education?.length
    );

    const showDirectorshipsSection = !!(
        userData?.currentEntities?.length || userData?.formerEntities?.length
    );

    const hideSectionForPDF = (value) => {
        if (isPDFReport && (value === undefined || value === null)) return true;
        return false;
    };
    useEffect(() => {
        showUserProfile && fetchData();
    }, [showUserProfile]);

    const fetchData = () => {
        fetchManagementProfileUserData({
            orgId: params.id || '',
            name: showUserProfile?.name,
            entityId: showUserProfile?.din,
            taskStepsList: [AION_FETCH_LITIGATION_CLASSIFICATION_DATA],
        })
            .then((res) => {
                let taskTrackerData = res?.taskTrackerData;
                let profileData = res?.managementProfile?.profile || {};
                setUserData(profileData);

                if (
                    AION_FETCH_LITIGATION_CLASSIFICATION_DATA in taskTrackerData &&
                    taskTrackerData[AION_FETCH_LITIGATION_CLASSIFICATION_DATA] !== 'TODO'
                ) {
                    setLitigationProcessed(true);
                }
            })
            .catch((err) => {
                console.error('Error in fetching management profile data', err);
            })
            .finally(() => setLoading(false));
    };

    const NoData = () => <span className={profileStyles.Nodata}>No data available</span>;

    const formatDateString = (dateString) => {
        if (!dateString || dateString === 'DD-MM-YYYY') return null;
        try {
            const components = dateString.split('-');
            // If date has all three components, return in format 'MM-YYYY'
            if (
                components?.[0] !== 'DD' &&
                components?.[1] !== 'MM' &&
                components?.[2] !== 'YYYY'
            ) {
                return dayjs(dateString, 'DD-MM-YYYY').format('MMM YYYY');
            }
            // If the day is missing, format as 'MM-YYYY'
            if (
                components?.[0] === 'DD' &&
                components?.[1] !== 'MM' &&
                components?.[2] !== 'YYYY'
            ) {
                return dayjs(dateString.replace('DD-', ''), 'MM-YYYY').format('MMM YYYY');
            }
            // If both day and month are missing, format as 'YYYY'
            if (
                components?.[0] === 'DD' &&
                components?.[1] === 'MM' &&
                components?.[2] !== 'YYYY'
            ) {
                return dayjs(dateString.replace('DD-MM-', ''), 'YYYY').format('YYYY');
            }
        } catch {
            return null;
        }
    };

    return (
        <div
            className={clsx(profileStyles.UserProfile, { [profileStyles.PDFReport]: isPDFReport })}
        >
            <div className={profileStyles.Heading}>
                Management Profile
                <span className={profileStyles.Name}> | {toTitleCase(showUserProfile?.name)}</span>
            </div>

            <div className={clsx(profileStyles.Body)}>
                {loading ? (
                    <LoadingSpinner
                        color={'#6021b3'}
                        height={'30px'}
                        style={{ marginTop: '40px' }}
                    />
                ) : (
                    <div className={profileStyles.Container}>
                        <div className={profileStyles.Top}>
                            <div className={profileStyles.Image}>
                                <img
                                    src={userData?.imageUrl || user}
                                    alt="profile"
                                    width={'100%'}
                                />
                            </div>

                            <div>
                                <div
                                    className={profileStyles.Name}
                                    style={{ ...(isPDFReport && { fontSize: '16px' }) }}
                                >
                                    {userData?.name || showUserProfile?.name || '-'}
                                </div>
                                <div className={profileStyles.InfoPills}>
                                    {userData?.dob && (
                                        <div
                                            className={profileStyles.Pill}
                                            style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                        >
                                            DOB:&nbsp;
                                            <span className={profileStyles.Black}>
                                                {userData?.dob}&nbsp;
                                            </span>
                                            <>{userData?.age && `(${userData?.age})`}</>
                                        </div>
                                    )}
                                    {userData?.gender && (
                                        <div
                                            className={profileStyles.Pill}
                                            style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                        >
                                            <img src={userBlack} alt={''} height={'16px'} />
                                            <span className={profileStyles.Black}>
                                                {toTitleCase(userData?.gender)}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div
                            className={profileStyles.SectionHeading}
                            style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                        >
                            <div></div>
                            <div>Director Information</div>
                        </div>
                        <div
                            className={profileStyles.DataRows}
                            style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                        >
                            {!hideSectionForPDF(userData?.isActive) && (
                                <div className={profileStyles.Row}>
                                    <div className={profileStyles.Key}>Status</div>
                                    <div
                                        className={clsx(profileStyles.Value, {
                                            [profileStyles.Active]: true,
                                            [profileStyles.Former]: !userData?.isActive,
                                        })}
                                    >
                                        {userData?.isActive === null ||
                                        userData?.isActive === undefined ? (
                                            <NoData />
                                        ) : (
                                            <div
                                                className={clsx({
                                                    [profileStyles.Active]: userData?.isActive,
                                                    [profileStyles.Former]: !userData?.isActive,
                                                })}
                                            >
                                                {userData?.isActive ? 'Active' : 'Former'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {!hideSectionForPDF(showUserProfile?.designation) && (
                                <div className={profileStyles.Row}>
                                    <div className={profileStyles.Key}>Designation</div>
                                    <div className={profileStyles.Value}>
                                        {showUserProfile?.designation || <NoData />}
                                    </div>
                                </div>
                            )}
                            {!hideSectionForPDF(userData?.directorSince) && (
                                <div className={profileStyles.Row}>
                                    <div className={profileStyles.Key}>Tenure</div>
                                    <div className={profileStyles.Value} style={{ padding: 0 }}>
                                        <div className={profileStyles.DirectorShip}>
                                            {userData?.directorSince || <NoData />}

                                            <div className={profileStyles.Dates}>
                                                {(userData?.isActive === null ||
                                                    userData?.isActive === undefined) &&
                                                !userData?.tenureBeginDate &&
                                                !userData?.tenureEndDate ? (
                                                    'NA'
                                                ) : (
                                                    <>
                                                        (
                                                        {userData?.tenureBeginDate &&
                                                        dayjs(userData?.tenureBeginDate).isValid()
                                                            ? dayjs(
                                                                  userData?.tenureBeginDate,
                                                              ).format('MMM YYYY')
                                                            : 'NA'}
                                                        -{' '}
                                                        {userData?.tenureEndDate &&
                                                        dayjs(userData?.tenureEndDate).isValid()
                                                            ? dayjs(userData?.tenureEndDate).format(
                                                                  'MMM YYYY',
                                                              )
                                                            : userData?.isActive
                                                            ? 'Present'
                                                            : 'NA'}
                                                        )
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!hideSectionForPDF(userData?.din) && (
                                <div className={profileStyles.Row}>
                                    <div className={profileStyles.Key}>DIN No.</div>
                                    <div className={profileStyles.Value}>
                                        {userData?.din || <NoData />}
                                    </div>
                                </div>
                            )}

                            {!hideSectionForPDF(userData?.pan) && (
                                <div className={profileStyles.Row}>
                                    <div className={profileStyles.Key}>PAN</div>
                                    <div className={profileStyles.Value}>
                                        {userData?.pan || <NoData />}
                                    </div>
                                </div>
                            )}
                        </div>

                        {!hideSectionForPDF(
                            userData?.phone || userData?.email || userData?.address,
                        ) && (
                            <div
                                className={profileStyles.SectionHeading}
                                style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                            >
                                <div></div>
                                <div> Contact Information</div>
                            </div>
                        )}
                        {!hideSectionForPDF(
                            userData?.phone || userData?.email || userData?.address,
                        ) && (
                            <div
                                className={profileStyles.DataRows}
                                style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                            >
                                {!hideSectionForPDF(userData?.phone) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Phone No.</div>
                                        <div className={profileStyles.Value}>
                                            {userData?.phone || <NoData />}
                                        </div>
                                    </div>
                                )}
                                {!hideSectionForPDF(userData?.email) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Email</div>
                                        <div className={profileStyles.Value}>
                                            {userData?.email || <NoData />}
                                        </div>
                                    </div>
                                )}
                                {!hideSectionForPDF(userData?.address) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Address</div>
                                        <div className={profileStyles.Value}>
                                            {userData?.address || <NoData />}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {!hideSectionForPDF(
                            !Number.isNaN(Number(userData?.creditScore)) ||
                                userData?.legalCasesCount ||
                                userData?.riskIndicatorCount,
                        ) && (
                            <div
                                className={profileStyles.SectionHeading}
                                style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                            >
                                <div></div>
                                <div>Regulatory Information</div>
                            </div>
                        )}
                        {!hideSectionForPDF(
                            !Number.isNaN(Number(userData?.creditScore)) ||
                                userData?.legalCasesCount ||
                                userData?.riskIndicatorCount,
                        ) && (
                            <div
                                className={profileStyles.DataRows}
                                style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                            >
                                {!hideSectionForPDF(userData?.creditScore) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Credit Bureau</div>
                                        <div
                                            className={clsx(
                                                profileStyles.Value,
                                                profileStyles.Cta,
                                                profileStyles.Flex,
                                            )}
                                            onClick={() => {
                                                if (!Number.isNaN(Number(userData?.creditScore)))
                                                    redirectToCreditBureau?.(userData?.directorId);
                                            }}
                                            style={{ color: isPDFReport ? 'black' : '' }}
                                        >
                                            {userData?.creditScore === null ||
                                            userData?.creditScore === undefined ? (
                                                <NoData />
                                            ) : (
                                                <>
                                                    {!isPDFReport && (
                                                        <img
                                                            src={ctaPurpleRight}
                                                            alt={''}
                                                            height={'14px'}
                                                        />
                                                    )}
                                                    {userData?.creditScore}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!hideSectionForPDF(userData?.legalCasesCount) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Legal Cases</div>
                                        <div
                                            className={clsx(profileStyles.Value, {
                                                [profileStyles.CtaRed]:
                                                    !!userData?.legalCasesCount && !isPDFReport,
                                                [profileStyles.Flex]: true,
                                            })}
                                            onClick={() => {
                                                if (userData?.legalCasesCount)
                                                    redirectToCompliances?.();
                                            }}
                                        >
                                            {userData?.legalCasesCount === null ||
                                            userData?.legalCasesCount === undefined ||
                                            !litigationProcessed ? (
                                                <NoData />
                                            ) : userData?.legalCasesCount ? (
                                                <>
                                                    {!isPDFReport && (
                                                        <img
                                                            src={alertIcon}
                                                            alt={''}
                                                            height={'14px'}
                                                        />
                                                    )}
                                                    {`${userData?.legalCasesCount || 0} Cases`}
                                                </>
                                            ) : (
                                                <>
                                                    {!isPDFReport && (
                                                        <img
                                                            src={greenTick}
                                                            alt={''}
                                                            height={'14px'}
                                                        />
                                                    )}
                                                    No legal cases found
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!hideSectionForPDF(userData?.riskIndicatorCount) && (
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Risk Indicators</div>
                                        <div
                                            className={clsx(profileStyles.Value, {
                                                [profileStyles.CtaRed]:
                                                    !!userData?.riskIndicatorCount && !isPDFReport,
                                                [profileStyles.Flex]: true,
                                            })}
                                            onClick={() => {
                                                if (userData?.riskIndicatorCount)
                                                    redirectToCompliances?.();
                                            }}
                                        >
                                            {userData?.riskIndicatorCount === null ||
                                            userData?.riskIndicatorCount === undefined ? (
                                                <NoData />
                                            ) : userData?.riskIndicatorCount > 0 ? (
                                                <>
                                                    {!isPDFReport && (
                                                        <img
                                                            src={alertIcon}
                                                            alt={''}
                                                            height={'14px'}
                                                        />
                                                    )}
                                                    {`${userData?.riskIndicatorCount || 0} ${
                                                        userData?.riskIndicatorCount === 1
                                                            ? 'indicator'
                                                            : 'indicators'
                                                    }`}
                                                </>
                                            ) : (
                                                <>
                                                    {!isPDFReport && (
                                                        <img
                                                            src={greenTick}
                                                            alt={''}
                                                            height={'14px'}
                                                        />
                                                    )}
                                                    No indicators found
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {showDirectorshipsSection && (
                            <>
                                <div
                                    className={profileStyles.SectionHeading}
                                    style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                >
                                    <div></div>
                                    <div>Other Directorships</div>
                                </div>
                                <div
                                    className={profileStyles.DataRows}
                                    style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                >
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>
                                            Present Directorship
                                        </div>
                                        <div
                                            className={profileStyles.Value}
                                            style={{
                                                padding: userData?.currentEntities?.length
                                                    ? '0'
                                                    : '',
                                            }}
                                        >
                                            {userData?.currentEntities?.length ? (
                                                userData?.currentEntities?.map(
                                                    (directorship: DirectorShip, i) => {
                                                        let fromDate = directorship?.fromDate
                                                            ? dayjs(directorship?.fromDate).format(
                                                                  'MMM YYYY',
                                                              )
                                                            : null;
                                                        let toDate = directorship?.toDate
                                                            ? dayjs(directorship?.toDate).format(
                                                                  'MMM YYYY',
                                                              )
                                                            : null;
                                                        return (
                                                            directorship?.entityName && (
                                                                <div
                                                                    className={
                                                                        profileStyles.DirectorShip
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {toTitleCase(
                                                                        directorship?.entityName,
                                                                    )}

                                                                    <div
                                                                        className={
                                                                            profileStyles.Dates
                                                                        }
                                                                    >
                                                                        ({fromDate || 'NA'} -{' '}
                                                                        {toDate || 'Present'})
                                                                    </div>
                                                                </div>
                                                            )
                                                        );
                                                    },
                                                )
                                            ) : (
                                                <span className={profileStyles.Nodata}>
                                                    No other present directorships
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Past Directorships</div>
                                        <div
                                            className={profileStyles.Value}
                                            style={{
                                                padding: userData?.formerEntities?.length
                                                    ? '0'
                                                    : '',
                                            }}
                                        >
                                            {userData?.formerEntities?.length ? (
                                                userData?.formerEntities?.map(
                                                    (directorship: DirectorShip, i) => {
                                                        let fromDate = directorship?.fromDate
                                                            ? dayjs(directorship?.fromDate).format(
                                                                  'MMM YYYY',
                                                              )
                                                            : null;
                                                        let toDate = directorship?.toDate
                                                            ? dayjs(directorship?.toDate).format(
                                                                  'MMM YYYY',
                                                              )
                                                            : null;
                                                        return (
                                                            directorship?.entityName && (
                                                                <div
                                                                    className={
                                                                        profileStyles.DirectorShip
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {toTitleCase(
                                                                        directorship?.entityName,
                                                                    )}

                                                                    <div
                                                                        className={
                                                                            profileStyles.Dates
                                                                        }
                                                                    >
                                                                        ({fromDate || 'NA'} -{' '}
                                                                        {toDate || 'NA'})
                                                                    </div>
                                                                </div>
                                                            )
                                                        );
                                                    },
                                                )
                                            ) : (
                                                <span className={profileStyles.Nodata}>
                                                    No other past directorships
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {showLinkedinSection && (
                            <>
                                <div
                                    className={profileStyles.SectionHeading}
                                    style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                >
                                    <div></div>
                                    <div>Linkedin Profile</div>
                                </div>
                                <div
                                    className={profileStyles.DataRows}
                                    style={{ ...(isPDFReport && { fontSize: '11px' }) }}
                                >
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Linkedin Profile</div>
                                        <div className={profileStyles.Value}>
                                            {userData?.linkedinUrl ? (
                                                <a
                                                    className={profileStyles.Flex}
                                                    href={userData?.linkedinUrl}
                                                    target="_blank"
                                                    style={{ color: '#006699' }}
                                                >
                                                    <img src={linkedin} alt={''} height={'20px'} />
                                                    {userData?.followersCount
                                                        ? `${userData?.followersCount} Followers`
                                                        : userData?.linkedinUrl && 'View Profile'}
                                                </a>
                                            ) : (
                                                <NoData />
                                            )}
                                        </div>
                                    </div>
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Linkedin Headline</div>
                                        <div className={profileStyles.Value}>
                                            {userData?.subTitle || <NoData />}
                                        </div>
                                    </div>
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Work Experience</div>
                                        <div
                                            className={profileStyles.Value}
                                            style={{
                                                padding: userData?.workExperience?.length ? 0 : '',
                                            }}
                                        >
                                            {userData?.workExperience?.length ? (
                                                userData?.workExperience?.map((exp: WorkEx, i) => {
                                                    let fromDate = formatDateString(exp?.startDate);
                                                    let toDate = formatDateString(exp?.endDate);
                                                    return (
                                                        <div
                                                            className={profileStyles.DirectorShip}
                                                            key={i}
                                                        >
                                                            {exp.companyName}

                                                            <div className={profileStyles.Dates}>
                                                                ({fromDate || 'NA'} -{' '}
                                                                {toDate
                                                                    ? toDate
                                                                    : i === 0
                                                                    ? 'Present'
                                                                    : 'NA'}
                                                                )
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <NoData />
                                            )}
                                        </div>
                                    </div>
                                    <div className={profileStyles.Row}>
                                        <div className={profileStyles.Key}>Education</div>
                                        <div
                                            className={profileStyles.Value}
                                            style={{
                                                padding: userData?.education?.length ? '0' : '',
                                            }}
                                        >
                                            {userData?.education?.length ? (
                                                userData?.education?.map((ed: Education, i) => {
                                                    let fromDate = formatDateString(ed?.startDate);
                                                    let toDate = formatDateString(ed?.endDate);
                                                    return (
                                                        <div
                                                            className={profileStyles.DirectorShip}
                                                            key={i}
                                                        >
                                                            {ed?.schoolName}

                                                            <div className={profileStyles.Dates}>
                                                                ({fromDate || 'NA'} -{' '}
                                                                {toDate || 'NA'})
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <NoData />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default ManagementUserProfile;
