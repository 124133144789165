import React from 'react';
import styles from './UnlockScanModal.module.scss';
import greyCross from '../../../../assets/grey-cross.svg';
import greenTick from '../../../../assets/green-tick-2.svg';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import { Button } from '../../../../common/_custom';
import { ACCESS_TIERS } from '../../../../store/investor/constants';
import { AICAUpgradeImg } from '../../../../assets/hostedassets';

function UnlockScanModal({ showUpgradeToScanModal, openModal, closeModal }) {
    const getUnlockTierText = () => {
        switch (showUpgradeToScanModal) {
            case ACCESS_TIERS.SCAN:
                return 'Unlock Scan and Track';
            case ACCESS_TIERS.TRACK:
                return 'Upgrade to Track';
        }
    };
    return (
        <SideDrawer
            allowClose={false}
            // heading={`Upgrade to ${getUnlockTierText()}`}
            onClose={() => closeModal()}
            width="30%"
        >
            <div className={styles.UnlockScanModal} style={{ height: '100%' }}>
                <div>
                    <div className={styles.Top}>
                        {/* {getUnlockTierText()} */}
                        Unlock Scan and Track
                        <img
                            src={greyCross}
                            alt=""
                            height={'20px'}
                            onClick={() => closeModal()}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className={styles.Body}>
                        <img src={AICAUpgradeImg} width="70%" alt="aicaupgrade" />

                        <div className={styles.Points}>
                            <div className={styles.Point}>
                                <img src={greenTick} alt="green" height={'20px'} />
                                Uncover hidden insights into company financials using advanced
                                analytics on GST, Bank Statements, Financial Statements and Bureau
                                Reports
                            </div>
                            <div className={styles.Point}>
                                <img src={greenTick} alt="green" height={'20px'} />
                                Identify Early Warning Signals recommended by RBI to address risks
                                in-time
                            </div>
                            {/* <div className={styles.Point}>
                                <img src={greenTick} alt="green" height={'20px'} />
                                Proactively monitor portfolio health using custom alerts on company
                                financials, compliances and social presence
                            </div> */}
                            <div className={styles.Point}>
                                <img src={greenTick} alt="green" height={'20px'} />
                                Reconcile data across multiple sources to validate legitimacy of
                                financial data
                            </div>
                            <div className={styles.Point}>
                                <img src={greenTick} alt="green" height={'20px'} />
                                Proactively monitor portfolio health using custom alerts on company
                                financials, compliances and social presence and receive
                                notifications on important company updates.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Footer}>
                    <Button
                        variant="purple"
                        text="Talk to Us"
                        onClick={() => {
                            window.open('https://calendly.com/shubhangi-singhal5', '_blank');
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
        </SideDrawer>
    );
}

export default UnlockScanModal;
