import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import styles from './StatusDropdown.module.scss';
import { removeTooltipContent, setTooltipContent } from '../../../../../../../../../store/tooltip/tooltip_actions';
import { useAppDispatch } from '../../../../../../../../../app/hooks';

export type StatusDropdownOption = {
    label: string;
    value: string;
    backgroundColor: string;
    labelColor: string;
    customCaret?: string | JSX.Element;
};

type Props = {
    dropdownOptions: StatusDropdownOption[];
    selectedOption: StatusDropdownOption;
    selectOption: (option: StatusDropdownOption) => void;
    disabled?: boolean;
    source?: string;
    customTrigger?: React.ReactNode;
};

const StatusDropdown: React.FC<Props> = ({ dropdownOptions, selectedOption, selectOption, disabled = false, source, customTrigger }) => {
    const dropdownRef = useRef(null);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        if (disabled) return;
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleOnChange = (option: StatusDropdownOption) => {
        selectOption(option);
        setIsOpen(false);
    };

    const handleMouseEnterName = (e) => {
        if (selectedOption.label)
            dispatch(
                setTooltipContent({
                    content: selectedOption.label,
                    target: e.currentTarget,
                }),
            );
    };

    const handleMouseLeaveName = () => {
        removeTooltipContent(dispatch);
    };

    return (
        <div className={styles.StatusDropdown} ref={dropdownRef}>
            {customTrigger ? (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                    }}
                >
                    {customTrigger}
                </div>
            ) : (
                <button
                    className={styles.DropdownToggle}
                    style={{
                        backgroundColor: selectedOption.backgroundColor,
                        color: selectedOption.labelColor,
                        cursor: source !== 'investor-deal' ? 'pointer' : 'default',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown();
                    }}
                >
                    <span className={styles.SelectedText} onMouseEnter={handleMouseEnterName} onMouseLeave={handleMouseLeaveName}>
                        {selectedOption.label}
                    </span>
                    {!disabled &&
                        (typeof selectedOption.customCaret === 'string' ? (
                            <img
                                className={clsx(styles.DropdownCaret, {
                                    [styles.CaretOpen]: isOpen,
                                })}
                                src={selectedOption.customCaret}
                                alt="Caret"
                            />
                        ) : (
                            <span
                                className={clsx(styles.DropdownCustomCaretIcon, {
                                    [styles.CaretOpenIcon]: isOpen,
                                })}
                            >
                                {selectedOption.customCaret}
                            </span>
                        ))}
                </button>
            )}
            {isOpen && (
                <div className={styles.DropdownMenu}>
                    {dropdownOptions?.map((option) => (
                        <button
                            key={`status-dropdown-${option.value}`}
                            className={styles.DropdownCell}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOnChange(option);
                            }}
                            title=""
                        >
                            <div
                                key={option.label}
                                className={styles.OptionPill}
                                style={{
                                    backgroundColor: option.backgroundColor,
                                    color: option.labelColor,
                                }}
                            >
                                {option.label}
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StatusDropdown;
