import React from 'react';
import FormPage from '../../../../../../common/DealApplicationDrawer/Components/Page/FormPage';
import { DealTermsLogType as DealTermsLogType } from '../DealTermsLoggingDrawer/DealTermsLoggingDrawer';
import styles from './DealTermsLog.module.scss';
import { useAppSelector } from '../../../../../../app/hooks';

interface DealTermsLogProps {
    formData: DealTermsLogType;
    dealTermsLoggingConfig: any;
    setIsFormValid: (isValid: boolean) => void;
    onUpdateLog: (updatedLog: DealTermsLogType) => void;
}

export const DealTermsLog: React.FC<DealTermsLogProps> = ({ formData, dealTermsLoggingConfig, setIsFormValid, onUpdateLog }) => {
    const userName = useAppSelector((state) => state.user.userData.userName);

    const updateDealLogs = (key: string, value: string | string[]) => {
        if (key === 'dealNotes') {
            const updatedLog = {
                ...formData,
                dealTerms: {
                    ...formData.dealTerms,
                    [key]: [
                        {
                            note: Array.isArray(value) ? value.join(', ') : value,
                            noteBy: userName,
                            noteDate: new Date().toISOString(),
                        },
                    ],
                },
            };
            onUpdateLog(updatedLog);
            return;
        } else if (key === 'logDate') {
            const updatedLog = {
                ...formData,
                logDate: Array.isArray(value) ? value[0] : value,
                dealTerms: {
                    ...formData.dealTerms,
                    [key]: Array.isArray(value) ? value[0] : value,
                },
            };
            onUpdateLog(updatedLog);
            return;
        }

        const updatedLog = {
            ...formData,
            dealTerms: {
                ...formData.dealTerms,
                [key]: value,
            },
        };
        onUpdateLog(updatedLog);
    };

    return (
        <div className={styles.logContainer}>
            <FormPage
                step={0}
                setIsFormValid={setIsFormValid}
                page={dealTermsLoggingConfig?.mainConfig?.additionalDetailsConfig?.pages[0]?.config}
                formData={formData}
                updateDealLogs={updateDealLogs}
            />
        </div>
    );
};

export default DealTermsLog;
