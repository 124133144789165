import styles from './TooltipV2.module.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { cancelRemoveTooltipContent, removeTooltipContent } from '../../../store/tooltip/tooltip_actions';

function TooltipV2() {
    const dispatch = useAppDispatch();
    const { position, content, showTooltip, placement } = useAppSelector((state) => state.tooltipReducer);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [coords, setCoords] = useState<any>({ top: 0, left: 0 });

    useEffect(() => {
        if (position && showTooltip && tooltipRef.current) {
            const tooltipElement = tooltipRef.current;
            if (tooltipElement) {
                const tooltipRect = tooltipElement.getBoundingClientRect();
                const newCoords = calculatePosition(position, tooltipRect, placement);
                newCoords && setCoords(newCoords);
            }
        } else {
            setCoords(null);
        }
    }, [position, showTooltip, placement, tooltipRef.current]);

    const calculatePosition = (position, tooltipRect, placement) => {
        const { top, left, width, height } = position;
        const { width: tooltipWidth, height: tooltipHeight } = tooltipRect;
        const offset = 10; // 10px offset

        switch (placement) {
            case 'top':
                return {
                    top: top - tooltipHeight - offset,
                    left: left + width / 2 - tooltipWidth / 2,
                };
            case 'bottom':
                return { top: top + height + offset, left: left + width / 2 - tooltipWidth / 2 };
            case 'left':
                return {
                    top: top + height / 2 - tooltipHeight / 2,
                    left: left - tooltipWidth - offset,
                };
            case 'right':
                return { top: top + height / 2 - tooltipHeight / 2, left: left + width + offset };
            default:
                return null;
        }
    };

    const handleMouseEnter = () => {
        cancelRemoveTooltipContent();
    };

    const handleMouseLeave = () => {
        removeTooltipContent(dispatch);
    };

    return position && showTooltip ? (
        <div
            ref={tooltipRef}
            className={clsx(styles.TooltipV2, { [styles.TooltipV2__Show]: showTooltip && !!coords })}
            style={{
                top: coords?.top,
                left: coords?.left,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {content}
        </div>
    ) : (
        <></>
    );
}

export default TooltipV2;
