import { ShimmerThumbnail, ShimmerTitle, ShimmerText } from 'react-shimmer-effects';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../app/hooks';
import ChecksTable from './components/ChecksTable/ChecksTable';
import styles from './Checks.module.scss';
import { useEffect, useState } from 'react';
import { getAlerts } from '../../../../../../store/dashboard/AlertEngine/alertEngine_apiHelpers';
import { isEmpty } from 'lodash';
import A4Page from '../../../../../AicaPDFReport/A4Page/A4Page';

export type CriterionRow = {
    criterionResult: string;
    description: string;
    category: string;
    alertDate: string;
};

function Checks({
    isLoading,
    isGeneratingProfile,
    isPDFReport = false,
}: {
    isLoading: boolean;
    isGeneratingProfile: boolean;
    isPDFReport?: boolean;
}) {
    const params = useParams();
    const user = useAppSelector((state) => state.user);

    const [totalRecords, setTotalRecords] = useState(0);
    const [rowData, setRowData] = useState<CriterionRow[]>([]);
    const [filters, setFilters] = useState({
        date: 1,
        severity: 1,
    });

    const showTable = !isEmpty(rowData) || !isGeneratingProfile;

    useEffect(() => {
        if (isEmpty(rowData)) getTableData();
    }, []);

    const getTableData = async () => {
        const payload = {
            assesseOrgId: params?.id ?? '',
            parentOrgId: user?.userData?.investorOrgId,
            alertTypes: ['CRITERIA'],
            pageNumber: 1,
            pageSize: 100, //@todo: as we don't have pagination for checks table we're hardcoding this.
        };
        const res = await getAlerts(payload);
        if (res) {
            setRowData(res.criteria);
            setTotalRecords(res.totalRecords);
        }
    };

    if (isEmpty(rowData)) {
        return <></>;
    }

    const render = (
        <div className={styles.Checks} style={{ display: showTable ? '' : 'none' }}>
            <div className={styles.Top}>
                <div className={styles.Title}>Checks</div>
                <div className={styles.Right}></div>
            </div>
            {isLoading ? (
                <div className={styles.Body}>
                    <ShimmerTitle line={1} gap={20} variant="primary" />
                    <ShimmerThumbnail height={78} rounded />
                    <ShimmerText line={12} gap={10} className={styles.ShimmerText} />
                </div>
            ) : (
                <div className={styles.Body}>
                    <ChecksTable
                        assesseOrgId={params?.id ?? ''}
                        parentOrgId={user?.userData?.investorOrgId}
                        rowData={rowData}
                    />
                </div>
            )}
        </div>
    );

    if (isPDFReport) return <></>;
    else return render;
}

export default Checks;
