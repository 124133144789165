import { CONSTANTS } from './constants';

export const getAuditedFinancialsReportId = (
    payload: {
        assesseeOrgId: string;
    },
    handlers,
) => {
    return {
        type: CONSTANTS.GET_AUDITED_FINANCIALS_REPORT_ID,
        payload,
        handlers,
    };
};
