import React, { FC, useCallback, useEffect, useRef, useState, RefObject } from 'react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact, ReactEditor } from 'slate-react';

// Your other plugins
import withLinks from './plugins/withLinks';
import withTables from './plugins/withTable';
import withEmbeds from './plugins/withEmbeds';
import withEquation from './plugins/withEquation';

// The new Excel plugin

import Toolbar from './Toolbar/Toolbar';
import { getBlock, getMarked, toggleMark } from './utils/SlateUtilityFunctions';
import TextEditorShimmerLoader from './TextEditorShimmerLoader';

import styles from './TextEditor.module.scss';
import withExcelPaste from './utils/withExcelPaste';

// --------------------------------------
// Types
// --------------------------------------
interface SlateEditorProps {
    source: string;
    editorInitialData: Descendant[];
    editorContentRef: React.MutableRefObject<Descendant[]>;
    targetRef?: RefObject<HTMLDivElement>;
    assesseeOrgId?: string;
    generating?: boolean;
    onClose?: () => void;
    savingData: boolean;
}

// We extend the base Slate editor to include React/History types
// If you have custom node types, define them in a `CustomTypes` definition file
// and import them appropriately.
type CustomEditor = ReactEditor;

// Simple element component
const Element: FC<any> = (props) => {
    return getBlock(props);
};

// Simple leaf component
const Leaf: FC<any> = (props) => {
    let { attributes, children, leaf } = props;
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>;
};

const TextEditor: FC<SlateEditorProps> = ({
    source,
    editorContentRef,
    editorInitialData,
    targetRef,
    assesseeOrgId,
    generating,
    onClose,
    savingData,
}) => {
    // --------------------------------------
    // Create the editor with plugins
    // --------------------------------------
    const [editor] = useState(() => {
        return withEquation(withHistory(withEmbeds(withTables(withLinks(withExcelPaste(withReact(createEditor()) as CustomEditor))))));
    });

    // --------------------------------------
    // Sync incoming data to the editor
    // --------------------------------------
    useEffect(() => {
        // Overwrite the editor state:
        editor.children = editorInitialData;
        editor.addMark(editor, 'fontSize', '12px');
        editor.onChange();
    }, [editorInitialData, source, assesseeOrgId, editor]);

    // --------------------------------------
    // Handle changes to editor content
    // --------------------------------------
    const handleEditorChange = useCallback(
        (value: Descendant[]) => {
            // Detect actual changes
            const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
            if (isAstChange && source === 'CREATE_DEAL') {
                // Optionally store new content in localStorage
                // const content = JSON.stringify(value);
                // localStorage.setItem('one_pager_content', content);
                // localStorage.setItem('one_pager_assessee', assesseeOrgId || '');
            }
            editorContentRef.current = value;

            // calculatePageBreaks();
        },
        [editor, source, editorContentRef, assesseeOrgId],
    );

    // --------------------------------------
    // Keyboard shortcuts
    // --------------------------------------
    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            // Typically Mac's "Command" key is "metaKey" and Windows' Control is `ctrlKey`.
            // Adjust if you want cross-platform behavior, e.g. using event.ctrlKey instead.
            if (event.metaKey) {
                switch (event.key) {
                    case 'b': {
                        event.preventDefault();
                        toggleMark(editor, 'bold');
                        break;
                    }
                    case 'i': {
                        event.preventDefault();
                        toggleMark(editor, 'italic');
                        break;
                    }
                    case 'u': {
                        event.preventDefault();
                        toggleMark(editor, 'underline');
                        break;
                    }
                    case 's': {
                        event.preventDefault();
                        toggleMark(editor, 'strikethrough');
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [editor],
    );

    // --------------------------------------
    // Render functions
    // --------------------------------------
    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

    // --------------------------------------
    // Example HTML code -> text injection (if you have that feature)
    // --------------------------------------
    const [htmlAction, setHtmlAction] = useState<{
        showInput: boolean;
        html: string;
        action: string;
        location: string;
    }>({
        showInput: false,
        html: '',
        action: '',
        location: '',
    });

    const handleCodeToText = (partialState: Partial<typeof htmlAction>) => {
        setHtmlAction((prev) => ({
            ...prev,
            ...partialState,
        }));
    };

    // useEffect(() => {
    //     if (targetRef?.current) {
    //         calculatePageBreaks();
    //     }
    // }, [targetRef]);

    // const calculatePageBreaks = () => {
    //     if (targetRef?.current) {
    //         const mmToPx = (mm) => mm * (96 / 25.4); // px
    //         const margin = mmToPx(5); // px
    //         const padding = mmToPx(20);
    //         const ht = mmToPx(420) + padding * 2 + margin * 2 - 185; // px
    //         const contentHeight = targetRef.current.scrollHeight;
    //         const hrElements = targetRef.current.querySelectorAll('#page-break-line');
    //         hrElements.forEach((hr, index) => {
    //             const hrElement = hr as HTMLElement;
    //             const top = (index + 1) * ht;

    //             if (top <= contentHeight) {
    //                 hrElement.style.position = 'absolute';
    //                 hrElement.style.top = `${top}px`;
    //                 hrElement.style.width = '100%';
    //                 hrElement.style.display = 'block';
    //             } else {
    //                 hrElement.style.display = 'none';
    //             }
    //         });
    //     }
    // };

    const getPageBreakLine = () => (
        <div className={styles.PageBreak} id="page-break-line">
            <span className={styles.Text}>Page Break</span>
        </div>
    );

    // --------------------------------------
    // Render Component
    // --------------------------------------
    return (
        <div className={styles.SlateWrapper}>
            <Slate editor={editor} initialValue={editorInitialData} onChange={handleEditorChange}>
                <Toolbar handleCodeToText={handleCodeToText} onClose={onClose} savingData={savingData} />
                {generating ? (
                    <TextEditorShimmerLoader />
                ) : (
                    <div className={styles.EditorWrapper} ref={targetRef} id="element-to-be-downloaded-as-pdf">
                        <Editable
                            placeholder="Write your text here..."
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown={handleKeyDown}
                        />
                        {/* {getPageBreakLine()}
                        {getPageBreakLine()}
                        {getPageBreakLine()} */}
                    </div>
                )}
            </Slate>
        </div>
    );
};

export default TextEditor;
