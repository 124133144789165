export interface LegalCasesData {
    lastFetchedDate?: String;
    status?: string;
    legalCasesFiledAgainstCompanyOrDirector: CaseRow[];
    legalCasesFiledByCompanyOrDirector: CaseRow[];
}

type CaseRow = {
    name: string;
    cases: Case[];
};

type Case = {
    filedBy: string;
    filedAgainst: string;
    caseCategory: string;
    court: string;
    status: string;
    caseNum: string;
};
export const HEADERS = {
    FILED_BY: 'filedBy',
    FILED_AGAINST: 'filedAgainst',
    CASE_CATEGORY: 'caseCategory',
    COURT: 'court',
    STATUS: 'status',
    CASE_NUM: 'caseNum',
    DOCIDS: 'docId',
};
const HEADER_LABELS = {
    [HEADERS.FILED_BY]: 'Filed By',
    [HEADERS.FILED_AGAINST]: 'Filed Against',
    [HEADERS.CASE_CATEGORY]: 'Case Type',
    [HEADERS.COURT]: 'Court',
    [HEADERS.STATUS]: 'Status',
    [HEADERS.CASE_NUM]: 'Case No',
};

const HEADER_LABELS_STAR = {
    [HEADERS.FILED_BY]: 'Filed By*',
    [HEADERS.FILED_AGAINST]: 'Filed Against*',
};

export const headers_filedBy = [
    {
        key: HEADERS.STATUS,
        label: HEADER_LABELS[HEADERS.STATUS],
    },
    {
        key: HEADERS.CASE_NUM,
        label: HEADER_LABELS[HEADERS.CASE_NUM],
    },
    {
        key: HEADERS.FILED_BY,
        label: HEADER_LABELS_STAR[HEADERS.FILED_BY],
    },
    {
        key: HEADERS.FILED_AGAINST,
        label: HEADER_LABELS[HEADERS.FILED_AGAINST],
    },
    {
        key: HEADERS.CASE_CATEGORY,
        label: HEADER_LABELS[HEADERS.CASE_CATEGORY],
    },
    {
        key: HEADERS.COURT,
        label: HEADER_LABELS[HEADERS.COURT],
    },
    {
        key: HEADERS.DOCIDS,
        label: '',
    },
];

export const headers_filedAgainst = [
    {
        key: HEADERS.STATUS,
        label: HEADER_LABELS[HEADERS.STATUS],
    },
    {
        key: HEADERS.CASE_NUM,
        label: HEADER_LABELS[HEADERS.CASE_NUM],
    },
    {
        key: HEADERS.FILED_AGAINST,
        label: HEADER_LABELS_STAR[HEADERS.FILED_AGAINST],
    },
    {
        key: HEADERS.FILED_BY,
        label: HEADER_LABELS[HEADERS.FILED_BY],
    },
    {
        key: HEADERS.CASE_CATEGORY,
        label: HEADER_LABELS[HEADERS.CASE_CATEGORY],
    },
    {
        key: HEADERS.COURT,
        label: HEADER_LABELS[HEADERS.COURT],
    },
    {
        key: HEADERS.DOCIDS,
        label: '',
    },
];
