import React, { useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import Button from '../common/Button';
import Icon from '../common/Icon';
import { activeMark, addMarkData, isBlockActive, isMarkActive, toggleBlock, toggleMark } from '../utils/SlateUtilityFunctions.js';
import useFormat from '../utils/customHooks/useFormat.js';
import defaultToolbarGroups from './toolbarGroups.js';
import ColorPicker from '../Elements/ColorPicker/ColorPicker';
import LinkButton from '../Elements/Link/LinkButton';
import Embed from '../Elements/Embed/Embed';
import TableSelector from '../Elements/Table/TableSelector';
import Id from '../Elements/ID/ID';
import TableContextMenu from '../Elements/TableContextMenu/TableContextMenu';
import styles from './Toolbar.module.scss';
import { commentsIcon, progressAutosavedGray } from '../../../assets/hostedassets';
import closeIcon from '../../_custom/Modal/close.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { closeCommentsThread, setShowCommentsThread } from '../../../store/commentsThread/commentsThread_actions';
import { COMMENTS_THREAD_SOURCES } from '../../../store/commentsThread/commentsThread_reducer';
import clsx from 'clsx';
import LoadingSpinner from '../../_custom/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify';

const Toolbar = (props) => {
    const { handleCodeToText, icon, savingData } = props;
    const editor = useSlate();
    const isTable = useFormat(editor, 'table');
    const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);
    const dispatch = useAppDispatch();
    const commentsThreadState = useAppSelector((state) => state.commentsThreadReducer);
    const assesseeOrgId = useAppSelector((state) => state.investor.onePager.assesseeOrgId);

    useEffect(() => {
        // Filter out the groups which are not allowed to be inserted when a table is in focus.
        let filteredGroups = [...defaultToolbarGroups];
        if (isTable) {
            filteredGroups = toolbarGroups.map((grp) =>
                grp.filter(
                    (element) =>
                        //groups that are not supported inside the table
                        !['codeToText'].includes(element.type),
                ),
            );
            filteredGroups = filteredGroups.filter((elem) => elem.length);
        }
        setToolbarGroups(filteredGroups);
    }, [isTable]);

    const BlockButton = ({ format, icon }) => {
        return (
            <Button
                active={isBlockActive(editor, format)}
                format={format}
                onMouseDown={(e) => {
                    e.preventDefault();
                    toggleBlock(editor, format);
                }}
            >
                <Icon icon={icon} />
            </Button>
        );
    };
    const MarkButton = ({ format, icon }) => {
        return (
            <Button
                active={isMarkActive(editor, format)}
                format={format}
                onMouseDown={(e) => {
                    e.preventDefault();
                    toggleMark(editor, format);
                }}
            >
                <Icon icon={icon} />
            </Button>
        );
    };
    const Dropdown = ({ format, options }) => {
        return (
            <select value={activeMark(editor, format) || '12px'} onChange={(e) => changeMarkData(e, format)} className={styles.Select}>
                {options.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.text}
                    </option>
                ))}
            </select>
        );
    };
    const changeMarkData = (event, format) => {
        event.preventDefault();
        const value = event.target.value;
        addMarkData(editor, { format, value });
    };

    const showHideCommentsThread = () => {
        if (commentsThreadState.showCommentsThread) {
            dispatch(closeCommentsThread());
        } else {
            if (!assesseeOrgId) {
                toast.error('Failed to intialise comments thread');
                return;
            }
            dispatch(
                setShowCommentsThread({
                    source: COMMENTS_THREAD_SOURCES.ONE_PAGER,
                    assesseeOrgId: assesseeOrgId,
                }),
            );
        }
    };

    return (
        <div className={styles.Toolbar}>
            <div className={styles.ToolbarLeft}>
                {toolbarGroups.map((group, index) => (
                    <span key={index} className={styles.ToolbarGrp}>
                        {group.map((element) => {
                            switch (element.type) {
                                case 'block':
                                    return <BlockButton key={element.id} {...element} />;
                                case 'mark':
                                    return <MarkButton key={element.id} {...element} />;
                                case 'dropdown':
                                    return <Dropdown key={element.id} {...element} />;
                                case 'link':
                                    return (
                                        <LinkButton
                                            key={element.id}
                                            active={isBlockActive(editor, 'link')}
                                            editor={editor}
                                            icon={element?.icon}
                                        />
                                    );
                                case 'embed':
                                    return <Embed key={element.id} format={element.format} editor={editor} icon={element?.icon} />;
                                case 'color-picker':
                                    return <ColorPicker key={element.id} activeMark={activeMark} format={element.format} editor={editor} />;
                                case 'table':
                                    return <TableSelector key={element.id} editor={editor} icon={element?.icon} />;
                                case 'id':
                                    return <Id editor={editor} />;
                                default:
                                    return null;
                            }
                        })}
                    </span>
                ))}
            </div>

            <div className={styles.ToolbarRight}>
                <img src={progressAutosavedGray} alt={'p'} width={'140px'} />
                <div
                    className={clsx(styles.CommentsIconContainer, {
                        [styles.CommentsIconContainer_HoverActive]:
                            commentsThreadState.showCommentsThread && !commentsThreadState.closingCommentsThread,
                    })}
                    onClick={showHideCommentsThread}
                >
                    <img src={commentsIcon} alt="comments" height={'25px'} />
                </div>
                {savingData ? (
                    <LoadingSpinner color="#BCBCBC" height="18px" style={{ margin: '0' }} />
                ) : (
                    <img onClick={() => props?.onClose?.()} className={styles.CloseIcon} src={closeIcon} alt={'x'} height={'14px'} />
                )}
            </div>

            <TableContextMenu editor={editor} />
        </div>
    );
};

export default Toolbar;
