import styles from './TabSlider.module.scss';
import greyLock from '../../../assets/grey-lock-2.svg';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { useAppSelector } from '../../../app/hooks';

export interface Tab {
    id: string;
    label: string;
    img: string;
    selectedImg: string;
    route: string;
    isLocked?: boolean;
    isHidden?: boolean;
    elementId?: string;
}

export interface TabSliderProps {
    selected: Tab | null;
    onChange: (tab: Tab) => void;
    navbarItems: Tab[];
}

function TabSlider({ selected, onChange, navbarItems }: TabSliderProps) {
    const location = useLocation();
    const [disabled, setDisabled] = useState(false);
    const tabsContainerRef = useRef<HTMLDivElement>(null);
    const selectedTabRef = useRef<HTMLAnchorElement | null>(null);
    const permissions = useAppSelector((state) => state.investor.permissions);
    const isCompany = location.pathname.includes('company');

    const fetchingPermissions = isCompany ? false : permissions.fetching;
    const permissionsFetched = isCompany ? true : permissions.permissionsFetched;

    const onTabClick = (e: React.MouseEvent<HTMLAnchorElement>, tab: Tab) => {
        if (disabled || tab?.isLocked || selected?.id === tab?.id) {
            e.preventDefault();
            return;
        }

        setDisabled(true);
        selectedTabRef.current = e.currentTarget;
        onChange(tab);

        setTimeout(() => {
            setDisabled(false);
        }, 200);
    };

    const setTabRef = (element: HTMLAnchorElement | null) => {
        if (element?.classList.contains('ActiveElement') && permissionsFetched) {
            selectedTabRef.current = element;
        }
    };

    return (
        <div className={styles.TabSlider} ref={tabsContainerRef}>
            {navbarItems
                ?.filter((item) => !item?.isHidden)
                ?.map((tab, i) => (
                    <NavLink
                        key={tab?.id}
                        id={tab?.elementId}
                        to={tab?.route}
                        ref={setTabRef}
                        className={clsx(styles.SingleTab, {
                            [styles.ActiveTab]: selected?.id === tab?.id,
                            [styles.DisabledTab]: tab?.isLocked,
                            ActiveElement: selected?.id === tab?.id,
                        })}
                        onClick={(e) => onTabClick(e, tab)}
                    >
                        {fetchingPermissions ? (
                            <>
                                <ShimmerThumbnail height={30} rounded className={styles.Shimmer} />
                            </>
                        ) : (
                            <>
                                {typeof tab?.img === 'string' ? (
                                    <img
                                        src={
                                            selected?.id?.toLowerCase?.() ===
                                            tab?.id?.toLowerCase?.()
                                                ? tab?.selectedImg
                                                : tab?.img
                                        }
                                        alt="-"
                                        height="24px"
                                        width="24px"
                                    />
                                ) : selected?.id?.toLowerCase?.() === tab?.id?.toLowerCase?.() ? (
                                    tab?.selectedImg
                                ) : (
                                    tab?.img
                                )}
                                {tab?.label}
                                {tab?.isLocked && (
                                    <img src={greyLock} alt="lock" height="20px" width="20px" />
                                )}
                            </>
                        )}
                    </NavLink>
                ))}
            {!!selectedTabRef?.current && (
                <div
                    className={styles.Slider}
                    style={{
                        left: selectedTabRef?.current?.offsetLeft || '',
                        width: selectedTabRef?.current?.offsetWidth,
                        visibility: selected ? 'visible' : 'hidden',
                    }}
                ></div>
            )}
        </div>
    );
}

export default TabSlider;
