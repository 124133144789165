import React from 'react';
import styles from './Users.module.scss';

const Users = ({ additionalDetails, additionalDocMetaData, selectedCompany }) => {
    return (
        <div className={styles.Container}>
            <div className={styles.Card}>
                <div className={styles.Top}>
                    <div className={styles.Title}>User Submitted Details</div>
                    <div className={styles.Right}></div>
                </div>
                <div className={styles.Body}>
                    <div className={styles.Fields}>
                        {Object.entries(additionalDetails || {}).map(([key, value]: any) => (
                            <div key={key} className={styles.Field}>
                                <div>{value.placeholder}</div>
                                <div>{value.value || '-'}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.Card}>
                <div className={styles.Top}>
                    <div className={styles.Title}>User Details</div>
                    <div className={styles.Right}></div>
                </div>

                <div className={styles.Body}>
                    <div className={styles.Fields}>
                        <div className={styles.Field}>
                            <div>Name</div>
                            <div>{additionalDocMetaData?.docSubmittedBy || '-'}</div>
                        </div>
                        <div className={styles.Field}>
                            <div>Mobile</div>
                            <div>{selectedCompany?.contactNumber || '-'}</div>
                        </div>
                        <div className={styles.Field}>
                            <div>Email</div>
                            <div>{selectedCompany?.contactEmail || '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;
