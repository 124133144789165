import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    Legend,
    Area,
    AreaChart,
    Label,
} from 'recharts';
import clsx from 'clsx';
import styles from '../Graph.module.scss';

import { isMobile } from '../../../../../utils/utils';
// import { lineStaticData as staticData } from '../../../../../utils/staticGraphData';
// import { graphDataType } from '../../../../../store/Dashboard/types';
// import { GRAPH_NAMES } from '../../../../../store/Dashboard/constants';
import CustomTooltip from './CustomTooltip/CustomTooltip';
import { formatPeriod } from '../../../../../store/dashboard/transformer';

/**
 * @Description Line graph component for single value for every x-coordinate
 */

interface LineGraphProps {
    height: number;
    error: boolean;
    graphData: any;
    graphName: string;
}

function LineGraph(props: LineGraphProps) {
    const { error } = props;
    const { colors, hasNegative, plots, metadata } = props.graphData;
    const graphData = plots[0]?.plotValues || [];
    const secondLineExists = !!(graphData?.[0] && 'yValue2' in graphData?.[0]);
    const thirdLineExists = !!(graphData?.[0] && 'yValue3' in graphData?.[0]);
    const fourthLineExists = !!(graphData?.[0] && 'yValue4' in graphData?.[0]);

    return (
        <ResponsiveContainer width="100%" height={props.height}>
            <AreaChart
                data={graphData.length ? graphData : []}
                margin={{
                    top: 30,
                    right: 20,
                    left: -25,
                    bottom: 5,
                }}
                className={clsx({ [styles.DisabledGraphSvg]: error })}
            >
                <XAxis
                    dataKey="xValue"
                    tick={{ fontSize: '10px' }}
                    axisLine={{ stroke: hasNegative ? '#ffffff' : '#000000' }}
                    tickFormatter={(val) => formatPeriod(val)}
                    interval={0}
                />
                {metadata?.legend ? (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ fontSize: isMobile ? '10px' : '14px', top: 0, right: 10 }}
                        iconType="circle"
                        formatter={(value, entry, index) => {
                            return metadata?.legend?.[value];
                        }}
                    />
                ) : null}
                <YAxis
                    tick={{ fontSize: '9px' }}
                    axisLine={{ stroke: '#ffffff' }}
                    tickLine={false}
                    dx={5}
                    // tickFormatter={addYaxisLabel}
                >
                    {!!metadata?.yTitle && (
                        <Label
                            value={metadata?.yTitle}
                            offset={0}
                            angle={-90}
                            style={{ fontSize: '12px', fill: '#BCBCBC' }}
                        />
                    )}
                </YAxis>
                {hasNegative ? <ReferenceLine y={0} stroke="#000000" /> : <></>}
                {/* <Legend
                    verticalAlign="top"
                    height={30}
                    align="right"
                    wrapperStyle={{
                        fontSize: isMobile ? '10px' : '14px',
                        width: '100%',
                        top: 20,
                        left: -10,
                    }}
                    iconType="circle"
                    // payload={}
                    formatter={(v) => {
                        return legend?.[v];
                    }}
                /> */}
                <Tooltip
                    content={
                        <CustomTooltip graphName={props.graphName} graphData={props.graphData} />
                    }
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: 'transparent' }}
                />
                {/* <Line
                    type="linear"
                    dataKey={'yValue'}
                    stroke={error ? colors?.error.secondary : colors?.primary}
                    dot={{ fill: colors?.primary }}
                    activeDot={{ fill: 'white', stroke: 'black' }}
                    strokeWidth={3}
                /> */}
                <Area
                    type="linear"
                    dataKey="yValue2"
                    stroke={colors?.secondary}
                    fill={colors?.secondary}
                />

                <Area
                    type="linear"
                    dataKey="yValue"
                    stroke={'#900004'}
                    fill={'#900004'}
                    activeDot={{ fill: 'white', stroke: 'black' }}
                />
                {secondLineExists && (
                    <Line
                        type="linear"
                        dataKey={'yValue2'}
                        stroke={colors?.secondary}
                        dot={{ fill: colors?.secondary }}
                        activeDot={{ fill: 'white', stroke: 'black' }}
                        strokeWidth={3}
                    />
                )}
                {thirdLineExists && (
                    <Line
                        type="monotone"
                        dataKey={'yValue3'}
                        stroke={colors?.tertiary}
                        dot={{ fill: colors?.tertiary }}
                        activeDot={{ fill: 'white', stroke: 'black' }}
                        strokeWidth={3}
                    />
                )}

                {fourthLineExists && (
                    <Line
                        type="monotone"
                        dataKey={'yValue4'}
                        stroke={colors?.fourth}
                        dot={{ fill: colors?.fourth }}
                        activeDot={{ fill: 'white', stroke: 'black' }}
                        strokeWidth={3}
                    />
                )}
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default LineGraph;
