import styles from './Page.module.scss';
import React, { lazy, Suspense, useEffect } from 'react';
import { ConfigBlock } from './PageTypes';
import { EXCLUDED_LABEL_TYPES } from '../../../ManageDealDrawer/DealDrawerConstants';
import { DealRecieverDetailMap, MasterData } from '../../customHooks/useDADataTransformations';
import { validateDealRecievers, validateFormData } from '../../customHooks/FormValidations';

const DealFormInputRenderer = lazy(() => import('../FormInputRenderer/DealFormInputRenderer'));
const DealLogsInputRenderer = lazy(
    () => import('../../../../modules/Investor/Deals/components/DealsList/DealLogsInputRenderer/DealLogsInputRenderer'),
);
const InvestorShareInputRenderer = lazy(() => import('../FormInputRenderer/InvestorShareInputRenderer'));
type PageProps = {
    page: ConfigBlock[];
    formData: MasterData | null;
    step?: number;
    hideHeading?: boolean;
    updateLenderMap?: (lenderMap: DealRecieverDetailMap) => void;
    updateDealTerms?: (key: string, value: string | string[]) => void;
    updateDealLogs?: (key: string, value: string | string[]) => void;
    setIsFormValid?: (value: boolean) => void;
};

const FormPage = (props: PageProps) => {
    const { page, formData, updateDealTerms, updateLenderMap, updateDealLogs, setIsFormValid, step } = props;

    useEffect(() => {
        if (setIsFormValid && page) {
            page.forEach((section) => {
                if (section.sectionKey === 'dealTerms' && props.step === 0) {
                    const isFormValid = validateFormData([section], formData as MasterData).isValid;
                    setIsFormValid?.(isFormValid);
                } else if (section.sectionKey === 'shareDeal' && props.step === 1) {
                    const isFormValid = validateDealRecievers(formData?.dealReceiverDetailMap as DealRecieverDetailMap).isValid;
                    setIsFormValid?.(isFormValid);
                }
            });
        }
    }, [formData, formData?.dealTerms, step, page, setIsFormValid]);

    const renderInputRenderers = (fieldConfig: any, key: string, section: ConfigBlock) => {
        switch (section.sectionKey) {
            case 'dealTerms':
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <DealFormInputRenderer
                            fieldConfig={fieldConfig}
                            sectionKey={section.sectionKey}
                            updateData={updateDealTerms as (key: string, value: string | string[]) => void}
                            formData={formData?.[`${section.sectionKey}`]}
                            inputKey={key}
                        />
                    </Suspense>
                );
            case 'dealLogs':
                return (
                    <Suspense fallback={<div>Loading...</div>}>
                        <DealLogsInputRenderer
                            fieldConfig={fieldConfig}
                            formData={formData?.dealTerms}
                            inputKey={key}
                            updateData={updateDealLogs as (key: string, value: string | string[]) => void}
                        />
                    </Suspense>
                );
            case 'shareDeal':
                return (
                    <InvestorShareInputRenderer
                        key={`investor-share-${JSON.stringify(formData?.dealTerms)}`}
                        step={props.step ?? 1}
                        fieldConfig={fieldConfig}
                        formData={formData?.dealReceiverDetailMap as DealRecieverDetailMap}
                        defaultPreferredTerms={formData?.dealTerms as object}
                        updateData={updateLenderMap as (lenderMap: DealRecieverDetailMap) => void}
                        defaultProductEnums={formData?.products as string[]}
                    />
                );
        }
    };
    return (
        <div className={styles.Page}>
            <div className={styles.SectionHeading}>
                {page?.map((section) => {
                    return (
                        <div key={`section-${section.sectionKey}`} className={styles.Section}>
                            <div className={styles.InputList}>
                                {Object.keys(section?.config)?.map((key: string) => {
                                    const fieldConfig = section.config[key];
                                    return (
                                        <div
                                            key={`${section.sectionKey}-${key}`}
                                            className={styles.InputContainers}
                                            style={section.sectionKey === 'shareDeal' ? { borderBottom: 'none' } : {}}
                                        >
                                            {!EXCLUDED_LABEL_TYPES.includes(fieldConfig?.type) && !props.hideHeading && (
                                                <div className={styles.InputLabel}>
                                                    <div>{fieldConfig.label}</div>
                                                    {fieldConfig.subLabel && <div className={styles.SubLabel}>{fieldConfig.subLabel}</div>}
                                                </div>
                                            )}
                                            <div style={{ width: '100%', height: '100%' }}>
                                                {renderInputRenderers(fieldConfig, key, section)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FormPage;
