import React, { useEffect, useRef, useState } from 'react';
import FileTree from '../FileTree/FileTree';
import styles from './Folder.module.scss';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { FileNode } from '../../../../DataRoom';
import {
    dataRoomArrowUp,
    downloadIconDataRoom,
    downloadIconDataRoomHovered,
    downloadDarkGray,
    folderIconDataRoom,
    PURPLE_THREE_DOTS_VERTICAL,
} from '../../../../../../assets/hostedassets';
import threeDotVertical from '../../../../../../assets/three-dot-vertical.svg';

import useFetchDataRoomFiles from '../../../../useFetchDataRoomFiles';
import { createPortal } from 'react-dom';
import { useAppSelector } from '../../../../../../app/hooks';
import { useSearchParams } from 'react-router-dom';

interface FolderProps {
    name: string;
    innerFiles: FileNode[];
    lastUpdated: string;
    index: number;
    parentFolderName: string;
    fetchFiles: Function;
    isNew: any;
}

const Folder: React.FC<FolderProps> = ({
    name,
    innerFiles,
    lastUpdated,
    index,
    fetchFiles,
    parentFolderName,
    isNew,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [downloadImgUrl, setDownloadImgUrl] = useState(downloadIconDataRoom);
    const { downloadTheFolder } = useFetchDataRoomFiles(fetchFiles);
    const [masterFolderName, setMasterFolderName] = useState('');
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const threeDotsRef = useRef<HTMLSpanElement | null>(null);
    const { handleDeleteFile } = useFetchDataRoomFiles();
    const fileViewType = searchParams.get('fileViewType');
    const isLender =
        useAppSelector((state) => state.user.userData.accountType) === USER_TYPE.LENDER;
    const islenderOrArchiveView = isLender || fileViewType === 'archived';
    let foldertoDelete = parentFolderName ? parentFolderName : name;

    useEffect(() => {
        if (index === 1) {
            setMasterFolderName(name);
        }
    }, [index, name]);

    const calculateDropdownPosition = () => {
        if (threeDotsRef.current) {
            const rect = threeDotsRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX - 10,
            });
        }
    };

    useEffect(() => {
        if (showOtherOptions) {
            calculateDropdownPosition();
        }
    }, [showOtherOptions]);

    useEffect(() => {
        const handleScroll = () => {
            if (showOtherOptions) {
                setShowOtherOptions(false);
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [showOtherOptions]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                threeDotsRef.current &&
                !threeDotsRef.current.contains(event.target as Node)
            ) {
                setShowOtherOptions(false);
            }
        };
        if (showOtherOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showOtherOptions]);

    // **Recursive function to extract all docIds from nested innerFiles**
    const extractDocIds = (files: FileNode[]): string[] => {
        let docIds: string[] = [];
        files.forEach((file) => {
            if (file.docId) {
                docIds.push(String(file.docId)); // Convert docId to string
            }
            if (file.innerFiles && file.innerFiles.length > 0) {
                docIds = [...docIds, ...extractDocIds(file.innerFiles)]; // Recursively get docIds
            }
        });

        return docIds;
    };

    const dropdown =
        showOtherOptions &&
        createPortal(
            <div ref={dropdownRef} className={styles.OtherOptions} style={dropdownPosition}>
                <div
                    style={{ color: '#DF666A' }}
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (innerFiles && innerFiles.length > 0) {
                            const docIds = extractDocIds(innerFiles);
                            await handleDeleteFile(docIds, name, foldertoDelete, false);
                            fetchFiles();
                            setShowOtherOptions(false);
                        }
                    }}
                >
                    Delete
                </div>
                <div
                    style={{ color: '#DF666A' }}
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (innerFiles && innerFiles.length > 0) {
                            const docIds = extractDocIds(innerFiles);
                            await handleDeleteFile(docIds, name, foldertoDelete, true);
                            fetchFiles();
                            setShowOtherOptions(false);
                        }
                    }}
                >
                    Archive
                </div>
            </div>,
            document.body,
        );

    return (
        <div className={styles.folder}>
            <div
                className={
                    islenderOrArchiveView ? styles.folderHeaderLenderView : styles.folderHeader
                }
                onClick={() => setIsOpen(!isOpen)}
            >
                <div
                    style={{
                        marginLeft: `${index * 1.25}rem`,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <span>
                        <img
                            className={isOpen ? styles.arrowDown : styles.arrowUp}
                            src={dataRoomArrowUp}
                            alt={'arrow'}
                        />
                    </span>

                    <span>
                        <img src={folderIconDataRoom} alt={'file'} />
                    </span>
                    <span className={styles.FolderName}>{name}</span>
                </div>
                <span className={isNew ? styles.labelStatus : ''}>{isNew ? 'New' : ''}</span>
                <span className={styles.FolderDate}>{lastUpdated}</span>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadTheFolder(innerFiles, name, false);
                    }}
                    className={styles.downloadIconContainer}
                    onMouseEnter={() => setDownloadImgUrl(downloadDarkGray)}
                    onMouseLeave={() => setDownloadImgUrl(downloadIconDataRoom)}
                >
                    <img src={downloadImgUrl} alt={'icon'} />
                </span>
                {/* {!islenderOrArchiveView && <div className={styles.separator} />} */}
                {!islenderOrArchiveView && (
                    <span
                        ref={threeDotsRef}
                        className={styles.ThreeDots}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowOtherOptions((prev) => !prev);
                        }}
                    >
                        <img
                            src={threeDotVertical}
                            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                            alt={'More'}
                        />
                    </span>
                )}
                {dropdown}
            </div>
            <div className={`${styles.folderContent} ${isOpen ? styles.open : styles.close}`}>
                <FileTree
                    data={innerFiles}
                    index={index}
                    masterFolderName={masterFolderName}
                    fetchFiles={fetchFiles}
                />
            </div>
        </div>
    );
};

export default Folder;
