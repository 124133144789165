import React from 'react';

const RefreshIcon = ({ colour = '#6021B3', width = '4', height = '22', style }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M11.7073 4.3737C11.0026 3.45829 10.0464 2.76812 8.95563 2.38763C7.86486 2.00715 6.68685 1.95284 5.5657 2.23136C4.44455 2.50988 3.42889 3.10914 2.64299 3.95584C1.85708 4.80253 1.335 5.85994 1.14062 6.9987M11.999 1.33203V4.66536H8.66563M2.28896 11.6237C2.99344 12.5395 3.94963 13.23 5.04049 13.6107C6.13135 13.9914 7.30955 14.0458 8.43085 13.7672C9.55216 13.4887 10.5679 12.8892 11.3538 12.0423C12.1397 11.1954 12.6616 10.1377 12.8556 8.9987M1.99812 14.6654V11.332H5.33146"
                stroke={colour}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RefreshIcon;
