import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
    enableAPIAccess,
    GST_API_SECRET,
    initiateGstOtp,
    submitGstOtp,
} from '../../utils/api/GstApiContainer';
import { toast } from 'react-toastify';
import { getTimeStamp } from '../../../../../../../utils/dateUtils';
import { useAppSelector } from '../../../../../../../app/hooks';
import { validateGSTUserName } from '../../../../../../../utils/utils';
import { Mixpanel } from '../../../../../../../utils/mixpanel';
import {
    COMPANY_GST_PROCEED,
    COMPANY_GST_SUBMIT_OTP,
} from '../../../../../../../utils/constants/mixpanelEvents/companyEvents';
import { useParams } from 'react-router-dom';

export const GENERIC_ERROR = 'Some error occurred!';

interface GstContextProps {
    children: React.ReactNode;
    gstValues: {
        showModal: boolean;
        setShowModal: React.Dispatch<React.SetStateAction<any>>;
        gstData: any;
    };
}

interface StepProps {
    component: string;
    props?: any;
}

interface FormValuesProps {
    gstUserName: string;
    consent: boolean;
}

interface OtpProps {
    OTP1: string;
    OTP2: string;
    OTP3: string;
    OTP4: string;
    OTP5: string;
    OTP6: string;
}

interface UserNameNPasswordProps {
    gstUserName: string;
    password: string;
}

interface GSTHook {
    step: StepProps;
    otp: string;
    gstData: any;
    setOtp: React.Dispatch<React.SetStateAction<any>>;
    setStep: React.Dispatch<React.SetStateAction<any>>;
    showModal: boolean;
    formValues: FormValuesProps;
    credsWrong: boolean;
    buttonDisable: boolean;
    otpBtnDisable: boolean;
    submitBtnDisable: boolean;
    userNameNPassword: UserNameNPasswordProps;
    handleBack: Function;
    setFormValues: React.Dispatch<React.SetStateAction<any>>;
    handleSubmit: Function;
    handleProceed: Function;
    handleOpenModal: Function;
    handleCloseModal: Function;
    handleSubmitOtp: Function;
    setUserNameNPassword: React.Dispatch<React.SetStateAction<any>>;
    handleEnableViaManually: Function;
}

export const STEPS = {
    GstConsent: 'GstConsent',
    Loader: 'Loader',
    GstErrorScreens: 'GstErrorScreens',
    // GstAccessViaAPI: 'GstAccessViaAPI',
    GstOtp: 'GstOtp',
};

export const CORE_DIRECT = 'CORE_DIRECT';
export const CORE_FLOW = 'CORE_FLOW';
export const AICA = 'AICA';
const ALREADYOTPSENTMESSAGE = 'Otp already sent, please submit the same otp.';

export const Context = createContext<GSTHook | null>(null);

export const GstContext = ({ children, gstValues }: GstContextProps) => {
    const params = useParams();
    const { showModal, setShowModal, gstData } = gstValues;
    const INITIAL_STATE = {
        component: STEPS.GstConsent,
    };
    const userData = useAppSelector((state) => state.user.userData);
    const [formValues, setFormValues] = useState<FormValuesProps>({
        gstUserName: '',
        consent: true,
    });
    const [otp, setOtp] = useState<string>('');
    const [credsWrong, setCredsWrong] = useState<boolean>(false);
    const [userNameNPassword, setUserNameNPassword] = useState<UserNameNPasswordProps>({
        gstUserName: '',
        password: '',
    });
    const [submitBtnDisable, setSubmitBtnDisable] = useState<boolean>(true);
    const [otpBtnDisable, setOtpBtnDisable] = useState<boolean>(true);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [step, setStep] = useState<StepProps>(INITIAL_STATE);

    useEffect(() => {
        if (formValues.consent && formValues.gstUserName.length) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [formValues]);

    useEffect(() => {
        if (otp?.length === 6) {
            setOtpBtnDisable(false);
        } else {
            setOtpBtnDisable(true);
        }
    }, [otp]);

    useEffect(() => {
        if (userNameNPassword.gstUserName.length && userNameNPassword.password.length) {
            setSubmitBtnDisable(false);
        } else {
            setSubmitBtnDisable(true);
        }
    }, [userNameNPassword]);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        setStep({
            component: STEPS.GstConsent,
        });

        setCredsWrong(false);
        setFormValues({
            gstUserName: '',
            consent: true,
        });
        setOtp('');
        setUserNameNPassword({
            gstUserName: '',
            password: '',
        });
        setSubmitBtnDisable(true);
        setButtonDisable(true);
        setOtpBtnDisable(true);
    }, []);

    const handleOpenModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleSubmitOtp = useCallback(() => {
        setStep({
            component: STEPS.Loader,
        });
        async function apiHelper() {
            try {
                const data = {
                    requestId: getTimeStamp(),
                    source: AICA,
                    organizationId: userData?.assesseeOrgId || params.id,
                    investorOrganizationId: userData?.investorOrgId,
                    gstData: {
                        gstin: gstData.gstin_number,
                        username: formValues.gstUserName,
                        otp: otp,
                        initiateHistoricalFetch: false,
                    },
                };
                const headers = {
                    'x-api-secret': GST_API_SECRET,
                    'x-api-source': AICA,
                };
                const res = await submitGstOtp(data, headers);
                if (res.data.responseData.responseCode === 20) {
                    handleCloseModal();
                    if (gstData.fetchData) gstData.fetchData();
                    toast.success(res.data.responseData.responseMessage);
                    return;
                }
                if (res.data.responseData.responseCode === 40) {
                    toast.error(res.data.responseData.responseMessage);
                    setStep({
                        component: STEPS.GstOtp,
                    });
                    setOtp('');
                    return;
                }
                if (res.data.responseData.responseCode === 41) {
                    toast.error(res.data.responseData.responseMessage);
                    setStep({
                        component: STEPS.GstOtp,
                    });
                    setOtp('');
                    return;
                }
            } catch (err) {
                setStep({
                    component: STEPS.GstOtp,
                });
                toast.error(GENERIC_ERROR);
            }
        }
        apiHelper();
    }, [otp, gstData, userData, userNameNPassword]);

    const handleProceed = useCallback(() => {
        Mixpanel.track(COMPANY_GST_PROCEED);
        const validationResult = validateGSTUserName(formValues.gstUserName);
        if (!validationResult.isValid) {
            toast.error(validationResult.message);
            return;
        }
        setStep({
            component: STEPS.Loader,
        });
        setOtp('');
        async function apiHelper() {
            try {
                const data = {
                    requestId: getTimeStamp(),
                    source: AICA,
                    organizationId: userData?.assesseeOrgId || params.id,
                    gstData: {
                        gstin: gstData.gstin_number,
                        username: formValues.gstUserName,
                        consent: formValues.consent ? 'Y' : 'N',
                    },
                };
                const headers = {
                    'x-api-secret': GST_API_SECRET,
                    'x-api-source': AICA,
                };
                const res = await initiateGstOtp(data, headers);
                if (res.data.responseData.responseCode === 20) {
                    // setButtonDisable(true);
                    setStep({
                        component: STEPS.GstOtp,
                    });
                    toast.success(res.data.responseData.responseMessage);
                    return;
                }
                if (res.data.responseData.responseCode === 40) {
                    // setButtonDisable(true);
                    toast.error(res.data.responseData.responseMessage);
                    if (res.data.responseData.responseMessage === ALREADYOTPSENTMESSAGE) {
                        setStep({
                            component: STEPS.GstOtp,
                        });
                    } else {
                        setStep({
                            component: STEPS.GstConsent,
                        });
                    }
                    return;
                }
                if (res.data.responseData.responseCode === 41) {
                    // setButtonDisable(true);
                    setStep({
                        component: STEPS.GstErrorScreens,
                        props: {
                            isAccessDenied: true,
                        },
                    });
                    return;
                }
            } catch (err) {
                // setButtonDisable(true);
                setStep({
                    component: STEPS.GstErrorScreens,
                    props: {
                        isAccessDenied: false,
                    },
                });
                toast.error(GENERIC_ERROR);
            }
        }
        apiHelper();
    }, [formValues, gstData, userData]);

    const handleSubmit = useCallback(() => {
        Mixpanel.track(COMPANY_GST_SUBMIT_OTP);
        const validateInput = () => {
            let isValid = true;
            let message = '';
            if (!userNameNPassword.gstUserName.match(/^([a-zA-Z0-9_\-.]){8,15}$/)) {
                isValid = false;
                message = 'Please enter a valid username';
            } else if (
                !userNameNPassword.password.match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!#%^$&`*\-_+])(?=.*[a-zA-Z0-9@!#%^$&`*\-_+]*$).{8,15}$/,
                )
            ) {
                isValid = false;
                message = 'Please enter a valid password';
            }
            return {
                isValid,
                message,
            };
        };
        const validationResult = validateInput();
        if (!validationResult.isValid) {
            toast.error(validationResult.message);
            return;
        }
        setStep({
            component: STEPS.Loader,
        });
        async function apiHelper() {
            try {
                const data = {
                    requestId: getTimeStamp(),
                    source: AICA,
                    organizationId: userData?.assesseeOrgId,
                    gstData: {
                        gstin: gstData.gstin_number,
                        username: userNameNPassword.gstUserName,
                        password: userNameNPassword.password,
                        sendFetchOtp: true,
                    },
                };
                const headers = {
                    'x-api-secret': GST_API_SECRET,
                    'x-api-source': AICA,
                };
                const res = await enableAPIAccess(data, headers);
                if (res.data.responseData.responseCode === 20) {
                    setSubmitBtnDisable(true);
                    setStep({
                        component: STEPS.GstOtp,
                    });
                    toast.success(res.data.responseData.responseMessage);
                    return;
                }
                if (res.data.responseData.responseCode === 40) {
                    toast.error(res.data.responseData.responseMessage);
                    return;
                }
                if (res.data.responseData.responseCode === 41) {
                    toast.error(res.data.responseData.responseMessage);
                    setStep({
                        component: STEPS.GstErrorScreens,
                        props: {
                            isAccessDenied: false,
                        },
                    });
                    return;
                }
            } catch (err) {
                setStep({
                    component: STEPS.GstErrorScreens,
                    props: {
                        isAccessDenied: false,
                    },
                });
                toast.error(GENERIC_ERROR);
            }
        }
        apiHelper();
    }, [userNameNPassword, gstData, userData]);

    const handleEnableViaManually = useCallback(() => {
        const helpLink = 'https://help.recur.club/en/article/how-to-enable-gst-api-access-';

        // Open the link in a new tab or window
        window.open(helpLink, '_blank');
        handleCloseModal();
    }, []);

    const handleBack = useCallback((step: string) => {
        setStep({
            component: STEPS.GstConsent,
        });
    }, []);

    return (
        <Context.Provider
            value={{
                otp,
                step,
                gstData,
                setOtp,
                setStep,
                showModal,
                formValues,
                credsWrong,
                buttonDisable,
                otpBtnDisable,
                submitBtnDisable,
                userNameNPassword,
                handleBack,
                handleSubmitOtp,
                setFormValues,
                handleSubmit,
                handleProceed,
                handleOpenModal,
                handleCloseModal,
                setUserNameNPassword,
                handleEnableViaManually,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useGSTContext = () => {
    return useContext<GSTHook | null>(Context);
};
