import { CONSTANTS } from './tooltip_constants';

interface TooltipReducer {
    showTooltip: boolean;
    // target: HTMLElement | null;
    position: { top: number; left: number; width: number; height: number } | null;
    content: string | JSX.Element | null;
    placement: 'top' | 'bottom' | 'left' | 'right' | '';
}

const initialState: TooltipReducer = {
    showTooltip: false,
    position: null,
    content: null,
    placement: '',
};

const setTooltipData = (state, action): TooltipReducer => {
    return {
        ...state,
        showTooltip: action.payload.showTooltip,
        position: action.payload.position,
        content: action.payload.content,
        placement: action?.payload?.placement || 'left',
    };
};

const remove = (): TooltipReducer => {
    return initialState;
};

const reducer = (state = initialState, action): TooltipReducer => {
    switch (action.type) {
        case CONSTANTS.SET_TOOLTIP_DATA:
            return setTooltipData(state, action);
        case CONSTANTS.REMOVE:
            return remove();
        case CONSTANTS.CANCEL_REMOVE:
            return state;
        default:
            return state;
    }
};

export default reducer;
