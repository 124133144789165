import styles from './AlertEngine.module.scss';
import { useEffect, useRef, useState } from 'react';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import { getAlertNumber } from '../../../../store/dashboard/AlertEngine/alertEngine_apiHelpers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { getTimeStamp } from '../../../../utils/dateUtils';
import AlertEngineTable from './components/AlertEngineTable/AlertEngineTable';
import clsx from 'clsx';
import { Mixpanel } from '../../../../utils/mixpanel';
import {
    INVESTOR_CLICK_ALERTS,
    INVESTOR_CLOSE_ALERTS,
} from '../../../../utils/constants/mixpanelEvents/investorEvents';
import { ALERT_ICON, ALERT_ICON_DISABLED } from '../../../../assets/hostedassets';

const AlertEngine = ({ selectedCompany, currentTab }) => {
    const [showAlerts, setShowAlerts] = useState(false);
    const [alertCount, setAlertCount] = useState('');
    const [isAlertGenerated, setAlertGenerated] = useState<boolean>(false);
    const params = useParams();
    const user = useAppSelector((state) => state.user);

    const alertPollingRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const alertPollingCount = useRef(0);

    useEffect(() => {
        getAlertsNumber();

        return () => {
            stopPolling();
        };
    }, []);

    const startPolling = () => {
        if (!alertPollingRef.current) {
            alertPollingRef.current = setInterval(() => {
                getAlertsNumber();
            }, 10000);
        }
    };

    const stopPolling = () => {
        if (alertPollingRef.current) {
            clearInterval(alertPollingRef.current);
            alertPollingRef.current = null;
        }
    };

    const getAlertsNumber = async () => {
        alertPollingCount.current += 1;
        const payload = {
            assesseOrgId: params?.id,
            parentOrgId: user?.userData?.investorOrgId,
            requestId: getTimeStamp(),
        };
        const res = await getAlertNumber(payload);
        if (res) {
            setAlertCount(res?.alertCount);
            if (!res?.alertsGenerated) {
                setAlertGenerated(false);
                if (alertPollingCount.current < 9) {
                    startPolling();
                } else stopPolling();
            } else {
                setAlertGenerated(true);
                stopPolling();
            }
        } else {
            stopPolling();
        }
    };
    const renderAlertButtonContent = () => {
        if (isAlertGenerated) {
            return <div>{alertCount ? <span>{alertCount}</span> : 'No'} Alerts</div>;
        } else {
            return <div>No Alerts</div>;
        }
    };

    const onAlertsButtonClick = () => {
        setShowAlerts(true);
    };

    const registerMixpanelEvent = () => {
        Mixpanel.track(INVESTOR_CLICK_ALERTS, {
            Timestamp: getTimeStamp(),
            AICASection: selectedCompany?.companyAccessType,
            TabName: currentTab,
        });
    };

    useEffect(() => {
        if (showAlerts) registerMixpanelEvent();
    }, [showAlerts]);
    return (
        <>
            <div
                className={clsx(styles.AlertBtnContainer, { [styles.Disabled]: !isAlertGenerated })}
            >
                <button onClick={onAlertsButtonClick} className={styles.AlertBtn}>
                    {renderAlertButtonContent()}
                    <img src={isAlertGenerated ? ALERT_ICON : ALERT_ICON_DISABLED} alt="alert" />
                </button>
            </div>
            {showAlerts && (
                <SideDrawer
                    allowClose
                    heading="Alerts"
                    onClose={() => {
                        Mixpanel.track(INVESTOR_CLOSE_ALERTS, {
                            Timestamp: getTimeStamp(),
                            AICASection: selectedCompany?.companyAccessType,
                            TabName: currentTab,
                        });
                        setShowAlerts(false);
                    }}
                    width="80%"
                >
                    <div className={styles.AlertDrawerHeader}>
                        <h1>Alerts</h1>
                    </div>
                    <div style={{ height: '100%' }}>
                        <AlertEngineTable
                            assesseOrgId={params?.id ?? ''}
                            parentOrgId={user?.userData?.investorOrgId}
                        />
                    </div>
                </SideDrawer>
            )}
        </>
    );
};
export default AlertEngine;
