import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import styles from './GoogleSignIn.module.scss';
import { GmailIcon } from '../../assets/hostedassets';

const GoogleSignIn: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const user = useAppSelector((state) => state.user);
    const navigate = useNavigate();
    const clientId = process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID;
    const redirectUri = `${window.location.origin}`; // Set your redirect URI

    const getOAuthURL = () => {
        const scope = [
            'https://www.googleapis.com/auth/gmail.readonly',
            'openid',
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/drive.readonly',
        ].join(' ');
        return `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(
            scope,
        )}&access_type=offline&include_granted_scopes=true&redirect_uri=${encodeURIComponent(
            redirectUri,
        )}&response_type=code&client_id=${clientId}&prompt=consent`;
    };
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onload = () => {
            setIsLoading(false);
            try {
                gapi?.load('auth2', () => {
                    try {
                        gapi.auth2
                            .init({ client_id: clientId })
                            .then(() => {
                                console.info('Google Auth2 initialized successfully.');
                            })
                            .catch((initError) => {
                                if (initError.error === 'idpiframe_initialization_failed') {
                                    console.error('IDP iframe initialization failed. Check your client_id or redirect URIs.', initError);
                                } else {
                                    console.error('Error during gapi.auth2.init:', initError);
                                }
                            });
                    } catch (initError) {
                        console.error('Error during gapi.auth2.init:', initError);
                    }
                });
            } catch (loadError) {
                console.error('Error loading gapi:', loadError);
            }
        };
        document.body.appendChild(script);
    }, [clientId]);

    const onFailure = (error: any) => {
        console.error('Error: ', error);
    };

    const handleSignin = () => {
        const a = document.createElement('a');
        a.href = getOAuthURL();
        a.click();
    };

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.GoogleSignIn} onClick={() => handleSignin()} id="google-signin-btn">
                <img src={GmailIcon} alt={'Google Icon'} />
                Integrate With Google
            </div>
        </div>
    );
};

export default GoogleSignIn;
