export const ManageUserTableHeaders: {
    id: number;
    label: string;
    type: 'static' | 'dynamic';
}[] = [
    {
        id: 0,
        label: 'User Name',
        type: 'static',
    },
    {
        id: 1,
        label: 'Email',
        type: 'static',
    },
    {
        id: 2,
        label: 'Phone Number',
        type: 'static',
    },
    {
        id: 3,
        label: 'User Type',
        type: 'static',
    },
    {
        id: 4,
        label: 'Status',
        type: 'static',
    },
    {
        id: 5,
        label: 'Organisation',
        type: 'static',
    },
];

export const InvestorTableHeaders: {
    id: number;
    label: string;
    type: 'static' | 'dynamic';
}[] = [
    {
        id: 0,
        label: 'Lender Name',
        type: 'static',
    },
    {
        id: 1,
        label: 'Lender Type',
        type: 'static',
    },
    {
        id: 2,
        label: 'Users',
        type: 'static',
    },
    {
        id: 3,
        label: 'Lender Owner',
        type: 'static',
    },
    {
        id: 4,
        label: 'Internal Comments',
        type: 'static',
    },
    {
        id: 5,
        label: 'Actions',
        type: 'static',
    },
];

export const CompanySideHeaders: {
    id: number;
    label: string;
    type: 'static' | 'dynamic';
}[] = [
    {
        id: 0,
        label: 'User Name',
        type: 'static',
    },
    {
        id: 1,
        label: 'Phone Number',
        type: 'static',
    },
    {
        id: 2,
        label: 'Email',
        type: 'static',
    },
    {
        id: 3,
        label: 'Status',
        type: 'static',
    },
];

export const PartnerTableHeaders: {
    id: number;
    label: string;
    type: 'static' | 'dynamic';
}[] = [
    {
        id: 0,
        label: 'Partner Name',
        type: 'static',
    },
    {
        id: 1,
        label: 'Users',
        type: 'static',
    },
    {
        id: 2,
        label: 'Partner Owner',
        type: 'static',
    },
    {
        id: 3,
        label: 'Internal Comments',
        type: 'static',
    },
    {
        id: 4,
        label: 'Actions',
        type: 'static',
    },
];
