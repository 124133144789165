import clsx from 'clsx';
import { getMonthsByYear, monthsEnum } from '../../../../../utils/dateUtils';

import notAvailable from '../../../../../assets/not-available.svg';
import greenTick from '../../../../../assets/available.svg';
import yellowTick from '../../../../../assets/yellow-tick.svg';
import hyphenGrey from '../../../../../assets/hyphen-grey.svg';
import styles from './scss/MonthlyView.module.scss';
import dayjs from 'dayjs';

interface SmallMonthlyViewProps {
    height: any;
    error: any;
    width: any;
    graphData: any;
    graphName: string;
    isPDFReport?: boolean;
}

function MonthlyView({ height, graphData, graphName, isPDFReport = false }: SmallMonthlyViewProps) {
    const { months, datesObj, startDate } = getMonthsByYear(24);
    const plots = graphData?.plots?.[0]?.plotValues || [];
    let data = {};
    let delayDaysData = {};
    plots.forEach((plot) => {
        data[plot.xValue] = plot.yValue;
    });
    plots.forEach((plot) => {
        delayDaysData[plot.xValue] = plot.yValue2;
    });

    const getAvailabilityStatusIcon = (month: string, year: string, data: any, ind, i) => {
        const date = `${monthsEnum[month].name}_${year}`;
        const current = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD');
        const start = dayjs(startDate, 'DD-MM-YYYY');
        // if (start.isAfter(current)) return hyphenGrey;
        if (data?.[date]) {
            if (data?.[date] === 1) return greenTick;
            if (data?.[date] === 2) return yellowTick;
            if (data?.[date] === 3) return notAvailable;
        }
        return hyphenGrey;
    };

    const Legend = () => {
        const legendData = [
            { img: hyphenGrey, label: 'Data Unavailable' },
            { img: greenTick, label: 'On-Time' },
            { img: yellowTick, label: 'Delayed' },
            { img: notAvailable, label: 'Default' },
        ];
        return (
            <div
                className={clsx(styles.LegendContainer, {
                    [styles.PDFReportLegend]: isPDFReport,
                })}
            >
                {legendData.map((legend, i) => (
                    <div className={styles.Legend} key={i}>
                        <img src={legend.img} alt="yellow" height="10px" />
                        {legend.label}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div
            className={clsx(styles.SmallMonthlyView, {
                [styles.PDFReport]: isPDFReport,
            })}
            style={{
                height: isPDFReport ? 'auto' : height,
            }}
        >
            <Legend />
            {!isPDFReport && (
                <div className={styles.Top} style={{ margin: '10px 0' }}>
                    <div className={styles.TopLeft}>
                        <div className={styles.Row}></div>
                    </div>
                </div>
            )}

            <div className={clsx(styles.TableContainer)}>
                <table className={clsx(styles.Table)}>
                    <thead>
                        <tr>
                            <th className={styles.FirstColumn}> </th>
                            {months.map((month: any, i: number) => (
                                <th className={styles.MonthName} key={`${month}${i}`}>
                                    {monthsEnum[month]?.name}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {Object.keys(datesObj).map((year, i) => (
                            <tr key={i}>
                                <td className={styles.Year}>{year}</td>
                                {datesObj[year].map((month: any, ind: any) => (
                                    <td key={ind} className={styles.Item}>
                                        <div className={styles.Img}>
                                            {data?.[`${monthsEnum[month].name}_${year}`] === 2 ? (
                                                <span className={styles.DelayCount}>
                                                    {
                                                        delayDaysData?.[
                                                            `${monthsEnum[month].name}_${year}`
                                                        ]
                                                    }
                                                </span>
                                            ) : (
                                                <img
                                                    src={getAvailabilityStatusIcon(
                                                        month,
                                                        year,
                                                        data,
                                                        ind,
                                                        i,
                                                    )}
                                                    alt="avl"
                                                    height={isPDFReport ? '6px' : '9px'}
                                                />
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MonthlyView;
