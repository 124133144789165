import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './CompanyOffersTable/CompanyOffersTable.module.scss';
import { ctaPurpleRight, GREY_DROPDOWN_CARET } from '../../../../assets/hostedassets';
import Tooltip from '../../../../common/_custom/Tooltip/Tooltip';
import { useAppSelector } from '../../../../app/hooks';
import { Mixpanel } from '../../../../utils/mixpanel';
import { STATUS_DROPDOWN_OPTIONS } from '../../../Investor/Deals/components/DealsList/DealsTable/components/DealsRow/DealsRow';

const RECUR_LENDER_ID = process.env.REACT_APP_RECUR_LENDER_ID;
const RECUR_REDIRECT_URL = process.env.REACT_APP_RECUR_REDIRECT_URL;

interface OfferData {
    dealId: string;
    dealProposerId: string;
    dealBeneficiaryId: string;
    sharedDate: string;
    borrowerName: string;
    amount: string;
    tenure: string;
    roi: string;
    loanTypes: string[];
    dealStatus: string;
    dealNotes: string;
    borrowerDetails: object;
    docIds: string[];
    mainConfig: {
        actionConfig: string[];
    };
    owner: string;
    investorDeals?: {
        dealId: string;
        lenderName: string;
        dealReceiverId: string;
        sharedDate: string;
        amount: string;
        dealStatus: string;
        loanTypes: string[];
    }[];
}

interface OffersRowsProps {
    headers: {
        key: string;
        label: string;
    }[];
    rows?: OfferData[];
}

const OffersRows: React.FC<OffersRowsProps> = ({ headers, rows }) => {
    const [collapsedMap, setCollapsedMap] = useState<{ [key: string]: boolean }>({});
    const userEmail = useAppSelector((state) => state.user.userData.primaryEmail);

    const onRowClick = (event: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement, MouseEvent>, dealId: string) => {
        event.stopPropagation();
        setCollapsedMap((prev) => ({ ...prev, [dealId]: !prev[dealId] }));
    };

    const renderStatus = (status: string) => {
        const statusOption = STATUS_DROPDOWN_OPTIONS[status];
        if (!statusOption) return <span>-</span>;

        return (
            <span
                className={styles.Status}
                style={{
                    backgroundColor: statusOption.backgroundColor,
                    color: statusOption.labelColor,
                }}
            >
                {statusOption.label}
            </span>
        );
    };

    const renderProductCell = (loanTypes: string[] | undefined, row: any) => {
        const isExpanded = collapsedMap[row.dealId];
        const hasInvestorDeals = row.investorDeals && row.investorDeals.length > 0;
        if (!loanTypes?.length) return '-';
        return loanTypes.length > 1 ? (
            <Tooltip
                content={loanTypes.join(', ')}
                direction="top"
                style={{
                    height: 'fit-content',
                    width: 'fit-content',
                }}
            >
                <div className={styles.ProductCell}>
                    <span
                        className={clsx(styles.FirstProduct, {
                            [styles.BoldText]: isExpanded && hasInvestorDeals,
                        })}
                    >
                        {loanTypes[0] || '-'}
                    </span>
                    <span
                        className={clsx(styles.RemainingCount, {
                            [styles.BoldText]: isExpanded && hasInvestorDeals,
                        })}
                    >
                        {`, +${loanTypes.length - 1} more`}
                    </span>
                </div>
            </Tooltip>
        ) : (
            <span
                className={clsx(styles.ProductCell, {
                    [styles.BoldText]: isExpanded && hasInvestorDeals,
                })}
            >
                {loanTypes.join(', ') || '-'}
            </span>
        );
    };

    const renderRedirectPill = (dealRecieverId: string) => {
        if (dealRecieverId === RECUR_LENDER_ID && RECUR_REDIRECT_URL && userEmail) {
            Mixpanel.track('Recurred Lender - Redirect to Recurred Lender', {
                referrerEmail: userEmail,
            });
            const redirectUrl = `${RECUR_REDIRECT_URL}?referrerEmail=${encodeURIComponent(userEmail)}`;
            return (
                <a
                    href={redirectUrl}
                    className={styles.RedirectPill}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={ctaPurpleRight} alt="Redirect" height={12} width={12} />
                    <span>Visit Platform</span>
                </a>
            );
        }
        return null;
    };

    const getRowText = (row: OfferData, headerKey: string) => {
        const isExpanded = collapsedMap[row.dealId];
        const hasInvestorDeals = row.investorDeals && row.investorDeals.length > 0;
        const valueMap: Record<string, () => React.ReactNode> = {
            collapsible: () =>
                hasInvestorDeals ? (
                    <button
                        className={clsx(styles.DropdownButton, {
                            [styles.Open]: isExpanded,
                        })}
                        onClick={(e) => onRowClick(e, row.dealId)}
                    >
                        <img src={GREY_DROPDOWN_CARET} alt="Dropdown" />
                    </button>
                ) : (
                    ''
                ),
            status: () => renderStatus(row.dealStatus),
            product: () => renderProductCell(row.loanTypes, row),
            createDate: () => <span className={clsx({ [styles.BoldText]: isExpanded && hasInvestorDeals })}>{row?.sharedDate || '-'}</span>,
            amount: () => <span className={clsx({ [styles.BoldText]: isExpanded && hasInvestorDeals })}>{row?.amount || '-'}</span>,
            name: () => (
                <span
                    className={clsx(styles.NameCell, {
                        [styles.BoldText]: isExpanded && hasInvestorDeals,
                    })}
                >
                    {row?.borrowerName || '-'}
                </span>
            ),
        };

        return (
            valueMap[headerKey]?.() ?? (
                <span className={clsx({ [styles.BoldText]: isExpanded && hasInvestorDeals })}>{row[headerKey] || '-'}</span>
            )
        );
    };

    const renderInvestorDealCell = (investorDeal: NonNullable<OfferData['investorDeals']>[number], headerKey: string) => {
        const valueMap: Record<string, () => React.ReactNode> = {
            collapsible: () => '',
            name: () => (
                <>
                    {investorDeal.lenderName || '-'}
                    {renderRedirectPill(investorDeal.dealReceiverId)}
                </>
            ),
            createDate: () => investorDeal.sharedDate || '-',
            product: () => renderProductCell(investorDeal.loanTypes, investorDeal),
            amount: () => investorDeal.amount || '-',
            status: () => renderStatus(investorDeal.dealStatus || '-'),
        };

        return valueMap[headerKey]?.() ?? '-';
    };

    return (
        <>
            {rows?.map((row, index) => (
                <React.Fragment key={`row-group-${index}`}>
                    <tr className={styles.Row} onClick={(e) => onRowClick(e, row.dealId)}>
                        {headers.map((header) => (
                            <td
                                className={clsx(styles.RowItem, {
                                    [styles.CollapsibleRowItem]: header.key === 'collapsible',
                                })}
                                key={`column-${header.key}`}
                            >
                                <div title={row[header.key]}>{getRowText(row, header.key)}</div>
                            </td>
                        ))}
                    </tr>
                    {collapsedMap[row.dealId] &&
                        row.investorDeals?.map((investorDeal, idx) => (
                            <tr key={`investor-${row.dealId}-${idx}`} className={clsx(styles.Row, styles.CollapsibleRow)}>
                                {headers.map((header) => (
                                    <td key={`investor-column-${header.key}`} className={clsx(styles.RowItem, styles.Indent)}>
                                        {renderInvestorDealCell(investorDeal, header.key)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                </React.Fragment>
            ))}
        </>
    );
};

export default OffersRows;
