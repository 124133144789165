import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import {
    downloadFolderStructure,
    fetchAllDocs,
    fetchAllDocsAica,
    fetchIsRecurCompany,
} from './service';

export async function getAllDocs(payload, handlers) {
    try {
        const response = await fetchAllDocs(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response.data?.fileNodes);
                return;

            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        console.error('Error in fetching session conversation', err);
    }
}

export async function getAllDocsAica(payload, handlers) {
    try {
        const response = await fetchAllDocsAica(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response.data);
                return;

            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        console.error('Error in fetching session conversation', err);
    }
}

export async function getIsRecurCompany(payload) {
    try {
        const response = await fetchIsRecurCompany(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.internalResponseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.data;
            case JAVA_API_STATUS.ERROR:
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        console.error('Error in fetching session conversation', err);
    }
}

export async function getFolderStructure(data) {
    try {
        const response = await downloadFolderStructure(data);
        switch (response.status) {
            case 200:
                return response.data;

            default:
                return toast.error('Some error occurred');
        }
    } catch (err) {
        console.error('Error in fetching session conversation', err);
    }
}
