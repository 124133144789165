import * as React from 'react';
const UploadDataIcon = ({ stroke = '#4D4D4D', ...props }) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.08691 13.5608V9.38689C2.08691 5.45193 2.08691 3.48393 3.30987 2.26202C4.53178 1.03906 6.49978 1.03906 10.4347 1.03906H12.5217C16.4567 1.03906 18.4247 1.03906 19.6466 2.26202C20.329 2.94341 20.6306 3.85645 20.7631 5.21298M20.8695 9.38689V13.5608C20.8695 17.4958 20.8695 19.4638 19.6466 20.6857C18.4247 21.9086 16.4567 21.9086 12.5217 21.9086H10.4347C6.49978 21.9086 4.53178 21.9086 3.30987 20.6857C2.62744 20.0043 2.32587 19.0912 2.19335 17.7347"
            stroke={stroke}
            strokeWidth={1.5}
            strokeLinecap="round"
        />
        <path
            d="M8.91943 9.90625C7.81869 10.168 7 11.1576 7 12.3385C7 13.7192 8.11929 14.8385 9.5 14.8385C9.73685 14.8385 9.966 14.8056 10.1831 14.744"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.0137 9.90625C16.1144 10.168 16.9331 11.1576 16.9331 12.3385C16.9331 13.7192 15.8138 14.8385 14.4331 14.8385C14.1963 14.8385 13.9671 14.8056 13.75 14.744"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 9.84375C15 8.1869 13.6568 6.84375 12 6.84375C10.3432 6.84375 9 8.1869 9 9.84375"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.2666 11.8175L12.0002 10.0781L13.7832 11.8472"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 14.3455V10.9609"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UploadDataIcon;
