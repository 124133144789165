export const BASE_ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    INVESTOR: '/investor',
    COMPANY: '/company',
    RESET_PASSOWRD: '/reset-password',
    ADDITIONAL_DETAILS: '/additional-details',
    CIBIL_VERIFICATION: '/cibilVerification',
    NOT_FOUND: '/not-found',
    MAGIC_LINK: '/ml',
};

export const INVESTOR_ROUTES = {
    LOGIN: '/investor/login',
    SIGNUP: '/investor/signup',
    SUMMON_AICA: '/investor/summon-aica',
    PARTNER_SIGNUP: '/investor/partner-signup',
    PARTNER_SIGNUP_ADDITIONAL_DETAILS: '/investor/partner-signup/additional-details',

    APP: '/investor/app',
    APP_WATCH: '/investor/app/watch',
    APP_SCAN: '/investor/app/scan',
    APP_TRACK: '/investor/app/track',
    PORTFOLIO: 'portfolio',
    PORTFOLIO_DETAILS: 'portfolio/:id',
    DATA_ROOM_DETAILS: 'data-room/:id',
    DATA_ROOM: 'data-room',
    MANAGE_USER: 'manage-user',
    DEBT_PRODUCTS: 'debt-products/:id',
    MANAGE_ELIGIBILITY: 'manage-eligibility',
    PDF_REPORT: 'pdf-report/:id',
    COMPANY_DEALS: 'company-deals/:id',

    //NEW TABS
    SCAN: 'scan',
    WATCH: 'watch',
    TRACK: 'track',
    DEALS: 'deals',
    MONITOR: 'monitor',
};

export const LENDER_ROUTES = {
    APP: '/lender/app',
    MANAGE_ELIGIBILITY: 'manage-eligibility',
    DEALS: 'deals',
};

export const COMPANY_ROUTES = {
    UPLOAD: '/company/upload',
    OFFERS: '/company/offers',
};
