import { getFinancialHeadersForDebtSchedule } from '../../../../utils/dateUtils';
import dayjs from 'dayjs';
import { convertToFirstLetterCapital } from '../../../../utils/utils';

const AICA_AI_PROFILE_MAIN = 'aicaAIProfileMain';
const AICA_FETCH_ORG_INFO = 'orgInfo';
const POLUS_FETCH_AUDITED_FINANCIAL_BALANCE_SHEET = 'auditedFinancialBalanceSheet';
const POLUS_FETCH_AUDITED_FINANCIAL_PROFIT_AND_LOSS = 'auditedFinancialProfitAndLoss';
const POLUS_FETCH_AUDITED_FINANCIAL_UNSECURED_LOAN = 'auditedFinancialUnsecuredLoan';
const UW_DEBT_SCHEDULE_DATA = 'debtSchedule';
const TOP_5_VENDOR_EXPENSE_LIST = 'top5VendorExpenseList';
const TOP_5_REVENUE_SOURCE_LIST = 'top5RevenueSourceList';
const DOMESTIC_REVENUE_ACROSS_STATES = 'domesticRevenueAcrossStates';

const YELLOW = '#FFD044';
const GenerateError = " Couldn't Generate [Enter Details Here] ";
const DataNotAvl = '[Data Not Available]';

const UW_DEBT_SCHEDULE_DATA_HEADERS = [
    'lenderName',
    'typeOfDebtLoan',
    'sanctionedAmount',
    'outstandingBalance',
];

const UW_DEBT_SCHEDULE_DATA_HEADER_NAMES = {
    lenderName: 'Lender Name',
    typeOfDebtLoan: 'Type of Debt/Loan',
    sanctionedAmount: 'Sanctioned Amount (Crs.)',
    outstandingBalance: 'O/S Balance as of Date (Crs.)',
};

const FINANCIAL_HEADERS = getFinancialHeadersForDebtSchedule();

/** -----****Initial Data for One Pager Summary****---
 * 1. initialiseDealSummaryEditor : for data initialised using api data.
 * -> 4 children functions to get specific data from the api
 * 2. updatePreferredTermsInEditorData: for data initialised from deal terms step 2 inputs.
 */

export function initialiseDealSummaryEditor(apiData: any, formData: null | Object = {}) {
    let uw_debt_schedule_data = apiData?.[UW_DEBT_SCHEDULE_DATA] ?? null;
    let ai_profile_main_data = apiData?.[AICA_AI_PROFILE_MAIN] ?? null;
    let audited_financial_profit_and_loss =
        apiData?.[POLUS_FETCH_AUDITED_FINANCIAL_PROFIT_AND_LOSS] ?? null;
    let audited_financial_balance_sheet =
        apiData?.[POLUS_FETCH_AUDITED_FINANCIAL_BALANCE_SHEET] ?? null;
    let audited_financial_unsecured_loan =
        apiData?.[POLUS_FETCH_AUDITED_FINANCIAL_UNSECURED_LOAN] ?? null;
    let orgInfo = apiData?.[AICA_FETCH_ORG_INFO] ?? null;

    let topFiveVendorExpense = apiData?.[TOP_5_VENDOR_EXPENSE_LIST]?.[0] ?? null;
    let topFiveRevenueSource = apiData?.[TOP_5_REVENUE_SOURCE_LIST]?.[0] ?? null;
    let domesticRevenueAcrossStates = apiData?.[DOMESTIC_REVENUE_ACROSS_STATES] ?? null;

    let uw_debt_schedule_data_headers =
        uw_debt_schedule_data?.headers?.filter(
            (header) => UW_DEBT_SCHEDULE_DATA_HEADERS?.includes(header?.key),
        ) ?? [];
    let table_uwDebtSchedule: any = null,
        table_managementProfile: any = null,
        table_dealSummary: any = null,
        table_preferredDealTerms: any = null,
        table_financialSummary: any = null;

    function getDealSummaryData() {
        let dealSummary = {
            key: 'table_dealSummary',
            type: 'tableWithoutHeaders',
            columns: 2,
            rows: 0,
            children: [
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Legal Entity Name' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        {
                                            type: 'paragraph',
                                            children: [
                                                orgInfo?.orgName
                                                    ? {
                                                          text: `${orgInfo?.orgName ?? ''}`,
                                                          bold: true,
                                                      }
                                                    : {
                                                          text: ` ${DataNotAvl}`,
                                                          bgColor: YELLOW,
                                                          bold: true,
                                                      },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Date of incorporation' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        ai_profile_main_data?.foundationDate
                                            ? {
                                                  text: `${
                                                      ai_profile_main_data?.foundationDate ?? ''
                                                  }`,
                                                  bold: true,
                                              }
                                            : {
                                                  text: `${DataNotAvl}`,
                                                  bgColor: YELLOW,
                                                  bold: true,
                                              },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Constitution' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        orgInfo?.constitutionType
                                            ? {
                                                  text: `${orgInfo?.constitutionType ?? ''}`,
                                                  bold: true,
                                              }
                                            : {
                                                  text: `${DataNotAvl}`,
                                                  bgColor: YELLOW,
                                                  bold: true,
                                              },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'sector_industry',
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Sector/Industry' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        formData?.['borrowerProfile.companySector'] ||
                                        ai_profile_main_data?.sector
                                            ? {
                                                  text: `${
                                                      formData?.['borrowerProfile.companySector']
                                                          ? formData?.[
                                                                'borrowerProfile.companySector'
                                                            ]
                                                          : ai_profile_main_data?.sector
                                                  }`,
                                                  bold: true,
                                              }
                                            : {
                                                  text: `${DataNotAvl}`,
                                                  bgColor: YELLOW,
                                                  bold: true,
                                              },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Headquarters' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        ai_profile_main_data?.location
                                            ? {
                                                  text: `${ai_profile_main_data?.location ?? ''}`,
                                                  bold: true,
                                              }
                                            : {
                                                  text: `${DataNotAvl}`,
                                                  bgColor: YELLOW,
                                                  bold: true,
                                              },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [{ text: 'Business Overview' }],
                                },
                            ],
                        },
                        {
                            type: 'table-cell',
                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        {
                                            type: 'unorderedList',
                                            children: [
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: 'Overview: ',
                                                            bold: true,
                                                        },
                                                        ai_profile_main_data?.overview
                                                            ? {
                                                                  text: ` ${
                                                                      ai_profile_main_data?.overview ??
                                                                      ''
                                                                  }`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                                // {
                                                //     type: 'list-item',
                                                //     children: [
                                                //         {
                                                //             text: `Business Model: `,
                                                //             bold: true,
                                                //         },
                                                //         ai_profile_main_data?.businessModel
                                                //             ? {
                                                //                   text: ` ${
                                                //                       ai_profile_main_data?.businessModel ??
                                                //                       ''
                                                //                   }`,
                                                //               }
                                                //             : {
                                                //                   text: `${DataNotAvl}`,
                                                //                   bgColor: YELLOW,
                                                //               },
                                                //     ],
                                                // },
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: `Key Markets: `,
                                                            bold: true,
                                                        },
                                                        domesticRevenueAcrossStates?.length
                                                            ? {
                                                                  text: ` ${
                                                                      domesticRevenueAcrossStates?.join(
                                                                          ', ',
                                                                      ) ?? ''
                                                                  }`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: `Top 5 Customers: `,
                                                            bold: true,
                                                        },
                                                        topFiveRevenueSource?.plotValues?.length
                                                            ? {
                                                                  text: ` ${
                                                                      topFiveRevenueSource?.plotValues
                                                                          ?.map((source: any) =>
                                                                              convertToFirstLetterCapital(
                                                                                  source?.xValue ??
                                                                                      '',
                                                                              ),
                                                                          )
                                                                          ?.join(', ') ?? ''
                                                                  }`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: `Top 5 Vendors: `,
                                                            bold: true,
                                                        },
                                                        topFiveVendorExpense?.plotValues?.length
                                                            ? {
                                                                  text: ` ${
                                                                      topFiveVendorExpense?.plotValues
                                                                          ?.map((source: any) =>
                                                                              convertToFirstLetterCapital(
                                                                                  source?.xValue ??
                                                                                      '',
                                                                              ),
                                                                          )
                                                                          ?.join(', ') ?? ''
                                                                  }`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'table-row',
                    children: [
                        {
                            type: 'table-cell',
                            children: [{ text: 'Previous Investors and Funding Stage' }],
                        },
                        {
                            type: 'table-cell',

                            children: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        {
                                            type: 'unorderedList',
                                            children: [
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: `Investor Name(s): `,
                                                            bold: true,
                                                        },
                                                        ai_profile_main_data?.fundingInfo?.investors
                                                            ?.length
                                                            ? {
                                                                  text: `${ai_profile_main_data?.fundingInfo?.investors
                                                                      ?.map(
                                                                          (investor: any) =>
                                                                              investor?.name,
                                                                      )
                                                                      ?.join(' ,')}`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                                {
                                                    type: 'list-item',
                                                    children: [
                                                        {
                                                            text: `Funding Stage: `,
                                                            bold: true,
                                                        },
                                                        ai_profile_main_data?.fundingInfo?.stage
                                                            ? {
                                                                  text: `${
                                                                      ai_profile_main_data
                                                                          ?.fundingInfo?.stage ?? ''
                                                                  }`,
                                                              }
                                                            : {
                                                                  text: `${DataNotAvl}`,
                                                                  bgColor: YELLOW,
                                                              },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        };
        dealSummary.rows = dealSummary?.children?.length ?? 0;
        return dealSummary;
    }
    function getManagementProfileData() {
        let managementProfileData = {
            key: 'table_managementProfile',
            type: 'tableWithoutHeaders',
            columns: 2,
            rows: 0,
            children: !!ai_profile_main_data?.directors?.length
                ? ai_profile_main_data?.directors?.map((director: any) => ({
                      type: 'table-row',
                      children: [
                          {
                              type: 'table-cell',
                              children: [
                                  {
                                      type: 'paragraph',
                                      children: [{ text: director?.name ?? '' }],
                                  },
                              ],
                          },
                          {
                              type: 'table-cell',
                              children: [
                                  {
                                      type: 'paragraph',
                                      children: [
                                          {
                                              type: 'unorderedList',
                                              children: [
                                                  {
                                                      type: 'list-item',
                                                      children: [
                                                          {
                                                              text: `Designation: `,
                                                              bold: true,
                                                          },
                                                          director?.designation
                                                              ? {
                                                                    text: `${
                                                                        director?.designation ?? ''
                                                                    }`,
                                                                }
                                                              : {
                                                                    text: DataNotAvl,
                                                                    bgColor: YELLOW,
                                                                    bold: true,
                                                                },
                                                      ],
                                                  },
                                                  {
                                                      type: 'list-item',
                                                      children: [
                                                          {
                                                              text: `Director Since:`,
                                                              bold: true,
                                                          },
                                                          director?.tenureBeginDate
                                                              ? {
                                                                    text: ` ${dayjs(
                                                                        director?.tenureBeginDate,
                                                                    ).format('MMM YYYY')}`,
                                                                }
                                                              : {
                                                                    text: DataNotAvl,
                                                                    bgColor: YELLOW,
                                                                    bold: true,
                                                                },
                                                      ],
                                                  },
                                                  {
                                                      type: 'list-item',
                                                      children: [
                                                          { text: `Age:`, bold: true },
                                                          director?.age
                                                              ? {
                                                                    text: ` ${director?.age ?? ''}`,
                                                                }
                                                              : {
                                                                    text: DataNotAvl,
                                                                    bgColor: YELLOW,
                                                                    bold: true,
                                                                },
                                                      ],
                                                  },
                                                  { type: 'paragraph', children: [{ text: `` }] },
                                              ],
                                          },
                                      ],
                                  },
                              ],
                          },
                      ],
                  }))
                : [
                      {
                          type: 'table-row',
                          children: [
                              {
                                  type: 'table-cell',
                                  children: [
                                      {
                                          type: 'paragraph',
                                          children: [{ text: DataNotAvl, bgColor: YELLOW }],
                                      },
                                  ],
                              },
                              {
                                  type: 'table-cell',
                                  children: [
                                      {
                                          type: 'paragraph',
                                          children: [{ text: DataNotAvl, bgColor: YELLOW }],
                                      },
                                  ],
                              },
                          ],
                      },
                  ],
        };
        managementProfileData.rows = managementProfileData?.children?.length ?? 0;
        return managementProfileData;
    }
    function getDebtScheduleData() {
        let uwDebtSchedule = {
            key: 'table_uwDebtSchedule',
            type: 'table',
            columns: uw_debt_schedule_data_headers?.length || UW_DEBT_SCHEDULE_DATA_HEADERS?.length,
            rows: 0,
            children: [
                {
                    type: 'table-row',
                    children: uw_debt_schedule_data_headers?.length
                        ? uw_debt_schedule_data_headers?.map?.((header, index) => ({
                              type: 'table-cell',
                              children: [
                                  {
                                      type: 'paragraph',
                                      children: [
                                          {
                                              text: header?.name ?? '',
                                              bold: true,
                                          },
                                      ],
                                  },
                              ],
                              ...(index > 1 && { textAlign: 'right' }),
                          }))
                        : UW_DEBT_SCHEDULE_DATA_HEADERS.map((headerKey, index) => ({
                              type: 'table-cell',
                              children: [
                                  {
                                      type: 'paragraph',
                                      children: [
                                          {
                                              text:
                                                  UW_DEBT_SCHEDULE_DATA_HEADER_NAMES?.[headerKey] ??
                                                  '',
                                              bold: true,
                                          },
                                      ],
                                      ...(index > 1 && { textAlign: 'right' }),
                                  },
                              ],
                          })),
                },
                ...(!!uw_debt_schedule_data?.rows?.length && !!uw_debt_schedule_data_headers?.length
                    ? uw_debt_schedule_data?.rows?.map((row: any) => {
                          return {
                              type: 'table-row',
                              children: uw_debt_schedule_data_headers?.map?.((header, index) => ({
                                  type: 'table-cell',
                                  children: [
                                      {
                                          type: 'paragraph',
                                          children: [{ text: `${row?.[header?.key] ?? ''}` }],
                                      },
                                  ],
                                  ...(index > 1 && { textAlign: 'right' }),
                              })),
                          };
                      })
                    : [
                          {
                              type: 'table-row',
                              children: UW_DEBT_SCHEDULE_DATA_HEADERS?.map((headerKey, index) => ({
                                  type: 'table-cell',
                                  children: [
                                      {
                                          type: 'paragraph',
                                          children: [{ text: DataNotAvl, bgColor: YELLOW }],
                                      },
                                  ],
                                  ...(index > 1 && { textAlign: 'right' }),
                              })),
                          },
                      ]),
                ...(uw_debt_schedule_data?.rows?.length &&
                ('totalSanctionedAmount' in uw_debt_schedule_data ||
                    'totalOutstandingAmount' in uw_debt_schedule_data)
                    ? [
                          {
                              type: 'table-row',
                              children: [
                                  {
                                      type: 'table-cell',
                                      children: [
                                          {
                                              type: 'paragraph',
                                              children: [{ text: 'Total' }],
                                          },
                                      ],
                                  },
                                  {
                                      type: 'table-cell',
                                      children: [
                                          {
                                              type: 'paragraph',
                                              children: [{ text: '' }],
                                          },
                                      ],
                                  },
                                  {
                                      type: 'table-cell',
                                      children: [
                                          {
                                              type: 'paragraph',
                                              children: [
                                                  {
                                                      text: `${
                                                          uw_debt_schedule_data?.totalSanctionedAmount ??
                                                          '-'
                                                      }`,
                                                  },
                                              ],
                                          },
                                      ],
                                      textAlign: 'right',
                                  },
                                  {
                                      type: 'table-cell',
                                      children: [
                                          {
                                              type: 'paragraph',
                                              children: [
                                                  {
                                                      text: `${
                                                          uw_debt_schedule_data?.totalOutstandingAmount ??
                                                          '-'
                                                      }`,
                                                  },
                                              ],
                                          },
                                      ],
                                      textAlign: 'right',
                                  },
                              ],
                          },
                      ]
                    : []),
            ],
        };
        uwDebtSchedule.rows = uwDebtSchedule?.children?.length;
        return uwDebtSchedule;
    }
    function getFinancialSummary() {
        const financialRows = [
            { key: 'Revenue', label: 'Revenue', table: 'pnl' },
            { key: 'EBITDA', label: 'EBIDTA', table: 'pnl' },
            { key: 'Profit After Tax', label: 'PAT', table: 'pnl' },
            { key: 'Net Working Capital', label: 'Net Working Capital', table: 'balanceSheet' },
            {
                key: 'Cash And Cash Equivalents',
                label: 'Cash And Cash Equivalents',
                table: 'balanceSheet',
            },
            {
                key: 'Total Current Assets',
                label: 'Total Current Assets',
                table: 'balanceSheet',
            },
            {
                key: 'Total Current Liabilities',
                label: 'Total Current Liabilities',
                table: 'balanceSheet',
            },
            { key: 'Net Worth', label: 'Net Worth', table: 'balanceSheet' },
            { key: 'Long Term Borrowings', label: 'Long Term Debt', table: 'unsecuredLoans' },
            { key: 'Short Term Borrowings', label: 'Short Term Debt', table: 'unsecuredLoans' },
            { key: 'Trade Receivables', label: 'Trade Receivables', table: 'balanceSheet' },
            { key: 'Trade Payables', label: 'Trade Payables', table: 'balanceSheet' },
            { key: 'Inventories', label: 'Inventory', table: 'balanceSheet' },
            { key: 'Total Assets', label: 'Total Balance Sheet Size', table: 'balanceSheet' },
        ];

        let table_financialSummary: any = null;
        table_financialSummary = {
            key: 'table_financialSummary',
            type: 'table',
            columns: FINANCIAL_HEADERS?.length ?? 0,
            rows: 0,
            children: [
                {
                    type: 'table-row',
                    children: FINANCIAL_HEADERS?.map((header, index) => ({
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: `${header?.name ?? '-'}` }],
                            },
                        ],
                        ...(index > 0 && { textAlign: 'right' }),
                    })),
                },
            ],
        };

        financialRows?.forEach((financialRow) => {
            let row: any = {
                type: 'table-row',
            };
            let data: any;
            switch (financialRow?.table) {
                case 'pnl':
                    data = audited_financial_profit_and_loss?.[financialRow?.key];
                    break;
                case 'unsecuredLoans':
                    data = audited_financial_unsecured_loan?.rows?.find(
                        (row: any) => row?.parent === financialRow?.key,
                    );
                    break;
                case 'balanceSheet':
                    data = audited_financial_balance_sheet?.[financialRow?.key];
                    break;
            }

            row.children = [
                {
                    type: 'table-cell',
                    children: [
                        {
                            type: 'paragraph',
                            children: [{ text: financialRow?.label }],
                        },
                    ],
                },
            ];
            FINANCIAL_HEADERS?.filter?.((header) => header.key !== 'classificationName')?.forEach(
                (header) => {
                    row.children.push({
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: `${data?.[header?.key] ?? '-'}` }],
                            },
                        ],
                        textAlign: 'right',
                    });
                },
            );

            table_financialSummary.children.push(row);
        });
        table_financialSummary.rows = table_financialSummary?.children?.length ?? 0;
        return table_financialSummary;
    }

    table_dealSummary = getDealSummaryData();
    table_managementProfile = getManagementProfileData();
    table_uwDebtSchedule = getDebtScheduleData();
    table_financialSummary = getFinancialSummary();

    let updatedData = [
        ...(table_dealSummary
            ? [
                  { type: 'headingTwo', children: [{ text: 'Deal Summary' }] },
                  //   { type: 'paragraph', children: [{ text: '\n' }] },
                  table_dealSummary,
              ]
            : []),
        { type: 'paragraph', children: [{ text: '\n' }] },
        ...(table_managementProfile
            ? [
                  { type: 'headingTwo', children: [{ text: 'Management Profile' }] },
                  table_managementProfile,
                  { type: 'paragraph', children: [{ text: '\n' }] },
              ]
            : []),
        ...(table_financialSummary
            ? [
                  { type: 'headingTwo', children: [{ text: 'Financial Summary' }] },
                  table_financialSummary,
                  { type: 'paragraph', children: [{ text: '\n' }] },
              ]
            : []),
        ...(table_uwDebtSchedule
            ? [
                  { type: 'headingTwo', children: [{ text: 'Debt Schedule' }] },
                  table_uwDebtSchedule,
                  { type: 'paragraph', children: [{ text: '\n' }] },
              ]
            : []),
    ];
    // if (formData) {
    //     table_preferredDealTerms = updatePreferredTermsInEditorData(formData);
    //     if (table_preferredDealTerms) {
    //         updatedData = [
    //             ...updatedData,
    //             { type: 'headingThree', children: [{ text: 'Preferred Terms' }] },
    //             table_preferredDealTerms,
    //         ];
    //     }
    // }
    updatedData = [
        ...updatedData,
        {
            type: 'paragraph',
            children: [{ text: '\n' }],
        },
        // {
        //     type: 'image',
        //     url: poweredByAICA,
        //     children: [{ text: '' }],
        //     height: '20px',
        //     justify: 'center',
        // },
    ];
    return updatedData;

    // setTimeout(() => {
    //     setGenerating(false);
    //     setEditorReady(true);
    // }, 200);
}

export const updatePreferredTermsInEditorData = (formData: any) => {
    let table_preferredDealTerms: any = null;

    table_preferredDealTerms = {
        key: 'table_preferredDealTerms',
        type: 'tableWithoutHeaders',
        columns: 2,
        rows: 0,
        children: [
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Amount' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.maximumDealAmount']
                                        ? { text: `${formData?.['dealTerms.maximumDealAmount']}` }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Rate of Interest' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.minimumRoi'] &&
                                    formData?.['dealTerms.maximumRoi']
                                        ? {
                                              text:
                                                  formData?.['dealTerms.minimumRoi'] &&
                                                  formData?.['dealTerms.maximumRoi']
                                                      ? `${formData['dealTerms.minimumRoi']} - ${formData['dealTerms.maximumRoi']}%`
                                                      : '',
                                          }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Product' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.loanTypes']?.length
                                        ? {
                                              type: 'unorderedList',
                                              children: formData?.['dealTerms.loanTypes']?.map(
                                                  (type: any) => ({
                                                      type: 'list-item',
                                                      children: [
                                                          {
                                                              type: 'list-item',
                                                              children: [
                                                                  { text: `${type ?? '-'}` },
                                                              ],
                                                          },
                                                      ],
                                                  }),
                                              ),
                                          }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Tenure(months)' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.minimumTenure'] &&
                                    formData?.['dealTerms.maximumTenure']
                                        ? {
                                              text:
                                                  formData?.['dealTerms.minimumTenure'] &&
                                                  formData?.['dealTerms.maximumTenure']
                                                      ? `${formData['dealTerms.minimumTenure']} - ${formData['dealTerms.maximumTenure']} months`
                                                      : '',
                                          }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Security' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.securityOffered']?.length
                                        ? {
                                              type: 'unorderedList',
                                              children: formData?.[
                                                  'dealTerms.securityOffered'
                                              ]?.map((security: any) => ({
                                                  type: 'list-item',
                                                  children: [{ text: `${security ?? '-'}` }],
                                              })),
                                          }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Deal Notes' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData?.['dealTerms.dealNotes']
                                        ? { text: `${formData?.['dealTerms.dealNotes'] ?? ''}` }
                                        : { text: DataNotAvl, bgColor: YELLOW },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };
    table_preferredDealTerms.rows = table_preferredDealTerms?.children?.length;
    return table_preferredDealTerms;
};

export const updateTemplateDataWithPreferredTerms = (existingTemplateData, formData) => {
    let dataToUpdate = JSON.parse(JSON.stringify(existingTemplateData));
    // let table_preferredDealTerms = updatePreferredTermsInEditorData(formData);
    // if (table_preferredDealTerms) {
    //     dataToUpdate = [
    //         ...dataToUpdate,
    //         { type: 'headingThree', children: [{ text: 'Preferred Terms' }] },
    //         table_preferredDealTerms,
    //     ];
    // }
    let dealSummaryDataIndex = dataToUpdate.findIndex(
        (table) => table?.key === 'table_dealSummary',
    );
    if (dealSummaryDataIndex > -1) {
        let dealSummaryData = dataToUpdate[dealSummaryDataIndex]?.children;
        let sectorIndustryIndex = dealSummaryData?.findIndex(
            (table) => table?.key === 'sector_industry',
        );

        if (sectorIndustryIndex > -1) {
            dealSummaryData[sectorIndustryIndex] = {
                key: 'sector_industry',
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [{ text: 'Sector/Industry' }],
                            },
                        ],
                    },
                    {
                        type: 'table-cell',
                        children: [
                            {
                                type: 'paragraph',
                                children: [
                                    formData && formData?.['borrowerProfile.companySector']
                                        ? {
                                              text: `${formData?.['borrowerProfile.companySector']}`,
                                              bold: true,
                                          }
                                        : {
                                              text: `${DataNotAvl}`,
                                              bgColor: YELLOW,
                                              bold: true,
                                          },
                                ],
                            },
                        ],
                    },
                ],
            };
        }
    }

    return dataToUpdate;
};
