import { useEffect, useState } from 'react';
import { isEmpty, isRestrictedUser } from '../../utils/utils';
import { FileNode } from './DataRoom';
import { getAllDocs, getAllDocsAica, getIsRecurCompany } from '../../store/dataRoom/apiHelper';
import { RESTRICTED_ROLES } from '../../store/investor/constants';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { RECUR_PNO_DOMAIN } from '../../store/user/constants';

const useCallApiToFetchFileData = (orgName?: string) => {
    const [fileData, setFileData] = useState<FileNode[] | null>(null);
    const [activeFilecount, setActiveFileCount] = useState<number>(0);
    const [archivedFilecount, setArchivedFileCount] = useState<number>(0);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [newFilePresent, setNewFilePresent] = useState(false);
    const user = useAppSelector((state) => state.user);
    // const [isRecurCompany, setIsRecurCompany] = useState<boolean | null>(null);
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const params = useParams();
    const pnoId = user?.userData?.investorOrgId;
    const [queryParams, setQueryParams] = useSearchParams();
    const viewType = queryParams.get('fileViewType');

    // useEffect(() => {
    //     const domainDataRoom = process.env.RECUR_PNO_DOMAIN || RECUR_PNO_DOMAIN;
    //
    //     if (domainDataRoom && location.origin.includes(domainDataRoom)) {
    //         fetchCompanyData();
    //     } else {
    //         setIsRecurCompany(false);
    //     }
    // }, []);

    useEffect(() => {
        if ((fileData === null && orgName) || viewType) {
            fetchFiles();
        }
    }, [viewType, queryParams.get('magicUpload'), queryParams.get('needInfo')]);

    // const fetchCompanyData = async () => {
    //     const { recurCompany } = await getIsRecurCompany({ assesseeOrgId: params?.id ?? '' });
    //     setIsRecurCompany(recurCompany);
    // };

    const fetchFiles = async (fetchOnDelete = false) => {
        if (!fileData) setIsFetchingData(true);
        await getAllDocsAica(
            {
                assesseeOrgId: params?.id ?? '',
                pnoId: pnoId ?? '',
                viewType: viewType ?? 'active',
                forOrgId: localStorage.getItem('user_org_id') ?? '',
            },
            {
                onSuccess: (data) => {
                    setFileData(data?.fileNodes);
                    setActiveFileCount(data?.activeFileCount);
                    setArchivedFileCount(data?.archivedFileCount);
                    setNewFilePresent(checkNewFolder(data?.fileNodes));
                },
            },
        );
        setIsFetchingData(false);
    };

    //check all folder and find if there is any new folder
    const checkNewFolder = (data: FileNode[]) => {
        let isNew = false;
        data.forEach((folder) => {
            if (folder.isNew) {
                isNew = true;
            }
        });
        return isNew;
    };

    return {
        fileData,
        isFetchingData,
        fetchFiles,
        activeFilecount,
        archivedFilecount,
        newFilePresent,
        // , isRecurCompany
    };
};

export default useCallApiToFetchFileData;
