import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    fetchAuditedTableData,
    fetchMisTableOptions,
    updateAuditedTableData,
} from '../../../../../../store/company/action';
import { getTimeStamp } from '../../../../../../utils/dateUtils';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { removeKeyAndConvertIdToNumber } from '../../../../../../utils/utils';

import EditableDropdown from '../../../../../../common/EditableDropdown/EditableDropdown';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import UploadData from '../../../../../Company/UploadData/UploadData';
import RawTable from '../../../../components/Tables/RawTable/RawTable';
import ClassifiedTable from '../../../../components/Tables/ClassifiedTable/ClassifiedTable';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';

import expandMore from '../../../../../../assets/expand-more.svg';
import styles from './ProvisionalFinancials.module.scss';
import { useAppSelector } from '../../../../../../app/hooks';
import { updateQueryParams } from '../../../../../../utils/searchParamUtils';
import { ctaPurpleRight } from '../../../../../../assets/hostedassets';

const ProvisionalFinancials = () => {
    const [metric, setMetric] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = useParams();
    const location = useLocation();

    const [metricOptions, setMetricOptions] = useState<string[]>([]);
    const [rawOptions, setRawOptions] = useState([]);
    const [dataFormatOptions, setDataFormatOptions] = useState([]);
    const [tableData, setTableData] = useState<any>([]);
    const [rawTableData, setRawTableData] = useState<any>([{}]);
    const [changedData, setChangedData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [classificationId, setClassificationId] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [tableType, setTableType] = useState<string>('');
    const [metricOptionsObject, setMetricsOptionsObject] = useState([]);
    const isLender = useAppSelector((state) => state.user.userData.accountType) === 'LENDER';
    const dispatch = useDispatch();

    const fetchOptions = () => {
        dispatch(
            fetchMisTableOptions({
                orgId: queryParams.id || '',
                source: 'AicaFinancials',
                type: 'mappedData',
                onSuccess: (res) => {
                    setRawOptions(res.data?.data?.[0]?.misOptions);
                    setMetricOptions(
                        res.data?.data?.[0]?.misOptions?.map((item) => {
                            return item.misDisplayName;
                        }),
                    );
                    setMetricsOptionsObject(res.data?.data?.[0]?.misOptions || []);
                    setDataFormatOptions(
                        res.data?.data?.[1].dataFormats.map((item) => item.dataFormat),
                    );
                },
            }),
        );
    };
    const fetchTableData = () => {
        setLoading(true);
        dispatch(
            fetchAuditedTableData(
                {
                    type: 'provisionalstatements',
                    mis: metric,
                    orgId: queryParams.id ?? '',
                    requestId: getTimeStamp(),
                    raw: false,
                },
                {
                    onError: () => {
                        setLoading(false);
                    },
                    onSuccess: (data) => {
                        setLoading(false);
                        setTableData(data);
                    },
                },
            ),
        );
    };
    const fetchRawData = (row: { [key: string]: string }, header: { [key: string]: string }) => {
        if (header.name === 'Classification' && row?.[header.key] && !row.isAggregated) {
            setLoading(true);
            setTableType(row.classificationName);
            dispatch(
                fetchAuditedTableData(
                    {
                        type: 'provisionalstatements',
                        mis: metric,
                        orgId: queryParams.id ?? '',
                        requestId: getTimeStamp(),
                        classificationId: row.classificationId,
                        raw: true,
                    },
                    {
                        onError: () => {
                            setLoading(false);
                        },
                        onSuccess: (data) => {
                            setLoading(false);
                            setRawTableData(data);
                        },
                    },
                ),
            );
        }
    };
    useEffect(() => {
        fetchOptions();
    }, []);
    useEffect(() => {
        if (metricOptionsObject?.length && metricOptions?.length) {
            let first = (metricOptionsObject as any).find(
                (item: any) => item?.isDataAvailable === true,
            )?.misDisplayName;
            setMetric(first || metricOptions?.[0]);
        }
    }, [metricOptionsObject, metricOptions]);
    useEffect(() => {
        if (metric) {
            if (metric === 'Other Metric (MIS)') {
                fetchRawData(
                    { classificationId: '1', classificationName: 'Unclassified' },
                    { name: 'Classification', key: 'classificationName' },
                );
            } else {
                fetchTableData();
            }
        }
    }, [metric]);
    const saveClassification = () => {
        dispatch(
            updateAuditedTableData({
                orgId: queryParams.id ?? '',
                metrics: metric,
                changedData: removeKeyAndConvertIdToNumber(changedData, 'name'),
                source: 'AicaFinancials',
                onSuccess: () => {
                    if (metric === 'Other Metric (MIS)') {
                        fetchRawData(
                            { classificationId: '1', classificationName: 'Unclassified' },
                            { name: 'Classification', key: 'classificationName' },
                        );
                    } else {
                        fetchTableData();
                        setTableType('');
                    }
                    setIsEditing(false);
                    setChangedData([]);
                    toast.success('Data saved successfully');
                },
                onError: () => {
                    toast.error('Failed to save data');
                },
            }),
        );
    };

    const showFinancialMetrics = () => {
        updateQueryParams(searchParams, setSearchParams, { showFinancialMetrics: true }, location);
    };
    const renderEditingBlock = () => {
        if (isEditing) {
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        fontFamily: 'Gilroy-Semibold,serif',
                        fontSize: '14px',
                    }}
                >
                    <div>Save Classification</div>
                    <button
                        onClick={() => setIsEditing(!isEditing)}
                        className={styles.CancelClassification}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => saveClassification()}
                        className={styles.SaveClassification}
                    >
                        <img
                            src={
                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/saveClassificationInvestor.svg'
                            }
                            alt={'save'}
                        />
                        Save
                    </button>
                </div>
            );
        }
        return (
            <div onClick={() => setIsEditing(!isEditing)} className={styles.EditClassification}>
                <img
                    src={
                        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/editClassification.svg'
                    }
                    alt="edit"
                />
                Edit Classification
            </div>
        );
    };

    return (
        <div>
            {(metric === 'Other Metric (MIS)' || !tableType) && (
                <div className={styles.HeadingContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <h3>Financial Statements</h3>
                        <div className={styles.divider} />
                        <EditableDropdown
                            id={'MetricSelector'}
                            optionStyle={{ fontSize: '14px' }}
                            value={metric}
                            options={metricOptions}
                            setOutput={(val) => {
                                setMetric(val);
                                setTableType('');
                            }}
                            removeInput={true}
                            placeHolder={'Select Metric'}
                            optionsObject={metricOptionsObject}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
                        <div
                            className={styles.AllFinancialMetrics}
                            onClick={() => showFinancialMetrics()}
                        >
                            <img src={ctaPurpleRight} alt={'upload'} />
                            View Financial Ratios
                        </div>
                        {metric !== 'Other Metric (MIS)' && !isLender ? (
                            <div className={styles.UploadData} onClick={() => setShowUpload(true)}>
                                <img
                                    src={
                                        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/UploadDataInvestorProv.svg'
                                    }
                                    alt={'upload'}
                                    height={'14px'}
                                />
                                Upload Data
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
            <div className={styles.ProvisionalFinancialsContainer}>
                {tableType && metric !== 'Other Metric (MIS)' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div className={styles.Breadcrumb}>
                            <span
                                className={styles.Blue}
                                onClick={() => {
                                    setTableType('');
                                }}
                            >
                                {metric}
                            </span>
                            <img src={expandMore} alt="exp" height="10px" />
                            {tableType}
                        </div>
                        {renderEditingBlock()}
                    </div>
                )}
                {loading ? (
                    <LoadingSpinner color="black" height="40px" style={{ marginTop: '40px' }} />
                ) : (
                    <div className={styles.TableContainer}>
                        {(tableType || metric === 'Other Metric (MIS)') && (
                            <RawTable
                                data={rawTableData}
                                loading={loading}
                                freezeColumnEnd={1}
                                freezeColumnStart={0}
                                isEditing={isEditing}
                                metric={metric}
                                rawOptions={rawOptions}
                                dataFormatOptions={dataFormatOptions}
                                changedData={changedData}
                                setChangedData={setChangedData}
                            />
                        )}
                        {tableType === '' && metric !== 'Other Metric (MIS)' && (
                            <ClassifiedTable
                                data={tableData}
                                loading={loading}
                                handleClick={fetchRawData}
                                metric={metric}
                            />
                        )}
                    </div>
                )}
                {showUpload && (
                    <SideDrawer onClose={() => setShowUpload(false)} width="50%">
                        {/* <SideDrawer.Header>Upload Data</SideDrawer.Header> */}
                        <UploadData
                            overrideSelectedStep={'financials'}
                            userType={USER_TYPE.INVESTOR}
                            isInvestor
                            assesseeOrgId={queryParams.id ?? ''}
                            closeDrawer={() => setShowUpload(false)}
                        />
                    </SideDrawer>
                )}
            </div>
        </div>
    );
};

export default ProvisionalFinancials;
