import React from 'react';
import Styles from './Common.module.scss';
import TextTooltip from '../../../../../../../common/_custom/Tooltip/Tooltip';
import infoIcon from '../../../../../../../assets/info.svg';
import clsx from 'clsx';

const CommonComponent = ({ title, tooltipText, row2Content, chart, isPDFReport = false }) => {
    return (
        <div className={clsx(Styles.Container, { [Styles.PDFReport]: isPDFReport })}>
            <div className={Styles.Headers}>
                <div className={Styles.Row1}>
                    {!isPDFReport && <span>{title}</span>}
                    {tooltipText && !isPDFReport && (
                        <TextTooltip content={tooltipText} direction="right">
                            <img src={infoIcon} alt="info" height="16px" />
                        </TextTooltip>
                    )}
                </div>
                <div className={Styles.Row2}>{row2Content}</div>
            </div>
            <div className={Styles.Chart}>{chart}</div>
        </div>
    );
};

export default CommonComponent;
