export const InviteUserIcon = ({ width = '16', height = '16', color = '#6021B3' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_8450_163599"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_8450_163599)">
                <path
                    d="M12.0003 9.33073V7.33073H10.0003V5.9974H12.0003V3.9974H13.3337V5.9974H15.3337V7.33073H13.3337V9.33073H12.0003ZM6.00033 7.9974C5.26699 7.9974 4.63921 7.73628 4.11699 7.21406C3.59477 6.69184 3.33366 6.06406 3.33366 5.33073C3.33366 4.5974 3.59477 3.96962 4.11699 3.4474C4.63921 2.92517 5.26699 2.66406 6.00033 2.66406C6.73366 2.66406 7.36144 2.92517 7.88366 3.4474C8.40588 3.96962 8.66699 4.5974 8.66699 5.33073C8.66699 6.06406 8.40588 6.69184 7.88366 7.21406C7.36144 7.73628 6.73366 7.9974 6.00033 7.9974ZM0.666992 13.3307V11.4641C0.666992 11.0863 0.764214 10.7391 0.958659 10.4224C1.1531 10.1057 1.41144 9.86406 1.73366 9.6974C2.42255 9.35295 3.12255 9.09462 3.83366 8.9224C4.54477 8.75017 5.26699 8.66406 6.00033 8.66406C6.73366 8.66406 7.45588 8.75017 8.16699 8.9224C8.8781 9.09462 9.5781 9.35295 10.267 9.6974C10.5892 9.86406 10.8475 10.1057 11.042 10.4224C11.2364 10.7391 11.3337 11.0863 11.3337 11.4641V13.3307H0.666992ZM2.00033 11.9974H10.0003V11.4641C10.0003 11.3418 9.96977 11.2307 9.90866 11.1307C9.84755 11.0307 9.76699 10.953 9.66699 10.8974C9.06699 10.5974 8.46144 10.3724 7.85033 10.2224C7.23921 10.0724 6.62255 9.9974 6.00033 9.9974C5.3781 9.9974 4.76144 10.0724 4.15033 10.2224C3.53921 10.3724 2.93366 10.5974 2.33366 10.8974C2.23366 10.953 2.1531 11.0307 2.09199 11.1307C2.03088 11.2307 2.00033 11.3418 2.00033 11.4641V11.9974ZM6.00033 6.66406C6.36699 6.66406 6.68088 6.53351 6.94199 6.2724C7.2031 6.01128 7.33366 5.6974 7.33366 5.33073C7.33366 4.96406 7.2031 4.65017 6.94199 4.38906C6.68088 4.12795 6.36699 3.9974 6.00033 3.9974C5.63366 3.9974 5.31977 4.12795 5.05866 4.38906C4.79755 4.65017 4.66699 4.96406 4.66699 5.33073C4.66699 5.6974 4.79755 6.01128 5.05866 6.2724C5.31977 6.53351 5.63366 6.66406 6.00033 6.66406Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
